import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { arrowBack as arrowBackIcon, informationCircleOutline, logoYoutube } from "ionicons/icons";
import {
  logoFacebook,
  logoTwitter,
  logoInstagram,
  logoLinkedin,
} from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { getSocialProfileAction, postSocialProfile } from "../../actions/dashboardActions";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import FeatHeader from '../../components/dashboard/FeatHeader';



const SocialProfile = () => {
  const dispatch = useDispatch();
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [youtube, setYoutube] = useState("");

  const { currentUser } = useSelector((state: any) => state.currentUser);

  const {getSocialProfileLoading,getSocialProfile,getSocialProfileError} = useSelector((state:any) => state.getSocialProfile )

  useEffect(() => {
    if (currentUser && currentUser.data) {
      dispatch(
        getSocialProfileAction(getAccessToken(), currentUser.data.store.subdomain)
      );
    }
  }, [currentUser]);
  
  useEffect(() => {
    if (getSocialProfileLoading === false && getSocialProfile && getSocialProfile.data) {
      console.log("lets find out ",getSocialProfile)
      setFacebook(getSocialProfile.data.facebook);
      setTwitter(getSocialProfile.data.twitter);
      setInstagram(getSocialProfile.data.instagram);
      setLinkedin(getSocialProfile.data.linkedin);
      setYoutube(getSocialProfile.data.youtube);
    }
  }, [getSocialProfile]);
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    const socialProfileData = {
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
      linkedin: linkedin,
      youtube: youtube
    };
    try {
      await dispatch(postSocialProfile(getAccessToken(),  currentUser.data.store.subdomain, socialProfileData));
      console.log('API call success');
    } catch (error) {
      console.log('API call failed:', error);
    }
  };
  const openLinkInNewTab = () => {
    window.open(`https://intelikart.tawk.help/article/social-profiles`, '_blank');
  };
  
  return (
    <IonPage>
      
      <FeatHeader
        title='Social Profiles'
        defaultHref='/settings'
        showInfoButton={true}
        onInfoClick={openLinkInNewTab}
      />
      <IonContent>
        <form onSubmit={handleSubmit} style={{marginTop:'20px'}}>
          <IonItem lines="inset">
            <IonIcon icon={logoFacebook} slot="start" />
            <IonLabel position="stacked">Facebook</IonLabel>
            <IonInput
              type="url"
              value={facebook}
              onIonChange={(e) => setFacebook(e.detail.value)}
              style={{ "--padding-start": "6px" }}
            />
          </IonItem>

          <IonItem lines="inset">
            <IonIcon icon={logoTwitter} slot="start" />
            <IonLabel position="stacked">Twitter</IonLabel>
            <IonInput
              type="url"
              value={twitter}
              onIonChange={(e) => setTwitter(e.detail.value)}
              style={{ "--padding-start": "6px" }}
            />
          </IonItem>

          <IonItem lines="inset">
            <IonIcon icon={logoInstagram} slot="start" />
            <IonLabel position="stacked">Instagram</IonLabel>
            <IonInput
              type="url"
              value={instagram}
              onIonChange={(e) => setInstagram(e.detail.value)}
              style={{ "--padding-start": "6px" }}
            />
          </IonItem>

          <IonItem lines="inset">
            <IonIcon icon={logoLinkedin} slot="start" />
            <IonLabel position="stacked">LinkedIn</IonLabel>
            <IonInput
              type="url"
              value={linkedin}
              onIonChange={(e) => setLinkedin(e.detail.value)}
              style={{ "--padding-start": "6px" }}
            />
          </IonItem>

          <IonItem lines="inset">
            <IonIcon icon={logoYoutube} slot="start" />
            <IonLabel position="stacked">Youtube</IonLabel>
            <IonInput
              type="url"
              value={youtube}
              onIonChange={(e) => setYoutube(e.detail.value)}
              style={{ "--padding-start": "6px" }}
            />
          </IonItem>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <IonButton type="submit" style={{ margin: "16px 0" }}>
              Save
            </IonButton>
          </div>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default SocialProfile;
