import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";
import {
  IonAlert,
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToast
} from "@ionic/react";
import introJs from "intro.js";
import "intro.js/introjs.css";
import {
  addCircleOutline,
  albumsOutline,
  calendarOutline,
  callOutline,
  caretForwardOutline,
  chevronForward,
  copyOutline as copyIcon,
  cubeOutline,
  eyeOutline,
  logoFacebook,
  logoTwitter,
  logoWhatsapp,
  mailUnreadOutline,
  peopleOutline,
  personOutline,
  pricetagsOutline
} from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import {
  BrowserView,
  MobileView,
  isDesktop,
  isMobile,
} from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { authenticateUserWhenLogout } from "../../actions/authActions";
import {
  getHomeScreenData,
  getHomeScreenDataWithDays,
} from "../../actions/dashboardActions";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import { useSharedData } from "../../utils/contexts";
import useFetch from "../../utils/useFetch";
import { ShareButtonMobile } from "../ShareButtonMobile";
import { ShareButtonWeb } from "../ShareButtonWeb";
const HomeScreen = () => {
  const [showWebToast, setShowWebToast] = useState(false);
  const [showAppToast, setShowAppToast] = useState(false);
  const [selectedValue, setSelectedValue] = useState(1000000);
  const [countdown, setCountdown] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [subsMessage, setSubsMessage] = useState({});
  const productCardRef = useRef(null);
  const sharedData: any = useSharedData();

  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser } = useSelector((state: any) => state.currentUser);
  const { homeScreenLoading, homeScreen, homeScreenError } = useSelector(
    (state: any) => state.homeScreenData
  );
  const {
    homeScreenWithDaysLoading,
    homeScreenWithDays,
    homeScreenWithDaysError,
  } = useSelector((state: any) => state.homeScreenDataWithDays);
  let columnSize = isMobile ? "12" : "12";
  let queryColSize = isMobile ? "" : "3";
  let addProductColSize = isMobile ? "" : "4";

  const [messageToShow, setMessageToShow] = useState({});
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [showIntro, setShowIntro] = useState(false);
  const { data: selfDeliveryData } = useFetch(
    "selfDeliveryAddon",
    sharedData.selfDeliveryUpdate
  );

  const handleRenew = () => {
    // const phoneNumber = "8448355548";
    // const url = `https://wa.me/${phoneNumber}`;
    // window.open(url, "_blank");
    history.push('/subscriptions')
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0]; // Get current date in the format "YYYY-MM-DD"
    return new Date(formattedDate); // Convert the formatted date string to a Date object
  };

  const openLink = () => {
    if (messageToShow && messageToShow["Link"]) {
      if (messageToShow["Link"].includes("https://")) {
        window.open(messageToShow["Link"], "_blank");
      } else {
        window.open("https://" + messageToShow["Link"], "_blank");
      }
    }
  };

  // useEffect(() => {
  //   initialValues();
  // }, [currentUser]);

  const initialValues = async () => {
    const accessToken = await getAccessToken();
    const storeId =
      currentUser && currentUser.data ? currentUser.data.store.subdomain : null;
    if (storeId) {
      if (accessToken) {
        await dispatch(getHomeScreenData(accessToken, storeId));
        await dispatch(
          getHomeScreenDataWithDays(accessToken, storeId, selectedValue)
        );
      } else {
        await dispatch(authenticateUserWhenLogout());
        await localStorage.clear();
        history.push("/");
      }
    }
  };
  const map = {
    "SCREEN.NONE": "/userdashboard",
    "SCREEN.HELP": "/help&support",
    "SCREEN.CUSTOMERS": "/customer",
    "SCREEN.LEARNGROW": "/ikacademy",
    "SCREEN.BANNERS": "/banners",
    "SCREEN.COUPONS": "/coupons",
    "SCREEN.CATALOG": "/catalogue/categories",
    "SCREEN.SETTINGS.LOCALISATION": "/settings/localization",
    "SCREEN.SETTINGS.BASICINFO": "/settings/basicinfo",
  };
  useEffect(() => {
    changeStatusHelper();
  }, [selectedValue]);

  const changeStatusHelper = async () => {
    const storeId =
      currentUser && currentUser.data ? currentUser.data.store.subdomain : null;
    if (currentUser && currentUser.data && storeId) {
      await dispatch(
        getHomeScreenDataWithDays(getAccessToken(), storeId, selectedValue)
      );
    }
  };

  const onSelectChange = async (e: any) => {
    setSelectedValue(e.detail.value);
  };

  const copyToClipboard = async (data: string) => {
    await Clipboard.write({
      string: `${data}`,
    });
  };

  const shareWebsite = async () => {
    await Share.share({});
  };

  const shareBusinessApp = async () => {
    await Share.share({
      title: "Intelikart Business App",
      text: `${
        homeScreenWithDaysLoading === false &&
        homeScreenWithDays &&
        homeScreenWithDays.data &&
        homeScreenWithDays.data.app
          ? homeScreenWithDays.data.app.message
          : "My Business App"
      }`,
      url: `${
        homeScreenWithDaysLoading === false &&
        homeScreenWithDays &&
        homeScreenWithDays.data &&
        homeScreenWithDays.data.app
          ? homeScreenWithDays.data.app.URL
          : ""
      }`,
    });
  };
  const urlSearchParams = new URLSearchParams(window.location.search);

  // Get the value of 'intro' parameter
  const introValue = urlSearchParams.get("intro");

  useEffect(() => {
    if (currentUser && currentUser.data) {
      const subsInfo = async () => {
        try {
          const response = await fetch(
            "https://api2.intelikart.in/subscriptions/info",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getAccessToken()}`,
                "x-ik-domain": `${currentUser?.data?.store.subdomain}`,
              },
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not OK");
          }

          const data = await response.json();
          const subscription_text = data["subscriptionText"];
          const date_regex = /(\d{2}-[A-Za-z]{3}-\d{4})/;
          const match = subscription_text.match(date_regex);
          const store_Creation = new Date(currentUser.data.store.created_at);
          const today = new Date();
          if (match) {
            const expiry_date = match[1];
            const expiryDateObj = new Date(expiry_date);
            const currentDateObj = new Date();
            const timeDiff = expiryDateObj.getTime() - currentDateObj.getTime();
            const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
            setCountdown(daysDiff);
            if (daysDiff !== null && daysDiff > 0) {
              if (daysDiff <= 10) {
                if (daysDiff === 0) {
                  setShowPopup(true);
                  setSubsMessage({
                    title: "Your subscription is expiring today.",
                    message:
                      "Please renew now to continue for uninterrupted services.",
                  });
                } else {
                  setShowPopup(true);
                  setSubsMessage({
                    title: `Your subscription is expiring in ${daysDiff} ${
                      daysDiff === 1 ? "Day" : "Days"
                    }`,
                    message:
                      "Please renew now to continue for uninterrupted services.",
                  });
                }
              }
            }
          } else {
            if (data.isNewUser) {
              let difference = Math.abs(
                today.getTime() - store_Creation.getTime()
              );
              let days_difference = Math.ceil(difference / (1000 * 3600 * 24));
              if (days_difference >= 30) {
                setSubsMessage({
                  title: "Your free trial plan have expired.",
                  message:
                    "Please purchase subscription for continued services",
                });
                setShowPopup(true);
              }
            } else {
              setSubsMessage({
                title: "Your subscription has expired.",
                message:
                  "Please renew now to continue for uninterrupted services.",
              });
              setShowPopup(true);
            }
          }
        } catch (error) {
          console.error("Error:", error);
        }
      };
      subsInfo();
    }
  }, [currentUser]);

  const renderPopup = () => {
    return (
      <IonAlert
        isOpen={showPopup}
        onDidDismiss={() => setShowPopup(false)}
        header={subsMessage["title"]}
        message={subsMessage["message"]}
        buttons={[
          {
            text: "CANCEL",
            role: "cancel",
            handler: function onCancel(){
                  if (
                    (isFirstTimeUser("completedProductIntro") || introValue) &&
                    isDesktop
                  ) {
                   startIntro();
                    localStorage.setItem("completedProductIntro", "true");
                  }
          }},
          {
            text: "RENEW",
            handler: handleRenew,
          },
        ]}
      />
    );
  };

  const startIntro = () => {
    const intro = introJs();
    intro.onchange((targetElement) => {
      setCurrentStep(intro._currentStep);
    });

    intro.setOptions({
      steps: [
        {
          element: "#products",
          intro: "To Add your products",
        },
        {
          element: "#profile",
          intro: "Follow and complete your store setup",
        },
        {
          element: "#catalogue",
          intro: "Manage your products and categories here.",
        },
        {
          element: "#maketing",
          intro: "Supercharge your marketing with printable banners and emails",
        },
        {
          element: "#appearance",
          intro: "Change store appearance to sync with your brand.",
        },
        {
          element: "#help",
          intro: "Got a question for us? Talk to us",
          position: "bottom",
        },
        {
          intro: "Thanks for taking the tour",
        },
      ],
    });

    intro.start();
    setFirstTimeUser("completedProductIntro");
    setShowIntro(true);

    return () => {
      intro.exit(false);
      setShowIntro(false);
    };
  };

  const setFirstTimeUser = (key) => {
    localStorage.setItem(key, "true");
  };

  useEffect(() => {
    if (
      homeScreenWithDaysLoading === false &&
      homeScreenWithDays &&
      homeScreenWithDays.data
    ) {
      (async () => {
        if (
          (isFirstTimeUser("completedProductIntro") || introValue) &&
          isDesktop
        ) {
          if(!showPopup){
          await startIntro();
          localStorage.setItem("completedProductIntro", "true");
          }
        }
      })();
    }
  }, [homeScreenWithDays]);

  const isFirstTimeUser = (key) => {
    const isFirstTime = localStorage.getItem(key);
    return isFirstTime !== "true";
  };

  return (
    <>
      <div>
        {showPopup && Object.keys(subsMessage).length !== 0 && renderPopup()}
      </div>
      <BrowserView>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonText>
                <h3 className="ion-no-margin">Dashboard</h3>
              </IonText>
            </IonCol>
            <IonCol size="3">
              <IonItem lines="none" className="roundedInput">
                <IonLabel
                  style={{
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Your store stats
                </IonLabel>
                <IonSelect
                  className="ion-no-padding ion-no-margin"
                  slot="end"
                  onIonChange={(e) => onSelectChange(e)}
                  value={selectedValue}
                >
                  <IonSelectOption value={1000000}>Lifetime</IonSelectOption>
                  <IonSelectOption value={1}>Today</IonSelectOption>
                  <IonSelectOption value={7}>Weekly</IonSelectOption>
                  <IonSelectOption value={30}>Monthly</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </BrowserView>
      <MobileView>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonText>
                <h3 className="ion-no-margin">Dashboard</h3>
              </IonText>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel>Your store stats</IonLabel>
                <IonSelect
                  className="ion-no-padding ion-no-margin"
                  slot="end"
                  onIonChange={(e) => onSelectChange(e)}
                  value={selectedValue}
                >
                  <IonSelectOption value={1000000}>Lifetime</IonSelectOption>
                  <IonSelectOption value={1}>Today</IonSelectOption>
                  <IonSelectOption value={7}>Weekly</IonSelectOption>
                  <IonSelectOption value={30}>Monthly</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
        {/* <ProfileCompletion name={currentUser && currentUser.data && currentUser.data ? `Hello, ${currentUser.data.first_name}` : ""}></ProfileCompletion> */}
      </MobileView>
      <IonGrid className="ion-no-margin ion-no-padding dashboard-cards">
        <IonRow className="ion-no-margin ion-no-padding">
          <IonCol size={columnSize}>
            <IonGrid className="ion-no-margin ion-no-padding">
              <IonRow className="ion-no-margin ion-no-padding">
                <IonCol>
                  <IonCard
                    className="ion-margin-right ion-no-padding"
                    routerLink="/catalogue/products"
                    ref={productCardRef}
                    id="products"
                  >
                    <IonItem lines="none">
                      <IonText color="secondary">Products</IonText>
                      <IonIcon
                        slot="end"
                        color="primary"
                        icon={cubeOutline}
                      ></IonIcon>
                    </IonItem>
                    <IonItem lines="none">
                      <IonText className="fnt-sz28 dashboard-big-number">
                        {homeScreenWithDaysLoading === false &&
                        homeScreenWithDays &&
                        homeScreenWithDays.data
                          ? homeScreenWithDays.data.products
                          : null}
                      </IonText>
                      <IonIcon
                        slot="end"
                        size="small"
                        icon={chevronForward}
                      ></IonIcon>
                    </IonItem>

                    {/* <IonText className="dashboard-fnt-sz">Your Orders</IonText>
                                            <IonCardTitle className="fnt-sz28">{homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data ? homeScreenWithDays.data.orders : null}</IonCardTitle> */}
                  </IonCard>
                </IonCol>
                <IonCol>
                  <IonCard
                    className="ion-margin-end  ion-no-padding"
                    routerLink="/customer"
                  >
                    <IonItem lines="none">
                      <IonText color="secondary">Customers</IonText>
                      <IonIcon
                        slot="end"
                        color="primary"
                        icon={peopleOutline}
                      ></IonIcon>
                    </IonItem>
                    <IonItem lines="none">
                      <IonText className="fnt-sz28 dashboard-big-number">
                        {homeScreenWithDaysLoading === false &&
                        homeScreenWithDays &&
                        homeScreenWithDays.data
                          ? homeScreenWithDays.data.users
                          : null}
                      </IonText>
                      <IonIcon
                        slot="end"
                        size="small"
                        icon={chevronForward}
                      ></IonIcon>
                    </IonItem>
                    {/* <IonCardHeader>
                                            <IonText className="dashboard-fnt-sz">Your Customers</IonText>
                                            <IonCardTitle className="fnt-sz28">{homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data ? homeScreenWithDays.data.users : null}</IonCardTitle>

                                        </IonCardHeader> */}
                  </IonCard>
                </IonCol>
                <IonCol>
                  <IonCard className="ion-margin-end ion-no-padding">
                    <IonItem lines="none">
                      <IonText color="secondary">Visitors</IonText>
                      <IonIcon
                        slot="end"
                        color="primary"
                        icon={personOutline}
                      ></IonIcon>
                    </IonItem>
                    <IonItem lines="none">
                      <IonText className="fnt-sz28 dashboard-big-number">
                        {homeScreenWithDaysLoading === false &&
                        homeScreenWithDays &&
                        homeScreenWithDays.data
                          ? homeScreenWithDays.data.visits
                          : null}
                      </IonText>
                      <IonIcon
                        slot="end"
                        size="small"
                        icon={chevronForward}
                      ></IonIcon>
                    </IonItem>
                    {/* <IonCardHeader>
                                            <IonText className="dashboard-fnt-sz">Your Customers</IonText>
                                            <IonCardTitle className="fnt-sz28">{homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data ? homeScreenWithDays.data.users : null}</IonCardTitle>

                                        </IonCardHeader> */}
                  </IonCard>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <IonCard
                    className="ion-margin-end ion-no-padding"
                    routerLink="/orders"
                  >
                    <IonItem lines="none">
                      <IonText color="secondary">Your Orders</IonText>
                      <IonIcon
                        slot="end"
                        color="primary"
                        icon={albumsOutline}
                      ></IonIcon>
                    </IonItem>
                    <IonItem lines="none">
                      <IonText className="fnt-sz28 dashboard-big-number">
                        {homeScreenWithDaysLoading === false &&
                        homeScreenWithDays &&
                        homeScreenWithDays.data
                          ? homeScreenWithDays.data.orders
                          : null}
                      </IonText>
                      <IonIcon
                        slot="end"
                        size="small"
                        icon={chevronForward}
                      ></IonIcon>
                    </IonItem>
                  </IonCard>
                </IonCol>
                <IonCol>
                  <IonCard className="ion-margin-end ion-no-padding">
                    <IonItem lines="none">
                      <IonText color="secondary">Sales</IonText>
                      <IonIcon
                        slot="end"
                        color="primary"
                        icon={pricetagsOutline}
                      ></IonIcon>
                    </IonItem>
                    <IonItem lines="none">
                      <IonText className="fnt-sz28 dashboard-big-number">
                        {homeScreenWithDaysLoading === false &&
                        homeScreenWithDays &&
                        homeScreenWithDays.data
                          ? homeScreenWithDays.data.sales
                          : null}
                      </IonText>
                      <IonIcon
                        slot="end"
                        size="small"
                        icon={chevronForward}
                      ></IonIcon>
                    </IonItem>
                  </IonCard>
                </IonCol>
                <IonCol>
                  <IonCard
                    className="ion-margin-end ion-no-padding"
                    onClick={openLink}
                  >
                    <div className="ion-margin-vertical text-center">
                      <BrowserView>
                        <IonTitle
                          color="secondary"
                          className="ion-margin-vertical text-center"
                          style={{
                            marginTop: "40px",
                            whiteSpace: "normal",
                            overflow: "visible",
                          }}
                        >
                          {homeScreenWithDaysLoading === false &&
                          homeScreenWithDays &&
                          homeScreenWithDays.data
                            ? homeScreenWithDays.data.message
                            : null}
                        </IonTitle>
                      </BrowserView>
                      <MobileView>
                        {isMobile && (
                          <IonText
                            color="secondary"
                            className="ion-margin-vertical text-center"
                            style={{
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "15%",
                              height: "100%",
                              fontWeight: "bold",
                            }}
                          >
                            {homeScreenWithDaysLoading === false &&
                            homeScreenWithDays &&
                            homeScreenWithDays.data
                              ? homeScreenWithDays.data.message
                              : null}
                          </IonText>
                        )}
                      </MobileView>
                    </div>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
          {/* <IonCol className="ion-no-padding ion-no-margin">
            {homeScreen.data && homeScreen.data.touch_target && (
              <IonCard
                className="dashboard-intelligence-card ion-no-padding ion-margin-end ion-text-center"
                routerLink={`${map[homeScreen.data.touch_target]}`}
              >
                <IonCardContent>
                  <IonText color="secondary">
                    <h3>
                      {currentUser && currentUser.data && currentUser.data
                        ? `Hello, ${currentUser.data.first_name || "there"} 🙂`
                        : ""}{" "}
                    </h3>
                  </IonText>
                  <IonImg className="ion-margin-top aiimage" src={AiImage} />
                  <IonText color="secondary">
                    <h2>
                      <b>
                        {homeScreenLoading === false &&
                        homeScreen &&
                        homeScreen.data
                          ? homeScreen.data.heading
                          : ""}
                      </b>
                    </h2>
                  </IonText>
                </IonCardContent>
                <IonItem lines="none" className="dashboard-ai-line">
                  <IonIcon
                    slot="end"
                    size="small"
                    icon={chevronForward}
                  ></IonIcon>
                </IonItem>
              </IonCard>
            )}
          </IonCol> */}
        </IonRow>
      </IonGrid>

      {/* Your website/app link component*/}
      <IonGrid className="dashboard-hr-scroll">
        <IonRow>
          <IonCol>
            <IonCard className="ion-no-margin ion-no-padding">
              <IonItem lines="none">
                <IonText color="secondary">
                  &nbsp;Your Business Website <br />
                </IonText>
                <IonButton
                  fill="clear"
                  slot="end"
                  href={
                    homeScreenWithDaysLoading === false &&
                    homeScreenWithDays &&
                    homeScreenWithDays.data &&
                    homeScreenWithDays.data.website
                      ? homeScreenWithDays.data.website.URL
                      : " "
                  }
                  target="_blank"
                >
                  <IonIcon
                    color="secondary"
                    slot="icon-only"
                    icon={eyeOutline}
                  ></IonIcon>
                </IonButton>
              </IonItem>
              <IonTitle className="ion-padding-left" size="small">
                <a
                  href={`${
                    homeScreenWithDaysLoading === false &&
                    homeScreenWithDays &&
                    homeScreenWithDays.data &&
                    homeScreenWithDays.data.website
                      ? homeScreenWithDays.data.website.URL
                      : " "
                  }`}
                  target="_blank"
                >
                  {homeScreenWithDaysLoading === false &&
                  homeScreenWithDays &&
                  homeScreenWithDays.data &&
                  homeScreenWithDays.data.website
                    ? homeScreenWithDays.data.website.URL
                    : " "}
                </a>
              </IonTitle>
              <IonItem lines="none">
                <a
                  style={{ textDecoration: "none" }}
                  href={`https://www.facebook.com/sharer/sharer.php?u=${
                    homeScreenWithDaysLoading === false &&
                    homeScreenWithDays &&
                    homeScreenWithDays.data &&
                    homeScreenWithDays.data.website
                      ? homeScreenWithDays.data.website.URL
                      : ""
                  }&t=My business website using Intelikart&quote=Please check out website for my business build using Intelikart.com ${
                    homeScreenWithDaysLoading === false &&
                    homeScreenWithDays &&
                    homeScreenWithDays.data &&
                    homeScreenWithDays.data.website
                      ? homeScreenWithDays.data.website.URL
                      : ""
                  }`}
                  target="_blank"
                >
                  <IonButton
                    className="ion-no-padding ion-margin-end"
                    fill="clear"
                    color="fb"
                  >
                    <IonIcon slot="icon-only" icon={logoFacebook}></IonIcon>
                  </IonButton>
                </a>
                <a
                  style={{ textDecoration: "none" }}
                  href={`https://twitter.com/intent/tweet?url=${
                    homeScreenWithDaysLoading === false &&
                    homeScreenWithDays &&
                    homeScreenWithDays.data &&
                    homeScreenWithDays.data.website
                      ? homeScreenWithDays.data.website.URL
                      : ""
                  }&text=Please check out website for my business build using Intelikart`}
                  target="_blank"
                >
                  <IonButton
                    className="ion-no-padding ion-margin-end"
                    fill="clear"
                    color="twitter"
                  >
                    <IonIcon slot="icon-only" icon={logoTwitter}></IonIcon>
                  </IonButton>
                </a>
                <IonButton
                  slot="end"
                  fill="clear"
                  color="secondary"
                  onClick={(e) => {
                    copyToClipboard(
                      homeScreenWithDaysLoading === false &&
                        homeScreenWithDays &&
                        homeScreenWithDays.data &&
                        homeScreenWithDays.data.website
                        ? homeScreenWithDays.data.website.URL
                        : "Not Available"
                    );
                    setShowWebToast(true);
                  }}
                >
                  <IonIcon slot="icon-only" icon={copyIcon}></IonIcon>
                </IonButton>
                <div slot="end" className="ion-no-padding ion-no-margin">
                  <MobileView>
                    <ShareButtonMobile
                      title="Intelikart Business Website"
                      text={`${
                        homeScreenWithDaysLoading === false &&
                        homeScreenWithDays &&
                        homeScreenWithDays.data &&
                        homeScreenWithDays.data.website
                          ? homeScreenWithDays.data.website.message
                          : "My Business Website"
                      }`}
                      url={`${
                        homeScreenWithDaysLoading === false &&
                        homeScreenWithDays &&
                        homeScreenWithDays.data &&
                        homeScreenWithDays.data.website
                          ? homeScreenWithDays.data.website.URL
                          : " "
                      }`}
                    />
                  </MobileView>
                  <BrowserView>
                    <ShareButtonWeb
                      url={
                        homeScreenWithDaysLoading === false &&
                        homeScreenWithDays &&
                        homeScreenWithDays.data &&
                        homeScreenWithDays.data.website
                          ? homeScreenWithDays.data.website.URL
                          : ""
                      }
                      text={`Please check out website for my business build using Intelikart.com ${
                        homeScreenWithDaysLoading === false &&
                        homeScreenWithDays &&
                        homeScreenWithDays.data &&
                        homeScreenWithDays.data.website
                          ? homeScreenWithDays.data.website.URL
                          : ""
                      }`}
                    />
                  </BrowserView>
                </div>
              </IonItem>
            </IonCard>
          </IonCol>
          {homeScreenWithDays &&
            homeScreenWithDays.data &&
            homeScreenWithDays.data.app &&
            homeScreenWithDays.data.app.URL && (
              <IonCol>
                <IonCard className="ion-no-margin ion-no-padding">
                  <IonItem lines="none">
                    <IonText color="secondary">
                      &nbsp;Your App <br />
                    </IonText>
                    <IonButton
                      fill="clear"
                      slot="end"
                      href={homeScreenWithDays.data.app.URL}
                      target="_blank"
                    >
                      <IonIcon
                        color="secondary"
                        slot="icon-only"
                        icon={eyeOutline}
                      ></IonIcon>
                    </IonButton>
                  </IonItem>
                 
                  <IonTitle className="ion-padding-left" size="small">
  {homeScreenWithDaysLoading === false &&
  homeScreenWithDays &&
  homeScreenWithDays.data &&
  homeScreenWithDays.data.app ? (
    <a
      href={homeScreenWithDays.data.app.URL}
      target="_blank"
    >
      {homeScreenWithDays.data.app.URL.substring(0, 35)}...
    </a>
  ) : (
    " "
  )}
                  </IonTitle>
                  <IonItem lines="none">
                    <a
                      style={{ textDecoration: "none" }}
                      href={`https://www.facebook.com/sharer/sharer.php?u=${
                        homeScreenWithDaysLoading === false &&
                        homeScreenWithDays &&
                        homeScreenWithDays.data &&
                        homeScreenWithDays.data.app
                          ? homeScreenWithDays.data.app.URL
                          : ""
                      }&quote=Please check out App for my business build using Intelikart.com ${
                        homeScreenWithDaysLoading === false &&
                        homeScreenWithDays &&
                        homeScreenWithDays.data &&
                        homeScreenWithDays.data.app
                          ? homeScreenWithDays.data.app.URL
                          : ""
                      }`}
                      target="_blank"
                    >
                      <IonButton
                        className="ion-no-padding ion-margin-end"
                        fill="clear"
                        color="fb"
                      >
                        <IonIcon slot="icon-only" icon={logoFacebook}></IonIcon>
                      </IonButton>
                    </a>
                    <a
                      style={{ textDecoration: "none" }}
                      href={`https://twitter.com/intent/tweet?url=${
                        homeScreenWithDaysLoading === false &&
                        homeScreenWithDays &&
                        homeScreenWithDays.data &&
                        homeScreenWithDays.data.app
                          ? homeScreenWithDays.data.website.app
                          : ""
                      }&text=Please check out App for my business build using Intelikart`}
                      target="_blank"
                    >
                      <IonButton
                        className="ion-no-padding ion-margin-end"
                        fill="clear"
                        color="twitter"
                      >
                        <IonIcon slot="icon-only" icon={logoTwitter}></IonIcon>
                      </IonButton>
                    </a>
                    <IonButton
                      slot="end"
                      fill="clear"
                      color="secondary"
                      onClick={(e) => {
                        copyToClipboard(
                          homeScreenWithDaysLoading === false &&
                            homeScreenWithDays &&
                            homeScreenWithDays.data &&
                            homeScreenWithDays.data.website
                            ? homeScreenWithDays.data.website.URL
                            : "Not Available"
                        );
                        setShowWebToast(true);
                      }}
                    >
                      <IonIcon slot="icon-only" icon={copyIcon}></IonIcon>
                    </IonButton>
                    <div slot="end" className="ion-no-padding ion-no-margin">
                      <MobileView>
                        <ShareButtonMobile
                          title="Intelikart Business Application"
                          text={`${
                            homeScreenWithDaysLoading === false &&
                            homeScreenWithDays &&
                            homeScreenWithDays.data &&
                            homeScreenWithDays.data.app
                              ? homeScreenWithDays.data.app.message
                              : "My Business App"
                          }`}
                          url={`${
                            homeScreenWithDaysLoading === false &&
                            homeScreenWithDays &&
                            homeScreenWithDays.data &&
                            homeScreenWithDays.data.app
                              ? homeScreenWithDays.data.app.URL
                              : ""
                          }`}
                        />
                      </MobileView>
                      <BrowserView>
                        <ShareButtonWeb
                          url={
                            homeScreenWithDaysLoading === false &&
                            homeScreenWithDays &&
                            homeScreenWithDays.data &&
                            homeScreenWithDays.data.app
                              ? homeScreenWithDays.data.app.URL
                              : ""
                          }
                          text={`Please check out website for my App build using Intelikart.com ${
                            homeScreenWithDaysLoading === false &&
                            homeScreenWithDays &&
                            homeScreenWithDays.data &&
                            homeScreenWithDays.data.app
                              ? homeScreenWithDays.data.app.URL
                              : ""
                          }`}
                        />
                      </BrowserView>
                    </div>
                  </IonItem>
                </IonCard>
              </IonCol>
            )}
          {selfDeliveryData?.data?.status === "active" && (
            <IonCol>
              <IonCard className="ion-no-margin ion-no-padding">
                <IonItem lines="none">
                  <IonText color="secondary">
                    &nbsp;Driver App <br />
                  </IonText>
                  <IonButton
                    fill="clear"
                    slot="end"
                    href="https://play.google.com/store/apps/details?id=com.intelikart.deliverydriverapp"
                    target="_blank"
                  >
                    <IonIcon
                      color="secondary"
                      slot="icon-only"
                      icon={eyeOutline}
                    ></IonIcon>
                  </IonButton>
                </IonItem>
                <IonTitle className="ion-padding-left" size="small">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.intelikart.deliverydriverapp"
                    target="_blank"
                  >
                    https://play.google.com/store/apps/details...
                  </a>
                </IonTitle>
                <IonItem lines="none">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`https://www.facebook.com/sharer/sharer.php?u=https://play.google.com/store/apps/details?id=com.intelikart.deliverydriverapp&quote=Please check out Driver app https://play.google.com/store/apps/details?id=com.intelikart.deliverydriverapp`}
                    target="_blank"
                  >
                    <IonButton
                      className="ion-no-padding ion-margin-end"
                      fill="clear"
                      color="fb"
                    >
                      <IonIcon slot="icon-only" icon={logoFacebook}></IonIcon>
                    </IonButton>
                  </a>
                  <a
                    style={{ textDecoration: "none" }}
                    href={`https://twitter.com/intent/tweet?url=https://play.google.com/store/apps/details?id=com.intelikart.deliverydriverapp&text=Please check out Driver app`}
                    target="_blank"
                  >
                    <IonButton
                      className="ion-no-padding ion-margin-end"
                      fill="clear"
                      color="twitter"
                    >
                      <IonIcon slot="icon-only" icon={logoTwitter}></IonIcon>
                    </IonButton>
                  </a>
                  <IonButton
                    slot="end"
                    fill="clear"
                    color="secondary"
                    onClick={(e) => {
                      copyToClipboard(
                        "https://play.google.com/store/apps/details?id=com.intelikart.deliverydriverapp"
                      );
                      setShowAppToast(true);
                    }}
                  >
                    <IonIcon slot="icon-only" icon={copyIcon}></IonIcon>
                  </IonButton>
                  <div slot="end" className="ion-no-padding ion-no-margin">
                    <MobileView>
                      <ShareButtonMobile
                        title="Intelikart Business Website"
                        text="Driver Application"
                        url="https://play.google.com/store/apps/details?id=com.intelikart.deliverydriverapp"
                      />
                    </MobileView>
                    <BrowserView>
                      <ShareButtonWeb
                        url="https://play.google.com/store/apps/details?id=com.intelikart.deliverydriverapp"
                        text={`Please check out Driver app https://play.google.com/store/apps/details?id=com.intelikart.deliverydriverapp`}
                      />
                    </BrowserView>
                  </div>
                </IonItem>
              </IonCard>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>

      <IonGrid className="ion-no-padding ion-no-margin">
        <IonRow className="ion-no-padding ion-no-margin">
          <IonCol>
            <IonGrid className="dashboard-shct-btns">
              <IonRow>
                <IonCol>
                  <IonCard
                    className="ion-margin-end ion-no-padding button"
                    routerLink="/orders"
                  >
                    <IonItem lines="none">
                      <IonText color="secondary">All Orders</IonText>
                      <IonIcon
                        slot="end"
                        size="small"
                        color="primary"
                        icon={cubeOutline}
                      ></IonIcon>
                    </IonItem>
                  </IonCard>
                </IonCol>
                <IonCol>
                  <IonCard
                    className="ion-margin-end ion-no-padding button"
                    routerLink="/marketing/announcements"
                  >
                    <IonItem lines="none">
                      <IonText color="secondary">Send Announcement</IonText>
                      <IonIcon
                        slot="end"
                        size="small"
                        color="primary"
                        icon={mailUnreadOutline}
                      ></IonIcon>
                    </IonItem>
                  </IonCard>
                </IonCol>
                <IonCol>
                  <IonCard
                    className="ion-margin-end ion-no-padding button"
                    routerLink="/coupons"
                  >
                    <IonItem lines="none">
                      <IonText color="secondary">Start a Sale</IonText>
                      <IonIcon
                        slot="end"
                        size="small"
                        color="primary"
                        icon={pricetagsOutline}
                      ></IonIcon>
                    </IonItem>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonCard
                    className="ion-margin-end ion-no-padding button"
                    routerLink="/catalogue/products"
                  >
                    <IonItem lines="none">
                      <IonText color="secondary">Manage Products</IonText>
                      <IonIcon
                        slot="end"
                        size="small"
                        color="primary"
                        icon={addCircleOutline}
                      ></IonIcon>
                    </IonItem>
                  </IonCard>
                </IonCol>
                <IonCol>
                  <IonCard
                    className="ion-margin-end ion-no-padding button pointer-cursor"
                  >
                    <IonItem lines="none" onClick={()=>{window.open('https://calendly.com/iksupport')}}>
                      <IonText color="secondary">Talk with Experts</IonText>
                      <IonIcon
                        slot="end"
                        size="small"
                        color="primary"
                        icon={calendarOutline}
                      ></IonIcon>
                    </IonItem>
                  </IonCard>
                </IonCol>
                <IonCol>
                  {/* <IonCard className="ion-margin-end ion-no-padding button">
                                            <IonItem lines='none'>
                                                <IonText color='secondary'>Request Payments</IonText>
                                                <IonIcon slot='end' size="small" color='primary' icon={cardOutline}  ></IonIcon>
                                            </IonItem> 
                                    </IonCard> */}
                  <IonCard
                    className="ion-margin-end ion-no-padding button"
                    routerLink="/features"
                  >
                    <IonItem lines="none">
                      <IonText color="secondary">Request Features</IonText>
                      <IonIcon
                        slot="end"
                        size="small"
                        color="primary"
                        icon={caretForwardOutline}
                      ></IonIcon>
                    </IonItem>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
          <IonCol size={queryColSize}>
            <IonCard className="dashboard-contact-container">
              <IonText className="ion-margin-top">
                <h5>Do you have any Query?</h5>
              </IonText>
              <IonGrid className="contact-btns-container">
                <IonRow className="ion-no-padding ion-no-margin">
                  <IonCol class="db-btn-brdr">
                    <IonButton
                      className="ion-no-padding"
                      fill="clear"
                      href={`tel:+918448355548`}
                      expand="block"
                    >
                      <IonIcon
                        color="dark"
                        slot="start"
                        icon={callOutline}
                      ></IonIcon>
                      <IonText color="secondary">Talk to us</IonText>
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton
                      className="ion-no-padding"
                      href="https://wa.me/+918448355548"
                      target="_blank"
                      fill="clear"
                      expand="block"
                    >
                      <IonIcon
                        color="whatsapp"
                        slot="start"
                        icon={logoWhatsapp}
                      ></IonIcon>
                      <IonText color="secondary">Chat with us</IonText>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonToast
        isOpen={showWebToast}
        onDidDismiss={() => setShowWebToast(false)}
        message="Website URL copied"
        duration={500}
      />
      <IonToast
        isOpen={showAppToast}
        onDidDismiss={() => setShowAppToast(false)}
        message="App URL copied"
        duration={500}
      />
    </>
  );
};

export default HomeScreen;
