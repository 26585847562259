import React, {useRef, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IonImg, IonSlide, IonSlides } from '@ionic/react';
import { getTipBanners } from '../../actions/dashboardActions';
import { BrowserView, MobileView } from 'react-device-detect';
import { getAccessToken } from '../../services/auth/userLocalStorage';

const TipBanners = () => {
    const mySlides = useRef<HTMLIonSlidesElement>(null);
    const slideOptsBrowser = {
        initialSlide: 0,
        slidesPerView: 2,
        loop: true,
        spaceBetween: 4,
        speed: 500
    };
    const slideOptsMobile = {
        initialSlide: 0, 
        loop: true, 
        speed: 500
    };
    const dispatch = useDispatch();
    const { tipBannersLoading, tipBanners, tipBannersError } = useSelector((state : any)=> state.tipBanners);
    useEffect(()=>{
        (async function() {
            await dispatch(getTipBanners(getAccessToken()));
        })();
        if(tipBannersLoading === false && tipBannersError)
            console.log(tipBannersError);
    },[]);
    function slidesDidLoad() {
        if(mySlides.current){
            mySlides.current.startAutoplay();
        }
    }
    return (<>
            <BrowserView> 
                <IonSlides pager={true} options={slideOptsBrowser} ref={mySlides} onIonSlidesDidLoad={slidesDidLoad} className="ion-no-padding">
                    {tipBannersLoading === false && tipBanners && tipBanners.data ?
                    (tipBanners.data.map((value : any)=>{
                        return (<IonSlide key={value.id}>
                            <IonImg src={value.name}/>
                        </IonSlide>)
                    })):null
                    }
                </IonSlides>
            </BrowserView>
            <MobileView> 
                <IonSlides  pager={true} options={slideOptsMobile} ref={mySlides} onIonSlidesDidLoad={slidesDidLoad} className="ion-no-padding">
                    {tipBannersLoading === false && tipBanners && tipBanners.data ?
                    (tipBanners.data.map((value : any)=>{
                        return (<IonSlide key={value.id}>
                            <IonImg src={value.name}/>
                        </IonSlide>)
                    })):null
                    }
                </IonSlides>
            </MobileView>
        </>
    )
}

export default TipBanners
