import dayjs from 'dayjs'; 

export const formatDate = (date, format) => (
    dayjs(date).format(format)
);

export const formatPrice = (value, format, force) => {
    if (!format) return '';
    if (!value && !force) return value;

    return format.replace(':value', parseFloat(value).toFixed(2));
};