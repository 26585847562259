import {
    IonContent,
    IonHeader,
    IonIcon,
    IonImg,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonModal,
    IonText,
    IonThumbnail,
    IonToolbar,
} from "@ionic/react";
import {
    checkmark as checkIcon,
    closeOutline,
    warning as warningIcon,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useMediaLibrary } from "../../../utils/contexts";
import useAPI from "../../../utils/api";

const MediaLibraryModal = ({
    showModal,
    setShowModal,
    handleImageSelection,
    canSelect,
}) => {
    const mediaLibraryData: any = useMediaLibrary();
    const [checkedImageData, setCheckedImageData] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    const [pageNumber, setPageNumber] = useState(1);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
    const API = useAPI();

    const fetchMediaLibrary = async (page) => {
        mediaLibraryData.setMediaLoading(true);
        try {
            const [ok, response] = await API.getMediaImage(page);
            if (ok) {
                const updatedAllMedia = { ...mediaLibraryData.allMedia };
                updatedAllMedia[page] = response.data;
                mediaLibraryData.setAllMedia(updatedAllMedia);
            }
        } catch (error) {
            console.error(error);
        } finally {
            mediaLibraryData.setMediaLoading(false);
        }
    };
    useEffect(() => {
        if (showModal && !mediaLibraryData.allMedia[1]) {
            fetchMediaLibrary(1);
        }
    }, [showModal, mediaLibraryData.allMedia]);

    const fetchData = () => {
        if (disableInfiniteScroll) return;
        if(mediaLibraryData.allMedia[pageNumber+1]) {
            setPageNumber(pageNumber + 1);
            return;
        }
        fetchMediaLibrary(pageNumber + 1);
        setPageNumber(pageNumber + 1);
    };

    useEffect(() => {
        if (
            mediaLibraryData.allMedia[pageNumber] &&
            mediaLibraryData.allMedia[pageNumber].length > 0
        ) {
            setDisableInfiniteScroll(false);
        } else {
            setDisableInfiniteScroll(true);
        }
    }, [mediaLibraryData.allMedia, pageNumber]);

    return (
        <IonModal isOpen={showModal} onDidDismiss={() => {
            setShowModal(false);
            setCheckedImageData({});
            setShowAlert(false);
        }}>
            <IonHeader>
                <IonToolbar>
                    <IonItem lines="none">
                        <IonText>
                            <h5>Select Image from Media Library</h5>
                        </IonText>
                        {Object.keys(checkedImageData).length > 0 && (
                            <IonIcon
                                slot="end"
                                icon={checkIcon}
                                color="primary"
                                style={{ cursor: "pointer" }}
                                size="large"
                                onClick={() => {
                                    handleImageSelection(checkedImageData);
                                    setCheckedImageData({});
                                    setShowAlert(false);
                                }}
                            />
                        )}
                        <IonIcon
                            slot="end"
                            icon={closeOutline}
                            color="primary"
                            style={{ cursor: "pointer" }}
                            size="large"
                            onClick={() => {
                                setShowModal(false);
                                setCheckedImageData({});
                                setShowAlert(false);
                            }}
                        />
                    </IonItem>
                </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding">
                {showAlert && (
                    <IonText
                        color="danger"
                        className="d-flex ion-margin ion-align-items-center border-danger-alert"
                    >
                        <h2 className="d-inline-block ion-no-margin ion-margin-end">
                            <IonIcon icon={warningIcon} />
                        </h2>
                        Maximum {canSelect} allowed.
                    </IonText>
                )}
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                    }}
                >
                    {mediaLibraryData.mediaLoading &&
                    !mediaLibraryData.allMedia[1] ? (
                        <IonText>Loading...</IonText>
                    ) : Object.keys(mediaLibraryData.allMedia).length !== 0 ? (
                        Object.keys(mediaLibraryData.allMedia).flatMap((page) =>
                            mediaLibraryData.allMedia[page].map(
                                (item, index) => (
                                    <IonThumbnail
                                        key={index}
                                        style={{
                                            height: "125px",
                                            width: "125px",
                                            margin: "6px",
                                            border: checkedImageData[item.id]
                                                ? "2px solid blue"
                                                : "2px solid #ddd",
                                        }}
                                        onClick={() => {
                                            if (
                                                canSelect >
                                                Object.keys(checkedImageData)
                                                    .length
                                            ) {
                                                setShowAlert(false);
                                                setCheckedImageData((prev) => {
                                                    let temp = { ...prev };
                                                    if (temp[item.id]) {
                                                        delete temp[item.id];
                                                    } else {
                                                        temp[item.id] = {
                                                            path: item.path,
                                                            url: item.url,
                                                        };
                                                    }
                                                    return temp;
                                                });
                                            } else {
                                                if (checkedImageData[item.id]) {
                                                    setShowAlert(false);
                                                    setCheckedImageData(
                                                        (prev) => {
                                                            let temp = {
                                                                ...prev,
                                                            };
                                                            delete temp[
                                                                item.id
                                                            ];
                                                            return temp;
                                                        }
                                                    );
                                                } else {
                                                    setShowAlert(true);
                                                }
                                            }
                                        }}
                                    >
                                        <IonImg
                                            src={item.url}
                                            alt="Media"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain",
                                                cursor: "pointer",
                                            }}
                                        />
                                    </IonThumbnail>
                                )
                            )
                        )
                    ) : (
                        <IonText className="ion-padding">
                            No image found
                        </IonText>
                    )}
                </div>

                <IonInfiniteScroll
                    threshold="100px"
                    onIonInfinite={(e) => {
                        fetchData();
                        setTimeout(() => e.target.complete(), 500);
                    }}
                >
                    <IonInfiniteScrollContent loadingText="Loading more..." />
                </IonInfiniteScroll>
            </IonContent>
        </IonModal>
    );
};

export default MediaLibraryModal;