import { Camera, CameraResultType } from '@capacitor/camera';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToggle,
  IonToolbar,
  isPlatform,
  useIonAlert,
  useIonToast
} from '@ionic/react';
import {
  arrowBack as arrowBackIcon, informationCircleOutline,
  trash as trashIcon ,caretDown as caretDownIcon
} from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import MediaLibraryModal from "../dashboard/product/components/MediaLibraryModal";
import useAPI from '../utils/api';
import { useCategory } from '../utils/contexts';
import { alphaNumericValidation, atleastOneCharValidation } from '../utils/validations';
import {
    calendar as calenderIcon
} from 'ionicons/icons';
interface RouteParams {
    id: string;
    name: string;
    fp: string;
}

const AddUpdateCategory = (props: any) => {
    const { id } = useParams<RouteParams>();
    const { name } = useParams<RouteParams>();
    const { fp } = useParams<RouteParams>();
    const [showModal, setShowModal] = useState(false);
    const [categoryStatus, setCategoryStatus] = useState("active");
    const [imageClicked, setImageClicked] = useState<any>();
    const [showButton, setShowButton] = useState(true);
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state: any) => state.currentUser);

    const [categoryName, setCategoryName] = useState('');
    const [isNameValidate, setIsNameValidate] = useState(true);

    const [pageName, setPageName] = useState({
        first: "Add",
        second: "Category"
    });
    const [iconCode, setIconCode] = useState('');
    const [pictureUrl, setPictureUrl] = useState('');
    const [platformPath, setPlatformPath] =useState<string | null>(null);
    const [imageFile, setImageFile] = useState(null);
    const fileInputRef = useRef<HTMLInputElement>();
  const [loading, setLoading] = useState(false);
  const [dateValidate, setdateValidate] = useState("");
  const [effectiveDate, setEffectiveDate] = useState(false);
const [eventDate, setEventDate] = useState(null);

    
  
  const history = useHistory();
  const location: any = useLocation();
  const API = useAPI();
    const [present] = useIonToast();
    const [alertPresent] = useIonAlert();
    const categoryData: any = useCategory();

    useEffect(() => {
        if (location.pathname.includes("add")) {
            if (location.pathname.includes("add-cat")) {
                setPageName({
                    first: "Add",
                    second: "Category"
                });
            } else if (location.pathname.includes("add-subcat")) {
                setPageName({
                    first: "Add",
                    second: "Subcategory"
                });
            }
        } else if (location.pathname.includes("update")) {
            if (location.pathname.includes("update-cat")) {
                setPageName({
                    first: "Update",
                    second: "Category"
                });
            } else if (location.pathname.includes("update-subcat")) {
                setPageName({
                    first: "Update",
                    second: "Subcategory"
                });
            }

            if (location.state?.name) {
              
              setCategoryName(location.state?.name)
            } else {
              setCategoryName('')
              
            }
          if (location.state?.icon) {
              
            setPictureUrl(location.state?.icon)
            } else {
            setPictureUrl('')
              
            }
          if (location.state) {
                
                setIconCode(location.state?.icon_code)
                setCategoryStatus(location.state.status)
              setEventDate(location.state?.schedule_date ? new Date(location.state.schedule_date).toISOString() : null)
            } else {
                history.push('/catalogue/categories')
          }
         
      }
      return () => {
        // Cleanup function to clear the state
        setCategoryName('');
        setEffectiveDate(false);
        setPictureUrl('');
        setShowButton(true);
        setIconCode('')
        setIsNameValidate(true) 
      }
    }, [location.pathname,location.state]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files.length > 0) {
            const file = event.target.files.item(0);
            setImageFile(file);
            const pictureUrl = URL.createObjectURL(file);
            setPictureUrl(pictureUrl);
            setShowButton(false);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }
    };
   
    const handlePictureClick = async () => {
        if (isPlatform('capacitor')) {
            try {
                const photo = await Camera.getPhoto({
                    resultType: CameraResultType.Uri,
                })
                setPictureUrl(photo.webPath);
                const file = await fetch(photo.webPath).then((r) =>
                    r.blob()
                );
                setImageFile(file);
            }
            catch (error) {
                console.log(error);
            }
        }
        else {
            fileInputRef.current.click();
        }
    }
    const handleImageSelection = (checkedImageData) => {
      const dataArray:any = Object.values(checkedImageData);
      setPictureUrl(dataArray[0].url);
      setPlatformPath(dataArray[0].path);
      setShowModal(false);
      setShowButton(false);
  };
  
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  let currentDate = formatDate(new Date());

  let userSelectedDate = eventDate===null? null :formatDate(new Date(eventDate));

  const dateValidation = () => {
    if (eventDate === null) {
      return false;
    }
   

    if (userSelectedDate < currentDate) { 
          setdateValidate("Selected dates are not valid.");
          return false;
        }
    if (userSelectedDate === currentDate) { 
          setdateValidate("Selected dates and current date is same and acceptable");
          return true;
        }
     return true;
    }

      const mediaClick= async ()=>{
        setShowModal(true);
        setImageClicked(0);
      }
         
         useEffect(() => {
            setShowButton(!pictureUrl);
        }, [pictureUrl]);
        
  const handleSave = async () => {
        if (pageName.second !== "Category" && (!imageFile && !pictureUrl)) {
            present("Image is mandatory for child categories", 2000)          
        }
        else {
            setLoading(true);
            let imageRes: any;
            if (imageFile) {
                imageRes = await API.uploadImage(imageFile, "category-image", imageFile.name || 'subCategoryImg.jpg'); 
            }     
            let res = true
            if(!alphaNumericValidation(categoryName) || atleastOneCharValidation(categoryName)){
                res = false;
                setIsNameValidate(false);
          }
          const isDateValid = eventDate=== null ? true : dateValidation();
           if (!isDateValid) {
            present("Selected date is not valid. Please select current or future date.", 2000);
            setLoading(false);

            return;
          }
          
            if (((pageName.second === "Category") || (platformPath) ||(imageRes && imageRes[0]  && imageRes[1]?.platform_path) || (pageName.first === "Update" && iconCode)) && res ) {
                const [createOk, createResponse] = pageName.first === "Add" ? await API.createCategory({
                    "id": 0,
                    "parent_id": pageName.second.startsWith('Sub') ? id : 0,
                    "name": categoryName,
                    "priority": '',
                    "icon_code": (imageRes && imageRes[1]?.platform_path) || platformPath  || null,
                  "status": categoryStatus,
                  'schedule_date': eventDate ||null,
                }) : await API.updateCategory({
                    "id": id,
                    "name": categoryName,
                    "priority": '',
                    "icon_code": (imageRes && imageRes[1]?.platform_path) || platformPath || iconCode || null,
                  "status": categoryStatus,
                  'schedule_date':eventDate||null,

                });
                if (createOk && createResponse) {
                    setCategoryName("");
                    setPictureUrl('');
                    setImageFile(null);
                    setLoading(false); 
                    setPlatformPath(null);  
                    fileInputRef.current.value = null;
                    categoryData.setUpdatedCategoryData(!categoryData.updatedCategoryData);

                    if (pageName.first === "Add" && pageName.second === "Category") {
                        history.push({ pathname: `/catalogue/category/add-subcat/${createResponse?.data?.id}/${createResponse?.data?.name}/${fp}`, state: { first: "Add", second: "Subcategory" } });
                    } else if (pageName.first === "Update") {
                        history.goBack();
                    } else {
                        if (fp === 'false') {
                            history.push('/catalogue/categories');
                        } else if (fp === 'true') {
                            history.push({
                                pathname: '/catalogue/products/create/true',
                                state: { id: createResponse?.data?.id, name: createResponse?.data?.name }
                            });
                        }
                    }
                } else {
                    if (!createOk) {
                        present(createResponse.message || "Some error occured!", 2000)
                    }
                }
            }
            setLoading(false);
        }
    }

    const onClickDeleteCategory = async () => {
        const category: any = location.state;
        if (category.subCategory[0]) {
            present("Please delete sub categories first", 2000);
        }
        else {
            setLoading(true)
            const [ok, response] = await API.deleteCategory(category.id);
            if (ok && response) {
                present("Category deleted successful.", 2000);
                setLoading(false);
                categoryData.setUpdatedCategoryData(!categoryData.updatedCategoryData);
                history.goBack();
            } else {
                if (!ok) {
                    present("There are products associated with this category, please delete them first.", 2000)
                }
            }
            setLoading(false);        
        }
    }
    
    const deleteImage = () => {
      setPictureUrl('');
      setPlatformPath('');
      setShowButton(true);
      setImageFile(null);
  };  

    return (
      <IonPage>
        <IonHeader style={{ height: '68px' }}>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton
                defaultHref="/catalogue/categories"
                icon={arrowBackIcon}
              />
            </IonButtons>
            <MobileView>
              <IonText className="ion-no-margin">{`${pageName.first} ${pageName.second}`}</IonText>
            </MobileView>
            <BrowserView>
              <IonText className="ion-no-margin">
                <h5>{`${pageName.first} ${pageName.second}`}</h5>
              </IonText>
            </BrowserView>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {pageName.second.startsWith("Sub") && pageName.first === "Add" && (
            <div className="ion-text-center ion-padding">
              <IonText>
                Add Subcategory in{" "}
                <span style={{ color: "#3880ff" }}>{name}</span>
              </IonText>
            </div>
          )}
          
          
          <IonRow className="ion-justify-content-center">
          
          <IonCol size="13" size-md="6" className="ion-margin-horizontal">
          <IonCard className="ion-padding" >
          <IonItem lines="inset">
            <IonLabel position="stacked" className="ion-padding-bottom ">
              <h1 className='label'>{`${pageName.second} Name`}</h1></IonLabel>  
            <IonInput
              required
              value={categoryName}
              autofocus={pageName.first === "Add"}
              placeholder={`Add ${pageName.second} Example Organic Fruits`}
              onIonChange={(event) => setCategoryName(event.detail.value)}
            />
          </IonItem>
          <IonText color="danger">
            <h5
              className={`error-text ion-margin-horizontal ${
                isNameValidate ? "ion-hide" : ""
              }`}
            >
              {" "}
              {`${pageName.second} should have atleast one letter and should not contain any special character.`}{" "}
            </h5>
          </IonText>
          <IonItem>
                    <IonLabel>Category Status</IonLabel>
                    <IonSelect
                        
                        value={categoryStatus}
                        onIonChange={(e) => setCategoryStatus(e.detail.value)} className="marginright color"
                    >
                        <IonSelectOption value="active">Active</IonSelectOption>
                        <IonSelectOption value="inactive">Inactive</IonSelectOption>
                    </IonSelect>

                    <IonIcon slot="end" size="small" color="primary" icon={caretDownIcon}></IonIcon>

                </IonItem>
                <IonItem>
                  <IonGrid>
                    <IonRow>
                      <IonCol>

                      
                  <IonCard>
                    <IonItem>
                      <IonLabel>Effective date</IonLabel>
                      <IonToggle
                        checked={effectiveDate}
                        onIonChange={(e) => {
                          if (e.detail.checked) {
                            // Handle toggle ON
                            setEffectiveDate(true);
                            // You can also set a default event date here if needed
                          } else {
                            // Handle toggle OFF
                            setEffectiveDate(false);
                            setEventDate(null);
                          }
                        }}
                        />
                    </IonItem>
                  </IonCard>
                        </IonCol>
                    </IonRow>
                    {effectiveDate && <IonRow>
                      <IonCol>
                        <IonCard>
                          <IonItem className="ion-no-padding">
                            <IonIcon className="ion-no-margin" icon={calenderIcon} slot="end" />
                            <IonDatetimeButton datetime="eventDate"></IonDatetimeButton>
                            <IonModal keepContentsMounted={true}>
                              <IonDatetime
                                style={{ color: "black" }}
                                id="eventDate"
                                value={eventDate ? new Date(eventDate).toISOString() : null}
                                presentation="date"
                                min={new Date().toISOString().split('T')[0]}
                                onIonChange={(e) => {
                                  let selectDate = `${(e.target.value)}`
                                  setEventDate(formatDate(selectDate))
                                }}
                              />
                            </IonModal>
                          </IonItem>
                        </IonCard>
                    </IonCol>
                    </IonRow>}
                        {!eventDate && <p style={{ color: 'blue', paddingLeft: '16px' }}>no date is selected</p>}
                        {eventDate && <p style={{ color: 'green', paddingLeft: '16px' }}>your selected date {eventDate ? formatDate(eventDate.toString()) : ''}</p>}
                        </IonGrid>
                  </IonItem>
                

              
          <IonText
            style={{ display: "inline-flex", gap: "10px" }} 
            className="ion-padding"
          >
            <IonIcon 
            icon={informationCircleOutline}
              style={{ fontSize: "22px" , color:"black"}}
            />
            
           <div style={{fontSize:"15px" , color:"black"}}>
             For Best View, Use 500X500px image
             </div>
          </IonText>
          <input
            hidden
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleFileChange}
          />

          {pageName.first === "Add" ? (
            <IonItem lines="none" >
               <IonLabel
                className="ion-padding-bottom"
                position="stacked"
                 
              >
                <h1 className='label'>{`${pageName.second} Image`}</h1></IonLabel>
              <div>
                {pictureUrl && (
                  <>
                  <IonCard className="category-image-card"> 
                    <img
                    width= "100%"
                    height={ 
                     pictureUrl ==="/assets/placeholder.svg"
                     ? "100%"
                     : "78%"
                    }
                    src={pictureUrl}
                    />
                    <IonButton
                      className="ion-no-margin"
                      expand="full"
                      color="danger"
                      onClick={deleteImage}
                    >
                      <IonIcon icon={trashIcon} slot='icon-only' />
                    </IonButton>
                    </IonCard>
                  </>
                )}  
              </div>
              
              
              <div style={{ margin: "10px 0 0 0" }}>
                {showButton && (
                  <>
                    <IonButton
                      className="ion-no-margin"
                      onClick={(e) => {
                        handlePictureClick();
                        setImageClicked(0);
                      }}
                    >
                      Upload Image
                    </IonButton>
                    <br></br>
                    <IonButton
                      className="ion-margin-top"
                      onClick={() => mediaClick()}
                    >
                      Media Library
                    </IonButton>
                  </>
                )}
              </div>
            </IonItem>
          ) : (
            <div className="category-image-container ion-justify-content-start">
            <IonItem lines="none">
                {/* for update media library */}   
                <div>
                  
                  {pictureUrl && (
                    <>
                    <IonCard className="category-image-card">
                      <img
                    width= "100%"
                    height={ 
                     pictureUrl ==="/assets/placeholder.svg"
                    ? "100%"
                    : "78%"
                    }
                    src={pictureUrl}
                    />
                      <IonButton
                        className="ion-no-margin"
                         expand="full"
                        color="danger"
                        onClick={deleteImage} >
                        <IonIcon icon={trashIcon}  />
                      </IonButton>
                      </IonCard>
                    </>
                  )}
             </div>              
                <div>
                  {showButton && (
                    <>
                      <IonButton
                        className="ion-no-margin"
                        onClick={(e) => {
                          handlePictureClick();
                          setImageClicked(0);
                        }}
                      >
                        Update Image
                      </IonButton>
                      <br></br>
                      <IonButton
                        className="ion-margin-top"
                        onClick={() => mediaClick()}
                      >
                        Media Library
                      </IonButton>
                    </>
                  )}
                </div>  
            </IonItem>
            </div>
          )}
          
          
          
          <div className="ion-text-end ion-padding-top">
            {pageName.first === "Update" && (
              <IonButton
                className="ion-padding-horizontal"
                onClick={() =>
                  alertPresent({
                    cssClass: "my-css",
                    header: pageName.second === "Subcategory" ? "Delete Subcategory" :"Delete Category",
                    message: `Are you sure about deleting this ${pageName.second === "Subcategory" ? "subcategory" :"category"}?`,
                    buttons: [
                      "Cancel",
                      { text: "Yes", handler: (d) => onClickDeleteCategory() },
                    ],
                  })
                }
                shape="round"
                color="danger"
              >
                Delete
              </IonButton>
            )}
            <IonButton
              shape="round"
              disabled={categoryName === "" ? true : false}
              onClick={handleSave}
            >
              {" "}
              Save
            </IonButton>
          </div>
          </IonCard>
          </IonCol>
          
          </IonRow>
          
          {pageName.first === "Update" && pageName.second === "Subcategory" && (
            <div className="ion-text-center ion-margin">
              <Link
                to={{
                  pathname: "/catalogue/products",
                  state: { id: id, name: categoryName },
                }}
                style={{ textDecoration: "none" }}
              >
                <IonButton expand="block" shape="round">
                  View All Products
                </IonButton>
              </Link>
            </div>
          )}
          <IonLoading isOpen={loading} message={"Loading..."} />
          <MediaLibraryModal
            showModal={showModal}
            setShowModal={setShowModal}
            handleImageSelection={handleImageSelection}
            canSelect={1}
          />
        </IonContent>
      </IonPage>
    );
};

export default AddUpdateCategory;