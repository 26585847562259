import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonText,
    IonTitle,
    IonToggle,
    IonToolbar,
    useIonToast,
} from "@ionic/react";
import { useState } from "react";
import {
    arrowBack as arrowBackIcon,
    informationCircleOutline,
} from "ionicons/icons";
import { useAddon } from "../../../utils/contexts";
import useAPI from "../../../utils/api";
import { indexedValue } from "../AddonsRoutes";
import { alphaNumericValidation } from "../../../utils/validations";
import FeatHeader from '../../../components/dashboard/FeatHeader';


const TawkForm = () => {
    const addons: any = useAddon();
    const [present] = useIonToast();
    const API = useAPI();
    const [propertyIdValidation, setPropertyIdValidation] = useState("");
    const [widgetIdValidation, setWidgetIdValidation] = useState("");
    const [propertyId, setPropertyId] = useState(
        Array.isArray(addons[indexedValue.tawk].data)
            ? ""
            : addons[indexedValue.tawk].data?.property_id
    );
    const [widgetId, setWidgetId] = useState(
        Array.isArray(addons[indexedValue.tawk].data)
            ? ""
            : addons[indexedValue.tawk].data?.widget_id
    );
    const [tawkCheck, setTawkCheck] = useState(
        Array.isArray(addons[indexedValue.tawk].data)
            ? false
            : addons[indexedValue.tawk].data?.status === "active"
    );

    const validation = () => {
        let result = true;

        if (!propertyId) {
            setPropertyIdValidation("Tawk Property Id is required");
            result = false;
        } else {
            if (!alphaNumericValidation(propertyId)) {
                setPropertyIdValidation(
                    "Tawk Property Id should not contain any special characters"
                );
                result = false;
            } else {
                setPropertyIdValidation("");
            }
        }

        if (!widgetId) {
            setWidgetIdValidation("Tawk Widget Id is required");
            result = false;
        } else {
            if (!alphaNumericValidation(widgetId)) {
                setWidgetIdValidation(
                    "Tawk Widget Id should not contain any special characters"
                );
                result = false;
            } else {
                setWidgetIdValidation("");
            }
        }

        return result;
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();

        if (validation()) {
            const [ok, response] = Array.isArray(addons[indexedValue.tawk].data)
                ? await API.createTawkAddon({
                      property_id: propertyId,
                      widget_id: widgetId,
                      status: tawkCheck ? "active" : "inactive",
                  })
                : await API.updateTawkAddon({
                      property_id: propertyId,
                      widget_id: widgetId,
                      status: tawkCheck ? "active" : "inactive",
                      id: addons[indexedValue.tawk].data.id,
                  });
            if (ok) {
                present("Data saved successfully!", 1000);
                addons[indexedValue.tawk].updateMethod(
                    !addons[indexedValue.tawk].update
                );
            } else {
                present(response.message, 1000);
            }
        }
    };

    const openLinkInNewTab = () => {
        window.open(`https://intelikart.tawk.help/article/tawkto`, "_blank");
    };

    return (<IonPage>
       
        <FeatHeader title='Configure Tawk' defaultHref="/add-ons" showInfoButton={true} onInfoClick={ openLinkInNewTab} />
        <IonContent className="ion-padding">
            <form onSubmit={onSubmitHandler}>
                <IonItem>
                    <IonLabel position="stacked"><h1>Tawk Property Id</h1></IonLabel>
                    <IonInput placeholder="Enter Property Id" type="text" value={propertyId} onIonChange={(e) => { setPropertyId(e.detail.value) }} />
                </IonItem>
                <IonText color="danger" className={`${propertyIdValidation ? "" : "ion-hide"}`}>
                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px', marginBottom: 0 + 'px' }} className='ion-margin-horizontal'>{propertyIdValidation}</h5>
                </IonText>
                <br />
                <IonItem>
                    <IonLabel position="stacked"><h1>Tawk Widget Id</h1></IonLabel>
                    <IonInput placeholder="Enter Widget Id" type="text" value={widgetId} onIonChange={(e) => { setWidgetId(e.detail.value) }} />
                </IonItem>
                <IonText color="danger" className={`${widgetIdValidation ? "" : "ion-hide"}`}>
                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px', marginBottom: 0 + 'px' }} className='ion-margin-horizontal'>{widgetIdValidation}</h5>
                </IonText>
                <br />
                <IonItem lines="none">
                    <IonText><h5>Enable Tawk</h5></IonText>
                    <IonToggle slot="end" checked={tawkCheck} onIonChange={e => setTawkCheck(e.detail.checked)} />
                </IonItem>
                <br />
                <div className="ion-text-center">
                    <IonButton type="submit">Save</IonButton>
                </div>
            </form>

                {/* Tawk Partner Badge */}
                <div
                    style={{ position: "fixed", bottom: "10px", right: "10px" }}
                >
                    <a href="https://www.tawk.to/?pid=ojehep1" target="_blank">
                        <img
                            src="https://partners.tawk.to/badges/partner.png"
                            width="150"
                        />
                    </a>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default TawkForm;
