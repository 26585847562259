import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonLoading,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    useIonToast
} from "@ionic/react";
import {
    arrowBack as arrowBackIcon,
    caretDown as caretDownIcon
} from "ionicons/icons";
import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useHistory, useLocation, useParams } from "react-router";
import useAPI from "../../utils/api";
import { useBrands, useCategory, useSharedData, useUser } from "../../utils/contexts";
import useFetch from "../../utils/useFetch";
import { atleastOneCharValidation } from "../../utils/validations";
import BrandModal from "./components/BrandModal";
import CategoryModal from "./components/CategoryModal";
import DigitalFiles from "./components/DigitalFiles";
import DynamicProductFields from "./components/DynamicProductFields";
import ProductDetailForm from "./components/ProductDetailForm";
import ProductImages from "./components/ProductImages";
import ProductLevelTaxes from "./components/ProductLevelTaxes";
import ProductMetaTags from "./components/ProductMetaTags";
import VariantForm from "./components/VariantForm";
import VariantTable from "./components/VariantTable";
import ComplementaryProduct from "./components/ComplementaryProduct";

const AddProduct = () => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [skuId, setSkuId] = useState("");
    const [qtyUnit, setQtyUnit] = useState("");
    const [video, setVideo] = useState("");
    const [sellingPrice, setSellingPrice] = useState(null);
    const [mrp, setMrp] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [minQty, setMinQty] = useState<any>(null);
    const [maxQty, setMaxQty] = useState<any>(null);
    const [avlQty, setAvlQty] = useState<any>(null);

    const location: any = useLocation();

    const [validation, setValidation] = useState({
        category: "",
        name: "",
        sellingPrice: "",
        Mrp: "",
        quantity: "",
        qtyUnit: "",
        description: "",
        maxQty: "",
        minQty: "",
        avlQty: "",
    });
    const [numberOfOptionValue, setNumberOfOptionValue] = useState([[1]]);
    const [pictureUrl, setPictureUrl] = useState([
        "/assets/placeholder.svg",
        "/assets/placeholder.svg",
        "/assets/placeholder.svg",
        "/assets/placeholder.svg",
    ]);
    const [digitalProductUrl, setDigitalProductUrl] = useState([
        "/assets/upload.svg",
        "/assets/upload.svg",
        "/assets/upload.svg",
        "/assets/upload.svg",
    ]);
    const [category, setCategory] = useState<any>();
    const [categoryId, setCategoryId] = useState<any>();
    const [productStatus, setProductStatus] = useState("active");
    const { fp } = useParams<any>();
    const [present] = useIonToast();
    const API = useAPI();
    const sharedData: any = useSharedData();
    const currentUser: any = useUser();
    const updateCategoryData: any = useCategory();
    const updateBrandsData: any=useBrands();
    const [brand, setBrand] = useState<any>();
    const [brandId, setBrandId] = useState<any>();
   const[showBrandModel,setShowBrandModel]=useState([]);


    const [isVariantChecked, setIsVariantChecked] = useState<any>();
    const [removedOptionsList, setRemovedOptionsList] = useState<any>([]);
    const [metaTagData, setMetaTagData] = useState<any>({
        metaTitle: "",
        metaDescription: "",
        metaKeyword: ""
    });
    const [isDuplicateValue, setIsDuplicateValue] = useState([
        false,
        false,
        false,
    ]);
    const { data } = useFetch("inventoryAddon", sharedData.inventoryUpdate);
    const { data: productTaxData } = useFetch("productTaxAddon", sharedData.productLevelTaxUpdate);
    const { data: dynamicFieldsData } = useFetch("dynamicFieldsAddon", sharedData.dynamicFieldsUpdate);
    const { data: complementaryProductData } = useFetch("complementaryProductAddon", sharedData.complementaryProductUpdate);

    //Fields used for taxes data.
    const [taxData, setTaxData] = useState([]);
    const [fieldsData, setFieldsData] = useState([]);
    const [complementaryData, setComplementaryData] = useState([]);

    // Digital Product 
    const {data:digitalProductData} = useFetch("getDigitalProductAddon", sharedData.digitalProductUpdate);
    const [productType, setProductType] = useState('PHYSICAL');
    const handleProductChange = (event) => {
        setProductType(event.detail.value);
    };
    const [digitalProductPaths, setDigitalProductPaths] = useState<any>([]);
    const [digitalValidation, setDigitalValidation] = useState("");

    //To save image data(index is start from 0)
    const [platformPaths, setPlatformPaths] = useState<any>([]);
    const [imageValidation, setImageValidation] = useState("");

    const [tableData, setTableData] = useState({});
    const [variantValidation, setVariantValidation] = useState({
        name: '',
        value: '',
        valuesArray: ''
    })
    const [tableValidation, setTableValidation] = useState({
        mrp: '',
        selllingPrice: '',
        avlQty: '',
        greater: ''
    })
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        let productData = location?.state?.productData;
        productData?.product_type==="DIGITAL"?setProductType("DIGITAL"):setProductType("PHYSICAL");
        if (productData) {
                setName(productData.name);
                setDescription(!!productData.desc_html && productData.desc_html !== "undefined" ? productData.desc_html : '');
                setSellingPrice(productData.variants[0].mrp);
                setMrp(productData.variants[0].display_mrp);
                setSkuId(productData.sku || "");
                setQuantity(productData.variants[0].quantity);
                setQtyUnit(productData.variants[0].quantity_unit_name);
                setMinQty(productData.variants[0].min_order_quantity);
                setMaxQty(productData.variants[0].max_order_quantity);
                setAvlQty(productData.variants[0].available_quantity);
                setVideo(productData.variants[0].videos[0]?.youtube_url);
            
            setCategory({ id: productData.category_id, name: productData.category_name });
            setProductStatus(productData.status);
            setCategoryId(productData.category_id);
            if (productData.brands) {
                setBrand({ id: productData.brands.id, name: productData.brands.name });
                setBrandId(productData.brands.id);
              } else {
                 
                setBrand(null);  
                setBrandId(null);  
              }
            let imageArray = [...pictureUrl];
            let imagePathArray = [...platformPaths];
            for (let i = 0; i < productData.variants[0].images.length; i++) {
                imageArray[i] = productData.variants[0].images[i].image_url_original;
                imagePathArray[i] = (productData.variants[0].images[i].image_url_original);
            }
            setPlatformPaths(imagePathArray);
            setPictureUrl(imageArray);
            let digitalProductArray = [...digitalProductUrl];
            let digitalProductPathArray = [...digitalProductPaths];
            for (let i = 0; i < productData.variants[0].digital_product.length; i++) {
                digitalProductArray[i] = productData.variants[0].digital_product[i].digital_product_url;
                digitalProductPathArray[i] = (productData.variants[0].digital_product[i].digital_product_url);
            }
            setDigitalProductPaths(digitalProductPathArray);
            setDigitalProductUrl(digitalProductArray);
            if (productData.attributes !== null || productData.variants.length > 1) {
                setIsVariantChecked(true);
                let numberOfValue = [], optionsData = [];
                productData.attributes.forEach((currValue) => {
                    let t1 = [];
                    let t2 = [1];
                    currValue.values.forEach((val) => {
                        t1.push(`${val.value}`);
                        t2.push(1);
                    });
                    numberOfValue.push(t2);
                    optionsData.push({
                        name: currValue.name,
                        value: t1
                    });
                });
                setOptionsData(optionsData);
                setNumberOfOptionValue(numberOfValue);
                let tableDataTemp = {...tableData};
                productData.variants.forEach((currentValue) => {
                    let optionListName;
                    if (currentValue.attribute_value_mappings.length === 1) {
                        optionListName = `${currentValue.attribute_value_mappings[0].attribute_value}`;
                    } else if (currentValue.attribute_value_mappings.length === 2) {
                        optionListName = `${currentValue.attribute_value_mappings[0].attribute_value} / ${currentValue.attribute_value_mappings[1].attribute_value}`;
                    } else if (currentValue.attribute_value_mappings.length === 3) {
                        optionListName = `${currentValue.attribute_value_mappings[0].attribute_value} / ${currentValue.attribute_value_mappings[1].attribute_value} / ${currentValue.attribute_value_mappings[2].attribute_value}`;
                    } else { }
                    if (optionListName) {
                        tableDataTemp[optionListName] = {
                            sellingPrice: currentValue.mrp,
                            Mrp: currentValue.display_mrp || undefined,
                            avlQty: currentValue.available_quantity,
                            video: currentValue.videos[0]?.youtube_url
                        }
                    }
                });
                setTableData(tableDataTemp);
                if (productData.attributes.length === 1) {
                    for (let i = 0; i < optionsData[0].value.length; i++) {
                        if (Object.keys(tableDataTemp).indexOf(`${optionsData[0].value[i]}`) === -1) {
                            setRemovedOptionsList([...removedOptionsList, `${optionsData[0].value[i]}`]);
                        }
                    }
                }
                if (productData.attributes.length === 2) {
                    for (let i = 0; i < optionsData[0].value.length; i++) {
                        for (let j = 0; j < optionsData[1].value.length; j++) {
                            if (Object.keys(tableDataTemp).indexOf(`${optionsData[0].value[i]} / ${optionsData[1].value[j]}`) === -1) {
                                setRemovedOptionsList([...removedOptionsList, `${optionsData[0].value[i]} / ${optionsData[1].value[j]}`]);
                            }
                        }
                    }
                }
                if (productData.attributes.length === 3) {
                    for (let i = 0; i < optionsData[0].value.length; i++) {
                        for (let j = 0; j < optionsData[1].value.length; j++) {
                            for (let k = 0; k < optionsData[2].value.length; k++) {
                                if (Object.keys(tableDataTemp).indexOf(`${optionsData[0].value[i]} / ${optionsData[1].value[j]} / ${optionsData[2].value[k]}`) === -1) {
                                    setRemovedOptionsList([...removedOptionsList, `${optionsData[0].value[i]} / ${optionsData[1].value[j]} / ${optionsData[2].value[k]}`]);
                                }
                            }
                        }
                    }
                }
            }
            setTaxData(productData.product_taxes ? productData.product_taxes : []);
            setFieldsData(productData.dynamic_product_field ? productData.dynamic_product_field : []);
            setComplementaryData(productData.complementary_products ? productData.complementary_products : []);

            setMetaTagData({
                metaTitle:  productData.metakeyword?.meta_title,
                metaDescription: productData.metakeyword?.meta_description,
                metaKeyword: productData.metakeyword?.meta_keyword
            })
        }
    }, []);
useEffect(()=>{
    if(updateBrandsData && updateBrandsData.brands){
setShowBrandModel(updateBrandsData&&updateBrandsData.brands)
    }
},[updateBrandsData])
    // validate functions
    const validateDetailsForm = () => {
        let temp = { ...validation };
        temp = {
            category: "",
            name: "",
            sellingPrice: "",
            Mrp: "",
            quantity: "",
            qtyUnit: "",
            description: "",
            maxQty: "",
            minQty: "",
            avlQty: "",
        }
        let result = true;

        //Validation for category
        if (!category) {
            temp.category = "Category is Required.";
            result = false;
        } else {
            temp.category = "";
        }

        //Validate product name
        if (name.trim().length < 3) {
            temp.name = "Product name should have minimum 3 characters.";
            result = false;
        } else {
            if(/^[\d`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+$/.test(name.trim())) {
                temp.name = "Product name should contain atleast 1 character.";
                result = false;
            }
            else{
                temp.name = "";
            }
        }

        //Validate selling price
        if (!sellingPrice) {
            temp.sellingPrice = "Selling price is required.";
            result = false;
        } else {
            if (!/^\d+(\.\d{1,2})?$/.test(sellingPrice)) {
                temp.sellingPrice =
                    "Selling price should contain only numbers and 2 digits after decimal.";
                result = false;
            } else if(parseInt(sellingPrice) === 0){
                            temp.sellingPrice = "Selling price cannot be 0.";
                result = false;
            }            
            else {
                temp.sellingPrice = "";
            }
        }

        //Validate mrp
        if (mrp !== null && mrp !== undefined && mrp !== "") {
            if (!/^\d+(\.\d{1,2})?$/.test(mrp)) {
                temp.Mrp = "MRP should contain only numbers and 2 digits after decimal.";
                result = false;
            } else if (Number(mrp) < Number(sellingPrice)) {
                temp.Mrp = "MRP should be greater than selling price.";
                result = false;
            } else if(parseInt(mrp) === 0){
                            temp.Mrp = "MRP cannot be 0.";
                result = false;
            } else {
                temp.Mrp = "";
            }
        }

        //validate maximum quantity
        if (maxQty !== null && maxQty !== undefined && maxQty !== "") {
            if (!/^\d+$/.test(maxQty)) {
                temp.maxQty =
                    "Maximum order quantity must contain only numbers.";
                result = false;
            } else if (parseInt(maxQty) === 0) {
                temp.maxQty = "Maximum order quantity cannot be 0.";
                result = false;
            } else {
                temp.maxQty = "";
            }
        }

        //validate minimum quantity
        if (minQty !== null && minQty !== undefined && minQty !== "") {
            if (!/^\d+$/.test(minQty)) {
                temp.minQty =
                    "Minimum order quantity must contain only numbers.";
                result = false;
            } else if (parseInt(minQty) === 0) {
                temp.minQty = "Minimum order quantity cannot be 0.";
                result = false;
            } else {
                temp.minQty = "";
            }
        }

        //Validate Qauntity
        if(productType==="PHYSICAL"){
        if (!quantity) {
            temp.quantity = "Quantity is required.";
            result = false;
        } else {
            if (!/^\d+$/.test(quantity)) {
                temp.quantity = "Quantity should contain only numbers.";
                result = false;
            } else if(parseInt(quantity) === 0){
                  temp.quantity = "Quantity cannot be 0.";
                     result = false;
            }
            
            else {
                temp.quantity = "";
            }
        }}

        //Validate available Quantity
        if (productType==="PHYSICAL" && data?.data?.status === "active" && (!avlQty || !/^\d+$/.test(avlQty))) {
            temp.avlQty = "Available Quantity is Required and only in numbers";
            result = false;
        } else {
            temp.avlQty = "";
        }

        //Validate unit name
        if(productType==="PHYSICAL"){
        if (!qtyUnit) {
            temp.qtyUnit = "Quantity unit is required.";
            result = false;
        } else {
            temp.qtyUnit = "";
        }}

        //image validation with separate state
        if (platformPaths.length === 0) {
            setImageValidation("Minimum one product image is needed.");
            result = false;
        } else {
            setImageValidation("");
        }
         //digital products validation with separate state
         if(productType==="DIGITAL"){
         if (digitalProductPaths.length === 0) {
            setDigitalValidation("Minimum one digital product is needed.");
            result = false;
        } else {
            setDigitalValidation("");
        }}

        setValidation(temp);
        return result;
    };

    const [optionsData, setOptionsData] = useState([{
        name: '',
        value: []
    }]);

    function removeEmojis(string) {
        if (string !== "") {
            var regex =
                /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
            return string.replace(regex, "");
        }
    }

    const variantsValidation = () => {
        let result = true;
        let tableTemp = {...tableValidation};
        let variantTemp = {...variantValidation};
        let foundValueError = false, foundNameError = false;
        for (const data of optionsData) {
            if (
                data.value.length === 0 ||
                data.name === "" ||
                data.name === undefined ||
                data.name === null
            ) {
                if (data.value.length === 0 && !foundValueError) {
                    variantTemp.value = `Minimum one value is required of ${data.name ? data.name : "variant"}.`;
                    result = false;
                    foundValueError = true;
                } else {
                    variantTemp.value = "";
                }
                if (
                    (data.name === "" ||
                    data.name === undefined ||
                    data.name === null || atleastOneCharValidation(data.name)) &&
                    !foundNameError
                ) {
                    if(atleastOneCharValidation(data.name)){
                        variantTemp.name = "Variant name should have atleast one letter.";
                    }else{
                        variantTemp.name = "Variant name is required.";
                    }
                    result = false;
                    foundNameError = true;
                } else {
                    variantTemp.name = "";
                }
            }

            let emptyValuePresent = false;
            data.value.forEach((element) => {
                if (!element) {
                    result = false;
                    emptyValuePresent = true;
                }
            });
            if (emptyValuePresent) {
                variantTemp.valuesArray = "Variant values can't be empty.";
            } else {
                variantTemp.valuesArray = "";
            }
        }
        setVariantValidation(variantTemp);

        let isSellingPriceDone = false,
            isMrpDone = false,
            isGreaterDone = false;
        Object.keys(tableData).forEach((variantName) => {
            if (!isSellingPriceDone) {
                if (!tableData[variantName].sellingPrice) {
                    tableTemp.selllingPrice = "Selling price is required in each variant.";
                    isSellingPriceDone = true;
                    result = false;
                } else {
                    if (!/^\d+(\.\d{1,2})?$/.test(tableData[variantName].sellingPrice)) {
                        tableTemp.selllingPrice = "Selling price must contain only numbers and 2 digits after";
                        isSellingPriceDone = true;
                        result = false;
                    } else {
                        tableTemp.selllingPrice = "";
                    }
                }
            }
            if (!isMrpDone) {
                if (tableData[variantName].Mrp) {
                    if (!/^\d+(\.\d{1,2})?$/.test(tableData[variantName].Mrp)) {
                        tableTemp.mrp = "MRP must contain only numbers and 2 digits after decimal in each variant.";
                        isMrpDone = true;
                        result = false;
                    } else {
                        tableTemp.mrp = "";
                    }
                }
            }
            if (!isGreaterDone) {
                if (
                    tableData[variantName].Mrp !== null &&
                    tableData[variantName].Mrp !== undefined &&
                    tableData[variantName].Mrp !== "" &&
                    Number(tableData[variantName].Mrp) < Number(tableData[variantName].sellingPrice)
                ) {
                    tableTemp.greater = "MRP must be greater than selling price in each variant.";
                    isGreaterDone = true;
                    result = false;
                } else {
                    tableTemp.greater = ""
                }
            }
        });

        setTableValidation(tableTemp);
        return result;
    };

    //Handling both duplicate and empty value on blur.
    const duplicateValidation = (optionIndex?, valueIndex?) => {
        setVariantValidation((prevValue)=>{
            let variantTemp = {...prevValue};
            optionsData.forEach((data, index) => {
                let emptyValuePresent = false;
                data.value.forEach((element, index) => {
                    if (!element) {
                        emptyValuePresent = true;
                    }
                });
                if (emptyValuePresent) {
                    variantTemp.valuesArray = "Variant values can't be empty.";
                } else {
                    variantTemp.valuesArray = "";
                }
            });
            return variantTemp;
        });
        let duplicateTemp = [...isDuplicateValue];
        if (optionIndex !== null && optionIndex !== undefined) {
            let duplicates = 0;
            optionsData[optionIndex].value.forEach((element, index) => {
                if (valueIndex !== index) {
                    if (optionsData[optionIndex].value.lastIndexOf(element.trim()) !== index) {
                        duplicates++;
                    }
                    if (optionsData[optionIndex].value.indexOf(element.trim()) !== index) {
                        duplicates++;
                    }
                }
            });
            if (duplicates !== 0) {
                duplicateTemp[optionIndex] = true;
            } else {
                duplicateTemp[optionIndex] = false;
            }
        } else {
            let result = true;
            let duplicates = [0, 0, 0];
            optionsData.forEach((data, outerIndex) => {
                data.value.forEach((element, index) => {
                    if (data.value.lastIndexOf(element) !== index) {
                        duplicates[outerIndex]++;
                        result = false;
                    }
                    if (data.value.indexOf(element) !== index) {
                        duplicates[outerIndex]++;
                        result = false;
                    }
                });
            });
            duplicates.forEach((element, index) => {
                if (element !== 0) {
                    duplicateTemp[index] = true;
                } else {
                    duplicateTemp[index] = false;
                }
            });
            return result;
        }
        setIsDuplicateValue(duplicateTemp);
    };

    const addProductFunction = async () => {
        setLoading(true);
        const formValidationResult = validateDetailsForm();
        let variantValidationResult = false;
        let tableValidationResult = false;
        if(isVariantChecked){
            variantValidationResult = variantsValidation();
            tableValidationResult = duplicateValidation();
        }else{
            variantValidationResult = true;
            tableValidationResult = true;
        }
        if (formValidationResult) {
            let productData = {
                product_type : productType,
                name: name,
                desc_html: removeEmojis(description),
                status: `${productStatus || "active"}`,
                category_id: category.id,
                brand_id:`${brand?.id ?? null}`,

                store_id: currentUser?.store.subdomain,
                sku: skuId,
                product_taxes: taxData,
                dynamic_product_field: fieldsData,
                meta_title: metaTagData.metaTitle,
                meta_description: metaTagData.metaDescription,
                meta_keyword: metaTagData.metaKeyword,

                complementary_products: complementaryData.map(complementary => complementary.id)
            }
            if(location?.state?.productData){
                productData["id"] = location?.state?.productData?.id;
                if (isVariantChecked) {
                    if (variantValidationResult && tableValidationResult) {
                        productData["variants"] = [
                            ...Object.keys(tableData).map((variantName, index) => {
                                return {
                                    id: location?.state?.productData?.variants[index] ? location?.state?.productData?.variants[index].id : null,
                                    mrp: tableData[variantName].sellingPrice,
                                    quantity: quantity,
                                    videos: [
                                        {
                                            "youtube_url": tableData[variantName].video
                                        }
                                    ],
                                    display_mrp:
                                    tableData[variantName].Mrp === tableData[variantName].sellingPrice ? undefined : tableData[variantName].Mrp,
                                    quantity_unit_name: `${qtyUnit}`,
                                    available_quantity:
                                        tableData[variantName].avlQty !== 0 && !tableData[variantName].avlQty
                                            ? avlQty
                                            : tableData[variantName].avlQty,
                                    min_order_quantity: minQty || null,
                                    max_order_quantity: maxQty || null,
                                    images: [
                                        ...platformPaths.map((value) => {
                                            return {
                                                image_url_original: value,
                                            };
                                        }),
                                    ],
                                    digital_product_url: [
                                        ...digitalProductPaths.map((value) => {
                                            return {
                                                digital_product_url: value,
                                            };
                                        }),
                                    ],
                                    attribute_value_mappings: [
                                        ...variantName.split(" / ").map((value, ind) => {
                                            return {
                                                attribute_name: optionsData[ind].name,
                                                attribute_value: value,
                                            };
                                        }),
                                    ],
                                };
                            }),
                        ]
                    }
                } else {
                    productData["variants"] = [{
                        id: location?.state?.productData?.variants[0].id,
                        mrp: sellingPrice,
                        quantity: quantity,
                        videos: [
                            {
                                "youtube_url": video
                            }
                        ],
                        display_mrp: sellingPrice === mrp ? undefined : mrp,
                        quantity_unit_name: `${qtyUnit}`,
                        available_quantity: avlQty,
                        min_order_quantity: minQty || null,
                        max_order_quantity: maxQty || null,
                        images: [
                            ...platformPaths.map((value) => {
                                return {
                                    image_url_original: value,
                                };
                            }),
                        ],
                        digital_product_url: [
                            ...digitalProductPaths.map((value) => {
                                return {
                                    digital_product_url: value,
                                };
                            }),
                        ],
                        attribute_value_mappings: null,
                    }]
                }
            } else{
                if (isVariantChecked) {
                    if (variantValidationResult && tableValidationResult) {
                        productData["variants"] = [
                            ...Object.keys(tableData).map((variantName) => {
                                return {
                                    mrp: tableData[variantName].sellingPrice,
                                    quantity: quantity,
                                    videos: [
                                        {
                                            "youtube_url": tableData[variantName].video
                                        }
                                    ],
                                    display_mrp:
                                    tableData[variantName].Mrp === tableData[variantName].sellingPrice ? undefined : tableData[variantName].Mrp,
                                    quantity_unit_name: `${qtyUnit}`,
                                    available_quantity:
                                        tableData[variantName].avlQty !== 0 && !tableData[variantName].avlQty
                                            ? avlQty
                                            : tableData[variantName].avlQty,
                                    min_order_quantity: minQty || null,
                                    max_order_quantity: maxQty || null,
                                    images: [
                                        ...platformPaths.map((value) => {
                                            return {
                                                image_url_original: value,
                                            };
                                        }),
                                    ],
                                    digital_product_url: [
                                        ...digitalProductPaths.map((value) => {
                                            return {
                                                digital_product_url: value,
                                            };
                                        }),
                                    ],
                                    attribute_value_mappings: [
                                        ...variantName.split(" / ").map((value, ind) => {
                                            return {
                                                attribute_name: optionsData[ind].name,
                                                attribute_value: value,
                                            };
                                        }),
                                    ],
                                };
                            }),
                        ]
                    }
                } else {
                    productData["variants"] = [{
                        mrp: sellingPrice,
                        quantity: quantity,
                        videos: [
                            {
                                "youtube_url": video
                            }
                        ],
                        display_mrp: sellingPrice === mrp ? undefined : mrp,
                        quantity_unit_name: `${qtyUnit}`,
                        available_quantity: avlQty,
                        min_order_quantity: minQty || null,
                        max_order_quantity: maxQty || null,
                        images: [
                            ...platformPaths.map((value) => {
                                return {
                                    image_url_original: value,
                                };
                            }),
                        ],
                        digital_product_url: [
                            ...digitalProductPaths.map((value) => {
                                return {
                                    digital_product_url: value,
                                };
                            }),
                        ],
                        attribute_value_mappings: null,
                    }]
                }
            }

            if(formValidationResult && variantValidationResult && tableValidationResult){
                const [ok, response] = location?.state?.productData ? await API.updateProduct(productData) : await API.createProduct(productData);
                if(ok) {
                    present("Data saved successfully!", 1500);
                    updateCategoryData.setUpdatedCategoryId({
                        id: response?.data.category_id,
                        refresh: !updateCategoryData.updatedCategoryId.refresh,
                        newId: location?.state?.productData?.category_id || category.id
                    });
                    resetValues();
                    if(location?.state?.productData){
                        history.goBack();
                    }else{
                        if (fp === "false" || fp === "${false}") {
                            history.push("/catalogue");
                        } else if (fp === "true" || fp === "${true}") {
                            history.push("/catalogue/products");
                        }
                    }
                }
            }
        }
        setLoading(false);
    };

    function resetValues(){
        setLoading(false);
        setName("");
        setDescription("");
        setSellingPrice(null);
        setMrp(null);
        setSkuId("");
        setQuantity(null);
        setQtyUnit("");
        setMinQty(null);
        setMaxQty(null);
        setAvlQty(null);
        setVideo("");
        setLoading(false);
        setCategory("");
        setBrand("");
        setPictureUrl([
            "/assets/placeholder.svg",
            "/assets/placeholder.svg",
            "/assets/placeholder.svg",
            "/assets/placeholder.svg",
        ]);
        setDigitalProductUrl([
            "/assets/upload.svg",
            "/assets/upload.svg",
            "/assets/upload.svg",
            "/assets/upload.svg",
        ]);
        setPlatformPaths([]);
        setDigitalProductPaths([]);
        setIsVariantChecked(false);
        setOptionsData([{
            name: '',
            value: []
        }]);
        setRemovedOptionsList([]);
        setIsDuplicateValue([false, false, false]);
        setTaxData([]);
        setTableData({});
        setValidation({
            category: "",
            name: "",
            sellingPrice: "",
            Mrp: "",
            quantity: "",
            qtyUnit: "",
            description: "",
            maxQty: "",
            minQty: "",
            avlQty: "",
        });
        setCategoryId(null);
        setBrandId(null);
        setProductStatus("active");
        setMetaTagData({
            metaTitle: "",
            metaDescription: "",
            metaKeyword: ""
        });
        setImageValidation("");
        setDigitalValidation("");
        setVariantValidation({
            name: '',
            value: '',
            valuesArray: ''
        });
        setTableValidation({
            mrp: '',
            selllingPrice: '',
            avlQty: '',
            greater: ''
        });
        setFieldsData([]);
        setComplementaryData([]);
    }

    useEffect(() => {
        return resetValues;
    }, [location]);

    return (
        <IonPage>
            <IonHeader style={{ height: '68px' }}>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                        defaultHref="/product" 
                        icon={arrowBackIcon}/>
                    </IonButtons>
                    <IonTitle>{location?.state?.productData ? "Update Product" : "Add Product"}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <CategoryModal category={category} setCategory={setCategory} setCategoryId={setCategoryId} validationText={validation.category}></CategoryModal>
                {updateBrandsData && showBrandModel.length> 0 && <BrandModal brand={brand} setBrand={setBrand} setBrandId={setBrandId}></BrandModal>}
                {!Array.isArray(digitalProductData) && digitalProductData.data.status === "active" && 
                <>
                <IonCard>
                <IonItem lines="inset">
                <IonLabel >Please choose a product Type</IonLabel>
                    <IonSelect value={productType} onIonChange={handleProductChange} className="marginRight color">
                        <IonSelectOption value="PHYSICAL">Physical product</IonSelectOption>
                        <IonSelectOption value="DIGITAL">Digital product</IonSelectOption>
                    </IonSelect>

                    <IonIcon size="small" slot="end" color="primary" icon={caretDownIcon}></IonIcon>

                </IonItem>
                </IonCard>
                </>}

                <ProductImages platformPaths={platformPaths} setPlatformPaths={setPlatformPaths} imageValidation={imageValidation} setImageValidation={setImageValidation} pictureUrl={pictureUrl} setPictureUrl={setPictureUrl}></ProductImages>
                {!Array.isArray(digitalProductData) && digitalProductData.data.status === "active" &&productType==="DIGITAL" && 
                <DigitalFiles digitalProductPaths={digitalProductPaths} setDigitalProductPaths={setDigitalProductPaths} digitalValidation={digitalValidation} setDigitalValidation={setDigitalValidation} digitalProductUrl={digitalProductUrl} setDigitalProductUrl={setDigitalProductUrl}></DigitalFiles>
                }
                <ProductDetailForm dataObject = {{name, setName, description, setDescription, skuId, setSkuId, qtyUnit, setQtyUnit, video, setVideo, sellingPrice, setSellingPrice, mrp, setMrp, quantity, setQuantity, minQty, setMinQty, maxQty, setMaxQty, avlQty, setAvlQty}} validation={validation} setTableData={setTableData} productType={productType}></ProductDetailForm>

                <ProductMetaTags metaTagData={metaTagData} setMetaTagData={setMetaTagData}></ProductMetaTags>
                {!Array.isArray(productTaxData) && productTaxData.data.status === "active" && <ProductLevelTaxes taxData={taxData} setTaxData={setTaxData} />}
                {!Array.isArray(dynamicFieldsData) && dynamicFieldsData.data.status === "active" && <DynamicProductFields fieldsData={fieldsData} setFieldsData={setFieldsData} />}
                {!Array.isArray(complementaryProductData) && complementaryProductData.data.status === "active" && <ComplementaryProduct complementaryData={complementaryData} setComplementaryData={setComplementaryData} productName={name} />}

                <IonCard>
                <IonItem>
                    <IonLabel>Product Status</IonLabel>
                    <IonSelect
                        
                        value={productStatus}
                        onIonChange={(e) => setProductStatus(e.detail.value)} className="marginright color"
                    >
                        <IonSelectOption value="active">Active</IonSelectOption>
                        <IonSelectOption value="inactive">Inactive</IonSelectOption>
                    </IonSelect>

                    <IonIcon slot="end" size="small" color="primary" icon={caretDownIcon}></IonIcon>

                </IonItem>

                {productType==="PHYSICAL" && 
                <VariantForm priceData={{sellingPrice, mrp, video, avlQty}} variantValidation={variantValidation} setVariantValidation={setVariantValidation} setTableData={setTableData}  optionsData={optionsData} setOptionsData={setOptionsData} duplicateValidation={duplicateValidation} isVariantChecked={isVariantChecked} setIsVariantChecked={setIsVariantChecked} isDuplicateValue={isDuplicateValue} removedOptionsList={removedOptionsList} setRemovedOptionsList={setRemovedOptionsList} numberOfOptionValue={numberOfOptionValue} setNumberOfOptionValue={setNumberOfOptionValue}/>}

                {isVariantChecked === true ? (
                <>
                    <IonItemDivider></IonItemDivider>
                    <br />
                    <VariantTable tableData={tableData} setTableData={setTableData} tableValidation={tableValidation} removedOptionsList={removedOptionsList} setRemovedOptionsList={setRemovedOptionsList} data={data}></VariantTable>
                </>
                ) : (
                    ""
                )}
                </IonCard>

                <br />
                <div className="ion-text-center">
                    <IonButton shape="round" onClick={(e) => addProductFunction()}>
                        {location?.state?.productData ? "Update" : "Add"} Product
                    </IonButton>
                </div>
                <IonLoading
                    isOpen={loading}
                    message="Please Wait"
                />
            </IonContent>
        </IonPage>
    );
};

export default AddProduct;
