import {
    IonAccordion,
    IonAccordionGroup,
    IonAlert,
    IonContent,
    IonHeader,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import {
    addCircleOutline,
    albumsOutline,
    analyticsOutline,
    appsOutline,
    appsSharp,
    bagAddOutline,
    bagHandleOutline,
    briefcaseOutline,
    browsersOutline,
    cellularOutline,
    colorPaletteOutline,
    documentsOutline,
    fileTrayFullOutline,
    folderOpen,
    gridOutline,
    hammerOutline,
    helpCircleOutline,
    homeOutline,
    imageOutline,
    listOutline,
    logOutOutline,
    mail as mailIcon,
    megaphone as megaphoneIcon,
    menu as menuIcon,
    peopleOutline,
    personOutline,
    pricetagOutline,
    pricetagsOutline,
    print as printIcon,
    receiptOutline,
    rocketOutline,
    trashBinOutline,
    trendingUp
} from 'ionicons/icons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from "react-router-dom";
import { getAccessPermission } from '../../services/auth/userLocalStorage';
import { useSharedData } from '../../utils/contexts';
import useFetch from '../../utils/useFetch';
import "./Menu.css";

const DashboardMenu = () => {
    const { currentUser } = useSelector((state: any) => state.currentUser);
    const [showAlert, setShowAlert] = useState(false)
    const location = useLocation();
    const history = useHistory();
    const sharedData: any = useSharedData();
    const { data } = useFetch("shiprocketAddon", sharedData.shiprocketUpdate);
    const permissions = getAccessPermission();

    const routes = {
        appPages: [
            { title: 'Home', path: '/userdashboard', icon: homeOutline, subRoutes: null },
            { title: 'Customers', path: '/customer', icon: peopleOutline, subRoutes: null },
            {
                title: 'Catalogue', id:'catalogue', path: '/catalogue', icon: appsSharp, subRoutes: [{ title: 'Categories', path: '/catalogue/categories', icon: albumsOutline, subRoutes: null },                
                { title: 'Inventory', path: '/catalogue/inventory', icon: fileTrayFullOutline, subRoutes: null },
                { title: 'Products', path: '/catalogue/products', icon: bagHandleOutline, subRoutes: null },
                { title: 'Master', path: '/catalogue/master/products', icon: bagAddOutline, subRoutes: null },
                { title: 'Brands', path: '/catalogue/brands', icon: pricetagsOutline, subRoutes: null },
            ]
            },
            { title: 'Orders', path: '/orders', icon: listOutline, subRoutes: [{ title: 'All orders', path: '/orders', icon: receiptOutline, subRoutes: null },
            { title: 'Abandoned', path: '/orders/abandoned', icon: trashBinOutline, subRoutes: null }] 
            },
            { title: 'Delivery', path: '/delivery', icon: rocketOutline, subRoutes: [{ title: 'Shiprocket', path: '/delivery/shiprocket', icon: receiptOutline, subRoutes: null }] 
            },
            { title: "Media Library", path: '/media-library', icon: imageOutline, subRoutes: null },
            { title: 'Coupons', path: '/coupons', icon: pricetagOutline, subRoutes: null },

            {
                title: 'Content', path: '/content', icon: folderOpen, subRoutes: [
                    { title: 'Pages', path: '/content/pages', icon: documentsOutline, subRoutes: null },
                    {
                        title: 'Menus', path: '/content/menus', icon: menuIcon, subRoutes: [
                            { title: 'Website', path: '/content/menus/web-menu', icon: browsersOutline, subRoutes: null },
                            { title: 'App', path: '/content/menus/app-menu', icon: appsOutline, subRoutes: null }
                        ]
                    },
                ]
            },

            {
                title: 'Marketing',id:'maketing', path: '/marketing', icon: cellularOutline, subRoutes: [{ title: 'Printables', path: '/marketing/printables', icon: printIcon, subRoutes: null },
                { title: 'Announcements', path: '/marketing/announcements', icon: megaphoneIcon, subRoutes: null },
                { title: 'Emails', path: '/marketing/emails', icon: mailIcon, subRoutes: null }]
            },

            ...(!(isPlatform('hybrid') && isPlatform('android')) ?
             [{
             title: 'Appearance', id: 'appearance', path: '/appearance', icon: colorPaletteOutline, subRoutes: [{ title: 'Website', path: '/appearance/ik-web-settings', icon: browsersOutline, subRoutes: null, state: { forApp: false } },
           { title: 'App', path: '/appearance/ik-app-settings', icon: appsOutline, subRoutes: null, state: { forApp: true } }]
             }] : []),

            { title: 'Users', path: '/staffs', icon: peopleOutline, subRoutes: null },

           { title: 'Reports', path: '/reports', icon: analyticsOutline, subRoutes: [{ title: 'Traffic', path: '/reports/traffic', icon: trendingUp, subRoutes: null },
            { title: 'Sales', path: '/reports/sales', icon: briefcaseOutline, subRoutes: null }] 
            },
            {title: "Manual Billing" ,path:'/manualbilling', icon: receiptOutline, subRoutes: null},
            {title: "Tools" ,path:'/tool', icon: hammerOutline, subRoutes: null},
            
            ...(!(isPlatform('hybrid') && isPlatform('android')) ?
            [{ title: 'Addons', path: '/add-ons', icon: addCircleOutline, subRoutes: null }] : []),
            { title: 'Settings', path: '/settings', icon: gridOutline, subRoutes: null }
        ]
        ,
        loggedInPages: [
            { title: 'Subscriptions', path: '/subscriptions', icon: personOutline, subRoutes: null },
            { title: 'Help & Support',id:'help', path: '/help&support', icon: helpCircleOutline, subRoutes: null }
        ]
        ,
        loggedOutPages: [
            { title: 'Logout', path: '/logout', icon: logOutOutline, subRoutes: null },
        ]
    };
    interface Pages {
        title: string,
        path: string,
        icon: string,
        routerDirection?: string,
        subRoutes: Pages[],
        state?: any,
        id?: any
    }

    function renderListItems(list) {
        const filteredList = permissions && permissions.length === 0
            ? list
            : list.filter(item => permissions && permissions.includes('orders') && (item.title === 'Orders') || (item.title === 'Help & Support'));
    
        return filteredList.map(p => (
            <>
                {p.subRoutes ? (
                    <IonAccordion value={p.title} key={p.title}>
                        <IonItem id={p.hasOwnProperty('id') ? p.id : null} lines='none' detail={false} slot="header" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
                            <IonIcon slot="start" icon={p.icon} />
                            <IonLabel>{p.title}</IonLabel>
                        </IonItem>
                        <div className="ion-padding" slot="content">
                            {(permissions && permissions.includes('orders') ? p.subRoutes?.filter(subp => subp.title === 'All orders') : p.subRoutes)?.map(subp => (
                                subp.subRoutes ? (
                                    <div className="ion-padding" slot="content">
                                        <IonAccordionGroup key={subp.title}>
                                            {renderListItems([subp])}
                                        </IonAccordionGroup>
                                    </div>
                                ) : (
                                    <IonMenuToggle key={subp.title} auto-hide="false">
                                        <IonItem style={{ cursor: 'pointer' }} key={subp.title} detail={false} onClick={e => history.push({ pathname: subp.path, state: subp?.state ? subp.state : {} })} routerDirection="none" className={`ion-padding-start ${(location.pathname.startsWith(subp.path) && !(location.pathname === "/orders/abandoned" && subp.path === "/orders")) ? 'selected' : undefined}`}>
                                            <IonIcon slot="start" icon={subp.icon} />
                                            <IonLabel>{subp.title}</IonLabel>
                                        </IonItem>
                                    </IonMenuToggle>
                                )
                            ))}
                        </div>
                    </IonAccordion>
                ) : (
                    <IonMenuToggle key={p.title} auto-hide="false">
                        <IonItem id={p.hasOwnProperty('id') ? p.id : null} detail={false} onClick={e => history.push({ pathname: p.path, state: p?.state ? p.state : {} })} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
                            <IonIcon slot="start" icon={p.icon} />
                            <IonLabel>{p.title}</IonLabel>
                        </IonItem>
                    </IonMenuToggle>
                )}
            </>
        ));
    }
    
    return (
        <IonMenu type="overlay" contentId="main" id='menu'>
            <IonHeader>
                <IonToolbar>
                    <IonImg style={{ height: '68px', width: "100%" }} src={currentUser && currentUser.data && currentUser.data && currentUser.data.store.logo ? `https://cdn.intelikart.com/uploads${currentUser.data.store.logo}` : "https://cdn.intelikart.com/uploads/new-images/default-store.png"}></IonImg>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonAccordionGroup>
                    <IonList lines="none" className="pointer-cursor">
                        {renderListItems(routes.appPages)}
                    </IonList>
                    <IonList lines="none" className="pointer-cursor">
                        <IonListHeader>Others</IonListHeader>
                        {renderListItems(routes.loggedInPages)}
                        <IonMenuToggle key='LOGOUT' auto-hide="false">
                            <IonItem detail={false} onClick={e => setShowAlert(true)} style={{ cursor: 'pointer' }} routerDirection="none" className={location.pathname.startsWith('/logout') ? 'selected' : undefined} >
                                <IonIcon slot="start" icon={logOutOutline} />
                                <IonLabel>Logout</IonLabel>
                            </IonItem>
                        </IonMenuToggle>
                    </IonList>
                </IonAccordionGroup>
            </IonContent>
            <IonAlert
                isOpen={showAlert === true}
                onDidDismiss={() => setShowAlert(false)}
                header={'Confirm Log Out'}
                message={`Are you sure you want to log out?`}
                buttons={[
                    {
                        text: 'NO',
                        role: 'cancel'
                    },
                    {
                        text: 'YES',
                        handler: () => { history.push('/logout') }
                    }
                ]}
            />
        </IonMenu>
    )
}

export default DashboardMenu
