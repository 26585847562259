import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonApp, IonSegment, IonSegmentButton, IonLabel, IonText, IonCard, IonPage, IonGrid, IonCol, IonRow, IonIcon, IonItem } from '@ionic/react';
import DashboardMenu from '../../components/dashboard/DashboardMenu';
import { getAcademyContent } from '../../actions/dashboardActions';
import { getAccessPermission, getAccessToken } from '../../services/auth/userLocalStorage';
import Header from '../../components/dashboard/Header'
import { authenticateUserWhenLogout } from '../../actions/authActions';
import { useHistory } from 'react-router';
import { chatboxEllipsesOutline, logoYoutube, buildOutline,calendarOutline, bagHandleOutline } from 'ionicons/icons';

const HelpAndSupport = () => {
  const [content, setContent] = useState<string | undefined>("VIDEOS");
  const dispatch = useDispatch();
  const history = useHistory();
  const { academyContentLoading, academyContent, academyContentError } = useSelector((state: any) => state.academyContent);
  const permissions = getAccessPermission();
  
  useEffect(() => {
    (async function () {
      const accessToken = await getAccessToken();
      if (accessToken) {
        await dispatch(getAcademyContent(accessToken));
      } else {
        await dispatch(authenticateUserWhenLogout())
        await localStorage.clear();
        history.push('/')
      }
    })();
    if (academyContentLoading === false && academyContentError)
      console.log(academyContentError);
  }, [content])

  const getVideoId = (url: string) => {
    let index = url.indexOf("?v=");

    if (index !== -1) {
      let ind = url.lastIndexOf("&");
      if (ind !== -1) {
        return url.slice(index + 3, ind);
      }
      return url.slice(index + 3, url.length);
    } else {
      let i = url.lastIndexOf("/");
      let ind = url.lastIndexOf("?");
      if (ind !== -1) {
        return url.slice(i + 1, ind);
      }
      return url.slice(i + 1, url.length);
    }
  }

  const handleClick = async () => {
     window.location.href='userdashboard'
  };

  return (
    <IonPage>
      <div className="ion-page" id="main-content">
        <Header name="Help & Support" docs={true} docslink='https://intelikart.tawk.help/article/help-and-support' />
        <IonContent className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol  sizeMd='6' sizeSm='12'>
                <IonCard href='https://wa.me/+918448355548' target="_blank">
                  <IonItem lines="none">
                    <IonIcon slot="start" icon={chatboxEllipsesOutline} size="large" />
                    <IonText >
                      Chat With Us
                    </IonText>
                  </IonItem>
                </IonCard>
              </IonCol>
              <IonCol  sizeMd='6' sizeSm='12'>
                <IonCard routerLink="/ikacademy" >
                  <IonItem lines="none">
                    <IonIcon slot="start" icon={logoYoutube} size="large" />
                    <IonText  >
                      Learn & Grow
                    </IonText>
                  </IonItem>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow className='item-align-center'>
              <IonCol sizeMd='6' sizeSm='12'>
                <IonCard routerLink="/support" >
                  <IonItem lines="none">
                    <IonIcon slot="start" icon={buildOutline} size="large" />
                    <IonText  >
                      Report Tech Issue
                    </IonText>
                  </IonItem>
                </IonCard>
              </IonCol>
              {permissions && permissions.length == 0 &&
                <IonCol sizeMd='6' sizeSm='12'>
                <IonCard onClick={() => { handleClick(); }} className='pointer-cursor'>
                    <IonItem lines="none">
                        <IonIcon slot="start" icon={bagHandleOutline} size="large" />
                        <IonText>
                        Product Overview 
                        </IonText>
                    </IonItem>
                    </IonCard>
                </IonCol>
            }
                 <IonCol  sizeMd='6' sizeSm='12'>
                <IonCard onClick={()=>{window.open('https://calendly.com/iksupport')}} className='pointer-cursor' >
                  <IonItem lines="none">
                    <IonIcon slot="start" icon={calendarOutline} size="large" />
                    <IonText  >
                      Schedule a Call with Experts
                    </IonText>
                  </IonItem>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </div>
    </IonPage>
  )
}

export default HelpAndSupport
function startIntro() {
  throw new Error('Function not implemented.');
}

