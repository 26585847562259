import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonText,
  IonItem,
  IonIcon,
  IonLoading,
  IonCard,
  IonButton,
  IonAlert,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  useIonToast,
  IonCheckbox,
} from "@ionic/react";
import {
  trash as trashIcon,
  arrowBack as arrowBackIcon,
  createOutline as editIcon,
} from "ionicons/icons";
import { Link, useHistory } from "react-router-dom";
import useAPI from "../../../utils/api";
import {
  DynamicStatusContext,
  useDynamicStatusUpdate,
  useUser,
} from "../../../utils/contexts";
import useFetch from "../../../utils/useFetch";
import FeatHeader from '../../../components/dashboard/FeatHeader';


const OrderStatusListing = () => {
  const [statusValue, setStatusValue] = useState<any>([]);
  const API = useAPI();
  const statusUpdateData: any = useDynamicStatusUpdate();
  const [statusUpdate, setStatusUpdate] = useState(false);
  const { loading, data, status } = useFetch(
    "getDynamicStatus",
    statusUpdateData.update
  );
  const [present] = useIonToast();

  const saveSelectedStatus = async () => {
    try {
      let statuses = [...statusValue,...['NEW',"DELIVER","RETURN","CANCEL"]]

      statuses = statuses.map((ostatus) => ({
        name: ostatus,
        display_name: displayName[ostatus]
      }));
      
      const [ok, repsonse] = await API.dynamicStatus({ status: statuses });
      if (ok) {
        present({
          message: "Status saved successfully",
          duration: 3000,
        });
      }
    } catch (error) {
      console.error("Error while saving status:", error);
      present({
        message: "Failed to save status",
        duration: 3000,
        color: "danger",
      });
    }
  };

  const statusType = [
    "NEW",
    "PROCESSING",
    "DELIVER",
    "DENY",
    "RETURN",
    "CANCEL",
    "OUT FOR DELIVERY"
  ];

  const displayName = {
    "NEW" : "New",
    "PROCESSING" : "Processing",
    "DELIVER" : "Delivered",
    "DENY" : "Denied",
    "RETURN" : "Returned",
    "CANCEL" : "Cancelled",
    "OUT FOR DELIVERY" : "Out for delivery"
  }

  const handlePushChange = (e: any, statusItem) => {
    if (e.detail.checked) {
      let temp = statusValue;
      if (!temp.includes(statusItem)) {
        temp.push(statusItem);
        setStatusValue(temp);
      }
    } else {
      let temp = statusValue;
      let index = temp.indexOf(statusItem);
      if (index != -1) {
        temp.splice(index, 1);
        setStatusValue(temp);
      }
    }
  };

  return (
    <DynamicStatusContext.Provider value={{ statusUpdate, setStatusUpdate }}>
      <IonPage>
        <FeatHeader
          defaultHref='/settings'
          title='Dynamic Order Status'
          showInfoButton={false}
        />
        <IonContent className="ion-padding">
          {statusType?.map((statusItem, index) => (
            <IonCard key={index}>
              <IonItem>
                <IonText style={{ display: "flex" }}>
                  <IonCheckbox
                  checked={
                    // Check if the statusItem is one of the default statuses
                    (statusItem === "NEW" || statusItem === "DELIVER" || statusItem === "RETURN" || statusItem === "CANCEL") || (data.data && data.data.length === 0) ||
                    // Then, check if it's already in the statusValue array
                    (data.data &&
                      data.data.length !== 0 &&
                      data.data.some(
                        (apiStatus) =>
                          apiStatus.name.toLowerCase() ===
                          statusItem.toLowerCase()
                      ))
                  }
                  disabled={
                    // Disable the default statuses
                    statusItem === "NEW" || statusItem === "DELIVER" || statusItem === "RETURN" || statusItem === "CANCEL"
                  }
                  onIonChange={(e) => handlePushChange(e, statusItem)}
                />
                  <IonText style={{ paddingLeft: "20px" }}>
                    <h4>{statusItem}</h4>
                  </IonText>
                </IonText>
              </IonItem>
            </IonCard>
          ))}
          <IonButton
            shape="round"
            className="ion-padding"
            style={{ marginLeft: "40%" }}
            onClick={saveSelectedStatus}
          >
            Save
          </IonButton>
        </IonContent>
      </IonPage>
    </DynamicStatusContext.Provider>
  );
};

export default OrderStatusListing;
