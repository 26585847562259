import { useEffect, useState } from 'react'
import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonToast } from '@ionic/react'
import { getAccessToken } from '../../services/auth/userLocalStorage';
import {
    arrowBack as arrowBackIcon, closeOutline, checkmarkSharp as checkmarkSharpIcon
} from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { postShiprocketOrder, getPickupLocations } from '../../actions/shiprocketActions';
import { useHistory, useLocation } from 'react-router';
import { BrowserView, MobileView } from 'react-device-detect';
import useFetch from '../../utils/useFetch';

const CreateShiprocketOrder = () => {
    const location = useLocation();

  const orderData = useFetch("orderById", location.state);
  const order = orderData?.data;

    // const { orderLoading, order, orderError } = useSelector((state: any) => state.orderById);
    const { currentUser } = useSelector((state: any) => state.currentUser);
    const { pickupLocationsLoading, pickupLocations, pickupLocationsError } =
        useSelector((state: any) => state.pickupLocations);

    const [pickupLocationData, setPickupLocationData] = useState<any>([]);
    const [pickupLocation, setPickupLocation] = useState<string>("");
    const [destinationAddress, setDestinationAddress] = useState<string>("");
    const [destinationCity, setDestinationCity] = useState<string>("");
    const [destinationState, setDestinationState] = useState<string>("");
    const [destinationCountry, setDestinationCountry] = useState<string>("");
    const [destinationZip, setDestinationZip] = useState<string>("");
    const [weight, setWeight] = useState<string>("");
    const [length, setLength] = useState<string>("");
    const [breadth, setBreadth] = useState<string>("");
    const [height, setHeight] = useState<string | number>("");

    // validation
    const [isPickupLocationEmpty, setIsPickupLocationEmpty] = useState(false);
    const [isDestinationAddressEmpty, setIsDestinationAddressEmpty] = useState(false);
    const [isDestinationCityEmpty, setIsDestinationCityEmpty] = useState(false);
    const [isDestinationStateEmpty, setIsDestinationStateEmpty] = useState(false);
    const [isDestinationCountryEmpty, setIsDestinationCountryEmpty] = useState(false);
    const [isDestinationZipEmpty, setIsDestinationZipEmpty] = useState(false);
    const [isWeightEmpty, setIsWeightEmpty] = useState(false);
    const [isLengthEmpty, setIsLengthEmpty] = useState(false);
    const [isBreadthEmpty, setIsBreadthEmpty] = useState(false);
    const [isHeightEmpty, setIsHeightEmpty] = useState(false);

    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const dispatch = useDispatch();
    const accessToken = getAccessToken();

    const { createShiprocketOrderLoading, createShiprocketOrder, createShiprocketOrderError } = useSelector((state: any) => state.createShiprocketOrder);

    const [present] = useIonToast();
    const history = useHistory()

    useEffect(() => {
        if (createShiprocketOrderLoading === false && createShiprocketOrder && createShiprocketOrder.data) {
            history.goBack();
        } else if (createShiprocketOrderLoading === false && createShiprocketOrder && createShiprocketOrder.message) {
            present(createShiprocketOrder.message, 1000)
        }
    }, [createShiprocketOrderLoading, createShiprocketOrder]);

    useEffect(() => {
        if (currentUser && currentUser.data) {
            dispatch(getPickupLocations(accessToken, currentUser.data.store.subdomain));
        }
    }, [currentUser]);

    useEffect(() => {
        if (order && order.data) {
            setDestinationAddress(order.data.delivery_address || "");
            setDestinationCity(order.data.delivery_city || "");
            setDestinationCountry(order.data.delivery_country || "");
            setDestinationZip(order.data.delivery_zip || "");
        }
    }, [order]);

    useEffect(() => {
        if (pickupLocationsLoading === false && pickupLocations && pickupLocations.data) {
            setPickupLocationData(pickupLocations.data);
        }

    }, [pickupLocationsLoading]);

    const handleModalOpen = async () => {
        setShowModal(true)
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res1 = await pickupLocationValidation();
        const res2 = await destinationAddressValidation();
        const res3 = await destinationCityValidation();
        const res4 = await destinationStateValidation();
        const res5 = await destinationCountryValidation();
        const res6 = await weightValidation();
        const res7 = await lengthValidation();
        const res8 = await breadthValidation();
        const res9 = await heightValidation();
        const res10 = await destinationZipValidation();

        if (res1 && res2 && res3 && res4 && res5 && res6 && res7 && res8 && res9 && res10 && order && order.data && order.data.id) {
            dispatch(postShiprocketOrder(order.data.id, accessToken, currentUser.data.store.subdomain, {
                pickup_location: pickupLocation,
                billing_state: destinationState,
                length: length,
                breadth: breadth,
                height: height,
                weight: weight,
                billing_address: destinationAddress,
                billing_city: destinationCity,
                billing_pincode: destinationZip,
                billing_country: destinationCountry,
            }));
        }
        setLoading(false);
    }

    const pickupLocationValidation = () => {
        let result = true;
        if (!pickupLocation) {
            setIsPickupLocationEmpty(true);
            result = false;
        } else {
            setIsPickupLocationEmpty(false);
        }
        return result;
    }

    const destinationAddressValidation = () => {
        let result = true;
        if (!destinationAddress) {
            setIsDestinationAddressEmpty(true);
            result = false;
        } else {
            setIsDestinationAddressEmpty(false);
        }
        return result;
    }

    const destinationCityValidation = () => {
        let result = true;
        if (!destinationCity) {
            setIsDestinationCityEmpty(true);
            result = false;
        } else {
            setIsDestinationCityEmpty(false);
        }
        return result;
    }

    const destinationStateValidation = () => {
        let result = true;
        if (!destinationState) {
            setIsDestinationStateEmpty(true);
            result = false;
        } else {
            setIsDestinationStateEmpty(false);
        }
        return result;
    }

    const destinationCountryValidation = () => {
        let result = true;
        if (!destinationCountry) {
            setIsDestinationCountryEmpty(true);
            result = false;
        } else {
            setIsDestinationCountryEmpty(false);
        }
        return result;
    }

    const destinationZipValidation = () => {
        let result = true;
        if (!destinationZip) {
            setIsDestinationZipEmpty(true);
            result = false;
        } else {
            setIsDestinationZipEmpty(false);
        }
        return result;
    }

    const weightValidation = () => {
        let result = true;
        if (!weight) {
            setIsWeightEmpty(true);
            result = false;
        } else {
            setIsWeightEmpty(false);
        }
        return result;
    }

    const lengthValidation = () => {
        let result = true;
        if (!length) {
            setIsLengthEmpty(true);
            result = false;
        } else {
            setIsLengthEmpty(false);
        }
        return result;
    }

    const breadthValidation = () => {
        let result = true;
        if (!breadth) {
            setIsBreadthEmpty(true);
            result = false;
        } else {
            setIsBreadthEmpty(false);
        }
        return result;
    }

    const heightValidation = () => {
        let result = true;
        if (!height) {
            setIsHeightEmpty(true);
            result = false;
        } else {
            setIsHeightEmpty(false);
        }
        return result;
    }

    return (
        <IonPage>
            <IonHeader style={{height:'68px'}}>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/orders/orderdetails" icon={arrowBackIcon} />
                    </IonButtons>
                    <MobileView>
                        <IonText className='ion-no-margin'>Create Shiprocket Order #{order && order.data ? order.data.id : ""}</IonText>
                    </MobileView>
                    <BrowserView>
                        <IonText className='ion-no-margin'><h5>Create Shiprocket Order #{order && order.data ? order.data.id : ""}</h5></IonText>
                    </BrowserView>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <form onSubmit={submitHandler} noValidate>
                    <IonItem className='ion-no-padding ion-margin-horizontal'>
                        <IonLabel position="stacked"><h1>Pickup Location</h1></IonLabel>
                        <br />
                        <div style={{ width: 100 + '%', display: 'flex', justifyContent: 'space-between' }} onClick={handleModalOpen}>
                            <span style={{ width: 95 + '%' }}>
                                {pickupLocation ? <>
                                    <IonInput value={pickupLocation} readonly></IonInput>
                                </> : <IonInput placeholder="Select Pickup Location" readonly></IonInput>}
                            </span>
                            <span>&#x25BC;</span>
                        </div>
                    </IonItem>

                    <IonModal isOpen={showModal} onDidDismiss={e => setShowModal(false)}>
                        <IonHeader>
                            <IonItem lines="none">
                                <IonText>
                                    <h5>
                                        Select pickup location
                                    </h5>
                                </IonText>
                                <IonButton style={{ marginLeft: 0 }} fill='clear' size='large' slot='end' onClick={() => setShowModal(false)} >
                                    <IonIcon slot="icon-only" icon={closeOutline} />
                                </IonButton>
                            </IonItem>
                        </IonHeader>
                        <IonContent className="ion-padding">
                            {pickupLocationData?.map((data, index) => (
                                <IonItem key={index} style={{ cursor: 'pointer' }} onClick={e => { setPickupLocation(data.pickup_location); setShowModal(false) }}>
                                    <IonText>
                                        <h5>{data.pickup_location}</h5>
                                        <p className="ion-no-margin ion-margin-bottom">{data.address}</p>
                                    </IonText>
                                    {pickupLocation && pickupLocation == data.pickup_location ? <IonIcon slot="end" color="primary" size="large" icon={checkmarkSharpIcon} /> : ""}
                                </IonItem>
                            ))}
                        </IonContent>
                    </IonModal>
                    <IonText color="danger" className={`${isPickupLocationEmpty ? "" : "ion-hide"}`}>
                        <h5 style={{ fontSize: 12 + 'px' }} className="ion-no-margin ion-margin-horizontal">Pickup Location is required.</h5>
                    </IonText>
                    <br />

                    <IonGrid className='ion-margin-horizontal' style={{ padding: "8px 0" }}>
                        <IonRow>
                            <IonCol className='ion-no-padding'>
                                <IonText style={{ fontSize: "18px", fontWeight: 400 }}>Destination Address</IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-justify-content-between'>
                            <IonCol size='8' className='ion-no-padding'>
                                <IonItem className='ion-no-padding' style={{ paddingTop: "12px" }}>
                                    <IonInput type="text" value={destinationAddress} placeholder="Address" onIonChange={(e) => { setDestinationAddress(e.detail.value) }} />
                                </IonItem>
                                <IonText color="danger" className={`${isDestinationAddressEmpty === false ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>Destination Address is required.</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='3' className='ion-no-padding'>
                                <IonItem className='ion-no-padding' style={{ paddingTop: "12px" }}>
                                    <IonInput type="text" value={destinationZip} placeholder="Pin Code" onIonChange={(e) => { setDestinationZip(e.detail.value) }} />
                                </IonItem>
                                <IonText color="danger" className={`${isDestinationZipEmpty === false ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>Destination Pin Code is required.</h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-justify-content-between'>
                            <IonCol size='4' className='ion-no-padding ion-padding-vertical'>
                                <IonItem className='ion-no-padding'>
                                    <IonInput type="text" value={destinationCity} placeholder="City" onIonChange={(e) => { setDestinationCity(e.detail.value) }} />
                                </IonItem>
                                <IonText color="danger" className={`${isDestinationCityEmpty === false ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>Destination City is required.</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='3' className='ion-no-padding ion-padding-vertical'>
                                <IonItem className='ion-no-padding'>
                                    <IonInput type="text" value={destinationState} placeholder="State" onIonChange={(e) => { setDestinationState(e.detail.value) }} />
                                </IonItem>
                                <IonText color="danger" className={`${isDestinationStateEmpty === false ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>Destination State is required.</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='3' className='ion-no-padding ion-padding-vertical'>
                                <IonItem className='ion-no-padding'>
                                    <IonInput type="text" value={destinationCountry} placeholder="Country" onIonChange={(e) => { setDestinationCountry(e.detail.value) }} />
                                </IonItem>
                                <IonText color="danger" className={`${isDestinationCountryEmpty === false ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>Destination Country is required.</h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <IonItem className='ion-no-padding ion-margin-horizontal'>
                        <IonLabel position="stacked"><h1>Weight (in kgs)</h1></IonLabel>
                        <br />
                        <IonInput type="number" value={weight} placeholder="Enter Weight (in kgs)" onIonChange={(e) => { setWeight(e.detail.value) }} />

                    </IonItem>
                    <IonText color="danger" className={`${isWeightEmpty === false ? "ion-hide" : ""}`}>
                        <h5 className="ion-margin-horizontal" style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>Weight is required.</h5>
                    </IonText>
                    <br />

                    <IonGrid className='ion-margin-horizontal' style={{ padding: "8px 0" }}>
                        <IonRow>
                            <IonCol className='ion-no-padding'>
                                <IonText style={{ fontSize: "18px", fontWeight: 400 }}>Package Dimensions</IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-justify-content-between'>
                            <IonCol sizeSm='4' sizeXs='12' className='ion-no-padding ion-padding-vertical zero-padding'>
                                <IonItem className='ion-no-padding'>
                                    <IonInput type="number" value={length} placeholder="Enter Length (in cms)" onIonChange={(e) => { setLength(e.detail.value) }} />
                                </IonItem>

                                <IonText color="danger" className={`${isLengthEmpty === false ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>Length is required.</h5>
                                </IonText>
                            </IonCol>
                            <IonCol sizeSm='3' sizeXs='12' className='ion-no-padding ion-padding-vertical zero-padding'>
                                <IonItem className='ion-no-padding'>
                                    <IonInput type="number" value={breadth} placeholder="Enter Breadth (in cms)" onIonChange={(e) => { setBreadth(e.detail.value) }} />
                                </IonItem>

                                <IonText color="danger" className={`${isBreadthEmpty === false ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>Breadth is required.</h5>
                                </IonText>
                            </IonCol>
                            <IonCol sizeSm='3' sizeXs='12' className='ion-no-padding ion-padding-vertical zero-padding'>
                                <IonItem className='ion-no-padding'>
                                    <IonInput type="number" value={height} placeholder="Enter Height (in cms)" onIonChange={(e) => { setHeight(e.detail.value) }} />
                                </IonItem>

                                <IonText color="danger" className={`${isHeightEmpty === false ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>Height is required.</h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <br />
                    <IonButton className='ion-margin-horizontal' type="submit">Create Shiprocket Order</IonButton>
                </form>

                <IonLoading isOpen={loading || pickupLocationsLoading}></IonLoading>
            </IonContent>
        </IonPage >
    )
}

export default CreateShiprocketOrder