import { useState, useEffect } from 'react';
import { IonAlert, IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonRadio, IonRadioGroup, IonRow, IonSpinner, IonText, IonTitle, IonToolbar, IonicSafeString, isPlatform } from '@ionic/react';
import {
    informationCircle as infoIcon,
    closeOutline
} from 'ionicons/icons';
import './Packages.css'
import useFetch from '../../utils/useFetch';
import useAPI from '../../utils/api';

interface ConfirmSubscriptionDetails {
    status?: string;
    subscription_plan_name?: string;
    subscription_end_date?: string;
}

const Packages = () => {
    const API = useAPI();

    const [isOpen, setIsOpen] = useState(false);
    const [isInfoOpen, setIsInfoOpen] = useState(false);
    const [isThankyou, setIsThankyou] = useState(false);
    const [totalSubscriptionMonths, setTotalSubscriptionMonths] = useState('0');
    const [subscriptionText, setSubscriptionText] = useState("");
    const [packageName, setPackageName] = useState("BASIC");
    const [currentSubscribedPackageName, setCurrentSubscribedPackageName] = useState("");

    const [currentSubscribedID, setCurrentSubscribedID] = useState(0);
    const [infoModalTitle, setInfoModalTitle] = useState("");
    const [infoModalBody, setInfoModalBody] = useState("");

    const [couponCode, setCouponCode] = useState("");
    const [isCouponCodeValidate, setIsCouponCodeValidate] = useState(true);
    const [isCouponCodeAvailable, setIsCouponCodeAvailable] = useState(true);
    const [couponAvailableMsg, setCouponAvailableMsg] = useState("");
    const [couponLoading, setCouponLoading] = useState(false);
    const [discount, setDiscount] = useState(null);
    const [makePaymentLoading, setMakePaymentLoading] = useState(false);
    const [isNewUser, setIsNewUser] = useState(false);

    const [isConfirmSubscriptionLoading, setIsConfirmSubscriptionLoading] = useState(false);
    const [confirmSubscriptionDetails, setConfirmSubscriptionDetails] = useState<ConfirmSubscriptionDetails>({});

    const infoModalData = {
        "Website on Subdomain":
            "Free website on subdomain like https://yourbusiness.intelikart.com",
        "Web Store Manager":
            "Store manager accessible on desktop and from anywhere https://manage.intelikart.com",
        "Cash on Delivery": "Enable CoD for orders of your customers",
        "Mobile and Desktop Website":
            "Responsive websites look good on smartphones, tablets and laptops",
        "SEO Friendly Website":
            "SEO friendly websites to make your business listing appear on Google search results",
        "Premium Features":
            "Premium marketing platform, Invite only webinars & Dedicated customer success manager",
        "Android Mobile App":
            "Native Android mobile app live on google playstore",
        "Website on your domain":
            "Host your website on your domain like https://yourbusiness.com with unlimited hosting support. If you don't have domain, our customer support team will assist you to get one",
        "Payment Gateway":
            "Using online payment gateway, you can provide option to take payments online for orders of your customers. Please note that all online transaction attract around 2% payment gateway fee",
        "Banners": "Using Banners you can announce about deals and announcements",
        "Coupons": "Using Coupons you can offer discounts for your online sales",
        "Digital Visiting Card":
            "Digital visiting cards can be shared over social media and whatsapp",
        "QR Code for Store":
            "Your customers can scan QR Code to browse your website or to download your app from playstore",
        "Unlimited Products": "List unlimited products on your store",
        "Custom Themes":
            "Using custom themes, you can change the look and feel of your app and website",
        "Premium Marketing Platform":
            "Get capability for push-notification and announcements, email and sms marketing",
        "Invite-only Webinars":
            "In invite only webinars our marketing experts share tips and tricks to grow your business online",
        "Delivery Partners":
            "To deliver your products in other cities, you can enable delivery partners to handle your order deliveries",
        "Marketing Support":
            "Get personalised advice on marketing for your business from our marketing experts",
        "Premium Tech Support":
            "Prioritise support for new features and support",
        "Dedicated Account Manager":
            "Dedicated customer success manager for you",
        "Quick Orders":
            "Quickly receive orders as images of prescription or grocery list",
        "IK Academy":
            "Free video tutorials and blogs to learn and grow your business",
        "Bulk Product Upload":
            "Quickly add your products and categories using bulk upload in Web Store Manager",
        "Customers List": "See your customers list and engage with them",
        "Order Settings": "Minimum order, store timings and delivery range",
        "Taxes and Charges":
            "Customizable taxes like GST and charges like delivery and packaging charges",
        "SSL Certificate":
            "SSL certificates are used for securing your online store",
    };

    const planPrice = {
        // plan_name : [3 months, 12 months, 24 months, 36 months]
        "BASIC": [1999, 4999, 7999, 11999],
        "PRO": [2999, 7999, 11999, 18999],
        "ADVANCE": [3999, 11999, 18999, 24999]
    }

    const openModal = (pName) => {
        setIsOpen(true);
        setPackageName(pName);
        setTotalSubscriptionMonths('0');
    };

    const closeModal = () => {
        setIsOpen(false);
        setCouponCode("");
        setIsCouponCodeValidate(true);
        setIsCouponCodeAvailable(true);
        setCouponAvailableMsg("");
        setCouponLoading(false);
        setMakePaymentLoading(false);
        setDiscount(null);
    };

    const removeCoupon = () => {
        setCouponCode("");
        setIsCouponCodeValidate(true);
        setIsCouponCodeAvailable(true);
        setCouponAvailableMsg("");
        setCouponLoading(false);
        setMakePaymentLoading(false);
        setDiscount(null);
    };

    const openInfoModal = (info) => {
        setInfoModalTitle(info);
        setInfoModalBody(infoModalData[info]);
        setIsInfoOpen(true);
    };

    const closeModalThankyou = () => {
        setIsThankyou(false);
        setConfirmSubscriptionDetails({});
        setIsConfirmSubscriptionLoading(false);

        // redirect to actual url
        window.location.href = `${window.location.origin}/subscriptions`;

        // remove from localStorage
        removeFromLocalStorage("coupon");
        removeFromLocalStorage("subscription_id");
    };

    const handleSubscriptionSelectionChange = (value) => {
        setTotalSubscriptionMonths(value);
    };

    const saveToLocalStorage = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    };

    const getFromLocalStorage = (key) => {
        const itemStr = localStorage.getItem(key);
        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        return item;
    };

    const removeFromLocalStorage = (key) => {
        localStorage.removeItem(key);
    };

    const goToPayment = async () => {
        let subscriptionPrice = 0;

        const mapMonthArray = {
            "3": 0,
            "12": 1,
            "24": 2,
            "36": 3,
        };

        const originalPrice = parseInt(
            planPrice[packageName][mapMonthArray[totalSubscriptionMonths]]
        );

        if (discount && discount > 0)
            subscriptionPrice = Math.round(
                originalPrice - (originalPrice * discount) / 100
            );
        else subscriptionPrice = originalPrice;

        const subscriptionData = {
            subscription_time_months: totalSubscriptionMonths,
            subscription_price: subscriptionPrice,
            subscription_plan_name: packageName,
        };

        try {
            setMakePaymentLoading(true);
            const [ok, res] = await API.createSubscription(subscriptionData);
            if (ok) {
                const coupon =
                    couponCode && discount > 0 ? couponCode : null;

                saveToLocalStorage("coupon", coupon);
                saveToLocalStorage("subscription_id", res["id"]);

                const redirectUrl = `${window.location.origin}/subscriptions?confirmSub=1`;

                const payReq = {
                    "ik-payment-token": res["ik-payment-token"],
                    "redirect-url": redirectUrl,
                };

                const [payok, paymentRes] = await API.paySubscription(payReq);

                if (payok && paymentRes && paymentRes.url) {

                    const phonepeUrl = paymentRes.url;

                    closeModal();

                    window.location.href = phonepeUrl;
                } else {
                    setMakePaymentLoading(false);
                }
            }
        }
        catch (err) {
            console.log(err);
            setMakePaymentLoading(false);
        }
    };

    const validateCoupon = async (e) => {
        e.preventDefault();
        let result = true;

        setIsCouponCodeValidate(true);
        setIsCouponCodeAvailable(true);

        if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(couponCode)) {
            setIsCouponCodeValidate(false);
            setCouponAvailableMsg('');
            setDiscount(null);

            result = false;
        } else if (result) {
            try {
                setIsCouponCodeValidate(true)
                setCouponLoading(true)
                const [ok, res] = await API.subscriptionCouponValidate(couponCode);
                if (ok) {
                    if (!res.valid) {
                        setIsCouponCodeAvailable(false);
                        setCouponLoading(false);
                        setCouponAvailableMsg(res.message);
                        setDiscount(null);
                        result = false;
                    } else {
                        setCouponAvailableMsg(res.message);
                        setCouponLoading(false);
                        setDiscount(res.value);
                    }
                }
            }
            catch (err) {
                setCouponLoading(false);
                setCouponAvailableMsg('');
                setDiscount(null);
                console.error(err);
            }
            return result;
        }
    }

    const confirmSubscription = async (
        couponCode,
        subscription_id
    ) => {
        try {
            const [ok, response] = await API.confirmSubscription(subscription_id, couponCode);
            if (ok && response && response.status) {
                setConfirmSubscriptionDetails(response);
                setIsConfirmSubscriptionLoading(false);
            }
        } catch (err) {
            setIsConfirmSubscriptionLoading(false);
            console.log(err);
        }
    };

    const { loading, data, status } = useFetch("subscriptionInfo");
    useEffect(() => {

        if (!loading && status === "fetched" && data) {
            setIsOpen(false)
            setSubscriptionText(data.subscriptionText)
            setCurrentSubscribedPackageName(data.currentSubscriptionName)
            setCurrentSubscribedID(data.subscriptionID)
            setIsNewUser(data.isNewUser)

            // check if thankyou page
            const queryString = window.location.search;
            if (queryString.includes("confirmSub=1")) {
                setIsThankyou(true)
                setIsConfirmSubscriptionLoading(true)

                let coupon = getFromLocalStorage("coupon");
                let subscription_id = getFromLocalStorage("subscription_id");

                confirmSubscription(coupon, subscription_id);
            }
        }

    }, [data, status, loading])

    return (
        <>
            <div>
                <section id="contact" className="contact">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12" sizeMd="6" offsetMd='3'>
                                <IonCard className='ion-text-center'>
                                    <IonText color="primary">
                                        <h3 style={{ fontSize: "14px" }}>
                                            {subscriptionText}
                                        </h3>
                                    </IonText>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </section>

                <section id="pricing" className="pricing">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12" sizeMd="6" sizeLg="3">
                                <IonCard>
                                    <IonCardHeader color='primary'>
                                        <IonText>
                                            <h3 className='ion-text-center ion-no-margin' style={{ fontSize: "16px" }}>BASIC
                                            </h3>
                                            {currentSubscribedPackageName === "BASIC" ? (
                                                <IonBadge color='danger'>Active</IonBadge>
                                            ) : (
                                                ""
                                            )}
                                        </IonText>

                                    </IonCardHeader>
                                    <IonCardContent className='ion-text-center ion-margin-vertical'>
                                        <IonText color="primary" className='ion-margin-bottom'>
                                            <p className='ion-padding-bottom'>
                                                <b>Top Domain Website</b>
                                            </p>
                                            <h4>
                                                <sup>₹</sup>
                                                {planPrice["BASIC"][0]}
                                                <span color='secondary'> / quarterly</span>
                                            </h4>
                                        </IonText>
                                        <ul>
                                            <li>Everything in FREE </li>
                                            <li>
                                                Website on your domain{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Website on your domain"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Payment Gateway{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Payment Gateway"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Banners{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Banners"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Coupons{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Coupons"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Digital Visiting Card{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Digital Visiting Card"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                QR Code for Store{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "QR Code for Store"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Unlimited Products{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Unlimited Products"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                <s>Android Mobile App{" "}
                                                    <span
                                                        onClick={() =>
                                                            openInfoModal(
                                                                "Android Mobile App"
                                                            )
                                                        }
                                                    >
                                                        <IonIcon icon={infoIcon} />
                                                    </span></s>
                                            </li>
                                        </ul>
                                        {
                                            isPlatform('capacitor') ? <IonButton
                                                shape='round'
                                                href='https://web.intelikart.com/subscriptions'
                                                target="_blank"
                                            >
                                                Buy Now
                                            </IonButton>
                                                :
                                                <IonButton
                                                    shape='round'
                                                    onClick={() => openModal("BASIC")}
                                                >
                                                    Buy Now
                                                </IonButton>}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                            <IonCol size="12" sizeMd="6" sizeLg="3">
                                <IonCard>
                                    <IonCardHeader color='primary'>
                                        <IonText>
                                            <h3 className='ion-text-center ion-no-margin' style={{ fontSize: "16px" }}>PRO</h3>
                                            {currentSubscribedPackageName === "PRO" ? (
                                                <IonBadge color='danger'>Active</IonBadge>
                                            ) : (
                                                ""
                                            )}
                                        </IonText>
                                    </IonCardHeader>
                                    <IonCardContent className='ion-text-center ion-margin-vertical'>
                                        <IonText color="primary ">
                                            <p className='ion-padding-bottom'>
                                                <b>Native Android Application</b>
                                            </p>
                                            <h4>
                                                <sup>₹</sup>
                                                {planPrice['PRO'][0]}
                                                <span> / quarterly</span>
                                            </h4>
                                        </IonText>
                                        <ul>
                                            <li>Everything in FREE </li>
                                            <li>
                                                Android Mobile App{' '}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            'Android Mobile App'
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Payment Gateway{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Payment Gateway"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Banners{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Banners"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Coupons{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Coupons"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                QR Code for Store{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "QR Code for Store"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Unlimited Products{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Unlimited Products"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Custom Themes{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Custom Themes"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                <s>Website on your domain{" "}
                                                    <span
                                                        onClick={() =>
                                                            openInfoModal(
                                                                "Website on your domain"
                                                            )
                                                        }
                                                    >
                                                        <IonIcon icon={infoIcon} />
                                                    </span></s>
                                            </li>
                                        </ul>
                                        {
                                            isPlatform('capacitor') ? <IonButton
                                                shape='round'
                                                href='https://web.intelikart.com/subscriptions'
                                                target="_blank"
                                            >
                                                Buy Now
                                            </IonButton>
                                                :
                                                <IonButton
                                                    shape='round'
                                                    onClick={() => openModal('PRO')}
                                                >
                                                    Buy Now
                                                </IonButton>
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                            {/* ADVANCE Plan */}
                            <IonCol size="12" sizeMd="6" sizeLg="3" className="mt-4 mt-md-0">
                                <IonCard>
                                    <IonCardHeader color='primary'>
                                        <IonText>
                                            <h3 className='ion-text-center ion-no-margin' style={{ fontSize: "16px" }}>ADVANCE</h3>
                                            {currentSubscribedPackageName === "ADVANCE" ? (
                                                <IonBadge color='danger'>Active</IonBadge>
                                            ) : (
                                                ""
                                            )}
                                        </IonText>
                                    </IonCardHeader>
                                    <IonCardContent className='ion-text-center ion-margin-vertical'>
                                        <IonText color="primary">
                                            <p className='ion-padding-bottom'>
                                                <b>Website + Android App</b>
                                            </p>
                                            <h4>
                                                <sup>₹</sup>
                                                {planPrice['ADVANCE'][0]}
                                                <span> / quarterly</span>
                                            </h4>
                                        </IonText>
                                        <ul>
                                            <li>Everything in BASIC + PRO </li>
                                            <li>
                                                Premium Features{' '}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal('Premium Features')
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Premium Marketing Platform{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Premium Marketing Platform"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Invite-only Webinars{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Invite-only Webinars"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Delivery Partners{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Delivery Partners"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Marketing Support{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Marketing Support"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Premium Tech Support{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Premium Tech Support"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Dedicated Account Manager{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Dedicated Account Manager"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                        </ul>
                                        {
                                            isPlatform('capacitor') ? <IonButton
                                                shape='round'
                                                href='https://web.intelikart.com/subscriptions'
                                                target="_blank"
                                            >
                                                Buy Now
                                            </IonButton>
                                                :
                                                <IonButton
                                                    shape='round'
                                                    onClick={() => openModal('ADVANCE')}
                                                >
                                                    Buy Now
                                                </IonButton>
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                            {/* FREE Plan */}
                            <IonCol size="12" sizeMd="6" sizeLg="3">
                                <IonCard>
                                    <IonCardHeader color='primary'>
                                        <IonText>
                                            <h3 className='ion-text-center ion-no-margin' style={{ fontSize: "16px" }}>FREE</h3>
                                            {currentSubscribedPackageName === "FREE" ? (
                                                <IonBadge color='danger'>Active</IonBadge>
                                            ) : (
                                                ""
                                            )}
                                        </IonText>
                                    </IonCardHeader>
                                    <IonCardContent className='ion-text-center ion-margin-vertical'>
                                        <IonText color="primary">
                                            <p className='ion-padding-bottom'>
                                                <b>Subdomain Website</b>
                                            </p>
                                            <h4>
                                                <sup>₹</sup>0<span> / forever</span>
                                            </h4>
                                        </IonText>
                                        <ul className='ion-no-margin'>
                                            <li>
                                                Website on Subdomain{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Website on Subdomain"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Web Store Manager{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Web Store Manager"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Cash on Delivery{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Cash on Delivery"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Mobile & Desktop Website{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Mobile and Desktop Website"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                SEO Friendly Website{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "SEO Friendly Website"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Quick Orders{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Quick Orders"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Customers List{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Customers List"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                IK Academy{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "IK Academy"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                Taxes and Charges{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "Taxes and Charges"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>
                                            <li>
                                                SSL Certificate{" "}
                                                <span
                                                    onClick={() =>
                                                        openInfoModal(
                                                            "SSL Certificate"
                                                        )
                                                    }
                                                >
                                                    <IonIcon icon={infoIcon} />
                                                </span>
                                            </li>

                                            <li>
                                                <s>Premium Features{" "}
                                                    <span
                                                        onClick={() =>
                                                            openInfoModal(
                                                                "Premium Features"
                                                            )
                                                        }
                                                    >
                                                        <IonIcon icon={infoIcon} />
                                                    </span></s>
                                            </li>
                                            <li style={{ paddingBottom: 0 }}>
                                                <s>Android Mobile App{" "}
                                                    <span
                                                        onClick={() =>
                                                            openInfoModal(
                                                                "Android Mobile App"
                                                            )
                                                        }
                                                    >
                                                        <IonIcon icon={infoIcon} />
                                                    </span></s>
                                            </li>
                                        </ul>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                        </IonRow>
                    </IonGrid>
                </section>
            </div>

            <IonModal isOpen={isOpen} onDidDismiss={closeModal}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle color="primary">
                            {packageName} Plan{' '}
                            <span className="month-price">
                                (₹ {planPrice[packageName][0]} / quarterly)
                            </span>
                        </IonTitle>
                        <IonButton style={{ marginLeft: 0 }} fill='clear' size='large' slot='end' onClick={closeModal} >
                            <IonIcon slot="icon-only" icon={closeOutline} />
                        </IonButton>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <div className="ion-padding">
                        {couponLoading ? (
                            <div className="ion-align-items-center ion-justify-content-center" style={{ height: "350px", display: "flex" }}>
                                <IonSpinner name="crescent" color='primary'></IonSpinner>
                            </div>
                        ) : (
                            <>
                                <IonText>
                                    <strong>Select the duration of your subscription: </strong>
                                </IonText>

                                <IonList>
                                    <IonItem lines='none' style={{ cursor: 'pointer' }} className={`duration-box ${totalSubscriptionMonths === "36" ? "checked" : ""}`} onClick={() => { handleSubscriptionSelectionChange('36') }}>
                                        <IonGrid>
                                            <IonRow>
                                                <IonCol size='6'>
                                                    <IonText>
                                                        <span className="duration">
                                                            36 months
                                                        </span>
                                                    </IonText>
                                                </IonCol>
                                                <IonCol size='6' className='ion-text-right'>
                                                    {discount ? (
                                                        <IonText>
                                                            <s>
                                                                <span className="originalPrice">
                                                                    ₹{" "}
                                                                    {parseInt(
                                                                        planPrice[
                                                                        packageName
                                                                        ][3]
                                                                    )}
                                                                </span>
                                                            </s>
                                                            <span className="discountedPrice">
                                                                {" "}
                                                                ₹{" "}
                                                                {Math.round(
                                                                    parseInt(
                                                                        planPrice[
                                                                        packageName
                                                                        ][3]
                                                                    ) -
                                                                    (parseInt(
                                                                        planPrice[
                                                                        packageName
                                                                        ][3]
                                                                    ) *
                                                                        discount) /
                                                                    100
                                                                )}
                                                            </span>
                                                        </IonText>
                                                    ) : (
                                                        <IonText>
                                                            <span className="discountedPrice">
                                                                ₹{" "}
                                                                {parseInt(
                                                                    planPrice[
                                                                    packageName
                                                                    ][3]
                                                                )}
                                                            </span>
                                                        </IonText>
                                                    )}
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonItem>

                                    <IonItem lines='none' style={{ cursor: 'pointer' }} className={`duration-box ${totalSubscriptionMonths === "24" ? "checked" : ""}`} onClick={() => { handleSubscriptionSelectionChange('24') }}>
                                        <IonGrid>
                                            <IonRow>
                                                <IonCol size='6'>
                                                    <IonText>
                                                        <span className="duration">
                                                            24 months
                                                        </span>
                                                    </IonText>
                                                </IonCol>
                                                <IonCol size='6' className='ion-text-right'>
                                                    {discount ? (
                                                        <IonText>
                                                            <s>
                                                                <span className="originalPrice">
                                                                    ₹{" "}
                                                                    {parseInt(
                                                                        planPrice[
                                                                        packageName
                                                                        ][2]
                                                                    )}
                                                                </span>
                                                            </s>
                                                            <span className="discountedPrice">
                                                                {" "}
                                                                ₹{" "}
                                                                {Math.round(
                                                                    parseInt(
                                                                        planPrice[
                                                                        packageName
                                                                        ][2]
                                                                    ) -
                                                                    (parseInt(
                                                                        planPrice[
                                                                        packageName
                                                                        ][2]
                                                                    ) *
                                                                        discount) /
                                                                    100
                                                                )}
                                                            </span>
                                                        </IonText>
                                                    ) : (
                                                        <IonText>
                                                            <span className="discountedPrice">
                                                                ₹{" "}
                                                                {parseInt(
                                                                    planPrice[
                                                                    packageName
                                                                    ][2]
                                                                )}
                                                            </span>
                                                        </IonText>
                                                    )}
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonItem>

                                    <IonItem lines='none' style={{ cursor: 'pointer' }} className={`duration-box ${totalSubscriptionMonths === "12" ? "checked" : ""}`} onClick={() => { handleSubscriptionSelectionChange('12') }}>
                                        <IonGrid>
                                            <IonRow>
                                                <IonCol size='6'>
                                                    <IonText>
                                                        <span className="duration">
                                                            12 months
                                                        </span>
                                                    </IonText>
                                                </IonCol>
                                                <IonCol size='6' className='ion-text-right'>
                                                    {discount ? (
                                                        <IonText>
                                                            <s>
                                                                <span className="originalPrice">
                                                                    ₹{" "}
                                                                    {parseInt(
                                                                        planPrice[
                                                                        packageName
                                                                        ][1]
                                                                    )}
                                                                </span>
                                                            </s>
                                                            <span className="discountedPrice">
                                                                {" "}
                                                                ₹{" "}
                                                                {Math.round(
                                                                    parseInt(
                                                                        planPrice[
                                                                        packageName
                                                                        ][1]
                                                                    ) -
                                                                    (parseInt(
                                                                        planPrice[
                                                                        packageName
                                                                        ][1]
                                                                    ) *
                                                                        discount) /
                                                                    100
                                                                )}
                                                            </span>
                                                        </IonText>
                                                    ) : (
                                                        <IonText>
                                                            <span className="discountedPrice">
                                                                ₹{" "}
                                                                {parseInt(
                                                                    planPrice[
                                                                    packageName
                                                                    ][1]
                                                                )}
                                                            </span>
                                                        </IonText>
                                                    )}
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonItem>

                                    {isNewUser && (
                                        <IonItem lines='none' style={{ cursor: 'pointer' }} className={`duration-box ${totalSubscriptionMonths === "3" ? "checked" : ""}`} onClick={() => { handleSubscriptionSelectionChange('3') }}>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size='6'>
                                                        <IonText>
                                                            <span className="duration">
                                                                3 months
                                                            </span>
                                                        </IonText>
                                                    </IonCol>
                                                    <IonCol size='6' className='ion-text-right'>
                                                        {discount ? (
                                                            <IonText>
                                                                <s>
                                                                    <span className="originalPrice">
                                                                        ₹{" "}
                                                                        {parseInt(
                                                                            planPrice[
                                                                            packageName
                                                                            ][0]
                                                                        )}
                                                                    </span>
                                                                </s>
                                                                <span className="discountedPrice">
                                                                    {" "}
                                                                    ₹{" "}
                                                                    {Math.round(
                                                                        parseInt(
                                                                            planPrice[
                                                                            packageName
                                                                            ][0]
                                                                        ) -
                                                                        (parseInt(
                                                                            planPrice[
                                                                            packageName
                                                                            ][0]
                                                                        ) *
                                                                            discount) /
                                                                        100
                                                                    )}
                                                                </span>
                                                            </IonText>
                                                        ) : (
                                                            <IonText>
                                                                <span className="discountedPrice">
                                                                    ₹{" "}
                                                                    {parseInt(
                                                                        planPrice[
                                                                        packageName
                                                                        ][0]
                                                                    )}
                                                                </span>
                                                            </IonText>
                                                        )}
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>
                                    )}
                                </IonList>

                                {discount ? (
                                    <>
                                        <IonText color='success'>
                                            <p className="coupon-success-msg">
                                                Coupon code applied successfully
                                            </p>
                                        </IonText>
                                        <IonGrid className="coupon-box">
                                            <IonRow>
                                                <IonCol size="8">
                                                    <IonText color='primary'>
                                                        <h5 className='ion-no-margin'>{couponCode}</h5>
                                                    </IonText>
                                                    <IonText>
                                                        <p className="ion-no-margin" style={{ fontSize: "12px", fontWeight: 600 }}>({discount}% off)</p>
                                                    </IonText>
                                                </IonCol>
                                                <IonCol size="4" className="ion-text-right ion-no-margin">
                                                    <IonButton
                                                        color='danger'
                                                        fill="clear"
                                                        onClick={removeCoupon}
                                                    >
                                                        Remove
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </>
                                ) : (
                                    <IonGrid className='ion-no-padding'>
                                        <IonRow className='ion-no-padding'>
                                            <IonCol size='12' style={{ marginTop: "6px", marginBottom: "6px" }}>
                                                <IonLabel><strong>Have a coupon code:</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>

                                        <IonRow>
                                            <IonCol size='10' className='ion-no-padding'>
                                                <div
                                                    className='couponInput'
                                                >
                                                    <IonInput
                                                        style={{ paddingTop: "5px", paddingBottom: "5px" }}
                                                        type="text"
                                                        placeholder="Your Coupon code"
                                                        value={couponCode}
                                                        name="couponCode"
                                                        id="couponCode"
                                                        onIonChange={(e) => setCouponCode(e.detail.value?.toUpperCase())}
                                                    />
                                                </div>

                                                <IonText color="danger">
                                                    <h5
                                                        style={{
                                                            fontSize: '12px',
                                                            marginTop: '5px',
                                                        }}
                                                        className={isCouponCodeValidate ? 'ion-hide' : ''}
                                                    >
                                                        Coupon Code can't contain special characters.
                                                    </h5>
                                                    <h5
                                                        style={{
                                                            fontSize: '12px',
                                                            marginTop: '5px',
                                                        }}
                                                        className={isCouponCodeAvailable ? 'ion-hide' : ''}
                                                    >
                                                        {couponAvailableMsg}
                                                    </h5>
                                                </IonText>
                                            </IonCol>
                                            <IonCol size='2'>
                                                <div className='couponButton'>
                                                    <IonButton
                                                        className=' ion-no-margin'
                                                        expand='full'
                                                        fill="clear"
                                                        onClick={validateCoupon}
                                                        disabled={!couponCode}
                                                    >
                                                        Apply
                                                    </IonButton>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                )}

                                {totalSubscriptionMonths.toString() !== '0' ? (
                                    <div className="ion-text-center" style={{ marginTop: "16px" }}>
                                        <IonButton
                                            shape='round'
                                            color="primary"
                                            onClick={goToPayment}
                                            disabled={couponLoading || makePaymentLoading}
                                        >
                                            {makePaymentLoading ? 'Making Payment...' : 'Make Payment Now'}
                                        </IonButton>
                                    </div>
                                ) : (
                                    <div className="ion-text-center" style={{ marginTop: "16px" }}>
                                        <IonButton shape='round' color="primary">
                                            Select Your Plan
                                        </IonButton>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </IonContent>
            </IonModal>

            {!isConfirmSubscriptionLoading ? <IonAlert
                isOpen={isThankyou}
                onDidDismiss={closeModalThankyou}
                header="Thank You"
                message={confirmSubscriptionDetails?.status == 'success' ? new IonicSafeString(`
                <ion-text>
                <p class="ion-no-margin">Thank you for subscribing. We wish growth for your business.</p>
                <p style="margin-bottom: 6px"><b>Subscription Plan: </b><br/>${confirmSubscriptionDetails?.subscription_plan_name}</p>
                    <p class="ion-no-margin"><b>Renewal Date: </b><br/>${confirmSubscriptionDetails?.subscription_end_date
                        ? new Date(confirmSubscriptionDetails?.subscription_end_date)?.toDateString()
                        : ''}</p>
                </ion-text>
                `) : new IonicSafeString(`<ion-text>
                    <p class="ion-no-margin">
                        <b>Payment Status: </b><br />
                        ${confirmSubscriptionDetails?.status?.toUpperCase()}
                    </p>
                    </ion-text>
                `)}
                buttons={[
                    {
                        text: 'OK',
                        handler: closeModalThankyou,
                    },
                ]}
            /> :
                <IonLoading isOpen={true} message="Please Wait"></IonLoading>}

            <IonAlert
                isOpen={isInfoOpen}
                onDidDismiss={() => setIsInfoOpen(false)}
                header={infoModalTitle}
                message={infoModalBody}
                buttons={[
                    {
                        text: "CLOSE",
                        role: "cancel",
                    }
                ]}
            />

            <IonLoading isOpen={loading} message="Please Wait"></IonLoading>
        </>
    )
};

export default Packages;
