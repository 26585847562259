import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonText } from '@ionic/react';
import { create as createIcon, trash as trashIcon } from 'ionicons/icons';
import { useState } from 'react';
import { alphaNumericValidation, numericValidation } from '../../../utils/validations';

const DynamicProductFields = ({fieldsData, setFieldsData}) => {
    const [field, setField] = useState({
        name: "",
        value: ""
    });
    const [editingIndex, setEditingIndex] = useState(null);
    const [validation, setValidation] = useState({
        name: "",
        value: "",
    });
    function validatingFunction(type){
        if(type === "name"){
            if(!alphaNumericValidation(field.name) || numericValidation(field.name)){
                setValidation({...validation, name: "Field name is required and should not contain special characters and only numbers."});
            }else{
                setValidation({...validation, name: ""});
            }
        }
        if(type === "value"){
            if(!alphaNumericValidation(field.value)){
                setValidation({...validation, value: "Field value is required and should not contain special characters."});
            }else{
                setValidation({...validation, value: ""});
            }
        }
    }

    function AddToList(){
        if(!field.name){
            validatingFunction("name")
        }
        else if(!field.value){
            validatingFunction("value")
        }
        else{
        if(!(validation.name || validation.value)){
            if(editingIndex !== null){
                setFieldsData((prev)=> {
                    let temp = [...prev]; 
                    temp.splice(editingIndex, 1, field);
                    return temp;
                });
                setEditingIndex(null);
            }else{
                setFieldsData([...fieldsData, field]);
            }
            setField({
                name: "",
                value: ""
            })
        }}
    }

    return (<>
        <h5 className="ion-padding-start ion-padding-top">Product Fields</h5>
        <IonGrid>
            <IonRow className='ion-wrap'>
                {fieldsData && fieldsData.map((elem, index)=>{
                    return <IonCard key={elem + index}>
                        <IonCardHeader>
                            <IonItem lines='none' className='ion-no-padding'>
                                <IonCardSubtitle style={{fontSize: '18px'}}>
                                    <span>Name: {elem.name} </span>
                                    <br/> 
                                    <span>Value: {elem.value} </span>
                                </IonCardSubtitle>
                                <IonButton slot='end' onClick={e=> {setField(elem); setEditingIndex(index);}}>
                                    <IonIcon icon={createIcon}/>
                                </IonButton>
                                <IonButton color="danger" slot='end' onClick={e => setFieldsData((prev)=> {
                                    let temp = [...prev];
                                    temp.splice(index,1);
                                    return temp;
                                })}>
                                    <IonIcon icon={trashIcon}/>
                                </IonButton>
                            </IonItem>
                        </IonCardHeader>
                    </IonCard>})
                }
            </IonRow>
        </IonGrid>
        
        <IonCard>
            <IonCardContent className="zero-padding-horizontal">
                <IonItem>
                    <IonLabel position="stacked"><h1 className="label">Field name</h1></IonLabel>
                    <IonInput placeholder="Enter the Field name" type="text" value={field.name} onIonChange={(e)=>{ setField({...field, name: e.detail.value}) }} onIonBlur={e=> validatingFunction("name")}/>
                </IonItem>
                <IonText color="danger" className={`${validation.name ? "": "ion-hide" }`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className="ion-margin-horizontal">{validation.name}</h5>
                </IonText>
                <IonItem>
                    <IonLabel position="stacked"><h1 className="label">Field value</h1></IonLabel>
                    <IonInput placeholder="Enter the Field value" type="text" value={field.value} onIonChange={(e)=>{ setField({...field, value: e.detail.value}) }} onIonBlur={e=> validatingFunction("value")}/>
                </IonItem>
                <IonText color="danger" className={`${validation.value ? "": "ion-hide" }`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className="ion-margin-horizontal">{validation.value}</h5>
                </IonText>
                <br/>
                <div className="ion-text-center">
                    <IonButton slot='end' onClick={e=> AddToList()}>Add Field</IonButton>
                </div>
            </IonCardContent>
        </IonCard>
    </>)
}

export default DynamicProductFields