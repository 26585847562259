import {
    IonButton,
    IonButtons,
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonToolbar
} from "@ionic/react";
import {
    closeOutline as closeOutlineIcon,
    pencil as pencilIcon,
  trash as trashIcon,
  informationCircleOutline as infoIcon,
} from "ionicons/icons";
import { useState } from "react";
import useAPI from "../../../utils/api";
import { useUser } from "../../../utils/contexts";

const Adjustments = ({order, showModal, setShowModal, currency, updateOrder, setUpdateorder }) => {
    const [message, setMessage] = useState<any>("");
    const [type, setType] = useState("ADD");
    const [adjustmentEdited, setAdjustmentEdited] = useState<any>();
    const [value, setValue] = useState("");
    const [validation, setValidation] = useState({});
    
    const API = useAPI();
    const currentUser: any = useUser();

    const validate = async () => {
        let temp = { ...validation };
        let result = true;

        if (!message) {
            temp["messageValidation"] = "Adjustment Message is required";
            result = false;
        } else {
            temp["messageValidation"] = "";
        }

        if (!value) {
            temp["valueValidation"] = "Adjustment Value is required";
            result = false;
        } else {
            if (/\D/.test(value)) {
                temp["valueValidation"] =
                "Adjustment Value should contain numbers only";
                result = false;
            } else {
                temp["valueValidation"] = "";
            }
        }
        await setValidation(temp);
        return result;
    };

    const addOrEditAdjustment = async () => {
        const res = await validate();
        if (res) {
            const [ok, response] = adjustmentEdited ? await API.updateAdjustment(order?.data?.data?.id, {
                id: adjustmentEdited.id,
                store_id: currentUser?.store?.subdomain,
                name: `${message}`,
                value: `${value}`,
                method: `${type}`,
            }) : await API.createAdjustment(order?.data?.data?.id, {
                store_id: currentUser?.store?.subdomain,
                name: `${message}`,
                value: `${value}`,
                method: `${type}`,
            });
            if (ok) {
                console.log("Here it is running please check response");
                console.log(response);
                setMessage("");
                setValue("");
                setType("ADD");
                setAdjustmentEdited(null);
                setUpdateorder(!updateOrder);
            }
        setShowModal(false);
        }
    };

    const deleteAdjustment = async (adjustmentId) => {
        const [ok, response] = await API.deleteAdjustment(order?.data?.data?.id, adjustmentId);
        if (ok) {
            console.log("Here it is running please check response");
            console.log(response);
            setUpdateorder(!updateOrder);
        }
  };
  const openLinkInNewTab = () => {
    window.open(`https://intelikart.tawk.help/article/add-adjustment`, '_blank');
  };

    return <>
    {order.data?.data.adjustments && order.data?.data.adjustments.length !== 0
                  ? order.data?.data.adjustments.map((elem) => {
                      return (
                        <IonCard key={elem.id}>
                          <IonItem lines="none">
                            <IonText slot="start">{elem.name}
                              
                            </IonText>
                            <IonText
                              slot="end"
                              className="ion-padding-horizontal"
                            >
                              {currency} {elem.value}
                            </IonText>
                          </IonItem>
                          <IonItem lines="none">
                            <IonText slot="start">{elem.method}</IonText>
                            <IonButton
                              slot="end"
                              fill="clear"
                              color="secondary"
                              onClick={(e) => {
                                setMessage(elem.name);
                                setValue(elem.value);
                                setType(elem.method);
                                setAdjustmentEdited(elem);
                                setShowModal(true);
                              }}
                            >
                              <IonIcon
                                slot="icon-only"
                                icon={pencilIcon}
                              ></IonIcon>
                            </IonButton>
                            <IonButton
                              slot="end"
                              fill="clear"
                              color="secondary"
                              onClick={(e) => deleteAdjustment(elem.id)}
                            >
                              <IonIcon
                                slot="icon-only"
                                icon={trashIcon}
                              ></IonIcon>
                            </IonButton>
                            {/* <IonIcon slot="end" icon={pencilIcon} onClick={e=>{setMessage(elem.name);setValue(elem.value);setType(elem.method); setAdjustmentEdited(elem); setForStatus(true)}}></IonIcon>
                                            <IonIcon slot="end" icon={trashIcon} onClick={e=> deleteAdjustment(elem.id)}></IonIcon> */}
                          </IonItem>
                        </IonCard>
                      );
                    })
                  : ""}
        <IonModal
                  isOpen={showModal}
                  onDidDismiss={() => {
                    setShowModal(false);
                    setValidation({});
                    setMessage("");
                    setValue("");
                    setType("ADD");
                    setAdjustmentEdited(null);
                  }}
                >
                  <IonHeader>
                    <IonToolbar>
                      <IonItem lines="none" style={{display:'flex',justifyContent:'center',alignItems:'center',}}>
                  <IonText className="ion-padding-start">
                            <h5>
                              {adjustmentEdited && adjustmentEdited.id ? "Edit" : "Add"} Adjustments
                </h5>
                
              </IonText>
              <IonText>
                
                    <IonButtons>
                      <IonButton fill='clear' color='secondary' onClick={openLinkInNewTab} style={{marginTop:'4px'}}>
                        <IonIcon icon={infoIcon} slot="icon-only">

                        </IonIcon>
                      </IonButton>
                    </IonButtons>
                 
              </IonText>
                          <IonText
                            slot="end"
                            color="primary"
                            onClick={(e) => {
                              setShowModal(false);
                              setValidation({});
                            }}
                            style={{ cursor: "pointer" }}
                          >
                                <h1><IonIcon
                                  slot="icon-only"
                                  size="large"
                                  icon={closeOutlineIcon}
                                ></IonIcon>
                                </h1>
                          </IonText>
                        </IonItem>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent>
                    <form>
                      <br />
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol sizeSm="3" sizeXs="4">
                            <IonLabel className="ion-padding-start">Message: </IonLabel>
                          </IonCol>
                          <IonCol>
                            <IonItem className="ion-padding-end">
                              <IonInput
                                value={message}
                                type="text"
                                placeholder="Eg. Missing Products, Extra Discount etc."
                                onIonChange={(e) => setMessage(e.detail.value)}
                              ></IonInput>
                            </IonItem>
                            <IonText
                              color="danger"
                              className={`${
                                validation["messageValidation"]
                                  ? ""
                                  : "ion-hide"
                              }`}
                            >
                              <h5
                                style={{ fontSize: 12 + "px" }}
                                className="ion-no-margin"
                              >
                                {validation["messageValidation"]}
                              </h5>
                            </IonText>
                          </IonCol>
                        </IonRow>
                        <br />
                        <IonRow className="ion-align-items-center">
                          <IonCol sizeSm="3" sizeXs="4">
                            <IonLabel className="ion-padding-start">Type:</IonLabel>
                          </IonCol>
                          <IonCol>
                            <IonItem className="ion-padding-end">
                              <IonSelect
                              className="ion-no-padding"
                                value={type}
                                style={{ width: 100 + "%" }}
                                onIonChange={(e) => setType(e.detail.value)}
                              >
                                <IonSelectOption key="1" value="ADD">
                                  ADD
                                </IonSelectOption>
                                <IonSelectOption key="2" value="SUBTRACT">
                                  SUBTRACT
                                </IonSelectOption>
                              </IonSelect>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <br />
                        <IonRow className="ion-align-items-center">
                          <IonCol sizeSm="3" sizeXs="4">
                            <IonLabel className="ion-padding-start">Value:</IonLabel>
                          </IonCol>
                          <IonCol>
                            <IonItem class="ion-padding-end">
                              <IonInput
                                value={value}
                                type="tel"
                                placeholder="10"
                                onIonChange={(e) => setValue(e.detail.value)}
                              ></IonInput>
                            </IonItem>
                            <IonText
                              color="danger"
                              className={`${
                                validation["valueValidation"] ? "" : "ion-hide"
                              }`}
                            >
                              <h5
                                style={{ fontSize: 12 + "px" }}
                                className="ion-no-margin"
                              >
                                {validation["valueValidation"]}
                              </h5>
                            </IonText>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                      <br />
                      <div className="ion-text-end" style={{paddingRight:"35px"}}>
                      <IonButton
                      shape="round"
                      slot="end"
                        fill="solid"
                        onClick={() => {
                          addOrEditAdjustment();
                        }}
                      >
                        Save
                      </IonButton>
                      </div>
                    </form>
                  </IonContent>
                </IonModal>
  </>
}

export default Adjustments