import { IonButton, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonRow, IonText } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Title from '../../components/dashboard/Header';
import ShortInfo from '../../components/order/ShortInfo';
import useAPI from '../../utils/api';
import { IsStaffContext, useOrderStatusChangeData, useSharedData, useUser } from '../../utils/contexts';
import useFetch from '../../utils/useFetch';
const Orders = () => {
	const {isStaff} :any = useContext(IsStaffContext);
	const {filterData, setFilterData, filterOrderData, setFilterOrderData, pageCountData, setPageCountData, infiniteScrollData, setInfiniteScrollData, update, setUpdate}: any = useOrderStatusChangeData();
	const [count, setCount] = useState<any>();;
	const history = useHistory();

	const sharedData:any = useSharedData();
    const currentUser: any = useUser();

	const [loading, setLoading] = useState(false);
    const[filter, setFilter]=useState(filterData);
	const API = useAPI();
	const[filterArrayObject, setFilterArrayObject]=useState(filterOrderData);
	const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(infiniteScrollData);
	const[availableOrderStatuses, setAvailableOrderStatuses]=useState<any>([
		{status: 'NEW', display_name: 'New'},
		{status: 'PROCESSING', display_name: 'Processing'},
		{status: 'DELIVER', display_name: 'Delivered'},
		{status: 'CANCEL', display_name: 'Cancelled'},
		{status: 'RETURN', display_name: 'Returned'},
		{status: 'DENY', display_name: 'Denied'}
	]);
	const [page, setPage] = useState(pageCountData);

	const orderAddonData = useFetch("orderAddon", sharedData.orderManagementUpdate);
	const scheduleOrderAddonData = useFetch("getScheduledOrderAddon", sharedData.scheduleOrderUpdate);
	const dynamicStatusData = useFetch("getDynamicStatus", sharedData.scheduleOrderUpdate, sharedData.orderManagementUpdate);

    useEffect(() =>{
		if(!dynamicStatusData?.loading && dynamicStatusData?.status === "fetched"){
        	getDynamicStausApi();
		}
	},[dynamicStatusData, scheduleOrderAddonData, orderAddonData])

	const getDynamicStausApi = async () =>{
      	if (dynamicStatusData.data?.data.length !== 0) {
        let temp = dynamicStatusData.data?.data
			.filter((status) => status.name.toLowerCase() !== "all")
			.map((status) => ({
				status: status.name,
				display_name: status.display_name,
			}));
        if (
			scheduleOrderAddonData &&
			!scheduleOrderAddonData.loading &&
			scheduleOrderAddonData.status === "fetched" &&
			scheduleOrderAddonData.data &&
			scheduleOrderAddonData.data?.data?.status === "active"
        ) {
          	temp.push({ status: "SCHEDULED", display_name: "Scheduled" });
        }
        if (
			orderAddonData &&
			!orderAddonData.loading &&
			orderAddonData.status === "fetched" &&
			orderAddonData.data &&
			orderAddonData.data?.data?.status === "active"
        ) {
          	temp.push({ status: "PENDING", display_name: "Pending" });
        }
        setAvailableOrderStatuses(temp);
	}
	  	else {
			let temp = [
				{status: 'NEW', display_name: 'New'},
				{status: 'PROCESSING', display_name: 'Processing'},
				{status: 'DELIVER', display_name: 'Delivered'},
				{status: 'CANCEL', display_name: 'Cancelled'},
				{status: 'RETURN', display_name: 'Returned'},
				{status: 'DENY', display_name: 'Denied'}
			];
			if (
				scheduleOrderAddonData &&
				!scheduleOrderAddonData.loading &&
				scheduleOrderAddonData.status === "fetched" &&
				scheduleOrderAddonData.data &&
				scheduleOrderAddonData.data?.data?.status === "active"
			) {
				temp.splice(1,0,{ status: "SCHEDULED", display_name: "Scheduled" });
			}
			if (
				orderAddonData &&
				!orderAddonData.loading &&
				orderAddonData.status === "fetched" &&
				orderAddonData.data &&
				orderAddonData.data?.data?.status === "active"
			) {
				temp.splice(0,0,{ status: "PENDING", display_name: "Pending" });
			}
			setAvailableOrderStatuses(temp);
	  	}
    }

	useEffect(() => {
		if(sessionStorage.getItem('reloadCount')){
			setCount(sessionStorage.getItem('reloadCount'));
		}else{
			sessionStorage.setItem('reloadCount', String(1));
			setCount('1');
		}
	}, []);

	useEffect(() => {
		if(count === '1' && isStaff) {
			sessionStorage.setItem('reloadCount', String(10));
			window.location.reload();
		}
	}, [count, isStaff])

	useEffect(() => {
        const pageNumber = page[filter];
        if((!pageNumber || pageNumber === 1) && currentUser?.store){
			fetchData(); 
		}
    }, [filter, filterData, currentUser]);

    useEffect(() => {
        if(currentUser?.store) {
			fetchData(); 
        }
    }, [update, currentUser]);

	const fetchData = async () => {
		if(!filterArrayObject[filter] || filterArrayObject[filter].length % 10 === 0) {			
            setLoading(true);
			const pageNumber = page[filter];
			const [ok, response] = await API.ordersByStatus(filter, pageNumber || 1);
			if (ok && response?.data) {
				if (response.data?.length > 0) {
					let op;
					if (filter === "ALL") {
						op = response.data.reduce(function (acc, curr) {
							const exists = acc["ALL"] && acc["ALL"].some(item => item.id === curr.id);
                            
                            if (!exists) {
                                if (!acc["ALL"]) {
                                    acc["ALL"] = [];
                                }
                                acc["ALL"].push(curr);
                            }
							return acc;
						}, filterArrayObject);
					} else {
						op = response.data.reduce(function (acc, curr) {
							const exists = acc[curr.status] && acc[curr.status].some(item => item.id === curr.id);
                                if (!exists) {
                                    if (!acc[curr.status]) {
                                        acc[curr.status] = [];
                                    }
                                    acc[curr.status].push(curr);
                                }
							return acc;
						}, filterArrayObject);
					}

					setFilterArrayObject(op);
					setFilterOrderData(op);
					setDisableInfiniteScroll((prev) => {
						let temp ={...prev};
						temp[filter] = response.data.length < 10;
						return temp;
					});
					setInfiniteScrollData((prev) => {
						let temp ={...prev};
						temp[filter] = response.data.length < 10;
						return temp;
					});
					const tempPage = { ...page };
					if(pageNumber){
						tempPage[filter] = pageNumber + 1;
					}else{
						tempPage[filter] = 2;
					}
					setPage(tempPage);
					setPageCountData(tempPage);
				} else {
					if(response.data.length === 0 && !filterArrayObject[filter]){
						let temp = {...filterArrayObject};
						temp[filter] = [];
						setFilterArrayObject(temp);
						setFilterOrderData(temp);
					}
					setDisableInfiniteScroll((prev) => {
						let temp ={...prev};
						temp[filter] = true;
						return temp;
					});
					setInfiniteScrollData((prev) => {
						let temp ={...prev};
						temp[filter] = true;
						return temp;
					});
				}
			}
			setLoading(false);
		}
	};

	async function searchNext($event: CustomEvent<void>) {
		await fetchData();
		($event.target as HTMLIonInfiniteScrollElement).complete();
	}

	const handleAddOrdersClick = () => {
		history.push("/orders/add");
	};

	const doRefresh = ev => {
		setFilterArrayObject({});
		setFilterOrderData({});
		setPage({ALL: 1, PENDING: 1});
		setPageCountData({ALL: 1, PENDING: 1});
		setFilter("ALL");
		setFilterData("ALL");
		fetchData();
		setTimeout(() => {
			ev.detail.complete();
		}, 1500);
	};

	return (
		<IonPage>
			<Title name="Orders" docs={true} docslink='https://intelikart.tawk.help/category/orders' />	
			<IonContent>
			<IonRow>
			<div className="horizontal-scroll ion-padding">
			<IonCol>
				<IonChip style={{color:filter==="ALL"?"white":"",background:filter==="ALL"?"#4286f5":""}} color={filter==="ALL"?"":"secondary"} key="ALL" onClick={()=>{setFilter("ALL"); setFilterData("ALL")}}>All</IonChip>
				{ availableOrderStatuses ? availableOrderStatuses.map(op=> <IonChip style={{color:op.status===filter?"white":"",background:op.status===filter?"#4286f5":""}} color={op.status===filter?"":"secondary"} key={op.status} onClick={()=>{setFilter(op.status); setFilterData(op.status)}}>{op.display_name.charAt(0).toUpperCase()+ op.display_name.substr(1).toLowerCase()}</IonChip>
				):""}
			</IonCol>
			</div>

			<IonCol className='ion-text-end'>
				<IonButton onClick={handleAddOrdersClick} shape='round'>
					Add Order
				</IonButton>
			</IonCol>
			</IonRow>
				<IonLoading isOpen={ orderAddonData.loading || scheduleOrderAddonData.loading || dynamicStatusData.loading || loading } /> 
				<IonRefresher slot="fixed" onIonRefresh={doRefresh}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				<div>
					{filterArrayObject[filter] && filterArrayObject[filter].length > 0 ?<IonList>
						{ filterArrayObject[filter]?.map(order => (
							<ShortInfo key={ order.id } order={ order } filterArrayObject={filterArrayObject} setFilterArrayObject={setFilterArrayObject} page={page} setPage={setPage} disableInfiniteScroll={disableInfiniteScroll} setDisableInfiniteScroll={setDisableInfiniteScroll} filter={filter} setFilter={setFilter}/>
						) ) }	
					</IonList>:<IonCard><IonCardContent><IonText className={`${ loading ? "ion-hide" : "ion-margin" }`}> No Orders in this section.</IonText></IonCardContent></IonCard>}
				</div>
				<IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll[filter]} onIonInfinite={searchNext}>
  					<IonInfiniteScrollContent loadingText="Loading more data..."></IonInfiniteScrollContent>
				</IonInfiniteScroll>

			</IonContent>
		</IonPage>
	);
};

export default Orders;
