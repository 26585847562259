import { 
    GET_PICKUP_FAIL, GET_PICKUP_REQUEST, GET_PICKUP_SUCCESS, 
    GET_SHIPROCKET_ORDER_FAIL, GET_SHIPROCKET_ORDER_REQUEST, GET_SHIPROCKET_ORDER_SUCCESS, 
    POST_PICKUP_LOCATION_FAIL, POST_PICKUP_LOCATION_REQUEST, POST_PICKUP_LOCATION_SUCCESS, 
    POST_SHIPROCKET_ORDER_FAIL, POST_SHIPROCKET_ORDER_REQUEST, POST_SHIPROCKET_ORDER_SUCCESS,
    CLEAR_ERRORS
 } from "../constants/shiprocketConstants";

export const loadPickupLocationsReducer = (state = { pickupLocations: {} }, action: any) =>{
    switch(action.type){
        case GET_PICKUP_REQUEST:
            return{
                ...state,
                pickupLocationsLoading: true,
                pickupLocationsError: null
            }
        case GET_PICKUP_SUCCESS:
            return{
                ...state,
                pickupLocationsLoading: false,
                pickupLocations: action.payload
            }
        case GET_PICKUP_FAIL:
            return{
                ...state,
                pickupLocationsLoading: false,
                pickupLocationsError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadShiprocketOrdersReducer = (state = { shiprocketOrders: {} }, action: any) =>{
    switch(action.type){
        case GET_SHIPROCKET_ORDER_REQUEST:
            return{
                ...state,
                shiprocketOrdersLoading: true,
                shiprocketOrdersError: null
            }
        case GET_SHIPROCKET_ORDER_SUCCESS:
            return{
                ...state,
                shiprocketOrdersLoading: false,
                shiprocketOrders: action.payload
            }
        case GET_SHIPROCKET_ORDER_FAIL:
            return{
                ...state,
                shiprocketOrdersLoading: false,
                shiprocketOrdersError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const createShiprocketOrderReducer = (state = { createShiprocketOrder: {} }, action: any) =>{
    switch(action.type){
        case POST_SHIPROCKET_ORDER_REQUEST:
            return{
                ...state,
                createShiprocketOrderLoading: true,
                createShiprocketOrderError: null
            }
        case POST_SHIPROCKET_ORDER_SUCCESS:
            return{
                ...state,
                createShiprocketOrderLoading: false,
                createShiprocketOrder: action.payload
            }
        case POST_SHIPROCKET_ORDER_FAIL:
            return{
                ...state,
                createShiprocketOrderLoading: false,
                createShiprocketOrderError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const createPickupLocationReducer = (state = { createPickupLocation: {} }, action: any) =>{
    switch(action.type){
        case POST_PICKUP_LOCATION_REQUEST:
            return{
                ...state,
                createPickupLocationLoading: true,
                createPickupLocationError: null
            }
        case POST_PICKUP_LOCATION_SUCCESS:
            return{
                ...state,
                createPickupLocationLoading: false,
                createPickupLocation: action.payload
            }
        case POST_PICKUP_LOCATION_FAIL:
            return{
                ...state,
                createPickupLocationLoading: false,
                createPickupLocationError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}