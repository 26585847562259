import { IonButton, IonCard, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonRow, IonText, IonThumbnail, IonTitle, useIonToast } from '@ionic/react'
import { useEffect, useState } from 'react'
import Header from '../../../components/dashboard/Header';
import { getAccessToken } from '../../../services/auth/userLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import { getMasterCategory, getMasterProducts, copyProducts } from '../../../actions/masterCatalogueAction';
import { Link, useHistory } from 'react-router-dom';
import { checkmarkSharp, closeCircleOutline, closeOutline, copy, search as serachIcon, } from 'ionicons/icons';
import ReactPaginate from 'react-paginate';

const MasterScreen = () => {
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState<any>([]);

    const { currentUser } = useSelector((state: any) => state.currentUser);
    const { masterCategoriesLoading, masterCategories, masterCategoriesError } = useSelector((state: any) => state.masterCategories);
    const { masterProductsLoading, masterProducts, masterProductsError } = useSelector((state: any) => state.masterProducts);
    const { copyProductsLoading, copyProductsMessage, copyProductsError } = useSelector((state: any) => state.copyMasterProducts);

    const [productsByCategory, setProductsByCategory] = useState<any>({});
    const [subcategoryData, setSubcategoryData] = useState<any>({});
    const [totalProducts, setTotalProducts] = useState<any>({});

    const [currentPage, setCurrentPage] = useState(0);
    const [paginateKey, setPaginateKey] = useState(0);

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [query, setQuery] = useState<any>("");
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [searchResult, setSearchResult] = useState({
        categoryList: [],
        productList: []
    });

    const dispatch = useDispatch();
    const accessToken = getAccessToken();

    const [present] = useIonToast();

    const history = useHistory();

    const pageOffset = 20

    useEffect(() => {
        if (accessToken && currentUser && currentUser.data) {
            dispatch(getMasterCategory(accessToken, currentUser.data.store.subdomain));
        }
    }, [currentUser]);

    useEffect(() => {
        setQuery('');
    
      }, [history.location.pathname]);

    useEffect(() => {
        if (currentUser) {
            setCurrentPage(0);
            setSelectedItems([]);
            setSelectAll(false);
            setPaginateKey(prevKey => prevKey + 1);
            if (!!subcategoryData.id && !!currentUser.data && !productsByCategory[subcategoryData.id]?.hasOwnProperty(currentPage)) {
                dispatch(getMasterProducts(subcategoryData.id, accessToken, currentUser.data.store.subdomain, 1));
            }
        }
    }, [subcategoryData, currentUser]);

    useEffect(() => {
        if (masterCategoriesLoading === false && masterCategories && masterCategories[0] && masterCategories[0].subCategory) {
            if (masterCategories[0].subCategory[0]) {
                setSubcategoryData({
                    ...masterCategories[0].subCategory[0],
                    parentCategory: masterCategories[0].id
                });
            }
        }
    }
        , [masterCategoriesLoading]);

    useEffect(() => {
        if (copyProductsLoading === false && copyProductsMessage) {
            present(copyProductsMessage, 1000);
        }
    }, [copyProductsLoading])

    useEffect(() => {
        if (masterProductsLoading === false && masterProducts && masterProducts.data && masterProducts.meta) {
            if (!totalProducts[subcategoryData.id]) {
                let totalTemp = { ...totalProducts }
                totalTemp[subcategoryData.id] = {
                    total: masterProducts.meta.pagination.total,
                    total_pages: masterProducts.meta.pagination.total_pages
                }
                setTotalProducts(totalTemp)
            }
            if (masterProducts?.data?.length > 0) {
                setProductsByCategory((prev) => {
                    const temp = prev[subcategoryData.id] ? [...prev[subcategoryData.id]] : [];
                    temp[currentPage] = [...masterProducts.data];
                    return { ...prev, [subcategoryData.id]: temp };
                });
            } else {
                setProductsByCategory((prev) => {
                    const temp = prev[subcategoryData.id] ? [...prev[subcategoryData.id]] : [];
                    temp[currentPage] = [];
                    return { ...prev, [subcategoryData.id]: temp };
                });
            }
        }
    }, [masterProductsLoading])

    const handleCheckboxClick = (productId) => {
        const isSelected = selectedItems.includes(productId);
        if (isSelected) {
            setSelectedItems(selectedItems.filter((id) => id !== productId));
            setSelectAll(false);
        } else {
            let temp = [...selectedItems]
            temp.push(productId)

            setSelectedItems(temp);

            if (temp.length === productsByCategory[subcategoryData.id][currentPage]?.length) {
                setSelectAll(true);
            }
        }
    };

    const handleSelectAll = async () => {
        setSelectAll(!selectAll);
        if (!selectAll === true) {
            let products = productsByCategory[subcategoryData.id][currentPage]?.map(x => x.id)
            setSelectedItems(products)
        }
        else {
            setSelectedItems([]);
        }
    }

    const handlePageClick = async (pageNumber) => {
        setCurrentPage(pageNumber)
        setLoading(true);
        setSelectedItems([]);
        setSelectAll(false)

        if (subcategoryData.id && (!productsByCategory[subcategoryData.id].hasOwnProperty(pageNumber) || productsByCategory[subcategoryData.id][pageNumber]?.length < 1)) {
            await dispatch(getMasterProducts(subcategoryData.id, accessToken, currentUser.data.store.subdomain, pageNumber + 1));
        }
        setLoading(false);
    }

    const copyProductsHandler = async () => {
        const copyProductsId = {
            "product_id": selectedItems
        }
        if (accessToken && currentUser) {
            setLoading(true);
            await dispatch(copyProducts(copyProductsId, accessToken, currentUser.data.store.subdomain));
            setSelectedItems([]);
            setSelectAll(false);
            setLoading(false);
        }
    }

    const searchProductByName = async (e)=>{
        setLoadingSearch(true)
        setQuery(e.detail.value)
        if(e.detail.value){
            if(currentUser && currentUser.data){
                const response = await fetch(`https://api2.intelikart.in/master/products?query=${e.detail.value}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${getAccessToken()}`,
                        'x-ik-domain':`${currentUser.data.store.subdomain}`
                    },
                });
                const data = await response.json();
                let result = [];
                for(const parentCategory of masterCategories){
                    let subCate = [];
                    for(const childCategory of parentCategory.subCategory){
                        for(const searchProduct of data.data){
                            if(searchProduct.category_id === childCategory.id){
                                subCate.push(childCategory);
                                break;
                            }
                        }
                    }
                    if(subCate.length !== 0){
                        let temp = {...parentCategory}
                        temp["subCategory"] = subCate
                        result.push(temp)
                    }
                }
                setSubcategoryData({
                    ...result[0]?.subCategory[0],
                    parentCategory: result[0]?.id
                });
                let temp = {...searchResult}
                temp.categoryList = result
                const productList = data.data.reduce(function(acc,curr){
                    if(acc[curr.category_id]){
                        acc[curr.category_id].push(curr)
                    }
                    else{
                        acc[curr.category_id]=[]
                        acc[curr.category_id].push(curr)
                    }
                    return acc
                },{});
                temp.productList = productList
                setSearchResult(temp);
            }
        }
        setLoadingSearch(false)
    }

    return (<IonPage>
        <Header name="Master" docs={true} docslink='https://intelikart.tawk.help/article/master'/>
        <IonContent>
            <div style={{ 'backgroundColor': '#F5F7FB' }}><br />
                <IonText className="ion-text-center">
                    <h5 className="ion-no-margin"><strong>Choose products for copying</strong></h5>
                </IonText>

                <IonGrid>
                    <IonRow className='ion-no-margin ion-padding-top'>
                        <IonCol sizeSm='6' sizeXs='12'>
                            {masterCategoriesLoading === false && <>
                                <div className="product-search-bar">
                                    <IonIcon icon={serachIcon}></IonIcon>
                                    <IonInput placeholder="Search Product" value={query} onIonChange={e =>
                                        searchProductByName(e)
                                    }></IonInput>
                                    {query && <IonIcon style={{cursor:"pointer", marginRight: "7px" }} icon={closeCircleOutline} onClick={e => { setQuery("") }}></IonIcon>}
                                </div>
                            </>}
                        </IonCol>
                        <IonCol style={{ paddingRight: 0 }}>
                            <IonCheckbox checked={selectAll} className='ion-float-end' onClick={e => handleSelectAll()} style={{ fontSize: "20px", marginRight: "20px", marginTop: "5px" }} />
                            <IonText className='ion-float-end' style={{ fontSize: "20px", marginRight: "30px" }}>
                                Select All
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                {masterCategoriesLoading === false && masterCategories && masterCategories.length !== 0 && subcategoryData.id && subcategoryData.name && <>
                    <IonItem lines="none" className="ion-margin-horizontal category-dropdown">
                        <IonLabel position="stacked">Select Subcategory</IonLabel>
                        <div style={{ width: 100 + '%', display: 'flex', justifyContent: 'space-between' }} onClick={e => setShowModal(true)}>
                            <span style={{ width: 95 + '%' }}>
                                {subcategoryData && subcategoryData.name && <IonInput value={subcategoryData.name} readonly></IonInput>}
                            </span>
                            <span>&#x25BC;</span>
                        </div>
                    </IonItem>
                    <IonModal isOpen={showModal} onDidDismiss={e => setShowModal(false)} >
                        <IonHeader>
                            <IonItem lines="none">
                                <IonTitle>Select Subcategory</IonTitle>
                                <IonButton fill='clear' size='large' slot='end' onClick={(e) => setShowModal(false)} >
                                    <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                                </IonButton>
                            </IonItem>
                        </IonHeader>
                        <IonContent className="ion-padding">
                            {(query && !loadingSearch ? searchResult.categoryList :masterCategories)?.map((parentCategory) => {
                                return <div key={parentCategory.id}>
                                    <IonItem style={{ cursor: 'pointer' }}>
                                        <IonLabel color={subcategoryData.parentCategory === parentCategory.id ? 'primary' : ''}>{parentCategory.name} ({parentCategory.subCategory.length})</IonLabel>
                                    </IonItem>
                                    <IonList>
                                        {parentCategory.subCategory.map((childCategory) => {
                                            return <IonItem key={childCategory.id} lines="none" className='ion-margin-start' onClick={e => { setSubcategoryData({ ...childCategory, parentCategory: parentCategory.id }); setShowModal(false); setCurrentPage(0); setSelectAll(false); setSelectedItems([]) }} style={{ cursor: 'pointer' }}>
                                                <IonThumbnail slot="start">
                                                    <img src={childCategory.icon_code_internet_url} />
                                                </IonThumbnail>
                                                <IonText color={subcategoryData.name === childCategory.name ? 'primary' : ''}>
                                                    <h6 className='ion-no-margin'>{childCategory.name}</h6>

                                                </IonText>
                                                {subcategoryData.name === childCategory.name ? <IonIcon slot="end" color="primary" size="large" icon={checkmarkSharp} /> : ""}
                                            </IonItem>
                                        }
                                        )}
                                    </IonList>
                                </div>
                            })}
                        </IonContent>
                    </IonModal>
                </>
                }

                <IonCard style={{ backgroundColor: 'white' }}>
                    <IonGrid className='ion-no-padding'>
                        <IonRow>
                            <IonCol size="3" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.13)' }} className='side-category-display'>
                                <IonList style={{ marginBottom: 60 + 'px' }}>
                                    {((masterCategories && masterCategories.length > 0) || (query && !loadingSearch)) && (
                                        (query && !loadingSearch ? searchResult.categoryList : masterCategories).map((parentCategory) => {
                                        return <div key={parentCategory.id} className='ion-padding'>
                                            <IonLabel color={subcategoryData.parentCategory === parentCategory.id ? 'primary' : ''}>{parentCategory.name} ({parentCategory.subCategory.length})</IonLabel>
                                            <IonList style={{ borderBottom: '1px solid rgba(0,0,0,0.16)' }}>
                                                {parentCategory.subCategory.map((childCategory) => {
                                                    return <div key={childCategory.id} className='ion-margin-start ion-padding-horizontal' onClick={e => { setSubcategoryData({ ...childCategory, parentCategory: parentCategory.id }); setCurrentPage(0); setSelectAll(false); setSelectedItems([]) }} style={{ cursor: 'pointer', paddingTop: 10 + 'px', paddingBottom: 10 + 'px', backgroundColor: `${subcategoryData.name === childCategory.name ? '#F5F7FB' : 'white'}` }}>
                                                        <IonLabel color={subcategoryData.name === childCategory.name ? 'primary' : ''}>- {childCategory.name}</IonLabel>
                                                    </div>
                                                }
                                                )}
                                            </IonList>
                                        </div>
                                    }))}
                                </IonList>
                            </IonCol>
                            <IonCol>
                                {((query && !loadingSearch) || (productsByCategory[subcategoryData.id] && productsByCategory[subcategoryData.id][currentPage] && productsByCategory[subcategoryData.id][currentPage]?.length > 0)) ? (query && !loadingSearch ? searchResult.productList[subcategoryData.id] : productsByCategory[subcategoryData.id][currentPage])?.map(product =>
                                    <IonItem key={product.id} className='ion-margin-top ion-margin-bottom'>
                                        <Link to={{ pathname: `/catalogue/products/update/${product.id}`, state: { ...subcategoryData } }}>
                                            <IonThumbnail slot="start">
                                                <img src={product.variants[0].image_url_original} />
                                            </IonThumbnail>
                                        </Link>
                                        <IonText className="ion-margin-start">
                                            <h5 className='ion-no-margin'>{product.name}&nbsp;
                                            </h5>
                                            <IonText color="medium">({product.variants[0].quantity} {product.variants[0].quantity_unit_name})</IonText>
                                            <br />
                                            {product.variants[0].mrp != 0 && product.variants[0].mrp != null ? <h5 className='ion-no-margin' style={{ display: 'inline' }}>Rs. {product.variants[0].mrp}&nbsp;</h5> : ""}
                                            {product.variants[0].display_mrp != 0 && product.variants[0].display_mrp != null ? <del>Rs. {product.variants[0].display_mrp}</del> : ""}
                                        </IonText>
                                        <IonCheckbox checked={selectedItems.includes(product.id)} onClick={(e) => handleCheckboxClick(product.id)} slot='end' />
                                    </IonItem>
                                )
                                    : (!masterProductsLoading && !masterCategoriesLoading) && <IonText className='ion-margin ion-text-center'><h5>No product in this category</h5></IonText>}

                                {totalProducts[subcategoryData.id]?.total_pages > 1 && <IonGrid>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol>
                                            <IonText color="primary" className="ion-text-center"><h5>Showing ({currentPage * pageOffset + 1} - {productsByCategory[subcategoryData.id][currentPage]?.length ? currentPage * pageOffset + productsByCategory[subcategoryData.id][currentPage]?.length : currentPage * pageOffset})/{totalProducts[subcategoryData.id].total}</h5>
                                                <ReactPaginate
                                                    key={paginateKey}
                                                    previousLabel="Prev"
                                                    nextLabel="Next"
                                                    pageCount={totalProducts[subcategoryData.id].total_pages}
                                                    onPageChange={data => handlePageClick(data.selected)}
                                                    containerClassName="pagination-container"
                                                    activeClassName="pagination-button"
                                                />
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCard>

                <IonLoading isOpen={masterProductsLoading === true || masterCategoriesLoading == true || loading == true} message="Please Wait" />
            </div>
        </IonContent>
        <IonRow>
            <IonCol>
                <IonButton onClick={() => copyProductsHandler()} expand="full" color="dark" className={`${(selectedItems.length > 0) ? '' : 'ion-hide'}`}><IonIcon icon={copy}></IonIcon> &nbsp; Copy Products</IonButton>
            </IonCol>
        </IonRow>
    </IonPage >
    )
}

export default MasterScreen