import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";
import {
  IonContent,
  IonLabel,
  IonText,
  IonLoading,
  IonBackButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonItem,
  IonInput,
  IonButton,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonCard,
  IonCardContent,
  IonTextarea,
  IonToast,
} from "@ionic/react";
import {
  getLocalization,
  setLocalization,
  clearErrors,
} from "../../actions/dashboardActions";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import { arrowBack as arrowBackIcon, laptop } from "ionicons/icons";
import FeatHeader from '../../components/dashboard/FeatHeader';


Geocode.setApiKey("AIzaSyDbix6IhpOdD9FO2DlmCFPCp3OrGgtbOvE");
Geocode.setLanguage("en");
Geocode.setRegion("in");
const Localization = () => {
  const [address, setAddress] = useState<any>();
  const [city, setCity] = useState<any>();
  const [state, setState] = useState<any>();
  const [latitude, setLatitude] = useState<any>();
  const [longitude, setLongitude] = useState<any>();
  const [ZIP, setZIP] = useState<any>();
  const [country, setCountry] = useState<any>("India");
  const [currency, setCurrency] = useState<any>("INR");
  const [language, setLanguage] = useState<any>("English");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isCurrencySelected, setIsCurrencySelected] = useState(false);

  //validation
  const [isAddressValidate, setIsAddressValidate] = useState(true);
  const [isZipValidate, setIsZipValidate] = useState(true);
  const [isCityValidate, setIsCityValidate] = useState(true);
  const [isStateValidate, setIsStateValidate] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const { localizationLoading, localization, localizationError } = useSelector(
    (state: any) => state.localization
  );
  const {
    setLocalizationResponseLoading,
    setLocalizationResponse,
    setLocalizationResponseError,
  } = useSelector((state: any) => state.setLocalization);
  const { currentUser } = useSelector((state: any) => state.currentUser);

  const containerStyle = {
    width: "100%",
    height: "40%",
  };
  const defaultLat = 28.7041;
  const defaultLong = 77.1025;

  useEffect(() => {
    const google = window.google;
    if (currentUser && currentUser.data) {
      dispatch(
        getLocalization(getAccessToken(), currentUser.data.store.subdomain)
      );
    }
  }, [currentUser]);

  useEffect(() => {
    const google = window.google;
    if (
      currentUser &&
      currentUser.data &&
      setLocalizationResponseLoading === false
    ) {
      dispatch(
        getLocalization(getAccessToken(), currentUser.data.store.subdomain)
      );
    }
  }, [setLocalizationResponseLoading]);

  useEffect(() => {
    if (localizationLoading === false && localization && localization.data) {
      setAddress(localization.data.address);
      setState(localization.data.state);
      setCity(localization.data.city);
      setZIP(localization.data.zip);
      setLatitude(parseFloat(localization.data.latitude));
      setLongitude(parseFloat(localization.data.longitude));
      setCountry(localization.data.country);

      if (localization.data.currency) {
        setCurrency(localization.data.currency);
        setIsCurrencySelected(true);
      }
    }
  }, [localization]);

  function getAddress(latLng) {
    Geocode.fromLatLng(latLng.lat(), latLng.lng()).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let city, state, country, zip;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "administrative_area_level_2":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                zip = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        setAddress(address);
        setCity(city);
        setState(state);
        setZIP(zip);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  const placeMarker = (e) => {
    // console.log(e.latLng.lat())
    setLatitude(e.latLng.lat());
    setLongitude(e.latLng.lng());
    getAddress(e.latLng);
  };

  const zipValidation = () => {
    let result = true;

    setIsAddressValidate(true);
    setIsZipValidate(true);
    setIsCityValidate(true);
    setIsStateValidate(true);

    if (
      !address ||
      !/[a-zA-Z]/.test(address) 
      
    ) {
      result = false;
      setIsAddressValidate(false);
    }
    if (
      !ZIP ||
      /[a-zA-Z!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(ZIP) ||
      ZIP.length != 6
    ) {
      result = false;
      setIsZipValidate(false);
    }
    if (!city || !(/^[A-Za-z\s]+$/.test(city))) {
      setIsCityValidate(false);
      result = false;
    }
    if (!state || !(/^[A-Za-z\s]+$/.test(state))) {
      setIsStateValidate(false);
      result = false;
    }

    return result;
  };

  const submitHandler = async (e: any) => {
    e.preventDefault();
    if (zipValidation()) {
      if (currentUser && currentUser.data) {
        await dispatch(
          setLocalization(getAccessToken(), currentUser.data.store.subdomain, {
            address: `${address}`,
            city: `${city}`,
            state: `${state}`,
            zip: `${ZIP}`,
            currency: `${currency}`,
            language: `${language}`,
            country: `${country}`,
            latitude: `${latitude}`,
            longitude: `${longitude}`,
          })
        );
        setShowToast(true);
        setToastMessage("Saved successfully");
        history.goBack();
      }
    }
  };
  return (
    <IonPage>
      <FeatHeader
        title='Localization'
        defaultHref="/settings"
        showInfoButton={false}
      />
      <IonContent className="ion-padding">
        <LoadScript googleMapsApiKey="AIzaSyDbix6IhpOdD9FO2DlmCFPCp3OrGgtbOvE">
          {localization && localization.data ? (
            <GoogleMap
              onClick={placeMarker}
              mapContainerStyle={containerStyle}
              center={{
                lat: latitude ? latitude : defaultLat,
                lng: longitude ? longitude : defaultLong,
              }}
              zoom={latitude && longitude ? 14 : 7}
            >
              {latitude && longitude ? (
                <Marker
                  position={{
                    lat: latitude,
                    lng: longitude,
                  }}
                />
              ) : (
                ""
              )}
              <></>
            </GoogleMap>
          ) : (
            ""
          )}
        </LoadScript>
        <form onSubmit={submitHandler}>
          <IonGrid>
            <IonRow className="ion-align-items-center">
              <IonCol size="4">
                <IonLabel>Address</IonLabel>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonTextarea
                    rows={3}
                    value={address}
                    name="address"
                    onIonChange={(e) => {
                      setAddress(e.detail.value);
                    }}
                  />
                </IonItem>
                <IonText
                  className={`${isAddressValidate ? "ion-hide" : ""}`}
                  color="danger"
                >
                  <h5
                    className="ion-margin-start"
                    style={{ fontSize: 12 + "px" }}
                  >
                    Address can't contain only numbers and special characters.
                  </h5>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol size="4">
                <IonLabel>City</IonLabel>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonInput
                    type="text"
                    value={city}
                    name="city"
                    onIonChange={(e) => {
                      setCity(e.detail.value);
                    }}
                  />
                </IonItem>
                <IonText
                  className={`${isCityValidate ? "ion-hide" : ""}`}
                  color="danger"
                >
                  <h5
                    className="ion-margin-start"
                    style={{ fontSize: 12 + "px" }}
                  >
                    Enter valid City
                  </h5>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol size="4">
                <IonLabel>State</IonLabel>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonInput
                    type="text"
                    value={state}
                    name="state"
                    onIonChange={(e) => {
                      setState(e.detail.value);
                    }}
                  />
                </IonItem>
                <IonText
                  className={`${isStateValidate ? "ion-hide" : ""}`}
                  color="danger"
                >
                  <h5
                    className="ion-margin-start"
                    style={{ fontSize: 12 + "px" }}
                  >
                    Enter valid state
                  </h5>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol size="4">
                <IonLabel>ZIP</IonLabel>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonInput
                    type="text"
                    value={ZIP}
                    name="ZIP"
                    onIonChange={(e) => {
                      setZIP(e.target.value);
                    }}
                    onIonBlur={() => zipValidation()}
                  />
                </IonItem>
                <IonText
                  className={`${isZipValidate ? "ion-hide" : ""}`}
                  color="danger"
                >
                  <h5
                    className="ion-margin-start"
                    style={{ fontSize: 12 + "px" }}
                  >
                    ZIP must contains numbers only and of 6 digits.
                  </h5>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol size="4">
                <IonLabel>Country</IonLabel>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonInput value={country} disabled />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol size="4">
                <IonLabel>Currency</IonLabel>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonSelect
                    value={currency}
                    onIonChange={(e) => setCurrency(e.detail.value)}
                    disabled={isCurrencySelected}
                  >
                    <IonSelectOption value="INR">INR</IonSelectOption>
                    <IonSelectOption value="AED">AED</IonSelectOption>
                    <IonSelectOption value="USD">USD</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol size="4">
                <IonLabel>Language</IonLabel>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonSelect
                    value={language}
                    onIonChange={(e) => setLanguage(e.detail.value)}
                  >
                    <IonSelectOption value="English">English</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
          <div className="ion-text-center">
            <IonButton shape="round" type="submit">
              Save
            </IonButton>
          </div>
        </form>
        <IonLoading
          isOpen={
            localizationLoading === true ||
            setLocalizationResponseLoading === true
          }
          message="Please Wait"
        ></IonLoading>
      </IonContent>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  );
};

export default Localization;
