import { useEffect, useState } from "react";
import {
    IonButton,
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonLoading,
    IonPage,
    IonRow,
} from "@ionic/react";
import Dropzone from "react-dropzone";
import { trash } from "ionicons/icons";
import { informationCircle } from "ionicons/icons";
import MediaLibraryPopUp from "./MediaLibraryPopUp";
import Title from "../../components/dashboard/Header";
import { useMediaLibrary, useUser } from "../../utils/contexts";
import useAPI from "../../utils/api";

const AddMediaLibrary = () => {
    const API = useAPI();
    const currentUser: any = useUser();

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [image, setImage] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);

    const mediaLibraryData: any = useMediaLibrary();

    const handleImageUpload = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            setSelectedImage(file);
        };
        reader.readAsDataURL(file);
    };

    const handleImageSubmit = async () => {
        if (selectedImage) {
            const formData = new FormData();
            formData.append("file", selectedImage);
            formData.append("upload_folder", "PRODUCT");
            await uploadMedia(formData);
            setImage(null);
            setSelectedImage(null);
        }
    };

    const fetchMediaLibrary = async (page) => {
        mediaLibraryData.setMediaLoading(true);
        try {
            const [ok, response] = await API.getMediaImage(page);

            if (ok) {
                mediaLibraryData.setMediaLibrary(response.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            mediaLibraryData.setMediaLoading(false);
        }
    };

    const uploadMedia = async (formData) => {
        mediaLibraryData.setMediaLoading(true);
        try {
            const [ok, response] = await API.uploadMediaImage(formData);
            if (ok) {
                mediaLibraryData.setUploadMediaLibrary(response.message);
            }
        } catch (error) {
            console.error(error);
        } finally {
            mediaLibraryData.setMediaLoading(false);
        }
    };

    const deleteMedia = async (id) => {
        mediaLibraryData.setMediaLoading(true);
        try {
            const [ok, response] = await API.deleteMediaImage(id);
            if (ok) {
                mediaLibraryData.setDeleteMediaLibrary(response.message);
            }
        } catch (error) {
            console.error(error);
        } finally {
            mediaLibraryData.setMediaLoading(false);
        }
    };

    useEffect(() => {
        if (currentUser && !mediaLibraryData.allMedia[1]) {
            fetchMediaLibrary(1);
        }
    }, [currentUser]);

    useEffect(() => {
        if (mediaLibraryData.uploadMediaLibrary && !mediaLibraryData.mediaLoading) {
            mediaLibraryData.setAllMedia({});
            setPageNumber(1);
            fetchMediaLibrary(1);
            setDisableInfiniteScroll(false);
            mediaLibraryData.setUploadMediaLibrary(null);
        }
    }, [mediaLibraryData.uploadMediaLibrary, mediaLibraryData.mediaLoading]);

    useEffect(() => {
        if (mediaLibraryData.deleteMediaLibrary && !mediaLibraryData.mediaLoading) {
            mediaLibraryData.setAllMedia({});
            setPageNumber(1);
            setDisableInfiniteScroll(false);
            fetchMediaLibrary(1);
            mediaLibraryData.setDeleteMediaLibrary(null);
        }
    }, [mediaLibraryData.deleteMediaLibrary, mediaLibraryData.mediaLoading]);

    const fetchData = () => {
        if (disableInfiniteScroll) return;
        if(mediaLibraryData.allMedia[pageNumber+1]) {
            setPageNumber(pageNumber + 1);
            return;
        }
        fetchMediaLibrary(pageNumber + 1);
        setPageNumber(pageNumber + 1);
    };

    useEffect(() => {
        if (
            mediaLibraryData.mediaLibrary &&
            mediaLibraryData.mediaLibrary.length > 0
        ) {
            const updatedAllMedia = { ...mediaLibraryData.allMedia };
            updatedAllMedia[pageNumber] = mediaLibraryData.mediaLibrary;
            mediaLibraryData.setAllMedia(updatedAllMedia);

            setDisableInfiniteScroll(
                Boolean(mediaLibraryData.mediaLibrary.length < 15)
            );
        } else {
            setDisableInfiniteScroll(true);
        }
    }, [mediaLibraryData.mediaLibrary]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (item) => {
        setIsModalOpen(true);
        setSelectedItem(item);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedItem(null);
    };

    return (
        <IonPage>
            <div className="ion-page" id="main-content">
                <Title name="Media Library" />
                <IonContent className="upload-card">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12" sizeSm="6" sizeMd="2" sizeLg="2">
                                <Dropzone onDrop={handleImageUpload}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps()}
                                            style={{
                                                marginTop: "10px",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                height: "105px",
                                                border: "1px dashed #ccc",
                                                marginBottom: "1rem",
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    position: "relative",
                                                }}
                                            >
                                                {image ? (
                                                    <IonImg
                                                        src={image}
                                                        alt="Uploaded"
                                                        style={{
                                                            maxWidth: "100%",
                                                            maxHeight: "100%",
                                                            width: "auto",
                                                            height: "auto",
                                                            position:
                                                                "absolute",
                                                            top: 0,
                                                            left: 0,
                                                            bottom: 0,
                                                            right: 0,
                                                            objectFit:
                                                                "contain",
                                                        }}
                                                    />
                                                ) : (
                                                    <p
                                                        style={{
                                                            textAlign: "center",
                                                            cursor: "pointer",
                                                            margin: "8px 0",
                                                        }}
                                                    >
                                                        Drag and drop an image
                                                        here or click to select
                                                        a file.
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                                <IonButton
                                    expand="full"
                                    onClick={handleImageSubmit}
                                >
                                    Upload
                                </IonButton>
                            </IonCol>
                            {Object.keys(mediaLibraryData.allMedia).map(
                                (page) =>
                                    mediaLibraryData.allMedia[page].map(
                                        (item) => (
                                            <IonCol
                                                key={item.id}
                                                size="12"
                                                sizeSm="6"
                                                sizeMd="2"
                                                sizeLg="2"
                                            >
                                                <IonCard
                                                    key={item.id}
                                                    style={{ width: "90%" }}
                                                >
                                                    <IonImg
                                                        src={item.url}
                                                        alt="Card Image"
                                                        style={{
                                                            width: "100%",
                                                            height: "120px",
                                                            objectFit:
                                                                "contain",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            openModal(item)
                                                        }
                                                    />
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                            alignItems:
                                                                "center",
                                                            padding: "8px",
                                                        }}
                                                    >
                                                        <IonIcon
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            icon={trash}
                                                            size="small"
                                                            onClick={() =>
                                                                deleteMedia(
                                                                    item.id
                                                                )
                                                            }
                                                        />
                                                        <IonIcon
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            icon={
                                                                informationCircle
                                                            }
                                                            size="small"
                                                            onClick={() =>
                                                                openModal(item)
                                                            }
                                                        />
                                                    </div>
                                                </IonCard>
                                            </IonCol>
                                        )
                                    )
                            )}
                        </IonRow>
                        <IonInfiniteScroll
                            threshold="100px"
                            onIonInfinite={(e) => {
                                fetchData();
                                setTimeout(() => e.target.complete(), 500);
                            }}
                        >
                            <IonInfiniteScrollContent loadingText="Loading more..."></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                    </IonGrid>
                    <IonLoading
                        isOpen={mediaLibraryData.mediaLoading}
                        message="Please Wait"
                    ></IonLoading>
                </IonContent>
                {selectedItem && (
                    <MediaLibraryPopUp
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        media={selectedItem}
                    />
                )}
            </div>
        </IonPage>
    );
};

export default AddMediaLibrary;
