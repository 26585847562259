import { IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonToolbar } from '@ionic/react';
import {
    desktopOutline as desktopOutlineIcon, informationCircleOutline, phonePortraitOutline as phonePortraitOutlineIcon,
    tabletPortraitOutline as tabletPortraitOutlineIcon
} from 'ionicons/icons';

const Header = ({screenType, setScreenType, forApp}) => {
    const selectedButton = {
        borderBottom: '2px solid #3880ff'
    }

    function setScreen(type: string){
        setScreenType(type)
    }

    const openLinkInNewTab = () => {
        window.open(`https://intelikart.tawk.help/article/change-theme`, '_blank');
    };

    return (
        <IonHeader style={{ height: '68px' }}>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/settings" /> Back
                </IonButtons>
                {!forApp && <IonButtons className='ion-justify-content-center' style={{position:'relative'}}>
                    <IonButton style={screenType.toLowerCase() === 'mobile' ? selectedButton : {}} onClick={e=> setScreen('mobile')}>
                        <IonIcon slot="icon-only" icon={phonePortraitOutlineIcon}></IonIcon>
                    </IonButton>
                    <IonButton style={screenType.toLowerCase() === 'tablet' ? selectedButton : {}} onClick={e=> setScreen('tablet')}>
                        <IonIcon slot="icon-only" icon={tabletPortraitOutlineIcon}></IonIcon>
                    </IonButton>
                    <IonButton style={screenType.toLowerCase() === 'desktop' ? selectedButton : {}} onClick={e=> setScreen('desktop')}>
                        <IonIcon slot="icon-only" icon={desktopOutlineIcon}></IonIcon>
                    </IonButton>
                    <IonButton fill='clear' color='secondary' onClick={openLinkInNewTab} style={{ position:'absolute', right:'1%', }} >
                    <IonIcon icon={informationCircleOutline} slot="end" />
                </IonButton>
                </IonButtons>}

            </IonToolbar>
        </IonHeader>
    )
}

export default Header