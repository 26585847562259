import {
    IonButton,
    IonCard,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonRadio,
    IonRadioGroup,
    IonText,
    IonTitle
} from "@ionic/react";
import {
    addOutline as addOutlineIcon,
    caretDown as caretDownIcon,
    closeOutline
} from "ionicons/icons";
import { useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { useCategory } from "../../../utils/contexts";

const CategoryModal = ({category, setCategory, setCategoryId, validationText}) => {
    const categoryData:any = useCategory();
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <IonCard>
            <IonItem lines="none"  onClick={(e) => setShowModal(true)}>
                <IonLabel>Select category</IonLabel>
                {category ? <IonText slot="end" color="primary">{category.name}</IonText> : ""}
                <IonIcon color="primary" slot="end" size="small" icon={caretDownIcon} />
            </IonItem>
            {validationText && (
                <IonText color="danger">
                    <h5 className="ion-margin-horizontal error-text">{validationText}</h5>
                </IonText>
            )}
            </IonCard>
            <IonModal isOpen={showModal} onDidDismiss={(e) => setShowModal(false)}>
                <IonHeader>
                    <IonItem lines="none">
                        <IonTitle>Select category</IonTitle>
                        <IonButton fill="clear" size="large" slot="end" onClick={(e) => setShowModal(false)}>
                            <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                        </IonButton>
                    </IonItem>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonRadioGroup
                        value={category?.id}
                        onIonChange={(e) => {
                            setCategoryId(e.detail.value);
                            setShowModal(false);
                        }}
                    >
                        {!categoryData.loading && categoryData.status === "fetched" && categoryData.categories
                            ?categoryData.categories.map((parent) => {
                                return (
                                    <>
                                        <IonItem key={parent.id}>
                                            <IonText color="medium">
                                                <h5>{parent.name}</h5>
                                            </IonText>
                                        </IonItem>
                                        {parent.subCategory.map((child) => {
                                            return (
                                                <IonItem
                                                    key={child.id}
                                                    onClick={(e) => setCategory(child)}
                                                    lines="none"
                                                    className="ion-padding-start"
                                                >
                                                    {child.name}
                                                    <IonRadio slot="start" value={child.id} />
                                                </IonItem>
                                            );
                                        })}
                                        <IonItem lines="none">
                                            <div
                                                onClick={(e) => {
                                                    setShowModal(false);
                                                }}
                                                slot="end"
                                            >
                                                <IonButton
                                                    color="medium"
                                                    size="small"
                                                    routerLink={`/catalogue/category/add-subcat/${parent.id}/${parent.name}/${true}`}
                                                >
                                                    <IonIcon icon={addOutlineIcon} />
                                                    Add Sub-Category
                                                </IonButton>
                                            </div>
                                        </IonItem>
                                    </>
                                );
                            })
                            : ""}
                    </IonRadioGroup>
                    <div className="ion-text-center">
                        <Link
                            className="text-decoration-none"
                            onClick={(e) => setShowModal(false)}
                            to={`/catalogue/category/add-cat/${true}`}
                        >
                            <IonButton>
                                <IonIcon icon={addOutlineIcon} />
                                Add New Category
                            </IonButton>
                        </Link>
                    </div>
                </IonContent>
            </IonModal>
        </>
    );
};

export default CategoryModal;
