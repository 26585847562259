import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLoading, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import {
    arrowBack,
    arrowBack as arrowBackIcon,
    informationCircleOutline
} from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as XLSX from "xlsx";
import { addProductInBulk } from '../../../actions/productActions';
import { getAccessToken } from '../../../services/auth/userLocalStorage';

const compulsoryHeadingsForProductsWithoutVariants = ['Handle*', 'Product Name*', 'Category Name*', 'Sub Category*', 'Selling Price*', 'Quantity*', 'Unit*', 'Main Image URL*'];
const compulsoryHeadingsForProductsWithVariants = ['Handle*', 'Product Name*', 'Category Name*', 'Sub Category*', 'Selling Price*', 'Quantity*', 'Unit*', 'Variant Name 1', 'Variant Value 1', 'Main Image URL*'];
const compulsoryHeadingsForVariants = ['Handle*', 'Selling Price*', 'Quantity*', 'Unit*', 'Variant Name 1', 'Variant Value 1'];

const BulkProductList = () => {
    const [loading, setLoading] = useState(false);
    //file handling states
    const [fileData, setFileData] = useState<any>();
    const [groupData, setGroupData] = useState<any>();
    const [modifiedVariantRow, setModifiedVariantRow] = useState<any>();

    const [fileName, setFileName] = useState<any>();
    const fileInputRef = useRef<HTMLInputElement>();

    //file validation
    const [haveInvalidRows, setHaveInvalidRows] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state: any) => state.currentUser);
    const { addProductInBulkLoading, addProductInBulkMessage, addProductInBulkError } = useSelector((state: any) => state.addProductInBulkData);

    useEffect(() => {
        if (addProductInBulkLoading && !addProductInBulkError) {
            history.push('/catalogue/products');
        }
    }, [addProductInBulkLoading])

    const makeGroupArr = (arr) => {
        let grouped = arr.reduce((acc, curr) => {
            if (acc[curr['Handle*']]) {
                acc[curr['Handle*']].push(curr);
            } else {
                acc[curr['Handle*']] = [curr];
            }
            // sort the array of objects by the presence of the "Product Name" property
            acc[curr['Handle*']].sort((a, _) => (a["Product Name*"] ? -1 : 1));
            return acc;
        }, {});

        // convert the map object to an array of objects, where each object contains an array of objects with the same Handle
        let arrGroup = Object.keys(grouped).map(key => ({ 'Handle': key, 'items': grouped[key] }));

        return arrGroup;
    }

    const findVariantRow = (arr, count, variantRow) => {
        arr.forEach(elem => {
            for (let i = 0; i < elem['items'].length; i++) {
                if (i != 0) {
                    variantRow.push(count)
                }
                count += 1
            }
        })

        return variantRow
    }

    const readExcel = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {

            setLoading(true);
            setHaveInvalidRows(false);
            setFileName(event.target.files[0].name);
            setFileData([])
            setModifiedVariantRow([])

            let groupedArr = []

            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(event.target.files[0]);
            fileReader.onload = async (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                let arr: any = XLSX.utils.sheet_to_json(ws, { header: 3 });

                if (arr && arr[0]) {
                    // create a map of unique Handle values to arrays of objects with that Handle
                    let arrGroup = makeGroupArr(arr)

                    // remove items from group if it is not according to Product Headings
                    arrGroup.map((elem, index) => {
                        let items = elem['items']
                        let result = true
                        if (items.length > 1) {
                            compulsoryHeadingsForProductsWithVariants.map(heading => {
                                if (!(heading in items[0])) {
                                    result = false
                                }
                            })
                            if (!result)
                                setHaveInvalidRows(true)
                        } else {
                            compulsoryHeadingsForProductsWithoutVariants.map(heading => {
                                if (!(heading in items[0])) {
                                    result = false
                                }
                            })
                            if (!result)
                                setHaveInvalidRows(true)
                        }

                        if (result) {
                            groupedArr.push(elem)
                        }
                    })

                    // flatten the array of objects into a single array
                    let data = [...groupedArr].reduce((acc, curr) => acc.concat(curr.items), []);

                    // find variantRow
                    let variantRow = findVariantRow(groupedArr, 0, [])

                    let modifiedData = [];
                    data.map((elem, index) => {
                        let result = true
                        if (variantRow.includes(index)) {
                            compulsoryHeadingsForVariants.forEach(heading => {
                                if (!(heading in elem)) {
                                    result = false
                                }
                            })
                            if (!result)
                                setHaveInvalidRows(true)
                        }

                        if (result) {
                            modifiedData.push(elem)
                        }
                    })

                    // update the group array
                    arrGroup = makeGroupArr(modifiedData)

                    // update the variant row
                    variantRow = findVariantRow(arrGroup, 0, [])

                    setFileData(modifiedData)
                    setGroupData(arrGroup)
                    setModifiedVariantRow(variantRow)
                }
                setLoading(false)
            };
            fileReader.onerror = (error) => {
                console.log(error);
            };
        }
        event.target.value = '';
    };

    const removeProductFromList = async (index) => {
        if (modifiedVariantRow.includes(index)) {
            let temp0 = fileData;
            temp0.splice(index, 1);
            let groupedArr = makeGroupArr(temp0)
            let variantRow = findVariantRow(groupedArr, 0, [])
            setFileData(temp0)
            setGroupData(groupedArr)
            setModifiedVariantRow(variantRow)

        } else {
            let handle = fileData[index]['Handle*']
            let tempGroup = groupData.filter(acc => acc.Handle != handle)
            let variantRow = findVariantRow(tempGroup, 0, [])

            // flatten the array of objects into a single array
            let data = [...tempGroup].reduce((acc, curr) => acc.concat(curr.items), []);
            setFileData(data)
            setGroupData(tempGroup)
            setModifiedVariantRow(variantRow)
        }
    }

    const saveAllProduct = async () => {
        setLoading(true);

        let obj;
        let reqData = []
        let imageArrayForHandle = {};

        fileData.map((elem, index) => {
            const itemsLength = groupData.find(group => group.Handle == elem['Handle*'])?.items?.length || 0;
            if (itemsLength == 1) {
                let imagesArray = [{ 'image_url_original': elem['Main Image URL*'] }];
                for (let i = 1; i <= 3; i++) {
                    const imageKey = `Other Image URL ${i}`;
                    if (elem[imageKey]) {
                        imagesArray.push({ 'image_url_original': elem[imageKey] });
                    }
                }

                imageArrayForHandle[elem['Handle*']] = imagesArray

                obj = {
                    "name": `${elem['Product Name*']}`,
                    "desc_html": `${elem['Product Description'] ? elem['Product Description'] : ''}`,
                    "status": "active",
                    "category_name": `${elem['Category Name*']}`,
                    "sub_category_name": `${elem['Sub Category*']}`,
                    "handle": `${elem['Handle*']}`,
                    "sku": `${elem['Product SKU ID'] ? elem['Product SKU ID'] : ''}`,
                    "variants": [
                        {
                            "mrp": `${elem['Selling Price*']}`,
                            "quantity": `${elem['Quantity*']}`,
                            "display_mrp": elem['MRP'] ? elem['MRP'] < elem['Selling Price*'] ? null : elem['MRP'] : null,
                            "quantity_unit_name": `${elem['Unit*']}`,
                            "min_order_quantity": elem['Minimum Order Quantity'] ?? null,
                            "max_order_quantity": elem['Maximum Order Quantity'] ?? null,
                            "available_quantity": elem['Available Quantity'] ?? null,
                            "images": imagesArray,
                            "attribute_value_mappings": null
                        }
                    ]
                }

                reqData.push(obj)

            } else if (itemsLength > 1) {
                if (modifiedVariantRow.includes(index)) {
                    let attributeArray = [{ 'attribute_name': elem['Variant Name 1'], 'attribute_value': elem['Variant Value 1'] }];
                    for (let i = 2; i <= 3; i++) {
                        const attributeName = `Variant Name ${i}`;
                        const attributeValue = `Variant Value ${i}`;
                        if (elem[attributeName] && elem[attributeValue]) {
                            attributeArray.push({ 'attribute_name': elem[attributeName], 'attribute_value': elem[attributeValue] });
                        }
                    }

                    // find the index of the object with matching handle
                    const handleIndex = reqData.findIndex(obj => obj.handle == elem['Handle*']);
                    if (handleIndex !== -1) {
                        let obj = {
                            "mrp": `${elem['Selling Price*']}`,
                            "quantity": `${elem['Quantity*']}`,
                            "display_mrp": elem['MRP'] ? elem['MRP'] < elem['Selling Price*'] ? null : elem['MRP'] : null,
                            "quantity_unit_name": `${elem['Unit*']}`,
                            "min_order_quantity": elem['Minimum Order Quantity'] ?? null,
                            "max_order_quantity": elem['Maximum Order Quantity'] ?? null,
                            "available_quantity": elem['Available Quantity'] ?? null,
                            "images": imageArrayForHandle[elem['Handle*']],
                            "attribute_value_mappings": attributeArray
                        }
                        // handle exists, so insert the data into the existing variants array
                        reqData[handleIndex].variants.push(obj);
                    }
                } else {
                    let imagesArray = [{ 'image_url_original': elem['Main Image URL*'] }];
                    for (let i = 1; i <= 3; i++) {
                        const imageKey = `Other Image URL ${i}`;
                        if (elem[imageKey]) {
                            imagesArray.push({ 'image_url_original': elem[imageKey] });
                        }
                    }

                    imageArrayForHandle[elem['Handle*']] = imagesArray

                    let attributeArray = [{ 'attribute_name': elem['Variant Name 1'], 'attribute_value': elem['Variant Value 1'] }];
                    for (let i = 2; i <= 3; i++) {
                        const attributeName = `Variant Name ${i}`;
                        const attributeValue = `Variant Value ${i}`;
                        if (elem[attributeName] && elem[attributeValue]) {
                            attributeArray.push({ 'attribute_name': elem[attributeName], 'attribute_value': elem[attributeValue] });
                        }
                    }

                    obj = {
                        "name": `${elem['Product Name*']}`,
                        "desc_html": `${elem['Product Description'] ? elem['Product Description'] : ''}`,
                        "status": "active",
                        "category_name": `${elem['Category Name*']}`,
                        "sub_category_name": `${elem['Sub Category*']}`,
                        "handle": `${elem['Handle*']}`,
                        "sku": `${elem['Product SKU ID'] ? elem['Product SKU ID'] : ''}`,
                        "variants": [
                            {
                                "mrp": `${elem['Selling Price*']}`,
                                "quantity": `${elem['Quantity*']}`,
                                "display_mrp": elem['MRP'] ? elem['MRP'] < elem['Selling Price*'] ? null : elem['MRP'] : null,
                                "quantity_unit_name": `${elem['Unit*']}`,
                                "min_order_quantity": elem['Minimum Order Quantity'] ?? null,
                                "max_order_quantity": elem['Maximum Order Quantity'] ?? null,
                                "available_quantity": elem['Available Quantity'] ?? null,
                                "images": imagesArray,
                                "attribute_value_mappings": attributeArray
                            }
                        ]
                    }
                    reqData.push(obj)
                }
            }
        })

        await dispatch(addProductInBulk({ products: reqData }, currentUser.data.store.subdomain, getAccessToken()));

        setLoading(false);
    }

    const openLinkInNewTab = () => {
        window.open(`https://intelikart.tawk.help/article/bulk-upload`, '_blank');
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar style={{ display: 'flex', alignItems: 'center' }}>
                    <IonButtons slot="start" style={{ display: 'flex', alignItems: 'center' }}>
                        <IonBackButton defaultHref="/catalogue/products" icon={arrowBack} />
                    </IonButtons>
                    <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <IonTitle>Bulk upload products</IonTitle>
                        <IonButton fill='clear' color='secondary' onClick={openLinkInNewTab}>
                            <IonIcon icon={informationCircleOutline} slot="icon-only" />
                        </IonButton>
                    </div>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <input
                    hidden
                    type='file'
                    accept=".xls, .xlsx, .cvs"
                    ref={fileInputRef}
                    onChange={(e) => {
                        readExcel(e);
                    }}
                />
                <IonCard>
                    <IonCardHeader>
                        <h6 className="ion-no-margin ion-margin-bottom">Upload products from XLS file</h6>
                        Download a <a href="https://cdn.intelikart.com/templates/Intelikart_bulk_product_template.xlsx" target="_blank">sample XLSX template</a> to see an example of the format required.
                        <br />
                        <IonText style={{ fontSize: "13px" }} color="danger">Note: * fields are mandatory for products.</IonText>
                    </IonCardHeader>
                    <IonCardContent className='ion-no-padding ion-padding-horizontal ion-margin-bottom'>
                        <IonGrid style={{ border: '1px dashed blue' }}>
                            <IonRow className="ion-align-items-center ion-margin">
                                <IonCol className='ion-text-center '>
                                    {fileName && <h6><b>{fileName}</b></h6>}
                                    <IonButton shape="round" onClick={e => { fileInputRef.current.click(); }}>{fileName ? 'Replace' : 'Add'} File</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                {haveInvalidRows && <IonCard><IonCardContent>
                    <IonText color='danger'><h6 className='ion-no-margin ion-margin'>There are some invalid rows which are not considered. Please correct them</h6></IonText>
                </IonCardContent></IonCard>}
                {fileData && fileData.length !== 0 &&
                    <IonCard style={{ marginTop: 40 + 'px' }}>
                        <IonGrid>
                            <IonRow>
                                <IonCol><h4>Preview your products</h4></IonCol>
                                <IonCol>
                                    <div className="ion-text-end">
                                        <IonButton shape='round' onClick={e => saveAllProduct()} className={`${fileData && fileData.length !== 0 ? '' : 'ion-hide'}`}>Save and add products</IonButton>
                                    </div>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical" style={{ backgroundColor: 'rgba(220,220,220,0.6)' }}>
                                <IonCol size='3'><b>Images (upto 4)</b></IonCol>
                                <IonCol><b>Product Name</b></IonCol>
                                <IonCol><b>Category</b></IonCol>
                                <IonCol><b>Sub Category</b></IonCol>
                                <IonCol><b>Selling Price</b></IonCol>
                                <IonCol><b>Quantity</b></IonCol>
                                <IonCol><b>Action</b></IonCol>
                            </IonRow>
                            {fileData.map((element, index) => {
                                if (modifiedVariantRow.includes(index)) {
                                    let attributes = []
                                    for (let i = 1; i <= 3; i++) {
                                        const nameKey = `Variant Name ${i}`;
                                        const valueKey = `Variant Value ${i}`;
                                        if (element[nameKey] && element[valueKey]) {
                                            attributes[element[nameKey]] = element[valueKey]
                                        }
                                    }

                                    let productName = '(' + Object.entries(attributes).map(([key, value]) => key + ':' + value).join(', ') + ')';

                                    return (
                                        <IonRow className='ion-align-items-center ion-margin-top' style={{ borderBottom: '1px solid rgb(220,220,220)' }} key={index}>
                                            <IonCol size='3'>
                                            </IonCol>
                                            <IonCol>{productName}</IonCol>
                                            <IonCol>{element['Category Name*']}</IonCol>
                                            <IonCol>{element['Sub Category*']}</IonCol>
                                            <IonCol>&#8377;{element['Selling Price*']}</IonCol>
                                            <IonCol>{element['Quantity*']} {element['Unit*']}</IonCol>
                                            <IonCol><IonText color='danger' style={{ cursor: 'pointer' }} onClick={e => removeProductFromList(index)}>Remove</IonText></IonCol>
                                        </IonRow>
                                    )
                                } else {
                                    let attributes = []
                                    let productName
                                    for (let i = 1; i <= 3; i++) {
                                        const nameKey = `Variant Name ${i}`;
                                        const valueKey = `Variant Value ${i}`;
                                        if (element[nameKey] && element[valueKey]) {
                                            attributes[element[nameKey]] = element[valueKey]
                                        }
                                    }

                                    let attribute = Object.entries(attributes).map(([key, value]) => key + ':' + value).join(', ');
                                    if (attribute) {
                                        productName = element['Product Name*'] + ' (' + attribute + ')'
                                    } else {
                                        productName = element['Product Name*']
                                    }

                                    return (
                                        <IonRow className='ion-align-items-center ion-margin-top' style={{ borderBottom: '1px solid rgb(220,220,220)' }} key={index}>
                                            <IonCol size='3'>
                                                <img width='48' height='48' style={{ marginRight: 2 + 'px' }} src={element['Main Image URL*']} />

                                                {/* optional images */}
                                                <img className={`${element.hasOwnProperty('Other Image URL 1') ? "" : 'ion-hide'}`} style={{ marginRight: 2 + 'px' }} width='48' height='48' src={element['Other Image URL 1']} />
                                                <img className={`${element.hasOwnProperty('Other Image URL 2') ? "" : 'ion-hide'}`} style={{ marginRight: 2 + 'px' }} width='48' height='48' src={element['Other Image URL 2']} />
                                                <img className={`${element.hasOwnProperty('Other Image URL 3') ? "" : 'ion-hide'}`} style={{ marginRight: 2 + 'px' }} width='48' height='48' src={element['Other Image URL 3']} />
                                            </IonCol>
                                            <IonCol>{productName}</IonCol>
                                            <IonCol>{element['Category Name*']}</IonCol>
                                            <IonCol>{element['Sub Category*']}</IonCol>
                                            <IonCol>&#8377;{element['Selling Price*']}</IonCol>
                                            <IonCol>{element['Quantity*']} {element['Unit*']}</IonCol>
                                            <IonCol><IonText color='danger' style={{ cursor: 'pointer' }} onClick={e => removeProductFromList(index)}>Remove</IonText></IonCol>
                                        </IonRow>
                                    )
                                }
                            })}
                        </IonGrid>
                    </IonCard>
                }

            </IonContent>
            <IonLoading isOpen={loading}></IonLoading>
        </IonPage>
    )
}

export default BulkProductList
