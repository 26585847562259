import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonText, IonTitle, IonToggle, IonToolbar, useIonToast } from "@ionic/react";
import { arrowBack, informationCircleOutline } from "ionicons/icons";
import { useState } from "react";
import { useAddon } from '../../../utils/contexts';
import useAPI from '../../../utils/api';
import { indexedValue } from '../AddonsRoutes';
import FeatHeader from '../../../components/dashboard/FeatHeader';


const OptimonkForm = () => {
    const addons: any = useAddon();
    const [present] = useIonToast();
    const API = useAPI();
    const [accountId, setAccountId] = useState(Array.isArray(addons[indexedValue.optimonk].data) ? '' : addons[indexedValue.optimonk].data?.account_id);
    const [optimonkCheck, setOptimonkCheck] = useState(Array.isArray(addons[indexedValue.optimonk].data) ? false : addons[indexedValue.optimonk].data?.status === 'active');
    const [accountIdValidation, setAccountIdValidation] = useState('');

    const validation = () => {
        let result = true;

        if (!accountId) {
            setAccountIdValidation('OptiMonk Account Id is required');
            result = false;
        } else {
            if(/\D/.test(accountId)){
                setAccountIdValidation("OptiMonk Account Id must contain a numeric value")
                result = false;
            }
            else{
                setAccountIdValidation('');
            }
        }
        return result;
    }
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        if (validation()) {
            const [ok, response] = Array.isArray(addons[indexedValue.optimonk].data) ?
                await API.createOptimonkAddon({ "account_id": accountId, "status": optimonkCheck ? "active" : "inactive" })
                :
                await API.updateOptimonkAddon({ "account_id": accountId, "status": optimonkCheck ? "active" : "inactive", id: addons[indexedValue.optimonk].data.id });

            if (ok) {
                present("Data saved successfully!", 1000);
                addons[indexedValue.optimonk].updateMethod(!addons[indexedValue.optimonk].update);
            }
            else {
                present(response.message, 1000);
            }
        }
    }

    const openLinkInNewTab = () => {
        window.open(`https://intelikart.tawk.help/article/optimonk`, '_blank');
    };

    return (
        <IonPage>
            
            <FeatHeader title='OptiMonk' showInfoButton={true} onInfoClick={ openLinkInNewTab} defaultHref='/add-ons' />
            <IonContent className="ion-padding">
                <form onSubmit={onSubmitHandler}>
                    <IonItem>
                        <IonLabel position="stacked"><h1>OptiMonk Account ID</h1></IonLabel>
                        <IonInput placeholder="Enter OptiMonk Account ID" type="text" value={accountId} onIonChange={(e) => { setAccountId(e.detail.value) }} onIonBlur={e => validation()} />
                    </IonItem>
                    <IonText color="danger" className={`${accountIdValidation ? "" : "ion-hide"}`}>
                        <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px', marginBottom: 0 + 'px' }} className='ion-margin-horizontal'>{accountIdValidation}</h5>
                    </IonText>
                    <br />
                    <IonItem lines="none">
                        <IonText><h5>Enable OptiMonk</h5></IonText>
                        <IonToggle slot="end" checked={optimonkCheck} onIonChange={e => setOptimonkCheck(e.detail.checked)} />
                    </IonItem>
                    <br />
                    <div className="ion-text-center">
                        <IonButton type="submit">Save</IonButton>
                    </div>
                </form>
            </IonContent>
        </IonPage>
    );

}

export default OptimonkForm;