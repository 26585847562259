import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonModal, IonRow, IonText, IonTitle } from '@ionic/react';
import {
    shareSocialOutline as shareIcon,
    logoWhatsapp as whatsappIcon,
    logoFacebook as facebookIcon,
    homeSharp,
    closeOutline
} from 'ionicons/icons';
import React, { useState } from 'react';

export const ShareButtonWeb = (props: any) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>  <IonButton fill='clear'color='secondary' slot='end'  onClick={e=> setShowModal(true)} >
                <IonIcon slot="icon-only"    icon={shareIcon}  ></IonIcon> 
            </IonButton>
            {/* <IonIcon size="large" icon={shareIcon} onClick={(e) => setShowModal(true)}></IonIcon> */}
            <IonModal id={"sharemodal"} isOpen={showModal} onDidDismiss={e=>setShowModal(false)}>
                <IonHeader>
                    <IonItem lines="none">
                        <IonTitle>Share</IonTitle>
                        <IonButton fill='clear'color='primary' slot='end'  onClick={e=> setShowModal(false)} >
                            <IonIcon slot="icon-only" icon={closeOutline}  ></IonIcon> 
                        </IonButton>
                        {/* <IonText color="primary" slot="end" onClick={e=> setShowModal(false)}>
                        <IonIcon  size='large' icon={closeOutline} />
                        </IonText> */}
                    </IonItem>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <a style={{textDecoration: 'none'}} href={`https://api.whatsapp.com/send/?text=${props.text}`} data-action="share/whatsapp/share" target="_blank">
                        <IonItem lines='none'>
                            <IonIcon  size="large" color="success" icon={whatsappIcon}></IonIcon>
                            <IonText>&nbsp; Whatsapp </IonText>
                        </IonItem>
                    </a>
                    </IonCol>
                            <IonCol>
                            <a style={{textDecoration: 'none'}} href={`https://www.facebook.com/sharer/sharer.php?u=${props.url}&quote=${props.text}`} target="_blank">
                        <IonItem lines='none'>
                            <IonIcon size="large" color="primary" icon={facebookIcon}></IonIcon>
                            <IonText>&nbsp; Facebook</IonText>
                        </IonItem>
                    </a>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    
                   
                </IonContent>
            </IonModal>
        </>
    )
}
