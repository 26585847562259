import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonText, IonTitle, IonToggle, IonToolbar, useIonToast } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import {
    arrowBack as arrowBackIcon
} from 'ionicons/icons';
import { useAddon } from '../../../utils/contexts';
import useAPI from '../../../utils/api';
import { indexedValue } from '../AddonsRoutes';
import FeatHeader from '../../../components/dashboard/FeatHeader';

const GAnalyticalForm = () => {
    const addons: any = useAddon();
    const [present] = useIonToast();
    const API = useAPI();
    const [error, setError] = useState(false);
    const [validation, setValidation] = useState(false);
    const [id, setId] = useState(Array.isArray(addons[indexedValue.ga].data) ? '' : addons[indexedValue.ga].data?.google_measurement_id);
    const [gaCheck, setGACheck] = useState(Array.isArray(addons[indexedValue.ga].data) ? false : addons[indexedValue.ga].data?.status === 'active');


    const validateId = (input) => {
        const regex = /^[A-Za-z][A-Za-z0-9-]*$/;
        return regex.test(input);
    }
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        if (id) {
        if (validateId(id)){
            const [ok, response] = Array.isArray(addons[indexedValue.ga].data) ?
                await API.createGAAddon({ "google_measurement_id": id, "status": gaCheck ? "active" : "inactive" })
                :
                await API.updateGAAddon({ "google_measurement_id": id, "status": gaCheck ? "active" : "inactive", id: addons[indexedValue.ga].data.id });

                if(ok) {
                    present("Data saved successfully!", 1000);
                    addons[indexedValue.ga].updateMethod(!addons[indexedValue.ga].update);
                }
        }
        else{
            present("Please enter a valid Google analytics id.", 1000);
        }
     }
     
      else{
            setError(true)
        }
    }

    return (<IonPage>

        <FeatHeader
            title='Configure Google Analytics'
            defaultHref="/settings"
            showInfoButton={false}


        />
        <IonContent className="ion-padding">
            <form onSubmit={onSubmitHandler}>
                <IonItem>
                    <IonLabel position="stacked"><h1>Google analytics id</h1></IonLabel>
                    <IonInput placeholder="Enter Google mesurement id" type="text" value={id} onIonChange={(e) => { setId(e.detail.value) }} onIonBlur={(e: any) => e.detail.value == '' ? setError(true) : setError(false)}  />
                </IonItem>
                <IonText color="danger" className={`${error ? "" : "ion-hide"}`}>
                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }} className='ion-margin-horizontal'>Google analytics id is required.</h5>
                </IonText>
                    <IonText color="danger" className={`${validation ? "": "ion-hide" }`}>
                        <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className='ion-margin-horizontal'>Please enter a valid Google analytics id.</h5>
                    </IonText>
                <br />
                <IonItem lines="none">
                    <IonText><h5>Enable Google Analytics</h5></IonText>
                    <IonToggle slot="end" checked={gaCheck} onIonChange={e => setGACheck(e.detail.checked)} />
                </IonItem>
                <br />
                <div className="ion-text-center">
                    <IonButton type="submit">Save</IonButton>
                </div>
            </form>
            {/* <IonLoading isOpen={loadingGARecords} message="Please Wait"></IonLoading> */}
        </IonContent>
    </IonPage>
    )
}

export default GAnalyticalForm
