import { 
    GET_PICKUP_FAIL, GET_PICKUP_REQUEST, GET_PICKUP_SUCCESS, 
    GET_SHIPROCKET_ORDER_FAIL, 
    GET_SHIPROCKET_ORDER_REQUEST, 
    GET_SHIPROCKET_ORDER_SUCCESS, 
    POST_PICKUP_LOCATION_FAIL, POST_PICKUP_LOCATION_REQUEST, POST_PICKUP_LOCATION_SUCCESS, 
    POST_SHIPROCKET_ORDER_FAIL, POST_SHIPROCKET_ORDER_REQUEST, POST_SHIPROCKET_ORDER_SUCCESS
 } from "../constants/shiprocketConstants";

const API_ROOT = 'https://api2.intelikart.in/';

// Get all pickup locations
export const getPickupLocations = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_PICKUP_REQUEST})
        const response = await fetch(`${ API_ROOT }shiprocket/pickup-location`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${accessToken}`,
                "x-ik-domain" : `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_PICKUP_SUCCESS,
            payload: data
        })
    }catch(error) {
        dispatch({
            type: GET_PICKUP_FAIL,
            payload: error
        })
    }
}

// Create Shiprocket Order
export const postShiprocketOrder = (orderId: number,accessToken: string, ikDomain: string, shiprocketOrderData: any) => async (dispatch: any) => {
    try{
        dispatch({type : POST_SHIPROCKET_ORDER_REQUEST})
        const response = await fetch(`${ API_ROOT }shiprocket/orders/${orderId}`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${accessToken}`,
                "x-ik-domain" : `${ikDomain}`
            },
            body: JSON.stringify(shiprocketOrderData)
        });
        const data = await response.json();
        dispatch({
            type: POST_SHIPROCKET_ORDER_SUCCESS,
            payload: data
        })
    }catch(error) {
        dispatch({
            type: POST_SHIPROCKET_ORDER_FAIL,
            payload: error
        })
    }
}

// Create Pickup Location
export const addPickupLocation = (accessToken: string, ikDomain: string, pickupLocationData: any) => async (dispatch: any) => {
    try{
        dispatch({type : POST_PICKUP_LOCATION_REQUEST})
        const response = await fetch(`${ API_ROOT }shiprocket/pickup-location`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${accessToken}`,
                "x-ik-domain" : `${ikDomain}`
            },
            body: JSON.stringify(pickupLocationData)
        });
        const data = await response.json();
        dispatch({
            type: POST_PICKUP_LOCATION_SUCCESS,
            payload: data
        })
    }catch(error) {
        dispatch({
            type: POST_PICKUP_LOCATION_FAIL,
            payload: error
        })
    }
}

// Get all shiprocket orders
export const getShiprocketOrder = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_SHIPROCKET_ORDER_REQUEST})
        const response = await fetch(`${ API_ROOT }shiprocket/orders`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${accessToken}`,
                "x-ik-domain" : `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_SHIPROCKET_ORDER_SUCCESS,
            payload: data
        })
    }catch(error) {
        dispatch({
            type: GET_SHIPROCKET_ORDER_FAIL,
            payload: error
        })
    }
}