import {
  IonAccordionGroup,
  IonButton,
  IonContent,
  IonReorderGroup,
  useIonToast,
} from "@ionic/react";
import { useEffect, useState } from "react";
import useAPI from "../../../../utils/api";
import { WebsiteSettingsContext } from "../../../../utils/contexts";
import useFetch from "../../../../utils/useFetch";
import BannerSlides from "../elements/homepage/BannerSlides";
import CategorySlides from "../elements/homepage/CategorySlides";
import CategorySubcategoryList from "../elements/homepage/CategorySubcategoryList";
import Footer from "../elements/homepage/Footer";
import Header from "../elements/homepage/Header";
import Poster from "../elements/homepage/Poster";
import QuickOrder from "../elements/homepage/QuickOrder";
import RecentProducts from "../elements/homepage/RecentProducts";
import WebsiteStyling from "../elements/websiteStyling/WebsiteStyling";
import StickyProduct from "../elements/homepage/StickyProduct";
import StickyCategory from "../elements/homepage/StickyCategory";
import Brands from "../elements/homepage/Brands";

const themeDetails = {
  header: {
    getComponent: function (this) {
      return <Header key="header" />;
    },
  },
  category_sliders: {
    getComponent: function (this) {
      return <CategorySlides data={this?.data} key="categorySlides" />;
    },
    data: {
      toggle: true,
      reorder: true,
    },
  },
  banner_sliders: {
    getComponent: function (this) {
      return <BannerSlides data={this?.data} key="bannerSLides" />;
    },
    data: {
      toggle: true,
      reorder: true,
    },
  },
  poster: {
    getComponent: function (this) {
      return <Poster data={this?.data} key="poster" />;
    },
    data: {
      toggle: true,
      reorder: true,
    },
  },
  product_sliders: {
    getComponent: function (this) {
      return <RecentProducts data={this?.data} key="recentProducts" />;
    },
    data: {
      toggle: true,
      reorder: true,
    },
  },
  brand_sliders: {
    getComponent: function (this) {
      return <Brands data={this?.data} key="brands" />;
    },
    data: {
      toggle: true,
      reorder: true,
    },
  },
  category_subcategory_lists: {
    getComponent: function (this) {
      return (
        <CategorySubcategoryList
          data={this?.data}
          key="categorySubcategoryList"
        />
      );
    },
    data: {
      toggle: true,
      reorder: true,
    },
  },
  quick_orders: {
    getComponent: function (this) {
      return <QuickOrder data={this?.data} key="QuickOrder" />;
    },
    data: {
      toggle: true,
      reorder: true,
    },
  },
  sticky_product: {
    getComponent: function (this) {
      return <StickyProduct data={this?.data} key="StickyProduct" />;
    },
    data: {
      toggle: true,
      reorder: true,
    },
  },

  sticky_category_sliders: {
    getComponent: function (this) {
      return <StickyCategory data={this?.data} key="stickyCategory" />;
    },
    data: {
      toggle: true,
      reorder: true,
    },
  },

  footer: {
    getComponent: function (this) {
      return <Footer key="footer" />;
    },
  },
  global_settings: {
    getComponent: function (this, forApp) {
      return <WebsiteStyling key="website styling" forApp={forApp} />;
    },
  },
};

const validateCurrentData = (data) => {
  let result = true;
  // console.log(data);
  if (data) {
    if (
      data["header"]?.display_order !== 1 ||
      data["footer"]?.display_order !== 11
    ) {
      result = false;
    }
    let array = new Array(12).fill(0);
    array[1] = 1;
    array[11] = 1;
    for (const element of Object.keys(data)) {
      if (
        element !== "header" &&
        element !== "footer" &&
        element !== "global_settings"
      ) {
        if (
          data[`${element}`].display_order >= 11 ||
          data[`${element}`].display_order <= 1
        ) {
          result = false;
          break;
        }
        if (array[data[`${element}`]?.display_order] === 1) {
          result = false;
          break;
        } else {
          array[data[`${element}`]?.display_order] = 1;
        }
      }
    }
  }
  return result;
};

const SideMenu = ({ iframeRef, forApp }) => {
  const [update, setUpdate] = useState(false);
  const { loading, data, status } = useFetch(
    `${forApp ? "appTheme" : "theme"}`,
    update
  );
  console.log('data',data);
  const API = useAPI();
  const [present] = useIonToast();
  const [websiteSettings, setWebsiteSettings] = useState<any>();

  useEffect(() => {
    if (!loading && status === "fetched") {
      let websiteData = undefined;
      if (
        data.data.header != null &&
        data.data.banner_sliders &&
        data.data.category_sliders &&
        data.data.category_subcategory_lists &&
        data.data.footer &&
        data.data.product_sliders &&
        data.data.brand_sliders &&
        data.data.poster &&
        data.data.sticky_product &&
        data.data.sticky_category_sliders
      ) {
        //for quick orders
        if (data.data?.quick_orders) {
          if (data.data.quick_orders?.display_order === 8) {
            let temp = { ...data.data };
            temp["header"].display_order = 1;
            temp["quick_orders"].display_order = 2;
            temp["category_sliders"].display_order =
              temp["category_sliders"].display_order + 1;
            temp["banner_sliders"].display_order =
              temp["banner_sliders"].display_order + 1;
            if ("display_order" in temp["poster"]) {
              temp["poster"].display_order = temp["poster"].display_order + 1;
            } else {
              temp["poster"].display_order = 5;
            }
            if ("display_order" in temp["sticky_product"]) {
              temp["sticky_product"].display_order =
                temp["sticky_product"].display_order + 1;
            } else {
              temp["sticky_product"].display_order = 8;
            }
            if ("display_order" in temp["sticky_category_sliders"]) {
              temp["sticky_category_sliders"].display_order =
                temp["sticky_category_sliders"].display_order + 1;
            } else {
              temp["sticky_category_sliders"].display_order = 9;
            }
            if ("display_order" in temp["brand_sliders"]) {
              temp["brand_sliders"].display_order =
                temp["brand_sliders"].display_order + 1;
            } else {
              temp["brand_sliders"].display_order = 10;
            }
            temp["product_sliders"].display_order =
              temp["product_sliders"].display_order + 1;
            temp["category_subcategory_lists"].display_order =
              temp["category_subcategory_lists"].display_order + 1;
            temp["footer"].display_order = 11;
            setWebsiteSettings(temp);
            websiteData = temp;
          } else {
            setWebsiteSettings(data.data);
            websiteData = data.data;
          }
        } else {
          let temp = { ...data.data };
          temp["header"].display_order = 1;
          temp["category_sliders"].display_order = 3;
          temp["banner_sliders"].display_order = 4;
          temp["poster"].display_order = 5;
          temp["product_sliders"].display_order = 6;
          temp["category_subcategory_lists"].display_order = 7;

          temp["sticky_product"].display_order = 8;
          temp["sticky_category_sliders"].display_order = 9;
          temp["brand_sliders"].display_order = 10;
          temp["footer"].display_order = 11;
          setWebsiteSettings({
            ...temp,
            quick_orders: {
              display: 0,
              display_order: 2,
              component_heading: "Quick order",
            },
          });
          websiteData = {
            ...temp,
            quick_orders: {
              display: 0,
              display_order: 2,
              component_heading: "Quick order",
            },
          };
        }

        //for poster
        if (
          !("display_order" in data.data?.poster) ||
          !("component_heading" in data.data?.poster) ||
          !("display" in data.data?.poster)
        ) {
          let temp = { ...data.data };
          temp["header"].display_order = 1;
          if (temp["category_sliders"].display_order >= 5) {
            temp["category_sliders"].display_order =
              temp["category_sliders"].display_order + 1;
          }
          if (temp["brand_sliders"].display_order >= 5) {
            temp["brand_sliders"].display_order =
              temp["brand_sliders"].display_order + 1;
          }
          if (temp["quick_orders"].display_order >= 5) {
            temp["quick_orders"].display_order =
              temp["quick_orders"].display_order + 1;
          }
          if (temp["banner_sliders"].display_order >= 5) {
            temp["banner_sliders"].display_order =
              temp["banner_sliders"].display_order + 1;
          }
          if (temp["product_sliders"].display_order >= 5) {
            temp["product_sliders"].display_order =
              temp["product_sliders"].display_order + 1;
          }
          if (temp["category_subcategory_lists"].display_order >= 5) {
            temp["category_subcategory_lists"].display_order =
              temp["category_subcategory_lists"].display_order + 1;
          }
          temp["footer"].display_order = 9;
          setWebsiteSettings({
            ...temp,
            poster: {
              display: 0,
              display_order: 5,
              component_heading: "Poster",
            },
          });
          websiteData = {
            ...temp,
            poster: {
              display: 0,
              display_order: 5,
              component_heading: "Poster",
            },
          };
        }

        if (
          !("display_order" in data.data?.sticky_product) ||
          !("component_heading" in data.data?.sticky_product) ||
          !("display" in data.data?.sticky_product)
        ) {
          let temp = { ...data.data };
          temp["header"].display_order = 1;
          if (temp["category_sliders"].display_order >= 5) {
            temp["category_sliders"].display_order =
              temp["category_sliders"].display_order + 1;
          }
          if (temp["brand_sliders"].display_order >= 5) {
            temp["brand_sliders"].display_order =
              temp["brand_sliders"].display_order + 1;
          }
          if (temp["quick_orders"].display_order >= 5) {
            temp["quick_orders"].display_order =
              temp["quick_orders"].display_order + 1;
          }
          if (temp["banner_sliders"].display_order >= 5) {
            temp["banner_sliders"].display_order =
              temp["banner_sliders"].display_order + 1;
          }
          if (temp["product_sliders"].display_order >= 5) {
            temp["product_sliders"].display_order =
              temp["product_sliders"].display_order + 1;
          }
          if (temp["category_subcategory_lists"].display_order >= 5) {
            temp["category_subcategory_lists"].display_order =
              temp["category_subcategory_lists"].display_order + 1;
          }
          temp["footer"].display_order = 9;
          setWebsiteSettings({
            ...temp,
            sticky_product: {
              display: 0,
              display_order: 8,
              component_heading: "",
            },
          });
          websiteData = {
            ...temp,
            sticky_product: {
              display: 0,
              display_order: 8,
              component_heading: "Sticky Products",
            },
          };
        }

        if (
          !("display_order" in data.data?.sticky_category_sliders) ||
          !("component_heading" in data.data?.sticky_category_sliders) ||
          !("display" in data.data?.sticky_category_sliders)
        ) {
          let temp = { ...data.data };
          temp["header"].display_order = 1;
          if (temp["category_sliders"].display_order >= 5) {
            temp["category_sliders"].display_order =
              temp["category_sliders"].display_order + 1;
          }
          if (temp["brand_sliders"].display_order >= 5) {
            temp["brand_sliders"].display_order =
              temp["brand_sliders"].display_order + 1;
          }
          if (temp["quick_orders"].display_order >= 5) {
            temp["quick_orders"].display_order =
              temp["quick_orders"].display_order + 1;
          }
          if (temp["banner_sliders"].display_order >= 5) {
            temp["banner_sliders"].display_order =
              temp["banner_sliders"].display_order + 1;
          }
          if (temp["product_sliders"].display_order >= 5) {
            temp["product_sliders"].display_order =
              temp["product_sliders"].display_order + 1;
          }
          if (temp["category_subcategory_lists"].display_order >= 5) {
            temp["category_subcategory_lists"].display_order =
              temp["category_subcategory_lists"].display_order + 1;
          }
          temp["footer"].display_order = 11;
          setWebsiteSettings({
            ...temp,
            sticky_category_sliders: {
              display: 0,
              display_order: 9,
              component_heading: "Sticky Categories",
              slides: temp["sticky_category_sliders"]?.slides || [],
            },
          });
          websiteData = {
            ...temp,
            sticky_category_sliders: {
              display: 0,
              display_order: 9,
              component_heading: "Sticky Categories",
              slides: temp["sticky_category_sliders"]?.slides || [],
            },
          };
        }

        if (
          !("display_order" in data.data?.brand_sliders) ||
          !("component_heading" in data.data?.brand_sliders) ||
          !("display" in data.data?.brand_sliders)
        ) {
          let temp = { ...data.data };
          // Adjust display order for other components
           temp["header"].display_order = 1;
           if (temp["category_sliders"].display_order >= 5) {
             temp["category_sliders"].display_order =
               temp["category_sliders"].display_order + 1;
           }
           if (temp["brand_sliders"].display_order >= 5) {
             temp["brand_sliders"].display_order =
               temp["brand_sliders"].display_order + 1;
           }
           if (temp["quick_orders"].display_order >= 5) {
             temp["quick_orders"].display_order =
               temp["quick_orders"].display_order + 1;
           }
           if (temp["banner_sliders"].display_order >= 5) {
             temp["banner_sliders"].display_order =
               temp["banner_sliders"].display_order + 1;
           }
           if (temp["product_sliders"].display_order >= 5) {
             temp["product_sliders"].display_order =
               temp["product_sliders"].display_order + 1;
           }
           if (temp["category_subcategory_lists"].display_order >= 5) {
             temp["category_subcategory_lists"].display_order =
               temp["category_subcategory_lists"].display_order + 1;
           }
           temp["footer"].display_order = 11;
           setWebsiteSettings({
             ...temp,
            brand_sliders: {
               display: 0,
               display_order: 10,
               component_heading: "brand",
             },
           });
           websiteData = {
             ...temp,
             brand_sliders: {
               display: 0,
               display_order: 10,
               component_heading: "brand",
             },
           };
        }

        if (!data.data.global_settings?.branding_colors) {
          setWebsiteSettings({
            ...data.data,
            global_settings: {
              ...data.data.global_settings,
              branding_colors: {
                primary_color: "#4286f5",
                secondary_color: "#666",
                background_color: "#FFFFFF",
              },
            },
          });
          websiteData = {
            ...data.data,
            global_settings: {
              ...data.data.global_settings,
              branding_colors: {
                primary_color: "#4286f5",
                secondary_color: "#666",
                background_color: "#FFFFFF",
              },
            },
          };
        }

        if (
          data.data.global_settings?.branding_colors &&
          !data.data.global_settings?.branding_colors?.background_color
        ) {
          setWebsiteSettings({
            ...data.data,
            global_settings: {
              ...data.data.global_settings,
              branding_colors: {
                ...data.data.global_settings?.branding_colors,
                background_color: "#FFFFFF",
              },
            },
          });
          websiteData = {
            ...data.data,
            global_settings: {
              ...data.data.global_settings,
              branding_colors: {
                ...data.data.global_settings?.branding_colors,
                background_color: "#FFFFFF",
              },
            },
          };
        }

        if (!validateCurrentData(websiteData)) {
          let temp = { ...websiteData };
          temp["header"].display_order = 1;
          temp["quick_orders"].display_order = 2;
          temp["category_sliders"].display_order = 3;
          temp["banner_sliders"].display_order = 4;
          temp["poster"].display_order = 5;
          temp["product_sliders"].display_order = 6;
          temp["category_subcategory_lists"].display_order = 7;
          temp["sticky_product"].display_order = 8;
          temp["sticky_category_sliders"].display_order = 9;
          temp["brand_sliders"].display_order = 10;
          temp["footer"].display_order = 11;
          setWebsiteSettings(temp);
          saveSettings();
        }
      } else {
        setWebsiteSettings({
          header: {
            display: 1,
            display_order: 1,
          },
          quick_orders: {
            display: forApp ? 1 : 0,
            display_order: 2,
            component_heading: "Quick order",
          },
          category_sliders: {
            display: 1,
            display_order: 3,
            component_heading: "Shop by category",
          },
          banner_sliders: {
            display: 1,
            display_order: 4,
            component_heading: "",
            slides: data.data?.banner_sliders?.slides || [],
          },
          poster: {
            display: 0,
            display_order: 5,
            component_heading: "",
            poster_info: data.data?.poster?.poster_info || null,
          },

          product_sliders: {
            display: 1,
            display_order: 6,
            component_heading: "Recent products",
          },
          category_subcategory_lists: {
            display: 1,
            display_order: 7,
            component_heading: "",
          },
          sticky_product: {
            display: 0,
            display_order: 8,
            component_heading: "Sticky Product",
            sticky_product_info:
              data.data?.sticky_product?.sticky_product_info || [],
          },
          sticky_category_sliders: {
            display: 0,
            display_order: 9,
            component_heading: "Sticky Categories",
            slides: data.data?.sticky_category_sliders?.slides || [],
          },
          brand_sliders: {
            display: 0,
            display_order: 10,
            component_heading: "Brand",
            
          },
          footer: {
            display: 1,
            display_order: 11,
          },
          global_settings: {
            branding_colors: {
              primary_color: "#4286f5",
              secondary_color: "#666",
              background_color: "#FFFFFF",
            },
          },
        });
      }
    }
  }, [loading, status, data]);

  useEffect(() => {
    if (!forApp) {
      setTimeout(() => {
        if (iframeRef.current) {
          iframeRef.current?.contentWindow.postMessage(
            { type: "click", home: websiteSettings },
            "*"
          );
        }
      }, 10000);
    }
  }, [iframeRef, forApp]);

  useEffect(() => {
    if (!forApp && iframeRef.current) {
      iframeRef.current?.contentWindow.postMessage(
        { type: "click", home: websiteSettings },
        "*"
      );
    }
  }, [iframeRef, websiteSettings, forApp]);

  const objectToArray = (object) => {
    let arr = [];
    for (const property in object) {
      if (
        property !== "global_settings" &&
        property !== "header" &&
        property !== "footer"
      ) {
        if (object[property]) {
          arr[object[property].display_order - 2] = property;
        }
      }
    }
    return arr;
  };

  const arrayToObject = (array) => {
    setWebsiteSettings((prev) => {
      let temp = { ...prev };
      for (let i = 0; i < array.length; i++) {
        if (
          array[i] !== "header" &&
          array[i] !== "footer" &&
          array[i] !== "global_settings"
        ) {
          if (i + 2 < 11) {
            if (temp[array[i]]) temp[array[i]].display_order = i + 2;
          } else {
            temp["quick_orders"].display_order = 2;
            temp["category_sliders"].display_order = 3;
            temp["banner_sliders"].display_order = 4;
            temp["poster"].display_order = 5;
            temp["product_sliders"].display_order = 6;
            temp["category_subcategory_lists"].display_order = 7;
            temp["sticky_product"].display_order = 8;
            temp["sticky_category_sliders"].display_order = 9;
            temp["brand_sliders"].display_order = 10;
            break;
          }
        }
      }
      temp["header"].display_order = 1;
      temp["footer"].display_order = 11;
      temp["global_settings"].display_order = 12;
      return temp;
    });
  };

  function doReorder(event: CustomEvent) {
    let arr = objectToArray(websiteSettings);
    const itemMove = arr.splice(
      event.detail.from === 0
        ? event.detail.from
        : event.detail.from > 4
        ? event.detail.from - 2
        : event.detail.from - 1,
      1
    )[0];
    arr.splice(
      event.detail.to === 0
        ? event.detail.to
        : event.detail.to > 4
        ? event.detail.to - 2
        : event.detail.to - 1,
      0,
      itemMove
    );
    
    event.detail.complete();
      arrayToObject(arr);
      
  }

  async function saveSettings() {
    const [ok, response] = forApp
      ? await API.updateAppTheme({ ...websiteSettings })
      : await API.updateTheme({ ...websiteSettings });
    if (ok) {
      present("Data saved successfully!", 1000);
    }
  }

  return !loading && status === "fetched" && websiteSettings ? (
    <WebsiteSettingsContext.Provider
      value={{ websiteSettings, setWebsiteSettings, setUpdate, update }}
    >
      <IonContent style={{ height: "calc(100% - 56px)" }}>
        <IonAccordionGroup multiple={true}>
          {themeDetails["header"].getComponent()}
          <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
            {objectToArray(websiteSettings)?.map((componentName) =>
              themeDetails[componentName].getComponent()
            )}
          </IonReorderGroup>
          {themeDetails["footer"].getComponent()}
          {themeDetails["global_settings"].getComponent(forApp)}
        </IonAccordionGroup>
        <IonButton onClick={(e) => saveSettings()} expand="block" shape="round">
          Save
        </IonButton>
      </IonContent>
    </WebsiteSettingsContext.Provider>
  ) : null;
};

export default SideMenu;
