import React, { useState } from "react";
import {
  IonCard,
  IonItem,
  IonInput,
  IonText,
  IonButton,
  IonIcon,
  IonLabel,
  useIonToast,
} from "@ionic/react";
import { closeOutline as closeIcon } from "ionicons/icons";
import { useCustomer, useCart } from "../../utils/contexts";
import useAPI from "../../utils/api";
import { advEmailValidation } from '../../utils/validations';

const CustomerDetails = () => {
  const { customer, setCustomer } = useCustomer();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const { setMyCartData } = useCart();
  const API = useAPI();
  const [present] = useIonToast();

  const [validation, setValidation] = useState({
    emailValidation: '',
    nameValidation: '',
})

  const getCartData = async (userId) => {
    try {
      const [cartOk, cartResponse] = await API.getCart(userId);
      if (cartOk && cartResponse) {
        setMyCartData(cartResponse.data);
      }
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  const saveCustomerData = async () => {
    const res = await validate();

    if(res) {
        try {
        const [ok, response] = await API.createCustomer({ name, email });
        if (ok && response) {
            const { userId, user_phone } = response;
            setCustomer({ name, email, userId, user_phone });
            present("Customer added successfully!", 2000)
            await getCartData(userId);
        } else {
            if (response && response.error) {
            alert(response.error);
            }
        }
        } catch (error) {
        console.error("Error creating customer:", error);
        }
    }
  };
  
  const validate = async () => {
    let result = true
    const temp = { ...validation };

    // Validate the email
    if (!email.trim()) {
        temp.emailValidation = "Email is required"
        result = false;
    } else {
        if (advEmailValidation(email)) {
            temp.emailValidation = "Invalid email"
            result = false;
        } else {
            temp.emailValidation = ""
        }
    }
    // Validate the name
    if (!name.trim()) {
        temp.nameValidation = "Name is required"
        result = false;
    } else {
        temp.nameValidation = ""
    }
    await setValidation(temp);
    return result;
}

  const fetchCustomerName = async (email) => {
    try {
      const [ok, response] = await API.getCustomerByEmail(email);
      if (ok && response) {
        if(!response.message){
        const { userId, user_phone, user_name } = response;
        setName(user_name);
        setCustomer({ name:user_name, email, userId, user_phone });
        await getCartData(userId);
        }
      } else {
        setName("");
      }
    } catch (error) {
      console.error("Error fetching customer name:", error);
    }
  };

  return (
    <>
    {!customer && (
        <IonCard className="ion-padding">
          <IonItem lines="none">
            <IonText>Select/Add Customer</IonText>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Enter Customer Email</IonLabel>
            <br />
            <IonInput
              placeholder="Enter customer email"
              type="email"
              value={email}
              name="email"
              onIonChange={(e) => {setEmail(e.detail.value);        
                e.detail.value.trim() && !advEmailValidation(e.detail.value) && fetchCustomerName(e.detail.value);
                }}
            />
          </IonItem>
          {validation.emailValidation && <IonText color="danger">{validation.emailValidation}</IonText>}
          <IonItem>
            <IonLabel position="stacked">Enter Customer Name</IonLabel>
            <br />
            <IonInput
              placeholder="Enter customer name"
              type="text"
              value={name}
              name="name"
              onIonChange={(e) => 
                setName(e.detail.value)}
            />
          </IonItem>
          {validation.nameValidation && <IonText color="danger">{validation.nameValidation}</IonText>}

          <br />
          <IonButton shape="round" onClick={saveCustomerData}>Save</IonButton>
        </IonCard>
      )}
      {/* Display user details */}
      {customer && (
        <IonCard className="ion-padding">
          <IonItem lines="none">
            <IonText>Customer Details</IonText>
            <IonIcon
              slot="end"
              icon={closeIcon}
              style={{ cursor: "pointer" }}
              size="medium"
              onClick={() => {
                setCustomer(null);
                setName("");
                setEmail("");
                setMyCartData(null);
              }}
            />
          </IonItem>
          <IonItem lines="none">
            <div>
              <IonText>Email: {customer.email}</IonText>
              <br />
              <IonText>Name: {customer.name}</IonText>
            </div>
          </IonItem>
        </IonCard>
      )}
    </>
  );
};
export default CustomerDetails;
