import React from 'react';
import { IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonButton, IonIcon } from '@ionic/react';
import { arrowBack as arrowBackIcon, informationCircleOutline } from 'ionicons/icons';

const Header = (props) => {
  return (
    <IonHeader style={{ height: '68px' }}>
      <IonToolbar style={{ display: 'flex', alignItems: 'center' }}>
        <IonButtons slot="start" style={{ display: 'flex', alignItems: 'center' }}>
          <IonBackButton defaultHref={props.defaultHref} icon={arrowBackIcon} />
        </IonButtons>
        <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IonTitle>{props.title}</IonTitle>
          {props.showInfoButton && (
            <IonButton fill='clear' color='secondary' onClick={props.onInfoClick}>
              <IonIcon icon={informationCircleOutline} slot="icon-only" />
            </IonButton>
          )}
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
