import {
  IonButton,
  IonCol,
  IonGrid,
  IonProgressBar,
  IonRow
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

interface Props{
  name: string
}

const ProfileCompletion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser } = useSelector((state: any) => state.currentUser);
  const { profileCompetionLoading, profileCompletion, profileCompetionError } = useSelector((state: any) => state.profileCompletionData);
  
  const map={'SCREEN.NONE':'/userdashboard',
        'SCREEN.SETTINGS.PAYMENT':'/settings/payments',
        'SCREEN.BANNERS':'/appearance/ik-web-settings',
        'SCREEN.COUPONS':'/coupons',
        'SCREEN.CATALOG':'/catalogue/categories',
        'SCREEN.SETTINGS.LOCALISATION':'/settings/localization',
        'SCREEN.SETTINGS.BASICINFO':'/settings/basicinfo'}

  return (
    <>
      {profileCompletion?.touch_target && map[profileCompletion?.touch_target]!=="" ? <IonGrid className='ion-no-padding ion-no-margin'  id="profile">
        <IonRow> 
          {/* <p style={{marginTop: '8px', marginBottom: '8px'}}>Store <br/> Setup&nbsp;&nbsp;</p> */}
            <IonCol className='ion-margin-top' size='8' style={{ marginTop:"5px" }}>
              <div style={{ position: "relative" }}>
              <IonProgressBar color={"success"} style={{ padding: '8px',
            borderRadius: '15px',
            border:"1px solid ",
            
          }} className='ion-margin-top' value={(profileCompletion?.total_percentage/100)}>

          </IonProgressBar>
          <div 
          style={{ 
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-30%, -50%)",
            fontWeight: "bold",
            color:profileCompletion?.total_percentage>50?"#fff":"",
         
           }}
          >
          {profileCompletion?.total_percentage ? <p>&nbsp;&nbsp;{`${(profileCompletion?.total_percentage.toFixed(0))}%`}</p>:""}
          </div>
          </div>
          {/* textAlign:'right',paddingTop:'7px' */}
          <span style={{marginTop: '8px', marginBottom: '8px'}}>           &nbsp;&nbsp;</span>
              <p className='ion-no-margin' style={{textAlign:'right',paddingTop:'-20px',marginTop:"-25px"}}> {profileCompletion?.touch_target && map[profileCompletion?.touch_target]!==""?<IonButton size="small" shape="round" color={"success"} className='btn' href={`${map[profileCompletion?.touch_target]}`}>Continue Store Setup</IonButton>:''}</p>
            </IonCol>  
           
        </IonRow>
      </IonGrid>
  : ""}
    </>


  )
}

export default ProfileCompletion
