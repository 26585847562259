import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  useIonToast,
} from "@ionic/react";
import { useState } from "react";
import Header from "../../components/dashboard/Header";
import useAPI from "../../utils/api";
import useFetch from "../../utils/useFetch";
import { checkmark, checkmarkDoneCircle, reader, readerOutline } from "ionicons/icons";

const NotificationList = () => {
  let [updateNotifications, setUpdateNotifications] = useState(false);
  const { loading, data, status } = useFetch(
    "notifications",
    updateNotifications
  );
  const API = useAPI();
  let localDate;
  const [present] = useIonToast();
  async function readNotification(id) {
    const [ok, response] = await API.readNotification({
      notification_id: id,
    });

    if (ok) {
      setUpdateNotifications(!updateNotifications);
    } else {
      present(response.message, 1500);
    }
  }
  
  return (
    <IonPage>
      <div className="ion-page" id="main-content">
        <Header name="Notification" />
        <IonContent>
          <IonList>
            {!loading && status === "fetched" && data?.data?.length !== 0 ? (
              data?.data?.map((notification, index) => (
                <IonItem key={index}>
                  <IonLabel>
                    {notification.viewed === 0 ? (
                      <>
                      <div style={{display:"flex",justifyContent:"space-between",}}>
                      <h2
                        style={{ fontWeight: "bold"}}
                      >
                        {notification.message}</h2>
                         <IonIcon style={{cursor:"pointer",fontSize:"1.5rem",fontWeight: "bold"}} slot="end" icon={readerOutline} onClick={() => readNotification(notification.id)}></IonIcon> 
                         </div>
                        <span style={{ fontSize: "0.8rem",fontWeight: "bold"}}>
                          {
                            (localDate = new Date(notification.created_at)
                              .toLocaleString()
                              .replace(/T/, " ")
                              .replace(/\..+/, "")).replace(/:\d{2}\s/, " ")
                          }
                        </span>
                        </>
                    ) : (
                      <h2 >
                        {notification.message} <br />
                        <span style={{ fontSize: "0.8rem" }}>
                          {
                            (localDate = new Date(notification.created_at)
                              .toLocaleString()
                              .replace(/T/, " ")
                              .replace(/\..+/, "")).replace(/:\d{2}\s/, " ")
                          }
                        </span>
                      </h2>
                    )}
                  </IonLabel>
                </IonItem>
              ))
            ) : data?.data?.length == 0 ? (
              <IonItem>
                <IonLabel>
                  <h2>No Notification is there </h2>
                </IonLabel>
              </IonItem>
            ) : (
              <IonItem>
                <IonLabel>
                  <h2>Loading..</h2>
                </IonLabel>
              </IonItem>
            )}
          </IonList>
        </IonContent>
      </div>
    </IonPage>
  );
};

export default NotificationList;
