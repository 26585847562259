import { IonAlert, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonPage, IonText, IonTitle, IonToggle, IonToolbar, useIonToast } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { useAddon } from '../../../utils/contexts';
import {
    arrowBack as arrowBackIcon,
    informationCircleOutline
} from 'ionicons/icons';
import useAPI from '../../../utils/api';
import { indexedValue } from '../AddonsRoutes';
import useFetch from '../../../utils/useFetch';
import { useHistory } from 'react-router';
import FeatHeader from '../../../components/dashboard/FeatHeader';


const ScheduledOrder = () => {
    const addons:any = useAddon();
    const [present] = useIonToast();
    const{ loading,data}=useFetch("timeslot");
    const[timeslot,setTimeslot]=useState([]);
    const [showAlertForSingle, setShowAlertForSingle] = useState(false);
    const history = useHistory();
    const API = useAPI();
    const [statusCheck, setStatusCheck] = useState(Array.isArray(addons[indexedValue.scheduledOrder].data) ? false : addons[indexedValue.scheduledOrder].data?.status === 'active');
    const handleDelete = () => {
        
        window.location.href = 'https://web.intelikart.com/settings/config-time-slot';
    };
    const onSubmitHandler = async(e)=>{

        e.preventDefault();
        if(timeslot.length>0){   
            const [ok, response] = Array.isArray(addons[indexedValue.scheduledOrder].data) ? 
                await API.PostScheduledOrderAddon({
                    "status": statusCheck ? "active" : "inactive"
                })
                :
                await API.PostScheduledOrderAddon({
                    "status": statusCheck ? "active" : "inactive",
                    id: addons[indexedValue.scheduledOrder].data.id
                })
                if(response.statusCode === 400){
                    setStatusCheck(!statusCheck);
                    present(response.message, 1500);
                }
                if(ok) {
                    present("Data saved successfully!", 1500);
                    addons[indexedValue.scheduledOrder].updateMethod(!addons[indexedValue.scheduledOrder].update);
                }
            }
            else{
                
                setShowAlertForSingle(true);

            }
    }
    useEffect(()=>{
        setTimeslot(data.time_slot);
     },[data])

    const openLinkInNewTab = () => {
        window.open(`https://intelikart.tawk.help/article/schedule-order`, '_blank');
    };

  return (
    <IonPage>
         
          <FeatHeader title='Configure Scheduled Order' showInfoButton={ true} onInfoClick={openLinkInNewTab} defaultHref='/add-ons'/>
            <IonContent className="ion-padding">
                <form onSubmit={onSubmitHandler}>
                    <IonItem lines="none">
                        <IonText><h5>Enable Scheduled Order</h5></IonText>
                        <IonToggle slot="end" checked={statusCheck} onIonChange={e => setStatusCheck(e.detail.checked)} />
                    </IonItem>
                    <br/>
                    <div className="ion-text-center">
                        <IonButton type="submit">Save</IonButton>
                    </div>
                </form>
            </IonContent>
            <IonAlert
                isOpen={showAlertForSingle}
                onDidDismiss={() => setShowAlertForSingle(false)}
                header={"No Time Slot Available"}
                message={`There are no available time slots. Please configure time slots first.`}
                buttons={[
                    {
                        text: "CANCEL",
                        role: "cancel",
                        handler: () => setShowAlertForSingle(false)
                    },
                    {
                        text: "CONFIGURE TIME SLOTS",
                        handler: handleDelete,
                    },
                ]}
            />

        </IonPage>
  )
}

export default ScheduledOrder