import { IonAccordion, IonCard, IonItem, IonLabel } from '@ionic/react';
import { useWebsiteSettings } from '../../../../../utils/contexts';
import AppBrandingColors from './AppBrandingColours';
import BrandingColors from './BrandingColors';

const WebsiteStyling = (props) => {
    const settings:any = useWebsiteSettings();
    return (
        <IonCard>
            <IonAccordion>
                <IonItem slot="header" color='light'>
                <IonLabel>
                    {props?.forApp ? "App Colors" : "Website Styles"}
                </IonLabel>
                </IonItem>
                <div slot="content">
                    {Object.keys(settings?.websiteSettings?.global_settings).map((name) => {
                        switch (name) {
                            case "branding_colors":
                                return props?.forApp ? <AppBrandingColors key="app_branding_colors" /> : <BrandingColors key="website_branding_colors" />;
                        
                        }
                    })}
                </div>
            </IonAccordion>
        </IonCard>
    )
}

export default WebsiteStyling