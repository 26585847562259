import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonText, IonTitle, IonToggle, IonToolbar, useIonToast } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import {
    arrowBack as arrowBackIcon
} from 'ionicons/icons';
import { useAddon } from '../../../utils/contexts';
import useAPI from '../../../utils/api';
import { indexedValue } from '../AddonsRoutes';
import FeatHeader from '../../../components/dashboard/FeatHeader';


const OrderForm = () => {
    const addons: any = useAddon();
    const [present] = useIonToast();
    const API = useAPI();
    const [manualCheck, setManualCheck] = useState(Array.isArray(addons[indexedValue.order].data) ? false : addons[indexedValue.order].data?.status === 'active');

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        const [ok, response] = Array.isArray(addons[indexedValue.order].data) ?
            await API.createOrderAddon({
                "status": manualCheck ? "active" : "inactive"
            })
            :
            await API.updateOrderAddon({
                "status": manualCheck ? "active" : "inactive",
                id: addons[indexedValue.order].data.id
            })
        if (response.statusCode === 400) {
            setManualCheck(!manualCheck);
            present(response.message, 1500);
        }
        if (ok) {
            present("Data saved successfully!", 1500);
            addons[indexedValue.order].updateMethod(!addons[indexedValue.order].update);
        }
    }

    return (<IonPage>

        <FeatHeader title='Configure Order Management' defaultHref='/add-ons' showInfoButton={false} />
        <IonContent className="ion-padding">
            <form onSubmit={onSubmitHandler}>
                <IonItem lines="none">
                    <IonText><h5>Enable manual order management</h5></IonText>
                    <IonToggle slot="end" checked={manualCheck} onIonChange={e => setManualCheck(e.detail.checked)} />
                </IonItem>
                <br />
                <div className="ion-text-center">
                    <IonButton type="submit">Save</IonButton>
                </div>
            </form>
        </IonContent>
    </IonPage>
    )
}

export default OrderForm