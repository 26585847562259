import {
    IonBadge,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonMenuButton,
    IonPopover,
    IonRow,
    IonText,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import {
    add,
    logoYoutube,
    notificationsOutline as notificationIcon,
    arrowUpCircleOutline as upgrade,
    informationCircleOutline as infoIcon ,
} from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { authenticateUserWhenLogout } from "../../actions/authActions";
import {
    getHomeScreenData,
    getHomeScreenDataWithDays,
} from "../../actions/dashboardActions";
import { getAccessToken } from '../../services/auth/userLocalStorage';
import { IsStaffContext, UserContext } from '../../utils/contexts';
import ProfileCompletion from './ProfileCompletion';

interface Props {
    name: string,
    docs?: boolean,
    docslink?: string,
}
const Title = (props: Props) => {
    const { isStaff }: any = useContext(IsStaffContext);
    const user: any = useContext(UserContext);
    const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({
        open: false,
        event: undefined,
    });

    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState(1000000);
    const { homeScreenLoading, homeScreen, homeScreenError } = useSelector((state: any) => state.homeScreenData);
    const {
        homeScreenWithDaysLoading,
        homeScreenWithDays,
        homeScreenWithDaysError,
    } = useSelector((state: any) => state.homeScreenDataWithDays);
    let history = useHistory();

    const handleNotificationClick = () => {
        let currentPath = history.location.pathname;
        if (currentPath === '/notification') {
            history.goBack();
        } else {
            history.push('/notification')
        }
    };

    const initialValues = async () => {
        const accessToken = await getAccessToken();
        const storeId =
            user ? user.store.subdomain : null;
        if (storeId) {
            console.log(isStaff);
            if (accessToken) {
                if (!isStaff) {
                    await dispatch(getHomeScreenData(accessToken, storeId));
                    await dispatch(
                        getHomeScreenDataWithDays(accessToken, storeId, selectedValue)
                    );
                }
            } else {
                await dispatch(authenticateUserWhenLogout());
                await localStorage.clear();
                history.push("/");
            }
        }
    };
    useEffect(() => {
        initialValues();
    }, [user]);

    const openLinkInNewTab = () => {
        window.open(`${props.docslink}`, '_blank');
    };

    return (
        <IonHeader style={{ height: '68px' }}>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton></IonMenuButton>
                </IonButtons>
                <MobileView>
                    <IonItem className='ion-no-margin ' lines='none'>
                        <IonText slot='start' className='ion-no-margin' >
                            <h5>
                                {props.name}
                            </h5>

                        </IonText>
                    </IonItem>
                </MobileView>
                <BrowserView>
                    <IonGrid className='ion-no-margin ion-no-padding'>
                        <IonRow>
                            <IonCol size={isMobile ? "5" : "3"}>
                                <IonItem className='ion-no-margin ' lines='none'>
                                    <IonText slot='start' className='ion-no-margin' >
                                        <h5>
                                            
                                            {props.name}
                                        </h5>
                                        
                                    </IonText>
                                </IonItem>
                            </IonCol>
                            {isMobile ? "" :
                                <IonCol size='9'>
                                    <ProfileCompletion ></ProfileCompletion>
                                </IonCol>
                            }
                        </IonRow>
                    </IonGrid>
                </BrowserView>
                {props.name === "Catalogue" ? <>
                    <IonButtons slot="end">
                        <IonButton size="small" target="_blank" href="https://www.youtube.com/watch?v=dkOvPjF41YA" color='medium'>
                            <IonIcon slot="start" icon={logoYoutube}></IonIcon>
                            How to Use</IonButton>
                        <span style={{ marginRight: '4px' }}>
                            {props.docs && (
                                <IonButtons slot="end">
                                    <IonButton fill='clear' color='secondary' onClick={openLinkInNewTab}>
                                        <IonIcon icon={infoIcon} slot="icon-only">

                                        </IonIcon>
                                    </IonButton>
                                </IonButtons>
                            )}
                        </span>
                    </IonButtons>
                </> : props.name === "Products" ?
                    <IonButtons slot="end">
                        <MobileView>
                            <Link to={`/catalogue/products/addproductbulk`} style={{ textDecoration: 'none' }}><IonButton shape="round"><IonIcon icon={add}></IonIcon> Bulk upload</IonButton></Link>
                        </MobileView>
                            <BrowserView style={{display:'flex',justifyContent:'center',gap:'5px',alignItems:'center'}}>
                                <Link to={`/catalogue/products/addproductbulk`} style={{ textDecoration: 'none' }}><IonButton shape="round"><IonIcon icon={add}></IonIcon> Bulk upload products</IonButton></Link>
                                <span style={{ marginRight: '4px' }}>
                                    {props.docs && (
                                        <IonButtons slot="end">
                                            <IonButton fill='clear' color='secondary' onClick={openLinkInNewTab}>
                                                <IonIcon icon={infoIcon} slot="icon-only">

                                                </IonIcon>
                                            </IonButton>
                                        </IonButtons>
                                    )}
                                </span>
                        </BrowserView>
                    </IonButtons>
                    : <>
                        { homeScreenLoading === false && homeScreen && homeScreen.data && homeScreen.subscription.text === 'FREE' ? 
                            !(isPlatform('hybrid') && isPlatform('android')) ? <IonButton slot='end' shape="round" color='danger' routerLink='/subscriptions'>
                                <IonIcon style={{ fontSize: "24px" }} slot='start' icon={upgrade}></IonIcon>  Upgrade
                            </IonButton> : <IonButton size='small' slot='end' className='ion-text-center' shape="round" color='danger' routerLink='/subscriptions'>
                                <IonIcon style={{ fontSize: "24px" }}  icon={upgrade}></IonIcon>
                            </IonButton> : <></>}
                        <IonButtons slot="end">
                            <IonButton onClick={handleNotificationClick} >
                                <IonBadge
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        right: '0',
                                        transform: 'translate(28%, -40%)',
                                        zIndex: '3',
                                        borderRadius: "50%",
                                        width: "22px",
                                        textAlign: "center"
                                    }}
                                    color="danger"
                                >
                                    {homeScreenWithDaysLoading === false &&
                                        homeScreenWithDays &&
                                        homeScreenWithDays.data && homeScreenWithDays.data.notification_count !== 0
                                        ? homeScreenWithDays.data.notification_count
                                        : null}
                                </IonBadge>
                                <IonIcon slot="icon-only" icon={notificationIcon} />
                                </IonButton>
                               
                            </IonButtons>
                            
                            {props.docs && (
                                <IonButtons slot="end">
                                    <IonButton fill='clear' color='secondary' onClick={openLinkInNewTab}>
                                        <IonIcon icon={infoIcon} slot="icon-only">

                                        </IonIcon>
                                    </IonButton>
                                </IonButtons>
                            )}
                    </>}
            </IonToolbar>
        </IonHeader>
    )
}

export default Title;
