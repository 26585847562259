import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonApp, IonText, IonItem, IonIcon, IonLoading, IonCard, IonButton, IonAlert, IonPage, IonBadge, IonCardContent, IonRow, IonGrid, IonCol, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, useIonAlert, IonLabel, IonInput, IonModal, useIonToast } from '@ionic/react';
import DashboardMenu from '../../components/dashboard/DashboardMenu';
import { getCoupons, deleteCoupon, clearErrors, getDeliveryOrder, deleteDeliverOrder, updateDeliveryOrder } from '../../actions/dashboardActions';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import { BrowserView, MobileView } from "react-device-detect";
import FeatHeader from '../../components/dashboard/FeatHeader';

import Header from '../../components/dashboard/Header'
import {
    arrowBack as arrowBackIcon,
    createSharp,
    trash as trashIcon,
    closeOutline as closeOutlineIcon,
    informationCircleOutline,
  } from "ionicons/icons";
import { Share } from '@capacitor/share';
import { authenticateUserWhenLogout } from '../../actions/authActions';
import { useHistory } from 'react-router';
import { ShareButtonMobile } from '../../components/ShareButtonMobile';
import { ShareButtonWeb } from '../../components/ShareButtonWeb';
import { type } from 'os';

const DeliveryOrderAll = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertUpdate, setShowAlertUpdate] = useState(false);
    const [alertData, setAlertData] = useState<any>();
    const dispatch = useDispatch();
    const history = useHistory();
    const [present] = useIonToast();
    const { zipcodesLoading, zipcodes, zipcodesError } = useSelector((state: any) => state.deliveryOrders);
    const { deletezipcodeLoading, deletezipcodeError } = useSelector((state: any) => state.deleteDeliveryOrderdata);
    const { updatezipcodeLoading, updatezipcodeError } = useSelector((state: any) => state.updateDeliveryOrderdata);
    const { currentUser } = useSelector((state: any) => state.currentUser);
    const { createDeliveryOrderMessageLoading, createDeliveryOrder, createDeliveryOrderMessageError } = useSelector((state: any) => state.createDeliveryOrder);
    const { homeScreenWithDaysLoading, homeScreenWithDays, homeScreenWithDaysError } = useSelector((state: any) => state.homeScreenDataWithDays);
    const [presentAlert] = useIonAlert();
    const [updateValue,setUpdateValue]=useState('');
    useEffect(() => {
        if (currentUser && currentUser.data) {
            dispatch(getDeliveryOrder(getAccessToken(), currentUser.data.store.subdomain));
        }
    }, [currentUser]);
    console.log("current",currentUser)

    useEffect(() => {
        if (currentUser && currentUser.data && createDeliveryOrderMessageLoading === false) {
            dispatch(getDeliveryOrder(getAccessToken(), currentUser.data.store.subdomain));
        }
    }, [createDeliveryOrderMessageLoading]);

    useEffect(() => {
        if (currentUser && currentUser.data && deletezipcodeLoading === false) {
            dispatch(getDeliveryOrder(getAccessToken(), currentUser.data.store.subdomain));
        }
    }, [deletezipcodeLoading]);
    useEffect(() => {
        if (currentUser && currentUser.data && updatezipcodeLoading === false) {
            dispatch(getDeliveryOrder(getAccessToken(), currentUser.data.store.subdomain));
        }
    }, [updatezipcodeLoading]);
    const handleOpenModal = () => {
        setShowAlertUpdate(true);
      };
      const handleCloseModal =async() => {
        setShowAlertUpdate(false);
 
    }; 
    const updateValueData=async()=>{
        if (/^\d{6}$/.test(updateValue)){
            dispatch(
                updateDeliveryOrder(getAccessToken(), currentUser.data.store.subdomain, {
                  updateValue,alertData
                })
              );
              present({
                message: "Saved successfully!",
                duration: 2000,
                position: "bottom",
              });
    
            setShowAlertUpdate(false);
        }
        else{
            present({
                message: "Please enter valid 6-digit numbers in all fields.",
                duration: 2000,
                position: "bottom",
              });
    
        }
    }
    const deleteDelivery = async (value: any) => {
        if (currentUser && currentUser.data && value) {
            if (getAccessToken()) {
                await dispatch(deleteDeliverOrder(getAccessToken(), currentUser.data.store.subdomain, {
                    id: value.id
                }));
            } else {
                await dispatch(authenticateUserWhenLogout())
                await localStorage.clear();
                history.push('/')
            }
        }
    }

   const  updateDelivery = async (value:any,zipcode:any) =>{

   }
    const openLinkInNewTab = () => {
        window.open(`https://intelikart.tawk.help/article/pin-code`, '_blank');
    };
   
    return (
        <IonPage>
            {console.log("zipcodes",zipcodes)}
            <div className="ion-page" id="main-content">
               
                <FeatHeader
                    title='Delivery Order'
                    defaultHref='/settings'
                    showInfoButton={true}
                    onInfoClick={ openLinkInNewTab} />
                <IonContent className="ion-padding">
                    <IonText className="ion-text-center ion-margin-bottom">
                        <h5 className="ion-no-margin"><strong>Manage Delivery area for your app and website</strong></h5>
                    
                    </IonText>
                    {
                        zipcodesLoading === false   && zipcodes && zipcodes.length !== 0 ?
                            zipcodes.map((value: any) => {
                                return (
                                    <IonCard key={value.id}>
                                        <IonCardContent>
                                            <IonGrid className="ion-no-padding ion-no-margin">
                                                <IonRow className="ion-no-padding ion-no-margin">
                                                    <IonCol className="ion-no-padding ion-no-margin"
                                                        size="10">
                                                        <IonText style={{ fontSize: "22px" }} color="dark">
                                                           Zip code {`${value.zipcode} `}
                                                             
                                                            <br />
                                                        </IonText>
                                                         
                                                    </IonCol>
                                                    <IonCol size="2" className="ion-no-padding ion-no-margin">
                                                        <MobileView>
                                                            <IonButton
                                                                fill="clear"
                                                                color="secondary"
                                                                onClick={() => { setAlertData(value); setShowAlert(true) }}>
                                                                <IonIcon
                                                                    slot="icon-only"
                                                                    icon={trashIcon}
                                                                ></IonIcon>
                                                            </IonButton>
                                                            <IonButton
                                                                fill="clear"
                                                                color="secondary"
                                                                onClick={() => { handleOpenModal();setAlertData(value);setUpdateValue(value.zipcode) }}>
                                                                <IonIcon
                                                                    slot="icon-only"
                                                                    icon={createSharp}
                                                                ></IonIcon>
                                                            </IonButton>
                                                        </MobileView>
                                                        <BrowserView>
                                                            {/* <ShareButtonWeb url="" text={`Use code ${value.code} to get ${value.value} ${value.method === "FLAT" ? "INR" : "%"} off on orders above ${value.min_order_value} INR from our ${homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.app && homeScreenWithDays.data.app.URL ? `${homeScreenWithDays.data.app.URL} app` : homeScreenWithDays.data && homeScreenWithDays.data.website && homeScreenWithDays.data.website.URL ? `${homeScreenWithDays.data.website.URL} website` : "app"}`} /> */}
                                                            <IonButton
                                                                fill="clear"
                                                                color="secondary"
                                                                onClick={() => {handleOpenModal();setAlertData(value);setUpdateValue(value.zipcode)  }}>
                                                                <IonIcon
                                                                    slot="icon-only"
                                                                    icon={createSharp}
                                                                ></IonIcon>
                                                            </IonButton>
                                                            <IonButton
                                                                fill="clear"
                                                                color="secondary"
                                                                onClick={() => { setAlertData(value); setShowAlert(true)  }}>
                                                                <IonIcon
                                                                    slot="icon-only"
                                                                    icon={trashIcon}
                                                                ></IonIcon>
                                                            </IonButton>
                                                        </BrowserView>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                );
                            }) :  <IonText className="ion-text-center ion-margin-bottom">
                            <h6 className="ion-margin "><strong>Delivering Across Every Corner</strong></h6>
                        
                        </IonText>
                    }
                    <div className="ion-text-center">
                        <IonButton shape="round" routerLink="/settings/delivery-order">Add Delivery Area</IonButton>
                    </div>
                    <IonAlert
                        isOpen={showAlert === true}
                        header={'Delete Delivery Area Zip Code'}
                        onDidDismiss={() => {setShowAlert(false); setShowAlertUpdate(false)}}
                       
                        message={`you want to delete your delivery area zip code ${alertData && alertData.zipcode ? alertData.zipcode : ""}`}
                        buttons={[
                            {
                                text: 'CANCEL',
                                role: 'cancel'
                            },
                            {
                                text: 'DELETE',
                                handler: () => { deleteDelivery(alertData); }
                            }
                        ]}
                    />
                       <IonModal isOpen={showAlertUpdate}  >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Update Delivery area</IonTitle>
            <IonText
                            slot="end"
                            color="primary"
                            onClick={(e) => {
                                setShowAlertUpdate(false);
                          
                            }}
                            style={{ cursor: "pointer" }}
                          >
                                <h1 className="ion-padding-end"><IonIcon
                                  slot="icon-only"
                                  size="large"
                                  icon={closeOutlineIcon}
                                ></IonIcon>
                                </h1>
                          </IonText>
          </IonToolbar> 
         
        </IonHeader>

        <IonContent className="ion-padding ion-justify-content-center">
          <IonItem>
            <IonLabel position="floating">Zip Code</IonLabel>
            <IonInput value={updateValue} onIonChange={(e)=>setUpdateValue(e.detail.value)}></IonInput>
          </IonItem>
           
              
          <div className="ion-text-center ion-padding-top">
       <IonButton  style={{ textAlign:"center" }} shape="round" onClick={updateValueData}>
            Update
          </IonButton>
          </div>
          
 
        </IonContent>
      </IonModal>
   

                    <IonLoading isOpen={zipcodesLoading === true || deletezipcodeLoading === true || updatezipcodeLoading === true} message="Please Wait"></IonLoading>
                </IonContent>
            </div>
        </IonPage>
    )
}

export default DeliveryOrderAll
