import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonLoading,
    IonPage,
    IonRow,
    IonText,
} from "@ionic/react";
import Header from "../../components/dashboard/Header";
import { BrowserView, MobileView } from "react-device-detect";
import { useSharedData, useUser } from "../../utils/contexts";
import { useEffect, useState } from "react";
import useFetch from "../../utils/useFetch";
import ReactPaginate from "react-paginate";
import useAPI from "../../utils/api";
import { useHistory } from "react-router";

const InventoryStocks = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    
    const [totalPages, setTotalPages] = useState(1);
    const [inventoryData, setInventoryData] = useState({});
    
    const sharedData: any = useSharedData();
    const currentUser: any = useUser();
    const API = useAPI();

    const { data: addonData } = useFetch(
        "inventoryAddon",
        sharedData.inventoryUpdate
    );

    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            if (location.pathname === "/catalogue/inventory" && currentUser) {
                setInventoryData({});
                setCurrentPage(1);
                setTotalPages(1);
                fetchData(1);
            }
        });

        return () => {
            unlisten();
        };
    }, [history, currentUser]);

      const fetchData = async (page) => {
        if (!inventoryData[page] || inventoryData[page].length === 0) {
            setLoading(true);
            try {
                const [ok, response] = await API.inventoryStocks(page);
                if (ok) {
                    const inventory = {...inventoryData}
                    inventory[page] = response
                    setInventoryData(inventory)
                    setTotalPages(response.meta.pagination.total_pages);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (currentUser) {
            fetchData(currentPage);
        }
    }, [currentPage, currentUser]);

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber + 1);
    };

    return (
        <IonPage>
            <Header name="Inventory Stocks" />
            <IonContent className="ion-padding">
                {addonData?.data?.status === "active" ? (
                    <IonGrid>
                        {inventoryData[currentPage] &&
                        inventoryData[currentPage].data &&
                        inventoryData[currentPage].data.length > 0 ? (
                            <>
                                <IonRow>
                                    <IonCol sizeSm="2" size-md="2" sizeXs="2">
                                        <IonImg></IonImg>
                                    </IonCol>
                                    <IonCol sizeSm="4" size-md="4" sizeXs="4">
                                        <IonText>
                                            <b>Name</b>
                                        </IonText>
                                    </IonCol>
                                    <IonCol sizeSm="2" size-md="2" sizeXs="0">
                                        <BrowserView>
                                            <IonText>
                                                <b>MRP</b>
                                            </IonText>
                                        </BrowserView>
                                    </IonCol>
                                    <IonCol sizeSm="2" size-md="2" sizeXs="2.4">
                                        <IonText>
                                            <BrowserView>
                                                <b>Selling Price</b>
                                            </BrowserView>
                                            <MobileView>
                                                <b>Price</b>
                                            </MobileView>
                                        </IonText>
                                    </IonCol>
                                    <IonCol sizeSm="2" size-md="2" sizeXs="2.9">
                                        <IonText>
                                            <BrowserView>
                                                <b>Available Stocks</b>
                                            </BrowserView>
                                            <MobileView>
                                                <b>Stocks</b>
                                            </MobileView>
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                                {inventoryData[currentPage].data.map((item) => (
                                    <IonRow key={item.id}>
                                        <IonCol
                                            sizeSm="2"
                                            size-md="2"
                                            sizeXs="2"
                                        >
                                            <IonImg
                                                src={item.image}
                                                alt={`Image ${item.id}`}
                                                style={{
                                                    width: "75%",
                                                    height: "75%",
                                                    maxHeight: "70px",
                                                }}
                                            />
                                        </IonCol>
                                        <IonCol
                                            sizeSm="4"
                                            size-md="4"
                                            sizeXs="4"
                                        >
                                            <IonText>
                                                {item.product_name}
                                            </IonText>
                                            <br />
                                            <IonText
                                                style={{ fontSize: "small" }}
                                            >
                                                <b>{item?.variant_name}</b>
                                            </IonText>
                                        </IonCol>
                                        <IonCol sizeSm="2" size-md="2">
                                            <BrowserView>
                                                <IonText>
                                                    {item.display_mrp ?? "-"}
                                                </IonText>
                                            </BrowserView>
                                        </IonCol>
                                        <IonCol
                                            sizeSm="2"
                                            size-md="2"
                                            sizeXs="2.4"
                                        >
                                            <IonText>{item.mrp}</IonText>
                                        </IonCol>
                                        <IonCol
                                            sizeSm="2"
                                            size-md="2"
                                            sizeXs="2.9"
                                        >
                                            <IonText>
                                                {item.available_quantity ?? "-"}
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                ))}
                            </>
                        ) : (
                            <IonRow>
                                <IonCol className="ion-text-center">
                                    No products available
                                </IonCol>
                            </IonRow>
                        )}
                        {totalPages > 1 && (
                            <IonRow className="ion-justify-content-center">
                                <IonCol>
                                    <IonText
                                        color="primary"
                                        className="ion-text-center"
                                    >
                                        {inventoryData[currentPage] &&
                                        inventoryData[currentPage].meta &&
                                        inventoryData[currentPage].meta
                                            .pagination ? (
                                            <ReactPaginate
                                                previousLabel="Prev"
                                                nextLabel="Next"
                                                pageCount={totalPages}
                                                onPageChange={(data) =>
                                                    handlePageChange(
                                                        data.selected
                                                    )
                                                }
                                                containerClassName="pagination-container"
                                                activeClassName="pagination-button"
                                                forcePage={currentPage - 1}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </IonText>
                                </IonCol>
                            </IonRow>
                        )}
                    </IonGrid>
                ) : (
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonText><b>Your Product Inventory is not Configured</b></IonText><br/>
                            <IonButton shape="round" style={{marginTop:'10px'}}
                                onClick={() => history.push("/add-ons/inventory-configure")}
                                >
                                Configure Product Inventory
                            </IonButton>
                        </IonCol>
                    </IonRow>
                )}
                <IonLoading isOpen={loading} message="Please Wait" />
            </IonContent>
        </IonPage>
    );
};

export default InventoryStocks;
