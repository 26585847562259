import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
    IonContent,
    IonPage,
    IonItem,
    IonLabel,
    IonButton,
    IonInput,
    IonText,
    IonSelectOption,
    IonSelect,
    IonLoading,
    IonIcon,
    IonCol,
    IonGrid,
    IonRow,
    IonModal,
    IonImg,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    useIonViewDidEnter,
    IonList,
    IonRadio,
    IonRadioGroup,
} from '@ionic/react';
import LogoImage from '../components/auth/LogoImage';
import { getBusinessTypeList, onboardingUser, clearErrors } from '../actions/authActions';
import { getAccessToken } from '../services/auth/userLocalStorage';
import {
    caretDown as caretDownIcon,
    checkmarkSharp as checkmarkSharpIcon,
    closeOutline,
    helpCircleOutline,
    search as serachIcon
} from 'ionicons/icons';
import LoginFooter from '../components/auth/LoginFooter';
import { isDesktop } from 'react-device-detect';
import countryCodes from 'country-codes-list';

interface businesstypeItem {
    id: number,
    name: string,
    icon: string,
    description: string,
}

function getCookie(name) {
    let dc = document.cookie;
    let prefix = name + "=";
    let begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
            end = dc.length;
        }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
}

const countryList = [
    "India",
    "United Arab Emirates"
]

const GetBusinessDetails = () => {
    const [showModal, setShowModal] = useState(false);
    const [showCountryModal, setShowCountryModal] = useState(false);
    let [phone, setPhone] = useState<undefined | string | null>('');
    const [storeType, setStoreType] = useState<any>();
    const [name, setName] = useState<undefined | string | null>('');
    const [referralCode, setReferralCode] = useState<any>();
    const [businessURL, setBusinessURL] = useState<undefined | string | null>('');
    const [cookieValue, setCookieValue] = useState<any>();
    const accessToken = getAccessToken();
    const [country, setCountry] = useState<any>("India");
    const [mobileCode, setMobileCode] = useState('+91');


    const [validation, setValidation] = useState({
        phoneValidation: '',
        nameValidation: '',
        businessTypeValidation: '',
        businessURLValidation: '',
        referralCodeValidation: ''
    })

    //create account loading
    const [loading, setLoading] = useState<undefined | boolean>(false);

    const history = useHistory();
    const dispatch = useDispatch();
    const { businessTypeListLoading, businessTypeList, businessTypeListError } = useSelector((state: any) => state.businessTypeList);
    const { currentUser } = useSelector((state: any) => state.currentUser);
    const { userOnboardingLoading, userOnboardingMessage, userOnboardingError } = useSelector((state: any) => state.userOnboarding);

    useEffect(() => {
        (async function () {
            await dispatch(getBusinessTypeList());
        })();
        setCookieValue(getCookie("_ikp"));
    }, []);

    useEffect(() => {
        if (cookieValue) {
            if (cookieValue.length == 5) {
                setReferralCode(cookieValue);
            }
        }
    }, [cookieValue]);

    useEffect(() => {
        if (userOnboardingLoading === false) {
            if (userOnboardingMessage && userOnboardingMessage.data && userOnboardingMessage.data.id && userOnboardingMessage.data.subdomain) {
                history.push('/userdashboard');
            }
            if (userOnboardingError) {
                console.log(userOnboardingError);
            }
        }
    }, [userOnboardingLoading, userOnboardingMessage, userOnboardingError]);

    const validate = async () => {
        let result = true
        let temp = { ...validation }

        //phone validation
        if (!phone) {
            temp.phoneValidation = "Mobile number is required"
            result = false
        } else {
            phone = phone.trim();

            // Check if there are any spaces inside the string
            if (/\s/.test(phone)) {
                temp.phoneValidation = "Should not Contain space in between"
                result = false; // Contains space inside
            } else if (!/^\d+$/.test(phone)) {
                temp.phoneValidation = "Contains non-digit characters"
                result = false; // Contains non-digit characters
            }else {
                temp.phoneValidation = ""
                result = true; // Only contains digits and has a length of 10
            }
          }
        

        // Referral Code Validation
        if (referralCode) {
            if (referralCode.length !== 5 || /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(name)) {
                temp.referralCodeValidation = "Referral code is invalid"
                result = false;
            } else {
                temp.referralCodeValidation = ""
            }
        }

        // Store id validation
        if (!storeType || !storeType.id) {
            temp.businessTypeValidation = "Business type is required"
            result = false;
        } else {
            temp.businessTypeValidation = ""
        }

        // Name validation
        if (!name) {
            temp.nameValidation = "Business name is required"
            result = false;
        } else {
            if (!/[a-zA-Z]/.test(name)) {
                temp.nameValidation = "Business name can't contain only numbers and special characters."
                result = false;
            }
            else
                temp.nameValidation = ""
        }

        // Business URL validation
        if (!businessURL) {
            temp.businessURLValidation = "Business URL is required"
            result = false;
        } else {
            if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(businessURL)) {
                temp.businessURLValidation = "Business URL can't contain special characters"
                result = false;
            }
            else {
                const response = await fetch(`https://api2.intelikart.in/subdomain-availability/${businessURL}.intelikart.com`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                const message = await response.json();
                let isAvailable = message.result === "available" ? true : false;
                if (isAvailable === false) {
                    temp.businessURLValidation = "That's already taken, please try something else"
                    result = false;
                } else
                    temp.businessURLValidation = ""
            }
        }
        await setValidation(temp);
        return result;
    }

    const submitHandler = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const res = await validate();
        if (res) {
            dispatch(onboardingUser(accessToken, currentUser.data.store.subdomain, {
                phone: mobileCode ? `${mobileCode}${phone}` : `${phone}`,
                storetype_id: storeType.id,
                subdomain: `${businessURL}.intelikart.com`,
                name: `${name}`,
                referral_code: referralCode ? `${referralCode}` : null,
                country: `${country}`
            }));
        }
        setLoading(false);
    }

    const firstNameInputRef = useRef<HTMLIonInputElement>(null);
    useIonViewDidEnter(() => {
        firstNameInputRef.current?.setFocus();
    });

    let classOfGrid = "";
    if (isDesktop) {
        classOfGrid = "center-grid";
    }

    const countryCodeArr = countryCodes.all().map(obj => `[${obj.countryCode}] ${obj.countryNameEn}: +${obj.countryCallingCode}`);

    const confirm = (countryName) => {
        setCountry(countryName);

        const countryStr = countryCodeArr.find(str => str.includes(countryName));

        if (countryStr) {
            const mobileCodeMatch = countryStr.match(/\+(\d+)/);

            if (mobileCodeMatch) {
                setMobileCode(mobileCodeMatch[0])
            }
        }
        setShowCountryModal(false);
    }

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <div className={classOfGrid}>
                    <IonTitle className="ion-text-center">
                        <h5>Enter business details</h5>
                    </IonTitle>
                    <form className="ion-padding" onSubmit={submitHandler}>
                        {/* business name */}
                        <IonItem className={`${!validation.nameValidation ? "ion-margin-bottom" : ""}`} lines="none">
                            <IonLabel position="stacked">Business Name</IonLabel>
                            <IonInput ref={firstNameInputRef} placeholder="App name / Business name" type="text" value={name} name="name" onIonChange={(e) => { setName(e.detail.value); setBusinessURL(e.detail.value ? (e.detail.value).replace(/ /g, '') : e.detail.value) }} />
                        </IonItem>
                        <IonText color="danger" className={`${validation.nameValidation ? "" : "ion-hide"}`}>
                            <h5 style={{ fontSize: 12 + 'px'}} className='ion-no-margin ion-margin-horizontal'>{validation.nameValidation}</h5>
                        </IonText>

                        {/* business url */}
                        <IonItem className={`${!validation.businessURLValidation ? "ion-margin-bottom" : ""}`} lines="none">
                            <IonLabel position="stacked">Choose a URL for store</IonLabel>
                            <div style={{ width: 100 + '%' }}>
                                <IonGrid className="ion-no-padding">
                                    <IonRow className="ion-align-items-center">
                                        <IonCol className="ion-no-padding" size="7">
                                            <IonInput type="text" value={businessURL} placeholder="URL" name="businessURL" onIonChange={(e) => { setBusinessURL(e.detail.value) }} />
                                        </IonCol>
                                        <IonCol className="ion-no-padding" size="5">
                                            <IonText>.intelikart.com</IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        </IonItem>
                        <IonText color="danger" className={`${validation.businessURLValidation ? "" : "ion-hide"}`}>
                            <h5 style={{ fontSize: 12 + 'px' }} className='ion-no-margin ion-margin-horizontal'>{validation.businessURLValidation}</h5>
                        </IonText>

                        {/* business type */}
                        <IonItem className={`${validation.businessTypeValidation ? "" : "ion-margin-bottom"}`} lines="none">
                            <IonLabel position="stacked">Select your business type</IonLabel>
                            <div style={{ width: 100 + '%', display: 'flex', justifyContent: 'space-between' }} onClick={e => setShowModal(true)}>
                                <span style={{ width: 95 + '%' }}>
                                    {storeType && storeType.id && storeType.icon && storeType.name && storeType.description ? <>
                                        {/* <IonImg slot="start" src={storeType.icon} style={{width: 50+'px', height: 50+'px'}}/> */}
                                        {/* <IonText>
                                            <p className="storeTypeName">{storeType.name}</p>
                                        </IonText> */}
                                        <IonInput value={storeType.name} readonly></IonInput>
                                    </> : <IonInput placeholder="Type of Business" readonly></IonInput>}
                                </span>
                                <span>&#x25BC;</span>
                            </div>
                        </IonItem>
                        <IonModal isOpen={showModal} onDidDismiss={e => setShowModal(false)} >
                            <IonHeader>
                                <IonItem lines="none">
                                    <IonTitle>Select Business Type</IonTitle>
                                    <IonButton fill='clear' size='large' slot='end' onClick={(e) => setShowModal(false)} >
                                        <IonIcon slot="icon-only" icon={closeOutline}  ></IonIcon>
                                    </IonButton>
                                    {/* <IonText style={{cursor:'pointer'}}  color="primary" slot="end" onClick={e=> setShowModal(false)}>
                                        <h5>CLOSE</h5>
                                    </IonText> */}
                                </IonItem>
                            </IonHeader>
                            <IonContent className="ion-padding">
                                {businessTypeList && businessTypeList.data ?
                                    (
                                        businessTypeList.data.map((element: businesstypeItem) => (
                                            <IonItem key={element.id} style={{ cursor: 'pointer' }} onClick={e => { setStoreType(element); setShowModal(false) }}>
                                                <IonText>
                                                    <h5>{element.name}</h5>
                                                    <p className="ion-no-margin ion-margin-bottom">{element.description}</p>
                                                </IonText>
                                                {storeType && storeType.id && element.id === storeType.id ? <IonIcon slot="end" color="primary" size="large" icon={checkmarkSharpIcon} /> : ""}
                                            </IonItem>
                                        )))
                                    : null
                                }
                            </IonContent>
                        </IonModal>
                        <IonText color="danger" className={`${validation.businessTypeValidation ? "" : "ion-hide"}`}>
                            <h5 style={{ fontSize: 12 + 'px' }} className="ion-no-margin ion-margin-horizontal">{validation.businessTypeValidation}</h5>
                        </IonText>

                        {/* country */}
                        <IonItem lines="none" className='ion-margin-bottom'>
                            <IonLabel position="stacked">Select your country</IonLabel>
                            <div style={{ width: 100 + '%', display: 'flex', justifyContent: 'space-between' }} onClick={e => setShowCountryModal(true)}>
                                <span style={{ width: 95 + '%' }}>
                                    {country ? <>
                                        <IonInput value={country} readonly></IonInput>
                                    </> : <IonInput placeholder="Country" readonly></IonInput>}
                                </span>
                                <span>&#x25BC;</span>
                            </div>
                        </IonItem>

                        <IonModal isOpen={showCountryModal} onDidDismiss={e => setShowCountryModal(false)}>
                            <CountryModal selectedCountry={country} cancelChanges={() => setShowCountryModal(false)} confirmChanges={(countryName) => { confirm(countryName) }} />
                        </IonModal>

                        {/* mobile */}
                        <IonItem className={`${!validation.phoneValidation ? "ion-margin-bottom" : ""}`} lines="none">
                            <IonLabel position="stacked">Enter your mobile number</IonLabel>
                            <IonGrid className="ion-no-padding" style={{ width: "100%" }}>
                                <IonRow className="ion-align-items-center">
                                    <IonCol className="ion-no-padding" size="1">
                                        <IonInput value={`${mobileCode}`} readonly />
                                    </IonCol>
                                    <IonCol className="ion-no-padding" size="11">
                                        <IonInput
                                            placeholder="Mobile Number"
                                            type="tel"
                                            inputmode="numeric"
                                            value={phone}
                                            name="phone"
                                            onIonChange={(e) => { setPhone(e.detail.value) }}
                                        />
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonItem>
                        <IonText color="danger" className={`${validation.phoneValidation ? "" : "ion-hide"}`}>
                            <h5 style={{ fontSize: 12 + 'px' }} className='ion-no-margin ion-margin-horizontal'>{validation.phoneValidation}</h5>
                        </IonText>

                        {/* Referral code */}
                        <IonItem className={`${!validation.referralCodeValidation ? "ion-margin-bottom" : ""}`} lines="none">
                            <IonLabel position="stacked">Referral Code</IonLabel>
                            <IonInput placeholder="Optional" type="tel" inputmode="numeric" value={referralCode} readonly={cookieValue ? true : false} onIonChange={(e) => { setReferralCode(e.detail.value) }} />
                        </IonItem>
                        <IonText color="danger" className={`${!validation.referralCodeValidation ? "ion-hide" : ""}`}>
                            <h5 style={{ fontSize: 12 + 'px' }} className='ion-no-margin ion-margin-horizontal'>{validation.referralCodeValidation}</h5>
                        </IonText>

                        <IonGrid className="ion-no-padding">
                            <IonRow className="ion-align-items-center">
                                <IonCol className="ion-text-center">
                                    <IonButton shape="round" type="submit">Create my store</IonButton>
                                </IonCol>
                                {/* <IonCol sizeMd='2'>
                                    <IonButton   fill='outline'>
                                        <IonIcon icon={helpCircleOutline} slot="start"/> Want A Demo ?
                                    </IonButton>
                                </IonCol> */}
                            </IonRow>
                        </IonGrid>
                    </form>
                    {/* <IonLoading isOpen={ businessTypeListLoading === true || loading === true } message={'Please Wait...'}/> */}
                </div>
            </IonContent>
            <LoginFooter />
        </IonPage>
    )
}

const CountryModal = ({ cancelChanges, confirmChanges, selectedCountry }) => {
    const [filteredCountryList, setFilteredCountryList] = useState(countryList)

    const searchbarInput = (e) => {
        let searchQuery = e.target.value;

        const normalizedQuery = searchQuery.toLowerCase();
        setFilteredCountryList(
            countryList.filter((item) => {
                return item.toLowerCase().includes(normalizedQuery);
            })
        );
    }

    return (
        <>
            <IonHeader>
                <IonItem lines="none">
                    <IonTitle>Select your country</IonTitle>
                    <IonButton style={{ marginLeft: 0 }} fill='clear' size='large' slot='end' onClick={cancelChanges} >
                        <IonIcon slot="icon-only" icon={closeOutline} />
                    </IonButton>
                </IonItem>
                <IonItem lines="none">
                    <div style={{ width: "100%", margin: "5px 15px 15px", border: "none", backgroundColor: "#eee" }} className="product-search-bar">
                        <IonIcon icon={serachIcon} style={{ marginRight: "7px" }}></IonIcon>
                        <IonInput placeholder="Search Country" onIonChange={searchbarInput}></IonInput>
                    </div>
                </IonItem>
            </IonHeader>

            <IonContent className="ion-no-padding ion-no-margin">
                <IonList id="modal-list" inset={true} className="ion-no-padding ion-no-margin">
                    <IonRadioGroup name="countryName" value={selectedCountry} onIonChange={(e) => confirmChanges(e.target.value)}>
                        {filteredCountryList.map((item, index) => (
                            <IonItem key={index}>
                                <IonRadio slot="start"
                                    value={item}
                                />
                                <IonLabel>{item}</IonLabel>
                            </IonItem>
                        ))}
                    </IonRadioGroup>
                </IonList>
            </IonContent>
        </>
    )
}

export default GetBusinessDetails
