import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRadio, IonRadioGroup, IonRow, IonText } from '@ionic/react';
import { create as createIcon, trash as trashIcon } from 'ionicons/icons';
import { useState } from 'react';
import { StoreCurrency } from '../../../utils/currency';
import { alphaNumericValidation } from '../../../utils/validations';
const ProductLevelTaxes = ({taxData, setTaxData}) => {
    const [tax, setTax] = useState({
        name: "",
        value: "",
        method: "FLAT"
    });
    const [editingIndex, setEditingIndex] = useState(null);
    const currency = StoreCurrency();
    const [validation, setValidation] = useState({
        name: "",
        value: "",
    });
    function validatingFunction(type) {
        if (type === "name") {
            if (!alphaNumericValidation(tax.name)) {
                setValidation({ ...validation, name: "Tax name is required and should not contain special characters." });
            } else {
                setValidation({ ...validation, name: "" });
            }
        }
        if (type === "value") {
            if (!tax.value) {
                setValidation({ ...validation, value: `Tax ${tax.method === 'FLAT' ? 'amount' : 'percentage'} is required.` });
            } else {
                if (parseInt(tax.value) === 0) {
                    setValidation({ ...validation, value: "Tax value should not be 0." });
                }
                else if (tax.method !== "FLAT" && (0 > Number(tax.value) || Number(tax.value) > 100 || !/^\d+(\.\d{0,2})?$/.test(tax.value))) {
                        setValidation({ ...validation, value: "Tax percentage is not greater than 100 or less than 0 and it can't accept character & symbols ." });
                    } else if (tax.method === "FLAT" && !(/^\d+(\.\d{1,2})?$/.test(`${tax.value}`))) {
                            setValidation({ ...validation, value: "Tax amount should contain numbers only or up to 2 decimal places only." });
                        } else {
                            setValidation({ ...validation, value: "" });
                        }
                    }
                }
            }
    

    function AddToList(){
        if(!tax.name){
            validatingFunction("name");
        }
        else if(!tax.value){
            validatingFunction("value")
        }
        else{
        if(!(validation.name || validation.value)){
            if(editingIndex !== null){
                setTaxData((prev)=> {
                    let temp = [...prev]; 
                    temp.splice(editingIndex, 1, tax);
                    return temp;
                });
                setEditingIndex(null);
            }else{
                setTaxData([...taxData, tax]);
            }
            setTax({
                name: "",
                value: "",
                method: "FLAT"
            })
        }}
    }

    const handleChange = (e) => { 
        const newMethod = e.detail.value;
        if (editingIndex === null) {
          setTax({ ...tax, method: newMethod, value: null, name: "" });
          setValidation({ name: "", value: "" });
        } else {
          setTax(prevTax => ({ ...prevTax, method: newMethod }));
        }
      }

    return (<>
        <h5 className="ion-padding-start ion-padding-top">Product Taxes</h5>
        <IonGrid>
            <IonRow className='ion-wrap'>
                {taxData && taxData.map((elem, index)=>{
                    return <IonCard key={elem + index}>
                        <IonCardHeader>
                            <IonItem lines='none' className='ion-no-padding'>
                                <IonCardSubtitle style={{fontSize: '18px'}}>{elem.name}</IonCardSubtitle>
                                <IonButton slot='end' onClick={async e=> {await setEditingIndex(index);await setTax(elem);}}>
                                    <IonIcon icon={createIcon}/>
                                </IonButton>
                                <IonButton color="danger" slot='end' onClick={e => setTaxData((prev)=> {
                                    let temp = [...prev];
                                    temp.splice(index,1);
                                    return temp;
                                })}>
                                    <IonIcon icon={trashIcon}/>
                                </IonButton>
                            </IonItem>
                        </IonCardHeader>
                        <IonCardContent>{elem.method === "FLAT" ? `Flat: ${elem.value} ${currency}` : `Percentage: ${elem.value}%`}</IonCardContent>
                    </IonCard>})
                }
            </IonRow>
        </IonGrid>
        
        <IonCard>
            <IonCardContent className="zero-padding-horizontal">
                <IonItem lines="none">
                    <IonLabel position="stacked"><h1 className="label">Select Tax/Charge type</h1></IonLabel>
                    <IonGrid className="ion-no-margin ion-no-padding ion-padding-top" style={{width: 100+'%'}}>
                        <IonRadioGroup name="taxType" value={tax.method} onIonChange={e => handleChange(e)}>
                            <IonRow>
                                <IonCol sizeSm="5" sizeXs="12">
                                    <IonItem lines="none">
                                        <IonRadio slot="start" value="FLAT" />
                                        <IonLabel>Flat</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol>
                                    <IonItem lines="none">
                                        <IonLabel>Percentage</IonLabel>
                                        <IonRadio slot="start" value="PERCENT" />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonRadioGroup>
                    </IonGrid>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked"><h1 className="label">Enter the Tax name</h1></IonLabel>
                    <IonInput placeholder="Enter the Tax name" type="text" value={tax.name} onIonChange={(e)=>{ setTax({...tax, name: e.detail.value}) }} onIonBlur={e=> validatingFunction("name")}/>
                </IonItem>
                <IonText color="danger" className={`${validation.name ? "": "ion-hide" }`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className="ion-margin-horizontal">{validation.name}</h5>
                </IonText>
                <IonItem>
                    <IonLabel position="stacked"><h1 className="label">{`${tax.method === "FLAT" ? "Enter the tax amount" : "Enter tax percentage"}`}</h1></IonLabel>
                    <IonInput placeholder="0.0" type="tel" value={tax.value} onIonChange={(e)=>{ setTax({...tax, value: e.detail.value}) }} onIonBlur={e=> validatingFunction("value")}/>
                </IonItem>
                <IonText color="danger" className={`${validation.value ? "": "ion-hide" }`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className="ion-margin-horizontal">{validation.value}</h5>
                </IonText>
                <br/>
                <div className="ion-text-center">
                    <IonButton slot='end' onClick={e=> AddToList()}>Add Tax</IonButton>
                </div>
            </IonCardContent>
        </IonCard>
    </>)
}

export default ProductLevelTaxes