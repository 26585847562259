import { Camera, CameraResultType } from "@capacitor/camera";
import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonText,
  isPlatform
} from "@ionic/react";
import {
  informationCircleOutline,
  trash as trashIcon
} from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import useAPI from "../../../utils/api";
import MediaLibraryModal from "./MediaLibraryModal";

const ProductImages = ({platformPaths, setPlatformPaths, imageValidation, setImageValidation, pictureUrl, setPictureUrl}) => {
    const API = useAPI();
    const [numOfImages, setNumOfImage] = useState<any>(0);
    const fileInputRef = useRef<HTMLInputElement>();
    const [showModal, setShowModal] = useState(false);

    const handleImageSelection = (checkedImageData) => {
      const dataArray:any = Object.values(checkedImageData);
      for(let i = 0; i < dataArray.length; i++){
        setPictureUrl((prev)=> {
          let temp = [...prev];
          temp[numOfImages + i] = dataArray[i].url;
          return temp;
        });
        setPlatformPaths((prev)=>{
          let temp = [...prev];
          temp[numOfImages + i] = dataArray[i].path;
          return temp;
        });
      }
      setShowModal(false);
    };

    useEffect(() => {
        setNumOfImage(platformPaths.length);
    }, [platformPaths])

    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.files.length > 0) {
            const file = event.target.files.item(0);
            if (file.type.startsWith("image")) {
                setImageValidation("");
                setPictureUrl((prev)=> {
                    const myPictureUrl = URL.createObjectURL(file);
                    let temp = [...prev];
                    temp[numOfImages] = myPictureUrl;
                    return temp;
                });
                const [ok, response]: any = await API.uploadImage(file, "product-image", file.name);
                if (ok && response?.platform_path) {
                    setPlatformPaths((prev)=>{
                        let temp = [...prev];
                        temp[numOfImages] = response?.platform_path;
                        return temp;
                    });
                }
            } else {
                setImageValidation("Please select a valid image.");
            }
        }
        event.target.value = "";
    };

    const handleUploadClick = async () => {
        if (isPlatform("capacitor")) {
            try {
                const photo = await Camera.getPhoto({
                    resultType: CameraResultType.Uri,
                });
                setPictureUrl((prev)=> {
                    let temp = [...prev];
                    temp[numOfImages] = photo.webPath;
                    return temp;
                });
                const file = await fetch(photo.webPath).then((r) => r.blob());
                const [ok, response]: any = await API.uploadImage(file, "product-image", "productImg.jpg");
                if (ok && response?.platform_path) {
                    setPlatformPaths((prev)=>{
                        let temp = [...prev];
                        temp[numOfImages] = response?.platform_path;
                        return temp;
                    });
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            fileInputRef.current.click();
        }
        setShowModal(false);
    };

    const deleteImage = async (imageNumber) => {
        await setPictureUrl(prev => {
            let temp = [...prev];
            temp.splice(imageNumber, 1);
            temp.push("/assets/placeholder.svg");
            return temp;
        });
        await setPlatformPaths(prev => {
            let temp = [...platformPaths];
            temp.splice(imageNumber, 1);
            return temp;
        });
    };

    return (
      <IonCard className="ion-padding">
            <IonText style={{ display: "inline-flex", gap: "15px" }} className="ion-margin-start">
                <IonIcon
                    className="ion-margin-right"
                    icon={informationCircleOutline}
                    style={{ fontSize: "25px" }}
                />
                For Best View, Use 500X500px image
            </IonText>
            <input
                hidden
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleFileChange}
            />
            <IonGrid>
                <IonRow className="ion-align-items-center">
                    {(Array.from({ length: (numOfImages < 5 ? numOfImages : 4) })).map((_, index) => <IonCol>
                        <IonCard className="product-image-card">
                            <img
                                width="100%"
                                height={
                                    pictureUrl[index] === "/assets/placeholder.svg" ? "100%" : "65%"
                                }
                                src={pictureUrl[index]}
                                alt="product"
                            ></img>
                            {pictureUrl[index] !== "/assets/placeholder.svg" && (
                                <IonButton
                                    className="ion-no-margin"
                                    expand="full"
                                    color="danger"
                                    onClick={(e) => deleteImage(index)}
                                >
                                    <IonIcon icon={trashIcon} />
                                </IonButton>
                            )}
                        </IonCard>
                    </IonCol>)}
                    {numOfImages < 4 && <IonCol>
                      <IonButton
                        className="ion-no-margin"
                        onClick={(e) => {
                          handleUploadClick();
                        }}
                      >
                        Upload Image
                      </IonButton>
                      <br></br>
                      <IonButton
                        className="ion-margin-top"
                        onClick={(e) => {
                          setShowModal(true);
                        }}
                      >
                        Media Library
                      </IonButton>
                    </IonCol>}
                </IonRow>
            </IonGrid>
            <IonText
                color="danger"
                className={`${imageValidation ? "" : "ion-hide"}`}>
                <h5 className="ion-margin-horizontal error-text">
                    {imageValidation}
                </h5>
            </IonText>
            <MediaLibraryModal
              showModal={showModal}
              setShowModal={setShowModal}
              handleImageSelection={handleImageSelection}
              canSelect = {4 - numOfImages}
            />
        </IonCard>
    );
};

export default ProductImages;