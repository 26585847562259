export const GET_GA_CONFIGURATION_REQUEST = 'GET_GA_CONFIGURATION_REQUEST'
export const GET_GA_CONFIGURATION_SUCCESS = 'GET_GA_CONFIGURATION_SUCCESS'
export const GET_GA_CONFIGURATION_FAIL = 'GET_GA_CONFIGURATION_FAIL'

export const POST_GA_CONFIGURATION_REQUEST = 'POST_GA_CONFIGURATION_REQUEST'
export const POST_GA_CONFIGURATION_SUCCESS = 'POST_GA_CONFIGURATION_SUCCESS'
export const POST_GA_CONFIGURATION_FAIL = 'POST_GA_CONFIGURATION_FAIL'

export const PUT_GA_CONFIGURATION_REQUEST = 'PUT_GA_CONFIGURATION_REQUEST'
export const PUT_GA_CONFIGURATION_SUCCESS = 'PUT_GA_CONFIGURATION_SUCCESS'
export const PUT_GA_CONFIGURATION_FAIL = 'PUT_GA_CONFIGURATION_FAIL'

export const GET_WHATSAPP_CONFIGURATION_REQUEST = 'GET_WHATSAPP_CONFIGURATION_REQUEST'
export const GET_WHATSAPP_CONFIGURATION_SUCCESS = 'GET_WHATSAPP_CONFIGURATION_SUCCESS'
export const GET_WHATSAPP_CONFIGURATION_FAIL = 'GET_WHATSAPP_CONFIGURATION_FAIL'

export const POST_WHATSAPP_CONFIGURATION_REQUEST = 'POST_WHATSAPP_CONFIGURATION_REQUEST'
export const POST_WHATSAPP_CONFIGURATION_SUCCESS = 'POST_WHATSAPP_CONFIGURATION_SUCCESS'
export const POST_WHATSAPP_CONFIGURATION_FAIL = 'POST_WHATSAPP_CONFIGURATION_FAIL'

export const PUT_WHATSAPP_CONFIGURATION_REQUEST = 'PUT_WHATSAPP_CONFIGURATION_REQUEST'
export const PUT_WHATSAPP_CONFIGURATION_SUCCESS = 'PUT_WHATSAPP_CONFIGURATION_SUCCESS'
export const PUT_WHATSAPP_CONFIGURATION_FAIL = 'PUT_WHATSAPP_CONFIGURATION_FAIL'


export const GET_SHIPROCKET_CONFIGURATION_REQUEST = 'GET_SHIPROCKET_CONFIGURATION_REQUEST'
export const GET_SHIPROCKET_CONFIGURATION_SUCCESS = 'GET_SHIPROCKET_CONFIGURATION_SUCCESS'
export const GET_SHIPROCKET_CONFIGURATION_FAIL = 'GET_SHIPROCKET_CONFIGURATION_FAIL'

export const POST_SHIPROCKET_CONFIGURATION_REQUEST = 'POST_SHIPROCKET_CONFIGURATION_REQUEST'
export const POST_SHIPROCKET_CONFIGURATION_SUCCESS = 'POST_SHIPROCKET_CONFIGURATION_SUCCESS'
export const POST_SHIPROCKET_CONFIGURATION_FAIL = 'POST_SHIPROCKET_CONFIGURATION_FAIL'

export const PUT_SHIPROCKET_CONFIGURATION_REQUEST = 'PUT_SHIPROCKET_CONFIGURATION_REQUEST'
export const PUT_SHIPROCKET_CONFIGURATION_SUCCESS = 'PUT_SHIPROCKET_CONFIGURATION_SUCCESS'
export const PUT_SHIPROCKET_CONFIGURATION_FAIL = 'PUT_SHIPROCKET_CONFIGURATION_FAIL'