import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonToast,
  useIonViewDidEnter
} from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { deviceDetect, isDesktop } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { authenticateUserWhenLogin, getCurrentUser, getUserTokens } from '../actions/authActions';
import { setPushNotification } from '../actions/dashboardActions';
import LoginFooter from '../components/auth/LoginFooter';
import LogoImage from '../components/auth/LogoImage';
import { getAccessPermission, getAccessToken, setTokensObject } from '../services/auth/userLocalStorage';
import { IsStaffContext } from '../utils/contexts';
// import { Device } from '@capacitor/device';


const SignUpWithEmailOTP = () => {
  const updateStaffData :any = useContext(IsStaffContext);
    const history = useHistory();
    const dispatch = useDispatch();
    const[email,setEmail]=useState('');
    // Added new state for internet connectivity
    const [isOnline, setIsOnline] = useState(true);
    const[loading,setLoading]=useState(false)
    const[isCredentialsValidate,setIsCredentialsValidate]=useState(true)
    const[resendButtonDisabledTime,setResendButtonDisabledTime]=useState(0);
    const [validation, setValidation] = useState({
        emailValidation: '',   
    })
    const [otpArray, setOtpArray] = useState(['', '', '', '']);
    const firstTextInputRef = useRef(null);
    const secondTextInputRef = useRef(null);
    const thirdTextInputRef = useRef(null);
    const fourthTextInputRef = useRef(null);
    const btnInputRef = useRef(null);
    const [isStaff, setIsStaff] = useState(false);

    const nullEntry: any[] = []

    const { userAPITokensLoading, userAPITokens, userAPITokensError } = useSelector((state : any)=> state.userAPITokens);
    const { currentUserLoading, currentUser, currentUserError } = useSelector((state : any)=> state.currentUser);
    const permissions = getAccessPermission();

    const [present] = useIonToast();

    useEffect(() => {
        if(userAPITokensLoading === false){
            if(userAPITokens){
                setTokensObject(userAPITokens.data);
                loadCurrentUser(userAPITokens.data.token);
                if (isPlatform('android')) {
                    // for push notification
                    PushNotify();
                }
            }
            if(userAPITokensError){
                setIsCredentialsValidate(false)
            }
        }

    }, [userAPITokens, userAPITokensError ]);
    

    const handleStaffRedirection = (permissions) => {
      if (permissions.includes('orders')) {
          history.push('/orders');
      }
  };
  
    useEffect(() => {
        if (currentUserLoading === false && getAccessToken()) {
            if (currentUser && currentUser.data) {
              console.log(permissions.length > 0);
                updateStaffData.setIsStaff(permissions.length > 0);
                dispatch(authenticateUserWhenLogin());

                if (currentUser.data.store.storetype_id !== 0) {
                    if (permissions.length === 0) {
                        history.push('/userdashboard', { direction: 'none' });
                    } else if (permissions.length > 0) {
                        // Staff logic
                        handleStaffRedirection(permissions);
                    }
                } else {
                    // Default redirection for other users
                    history.push('/getbusinessdetails');
                }

            }
        }
    }, [currentUser, currentUserLoading, dispatch, history, permissions]);

    useEffect(() => {
        // Added effect to check internet connectivity
        const handleOnlineStatus = () => {
            setIsOnline(navigator.onLine);
        };

        handleOnlineStatus(); // Check initial online status

        window.addEventListener('online', handleOnlineStatus);
        window.addEventListener('offline', handleOnlineStatus);

        return () => {
            window.removeEventListener('online', handleOnlineStatus);
            window.removeEventListener('offline', handleOnlineStatus);
        };
    }, []);


    const register = () => {
        PushNotifications.register(); 
        PushNotifications.addListener('registration',
            (token: Token) => {
                const firebase_token = JSON.stringify(token);
                const firebase_token_obj = JSON.parse(firebase_token);
                const firebase_token_value = firebase_token_obj.value;
                const deviceDetails = deviceDetect().osName + " " + deviceDetect().browserName;
                dispatch(
                    setPushNotification(
                      getAccessToken(),
                      "seller-app",
                      deviceDetails,
                      {
                        registration_id: `${firebase_token_value}`,
                      }
                    )
                  ); 
            }
        );

        PushNotifications.addListener('registrationError',
            (error: any) => {
                alert('Error on registration: ' + JSON.stringify(error));
                console.log('Error on registration: ' + JSON.stringify(error));
            }
        );

        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
            }
        );

        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
            }
        );
    }

    

    const PushNotify = () => {
        PushNotifications.checkPermissions().then((res) => {
            console.log("Res",res)
            if (res.receive !== 'granted') {
                console.log("Response receive",res.receive)
              PushNotifications.requestPermissions().then((res) => {
                if (res.receive === 'denied') {
                console.log('push notifcation denied')
                }
                else {
                console.log('push notifcation granted ')
                  register();
                }
              });
            }
            else {
              register();
            }
          });
    }

    const onOtpChange = index => {
        return e => {        
            if (isNaN(Number(e.target.value))) {
                return;
            }
            const otpArrayCopy = otpArray.concat();
            
            if(otpArrayCopy[index]=="" && index===1 && e.key==='Backspace'){
                setTimeout(() => firstTextInputRef.current.setFocus(), 50)
            }
            else if(otpArrayCopy[index]=="" && index===2 && e.key==='Backspace'){
                setTimeout(() =>secondTextInputRef.current.setFocus(), 50)
            }
            else if(otpArrayCopy[index]=="" && index===3 && e.key==='Backspace'){
                setTimeout(() => thirdTextInputRef.current.setFocus(), 50)
            }
            otpArrayCopy[index] = e.target.value;
            setOtpArray(otpArrayCopy);
            if(otpArrayCopy[index]!=="" && index===0){
                setTimeout(() => secondTextInputRef.current.setFocus(), 50)
            }
            else if(otpArrayCopy[index]!=="" && index===1){
                setTimeout(() =>thirdTextInputRef.current.setFocus(), 50)
            }
            else if(otpArrayCopy[index]!=="" && index===2){
                setTimeout(() => fourthTextInputRef.current.setFocus(), 50)
            }
            else if(otpArrayCopy[index]!=="" && index===3){
                setTimeout(() =>
                    fourthTextInputRef.current.setBlur(), 20)
            }
        }
    }
    let resendOtpTimerInterval;
    const startResendOtpTimer = () => {
        if (resendOtpTimerInterval) {
            clearInterval(resendOtpTimerInterval);
        }
        resendOtpTimerInterval = setInterval(() => {
            if (resendButtonDisabledTime <= 0) {
                clearInterval(resendOtpTimerInterval);
            } else {
                setResendButtonDisabledTime(resendButtonDisabledTime - 1);
            }
        }, 1000);
    };

    useEffect(() => {
        startResendOtpTimer();

        return () => {
            if (resendOtpTimerInterval) {
                clearInterval(resendOtpTimerInterval);
            }
        };
    }, [resendButtonDisabledTime]);

    useEffect(() => {
        if (userAPITokensLoading === false && userAPITokensError && userAPITokensError.message) {
            present(userAPITokensError.message, 1000);
        }
    }, [userAPITokensLoading, userAPITokensError])

    const validate = () => {
        let result = true;
        let temp = { ...validation }

    if (!email) {
        temp.emailValidation = "Email is required."
        result = false;
    } else {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            temp.emailValidation = "Email is invalid."
            result = false;
            setTimeout(() => {
                temp.emailValidation = "";
                setValidation(temp);
            }, 2000);
        } else {
            temp.emailValidation = ""
        }
    }
    setValidation(temp);
    return result;
}


    const loadCurrentUser = async (token: string)=>{
        let staff = isStaff ? 'true' : 'false'
        await dispatch(getCurrentUser(token, staff));
    }
    const sendOTP = async (e) => {
        e.preventDefault();
        const res = await validate();
        if (!res) {
            validate();
        } else {
            let scope = isStaff ? 'staff' : 'store'
            try {
                const response = await fetch(
                    `https://api2.intelikart.in/oauth/otp`,
                    {
                        method: "POST",
                        headers: {
                            "content-type": "application/json",
                        },
                        body: JSON.stringify({ "email": email, "scope": scope })
                    }
                );
                if(response.status == 200) {
                    setResendButtonDisabledTime(60)
                    setTimeout(() => firstTextInputRef.current.setFocus(), 100)
                }
                else {
                    const res = await response.json();
                    present(res.message, 1000);
                }

            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        }
    }
    const verifyOTP=async(e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('username',email);
        formData.append('password',otpArray.join(""));
        formData.append('scope', isStaff ? 'staff' : 'store');
        formData.append('thirdparty','email-otp') 
        await dispatch(getUserTokens(formData,"email"));
    }

    const firstNameInputRef = useRef<HTMLIonInputElement>(null);
    useIonViewDidEnter(() => {
        firstNameInputRef.current?.setFocus();
    });

    let classOfGrid = "";
    if(isDesktop){
        classOfGrid = "center-grid";
    }


    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" />
            </IonButtons>
            <IonTitle>Login With Email</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding ion-text-center">
          {isOnline ? (
            <IonGrid className={classOfGrid}>
              <IonRow>
                <IonCol>
                  <div className="iklogo">
                    <LogoImage />
                  </div>
                  <div className='ion-margin-top'><IonRow className="ion-justify-content-center">
                      <IonItem>
                        <IonLabel>I am</IonLabel>
                        <IonSelect
                          value={isStaff ? "staff" : "seller"}
                          onIonChange={(e) =>
                            setIsStaff(e.detail.value === "staff")
                          }
                          interface="popover"
                          style={{ maxWidth: "200px" }}
                        >
                          <IonSelectOption value="seller">
                            Seller
                          </IonSelectOption>
                          <IonSelectOption value="staff">Staff</IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    </IonRow></div>
                  <form className="ion-padding" onSubmit={sendOTP}>
                    <IonItem>
                      <IonLabel position="stacked">Email</IonLabel>
                      <IonInput
                        ref={firstNameInputRef}
                        required
                        autofocus={true}
                        value={email}
                        type="text"
                        onIonChange={(e) => setEmail((e.detail.value).trim())}
                        placeholder="example@email.com"
                        className="ion-text-center"
                      />
                    </IonItem>
                    <IonText
                      color="danger"
                      className={`${
                        !validation.emailValidation ? "ion-hide" : ""
                      }`}
                    >
                      <h5 style={{ fontSize: 12 + "px", marginTop: 0 + "px" }}>
                        {validation.emailValidation}
                      </h5>
                    </IonText>

                    {resendButtonDisabledTime ? (
                      <p
                        className="ion-text-center"
                        style={{ color: "#4286f5" }}
                      >
                        Resend OTP in <b>{resendButtonDisabledTime}s</b>
                      </p>
                    ) : (
                      <IonButton className="ion-margin-top" type="submit">
                        Send OTP
                      </IonButton>
                    )}
                  </form>
                  <form onSubmit={verifyOTP}>
                    <br />
                    <IonLabel>Enter OTP sent on your email</IonLabel>
                    <IonRow>
                      <IonCol>
                        <IonInput
                          style={{
                            borderBottom: "1px solid",
                            fontSize: "25px",
                            textAlign: "center",
                          }}
                          value={otpArray[0]}
                          onKeyUp={onOtpChange(0)}
                          ref={firstTextInputRef}
                          required={true}
                          type="tel"
                          maxlength={1}
                          className="ion-padding-horizontal"
                        ></IonInput>
                      </IonCol>
                      <IonCol>
                        <IonInput
                          style={{
                            borderBottom: "1px solid",
                            fontSize: "25px",
                            textAlign: "center",
                          }}
                          value={otpArray[1]}
                          onKeyUp={onOtpChange(1)}
                          ref={secondTextInputRef}
                          required={true}
                          type="tel"
                          maxlength={1}
                          className="ion-padding-horizontal"
                        ></IonInput>
                      </IonCol>
                      <IonCol>
                        <IonInput
                          style={{
                            borderBottom: "1px solid",
                            fontSize: "25px",
                            textAlign: "center",
                          }}
                          value={otpArray[2]}
                          onKeyUp={onOtpChange(2)}
                          ref={thirdTextInputRef}
                          required={true}
                          type="tel"
                          maxlength={1}
                          className="ion-padding-horizontal"
                        ></IonInput>
                      </IonCol>
                      <IonCol>
                        <IonInput
                          style={{
                            borderBottom: "1px solid",
                            fontSize: "25px",
                            textAlign: "center",
                          }}
                          value={otpArray[3]}
                          onKeyUp={onOtpChange(3)}
                          ref={fourthTextInputRef}
                          required={true}
                          type="tel"
                          maxlength={1}
                          className="ion-padding-horizontal"
                        ></IonInput>
                      </IonCol>
                    </IonRow>

                    <IonText
                      color="danger"
                      className={`${
                        isCredentialsValidate === true ? "ion-hide" : ""
                      }`}
                    >
                      <h5
                        style={{ fontSize: 12 + "px", marginTop: 5 + "px" }}
                        className={`ion-margin-horizontal `}
                      >
                        {" "}
                        {currentUserError?.error}
                      </h5>
                    </IonText>
                    <IonButton
                      className="ion-margin-top"
                      type="submit"
                      ref={btnInputRef}
                    >
                      Verify Now{" "}
                    </IonButton>
                  </form>
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            // Display a message when the user is offline
            <IonText color="danger">
              <h5>
                You are not connected to the internet. Please check your
                connection and try again.
              </h5>
            </IonText>
          )}
        </IonContent>
        <IonLoading
          isOpen={loading || userAPITokensLoading}
          message={"Please Wait..."}
        />
        <LoginFooter />
      </IonPage>
    );
                            }          

export default SignUpWithEmailOTP
