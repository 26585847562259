import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import {
  IonPage, IonContent, IonGrid, IonRow, IonCol,
  IonButton, IonCard, IonCardHeader,
  IonCardTitle, IonCardContent,
  IonText,
  IonLoading,
  IonIcon
} from '@ionic/react';
import { createOutline } from 'ionicons/icons';
import Header from '../../components/dashboard/Header';
import useFetch from "../../utils/useFetch";

const ShowManualBill = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  const { data, loading, status } = useFetch('getManualBills');

  const cardStyle = {
    cursor: 'pointer'
  };

  return (
    <IonPage>
      <Header name='Manual Billing' />
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow className="ion-justify-content-end ion-padding-vertical">
            <IonCol size="auto">
              <IonButton
                shape="round"
                onClick={() => {
                  history.push('/manualbilling/createmanualbill');
                }}
              >
                Create New Bill
              </IonButton>
            </IonCol>
          </IonRow>

          {Array.isArray(data?.data) && data.data.slice().reverse().map((item, index) => (
            <IonRow key={index} className="ion-justify-content-center">
              <IonCol size='12'>
                <IonCard>
                  <IonCardHeader>
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <IonCardTitle>
                            Invoice Id: {item.id}
                          </IonCardTitle>
                        </IonCol>
                        <IonCol size="auto">
                          <IonText>
                            Amount: {item.total_amount}
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow className="ion-align-items-center">
                      <IonCol>
                        <p>Name: {item.name}</p>
                        <p>Email: {item.email}</p>
                      </IonCol>
                      <IonCol size="auto">
                        <IonIcon
                          size="large"
                          color="primary"
                          icon={createOutline}
                          onClick={() => {
                            history.push(`/manualbilling/updatemanualbill/${item.id}`);
                          }}
                          style={cardStyle}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          ))}

          {!Array.isArray(data?.data) && (
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol className="ion-text-center">
                <IonText>No bills available.</IonText>
              </IonCol>
            </IonRow>
          )}

        </IonGrid>
        <IonLoading isOpen={loading} message="Please Wait" />
      </IonContent>
    </IonPage>
  );
};

export default ShowManualBill;
