import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  IonContent,
  IonLabel,
  IonText,
  IonLoading,
  IonBackButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonItem,
  IonInput,
  IonButton,
  IonPage,
  IonRow,
  IonGrid,
  IonCol,
  isPlatform,
  IonTextarea,
  IonToast,
  IonModal,
} from "@ionic/react";
import {
  getBasicInfo,
  setBasicInfo,
  clearErrors,
} from "../../actions/dashboardActions";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import { arrowBack as arrowBackIcon } from "ionicons/icons";
import { Camera, CameraResultType } from "@capacitor/camera";
import { getCurrentUser } from "../../actions/authActions";
import FeatHeader from '../../components/dashboard/FeatHeader';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { IonIcon } from '@ionic/react';
import { close as closeIcon } from 'ionicons/icons';


async function savePicture(file, fileName) {
  if (!file) return "";
  try {
    const formData = new FormData();
    formData.append("file", file, fileName);
    const response = await fetch(
      `https://api2.intelikart.in/upload/logo-image`,
      {
        method: "POST",
        body: formData,
      }
    );
    const data = await response.json();
    return data.platform_path;
  } catch (error) {
    console.log(error);
    return "";
  }
}

const BasicInfo = () => {
  const [businessName, setBusinessName] = useState<any>();
  const [description, setDescription] = useState<any>();
  const [isGSTValid, setIsGSTValid] = useState(true);
  const [isBNameValid, setIsBNameValid] = useState(true);
  const [pictureUrl, setPictureUrl] = useState(
    "https://cdn.intelikart.com/uploads/new-images/default-store.png"
  );
  const [imageFile, setImageFile] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [GSTIN, setGSTIN] = useState<any>("");
  const [crop, setCrop] = useState({ width: 500, aspect: 1.1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [imageRef, setImageRef] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(pictureUrl);
  const dispatch = useDispatch();
  const history = useHistory();
  const { basicInfoLoading, basicInfo, basicInfoError } = useSelector(
    (state: any) => state.basicInfo
  );
  const {
    setBasicInfoResponseLoading,
    setBasicInfoResponse,
    setBasicInfoResponseError,
  } = useSelector((state: any) => state.setBasicInfo);
  const { currentUser } = useSelector((state: any) => state.currentUser);
  const fileInputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    if (currentUser && currentUser.data) {
      dispatch(
        getBasicInfo(getAccessToken(), currentUser.data.store.subdomain)
      );
    }
  }, [currentUser]);

  useEffect(() => {
    if (basicInfoLoading === false && basicInfo && basicInfo.data) {
      setBusinessName(basicInfo.data.name);
      setDescription(basicInfo.data.business_description);
      setGSTIN(basicInfo.data.gst);
      if (basicInfo.data.logo_platform_path !== null) {
        setPictureUrl(basicInfo.data.logo_internet_url);
        setCroppedImageUrl(basicInfo.data.logo_internet_url);
      }
    }
  }, [basicInfo]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length > 0) {
      const file = event.target.files.item(0);
      setImageFile(file);
      setImageName(file.name);
      const pictureUrl = URL.createObjectURL(file);
      setPictureUrl(pictureUrl);
      setShowCropModal(true);

    }
  };
  const handlePictureClick = async () => {
    if (isPlatform("capacitor")) {
      try {
        const photo = await Camera.getPhoto({
          resultType: CameraResultType.Uri,
        });
        setPictureUrl(photo.webPath);
        const file = await fetch(photo.webPath).then((r) => r.blob());
        setImageFile(file);
        setImageName("logo.jpg");
        setShowCropModal(true);

      } catch (error) {
        console.log(error);
      }
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the previous value
    }
    fileInputRef.current.click();
    }
  };
  const GSTValidation = () => {
    let result = true;
      // Check if the first two characters are capital letters
      const firstTwoChars = GSTIN.substring(0, 2);
      // Check if the rest of the characters are numbers
      const restOfString = GSTIN.substring(2);
      if (GSTIN) {
        // Check if the first two characters are capital letters
        const firstTwoChars = GSTIN.substring(0, 2);
        if (GSTIN.length !== 15 || !(/^[0-9]{2}/.test(firstTwoChars))) {
          setIsGSTValid(false);
          result = false;
        } else {
          setIsGSTValid(true);
        }
      }
    
    return result;
  };

  const NameValidation = () => {
    console.warn("checking naming")
    let result = true;
      if (businessName===null || !(/[a-zA-Z]/.test(businessName)) ) {
        setIsBNameValid(false);
        result = false;
        console.warn("inside if");
      } else {
        console.warn("inside else");
        setIsBNameValid(true);
      }
  
    return result;
  };
  const submitHandler = async (e: any) => {
    e.preventDefault();
    NameValidation()
     GSTValidation()
    if (currentUser && currentUser.data && NameValidation() &&
    GSTValidation() ) {
      setLoading(true);
      let platform_path = await savePicture(imageFile, imageName);
      if (!platform_path) platform_path = basicInfo.data.logo_platform_path;
      Promise.all([
        await dispatch(
          setBasicInfo(getAccessToken(), currentUser.data.store.subdomain, {
            name: `${businessName}`,
            business_description: `${description}`,
            gst: `${GSTIN}`,
            logo: platform_path,
          })
        ),
        // await dispatch(
        //   getBasicInfo(getAccessToken(), currentUser.data.store.subdomain)
        // ),
        // await dispatch(getCurrentUser(getAccessToken())),
      ])
        .then(() => {
          setLoading(false);
          setShowToast(true); // Show the toast message
          history.goBack();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const handleImageLoaded = (image) => {
    setImageRef(image);
  };

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        const file = new File([blob], 'cropped.jpg', { type: 'image/jpeg' });
        setCroppedImageUrl(URL.createObjectURL(file));
        resolve(file);
      }, 'image/jpeg');
    });
  };

  const handleCropComplete = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImg(imageRef, crop);
      setImageFile(croppedImage);
      setCompletedCrop(crop);
    }
  };

  const handleCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  return (
    <IonPage>
      <FeatHeader
        title='Basic Info'
        defaultHref='/settings'
        showInfoButton={false}
      />
      <IonContent className="ion-padding">
        <form onSubmit={submitHandler}>
          <IonGrid>
            <IonRow className="ion-align-items-center">
              <IonCol sizeSm="4" sizeXs="4.5">
                <IonLabel>Business name</IonLabel>
              </IonCol>
              <IonCol>
                <IonItem style={{'--padding-start' : '0'}}>
                  <IonInput
                    placeholder={businessName}
                    type="text"
                    value={businessName}
                    name="businessName"
                    onIonChange={(e) => {
                      setBusinessName(e.detail.value);
                    }}
                  />
                </IonItem>
                <IonText
                  className={`${isBNameValid == true ? "ion-hide" : ""}`}
                  color="danger"
                >
                  <h5
                    className={`ion-margin-start ${
                      isBNameValid ? "ion-hide" : ""
                    }`}
                    style={{ fontSize: 12 + "px" }}
                  >
                    Name is not valid.
                  </h5>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol sizeSm="4" sizeXs="4.5">
                <IonLabel>Description</IonLabel>
              </IonCol>
              <IonCol>
                <IonItem style={{'--padding-start' : '0'}}>
                  <IonTextarea
                    placeholder={description}
                    value={description}
                    name="description"
                    onIonChange={(e) => {
                      setDescription(e.detail.value);
                    }}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol sizeSm="4" sizeXs="4.5">
                <IonLabel>GSTIN</IonLabel>
              </IonCol>
              <IonCol>
                <IonItem style={{'--padding-start' : '0'}}>
                  <IonInput
                    placeholder={GSTIN}
                    type="text"
                    value={GSTIN}
                    name="GSTIN"
                    onIonChange={(e) => {
                      setGSTIN(e.detail.value);
                    }}
                  />
                </IonItem>
                <IonText
                  className={`${isGSTValid == true ? "ion-hide" : ""}`}
                  color="danger"
                >
                  <h5
                    className={`ion-margin-start ${
                      isGSTValid ? "ion-hide" : ""
                    }`}
                    style={{ fontSize: 12 + "px" }}
                  >
                    GSTIN is not valid.
                  </h5>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol sizeSm="4" sizeXs="4.5">
                <IonLabel>Website</IonLabel>
              </IonCol>
              <IonCol>
                <IonItem style={{'--padding-start' : '0'}}>
                  <IonInput
                    value={
                      basicInfoLoading === false && basicInfo && basicInfo.data
                        ? basicInfo.data.subdomain
                        : ""
                    }
                    disabled
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol sizeSm="4" sizeXs="4.5">
                <IonLabel>Store Type</IonLabel>
              </IonCol>
              <IonCol>
                <IonItem style={{'--padding-start' : '0'}}>
                  <IonInput
                    value={
                      basicInfoLoading === false && basicInfo && basicInfo.data
                        ? basicInfo.data.store_type
                        : ""
                    }
                    disabled
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4">
                <IonLabel>Logo</IonLabel>
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol size="6">
                <div className="image-upload" style={{textAlign:"center"}}>
                  <input
                    hidden
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                  <img width="100%" height="150" src={croppedImageUrl} alt="" style={{objectFit: 'contain' }}></img>
                  </div>
              </IonCol>
              <IonCol>
                <IonButton
                  size="small"
                  shape="round"
                  onClick={handlePictureClick}
                >
                  Update Image
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          <div className="ion-text-center">
            <IonButton shape="round" type="submit">
              Save
            </IonButton>
          </div>
        </form>
        <IonLoading
          isOpen={
            loading === true ||
            basicInfoLoading === true ||
            setBasicInfoResponseLoading === true
          }
          message="Please Wait"
        ></IonLoading>
      </IonContent>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Saved successfully!"
        duration={2000}
      />
      <IonModal isOpen={showCropModal} onDidDismiss={() => setShowCropModal(false)}>
        <IonContent className="ion-padding">
          <div className="crop-container" style={{ padding: '20px', textAlign: 'center' }}>
            <ReactCrop
              src={pictureUrl}
              crop={crop}
              ruleOfThirds
              onImageLoaded={handleImageLoaded}
              onComplete={handleCropComplete}
              onChange={handleCropChange}
            />
          </div>
          <div className="ion-text-center ion-margin-top">
            <IonButton onClick={() => setShowCropModal(false)}>Done</IonButton>
          </div>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default BasicInfo;
