import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IonContent,
  IonLabel,
  IonText,
  IonLoading,
  IonBackButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonItem,
  IonInput,
  IonButton,
  IonPage,
  IonItemDivider,
  IonRange,
  IonList,
  IonCard,
  IonIcon,
  IonToggle,
  useIonToast,
  IonRow
} from "@ionic/react";
import { postTagLine, getTagLine } from "../../actions/dashboardActions";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import FeatHeader from '../../components/dashboard/FeatHeader';

import {
  arrowBack as arrowBackIcon,
  newspaperOutline as newspaperOutlineIcon,
} from "ionicons/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from "quill";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }],
    ["bold", "italic", "underline", "sttrike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    ["clean"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
];

const Branding = () => {
  const dispatch = useDispatch();
  const [tagLine, setTagLine] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [present] = useIonToast();

  const { currentUser } = useSelector((state: any) => state.currentUser);
  const { TagLineLoading, TagLine, TagLineError } = useSelector(
    (state: any) => state.getTagLine
  );

  const [validation, setValidation] = useState({
    footerValidation: "",
  });
  const validate = async () => {
    let result = true;
    let temp = { ...validation };

    // footer tagline validation
    if (!tagLine || tagLine.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
      temp.footerValidation = "Tagline is required";
      setIsSaved(false);
      result = false;
    } else temp.footerValidation = "";
    await setValidation(temp);
    return result;
  };

  useEffect(() => {
    if (currentUser && currentUser.data) {
      dispatch(getTagLine(getAccessToken(), currentUser.data.store.subdomain));
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      TagLineLoading === false &&
      TagLine &&
      TagLine.data &&
      TagLine.data.footer_text
    ) {
      setTagLine(TagLine.data.footer_text);
    }
    
  }, [TagLine]);

  const handleClick = async (e: any) => {
    e.preventDefault();
    const res = await validate();
    if (res) {
      dispatch(
        postTagLine(getAccessToken(), currentUser.data.store.subdomain, {
          footer_text: tagLine,
        })
      );
      setIsSaved(true);
      present({
        message: "Saved successfully!",
        duration: 2000,
        position: "bottom",
      });
    }
    if(!tagLine || tagLine.replace(/<(.|\n)*?>/g, "").trim().length === 0){
      setIsSaved(false)
    }
    // setTimeout(() => {
    //   setIsSaved(false);
    // }, 1000);
  };

  return (
    <IonPage>
      <FeatHeader
        title='Branding'
        defaultHref='/settings'
        showInfoButton={false}
      />
      <IonContent className="ion-padding">
      <IonRow>
        <b> This Branding will show at the footer. </b> 
      </IonRow>
        <IonItem lines="none">
          <IonLabel position="stacked">Tag Line</IonLabel>
        </IonItem>
        <ReactQuill
          placeholder="Write Footer Tag Line here."
          modules={modules}
          formats={formats}
          value={tagLine}
          onChange={(e) => {
            return setTagLine(e);
          }}
        />
        <IonText
          color="danger"
          className={`${validation.footerValidation ? "" : "ion-hide"}`}
        >
          <h5
            style={{ fontSize: 12 + "px" }}
            className="ion-no-margin ion-margin-horizontal"
          >
            {validation.footerValidation}
          </h5>
        </IonText>
        <br />
        <div className="ion-text-center"></div>
        <div className="ion-text-center ion-margin-bottom">
          <IonButton
            shape="round"
            onClick={(e) => {
              handleClick(e);
            }}
          >
            Save
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Branding;
