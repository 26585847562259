import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    useIonToast,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { arrowBackOutline as arrowBackIcon } from "ionicons/icons";
import useAPI from "../../utils/api";
import { useHistory, useParams } from "react-router";
import {
    trashOutline as trashIcon,
    createOutline as updateIcon,
    addOutline as addIcon,
    removeOutline as removeIcon,
    informationCircleOutline as infoIcon,
} from "ionicons/icons";
import { useUser } from "../../utils/contexts";

interface RouteParams {
    id: string;
}

function CreateAppMenu() {
    const history = useHistory();
    const { id } = useParams<RouteParams>();
    const API = useAPI();
    const [present] = useIonToast();

    const [menuName, setMenuName] = useState("");
    const [links, setLinks] = useState([]);
    const [linkText, setLinkText] = useState("");
    const [btnText, setBtnText] = useState("Add Link");
    const [flag, setFlag] = useState(true);
    const [link, setLink] = useState("");
    const [show, setShow] = useState(false);
    const [elementId, setElementId] = useState(-1);
    const [menuValidation, setMenuValidation] = useState(false);
    const [linkTextValidation, setLinkTextValidation] = useState("");
    const [urlValidation, setUrlValidation] = useState("");
    const currentUser: any = useUser();

    const validate = () => {
        let result = true;
        if (linkText == "") {
            setLinkTextValidation("Link text is required");
            result = false;
        } else {
            if (
                /^[\d`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+$/.test(
                    linkText.trim()
                )
            ) {
                setLinkTextValidation(
                    "Link text must contain at least one character"
                );
                result = false;
            } else {
                setLinkTextValidation("");
            }
        }

        if (link == "") {
            setUrlValidation("Link URL is required");
            result = false;
        } else {
            if (
                !/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
                    link.trim()
                )
            ) {
                setUrlValidation("Link URL is invalid");
                result = false;
            } else {
                setUrlValidation("");
            }
        }

        return result;
    };

    const linkHandler = () => {
        if (validate()) {
            if (btnText == "Update Link") {
                links?.map((item, index) => {
                    if (elementId == index) {
                        item.text = linkText;
                        item.link = link;
                        setBtnText("Add Link");
                        setFlag(!flag);
                        setLink("");
                        setLinkText("");
                    }
                });
            } else {
                setLinks((prev) => {
                    return [...prev, { text: linkText, link: link }];
                });
                setLink("");
                setLinkText("");
            }
        }
    };
    const submitHandler = async (e) => {
        e.preventDefault();
        if (
            menuName === "" ||
            /^[\d`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+$/.test(
                menuName.trim()
            ) ||
            links?.length < 1
        ) {
            setMenuValidation(true);
            setTimeout(() => setMenuValidation(false), 5000);
        } else {
            if (!history.location.pathname.includes("create")) {
                let [ok, response] = await API.updateAppMenu({
                    id: id,
                    name: menuName,
                    links: links,
                });
                if (ok) {
                    history.push("/content/menus/app-menu");
                } else {
                    present("some error occured please try again later", 3000);
                }
            } else {
                let [ok, response] = await API.createAppMenu({
                    name: menuName,
                    links: links,
                });
                if (ok) {
                    history.push("/content/menus/app-menu");
                } else {
                    present("some error occured please try again later", 3000);
                }
            }
        }
    };
    useEffect(() => {
        if (currentUser) {
            if (!history.location.pathname.includes("create")) {
                const getData = async () => {
                    try {
                        const [ok, response] = await API.getAppMenus();
                        if (ok) {
                            setMenuName(response.data.name);
                            setLinks(response.data.links);
                        } else {
                        }
                    } catch (err) {}
                };
                getData();
            }
        }
    }, [history, currentUser]);
    return (
        <IonPage>
            <IonHeader className="header-height">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            defaultHref="/content/menus/app-menu"
                            icon={arrowBackIcon}
                        />
                    </IonButtons>
                    <IonTitle>
                        {history.location.pathname.includes("create")
                            ? "Create Menu"
                            : "Update Menu"}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <form
                    className="ion-padding"
                    onSubmit={(e) => {
                        submitHandler(e);
                    }}
                    method="post"
                >
                    <IonItem lines="inset">
                        <IonLabel position="floating">
                            {" "}
                            Enter Menu Name
                        </IonLabel>
                        <IonInput
                            type="text"
                            value={menuName}
                            placeholder="Enter menu name"
                            onIonChange={(e) => {
                                setMenuName(e.detail.value);
                            }}
                        />
                    </IonItem>

                    <IonCard className="ion-no-margin ion-margin-vertical">
                        <IonCardHeader>
                            <IonCardTitle className="ion-align-center">
                                <IonRow className="ion-align-items-center ion-justify-content-between">
                                    {" "}
                                    <IonText>Menu Links</IonText>
                                    <IonButton
                                        shape="round"
                                        size="small"
                                        onClick={() => setShow(!show)}
                                    >
                                        <IonIcon
                                            icon={show ? removeIcon : addIcon}
                                        ></IonIcon>{" "}
                                    </IonButton>
                                </IonRow>
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            {links?.length !== 0 &&
                                links?.map((item, index) => {
                                    return (
                                        <IonItem lines="inset">
                                            <IonGrid style={{ width: "100%" }}>
                                                <IonRow>
                                                    <IonCol
                                                        sizeMd="4"
                                                        sizeXs="4"
                                                        style={{
                                                            overflow: "hidden",
                                                            textOverflow:
                                                                "ellipsis",
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        <IonText>
                                                            {item.text}
                                                        </IonText>
                                                    </IonCol>
                                                    <IonCol
                                                        sizeMd="7"
                                                        sizeXs="6"
                                                        style={{
                                                            overflow: "hidden",
                                                            textOverflow:
                                                                "ellipsis",
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        <IonText>
                                                            {item.link}
                                                        </IonText>
                                                    </IonCol>
                                                    <IonCol
                                                        sizeMd="1"
                                                        sizeXs="2"
                                                    >
                                                        <IonIcon
                                                            size="small"
                                                            className="pointer-cursor"
                                                            icon={updateIcon}
                                                            onClick={() => {
                                                                setShow(true);
                                                                setLinkText(
                                                                    item.text
                                                                );
                                                                setLink(
                                                                    item.link
                                                                );
                                                                setBtnText(
                                                                    "Update Link"
                                                                );
                                                                setElementId(
                                                                    index
                                                                );
                                                            }}
                                                        />
                                                        <IonIcon
                                                            size="small"
                                                            className="pointer-cursor"
                                                            icon={trashIcon}
                                                            onClick={() => {
                                                                links?.map(
                                                                    (
                                                                        value,
                                                                        index
                                                                    ) => {
                                                                        if (
                                                                            value.id ==
                                                                            item.id
                                                                        ) {
                                                                            links.splice(
                                                                                index,
                                                                                1
                                                                            );
                                                                            setFlag(
                                                                                !flag
                                                                            );
                                                                            return 1;
                                                                        }
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>
                                    );
                                })}
                        </IonCardContent>
                        {show && (
                            <>
                                <hr style={{ borderTop: "1px solid #ccc" }} />
                                <IonItem lines="none">
                                    <h3 className="ion-no-margin">Add Link </h3>
                                </IonItem>
                                <IonItem lines="inset">
                                    <IonLabel position="floating">
                                        {" "}
                                        Enter Link Text
                                    </IonLabel>
                                    <IonInput
                                        type="text"
                                        value={linkText}
                                        placeholder="Enter Link Text here"
                                        onIonChange={(e) => {
                                            setLinkText(e.detail.value);
                                        }}
                                    />{" "}
                                </IonItem>
                                <IonText
                                    color="danger"
                                    className={`${
                                        linkTextValidation ? "" : "ion-hide"
                                    }`}
                                >
                                    <h5
                                        style={{
                                            fontSize: 12 + "px",
                                            marginTop: 0 + "px",
                                            marginBottom: 0 + "px",
                                        }}
                                        className="ion-margin-horizontal"
                                    >
                                        {linkTextValidation}
                                    </h5>
                                </IonText>
                                <IonItem lines="inset">
                                    <IonLabel position="floating">
                                        Enter Link{" "}
                                    </IonLabel>
                                    <IonInput
                                        type="text"
                                        placeholder="Enter Link here"
                                        value={link}
                                        onIonChange={(e) => {
                                            setLink(e.detail.value);
                                        }}
                                    />
                                </IonItem>
                                <IonText
                                    color="danger"
                                    className={`${
                                        urlValidation ? "" : "ion-hide"
                                    }`}
                                >
                                    <h5
                                        style={{
                                            fontSize: 12 + "px",
                                            marginTop: 0 + "px",
                                            marginBottom: 0 + "px",
                                        }}
                                        className="ion-margin-horizontal"
                                    >
                                        {urlValidation}
                                    </h5>
                                </IonText>
                                <IonButton
                                    className="ion-margin"
                                    shape="round"
                                    onClick={() => linkHandler()}
                                >
                                    {btnText}
                                </IonButton>
                            </>
                        )}
                    </IonCard>
                    {menuValidation ? (
                        <IonText color="danger">
                            Links and Name cannot be empty. Menu Name must
                            contain at least one character.
                        </IonText>
                    ) : (
                        ""
                    )}
                    <div className="ion-text-center ion-padding-vertical">
                        <IonButton shape="round" type="submit">
                            {history.location.pathname.includes("create")
                                ? "Create Menu"
                                : "Update Menu"}
                        </IonButton>
                    </div>
                </form>
            </IonContent>
        </IonPage>
    );
}

export default CreateAppMenu;
