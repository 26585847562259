import { IonAlert, IonButton, IonCard, IonCol, IonContent, IonGrid, IonPage, IonRow, IonText, IonToggle, useIonToast } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import Header from '../../components/dashboard/Header';
import useAPI from '../../utils/api';
import { useAddon } from '../../utils/contexts';

const Card = ({ data }) => {
    console.log("DATAs",data);
    const [showAlert, setShowAlert] = useState(false);
    const [present] = useIonToast();
    const history = useHistory();
    const API = useAPI();

    const handler = async (e) => {
        if (Array.isArray(data.data)) {
            console.log("daattaa",data.data)
            setShowAlert(true)
        } else {
            if (data.data && !Array.isArray(data.data) && (data.data.status === "active") !== e.target.checked) {
                if (data.name === "Google Analytics") {
                    const [ok, response] = await API.updateGAAddon({
                        "google_measurement_id": data.data.google_measurement_id,
                        "status": e.target.checked ? "active" : "inactive",
                        id: data.data.id
                    });

                    if (ok) {
                        data.updateMethod(!data.update);
                    }
                } else if (data.name === "Whatsapp") {
                    const [ok, response] = await API.updateWhatsappAddon({
                        "whatsapp_phone": data.data.whatsapp_phone,
                        "status": e.target.checked ? "active" : "inactive",
                        id: data.data.id
                    });

                    if (ok) {
                        data.updateMethod(!data.update);
                    }
                } else if (data.name === "Shiprocket") {
                    const [ok, response] = await API.updateShiprocketAddon({
                        "shiprocket_email": data.data.shiprocket_email,
                        "shiprocket_password": data.data.shiprocket_password,
                        "status": e.target.checked ? "active" : "inactive",
                        "id": data.data.id
                    });

                    if (ok) {
                        data.updateMethod(!data.update);
                    }
                } else if (data.name === "Inventory Management(Product Level)") {
                    const [ok, response] = await API.updateInventoryAddon({
                        "show_stock_out_products": data.data.show_stock_out_products,
                        "status": e.target.checked ? "active" : "inactive",
                        "id": data.data.id
                    });

                    if (ok) {
                        data.updateMethod(!data.update);
                    }
                } else if (data.name === "Manual Order Management") {
                    const [ok, response] = await API.updateOrderAddon({
                        "status": e.target.checked ? "active" : "inactive",
                        "id": data.data.id
                    });

                    if (response.statusCode === 400) {
                        present(response.message, 1500);
                        e.target.checked = !e.target.checked
                    }
                    if (ok) {
                        data.updateMethod(!data.update);
                    }
                } else if (data.name === "Mailchimp") {
                    const [ok, response] = await API.updateMailchimpAddon({
                        "mailchimp_api_key": data.data.mailchimp_api_key,
                        "mailchimp_list_id": data.data.mailchimp_list_id,
                        "status": e.target.checked ? "active" : "inactive",
                        "id": data.data.id
                    });

                    if (response.statusCode === 400) {
                        present(response.message, 1500);
                        e.target.checked = !e.target.checked
                    }
                    if (ok) {
                        data.updateMethod(!data.update);
                    }
                } else if (data.name === "Product Level Taxes") {
                    const [ok, response] = await API.createProductTaxAddon({
                        "status": e.target.checked ? "active" : "inactive",
                        "id": data.data.id
                    });

                    if (ok) {
                        data.updateMethod(!data.update);
                    }
                } else if (data.name === "Self Delivery") {              
                    const [ok, response] = await API.updateSelfDeliveryAddon({
                        "status": e.target.checked ? "active" : "inactive",
                        "id": data.data.id
                    }); 
                    if (ok) {                    
                        data.updateMethod(!data.update);
                    }
                }
                else if (data.name === "OptiMonk") {
                    const [ok, response] = await API.updateOptimonkAddon({
                        "account_id": data.data.account_id,
                        "status": e.target.checked ? "active" : "inactive",
                        "id": data.data.id
                    });

                    if (response.statusCode === 400) {
                        present(response.message, 1500);
                        e.target.checked = !e.target.checked
                    }
                    if (ok) {
                        data.updateMethod(!data.update);
                    }
                }
                else if (data.name === "Custom SMTP") {
                    const [ok, response] = await API.updateSmtpAddon({
                        "host": data.data.host,
                        "port": data.data.port,
                        "username": data.data.username,
                        "password": data.data.password,
                        "from_address": data.data.from_address,
                        "from_name": data.data.from_name,
                        "status": e.target.checked ? "active" : "inactive",
                        "id": data.data.id
                    });

                    if (response.statusCode === 400) {
                        present(response.message, 1500);
                        e.target.checked = !e.target.checked
                    }
                    if (ok) {
                        data.updateMethod(!data.update);
                    }
                }

                else if (data.name === "Tawk.To") {
                    const [ok, response] = await API.updateTawkAddon({
                        "property_id": data.data.property_id,
                        "widget_id": data.data.widget_id,
                        "status": e.target.checked ? "active" : "inactive",
                        "id": data.data.id
                    });

                    if (response.statusCode === 400) {
                        present(response.message, 1500);
                        e.target.checked = !e.target.checked
                    }
                    if (ok) {
                        data.updateMethod(!data.update);
                    }
                }
                else if (data.name === "Complementary Products") {
                    const [ok, response] = await API.putComplementaryProductAddon({
                        "status": e.target.checked ? "active" : "inactive",
                        "id": data.data.id
                    });

                    if (response.statusCode === 400) {
                        present(response.message, 1500);
                        e.target.checked = !e.target.checked
                    }

                    if (ok) {
                        data.updateMethod(!data.update);
                    }
                }
                else if (data.name === "ONDC") {
                    const [ok, response] = await API.putOndcAddon({
                        "status": e.target.checked ? "active" : "inactive",
                        "id": data.data.id
                    });

                    if (response.statusCode === 400) {
                        present(response.message, 1500);
                        e.target.checked = !e.target.checked
                    }

                    if (ok) {
                        data.updateMethod(!data.update);
                    }
                }
                else if (data.name === "SEO") {
                    const [ok, response] = await API.createSeoAddon({
                        "title": data.data.title,
                        "description": data.data.description,
                        "keyword": data.data.keyword,
                        "status": e.target.checked ? "active" : "inactive"
                    });

                    if (ok) {
                        data.updateMethod(!data.update);
                    }
                }
                else if (data.name === "Product Fields") {
                    const [ok, response] = await API.createDynamicFieldsAddon({
                        "status": e.target.checked ? "active" : "inactive",
                        "id": !Array.isArray(data.data) && data?.data?.id
                    });

                    if (ok) {
                        data.updateMethod(!data.update);
                    }
                }
                else if (data.name === "Scheduled Order") {
                    const [ok, response] = await API.PostScheduledOrderAddon({
                        "status": e.target.checked ? "active" : "inactive",
                        "id": data.data.id
                    });

                    if (ok) {
                        data.updateMethod(!data.update);
                    }
            }
            else if (data.name === "Digital Product"){
                console.log("toglle")
                const [ok, response] = await API.PostDigitalProductAddon({
                    "status": e.target.checked ? "active" : "inactive",
                    "id": data.data.id
                });

                if (ok) {
                    data.updateMethod(!data.update);
                }
            }
            else if (data.name === "Festival"){
                console.log("toglle")
                const [ok, response] = await API.PostFestivalAddon({
                    "status": e.target.checked ? "active" : "inactive",
                    "id": data.data.id
                });

                if (ok) {
                    data.updateMethod(!data.update);
                }
            }
            // else if (data.name === "Google Play Store"){
            //     console.log("toglle")
            //     const [ok, response] = await API.PostFestivalAddon({
            //         "status": e.target.checked ? "active" : "inactive",
            //         "id": data.data.id
            //     });

            //     if (ok) {
            //         data.updateMethod(!data.update);
            //     }
            // }
        }
    }
}

    return (<>
        <IonCard style={{display:"flex",alignItems:"center", minWidth: 360 + 'px', height: '100%' }} >
            <IonGrid>
                <IonRow className="ion-align-items-center">
                    <IonCol size="4">
                        <img src={data.image} width='154px' />
                    </IonCol>
                    <IonCol size='6'>
                        <IonText >
                            <h4>{data.name}</h4>
                            <p>{data.description}</p>
                            {data.url && <IonButton shape="round"  routerLink={data.url}>Configure</IonButton>}
                        </IonText>
                    </IonCol>
                    {/* {data.name != "Google Play Store" && <IonCol size="2">
                        <IonToggle color="primary" checked={data.data.status === 'active'} onIonChange={(e: any) => handler(e)}></IonToggle>
                    </IonCol>} */}
                </IonRow>
            </IonGrid>
        </IonCard>
        <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            header={'Alert'}
            message={`Please configure ${data.name} addon before activating it.`}
            buttons={['Cancel', {
                text: 'Configure',
                handler: e => {
                    history.push(data.url)
                }
            },]}
        />
    </>
    )
        }

const Addons = (props: any) => {
    const data: any = useAddon();

    return (<IonPage>
        <div className="ion-page" id="main-content">
            <Header name="Addons" />
            <IonContent>
                <IonText className="ion-text-center">
                    <p>Extend the functionality of your store using addons</p>
                </IonText>
                <IonGrid>
                    <IonRow className='ion-align-items-stretch'>
                        {console.log("MAp",data)}
                        {data?.map((addon) => {
                            return !addon.loading && addon.status === "fetched" && <IonCol key={addon.name} sizeLg='6' sizeMd='6' sizeSm='12'>
                                <Card data={addon} />
                            </IonCol>
                        })}
                    </IonRow>
                </IonGrid>
            </IonContent>
        </div>
    </IonPage>
    )
}

export default Addons
