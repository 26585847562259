import { getTagLine } from '../actions/dashboardActions';
import { 

    LOAD_TIPS_BANNERS_REQUEST, LOAD_TIPS_BANNERS_SUCCESS, LOAD_TIPS_BANNERS_FAIL,
    LOAD_HOME_SCREEN_REQUEST, LOAD_HOME_SCREEN_SUCCESS, LOAD_HOME_SCREEN_FAIL,
    LOAD_HOME_SCREEN_WITH_DAYS_REQUEST, LOAD_HOME_SCREEN_WITH_DAYS_SUCCESS, LOAD_HOME_SCREEN_WITH_DAYS_FAIL,
    LOAD_ACADEMY_CONTENT_REQUEST, LOAD_ACADEMY_CONTENT_SUCCESS, LOAD_ACADEMY_CONTENT_FAIL,
    LOAD_CUSTOMERS_REQUEST, LOAD_CUSTOMERS_SUCCESS, LOAD_CUSTOMERS_FAIL,
    GET_COUPONS_REQUEST, GET_COUPONS_SUCCESS, GET_COUPONS_FAIL,
    CREATE_COUPON_REQUEST, CREATE_COUPON_SUCCESS, CREATE_COUPON_FAIL,
    DELETE_COUPON_REQUEST, DELETE_COUPON_SUCCESS, DELETE_COUPON_FAIL,
    GET_BANNERS_REQUEST, GET_BANNERS_SUCCESS, GET_BANNERS_FAIL,
    CREATE_BANNER_REQUEST, CREATE_BANNER_SUCCESS, CREATE_BANNER_FAIL,
    DELETE_BANNER_REQUEST, DELETE_BANNER_SUCCESS, DELETE_BANNER_FAIL,
    GET_SETTINGS_BASIC_INFO_REQUEST, GET_SETTINGS_BASIC_INFO_SUCCESS, GET_SETTINGS_BASIC_INFO_FAIL,
    SET_SETTINGS_BASIC_INFO_REQUEST, SET_SETTINGS_BASIC_INFO_SUCCESS, SET_SETTINGS_BASIC_INFO_FAIL,
    GET_SETTINGS_LOCALIZATION_REQUEST, GET_SETTINGS_LOCALIZATION_SUCCESS, GET_SETTINGS_LOCALIZATION_FAIL,
    SET_SETTINGS_LOCALIZATION_REQUEST, SET_SETTINGS_LOCALIZATION_SUCCESS, SET_SETTINGS_LOCALIZATION_FAIL,
    GET_SETTINGS_SERVICE_DETAILS_REQUEST, GET_SETTINGS_SERVICE_DETAILS_SUCCESS, GET_SETTINGS_SERVICE_DETAILS_FAIL,
    SET_SETTINGS_SERVICE_DETAILS_REQUEST, SET_SETTINGS_SERVICE_DETAILS_SUCCESS, SET_SETTINGS_SERVICE_DETAILS_FAIL,
    GET_SETTINGS_QUICK_ORDERS_REQUEST, GET_SETTINGS_QUICK_ORDERS_SUCCESS, GET_SETTINGS_QUICK_ORDERS_FAIL,
    SET_SETTINGS_QUICK_ORDERS_REQUEST, SET_SETTINGS_QUICK_ORDERS_SUCCESS, SET_SETTINGS_QUICK_ORDERS_FAIL,
    GET_SETTINGS_PAYMENTS_REQUEST, GET_SETTINGS_PAYMENTS_SUCCESS, GET_SETTINGS_PAYMENTS_FAIL,
    SET_SETTINGS_PAYMENTS_REQUEST, SET_SETTINGS_PAYMENTS_SUCCESS, SET_SETTINGS_PAYMENTS_FAIL,
    GET_SETTINGS_RAZORPAY_REQUEST, GET_SETTINGS_RAZORPAY_SUCCESS, GET_SETTINGS_RAZORPAY_FAIL,
    SET_SETTINGS_RAZORPAY_REQUEST, SET_SETTINGS_RAZORPAY_SUCCESS, SET_SETTINGS_RAZORPAY_FAIL,
    GET_TAXES_REQUEST, GET_TAXES_SUCCESS, GET_TAXES_FAIL,
    CREATE_TAX_REQUEST, CREATE_TAX_SUCCESS, CREATE_TAX_FAIL,
    DELETE_TAX_REQUEST, DELETE_TAX_SUCCESS, DELETE_TAX_FAIL,
    UPLOAD_IMAGE_REQUEST, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAIL,
    GET_ORDERS_BY_STATUS_REQUEST, GET_ORDERS_BY_STATUS_SUCCESS, GET_ORDERS_BY_STATUS_FAIL,
    GET_ORDER_BY_ID_REQUEST, GET_ORDER_BY_ID_SUCCESS, GET_ORDER_BY_ID_FAIL,
    GET_INVOICE_REQUEST, GET_INVOICE_SUCCESS, GET_INVOICE_FAIL,
    ORDER_STATUS_CHANGE_REQUEST, ORDER_STATUS_CHANGE_SUCCESS, ORDER_STATUS_CHANGE_FAIL,
    CHANGE_ORDER_STAFF_REQUEST, CHANGE_ORDER_STAFF_SUCCESS, CHANGE_ORDER_STAFF_FAIL,
    GET_ADJUSTMENTS_REQUEST, GET_ADJUSTMENTS_SUCCESS, GET_ADJUSTMENTS_FAIL,
    SET_ADJUSTMENTS_REQUEST, SET_ADJUSTMENTS_SUCCESS, SET_ADJUSTMENTS_FAIL,
    EDIT_ADJUSTMENTS_REQUEST, EDIT_ADJUSTMENTS_SUCCESS, EDIT_ADJUSTMENTS_FAIL,
    DELETE_ADJUSTMENTS_REQUEST, DELETE_ADJUSTMENTS_SUCCESS, DELETE_ADJUSTMENTS_FAIL,
    GET_PRINTABLES_REQUEST, GET_PRINTABLES_SUCCESS, GET_PRINTABLES_FAIL,
    GET_ANNOUNCEMENTS_REQUEST, GET_ANNOUNCEMENTS_SUCCESS, GET_ANNOUNCEMENTS_FAIL,
    CREATE_ANNOUNCEMENT_REQUEST, CREATE_ANNOUNCEMENT_SUCCESS, CREATE_ANNOUNCEMENT_FAIL,
    LOAD_PRODUCTS_BY_SUBCATEGORY_REQUEST, LOAD_PRODUCTS_BY_SUBCATEGORY_SUCCESS, LOAD_PRODUCTS_BY_SUBCATEGORY_FAIL,
    LOAD_PROFILE_COMPLETION_FAIL,
    LOAD_PROFILE_COMPLETION_REQUEST,
    LOAD_PROFILE_COMPLETION_SUCCESS,
    GET_THEMES_FAIL, GET_THEMES_SUCCESS, GET_THEMES_REQUEST,
    SET_THEME_REQUEST, SET_THEME_SUCCESS, SET_THEME_FAIL,
    GET_BRANDING_COLORS_FAIL, GET_BRANDING_COLORS_SUCCESS, GET_BRANDING_COLORS_REQUEST,
    SET_BRANDING_COLOR_REQUEST, SET_BRANDING_COLOR_SUCCESS, SET_BRANDING_COLOR_FAIL,
    CLEAR_ERRORS,
    GET_EMAILS_STATS_REQUEST,
    GET_EMAILS_STATS_SUCCESS,
    GET_EMAILS_STATS_FAIL,
    CREATE_EMAILS_REQUEST,
    CREATE_EMAILS_SUCCESS,
    CREATE_EMAILS_FAIL,
    GET_SALES_REQUEST, GET_SALES_SUCCESS, GET_SALES_FAIL, 
    GET_VISITORS_REQUEST, GET_VISITORS_SUCCESS, GET_VISITORS_FAIL,
    GET_CART_REQUEST, GET_CART_SUCCESS, GET_CART_FAIL,
    SET_PUSH_NOTIFICATION_REQUEST, SET_PUSH_NOTIFICATION_SUCCESS, SET_PUSH_NOTIFICATION_FAIL,
    DELETE_PUSH_NOTIFICATION_REQUEST, POST_COMMUNICATIONS_REQUEST,POST_COMMUNICATIONS_SUCCESS,POST_COMMUNICATIONS_FAIL,DELETE_PUSH_NOTIFICATION_SUCCESS, DELETE_PUSH_NOTIFICATION_FAIL,GET_COMMUNICATIONS_REQUEST,GET_COMMUNICATIONS_SUCCESS,GET_COMMUNICATIONS_FAIL,GET_TAGLINE_FAIL,GET_TAGLINE_REQUEST,GET_TAGLINE_SUCCESS,
    GET_SETTINGS_CASHFREE_REQUEST, GET_SETTINGS_CASHFREE_SUCCESS, GET_SETTINGS_CASHFREE_FAIL,
    SET_SETTINGS_CASHFREE_REQUEST, SET_SETTINGS_CASHFREE_SUCCESS, SET_SETTINGS_CASHFREE_FAIL,
    GET_SETTINGS_PHONEPE_REQUEST, GET_SETTINGS_PHONEPE_SUCCESS, GET_SETTINGS_PHONEPE_FAIL,
    SET_SETTINGS_PHONEPE_REQUEST, SET_SETTINGS_PHONEPE_SUCCESS, SET_SETTINGS_PHONEPE_FAIL, FETCH_SUGGESTIONS_REQUEST, FETCH_SUGGESTIONS_SUCCESS, FETCH_SUGGESTIONS_FAILURE, POST_DELIVERY_ORDER_SUCCESS, POST_DELIVERY_ORDER_FAIL, POST_DELIVERY_ORDER_REQUEST, GET_DELIVERY_ORDER_REQUEST, GET_DELIVERY_ORDER_SUCCESS, GET_DELIVERY_ORDER_FAIL, DELETE_DELIVERY_ORDER_FAIL, DELETE_DELIVERY_ORDER_SUCCESS, DELETE_DELIVERY_ORDER_REQUEST, UPDATE_DELIVERY_ORDER_REQUEST, UPDATE_DELIVERY_ORDER_FAIL, UPDATE_DELIVERY_ORDER_SUCCESS,
} from '../constants/dashboardConstants';

export const loadTipBannersReducer = (state = { tipBanners: {} }, action: any) =>{
    switch(action.type){
        case LOAD_TIPS_BANNERS_REQUEST:
            return{
                ...state,
                tipBannersLoading: true,
                tipBannersError: null
            }
        case LOAD_TIPS_BANNERS_SUCCESS:
            return{
                ...state,
                tipBannersLoading: false,
                tipBanners: action.payload
            }
        case LOAD_TIPS_BANNERS_FAIL:
            return{
                ...state,
                tipBannersLoading: false,
                tipBannersError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadHomeScreenReducer = (state = { homeScreen: {} }, action: any) =>{
    switch(action.type){
        case LOAD_HOME_SCREEN_REQUEST:
            return{
                ...state,
                homeScreenLoading: true,
                homeScreenError: null
            }
        case LOAD_HOME_SCREEN_SUCCESS:
            return{
                ...state,
                homeScreenLoading: false,
                homeScreen: action.payload
            }
        case LOAD_HOME_SCREEN_FAIL:
            return{
                ...state,
                homeScreenLoading: false,
                homeScreenError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadHomeScreenWithDaysReducer = (state = { homeScreenWithDays: {} }, action: any) =>{
    switch(action.type){
        case LOAD_HOME_SCREEN_WITH_DAYS_REQUEST:
            return{
                ...state,
                homeScreenWithDaysLoading: true,
                homeScreenWithDaysError: null
            }
        case LOAD_HOME_SCREEN_WITH_DAYS_SUCCESS:
            return{
                ...state,
                homeScreenWithDaysLoading: false,
                homeScreenWithDays: action.payload
            }
        case LOAD_HOME_SCREEN_WITH_DAYS_FAIL:
            return{
                ...state,
                homeScreenWithDaysLoading: false,
                homeScreenWithDaysError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const loadProfileCompletionDataReducer = (state = { profileCompletion: {} }, action: any) =>{
    switch(action.type){
        case LOAD_PROFILE_COMPLETION_REQUEST:
            return{
                ...state,
                profileCompletionLoading: true,
                profileCompeltionError: null
            }
        case LOAD_PROFILE_COMPLETION_SUCCESS:
            return{
                ...state,
                profileCompletionLoading: false,
                profileCompletion: action.payload
            }
        case LOAD_PROFILE_COMPLETION_FAIL:
            return{
                ...state,
                profileCompletionLoading: false,
                profileCompletionError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const loadAcademyContentReducer = (state = { academyContent: {} }, action: any) =>{
    switch(action.type){
        case LOAD_ACADEMY_CONTENT_REQUEST:
            return{
                ...state,
                academyContentLoading: true,
                academyContentError: null
            }
        case LOAD_ACADEMY_CONTENT_SUCCESS:
            return{
                ...state,
                academyContentLoading: false,
                academyContent: action.payload
            }
        case LOAD_ACADEMY_CONTENT_FAIL:
            return{
                ...state,
                academyContentLoading: false,
                academyContentError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadCouponsReducer = (state = { coupons: {} }, action: any) =>{
    switch(action.type){
        case GET_COUPONS_REQUEST:
            return{
                ...state,
                couponsLoading: true,
                couponsError: null
            }
        case GET_COUPONS_SUCCESS:
            return{
                ...state,
                couponsLoading: false,
                coupons: action.payload
            }
        case GET_COUPONS_FAIL:
            return{
                ...state,
                couponsLoading: false,
                couponsError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const createCouponReducer = (state = { createCouponMessage: {} }, action: any) =>{
    switch(action.type){
        case CREATE_COUPON_REQUEST:
            return{
                ...state,
                createCouponMessageLoading: true,
                createCouponMessageError: null
            }
        case CREATE_COUPON_SUCCESS:
            return{
                ...state,
                createCouponMessageLoading: false,
                createCouponMessage: action.payload
            }
        case CREATE_COUPON_FAIL:
            return{
                ...state,
                createCouponMessageLoading: false,
                createCouponMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const deleteCouponReducer = (state = { deleteCouponMessage: {} }, action: any) =>{
    switch(action.type){
        case DELETE_COUPON_REQUEST:
            return{
                ...state,
                deleteCouponMessageLoading: true,
                deleteCouponMessageError: null
            }
        case DELETE_COUPON_SUCCESS:
            return{
                ...state,
                deleteCouponMessageLoading: false,
                deleteCouponMessage: action.payload
            }
        case DELETE_COUPON_FAIL:
            return{
                ...state,
                deleteCouponMessageLoading: false,
                deleteCouponMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadCustomersReducer = (state = { customers: {} }, action: any) =>{
    switch(action.type){
        case LOAD_CUSTOMERS_REQUEST:
            return{
                ...state,
                customersLoading: true,
                customersError: null
            }
        case LOAD_CUSTOMERS_SUCCESS:
            return{
                ...state,
                customersLoading: false,
                customers: action.payload
            }
        case LOAD_CUSTOMERS_FAIL:
            return{
                ...state,
                customersLoading: false,
                customersError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadBannersReducer = (state = { banners: {} }, action: any) =>{
    switch(action.type){
        case GET_BANNERS_REQUEST:
            return{
                ...state,
                bannersLoading: true,
                bannersError: null
            }
        case GET_BANNERS_SUCCESS:
            return{
                ...state,
                bannersLoading: false,
                banners: action.payload
            }
        case GET_BANNERS_FAIL:
            return{
                ...state,
                bannersLoading: false,
                bannersError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const createBannerReducer = (state = { addBanner: {} }, action: any) =>{
    switch(action.type){
        case CREATE_BANNER_REQUEST:
            return{
                ...state,
                addBannerLoading: true,
                addBannerError: null
            }
        case CREATE_BANNER_SUCCESS:
            return{
                ...state,
                addBannerLoading: false,
                addBanner: action.payload
            }
        case CREATE_BANNER_FAIL:
            return{
                ...state,
                addBannerLoading: false,
                addBannerError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const deleteBannerReducer = (state = { deleteBannerMessage: {} }, action: any) =>{
    switch(action.type){
        case DELETE_BANNER_REQUEST:
            return{
                ...state,
                deleteBannerMessageLoading: true,
                deleteBannerMessageError: null
            }
        case DELETE_BANNER_SUCCESS:
            return{
                ...state,
                deleteBannerMessageLoading: false,
                deleteBannerMessage: action.payload
            }
        case DELETE_BANNER_FAIL:
            return{
                ...state,
                deleteBannerMessageLoading: false,
                deleteBannerMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getBasicInfoReducer = (state = { basicInfo: {} }, action: any) =>{
    switch(action.type){
        case GET_SETTINGS_BASIC_INFO_REQUEST:
            return{
                ...state,
                basicInfoLoading: true,
                basicInfoError: null
            }
        case GET_SETTINGS_BASIC_INFO_SUCCESS:
            return{
                ...state,
                basicInfoLoading: false,
                basicInfo: action.payload
            }
        case GET_SETTINGS_BASIC_INFO_FAIL:
            return{
                ...state,
                basicInfoLoading: false,
                basicInfoError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setBasicInfoReducer = (state = { setBasicInfoResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_SETTINGS_BASIC_INFO_REQUEST:
            return{
                ...state,
                setBasicInfoResponseLoading: true,
                setBasicInfoResponseError: null
            }
        case SET_SETTINGS_BASIC_INFO_SUCCESS:
            return{
                ...state,
                setBasicInfoResponseLoading: false,
                setBasicInfoResponse: action.payload
            }
        case SET_SETTINGS_BASIC_INFO_FAIL:
            return{
                ...state,
                setBasicInfoResponseLoading: false,
                setBasicInfoResponseError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getQuickOrderReducer = (state = { quickOrder: {} }, action: any) =>{
    switch(action.type){
        case GET_SETTINGS_QUICK_ORDERS_REQUEST:
            return{
                ...state,
                quickOrderLoading: true,
                quickOrderError: null
            }
        case GET_SETTINGS_QUICK_ORDERS_SUCCESS:
            return{
                ...state,
                quickOrderLoading: false,
                quickOrder: action.payload
            }
        case GET_SETTINGS_QUICK_ORDERS_FAIL:
            return{
                ...state,
                quickOrderLoading: false,
                quickOrderError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setQuickOrderReducer = (state = { setQuickOrderResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_SETTINGS_QUICK_ORDERS_REQUEST:
            return{
                ...state,
                setQuickOrderResponseLoading: true,
                setQuickOrderResponseError: null
            }
        case SET_SETTINGS_QUICK_ORDERS_SUCCESS:
            return{
                ...state,
                setQuickOrderResponseLoading: false,
                setQuickOrderResponse: action.payload
            }
        case SET_SETTINGS_QUICK_ORDERS_FAIL:
            return{
                ...state,
                setQuickOrderResponseLoading: false,
                setQuickOrderResponseError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getPaymentReducer = (state = { payment: {} }, action: any) =>{
    switch(action.type){
        case GET_SETTINGS_PAYMENTS_REQUEST:
            return{
                ...state,
                paymentLoading: true,
                paymentError: null
            }
        case GET_SETTINGS_PAYMENTS_SUCCESS:
            return{
                ...state,
                paymentLoading: false,
                payment: action.payload
            }
        case GET_SETTINGS_PAYMENTS_FAIL:
            return{
                ...state,
                paymentLoading: false,
                paymentError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setPaymentReducer = (state = { setPaymentResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_SETTINGS_PAYMENTS_REQUEST:
            return{
                ...state,
                setPaymentResponseLoading: true,
                setPaymentResponseError: null
            }
        case SET_SETTINGS_PAYMENTS_SUCCESS:
            return{
                ...state,
                setPaymentResponseLoading: false,
                setPaymentResponse: action.payload
            }
        case SET_SETTINGS_PAYMENTS_FAIL:
            return{
                ...state,
                setPaymentResponseLoading: false,
                setPaymentResponseError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}


export const getRazorPayReducer = (state = { razorPay: {} }, action: any) =>{
    switch(action.type){
        case GET_SETTINGS_RAZORPAY_REQUEST:
            return{
                ...state,
                razorPayLoading: true,
                razorPayError: null
            }
        case GET_SETTINGS_RAZORPAY_SUCCESS:
            return{
                ...state,
                razorPayLoading: false,
                razorPay: action.payload
            }
        case GET_SETTINGS_RAZORPAY_FAIL:
            return{
                ...state,
                razorPayLoading: false,
                razorPayError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setRazorPayReducer = (state = { setRazorPayResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_SETTINGS_RAZORPAY_REQUEST:
            return{
                ...state,
                setRazorPayResponseLoading: true,
                setRazorPayResponseError: null
            }
        case SET_SETTINGS_RAZORPAY_SUCCESS:
            return{
                ...state,
                setRazorPayResponseLoading: false,
                setRazorPayResponse: action.payload
            }
        case SET_SETTINGS_RAZORPAY_FAIL:
            return{
                ...state,
                setRazorPayResponseLoading: false,
                setRazorPayResponseError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getServiceDetailReducer = (state = { serviceDetail: {} }, action: any) =>{
    switch(action.type){
        case GET_SETTINGS_SERVICE_DETAILS_REQUEST:
            return{
                ...state,
                serviceDetailLoading: true,
                serviceDetailError: null
            }
        case GET_SETTINGS_SERVICE_DETAILS_SUCCESS:
            return{
                ...state,
                serviceDetailLoading: false,
                serviceDetail: action.payload
            }
        case GET_SETTINGS_SERVICE_DETAILS_FAIL:
            return{
                ...state,
                serviceDetailLoading: false,
                serviceDetailError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setServiceDetailReducer = (state = { setServiceDetailResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_SETTINGS_SERVICE_DETAILS_REQUEST:
            return{
                ...state,
                setServiceDetailResponseLoading: true,
                setServiceDetailResponseError: null
            }
        case SET_SETTINGS_SERVICE_DETAILS_SUCCESS:
            return{
                ...state,
                setServiceDetailResponseLoading: false,
                setServiceDetailResponse: action.payload
            }
        case SET_SETTINGS_SERVICE_DETAILS_FAIL:
            return{
                ...state,
                setServiceDetailResponseLoading: false,
                setServiceDetailResponseError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getLocalizationReducer = (state = { localization: {} }, action: any) =>{
    switch(action.type){
        case GET_SETTINGS_LOCALIZATION_REQUEST:
            return{
                ...state,
                localizationLoading: true,
                localizationError: null
            }
        case GET_SETTINGS_LOCALIZATION_SUCCESS:
            return{
                ...state,
                localizationLoading: false,
                localization: action.payload
            }
        case GET_SETTINGS_LOCALIZATION_FAIL:
            return{
                ...state,
                localizationLoading: false,
                localizationError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setLocalizationReducer = (state = { setLocalizationResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_SETTINGS_LOCALIZATION_REQUEST:
            return{
                ...state,
                setLocalizationResponseLoading: true,
                setLocalizationResponseError: null
            }
        case SET_SETTINGS_LOCALIZATION_SUCCESS:
            return{
                ...state,
                setLocalizationResponseLoading: false,
                setLocalizationResponse: action.payload
            }
        case SET_SETTINGS_LOCALIZATION_FAIL:
            return{
                ...state,
                setLocalizationResponseLoading: false,
                setLocalizationResponseError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const uploadImageReducer = (state = { uploadResponse: {} }, action: any) =>{
    switch(action.type){
        case UPLOAD_IMAGE_REQUEST:
            return{
                ...state,
                uploadResponseLoading: true,
                uploadResponseError: null
            }
        case UPLOAD_IMAGE_SUCCESS:
            return{
                ...state,
                uploadResponseLoading: false,
                uploadResponse: action.payload
            }
        case UPLOAD_IMAGE_FAIL:
            return{
                ...state,
                uploadResponseLoading: false,
                uploadResponseError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadTaxesReducer = (state = { taxes: {} }, action: any) =>{
    switch(action.type){
        case GET_TAXES_REQUEST:
            return{
                ...state,
                taxesLoading: true,
                taxesError: null
            }
        case GET_TAXES_SUCCESS:
            return{
                ...state,
                taxesLoading: false,
                taxes: action.payload
            }
        case GET_TAXES_FAIL:
            return{
                ...state,
                taxesLoading: false,
                taxesError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const createTaxReducer = (state = { createTaxMessage: {} }, action: any) =>{
    switch(action.type){
        case CREATE_TAX_REQUEST:
            return{
                ...state,
                createTaxMessageLoading: true,
                createTaxMessageError: null
            }
        case CREATE_TAX_SUCCESS:
            return{
                ...state,
                createTaxMessageLoading: false,
                createTaxMessage: action.payload
            }
        case CREATE_TAX_FAIL:
            return{
                ...state,
                createTaxMessageLoading: false,
                createTaxMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const deleteTaxReducer = (state = { deleteTaxMessage: {} }, action: any) =>{
    switch(action.type){
        case DELETE_TAX_REQUEST:
            return{
                ...state,
                deleteTaxMessageLoading: true,
                deleteTaxMessageError: null
            }
        case DELETE_TAX_SUCCESS:
            return{
                ...state,
                deleteTaxMessageLoading: false,
                deleteTaxMessage: action.payload
            }
        case DELETE_TAX_FAIL:
            return{
                ...state,
                deleteTaxMessageLoading: false,
                deleteTaxMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}





//orders
export const loadOrdersReducer = (state = { orders: {} }, action: any) =>{
    switch(action.type){
        case GET_ORDERS_BY_STATUS_REQUEST:
            return{
                ...state,
                ordersLoading: true,
                ordersError: null
            }
        case GET_ORDERS_BY_STATUS_SUCCESS:
            return{
                ...state,
                ordersLoading: false,
                orders: action.payload
            }
        case GET_ORDERS_BY_STATUS_FAIL:
            return{
                ...state,
                ordersLoading: false,
                ordersError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadOrderByIdReducer = (state = { order: {} }, action: any) =>{
    switch(action.type){
        case GET_ORDER_BY_ID_REQUEST:
            return{
                ...state,
                orderLoading: true,
                orderError: null
            }
        case GET_ORDER_BY_ID_SUCCESS:
            return{
                ...state,
                orderLoading: false,
                order: action.payload
            }
        case GET_ORDER_BY_ID_FAIL:
            return{
                ...state,
                orderLoading: false,
                orderError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadInvoiceReducer = (state = { invoice: {} }, action: any) =>{
    switch(action.type){
        case GET_INVOICE_REQUEST:
            return{
                ...state,
                invoiceLoading: true,
                invoiceError: null
            }
        case GET_INVOICE_SUCCESS:
            return{
                ...state,
                invoiceLoading: false,
                invoice: action.payload
            }
        case GET_INVOICE_FAIL:
            return{
                ...state,
                invoiceLoading: false,
                invoiceError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const changeOrderStatusReducer = (state = { changeOrderStatus: {} }, action: any) =>{
    switch(action.type){
        case ORDER_STATUS_CHANGE_REQUEST:
            return{
                ...state,
                changeOrderStatusLoading: true,
                changeOrderStatusError: null
            }
        case ORDER_STATUS_CHANGE_SUCCESS:
            return{
                ...state,
                changeOrderStatusLoading: false,
                changeOrderStatus: action.payload
            }
        case ORDER_STATUS_CHANGE_FAIL:
            return{
                ...state,
                changeOrderStatusLoading: false,
                changeOrderStatusError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const changeOrderStaffReducer = (state = { changeOrderStaff: {} }, action: any) =>{
    switch(action.type){
        case CHANGE_ORDER_STAFF_REQUEST:
            return{
                ...state,
                changeOrderStaffLoading: true,
                changeOrderStaffError: null
            }
        case CHANGE_ORDER_STAFF_SUCCESS:
            return{
                ...state,
                changeOrderStaffLoading: false,
                changeOrderStaff: action.payload
            }
        case CHANGE_ORDER_STAFF_FAIL:
            return{
                ...state,
                changeOrderStaffLoading: false,
                changeOrderStaffError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadAdjustmentsReducer = (state = { adjustments: {} }, action: any) =>{
    switch(action.type){
        case GET_ADJUSTMENTS_REQUEST:
            return{
                ...state,
                adjustmentsLoading: true,
                adjustmentsError: null
            }
        case GET_ADJUSTMENTS_SUCCESS:
            return{
                ...state,
                adjustmentsLoading: false,
                adjustments: action.payload
            }
        case GET_ADJUSTMENTS_FAIL:
            return{
                ...state,
                adjustmentsLoading: false,
                adjustmentsError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setAdjustmentReducer = (state = { setAdjustmentMessage: {} }, action: any) =>{
    switch(action.type){
        case SET_ADJUSTMENTS_REQUEST:
            return{
                ...state,
                setAdjustmentMessageLoading: true,
                setAdjustmentMessageError: null
            }
        case SET_ADJUSTMENTS_SUCCESS:
            return{
                ...state,
                setAdjustmentMessageLoading: false,
                setAdjustmentMessage: action.payload
            }
        case SET_ADJUSTMENTS_FAIL:
            return{
                ...state,
                setAdjustmentMessageLoading: false,
                setAdjustmentMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadEmailStatsReducer = (state = { emailStats: {} }, action: any) =>{
    switch(action.type){
        case GET_EMAILS_STATS_REQUEST:
            return{
                ...state,
                emailStatsLoading: true,
                emailStatsError: null
            }
        case GET_EMAILS_STATS_SUCCESS:
            return{
                ...state,
                emailStatsLoading: false,
                emailStats: action.payload
            }
        case GET_EMAILS_STATS_FAIL:
            return{
                ...state,
                emailStatsLoading: false,
                emailStatsError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const sendEmailsReducer = (state = { sendEmailMessage: {} }, action: any) =>{
    switch(action.type){
        case CREATE_EMAILS_REQUEST:
            return{
                ...state,
                sendEmailMessageLoading: true,
                sendEmailMessageError: null
            }
        case CREATE_EMAILS_SUCCESS:
            return{
                ...state,
                sendEmailMessageLoading: false,
                sendEmailMessage: action.payload
            }
        case CREATE_EMAILS_FAIL:
            return{
                ...state,
                sendEmailMessageLoading: false,
                sendEmailMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const editAdjustmentReducer = (state = { editAdjustmentMessage: {} }, action: any) =>{
    switch(action.type){
        case EDIT_ADJUSTMENTS_REQUEST:
            return{
                ...state,
                editAdjustmentMessageLoading: true,
                editAdjustmentMessageError: null
            }
        case EDIT_ADJUSTMENTS_SUCCESS:
            return{
                ...state,
                editAdjustmentMessageLoading: false,
                editAdjustmentMessage: action.payload
            }
        case EDIT_ADJUSTMENTS_FAIL:
            return{
                ...state,
                editAdjustmentMessageLoading: false,
                editAdjustmentMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const deleteAdjustmentReducer = (state = { deleteAdjustmentMessage: {} }, action: any) =>{
    switch(action.type){
        case DELETE_ADJUSTMENTS_REQUEST:
            return{
                ...state,
                deleteAdjustmentMessageLoading: true,
                deleteAdjustmentMessageError: null
            }
        case DELETE_ADJUSTMENTS_SUCCESS:
            return{
                ...state,
                deleteAdjustmentMessageLoading: false,
                deleteAdjustmentMessage: action.payload
            }
        case DELETE_ADJUSTMENTS_FAIL:
            return{
                ...state,
                deleteAdjustmentMessageLoading: false,
                deleteAdjustmentMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

//marketting
export const loadPrintablesReducer = (state = { printables: {} }, action: any) =>{
    switch(action.type){
        case GET_PRINTABLES_REQUEST:
            return{
                ...state,
                printablesLoading: true,
                printablesError: null
            }
        case GET_PRINTABLES_SUCCESS:
            return{
                ...state,
                printablesLoading: false,
                printables: action.payload
            }
        case GET_PRINTABLES_FAIL:
            return{
                ...state,
                printablesLoading: false,
                printablesError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadAnnouncementsReducer = (state = { announcements: {} }, action: any) =>{
    switch(action.type){
        case GET_ANNOUNCEMENTS_REQUEST:
            return{
                ...state,
                announcementsLoading: true,
                announcementsError: null
            }
        case GET_ANNOUNCEMENTS_SUCCESS:
            return{
                ...state,
                announcementsLoading: false,
                announcements: action.payload
            }
        case GET_ANNOUNCEMENTS_FAIL:
            return{
                ...state,
                announcementsLoading: false,
                announcementsError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const createAnnouncementReducer = (state = { createAnnouncement: {} }, action: any) =>{
    switch(action.type){
        case CREATE_ANNOUNCEMENT_REQUEST:
            return{
                ...state,
                createAnnouncementLoading: true,
                createAnnouncementError: null
            }
        case CREATE_ANNOUNCEMENT_SUCCESS:
            return{
                ...state,
                createAnnouncementLoading: false,
                createAnnouncement: action.payload
            }
        case CREATE_ANNOUNCEMENT_FAIL:
            return{
                ...state,
                createAnnouncementLoading: false,
                createAnnouncementError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadProductsBySubcategoryReducer = (state = { productsBySubcategory: {} }, action: any) =>{
    switch(action.type){
        case LOAD_PRODUCTS_BY_SUBCATEGORY_REQUEST:
            return{
                ...state,
                productsBySubcategoryLoading: true,
                productsBySubcategoryError: null
            }
        case LOAD_PRODUCTS_BY_SUBCATEGORY_SUCCESS:
            return{
                ...state,
                productsBySubcategoryLoading: false,
                productsBySubcategory: action.payload
            }
        case LOAD_PRODUCTS_BY_SUBCATEGORY_FAIL:
            return{
                ...state,
                productsBySubcategoryLoading: false,
                productsBySubcategoryError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadThemesReducer = (state = { themes: {} }, action: any) =>{
    switch(action.type){
        case GET_THEMES_REQUEST:
            return{
                ...state,
                themesLoading: true,
                themesError: null
            }
        case GET_THEMES_SUCCESS:
            return{
                ...state,
                themesLoading: false,
                themes: action.payload
            }
        case GET_THEMES_FAIL:
            return{
                ...state,
                themesLoading: false,
                themesError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setThemeReducer = (state = { setThemeResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_THEME_REQUEST:
            return{
                ...state,
                setThemeLoading: true,
                setThemeError: null
            }
        case SET_THEME_SUCCESS:
            return{
                ...state,
                setThemeLoading: false,
                setThemeResponse: action.payload
            }
        case SET_THEME_FAIL:
            return{
                ...state,
                setThemeLoading: false,
                setThemeError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadBrandingColorsReducer = (state = { brandingColors: {} }, action: any) =>{
    switch(action.type){
        case GET_BRANDING_COLORS_REQUEST:
            return{
                ...state,
                brandingColorsLoading: true,
                brandingColorsError: null
            }
        case GET_BRANDING_COLORS_SUCCESS:
            return{
                ...state,
                brandingColorsLoading: false,
                brandingColors: action.payload
            }
        case GET_BRANDING_COLORS_FAIL:
            return{
                ...state,
                brandingColorsLoading: false,
                brandingColorsError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setBrandingColorReducer = (state = { setBrandingColorResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_BRANDING_COLOR_REQUEST:
            return{
                ...state,
                setBrandingColorLoading: true,
                setBrandingColorError: null
            }
        case SET_BRANDING_COLOR_SUCCESS:
            return{
                ...state,
                setBrandingColorLoading: false,
                setBrandingColorResponse: action.payload
            }
        case SET_BRANDING_COLOR_FAIL:
            return{
                ...state,
                setBrandingColorLoading: false,
                setBrandingColorError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getSalesReducer = (state = { sales: {} }, action: any) =>{
    switch(action.type){
        case GET_SALES_REQUEST:
            return{
                ...state,
                salesLoading: true,
                salesError: null
            }
        case GET_SALES_SUCCESS:
            return{
                ...state,
                salesLoading: false,
                sales: action.payload
            }
        case GET_SALES_FAIL:
            return{
                ...state,
                salesLoading: false,
                salesError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getVisitorsReducer = (state = { visitors: {} }, action: any) =>{
    switch(action.type){
        case GET_VISITORS_REQUEST:
            return{
                ...state,
                visitorsLoading: true,
                visitorsError: null
            }
        case GET_VISITORS_SUCCESS:
            return{
                ...state,
                visitorsLoading: false,
                visitors: action.payload
            }
        case GET_VISITORS_FAIL:
            return{
                ...state,
                visitorsLoading: false,
                visitorsError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getCartReducer = (state = { cart: {} }, action: any) =>{
    switch(action.type){
        case GET_CART_REQUEST:
            return{
                ...state,
                cartLoading: true,
                cartError: null
            }
        case GET_CART_SUCCESS:
            return{
                ...state,
                cartLoading: false,
                cart: action.payload
            }
        case GET_CART_FAIL:
            return{
                ...state,
                cartLoading: false,
                cartsError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setPushNotificationReducer = (state = { setPushNotificationResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_PUSH_NOTIFICATION_REQUEST:
            return{
                ...state,
                setPushNotificationLoading: true,
                setPushNotificationError: null
            }
        case SET_PUSH_NOTIFICATION_SUCCESS:
            return{
                ...state,
                setPushNotificationLoading: false,
                setPushNotificationResponse: action.payload
            }
        case SET_PUSH_NOTIFICATION_FAIL:
            return{
                ...state,
                setPushNotificationLoading: false,
                setPushNotificationError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const deletePushNotificationReducer = (state = { deletePushNotificationMessage: {} }, action: any) =>{
    switch(action.type){
        case DELETE_PUSH_NOTIFICATION_REQUEST:
            return{
                ...state,
                deletePushNotificationLoading: true,
                deletePushNotificationError: null
            }
        case DELETE_PUSH_NOTIFICATION_SUCCESS:
            return{
                ...state,
                deletePushNotificationLoading: false,
                deletePushNotificationMessage: action.payload
            }
        case DELETE_PUSH_NOTIFICATION_FAIL:
            return{
                ...state,
                deletePushNotificationLoading: false,
                deletePushNotificationError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}
// for communications
export const getCommunicationReducer = (state = { getCommunication: {} }, action: any) =>{
    switch(action.type){
        case GET_COMMUNICATIONS_REQUEST:
            return{
                ...state,
                getCommunicationLoading: true,
                getCommunicationError: null
            }
        case GET_COMMUNICATIONS_SUCCESS:
            return{
                ...state,
                getCommunicationLoading: false,
                getCommunication: action.payload
            }
        case GET_COMMUNICATIONS_FAIL:
            return{
                ...state,
                getCommunicationLoading: false,
                getCommunicationError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const postCommunicationReducer = (state = { postCommunication: {} }, action: any) =>{
    switch(action.type){
        case POST_COMMUNICATIONS_REQUEST:
            return{
                ...state,
                postCommunicationLoading: true,
                postCommunicationError: null
            }
        case POST_COMMUNICATIONS_SUCCESS:
            return{
                postCommunicationLoading: false,
                postCommunication: action.payload
            }
        case POST_COMMUNICATIONS_FAIL:
            return{
                ...state,
                postCommunicationLoading: false,
                postCommunicationError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const postTagLineReducer = (state = {postTagLine:{}}, action) => {
    switch (action.type) {
      case "POST_TAGLINE_REQUEST":
        return {
          ...state,
          postTagLineLoading: true,
          postTagLineError:null
        };
      case "POST_TAGLINE_SUCCESS":
        return {
          ...state,
          postTagLineLoading: false,
          postTagLine: action.payload
        };
      case "POST_TAGLINE_FAIL":
        return {
          ...state,
          postTagLineLoading: false,
          postTagLineError: action.payload
        };
      default:
        return state;
    }
  };
export const getTagLineReducer = (state = { TagLine: {} }, action: any) =>{
    switch(action.type){
        case GET_TAGLINE_REQUEST:
            return{
                ...state,
                TagLineLoading: true,
                TagLineError: null
            }
        case GET_TAGLINE_SUCCESS:
            return{
                ...state,
                TagLineLoading: false,
                TagLine: action.payload
            }
        case GET_TAGLINE_FAIL:
            return{
                ...state,
                TagLineLoading: false,
                TagLineError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getCashfreeReducer = (state = { cashfree: {} }, action: any) =>{
    switch(action.type){
        case GET_SETTINGS_CASHFREE_REQUEST:
            return{
                ...state,
                cashfreeLoading: true,
                cashfreeError: null
            }
        case GET_SETTINGS_CASHFREE_SUCCESS:
            return{
                ...state,
                cashfreeLoading: false,
                cashfree: action.payload
            }
        case GET_SETTINGS_CASHFREE_FAIL:
            return{
                ...state,
                cashfreeLoading: false,
                cashfreeError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setCashfreeReducer = (state = { setCashfreeResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_SETTINGS_CASHFREE_REQUEST:
            return{
                ...state,
                setCashfreeResponseLoading: true,
                setCashfreeResponseError: null
            }
        case SET_SETTINGS_CASHFREE_SUCCESS:
            return{
                ...state,
                setCashfreeResponseLoading: false,
                setCashfreeResponse: action.payload
            }
        case SET_SETTINGS_CASHFREE_FAIL:
            return{
                ...state,
                setCashfreeResponseLoading: false,
                setCashfreeResponseError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getPhonepeReducer = (state = { phonepe: {} }, action: any) =>{
    switch(action.type){
        case GET_SETTINGS_PHONEPE_REQUEST:
            return{
                ...state,
                phonepeLoading: true,
                phonepeError: null
            }
        case GET_SETTINGS_PHONEPE_SUCCESS:
            return{
                ...state,
                phonepeLoading: false,
                phonepe: action.payload
            }
        case GET_SETTINGS_PHONEPE_FAIL:
            return{
                ...state,
                phonepeLoading: false,
                phonepeError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setPhonepeReducer = (state = { setPhonepeResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_SETTINGS_PHONEPE_REQUEST:
            return{
                ...state,
                setPhonepeResponseLoading: true,
                setPhonepeResponseError: null
            }
        case SET_SETTINGS_PHONEPE_SUCCESS:
            return{
                ...state,
                setPhonepeResponseLoading: false,
                setPhonepeResponse: action.payload
            }
        case SET_SETTINGS_PHONEPE_FAIL:
            return{
                ...state,
                setPhonepeResponseLoading: false,
                setPhonepeResponseError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}
  
 export const dynamicMessagereducer = (state = { setDynamicMessage: {} }, action) => {
    switch (action.type) {
      case FETCH_SUGGESTIONS_REQUEST:
        return {
          ...state,
          setDynamicMessageloading: true,
          setDynamicMessageerror: null,
        };
      case FETCH_SUGGESTIONS_SUCCESS:
        return {
          ...state,
          setDynamicMessage: action.payload,
          setDynamicMessageloading: false,
        };
      case FETCH_SUGGESTIONS_FAILURE:
        return {
          ...state,
          setDynamicMessageloading: false,
          setDynamicMessageerror: action.payload,
        };
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
      default:
        return state;
    }
  };
  
  //social profile reuducer 
  export const postSocialProfileReducer = (state = {postSocialProfile:{}}, action) => {
    switch (action.type) {
      case "POST_SOCIAL_PROFILE_REQUEST":
        return {
          ...state,
          postSocialProfileLoading: true,
          postSocialProfileError:null
        };
      case "POST_SOCIAL_PROFILE_SUCCESS":
        return {
          ...state,
          postSocialProfileLoading: false,
          postSocialProfile: action.payload
        };
      case "POST_SOCIAL_PROFILE_FAILURE":
        return {
          ...state,
          postSocialProfileLoading: false,
          postSocialProfileError: action.payload
        };
      default:
        return state;
    }
  };

  export const getSocialProfileReducer = (state = {getSocialProfile:{}}, action) => {
    switch (action.type) {
      case "GET_SOCIAL_PROFILE_REQUEST":
        return {
          ...state,
          getSocialProfileLoading: true,
          getSocialProfileError:null
        };
      case "GET_SOCIAL_PROFILE_SUCCESS":
        return {
          ...state,
          getSocialProfileLoading: false,
          getSocialProfile: action.payload
        };
      case "GET_SOCIAL_PROFILE_FAILURE":
        return {
          ...state,
          getSocialProfileLoading: false,
          getSocialProfileError: action.payload
        };
      default:
        return state;
    }
};

   //Delivery Message
 export const slotTimeReducer = (state = {postSlotTime:{}}, action) => {
    switch (action.type) {
      case "POST_DELIVERY_MESSAGE_REQUEST":
        return {
          ...state,
          postSlotTimeLoading: true,
          postSlotTimeError:null
        };
      case "POST_DELIVERY_MESSAGE_SUCCESS":
        return {
          ...state,
          postSlotTimeLoading: false,
          postSlotTime: action.payload
        };
      case "POST_DELIVERY_MESSAGE_FAILURE":
        return {
          ...state,
          postSlotTimeLoading: false,
          postSlotTimeError: action.payload
        };
      default:
        return state;
    }
  };
  export const createDeliveryOrder = (state = { createDeliveryOrder: {} }, action: any) =>{
    switch(action.type){
        case POST_DELIVERY_ORDER_REQUEST:
            return{
                ...state,
                createDeliveryOrderMessageLoading: true,
                createDeliveryOrderMessageError: null
            }
        case POST_DELIVERY_ORDER_SUCCESS:
            return{
                ...state,
                createDeliveryOrderMessageLoading: false,
                createDeliveryOrder: action.payload
            }
        case POST_DELIVERY_ORDER_FAIL:
            return{
                ...state,
                createDeliveryOrderMessageLoading: false,
                createDeliveryOrderMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const loadDeliveryOrder = (state = { zipcodes: {} }, action: any) =>{
    switch(action.type){
        case GET_DELIVERY_ORDER_REQUEST:
            return{
                ...state,
                zipcodesLoading: true,
                zipcodesError: null
            }
        case GET_DELIVERY_ORDER_SUCCESS:
            return{
                ...state,
                zipcodesLoading: false,
                zipcodes: action.payload
            }
        case GET_DELIVERY_ORDER_FAIL:
            return{
                ...state,
                zipcodesLoading: false,
                zipcodesError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const deleteDeliveryOrderReducer = (state = { deletezipcode: {} }, action: any) =>{
    switch(action.type){
        case DELETE_DELIVERY_ORDER_REQUEST:
            return{
                ...state,
                deletezipcodeLoading: true,
                deletezipcodeError: null
            }
        case DELETE_DELIVERY_ORDER_SUCCESS:
            return{
                ...state,
                deletezipcodeLoading: false,
                deletezipcode: action.payload
            }
        case DELETE_DELIVERY_ORDER_FAIL:
            return{
                ...state,
                deletezipcodeLoading: false,
                deletezipcodeError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const updateDeliveryOrderReducer = (state = { updatezipcode: {} }, action: any) =>{
    switch(action.type){
        case UPDATE_DELIVERY_ORDER_REQUEST:
            return{
                ...state,
                updatezipcodeLoading: true,
                updatezipcodeError: null
            }
        case UPDATE_DELIVERY_ORDER_SUCCESS:
            return{
                ...state,
                updatezipcodeLoading: false,
                updatezipcode: action.payload
            }
        case UPDATE_DELIVERY_ORDER_FAIL:
            return{
                ...state,
                updatezipcodeLoading: false,
                updatezipcodeError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}