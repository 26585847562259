import { IonPage, IonRouterOutlet } from '@ionic/react';
import { useState } from 'react';
import { Route } from 'react-router';
import AddUpdateCategory from '../../pages/AddUpdateCategory';
import Catalogue from '../../pages/Catalogue';
import ProtectedRoute from '../../route/ProtectedRoute';
import { BrandsContext, CategoryContext, useBrands } from '../../utils/contexts';
import useFetch from '../../utils/useFetch';
import AddProduct from '../product/AddProduct';
import BulkProductList from '../product/AddProductBulk/BulkProductList';
import ChooseCategory from '../product/AddReadymadeProduct/ChooseCategory';
import PreDefineProductList from '../product/AddReadymadeProduct/ReadymadeProductList';
import MasterScreen from '../product/Master/MasterScreen';
import ProductsHomeScreen from '../product/ProductsHomeScreen';
import ProductBrands from '../../pages/ProductBrands';
import AddUpdateBrand from '../../pages/AddUpdateBrand';
import InventoryStocks from '../product/InventoryStocks';


const CatalogueRoutes = () => {
    const [updatedCategoryData, setUpdatedCategoryData] = useState(false);
    const {data:data, status, loading} = useFetch("categories", "all", updatedCategoryData);
    const [updatedBrandsData, setUpdatedBrandsData] = useState(false);
    const [updatedBrandsId, setUpdatedBrandsId] = useState({
        id: "",
        refresh: true
    });
    const [updatedCategoryId, setUpdatedCategoryId] = useState({
        id: "",
        refresh: true
    }); 
    const brandsdata = useFetch(
        "Brands",
        updatedBrandsData 
      );
 
     

    return (
        <CategoryContext.Provider value={{updatedCategoryId, setUpdatedCategoryId, categories: data?.data, status: status, loading: loading, updatedCategoryData, setUpdatedCategoryData}}>
            <BrandsContext.Provider value={{updatedBrandsId,setUpdatedBrandsId,brands: brandsdata?.data?.data,status: brandsdata.status, loading: brandsdata.loading, updatedBrandsData,setUpdatedBrandsData}}>
            <IonPage>
                <IonRouterOutlet>
                    <ProtectedRoute path="/catalogue/categories" component={Catalogue} exact={true} />
                    <ProtectedRoute path="/catalogue/products" component={ProductsHomeScreen} exact={true} />
                    <ProtectedRoute path="/catalogue/products/update/:id" component={AddProduct} exact={true} />
                    <ProtectedRoute path="/catalogue/products/create/:fp" component={AddProduct} exact={true} />
                    <ProtectedRoute path="/catalogue/master/products" component={MasterScreen} exact={true} />
                    <ProtectedRoute path="/catalogue/products/addreadymadeproduct/choosecategory" component={ChooseCategory} exact={true} />
                    <ProtectedRoute path="/catalogue/products/addreadymadeproduct/productlist" component={PreDefineProductList} exact={true} />
                    <ProtectedRoute path="/catalogue/products/addproductbulk" component={BulkProductList} exact={true} />
                    <ProtectedRoute path="/catalogue/brands/" component={ProductBrands} exact={true} />
                    <ProtectedRoute path="/catalogue/inventory" component={InventoryStocks} exact={true} />
                   
                    <Route path="/catalogue/brands/add-brands" exact={true}>
                        
                        <AddUpdateBrand />
                       
                    </Route>

<Route path="/catalogue/brands/update-brands/:id" exact={true}>
 
                            <AddUpdateBrand />
                          
                    </Route>
                    <Route path="/catalogue/category/add-cat/:fp" exact={true}>
                        <AddUpdateCategory />
                    </Route>

                    <Route path="/catalogue/category/add-subcat/:id/:name/:fp" exact={true}>
                        <AddUpdateCategory />
                    </Route>

                    <Route path="/catalogue/category/update-cat/:id" exact={true}>
                        <AddUpdateCategory />
                    </Route>

                    <Route path="/catalogue/category/update-subcat/:id" exact={true}>
                        <AddUpdateCategory />
                    </Route>

                </IonRouterOutlet >
            </IonPage >
            </BrandsContext.Provider>
        </CategoryContext.Provider >
    )
}

export default CatalogueRoutes
