import { GET_MEDIA_IMAGE_REQUEST,GET_MEDIA_IMAGE_FAIL,GET_MEDIA_IMAGE_SUCCESS, UPLOAD_MEDIA_IMAGE_REQUEST, UPLOAD_MEDIA_IMAGE_SUCCESS, UPLOAD_MEDIA_IMAGE_FAIL, DELETE_MEDIA_IMAGE_FAIL, DELETE_MEDIA_IMAGE_SUCCESS, DELETE_MEDIA_IMAGE_REQUEST
 } from "../constants/mediaConstants"

export const getMediaImageReducer = (state = {mediaLibrary:{}}, action: any) => {
    switch(action.type){
        case GET_MEDIA_IMAGE_REQUEST:
            return{
                ...state,
                mediaLibraryLoading: true,
                mediaLibraryErrors: null
            }
        case GET_MEDIA_IMAGE_SUCCESS:
            return{
                ...state,
                mediaLibraryLoading: false,
                mediaLibrary: action.payload
            }
        case GET_MEDIA_IMAGE_FAIL:
            return{
                ...state,
                mediaLibraryLoading: false,
                mediaLibraryErrors: action.payload
            }
            default:
            return state

    }


}

export const uploadMediaImageReducer = (state = {uploadmediaLibrary:{}}, action: any) => {
    switch(action.type){
        case UPLOAD_MEDIA_IMAGE_REQUEST:
            return{
                ...state,
                uploadmediaLibraryLoading: true,
                uploadmediaLibraryErrors: null
            }
        case UPLOAD_MEDIA_IMAGE_SUCCESS:
            return{
                ...state,
                uploadmediaLibraryLoading: false,
                uploadmediaLibrary: action.payload
            }
        case UPLOAD_MEDIA_IMAGE_FAIL:
            return{
                ...state,
                uploadmediaLibraryLoading: false,
                uploadmediaLibraryErrors: action.payload
            }
            default:
            return state
    }

}

export const deleteMediaImageReducer = (state = {deletemediaLibrary:{}}, action: any) => {
    switch(action.type){
        case DELETE_MEDIA_IMAGE_REQUEST:
            return{
                ...state,
                deletemediaLibraryLoading: true,
                deletemediaLibraryErrors: null
            }
        case DELETE_MEDIA_IMAGE_SUCCESS:
            return{
                ...state,
                deletemediaLibraryLoading: false,
                deletemediaLibrary: action.payload
            }
        case DELETE_MEDIA_IMAGE_FAIL:
            return{
                ...state,
                deletemediaLibraryLoading: false,
                deletemediaLibraryErrors: action.payload
            }
            default:
                return state
    }

}