import { Directory } from '@capacitor/filesystem';
import { IonAlert, IonBadge, IonButton, IonCard, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonPopover, IonRow, IonSelect, IonText, IonThumbnail, isPlatform, useIonToast } from '@ionic/react';
import write_blob from 'capacitor-blob-writer';
import { add, addCircle as addCircleIcon, add as addIcon, checkmarkSharp as checkmarkSharpIcon, closeCircleOutline, closeOutline, createSharp as createSharpIcon, search as serachIcon, toggle, trashSharp as trashSharpIcon, helpCircleOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import { getStoresCategory } from '../../actions/categoryAction';
import { getProductsList } from '../../actions/dashboardActions';
import { bulkEditProduct, deleteProduct } from '../../actions/productActions';
import Header from '../../components/dashboard/Header';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import useAPI from '../../utils/api';
import { useCategory, useUser, useWebsiteSettings } from '../../utils/contexts';
import { StoreCurrency } from '../../utils/currency';
import { IonSelectOption } from '@ionic/react';
import { BrowserView, MobileView } from 'react-device-detect';


const ProductsHomeScreen = (props: any) => {
  const [showSelectCateModal, setShowSelectCateModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const currency = StoreCurrency();
  const updateCategoryData: any = useCategory();
  const location: any = useLocation();
  const API = useAPI();
  const settings: any = useWebsiteSettings();
  const [presentToast] = useIonToast();


  const [totalProducts, setTotalProducts] = useState({});

  //states for search product by name as query
  const [query, setQuery] = useState<any>("");
  const [searchResult, setSearchResult] = useState({
    categoryList: [],
    productList: [],
  });
  //states for displaying initial products and load more products.
  const [filterArrayObject, setFilterArrayObject] = useState({});
  const [subcategoryData, setSubcategoryData] = useState<any>({});

  //states for product short info
  const [checkedProducts, setCheckedProducts] = useState<any>([]);
  const [showAlertForMulti, setShowAlertForMulti] = useState(false);
  const [showAlertForSingle, setShowAlertForSingle] = useState(false);
  const [showAlertForStatus, setShowAlertForStatus] = useState(false);
  const [deletedProductId, setDeletedProductId] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [isAnyProductChange, setIsAnyProductChange] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [message, setMessage] = useState('');


  // var idFromProps;
  // var cateIdFromProps;
  // if(props && props.location && props.location.state){
  //     idFromProps = props.location.state;
  //     if(idFromProps.id){
  //         cateIdFromProps = idFromProps.id;
  //     }
  // }
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategoryId, setselectedSubcategoryId] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState({});

  // const [products, setProducts] = useState([]);
  // // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  




  const currentUser: any = useUser();
  const { fetchCategoriesLoading, categories, fetchCategoriesError } =
    useSelector((state: any) => state.categories);

  const {
    productsBySubcategoryLoading,
    productsBySubcategory,
    productsBySubcategoryError,
  } = useSelector((state: any) => state.productListBySubcategory);

  const { deleteProductLoading } = useSelector(
    (state: any) => state.deleteProductData
  );

  const { createProductLoading, createProductMessage, createProductError } =
    useSelector((state: any) => state.createProducData);

  const { editProductLoading, editProductMessage, editProductError } =
    useSelector((state: any) => state.editProductData);

  const { bulkEditLoading, bulkEditMessage, bulkEditError } = useSelector(
    (state: any) => state.bulkEditData
  );

  // console.log(productsBySubcategory);
  // console.log(subcategoryData);
  // console.log(totalProducts);
  

  const {
    addProductInBulkLoading,
    addProductInBulkMessage,
    addProductInBulkError,
  } = useSelector((state: any) => state.addProductInBulkData);

  // const {searchProductQueryLoading, searchProductQueryMessage, searchProductQueryError} = useSelector((state : any)=> state.searchQueryProductData);

  const dispatch = useDispatch();
  const history = useHistory();

  //youtube option
  const opts = {
    height: "390",
    width: "100%",
  };

  //for reloading when new product is added
  useEffect(() => {
    if (updateCategoryData.updatedCategoryId?.id) {
      let temp = { ...filterArrayObject };
      delete temp[`${updateCategoryData.updatedCategoryId?.id}`];
      if (
        updateCategoryData.updatedCategoryId?.id !==
        updateCategoryData.updatedCategoryId?.newId
      ) {
        delete temp[`${updateCategoryData.updatedCategoryId?.newId}`];
      }
      setFilterArrayObject(temp);
      setIsAnyProductChange(!isAnyProductChange);
    }
  }, [
    updateCategoryData.updatedCategoryId?.id,
    updateCategoryData.updatedCategoryId?.refresh,
  ]);

  // //for reloading when any product is edited
  // useEffect(() => {
  //     if(Boolean(editProductLoading === false && editProductMessage?.category_id)){
  //         let temp = {...filterArrayObject}
  //         //temp[`${editProductMessage[0].category_id}`] = []
  //         delete temp[`${editProductMessage?.category_id}`]
  //         setFilterArrayObject(temp);
  //         setIsAnyProductChange(!isAnyProductChange)
  //     }
  // }, [editProductLoading]);

  //for reloading when any product is edited in bulk
  useEffect(() => {
    if (Boolean(bulkEditLoading === false)) {
      setIsAnyProductChange(!isAnyProductChange);
    }
  }, [bulkEditLoading]);

  useEffect(() => {
    setQuery('');

  }, [history.location.pathname]);

  useEffect(() => {

      if (
        fetchCategoriesLoading === false &&
        categories[0] &&
        categories[0].subCategory
      ) {
        if (categories[0].subCategory[0]) {
          setSubcategoryData({
            ...categories[0].subCategory[0],
            parentCategory: categories[0].id,
          });
        } else {
          const data = categories.filter((cat) => {
            return cat.subCategory.length > 0;
          });
          if (data && data.length !== 0) {
            setSubcategoryData({
              ...data[0].subCategory[0],
              parentCategory: data[0].id,
            });
          }
        }
      }
  }, [fetchCategoriesLoading]);




 

  useEffect(() => {

    if (!categories && getAccessToken() && currentUser) {
      dispatch(
        getStoresCategory(getAccessToken(), currentUser.store.subdomain)
      );
    }
    if (
      getAccessToken() &&
      currentUser &&
      addProductInBulkLoading === false
    ) {
      dispatch(
        getStoresCategory(getAccessToken(), currentUser.store.subdomain)
      );
    }
  }, [currentUser, addProductInBulkLoading]);

  //dynamic content
  //when subcategory change then get their product for first time.
  useEffect(() => {

    if (currentUser) {
      setCurrentPage(0);

      setSelectAll(false);
      if (
        !!subcategoryData.id &&
        !!currentUser &&
        !query &&
        !(subcategoryData.id in filterArrayObject)
      ) {
        dispatch(
          getProductsList(subcategoryData.id, currentUser.store.subdomain)
        );
      }
      if (location && location.state && location.state.data) {
        stickyproduct();
      } else {
        setCheckedProducts([]);
      }
    }
  }, [subcategoryData, currentUser]);

  // useEffect(() => {
  //   if (productsBySubcategory && productsBySubcategory.meta) {
  //     setTotalProducts(productsBySubcategory.meta.pagination.total);
  //     setTotalPages(productsBySubcategory.meta.pagination.total_pages);
  //   }
  // }, [productsBySubcategory]);
  

  useEffect(() => {

    if (currentUser) {
      setCurrentPage(0);

      setSelectAll(false);
      if (
        !!subcategoryData.id &&
        !!currentUser &&
        !query &&
        !(subcategoryData.id in filterArrayObject)
      ) {
        dispatch(
          getProductsList(subcategoryData.id, currentUser.store.subdomain)
        );
      }
      if (!!subcategoryData.id && !!currentUser && query) {
        searchProductByName({ detail: { value: query } });
      }
      if (location && location.state && location.state.data) {
        stickyproduct();
      } else {
        setCheckedProducts([]);
      }
    }
  }, [isAnyProductChange]);


  // useEffect(() => {
  //   console.log('hello7');

  //   if (
  //     productsBySubcategoryLoading === false &&
  //     productsBySubcategory &&
  //     productsBySubcategory.data &&
  //     productsBySubcategory.meta
  //   ) {
  //     if (!totalProducts[subcategoryData.id]) {
  //       let totalTemp = { ...totalProducts };
  //       totalTemp[subcategoryData.id] =
  //         productsBySubcategory.meta.pagination.total;
  //       setTotalProducts(totalTemp);
  //     }
  //     if (productsBySubcategory.data.length > 0) {
  //       let filterArrayTemp = { ...filterArrayObject };
  //       const op = productsBySubcategory.data.reduce(function (acc, curr) {
  //         if (!acc[subcategoryData.id]) {
  //           acc[subcategoryData.id] = [];
  //         }
  //         acc[subcategoryData.id].push(curr);
  //         return acc;
  //       }, filterArrayTemp);
  //       setFilterArrayObject(op);
  //     }
  //     if (productsBySubcategory.data.length === 0) {
  //       let temp = { ...filterArrayObject };
  //       delete temp[subcategoryData.id];
  //       setFilterArrayObject(temp);
  //     }
  //   }
  // }, [productsBySubcategoryLoading]);
  useEffect(() => {

    if (
      productsBySubcategoryLoading === false &&
      productsBySubcategory &&
      productsBySubcategory.data &&
      productsBySubcategory.meta
    ) {
      const totalItems = productsBySubcategory.meta.pagination.total;
      const itemsPerPage = productsBySubcategory.meta.pagination.per_page;
      const totalPages = Math.ceil(totalItems / itemsPerPage);

      // Update totalProducts object to store both total items and total pages
      if (!totalProducts[subcategoryData.id]) {
        let totalTemp = { ...totalProducts };
        totalTemp[subcategoryData.id] = {
          totalItems: totalItems,
          totalPages: totalPages
        };
        setTotalProducts(totalTemp);
      }

      if (productsBySubcategory.data.length > 0) {
        let filterArrayTemp = { ...filterArrayObject };
        const op = productsBySubcategory.data.reduce(function (acc, curr) {
          if (!acc[subcategoryData.id]) {
            acc[subcategoryData.id] = [];
          }
          acc[subcategoryData.id].push(curr);
          return acc;
        }, filterArrayTemp);
        setFilterArrayObject(op);
      }

      if (productsBySubcategory.data.length === 0) {
        let temp = { ...filterArrayObject };
        delete temp[subcategoryData.id];
        setFilterArrayObject(temp);
      }
    }
  }, [productsBySubcategoryLoading]);


  // useEffect(() => {
  //     if(idFromProps && props.location.state){
  //         setSubcategoryData({
  //             id: idFromProps.id,
  //             name: idFromProps.name
  //         });
  //     }
  // }, [idFromProps, cateIdFromProps])

  useEffect(() => {

    if (location && location.state && location.state.data) {
      stickyproduct();
    }
  }, [location && location.state && location.state.data]);
  async function stickyproduct() {
    await setCheckedProducts(location.state.data);
  }

  const handelstickyproduct = async () => {

    let data = { product_id: checkedProducts };
    const [ok, response] = await API.updateStickyProduct(data);

    if (ok && response) {
      presentToast("Sticky Product Added successful!", 1000);

      history.push({ pathname: "/appearance/ik-web-settings", state: "true" });
    }
  };

  console.log(filterArrayObject);
  const handlePageClick = async (pageNumber) => {

    setCurrentPage(pageNumber);
    setLoading(true);
    if (
      subcategoryData.id in filterArrayObject &&
      filterArrayObject[`${subcategoryData.id}`].length <= pageNumber * 20
    ) {
      let response = await fetch(
        `https://api2.intelikart.in/search/products?page=${pageNumber + 1
        }&categoryId=${subcategoryData.id}&includeall=true`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${getAccessToken()}`,
            "x-ik-domain": `${currentUser.store.subdomain}`,
          },
        }
      );

      response
        .json()
        .then(async (res) => {
          if (res && res.data && res.data.length > 0) {
            let filterArrayTemp = { ...filterArrayObject };
            const op = res.data.reduce(function (acc, curr) {
              if (!acc[subcategoryData.id]) {
                acc[subcategoryData.id] = [];
              }
              acc[subcategoryData.id].push(curr);
              return acc;
            }, filterArrayTemp);
            setFilterArrayObject(op);
          }
        })
        .catch((err) => console.error(err));
    }
    setLoading(false);
  };

  const handelChecked = async (productId) => {

    if (checkedProducts.includes(productId)) {
      let temp = [...checkedProducts];
      temp = temp.filter((elem) => productId !== elem);
      await setCheckedProducts(temp);
      setSelectAll(false);
    } else {
      let temp = [...checkedProducts];
      temp.push(productId);
      await setCheckedProducts(temp);

      if (temp.length === filterArrayObject[subcategoryData.id].length) {
        setSelectAll(true);
      }
    }
  };

  const handelSelectAll = async () => {

    const newSelectAllState = !selectAll;
    setSelectAll(newSelectAllState);

    if (newSelectAllState) {
      let temp = [];
      if (filterArrayObject[subcategoryData.id] && filterArrayObject[subcategoryData.id].length > 0) {
        filterArrayObject[subcategoryData.id].forEach((x) => {
          temp.push(x.id);
        });
      }
      await setCheckedProducts(temp);
    } else {
      await setCheckedProducts([]);
    }
  };

  const deleteAllSelected = async () => {

    if (getAccessToken() && currentUser) {
      setLoading(true);
      for (const value of checkedProducts) {
        await dispatch(
          deleteProduct(
            getAccessToken(),
            currentUser.store.subdomain,
            value,
            currentUser.store.subdomain
          )
        );
      }
      let temp = { ...filterArrayObject };
      delete temp[`${subcategoryData.id}`];
      setFilterArrayObject(temp);
      setCheckedProducts([]);
      setLoading(false);
      setIsAnyProductChange(!isAnyProductChange);
    }
  };

  const deleteSingleProduct = async () => {

    if (getAccessToken() && currentUser && deletedProductId) {
      await dispatch(
        deleteProduct(
          getAccessToken(),
          currentUser.store.subdomain,
          deletedProductId,
          currentUser.store.subdomain
        )
      );
      let temp = { ...filterArrayObject };
      delete temp[`${subcategoryData.id}`];
      setFilterArrayObject(temp);
      setIsAnyProductChange(!isAnyProductChange);
    }
  };

  const bulkEditHandler = async (value: any) => {

    let products = [];
    checkedProducts.map((x) => {
      products.push({
        id: x,
        status: `${value}`,
      });
    });
    const bulkEditData = {
      products: products,
    };
    if (getAccessToken() && currentUser) {
      setLoading(true);
      await dispatch(
        bulkEditProduct(
          bulkEditData,
          currentUser.store.subdomain,
          getAccessToken()
        )
      );
      let temp = { ...filterArrayObject };
      delete temp[`${subcategoryData.id}`];
      setFilterArrayObject(temp);
      setCheckedProducts([]);
      setSelectAll(false);
      setLoading(false);
      setIsAnyProductChange(!isAnyProductChange);
    }
  };
 

  

  const searchProductByName = async (query, subcategoryId = null) => {
    console.log('hello16');

    setLoadingSearch(true);
    setMessage('');

    if (query) {
      if (currentUser) {
        const response = await fetch(
          `https://api2.intelikart.in/search/products?query=${query}&includeall=true`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${getAccessToken()}`,
              "x-ik-domain": `${currentUser.store.subdomain}`,
            },
          }
        );
        const data = await response.json();
        let result = [];
        for (const parentCategory of categories) {
          let subCate = [];
          for (const childCategory of parentCategory.subCategory) {
            if (subcategoryId && childCategory.id !== subcategoryId) continue;
            for (const searchProduct of data.data) {
              if (searchProduct.category_id === childCategory.id) {
                subCate.push(childCategory);
                break;
              }
            }
          }
          if (subCate.length !== 0) {
            let temp = { ...parentCategory };
            temp["subcategories"] = subCate;
            result.push(temp);
          }
        }


        if (result.length > 0) {
          setSubcategoryData({
            ...result[0]?.subcategories[0],
            parentCategory: result[0]?.id,
          });
        } else {
          setSubcategoryData({
            ...selectedSubcategory,
            parentCategory: selectedCategory?.id,
          });
        }

        
        let temp = { ...searchResult };
        temp.categoryList = result;
        const productList = data.data.reduce((acc, curr) => {
          if (acc[curr.category_id]) {
            acc[curr.category_id].push(curr);
          } else {
            acc[curr.category_id] = [];
            acc[curr.category_id].push(curr);
          }
          return acc;
        }, {});
        temp.productList = productList;
        setSearchResult(temp);

        if (data.data.length === 0) {
          setMessage('This product not available');
        }
      }
      }
     

    setLoadingSearch(false);
  };




  const handleExport = async () => {
    console.log('hello17');

    try {
      setLoadingExport(true);
      let response = await fetch(`https://api2.intelikart.in/products`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${getAccessToken()}`,
          "x-ik-domain": `${currentUser.store.subdomain}`,
        },
      });

      if (response && response.status == 200) {
        const blob = await response.blob();
        if (isPlatform("capacitor")) {
          try {
            write_blob({
              path: `products.xlsx`,
              directory: Directory.Documents,
              blob: blob,
            });
          } catch (err) {
            presentToast("Error downloading file", 5000);
            return;
          }
        } else {
          let a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = "products.xlsx";
          a.click();
        }
        setLoadingExport(false);
        presentToast("Downloading File", 2000);
      }
    } catch (err) {
      console.log(err);
      setLoadingExport(false);
    }
  };



  const handleFilterStatus = (event) => {

    let status = event.detail.value;
    setSelectedStatus(status);
    setCheckedProducts([]);
  }

  const handleSubCategoryChange = (subcategoryId) => {
    
    let selectedCat = '';
    let selectedCatId = '';
    let selectedSubcategoryId = '';
    let selectedSubcategory = {};
    if (subcategoryId === null) {
     
      if (categories[0].subCategory[0]) {
        selectedCat = categories[0];
        selectedCatId = categories[0].id;
        selectedSubcategory = categories[0].subCategory[0];
      } else {
        const data = categories.filter((cat) => {
          return cat.subCategory.length > 0;
        });
        if (data && data.length !== 0) {
          selectedCat = data[0];
          selectedCatId = data[0].id;
          selectedSubcategory = data[0].subCategory[0];
          
        }
      }
      
    } else {

      categories.forEach(cat => {
        const subcategory = cat.subCategory.find(subcat => subcat.id === subcategoryId);
        if (subcategory) {
          selectedCat = cat;
          selectedCatId = cat.id;
          selectedSubcategoryId = subcategory.id;
          selectedSubcategory = subcategory;
        }
      });
    }
    

    setSelectedCategory(selectedCat);
    setselectedSubcategoryId(selectedSubcategoryId);
    setSelectedSubcategory(selectedSubcategory);

    if (query) {
      searchProductByName(query);
    }
    if (!query && subcategoryId == null) {
      searchProductByName('', subcategoryId =296352)
      setSubcategoryData({ ...selectedSubcategory, parentCategory: selectedCatId })

    }
    setSubcategoryData({ ...selectedSubcategory, parentCategory: selectedCatId })
  };



  let subcategoryList = [];
  if (Array.isArray(categories)) {
    console.log(categories);
    categories.forEach(category => {
      // Ensure category.subcategories is defined and is an array
      if (category.subCategory && Array.isArray(category.subCategory)) {
        category.subCategory.forEach(subcategory => {
          subcategoryList.push(subcategory);
        });
      } else {
        console.log(`Category ${category.name} has no subcategories or is not an array`);
      }
    });
  } else {
    console.log('Categories is not an array');
  }

  const handleInputChange = (e) => {
    console.log('hello20');

    setQuery(e.target.value);
    if (selectedSubcategoryId) {
      searchProductByName(e.target.value, selectedSubcategoryId);
    } else {
      searchProductByName(e.target.value);
    }
  };

  // console.log('Subcategory List:', subcategoryList);
  // console.log('total',totalProducts);
  // console.log('current page',currentPage);

    return (
      <IonPage>
        <Header name="Products" docs={true} docslink='https://intelikart.tawk.help/article/product' />
        <IonContent>
          <div style={{ backgroundColor: "#F5F7FB" }}>
            <br />
            <IonText className="ion-text-center">
              <h5 className="ion-no-margin">
                <strong>Manage your products</strong>
              </h5>
            </IonText>
            <IonGrid>
              <IonRow>

                <IonCol sizeSm="8" sizeXs="12">
                  {!fetchCategoriesLoading && (
                    <div className="product-search-bar">
                      <IonIcon icon={serachIcon}></IonIcon>
                      <IonSelect
                        value={selectedSubcategoryId || null}
                        placeholder="Search by Category"
                        onIonChange={(e) => handleSubCategoryChange(e.detail.value)}
                      >
                        <IonSelectOption value={null}>All categories</IonSelectOption>
                        {subcategoryList?.map(cate => (
                          
                          <IonSelectOption key={cate.id} value={cate.id}>{cate.name}</IonSelectOption>
                        ))}
                      </IonSelect>
                      <IonInput
                        placeholder='Search Product'
                        value={query}
                        onIonChange={handleInputChange}
                      ></IonInput>
                      {query && (
                        <IonIcon
                          style={{ marginRight: "7px", cursor: "pointer" }}
                          icon={closeCircleOutline}
                          onClick={() => {
                            setQuery('');
                           
                            if (selectedSubcategory && Object.keys(selectedSubcategory).length === 0) {
                              console.log(selectedCategory,);
                              console.log(selectedSubcategory,);
                              
                              if (categories[0].subCategory[0]) {
                                setSubcategoryData({
                                  ...categories[0].subCategory[0],
                                  parentCategory: categories[0].id,
                                });
                              } else {
                                const data = categories.filter((cat) => {
                                  return cat.subCategory.length > 0;
                                });
                                if (data && data.length !== 0) {
                                  setSubcategoryData({
                                    ...data[0].subCategory[0],
                                    parentCategory: data[0].id,
                                  });
                                }
                              } 
                            } else {
                              console.log(selectedCategory);
                              console.log(selectedSubcategory,);
                              setSubcategoryData({
                                ...selectedSubcategory,
                                parentCategory: selectedCategory.id,
                              })
                              
                            }         
}}
                        ></IonIcon>
                      )}
                    </div>
                  )}
                </IonCol>
                <IonCol sizeSm="4" sizeXs="12">
                  <Link
                    to={`/catalogue/products/create/${true}`}
                    style={{ textDecoration: "none" }}
                  >

                    <IonButton
                      id='hover-trigger'
                      shape="round"
                      expand="block"
                      color="success"
                    

                    >
                      Add Products{" "}
                      <IonIcon
                        icon={addIcon}
                        style={{ marginLeft: "auto" }}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}>

                      </IonIcon>
                    </IonButton>
                  </Link>
                </IonCol>

              </IonRow>
              <IonRow className="ion-no-margin ion-padding-top">
                <IonCol>
                  <IonButton
                    disabled={loadingExport}
                    onClick={handleExport}
                    className="ion-no-margin"
                    style={{ marginLeft: "10px" }}
                    fill="outline"
                  >
                    Export
                  </IonButton>
                </IonCol>
                <IonCol sizeMd='4' sizeSm='7' sizeXs='7' className=''>


                  <IonItem lines="none" className="roundedInput">
                    <IonLabel
                      style={{
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}> Search by</IonLabel>
                    <IonSelect
                      className="ion-no-padding ion-no-margin"
                      value={selectedStatus}
                      onIonChange={handleFilterStatus}>
                      <IonSelectOption value="all">All</IonSelectOption>
                      <IonSelectOption value="Active">Active</IonSelectOption>
                      <IonSelectOption value="Inactive">Inactive</IonSelectOption>

                    </IonSelect>
                  </IonItem>

                </IonCol>
                <IonCol style={{ paddingRight: 0 }}>
                  <IonCheckbox
                    checked={selectAll}
                    className="ion-float-end"
                    onClick={(e) => handelSelectAll()}
                    style={{
                      fontSize: "20px",
                      marginRight: "20px",
                      marginTop: "5px",
                    }}
                  />
                  <IonText
                    className="ion-float-end"
                    style={{ fontSize: "20px", marginRight: "30px" }}
                  >
                    Select All
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>

            {fetchCategoriesLoading === false &&
              categories.length !== 0 &&
              subcategoryData.id &&
              subcategoryData.name && (
                <>
                  <IonItem
                    lines="none"
                    className="ion-margin-horizontal category-dropdown"
                  >
                    <IonLabel position="stacked">Select Subcategory</IonLabel>
                    <div
                      style={{
                        width: 100 + "%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={(e) => setShowSelectCateModal(true)}
                    >
                      <span style={{ width: 95 + "%" }}>
                        {subcategoryData && subcategoryData.name && (
                          <IonInput
                            value={subcategoryData.name}
                            readonly
                          ></IonInput>
                        )}
                      </span>
                      <span>&#x25BC;</span>
                    </div>
                  </IonItem>
                  <IonModal
                    isOpen={showSelectCateModal}
                    onDidDismiss={(e) => setShowSelectCateModal(false)}
                  >
                    <IonHeader>
                      <IonItem lines="none">
                        <IonText>
                          <h5>Select Subcategory</h5>
                        </IonText>
                        <IonButton
                          fill="clear"
                          size="large"
                          slot="end"
                          onClick={(e) => setShowSelectCateModal(false)}
                        >
                          <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                        </IonButton>
                      </IonItem>
                    </IonHeader>
                    <IonContent className="ion-padding">
                      {(query && !loadingSearch
                        ? searchResult.categoryList
                        : categories
                      )?.map((parentCategory) => {
                        return (
                          <>
                            <IonItem
                              key={parentCategory.id}
                              style={{ cursor: "pointer" }}
                            >
                              <IonLabel
                                color={
                                  subcategoryData.parentCategory ===
                                    parentCategory.id
                                    ? "primary"
                                    : ""
                                }
                              >
                                {parentCategory.name} (
                                {parentCategory.subCategory.length})
                              </IonLabel>
                            </IonItem>
                            <IonList>
                              {parentCategory.subCategory.map((childCategory) => {
                                return (
                                  <IonItem
                                    key={childCategory.id}
                                    lines="none"
                                    className="ion-margin-start"
                                    onClick={(e) => {
                                      setSubcategoryData({
                                        ...childCategory,
                                        parentCategory: parentCategory.id,
                                      });
                                      setShowSelectCateModal(false);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <IonThumbnail slot="start">
                                      <img
                                        src={childCategory.icon_code_internet_url}
                                      />
                                    </IonThumbnail>
                                    <IonText
                                      color={
                                        subcategoryData.name ===
                                          childCategory.name
                                          ? "primary"
                                          : ""
                                      }
                                    >
                                      <h6 className="ion-no-margin">
                                        {childCategory.name}
                                      </h6>
                                    </IonText>
                                    {subcategoryData.name ===
                                      childCategory.name ? (
                                      <IonIcon
                                        slot="end"
                                        color="primary"
                                        size="large"
                                        icon={checkmarkSharpIcon}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </IonItem>
                                );
                              })}
                            </IonList>
                          </>
                        );
                      })}
                    </IonContent>
                  </IonModal>
                  <IonCard style={{ backgroundColor: "white" }}>
                    <IonGrid className="ion-no-padding">
                      <IonRow>
                        <IonCol
                          size="3"
                          style={{ borderRight: "1px solid rgba(0, 0, 0, 0.13)" }}
                          className="side-category-display"
                        >
                        <IonList
                          className='scrollable-subcategory-list'           

                          style={{ marginBottom: 60 + "px" }}>
                            {(query && !loadingSearch
                              ? searchResult.categoryList
                              : categories
                            ).map((parentCategory) => {
                              return (
                                <>
                                  <div
                                    key={parentCategory.id}
                                    className="ion-padding"
                                  >
                                    <IonLabel
                                      color={
                                        subcategoryData.parentCategory ===
                                          parentCategory.id
                                          ? "primary"
                                          : ""
                                      }
                                    >
                                      {parentCategory.name} (
                                      {parentCategory.subCategory.length})
                                    </IonLabel>
                                  </div>
                                  <IonList
                                    style={{
                                      borderBottom: "1px solid rgba(0,0,0,0.16)",
                                    }}
                                  >
                                    {parentCategory.subCategory.map(
                                      (childCategory) => {
                                        return (
                                          <div
                                            key={childCategory.id}
                                            className="ion-margin-start ion-padding-horizontal"
                                            onClick={(e) =>
                                              setSubcategoryData({
                                                ...childCategory,
                                                parentCategory: parentCategory.id,
                                              })
                                            }
                                            style={{
                                              cursor: "pointer",
                                              paddingTop: 10 + "px",
                                              paddingBottom: 10 + "px",
                                              backgroundColor: `${subcategoryData.name ===
                                                childCategory.name
                                                ? "#F5F7FB"
                                                : "white"
                                                }`,
                                            }}
                                          >
                                            <IonLabel
                                              color={
                                                subcategoryData.name ===
                                                  childCategory.name
                                                  ? "primary"
                                                  : ""
                                              }
                                            >
                                              - {childCategory.name}
                                            </IonLabel>
                                          </div>
                                        );
                                      }
                                    )}
                                  </IonList>
                                </>
                              );
                            })}
                          </IonList>
                        </IonCol>
                      
                      <IonCol>
                        {(query && !loadingSearch) ||
                          (filterArrayObject[subcategoryData.id] && filterArrayObject[subcategoryData.id].length > 0) ? (
                          <>
                            {(
                              query && !loadingSearch
                                ? searchResult.productList[subcategoryData.id]
                                : filterArrayObject[subcategoryData.id]
                                  .filter(product => selectedStatus === 'all' || product.status.toLowerCase() === selectedStatus.toLowerCase())
                                  .slice(currentPage * 20, currentPage * 20 + 20)
                            )?.map((product) => (
                                <>
                                <BrowserView>
                              <IonItem
                                key={product.id}
                                className="ion-margin-top ion-margin-bottom"
                              >
                                <Link
                                  to={{
                                    pathname: `/catalogue/products/update/${product.id}`,
                                    state: {
                                      ...subcategoryData,
                                      productData: product,
                                    },
                                  }}
                                >
                                  <IonThumbnail slot="start">
                                    <img src={product.variants[0].image_url_original} />
                                  </IonThumbnail>
                                </Link>
                                <IonText className="ion-margin-start">
                                  <h5 className="ion-no-margin">
                                    {product.name}&nbsp;
                                    <IonBadge
                                      color={`${product.status == "inactive" && "danger"}`}
                                      style={{
                                        fontSize: "11px",
                                        padding: "3px",
                                      }}
                                    >
                                      {product.status == "inactive" && "INACTIVE"}
                                    </IonBadge>
                                  </h5>
                                  <IonText color="medium">
                                    ({product.variants[0].quantity} {product.variants[0].quantity_unit_name})
                                  </IonText>
                                  <br />
                                  {product.variants[0].mrp != 0 && product.variants[0].mrp != null ? (
                                    <h5 className="ion-no-margin" style={{ display: "inline" }}>
                                      {currency} {product.variants[0].mrp}&nbsp;
                                    </h5>
                                  ) : (
                                    ""
                                  )}
                                  {product.variants[0].display_mrp != 0 && product.variants[0].display_mrp != null ? (
                                    <del>
                                      {currency} {product.variants[0].display_mrp}
                                    </del>
                                  ) : (
                                    ""
                                  )}
                                </IonText>
                                <IonButton
                                  onClick={(e) =>
                                    history.push({
                                      pathname: `/catalogue/products/update/${product.id}`,
                                      state: {
                                        ...subcategoryData,
                                        productData: product,
                                      },
                                    })
                                  }
                                  style={{ marginLeft: 10 + "px" }}
                                  slot="end"
                                >
                                  <IonIcon icon={createSharpIcon} />
                                </IonButton>
                                <IonButton
                                  className="ion-float-end"
                                  style={{ marginRight: 10 + "px" }}
                                  onClick={(e) => {
                                    setShowAlertForSingle(true);
                                    setDeletedProductId(`${product.id}`);
                                  }}
                                  color="danger"
                                  slot="end"
                                >
                                  <IonIcon icon={trashSharpIcon} />
                                </IonButton>
                                <IonCheckbox
                                  checked={checkedProducts.includes(product.id)}
                                  onClick={(e) => handelChecked(product.id)}
                                  slot="end"
                                />
                              </IonItem>
                              </BrowserView>
                              <MobileView>
                                <IonItem
                                key={product.id}
                                className="ion-margin-top ion-margin-bottom"
                              >
                                <Link
                                  to={{
                                    pathname: `/catalogue/products/update/${product.id}`,
                                    state: {
                                      ...subcategoryData,
                                      productData: product,
                                    },
                                  }}
                                >
                                  <IonThumbnail slot="start">
                                    <img src={product.variants[0].image_url_original} />
                                  </IonThumbnail>
                                </Link>
                                <IonText className="ion-margin-start">
                                  <h5 className="ion-no-margin">
                                    {product.name}
                                  </h5>
                                  <IonBadge
                                      color={`${product.status == "inactive" && "danger"}`}
                                      style={{
                                        fontSize: "11px",
                                        padding: "3px",
                                      }}
                                    >
                                      {product.status == "inactive" && "INACTIVE"}
                                    </IonBadge>
                                    {product.status == "inactive" && <br />}
                                  <IonText color="medium">
                                    ({product.variants[0].quantity} {product.variants[0].quantity_unit_name})
                                  </IonText>
                                  <br />
                                  {product.variants[0].mrp != 0 && product.variants[0].mrp != null ? (
                                    <h5 className="ion-no-margin" style={{ display: "inline" }}>
                                      {currency} {product.variants[0].mrp}&nbsp;
                                    </h5>
                                  ) : (
                                    ""
                                  )}
                                  {product.variants[0].display_mrp != 0 && product.variants[0].display_mrp != null ? (
                                    <del>
                                      {currency} {product.variants[0].display_mrp}
                                    </del>
                                  ) : (
                                    ""
                                  )}
                                </IonText>
                                <IonButton
                                  onClick={(e) =>
                                    history.push({
                                      pathname: `/catalogue/products/update/${product.id}`,
                                      state: {
                                        ...subcategoryData,
                                        productData: product,
                                      },
                                    })
                                  }
                                  style={{ marginLeft: 5 + "px" }}
                                  slot="end"
                                >
                                  <IonIcon icon={createSharpIcon} />
                                </IonButton>
                                <IonButton
                                  className="ion-float-end"
                                  style={{ marginRight: 5 + "px", marginLeft: 5 + "px" }}
                                  onClick={(e) => {
                                    setShowAlertForSingle(true);
                                    setDeletedProductId(`${product.id}`);
                                  }}
                                  color="danger"
                                  slot="end"
                                >
                                  <IonIcon icon={trashSharpIcon} />
                                </IonButton>
                                <IonCheckbox
                                  style={{ marginLeft: 5 + "px" }}
                                  checked={checkedProducts.includes(product.id)}
                                  onClick={(e) => handelChecked(product.id)}
                                  slot="end"
                                />
                              </IonItem>
                              </MobileView>
                              </>
                            ))}
                            {!query && ((totalProducts[subcategoryData.id].totalPages) > 1) && 
                              // productsBySubcategory.meta &&
                              // productsBySubcategory.meta.pagination &&
                                // productsBySubcategory.meta.pagination.total_pages > 1 && 
                                (
                                <IonGrid>
                                  <IonRow className="ion-justify-content-center">
                                    <IonCol>
                                      <IonText color="primary" className="ion-text-center">
                                        <h5>
                                          Showing ({((currentPage * 20)+1)} -{" "}
                                          {(currentPage + 1) * 20 > filterArrayObject[subcategoryData.id].length
                                            ? filterArrayObject[subcategoryData.id].length
                                            : (currentPage + 1) * 20}
                                          )/{totalProducts[subcategoryData.id].totalItems}
                                        </h5>
                                        <ReactPaginate
                                          
                                          previousLabel="Prev"
                                          nextLabel="Next"
                                          
                                          pageCount={totalProducts[subcategoryData.id].totalPages}
                                          
                                          onPageChange={(data) => handlePageClick(data.selected)}
                                          containerClassName="pagination-container"
                                          activeClassName="pagination-button"
                                        />
                                      </IonText>
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                              )}
                          </>
                        ) : (
                          <div className="ion-text-center ion-margin-vertical">
                            <Link
                              to={{
                                pathname: `/catalogue/products/create/${true}`,
                                state: { ...subcategoryData },
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <IonButton>
                                <IonIcon icon={addCircleIcon} />
                                Add Product in {subcategoryData.name}
                              </IonButton>
                            </Link>
                          </div>
                        )}
                        <IonCard className="position-bottom ion-text-center ion-no-margin ion-margin-top">
                          <Link
                            to={{
                              pathname: `/catalogue/products/create/${true}`,
                              state: { ...subcategoryData },
                            }}
                            style={{ textDecoration: "none", display: "none" }}
                            className="ion-margin"
                          >
                            <h6 className="ion-no-margin">
                              <span className="category-plus-button">+</span> Add new product in {subcategoryData.name}
                            </h6>
                          </Link>
                        </IonCard>
                      </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                </>
              )}
            <IonAlert
              isOpen={showAlertForMulti === true}
              onDidDismiss={() => setShowAlertForMulti(false)}
              header={"Delete Selected Products"}
              message={`You are about to delete ${checkedProducts.length} products. Are you sure?`}
              buttons={[
                {
                  text: "CANCEL",
                  role: "cancel",
                },
                {
                  text: "DELETE",
                  handler: () => {
                    deleteAllSelected();
                  },
                },
              ]}
            />
            <IonAlert
              isOpen={showAlertForSingle === true}
              onDidDismiss={() => setShowAlertForSingle(false)}
              header={"Delete Product"}
              message={`Are you sure?`}
              buttons={[
                {
                  text: "CANCEL",
                  role: "cancel",

                },
                {
                  text: "DELETE",
                  handler: () => {
                    deleteSingleProduct();
                  },
                },
              ]}
            />
            <IonAlert
              isOpen={showAlertForStatus === true}
              onDidDismiss={() => setShowAlertForStatus(false)}

              header={"Change Status of Products"}
              message={`Are you sure?`}
              cssClass='custom-alert'

              buttons={[
                {
                  text: "CANCEL",
                  role: "cancel",
                  cssClass: 'cancel-button',
                },
                {
                  text: "ACTIVE",
                  handler: () => {
                    bulkEditHandler("active");
                  },
                  cssClass: 'active-button',

                },
                {
                  text: "INACTIVE",
                  handler: () => {
                    bulkEditHandler("inactive");
                  },
                  cssClass: 'inactive-button',

                },
              ]}
            />
          </div>
          <IonLoading
            isOpen={
              productsBySubcategoryLoading === true ||
              fetchCategoriesLoading === true ||
              loading === true ||
              deleteProductLoading === true
            }
            message="Please Wait"
          />
        </IonContent>
        {location && location.state && location.state.data ? (
          <IonRow>
            <IonCol style={{ display: "flex", justifyContent: "center" }}>
              <IonButton onClick={(e) => handelstickyproduct()} shape="round">
                <IonIcon icon={add}></IonIcon> &nbsp; Add Sticky Product
              </IonButton>
            </IonCol>
          </IonRow>
        ) : (
          <IonRow>
            <IonCol>
              <IonButton
                onClick={(e) => setShowAlertForMulti(true)}
                expand="full"
                color="danger"
                className={`${checkedProducts.length > 0 ? "" : "ion-hide"}`}
              >
                <IonIcon icon={trashSharpIcon}></IonIcon> &nbsp; Delete all
                selected
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                onClick={(e) => setShowAlertForStatus(true)}
                expand="full"
                color="dark"
                className={`${checkedProducts.length > 0 ? "" : "ion-hide"}`}
              >
                <IonIcon icon={toggle}></IonIcon> &nbsp; Change Status
              </IonButton>
            </IonCol>
          </IonRow>
        )}
      </IonPage>
    );
  
}

export default ProductsHomeScreen