import { ItemReorderEventDetail } from "@ionic/core/components";
import {
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLoading,
    IonPage,
    IonReorder,
    IonReorderGroup,
    IonRow,
    IonText,
    IonThumbnail,
    useIonToast,
} from "@ionic/react";
import {
    add as addIcon,
    closeCircleOutline,
    createOutline,
    search as serachIcon,
    trashOutline,
    trashSharp as trashSharpIcon,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, useHistory } from "react-router-dom";
import YouTube from "react-youtube";
import {
    getStoresCategory,
    updateCategoryTree,
} from "../actions/categoryAction";
import Header from "../components/dashboard/Header";
import { getAccessToken } from "../services/auth/userLocalStorage";
import useAPI from "../utils/api";
import { BrandsContext, useBrands, useCategory } from "../utils/contexts";
import useFetch from "../utils/useFetch";
import AddUpdateBrand from "./AddUpdateBrand";

const ProductBrands = () => {
    const [brands, setBrands] = useState([]);
    const [updatedBrandsData, setupdatedBrandsData] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [markForDeletion, setMarkForDeletion] = useState([]);
    const [present] = useIonToast();
    const API = useAPI();
    const dispatch = useDispatch();
    const history = useHistory();
    const categoryData: any = useCategory();

    const { currentUser } = useSelector((state: any) => state.currentUser);
    const [categoryReorderd, setCategoryReorderd] = useState(true);
    const opts = {
        height: "390",
        width: "100%",
    };
    const brandsdata: any = useBrands();
    console.log("BRands", brandsdata);
    useEffect(() => {
        if (
            categoryData.status === "fetched" &&
            categoryData.loading === false
        ) {
            setCategoryList(categoryData.categories);
        }
    }, [categoryData]);
    useEffect(() => {
        if (brandsdata.status === "fetched" && brandsdata.loading === false) {
            // setCategoryList(categoryData.categories);
            setBrands(brandsdata.brands);
        }
    }, [brandsdata, currentUser]);
    useEffect(()=>{
        setSearchString('');
    },[history.location.pathname])
    // useEffect(() => {
    //     if(currentUser && currentUser.data && currentUser.data.store && currentUser.data.store.subdomain){
    //         // if (brandscontext.status === "fetched" && brandscontext.loading === false){
    //      getBrands();
    //     }

    // }, [currentUser]);

    const filteredCategories = (e) => {
        let query = e.detail.value.toLowerCase();
        let result = [];
        for (let parentCategory of brandsdata.brands) {
            if (parentCategory.name.toLowerCase().includes(query)) {
                result.push(parentCategory);
            }
        }
        setBrands(result);
    };

    const handledelete = async (id) => {
        try {
            const [ok, repsonse] = await API.deleteBrands(id);
            console.log("dele", ok, repsonse);
            if (ok) {
                console.log("re", repsonse);
                if (repsonse) {
                    present(repsonse.message || "Some error occured!", 2000);
                    brandsdata.setUpdatedBrandsData(
                        !brandsdata.updatedBrandsData
                    );
                    getBrands();
                }
            } else {
                present(
                    `There are products associated with this brand, please delete them first`,
                    2000
                );
            }
            console.log("ok", ok, "res", repsonse);
        } catch (e) {
            console.log(e);
        }
    };

    const getBrands = async () => {
        try {
            const [ok, repsonse] = await API.Brands();

            if (ok) {
                if (repsonse.data.length > 0) {
                    setBrands(repsonse.data);
                } else {
                    setBrands([]);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <IonPage>
            <Header name="Brands" />
            <IonContent>
                <div
                    style={{ backgroundColor: "#F5F7FB", height: 81.6 + "vh" }}
                >
                    <br />
                    <IonText className="ion-text-center">
                        <h5 className="ion-no-margin ion-margin-bottom">
                            <strong>Manage Brands</strong>
                        </h5>
                    </IonText>
                    <IonGrid>
                        <IonRow>
                            <IonCol sizeSm="6" sizeXs="12">
                                {brandsdata.status === "fetched" &&
                                    brandsdata.loading === false && (
                                        <div className="product-search-bar">
                                            <IonIcon
                                                icon={serachIcon}
                                            ></IonIcon>
                                            <IonInput
                                                placeholder="Search Brand"
                                                value={searchString}
                                                onIonChange={(e) => {
                                                    setSearchString(
                                                        e.detail.value
                                                    );
                                                    filteredCategories(e);
                                                }}
                                            ></IonInput>

                                            {searchString ? (
                                                <IonIcon
                                                    icon={closeCircleOutline}
                                                    style={{
                                                        cursor: "pointer",
                                                        marginRight:"20px"
                                                    }}
                                                    onClick={(e) => {
                                                        setBrands(
                                                            brandsdata.brands
                                                        );
                                                        setSearchString("");
                                                    }}
                                                ></IonIcon>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    )}
                            </IonCol>
                            <IonCol sizeSm="6" sizeXs="12">
                                <Link
                                    to={`/catalogue/brands/add-brands`}
                                    style={{ textDecoration: "none" }}
                                >
                                    <IonButton
                                        shape="round"
                                        expand="block"
                                        color="success"
                                    >
                                        Create new Brand
                                        <IonIcon
                                            icon={addIcon}
                                            style={{ marginLeft: "auto" }}
                                        ></IonIcon>
                                    </IonButton>
                                </Link>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    {brandsdata.loading === false &&
                        brandsdata.status === "fetched" && (
                            <>
                                {brands &&
                                    brands.map((item) => {
                                        return (
                                            <IonCard
                                                style={{
                                                    backgroundColor: "white",
                                                }}
                                                key={item.id}
                                            >
                                                <BrowserView>
                                                    <IonItem>
                                                        <IonThumbnail slot="start">
                                                            {/* {item.icon} */}
                                                            <img
                                                                src={item.icon}
                                                            />
                                                        </IonThumbnail>
                                                        <IonText>
                                                            <h4 className="ion-no-margin">
                                                                {item.name}
                                                            </h4>
                                                        </IonText>
                                                        <IonButton
                                                            fill="clear"
                                                            color="primary"
                                                            onClick={() =>
                                                                history.push({
                                                                    pathname: `/catalogue/brands/update-brands/${item.id}`,
                                                                })
                                                            }
                                                            slot="end"
                                                        >
                                                            <IonIcon
                                                                slot="icon-only"
                                                                icon={
                                                                    createOutline
                                                                }
                                                            ></IonIcon>
                                                        </IonButton>
                                                        <IonButton
                                                            fill="clear"
                                                            color="primary"
                                                            onClick={() => {
                                                                handledelete(
                                                                    item.id
                                                                );
                                                            }}
                                                            slot="end"
                                                        >
                                                            <IonIcon
                                                                slot="icon-only"
                                                                icon={
                                                                    trashOutline
                                                                }
                                                            ></IonIcon>
                                                        </IonButton>
                                                    </IonItem>
                                                </BrowserView>
                                                <MobileView>
                                                    <IonItem>
                                                        <IonThumbnail slot="start">
                                                            <img
                                                                src={item.icon}
                                                            />
                                                        </IonThumbnail>
                                                        <IonGrid>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <IonText>
                                                                        <h4 className="ion-no-margin">
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </h4>
                                                                    </IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol sizeXs="7">
                                                                    <IonText
                                                                        color="primary"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                    >
                                                                        {/* <p className='ion-no-margin' onClick={e => history.push(`/catalogue/category/add-subcat/${item.id}/false`)}>Add Sub-Category +</p> */}
                                                                    </IonText>
                                                                </IonCol>
                                                                <IonCol sizeXs="2">
                                                                    <IonButton
                                                                        fill="clear"
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            history.push(
                                                                                {
                                                                                    pathname: `/catalogue/brands/update-brands/${item.id}`,
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        <IonIcon
                                                                            slot="icon-only"
                                                                            icon={
                                                                                createOutline
                                                                            }
                                                                        ></IonIcon>
                                                                    </IonButton>
                                                                </IonCol>
                                                                <IonCol sizeXs="2">
                                                                    <IonButton
                                                                        fill="clear"
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            handledelete(
                                                                                item.id
                                                                            );
                                                                        }}
                                                                    >
                                                                        <IonIcon
                                                                            slot="icon-only"
                                                                            icon={
                                                                                trashOutline
                                                                            }
                                                                        ></IonIcon>
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                    </IonItem>
                                                </MobileView>
                                            </IonCard>
                                        );
                                    })}
                            </>
                        )}
                    {brandsdata.status === "fetched" &&
                        brandsdata.loading === false &&
                        brands?.length === 0 && (
                            <IonText className="ion-text-center">
                                <h4>No Brands found</h4>
                            </IonText>
                        )}
                </div>
            </IonContent>
            <IonLoading
                isOpen={brandsdata.loading === true}
                message="Please Wait"
            ></IonLoading>
            {/* <IonButton expand="full"  color="danger" className={`${markForDeletion.categories.length > 0 || markForDeletion.subcategories.length > 0 ? '' : 'ion-hide'}`} onClick={e=> deleteAllSelected()}><IonIcon icon={trashSharpIcon}></IonIcon> &nbsp; Delete all selected</IonButton> */}
        </IonPage>
    );
};

export default ProductBrands;
