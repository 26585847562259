import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent, IonItem, IonLabel, IonToggle, IonDatetime, IonDatetimeButton, IonIcon, IonModal, IonButton, IonCol, IonRow, IonCard, useIonToast, IonGrid, IonText } from "@ionic/react";
import {
    calendar as calenderIcon,
} from 'ionicons/icons';
import {
    arrowBack as arrowBackIcon
} from "ionicons/icons";
import { useEffect, useState } from "react";
import useAPI from "../../utils/api";
import useFetch from "../../utils/useFetch";
import FeatHeader from '../../components/dashboard/FeatHeader';


const Maintenance = () => {
    const [storeOffline, setStoreOffline] = useState(false);
    const [present] = useIonToast();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [dateFlag, setDateFlag] = useState(false);
    let storeStatus = useFetch("getStoreStatus");
    const API = useAPI();

    const currentDate = new Date()
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');

    useEffect(() => {
        setDateFlag(false);
        if (storeStatus.loading === false && storeStatus && storeStatus?.data.data) {
            const data = storeStatus?.data.data;

            let isOffline = (data.offline_start_date != null && data.offline_end_date != null) ? true : false;
            if (isOffline) {
                let strDate = `${(data.offline_start_date).slice(0, 10)}T${(data.offline_start_date).slice(11, 16)}:00+05:30`;
                let lstDate = `${(data.offline_end_date).slice(0, 10)}T${(data.offline_end_date).slice(11, 16)}:00+05:30`;
                setStartDate(strDate);
                setEndDate(lstDate);

                let cDate = new Date();
                let sDate = new Date(
                    data.offline_start_date
                );
                let eDate = new Date(
                    data.offline_end_date
                );
                (isOffline && (cDate >= sDate && cDate <= eDate)) ? setStoreOffline(true) : setStoreOffline(false);
            } else {
                if (startDate === "") {
                    setStartDate(`${year}-${month}-${day}T${hours}:${minutes}:00+05:30`)
                }
            }
        }
    }, [storeStatus])


    async function submit() {
        let startdt = null, enddt = null;

        startdt = `${startDate.slice(0, 10)} ${startDate.slice(11, 16)}:00`;
        enddt = `${endDate.slice(0, 10)} ${endDate.slice(11, 16)}:00`;

        const data = {
            "offline_start_date": storeOffline ? startdt : null,
            "offline_end_date": storeOffline ? enddt : null
        };

        if (validationDate()) {
            present({
                message: 'Please Enter valid Date and Time',
                duration: 4000
            });
        }
        else {
            try {
                const [ok, response] = await API.setStoreStatus(data);
                if (ok) {
                    present({
                        message: 'Your Store Status is updated',
                        duration: 2500
                    });
                } else {
                    present({
                        message: 'Some error occured , please try again later',
                        duration: 4000
                    });
                }
            }
            catch (e) {
                alert(e.message);
            }
        }
    }

    function validationDate() {
        let result = false
        setDateFlag(false)
        if (endDate === "") {
            if (storeOffline) {
                setDateFlag(true);

                result = true
            }
        } else {
            const stDate = new Date(startDate);
            const edDate = new Date(endDate);

            if (edDate.getTime() < stDate.getTime()) {
                setDateFlag(true);
                result = true
            }
        }
        return result
    }

    function getMinEndDate() {
        if (startDate === "") {
            return new Date().toISOString().split('T')[0]
        }
        return new Date(startDate).toISOString().split('T')[0]
    }

    return (
        <IonPage>

            <FeatHeader
                title='Maintenance'
                defaultHref='/settings'
                showInfoButton={false}
            />
            <IonContent>
                <IonCard>
                    <IonItem>
                        <IonLabel>Store Offline</IonLabel>
                        <IonToggle checked={storeOffline} onIonChange={(e) => {
                            if (e.detail.checked) {
                                setStoreOffline(e.detail.checked)
                            } else {
                                setStartDate(`${year}-${month}-${day}T${hours}:${minutes}:00+05:30`)
                                setEndDate("");
                                setStoreOffline(e.detail.checked)
                                setDateFlag(false)
                            }
                        }} />
                    </IonItem>
                </IonCard>
                {storeOffline && (
                    <IonCard>
                        <IonRow>
                            <IonCol className="ion-no-padding" sizeMd="6" sizeXs="12">
                                <IonRow className="ion-no-padding">
                                    <IonCol size="5" sizeXs="4" className="ion-no-padding">
                                        <IonItem lines="none">
                                            <IonLabel>
                                                Start Date
                                            </IonLabel>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="7" sizeXs="8" className="date ion-no-padding">
                                        <IonItem className="ion-no-padding">
                                            <IonIcon className="ion-no-margin" icon={calenderIcon} slot="end" />
                                            <IonDatetimeButton datetime="startDate"></IonDatetimeButton>
                                            <IonModal keepContentsMounted={true}>
                                                <IonDatetime style={{ color: "black" }} id="startDate" value={startDate} presentation="date-time" min={new Date().toISOString().split('T')[0]} onIonChange={(e) => {
                                                    let strDate = `${(e.target.value)}`
                                                    setStartDate(strDate)
                                                }} />
                                            </IonModal>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                            <IonCol className="ion-no-padding" sizeMd="6" sizeXs="12">
                                <IonRow className="ion-no-padding">
                                    <IonCol size="5" sizeXs="4" className="ion-no-padding">
                                        <IonItem lines="none">
                                            <IonLabel>
                                                End Date
                                            </IonLabel>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="7" sizeXs="8" className="date ion-no-padding">
                                        <IonItem className="ion-no-padding">
                                            <IonIcon className="ion-no-margin" icon={calenderIcon} slot="end" />
                                            <IonDatetimeButton datetime="endDate"></IonDatetimeButton>
                                            <IonModal keepContentsMounted={true} >
                                                <IonDatetime id="endDate" style={{ color: "black" }} value={endDate} presentation="date-time" onIonChange={(e) => {
                                                    let enDate = `${(e.target.value)}`
                                                    setEndDate(enDate)
                                                }}
                                                    min={getMinEndDate()} />
                                            </IonModal>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        <IonGrid>
                            <IonRow className="ion-align-items-center">
                                <IonCol size="8">{dateFlag && <IonText color="danger"> Please select valid dates</IonText>}</IonCol>
                                <IonCol size="4" className="ion-text-end">
                                    <IonButton shape="round" color="primary" onClick={() => {
                                        validationDate();
                                    }}>
                                        OK
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCard>
                )}
                <div className="ion-text-center">
                    <IonButton shape="round" onClick={submit}>Save</IonButton>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Maintenance;
