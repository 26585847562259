import { IonChip, IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonRow, IonSpinner, IonText, useIonToast } from '@ionic/react';
import { useRef, useState, useEffect } from 'react';
import Header from '../../components/dashboard/Header';
import useAPI from '../../utils/api';
import { useUser } from '../../utils/contexts';
import useFetch from '../../utils/useFetch';
import { advEmailValidation } from '../../utils/validations';
import { close } from "ionicons/icons";
import { BrowserView } from 'react-device-detect';

const CLIENT_ID =
    "451022753472-pkt5lnhga15qrhg7ukmepkim91m9v00m.apps.googleusercontent.com";
const SCOPES = 'https://www.googleapis.com/auth/gmail.readonly';
const REDIRECT_URI = "https://web.intelikart.com/marketing/emails";

const Emails = () => {
    const [present] = useIonToast();
    const [emailList, setEmailList] = useState<any>([]);
    const [update, setUpdate] = useState(true);
    const [emailValidation, setEmailValidation] = useState("");
    const {loading, data, status} = useFetch("emailStats", update);
    const [importContactStatus, setImportContactStatus] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const API = useAPI();
    const currentUser: any = useUser();
    const inputRef = useRef<HTMLIonInputElement>(null);

    const subject = `Buy online from ${currentUser?.store.name}`
    const body = `<pre>
Hi,
    Wanted to share with you my store URL.
    </pre>
    <a href=${currentUser?.store.subdomain}><button>${currentUser?.store.name}</button></a>
    <pre>Thanks</pre>`

    function addEmail() {
        const mailItem: any = inputRef.current.value;
        const formattedMailItem = mailItem.replace(",", "");
        if (formattedMailItem === "" || advEmailValidation(formattedMailItem)) {
            setEmailValidation('Email is invalid');
        } else {
            setEmailValidation('');
            setEmailList([...emailList, formattedMailItem]);
            setTimeout(() => {
                inputRef.current.value = "";
              }, 0);
        }
    }
    function deleteEmail(value, index) {
        let temp = [...emailList]
        temp.splice(index, 1)
        setEmailList(temp)
    }

    const registerEmails = async()=>{
        const mailItem: any = inputRef.current.value;
        const formattedMailItem = mailItem.replace(",", ""); 
    
        if (emailList.length === 0) {
            if (formattedMailItem !== "" && !advEmailValidation(formattedMailItem)) {

                const [ok, response] = await API.createEmails({
                    emails: [formattedMailItem],
                    subject: subject,
                    body: body
                });

                if(ok) {
                    setUpdate(!update);
                    present("Your emails are saved successfully.", 1500);
                    inputRef.current.value = ""
                }
                return;
            }
            setEmailValidation('Email is required.')
            return;
        }
            const [ok, response] = await API.createEmails({
                emails: emailList,
                subject: subject,
                body: body
            });

            if(ok) {
                setUpdate(!update);
                present("Your emails are saved successfully.", 1500);
                setEmailList([]);
                inputRef.current.value = ""
            }

    }

    const handleImportContacts = () => {
        window.location.href = getAuthUrl();
    };

    const getAuthUrl = () => {
        const queryParams = new URLSearchParams({
            client_id: CLIENT_ID,
            redirect_uri: REDIRECT_URI,
            response_type: "token",
            scope: SCOPES,
        });
        let api = `https://accounts.google.com/o/oauth2/auth?${queryParams.toString()}`;
        return api;
    };

    const handleOAuthCallback = () => {
        let accessToken = localStorage.getItem("access_token");
        if (accessToken) {
            importGmail(accessToken);
        }
    };

    const importGmail = async (accessToken: string) => {
        setIsFetching(true);
        const url = 'https://www.googleapis.com/gmail/v1/users/me/messages?q=in:sent';
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        try {
            const response = await fetch(url, { headers });
            const data = await response.json();

            const uniqueEmails = new Set();

            await Promise.all(
                data.messages.map(async (message: any) => {
                    const messageId = message.id;
                    const emailAddress = await fetchEmail(messageId, accessToken);
                    if (emailAddress) {
                        uniqueEmails.add(emailAddress);
                    }
                })
            );

            const validEmailAddresses = Array.from(uniqueEmails);

            await setEmailList([...emailList, ...validEmailAddresses]);
            await setImportContactStatus(true)

        } catch (error) {
            console.log('Error fetching Gmail messages:', error);
        } finally {
            setIsFetching(false);
        }
    };

    const fetchEmail = async (messageId: string, accessToken: string) => {
        const url = `https://www.googleapis.com/gmail/v1/users/me/messages/${messageId}`;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        try {
            const response = await fetch(url, { headers });
            const data = await response.json();

            const toField = data.payload.headers.find((header: any) => header.name === 'To');
            if (toField) {
                const matches = toField.value.match(/<([^>]+)>/);
                if (matches && matches.length >= 2) {
                    return matches[1];
                } else {
                    return toField.value;
                }
            }
        } catch (error) {
            console.log('Error fetching message details:', error);
        }

        return '';
    };

    useEffect(() => {
        let accessToken = localStorage.getItem("access_token");

        if(accessToken === null) {

            const hashParams = window.location.hash.substr(1);
            const accessTokenMatch = hashParams.match(/access_token=([^&]*)/);

            if (accessTokenMatch && accessTokenMatch.length >= 2) {
                accessToken = accessTokenMatch[1];
                localStorage.setItem("access_token", accessToken);

                handleOAuthCallback();  
            } 
        } else {
            handleOAuthCallback();  
        }

    }, []);

    return (
      <IonPage>
      <div className="ion-page" id="main-content">
                <Header name="Emails" docs={true} docslink='https://intelikart.tawk.help/article/email-marketing'/>
        <IonContent className="ion-padding">

        <IonText className="ion-text-center">
            <h5><strong>Send emails to your customers</strong></h5>
        </IonText>
        <IonGrid>
            <IonRow>
                <IonCol>
                    <IonCard>
                        <IonCardContent className="ion-text-center">
                            {!loading && status === "fetched" && data?.total_emails_submitted}
                            <br/>
                           Total Submitted Emails
                        </IonCardContent>
                    </IonCard>
                </IonCol>
                <IonCol>
                    <IonCard>
                        <IonCardContent className="ion-text-center">
                            {!loading && status === "fetched" && data?.total_emails_send}
                            <br/>
                            Total Sent Emails
                        </IonCardContent>
                    </IonCard>
                </IonCol>
                <IonCol>
                    <IonCard>
                        <IonCardContent className="ion-text-center">
                            {!loading && status === "fetched" && data?.total_emails_pending}
                            <br/>
                            Total Emails in Queue 
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </IonGrid>
        <IonGrid>
            <IonRow className="ion-align-items-center">
            <IonCol sizeSm="4" sizeXs="12">
                                <IonLabel>Emails</IonLabel>
                            </IonCol>
                            <IonCol className="ion-padding" style={{paddingBottom: 0}} sizeSm="8" sizeXs="12">
                                {emailList.map((value, index) => (
                                    <IonChip color="primary" style={{ /*margin: "2px 5px"*/ }} key={value}>
                                        <IonLabel>{value}</IonLabel>
                                        <IonIcon
                                            icon={close}
                                            onClick={(e) => deleteEmail(value, index)}
                                        ></IonIcon>
                                    </IonChip>
                                    
                                ))}
                                <IonItem>
                                    <IonInput
                                     style={{paddingLeft: 0}}
                                        placeholder="Enter comma seperated emails"
                                        ref={inputRef}
                                        onKeyDown={(e) =>
                                            e.key === "," || e.key === "Enter" ? addEmail() : ""
                                        }
                                    ></IonInput>
                                    <BrowserView>
                                        <IonButton onClick={handleImportContacts} disabled={importContactStatus === true}>
                                            {(importContactStatus === true) ? "Imported Gmail" : "Import Gmail"}
                                            {isFetching && <div><IonSpinner name="lines-sharp-small"></IonSpinner></div>}
                                        </IonButton>
                                    </BrowserView>
                                </IonItem>
                                <IonText
                                    color="danger"
                                    className={`${emailValidation ? "" : "ion-hide"}`}
                                >
                                    <h5
                                        style={{ fontSize: 12 + "px", marginTop: 5 + "px" }}
                                        className="ion-margin-horizontal"
                                    >
                                        {emailValidation}
                                    </h5>
                                </IonText>
                            </IonCol>
                
            </IonRow>
            <IonRow className="ion-align-items-center">
                <IonCol sizeSm="4" sizeXs="12">
                    <IonLabel>Subject</IonLabel>
                </IonCol>
                <IonCol sizeSm="8" sizeXs="12">
                    <IonCard>
                        <IonCardContent>
                            {subject}
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
                <IonCol sizeSm="4" sizeXs="12">
                    <IonLabel>Body</IonLabel>
                </IonCol>
                <IonCol sizeSm="8" sizeXs="12">
                    <IonCard>
                        <IonCardContent>
                        
Hi,<br />
          <span style={{ marginLeft:"20px" }}>Looking for the hottest deals? Look no further! We at {`${currentUser?.store.name}`}, <br />
          are serving our customers through our online store at <a href={`https://${currentUser?.store.subdomain}`} style={{ textDecoration:"none" }} target="_blank">{`${currentUser?.store.name}`}</a> <br />
          Explore a vast selection, exclusive Offers with Special discounts,deals,<br /> and promotions with hassle free online payments and doorstep 
          <br />delivery.
          </span>
       
          </IonCardContent>
          <IonCardContent>
    Our website- <a href={`https://${currentUser?.store.subdomain}`} style={{ textDecoration:"none" }} target="_blank">{`${currentUser?.store.name}`}</a>
     <br />
     {currentUser?.store.apk_url !=null? <>Our app-<a href={`https://${currentUser?.store.apk_url}`} style={{ textDecoration:"none" }} target="_blank">{`${currentUser?.store.name}`}</a></>:" "
     }
         </IonCardContent>
         <IonCardContent>
     Happy Shopping<br />
    Team {`${currentUser?.store.name}`}
 
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </IonGrid>
        <br/>
        <div className="ion-text-center">
            <IonButton type="button" shape="round" onClick={registerEmails}>Send</IonButton>
        </div>
        <IonLoading isOpen={loading} message="Please Wait"></IonLoading>
        </IonContent>
      </div>
    </IonPage>
)}

export default Emails