import {
    CLEAR_ERRORS,
    GET_MASTER_CATEGORIES_FAIL, GET_MASTER_CATEGORIES_REQUEST, GET_MASTER_CATEGORIES_SUCCESS,
    GET_MASTER_PRODUCTS_FAIL, GET_MASTER_PRODUCTS_REQUEST, GET_MASTER_PRODUCTS_SUCCESS, POST_COPY_PRODUCTS_FAIL, POST_COPY_PRODUCTS_REQUEST, POST_COPY_PRODUCTS_SUCCESS
} from "../constants/masterCatelogueConstants"

export const getMasterCategoriesReducer = (state = { masterCategories: {} }, action: any) => {
    switch (action.type) {
        case GET_MASTER_CATEGORIES_REQUEST:
            return {
                ...state,
                masterCategoriesLoading: true,
                masterCategoriesError: null
            }
        case GET_MASTER_CATEGORIES_SUCCESS:
            return {
                ...state,
                masterCategoriesLoading: false,
                masterCategories: action.payload
            }
        case GET_MASTER_CATEGORIES_FAIL:
            return {
                ...state,
                masterCategoriesLoading: false,
                masterCategoriesError: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getMasterProductsReducer = (state = { masterProducts: {} }, action: any) => {
    switch (action.type) {
        case GET_MASTER_PRODUCTS_REQUEST:
            return {
                ...state,
                masterProductsLoading: true,
                masterProductsError: null
            }
        case GET_MASTER_PRODUCTS_SUCCESS:
            return {
                ...state,
                masterProductsLoading: false,
                masterProducts: action.payload
            }
        case GET_MASTER_PRODUCTS_FAIL:
            return {
                ...state,
                masterProductsLoading: false,
                masterProductsError: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const copyProductsReducer = (state = { copyProductsMessage: {} }, action: any) =>{
    switch(action.type){
        case POST_COPY_PRODUCTS_REQUEST:
            return{
                ...state,
                copyProductsLoading: true,
                copyProductsError: null
            }
        case POST_COPY_PRODUCTS_SUCCESS:
            return{
                ...state,
                copyProductsLoading: false,
                copyProductsMessage: action.payload
            }
        case POST_COPY_PRODUCTS_FAIL:
            return{
                ...state,
                copyProductsLoading: false,
                copyProductsError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}
