import { IonAlert, IonBadge, IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLoading, IonModal, IonPage, IonRow, IonText, IonTitle, useIonToast } from '@ionic/react'
import { useEffect, useState } from 'react'
import Header from '../../components/dashboard/Header'
import { useDispatch, useSelector } from 'react-redux'
import { getAccessToken } from '../../services/auth/userLocalStorage';
import { getPickupLocations, getShiprocketOrder } from '../../actions/shiprocketActions';
import { checkmarkSharp, closeCircle, closeOutline, create, print } from 'ionicons/icons';
import { useHistory } from 'react-router';
import useFetch from '../../utils/useFetch';
import { useSharedData } from '../../utils/contexts';

const ShiprocketOrder = ({ location }) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const accessToken = getAccessToken();
    const [present] = useIonToast();

    const { currentUser } = useSelector((state: any) => state.currentUser);
    const { shiprocketOrdersLoading, shiprocketOrders, shiprocketOrdersError } = useSelector((state: any) => state.shiprocketOrders);
    const { pickupLocationsLoading, pickupLocations, pickupLocationsError } =
        useSelector((state: any) => state.pickupLocations);
    const sharedData: any = useSharedData();
    const { data } = useFetch("shiprocketAddon", sharedData.shiprocketUpdate);

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false)

    const [showAlertForCancel, setShowAlertForCancel] = useState(false);

    const [selectAll, setSelectAll] = useState(false);

    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [selectedItemsShipment, setSelectedItemsShipment] = useState<any>([]);
    const [pickupLocationData, setPickupLocationData] = useState<any>([]);
    const [pickupLocation, setPickupLocation] = useState<string>("");

    const [highlightedOrderId, setHighlightedOrderId] = useState(null);

    useEffect(() => {
        if (currentUser && currentUser.data) {
            dispatch(getShiprocketOrder(accessToken, currentUser.data.store.subdomain))
        }
    }, [currentUser])

    useEffect(() => {
        if (pickupLocationsLoading === false && pickupLocations && pickupLocations.data) {
            setPickupLocationData(pickupLocations.data);
        }

    }, [pickupLocationsLoading]);

    useEffect(() => {
        if (shiprocketOrdersLoading === false && shiprocketOrders && shiprocketOrders.data && location.state) {
            setHighlightedOrderId(location.state);
            setTimeout(() => {
                setHighlightedOrderId(null);
            }, 3000);
        }
    }, [shiprocketOrdersLoading, location.state]);

    const paymentStatusColor = {
        "PAID": "warning",
        "COD": "danger"
    }

    const cancelOrders = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                `https://api2.intelikart.in/shiprocket/cancel`, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    'authorization': `Bearer ${accessToken}`,
                    'x-ik-domain': `${currentUser.data.store.subdomain}`
                },
                body: JSON.stringify({
                    order_id: selectedItems,
                }),
            }
            );
            const data = await response.json();
            present(data.message, 1000);

            setSelectedItems([]);
            setSelectedItemsShipment([])
            setSelectAll(false);
            dispatch(getShiprocketOrder(accessToken, currentUser.data.store.subdomain))

        } catch (error) {
            return "";
        }
        setLoading(false);
    }

    const openModal = async () => {
        setLoading(true)
        await dispatch(getPickupLocations(accessToken, currentUser.data.store.subdomain));
        setLoading(false)
        setShowModal(true)
    }

    const closeModal = async (pickup_location) => {
        setPickupLocation(pickup_location)
        setShowModal(false)
        setLoading(true)

        try {
            const response = await fetch(
                `https://api2.intelikart.in/shiprocket/pickup-location`, {

                method: "PUT",
                headers: {
                    "content-type": "application/json",
                    'authorization': `Bearer ${accessToken}`,
                    'x-ik-domain': `${currentUser.data.store.subdomain}`
                },
                body: JSON.stringify({
                    order_id: selectedItems,
                    pickup_location: pickup_location
                }),
            }
            );
            const data = await response.json();
            if (data.message) {
                present(data.message, 1000);
            }

            setSelectedItems([]);
            setSelectedItemsShipment([])
            setSelectAll(false);
            dispatch(getShiprocketOrder(accessToken, currentUser.data.store.subdomain))

        } catch (error) {
            return "";
        }
        setPickupLocation('')
        setLoading(false)
    }

    const handleCheckboxClick = (orderId, shipmentId) => {
        const isSelected = selectedItems.includes(orderId);
        if (isSelected) {
            setSelectedItems(selectedItems.filter((id) => id !== orderId));
            setSelectedItemsShipment(selectedItemsShipment.filter(id => id !== shipmentId))
            setSelectAll(false);
        } else {
            let temp = [...selectedItems]
            temp.push(orderId)

            setSelectedItems(temp);

            let temp2 = [...selectedItemsShipment]
            temp2.push(shipmentId)
            setSelectedItemsShipment(temp2)

            if (temp.length === shiprocketOrders?.data?.length) {
                setSelectAll(true);
            }
        }
    };

    const handleSelectAll = async () => {
        setSelectAll(!selectAll);
        if (!selectAll === true) {
            let orders = shiprocketOrders?.data?.map(x => x.shiprocket_order_id)
            let ship = shiprocketOrders?.data?.map(x => x.shipment_id)
            setSelectedItems(orders)
            setSelectedItemsShipment(ship)
        }
        else {
            setSelectedItems([]);
            setSelectedItemsShipment([]);
        }
    }

    const geneateAWB = async (shipmentId) => {
        setLoading(true)
        try {
            const response = await fetch(
                `https://api2.intelikart.in/shiprocket/awb/${shipmentId}`, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    'authorization': `Bearer ${accessToken}`,
                    'x-ik-domain': `${currentUser.data.store.subdomain}`
                }
            }
            );
            const data = await response.json();
            present(data.message, 1000);

            dispatch(getShiprocketOrder(accessToken, currentUser.data.store.subdomain))

        } catch (error) {
            return "";
        }
        setLoading(false)
    }

    const generateLabel = async () => {
        setLoading(true)
        try {
            const response = await fetch(
                `https://api2.intelikart.in/shiprocket/label`, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    'authorization': `Bearer ${accessToken}`,
                    'x-ik-domain': `${currentUser.data.store.subdomain}`
                },
                body: JSON.stringify({
                    shipment_id: selectedItemsShipment,
                }),
            }
            );
            const data = await response.json();
            if (data.label_url) {
                const link = document.createElement('a');
                link.href = data.label_url;
                link.setAttribute('download', 'label.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            if (data.message) {
                present(data.message, 1000);
            }
            setSelectedItems([]);
            setSelectedItemsShipment([])
            setSelectAll(false);
            dispatch(getShiprocketOrder(accessToken, currentUser.data.store.subdomain))

        } catch (error) {
            return "";
        }
        setLoading(false)
    }

    return (
      <IonPage>
        <div className="ion-page" id="main-content">
          <Header name="Shiprocket Orders" />
          {data?.data?.status === "active" ? (
            <>
              <IonContent className="ion-padding">
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonButton
                        fill="outline"
                        onClick={() =>
                          history.push("/shiprocket/addpickuplocation")
                        }
                      >
                        Add Pickup Location
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <br />

                {shiprocketOrdersLoading === false &&
                shiprocketOrders &&
                shiprocketOrders.data &&
                shiprocketOrders.data.length !== 0 ? (
                  <>
                    <IonItem lines="none">
                      <IonGrid className="ion-no-padding">
                        <IonRow>
                          <IonCol size="1">
                            <IonCheckbox
                              checked={selectAll}
                              onClick={(e) => handleSelectAll()}
                              style={{
                                fontSize: "20px",
                                marginRight: "20px",
                                marginTop: "5px",
                              }}
                            />
                          </IonCol>
                          <IonCol size="1">
                            <IonText>
                              <b>Order #</b>
                            </IonText>
                          </IonCol>
                          <IonCol size="1">
                            <IonText>
                              <b>Total</b>
                            </IonText>
                          </IonCol>
                          <IonCol>
                            <IonText>
                              <b>Address</b>
                            </IonText>
                          </IonCol>
                          <IonCol size="2">
                            <IonText>
                              <b>Pickup</b>
                            </IonText>
                          </IonCol>
                          <IonCol size="1">
                            <IonText>
                              <b>Status</b>
                            </IonText>
                          </IonCol>
                          <IonCol size="2">
                            <IonText>
                              <b>Action</b>
                            </IonText>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    {shiprocketOrders.data.map((order: any) => {
                      return (
                        <IonItem
                          key={order.id}
                          color={highlightedOrderId == order.id ? "light" : ""}
                        >
                          <IonGrid className="ion-no-padding">
                            <IonRow className="ion-align-items-center">
                              <IonCol size="1">
                                <IonCheckbox
                                  style={{ margin: 0, padding: 0 }}
                                  checked={selectedItems.find(
                                    (id) => order.shiprocket_order_id === id
                                  )}
                                  onClick={() =>
                                    handleCheckboxClick(
                                      order.shiprocket_order_id,
                                      order.shipment_id
                                    )
                                  }
                                />
                              </IonCol>
                              <IonCol size="1">
                                <IonText>{order.id}</IonText>
                              </IonCol>
                              <IonCol size="1">
                                <IonText>{order.amount}</IonText>
                                <br />
                                <IonBadge
                                  color={`${
                                    paymentStatusColor[order.payment_method]
                                  }`}
                                >
                                  {order.payment_method}
                                </IonBadge>
                              </IonCol>
                              <IonCol>
                                <IonText>
                                  {order.customer_address}
                                  <br />
                                  {order.customer_city} -{" "}
                                  {order.customer_pincode}
                                </IonText>
                              </IonCol>
                              <IonCol size="2">
                                <IonText>{order.pickup_location}</IonText>
                              </IonCol>
                              <IonCol size="1">
                                <IonText>{order.status}</IonText>
                              </IonCol>
                              <IonCol size="2">
                                <IonButton
                                  fill="clear"
                                  onClick={() => geneateAWB(order.shipment_id)}
                                >
                                  <IonText>Generate AWB</IonText>
                                </IonButton>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonItem>
                      );
                    })}
                  </>
                ) : (
                  <IonText className="ion-text-center">
                    <h5>There are no Shiprocket orders.</h5>
                  </IonText>
                )}

                <IonLoading
                  isOpen={shiprocketOrdersLoading === true || loading}
                  message="Please Wait"
                />

                <IonAlert
                  isOpen={showAlertForCancel}
                  onDidDismiss={() => {
                    setShowAlertForCancel(false);
                  }}
                  header={"Cancel the order"}
                  message={`Are you sure?`}
                  buttons={[
                    {
                      text: "NO",
                      role: "cancel",
                    },
                    {
                      text: "YES",
                      handler: () => {
                        cancelOrders();
                      },
                    },
                  ]}
                />

                <IonModal
                  isOpen={showModal}
                  onDidDismiss={() => setShowModal(false)}
                >
                  <IonHeader>
                    <IonItem lines="none">
                      <IonTitle>Change pickup location</IonTitle>
                      <IonButton
                        style={{ marginLeft: 0 }}
                        fill="clear"
                        size="large"
                        slot="end"
                        onClick={() => setShowModal(false)}
                      >
                        <IonIcon slot="icon-only" icon={closeOutline} />
                      </IonButton>
                    </IonItem>
                  </IonHeader>
                  <IonContent className="ion-padding">
                    {pickupLocationData?.map((data, index) => (
                      <IonItem
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          closeModal(data.pickup_location);
                        }}
                      >
                        <IonText>
                          <h5>{data.pickup_location}</h5>
                          <p className="ion-no-margin ion-margin-bottom">
                            {data.address}
                          </p>
                        </IonText>
                        {pickupLocation &&
                        pickupLocation == data.pickup_location ? (
                          <IonIcon
                            slot="end"
                            color="primary"
                            size="large"
                            icon={checkmarkSharp}
                          />
                        ) : (
                          ""
                        )}
                      </IonItem>
                    ))}
                  </IonContent>
                </IonModal>
              </IonContent>
              <IonRow>
                <IonCol>
                  <IonButton
                    onClick={() => {
                      generateLabel();
                    }}
                    expand="full"
                    color="dark"
                    className={`${
                      selectedItemsShipment?.length > 0 ? "" : "ion-hide"
                    }`}
                  >
                    <IonIcon icon={print}></IonIcon> &nbsp; Generate Label
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    onClick={() => {
                      setShowAlertForCancel(true);
                    }}
                    expand="full"
                    color="danger"
                    className={`${selectedItems?.length > 0 ? "" : "ion-hide"}`}
                  >
                    <IonIcon icon={closeCircle}></IonIcon> &nbsp; Cancel All
                    Selected
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    onClick={() => {
                      openModal();
                    }}
                    expand="full"
                    color="dark"
                    className={`${selectedItems?.length > 0 ? "" : "ion-hide"}`}
                  >
                    <IonIcon icon={create}></IonIcon> &nbsp; Change Pickup
                    Location
                  </IonButton>
                </IonCol>
              </IonRow>
            </>
          ) : (
            <IonContent className="ion-padding ion-text-center">
                <IonText><b>Your Shiprocket is not Configured</b></IonText><br/>
                <IonButton shape="round" style={{marginTop:'10px'}}
                  onClick={() => history.push("/add-ons/shiprocket-configure")}
                >
                 Configure Shiprocket
                </IonButton>
            </IonContent>
          )}
        </div>
      </IonPage>
    );
}

export default ShiprocketOrder