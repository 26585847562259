export const GET_PRODUCT_BY_ID_REQUEST = 'GET_PRODUCT_BY_ID_REQUEST'
export const GET_PRODUCT_BY_ID_SUCCESS = 'GET_PRODUCT_BY_ID_SUCCESS'
export const GET_PRODUCT_BY_ID_FAIL = 'GET_PRODUCT_BY_ID_FAIL'

export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_FAIL = 'CREATE_PRODUCT_FAIL'

export const ADD_PRODUCT_IN_BULK_REQUEST = 'ADD_PRODUCT_IN_BULK_REQUEST'
export const ADD_PRODUCT_IN_BULK_SUCCESS = 'ADD_PRODUCT_IN_BULK_SUCCESS'
export const ADD_PRODUCT_IN_BULK_FAIL = 'ADD_PRODUCT_IN_BULK_FAIL'

export const EDIT_PRODUCT_REQUEST = 'EDIT_PRODUCT_REQUEST'
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS'
export const EDIT_PRODUCT_FAIL = 'EDIT_PRODUCT_FAIL'

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL'

export const SEARCH_PRODUCT_QUERY_REQUEST = 'SEARCH_PRODUCT_QUERY_REQUEST'
export const SEARCH_PRODUCT_QUERY_SUCCESS = 'SEARCH_PRODUCT_QUERY_SUCCESS'
export const SEARCH_PRODUCT_QUERY_FAIL = 'SEARCH_PRODUCT_QUERY_FAIL'

export const BULK_EDIT_REQUEST = 'BULK_EDIT_REQUEST'
export const BULK_EDIT_SUCCESS = 'BULK_EDIT_SUCCESS'
export const BULK_EDIT_FAIL = 'BULK_EDIT_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
