import { createContext, useContext } from 'react';

export const AppContext = createContext({
  user: {},
  siteId: null,
  updateUser: () => {}
});

export const UserContext = createContext({});

export const useUser = () => {
  return useContext(UserContext);
};

export const SharedContext = createContext({});

export const useSharedData = () => {
  return useContext(SharedContext);
};

export const AddonContext = createContext({});

export const useAddon = () => {
  return useContext(AddonContext);
};

export const PagesContext = createContext({});

export const usePagesForUpdate = () => {
  return useContext(PagesContext);
};

export const OrderStatusContext = createContext({});

export const useOrderStatusChangeData = () => {
    return useContext(OrderStatusContext);
  };

export const StaffContext = createContext({});

export const useStaffForUpdate = () => {
  return useContext(StaffContext);
};

export const CategoryContext = createContext({});

export const useCategory = () => {
  return useContext(CategoryContext);
};

export const WebsiteSettingsContext = createContext({});

export const useWebsiteSettings = () => {
  return useContext(WebsiteSettingsContext);
};

export const DynamicStatusContext = createContext({});

export const useDynamicStatusUpdate = () => {
  return useContext(DynamicStatusContext);
};

export const TimeslotContext = createContext({});

export const useTimeSlotUpdate = () => {
  return useContext(TimeslotContext);
};



export const IsStaffContext = createContext({});

export const useIsStaffUpdate = () => {
  return useContext(IsStaffContext);
};
export const BrandsContext = createContext({});

export const useBrands = () => {
  return useContext(BrandsContext);
};

export const DeliveryOrderContext = createContext({});

export const useDeliveryOrderUpdate = () => {
  return useContext(DeliveryOrderContext);
};

export const InventoryStocksContext = createContext({});

export const useInventoryStocksUpdate = () => {
  return useContext(InventoryStocksContext);
};

export const CartContext = createContext<{
  myCartData: any;
  setMyCartData: React.Dispatch<React.SetStateAction<any>>;
}>({
  myCartData: null,
  setMyCartData: () => {}
});

export const useCart = () => {
  return useContext(CartContext);
};


export const CustomerContext = createContext<{
  customer: any;
  setCustomer: React.Dispatch<React.SetStateAction<any>>;
}>({
  customer: null,
  setCustomer: () => {}
});

export const useCustomer = () => useContext(CustomerContext);

export const MediaLibraryContext = createContext({});

export const useMediaLibrary = () => {
    return useContext(MediaLibraryContext);
  };