import {
  IonBadge,
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonPopover,
  IonRow,
  IonText
} from "@ionic/react";
import {
  call as callIcon,
  checkmarkOutline as checkmarkOutlineIcon,
  closeOutline as closeOutlineIcon,
  documentText as doc,
  time
} from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../services/formatters";
import useAPI from "../../utils/api";
import { useOrderStatusChangeData } from "../../utils/contexts";
import { StoreCurrency } from '../../utils/currency';

const ShortInfo = (props) => {
  const [showPopover, setShowPopover] = useState<{open: boolean; event: Event | undefined;}>({
    open: false,
    event: undefined,
  });
  const [showPopover1, setShowPopover1] = useState<{open: boolean; event: Event | undefined;}>({
    open: false,
    event: undefined,
  });
	const {setFilterOrderData, setPageCountData, setInfiniteScrollData, update, setUpdate}: any = useOrderStatusChangeData();
  const API = useAPI();
  const currency = StoreCurrency();
  const { order } = props;

  const paymentStatusColor = {
    PAID: "warning",
    COD: "danger",
  };

  const history = useHistory();

  async function orderStatusChange(
    orderStatus: boolean,
    scheduleDate,
    scheduleTime
  ) {
    if (orderStatus) {
      if (scheduleDate || scheduleTime) {
        const [ok, response] = await API.changeOrderStatus(order?.id, "SCHEDULED", "");
        if (ok) {
          updateStatusData(order?.status,"SCHEDULED");
        }
      } else {
        const [ok, response] = await API.changeOrderStatus(order?.id, "NEW", "");
        if (ok) {
          updateStatusData(order?.status,"NEW");
        }
      }   
    } else {
      const [ok, response] = await API.changeOrderStatus(order?.id, "DENY", "");
      if (ok) {
        updateStatusData(order?.status,"DENY");
      }
    }
  }

  const updateStatusData = (currentStatus, updatedStatus) => {
    let pageTemp = {...props?.page};
    pageTemp["ALL"] = 1;
    pageTemp["PENDING"] = 1;
    pageTemp[`${currentStatus}`] = 1;
    if(updatedStatus in pageTemp){
      pageTemp[`${updatedStatus}`] = 1;
    }
    props?.setPage(pageTemp);
    setPageCountData(pageTemp);

    let dataTemp = {...props?.filterArrayObject};
    dataTemp["ALL"] = [];
    dataTemp["PENDING"] = [];
    dataTemp[`${currentStatus}`] = [];
    if(updatedStatus in dataTemp){
      dataTemp[`${updatedStatus}`] = [];
    }
    props?.setFilterArrayObject(dataTemp);
    setFilterOrderData(dataTemp);

    let infiniteScrollTemp = {...props?.disableInfiniteScroll};
    infiniteScrollTemp["ALL"] = false;
    infiniteScrollTemp["PENDING"] = false;
    infiniteScrollTemp[`${currentStatus}`] = false;
    if(updatedStatus in infiniteScrollTemp){
      infiniteScrollTemp[`${updatedStatus}`] = false;
    }
    props?.setDisableInfiniteScroll(infiniteScrollTemp)
    setInfiniteScrollData(infiniteScrollTemp);

    //To trigger the update on order page.
    setUpdate(!update)
  }

  return (
    <div key={order.id}>
      <IonCard key={order.id}>
        <IonItem lines="none">
          <IonText slot="start">
            <h5>Order #{order.id}</h5>
          </IonText>
          {order.schedule_order_date !== null && (
            <>
              <h6 style={{ display: "flex", alignItems: "center" }}>
                <IonButton
                  fill="clear"
                  color="secondary"
                  onClick={(e) =>
                    setShowPopover({ open: true, event: e.nativeEvent })
                  }
                >
                  <IonIcon icon={time} slot="icon-only" size="medium"></IonIcon>
                </IonButton>{" "}
              </h6>
              <IonPopover
                isOpen={showPopover.open}
                event={showPopover.event}
                onDidDismiss={() =>
                  setShowPopover({ open: false, event: undefined })
                }
                showBackdrop={false}
                id="popover"
              >
                <IonContent className="ion-padding">
                  <IonText style={{ fontSize: "14px" }}>
                    {order.schedule_order_date} - {order.schedule_order_time}
                  </IonText>
                </IonContent>
              </IonPopover>
            </>
          )}
          <IonText>
            <h5>by {order.user_name}</h5>
          </IonText>
          <IonText slot="end">
            <h5>{formatDate(order.created_at, "DD/MM/YY h:mm A")}</h5>
          </IonText>
        </IonItem>
        <IonRow style={{ width: "100%", color: "black" }}>
        {order.products[0] && order.products[0].variant_image_url_small ? (
    <IonCol size="3">
      <IonImg
        src={order.products[0].variant_image_url_small}
        style={{ height: "15vh", width: "100%" }}
      />
    </IonCol>
  ) : (
    <IonCol size="3"></IonCol> // Empty column to maintain layout
  )}
          <IonCol size="9">
            <IonRow className="ion-no-padding">
              {order.products[0] && <h5>{order.products[0].variant_name}</h5>}
            </IonRow>
            <IonRow>
              <IonCol>
                <h6>
                  {currency} {order.order_final_price}
                </h6>
              </IonCol>
              <IonCol style={{ textAlign: "right",}} sizeMd="1">
                <IonBadge style={{ textAlign: "right",}} color={`${paymentStatusColor[order.payment_status]}`}>
                  {order.payment_status}
                </IonBadge>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonItem lines="none">
          <IonText slot="start">
            <b>{order.status}</b>
          </IonText>
          {order.status == "SCHEDULED" && (
            <IonText>
              {order.schedule_order_date} - {order.schedule_order_time}
            </IonText>
          )}
          {order.products[0]?.digital_product?.length === 0 || order.pricing_breakup.subTotal==0 ? (
            ""
          ) : (
            <IonText>
              <IonButton
                fill="clear"
                color="secondary"
                onClick={(e) =>
                  setShowPopover1({ open: true, event: e.nativeEvent })
                }
              >
                <IonIcon icon={doc} slot="start" size="medium"></IonIcon>
              </IonButton>{" "}
              <IonPopover
                isOpen={showPopover1.open}
                event={showPopover1.event}
                onDidDismiss={() =>
                  setShowPopover1({ open: false, event: undefined })
                }
                showBackdrop={false}
                id="popover1"
              >
                <IonContent className="ion-padding">
                  <IonText style={{ fontSize: "14px" }}>
                    Digital Product
                  </IonText>
                </IonContent>
              </IonPopover>
            </IonText>
          )}
          <IonItem lines="none" slot="end" className="ion-no-padding">
            {order?.available_carrier_service?.includes("shiprocket") &&
              order?.fulfillment !== null && (
                <IonButton
                  onClick={() =>
                    history.push({ pathname: `/delivery/shiprocket`, state: order.id })
                  }
                >
                  Fulfilled by Shiprocket
                </IonButton>
              )}
            <IonButton href={`tel:${order.user_phone}`}>
              <IonIcon icon={callIcon}></IonIcon>
            </IonButton>
            <IonButton
              onClick={() =>
                {
                  history.push({
                  pathname: `/orders/orderdetails`,
                  state: order.id,
                })}
              }
            >
              Details &gt;
            </IonButton>
          </IonItem>
        </IonItem>
        {order.status === "PENDING" && (
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton
                  color="success"
                  expand="block"
                  onClick={(e) =>
                    orderStatusChange(
                      true,
                      order.schedule_order_date,
                      order.schedule_order_time
                    )
                  }
                >
                  <IonIcon icon={checkmarkOutlineIcon}></IonIcon>
                  Accept
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton
                  color="danger"
                  expand="block"
                  onClick={(e) =>
                    orderStatusChange(false, order.schedule_order_date, null)
                  }
                >
                  <IonIcon icon={closeOutlineIcon}></IonIcon>
                  Reject
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonCard>
    </div>
  );
};

export default ShortInfo;
