import {
  IonAccordion,
  IonCard,
  IonInput,
  IonItem,
  IonLabel,
  IonReorder,
  IonToggle,
} from "@ionic/react";
import { useWebsiteSettings } from "../../../../../utils/contexts";

const Brands = ({ data }) => {
  const settings: any = useWebsiteSettings();
  return (
    <IonCard>
      <IonAccordion>
        <IonItem slot="header" color="light">
          <IonLabel>Brands</IonLabel>
          {data.reorder && <IonReorder slot="end" />}
          {data.toggle && (
            <IonToggle
              checked={settings.websiteSettings.brand_sliders.display === 1}
              color="medium"
              onIonChange={(e) =>
                settings.setWebsiteSettings({
                  ...settings.websiteSettings,
                  brand_sliders: {
                    ...settings.websiteSettings.brand_sliders,
                    display: e.detail.checked ? 1 : 0,
                  },
                })
              }
              slot="end"
            ></IonToggle>
          )}
        </IonItem>
        <div className="ion-padding" slot="content">
          <IonItem>
            <IonLabel position="stacked">Heading</IonLabel>
            <IonInput
              placeholder="Write heading here."
              type="text"
              value={settings.websiteSettings.brand_sliders.component_heading}
              onIonChange={(e) =>
                settings.setWebsiteSettings({
                  ...settings.websiteSettings,
                  brand_sliders: {
                    ...settings.websiteSettings.brand_sliders,
                    component_heading: e.detail.value,
                  },
                })
              }
            />
          </IonItem>
        </div>
      </IonAccordion>
    </IonCard>
  );
};

export default Brands;
