import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { loadCategoriesReducer } from './reducers/categoryReducer';

import { gaConfigurationReducer, shiprocketConfigurationReducer, whatsappConfigurationReducer } from './reducers/addonsReducers';
import {
    loadBusinessTypeListReducer,
    loadCurrentUserReducer,
    loadUserTokensReducer,
    userAuthenticateReducer,
    userOnboardingReducer
} from './reducers/authReducers';
import {
    changeOrderStaffReducer,
    changeOrderStatusReducer,
    createAnnouncementReducer,
    createBannerReducer,
    createCouponReducer,
    createDeliveryOrder,
    createTaxReducer,
    deleteAdjustmentReducer,
    deleteBannerReducer,
    deleteCouponReducer,
    deleteDeliveryOrderReducer,
    deletePushNotificationReducer,
    deleteTaxReducer,
    dynamicMessagereducer,
    editAdjustmentReducer,
    getBasicInfoReducer,
    getCartReducer,
    getCashfreeReducer,
    getCommunicationReducer,
    getLocalizationReducer,
    getPaymentReducer,
    getPhonepeReducer,
    getQuickOrderReducer,
    getRazorPayReducer,
    getSalesReducer,
    getServiceDetailReducer,
    getSocialProfileReducer,
    getTagLineReducer,
    getVisitorsReducer,
    loadAcademyContentReducer,
    loadAdjustmentsReducer,
    loadAnnouncementsReducer,
    loadBannersReducer,
    loadBrandingColorsReducer,
    loadCouponsReducer,
    loadCustomersReducer,
    loadDeliveryOrder,
    loadEmailStatsReducer,
    loadHomeScreenReducer,
    loadHomeScreenWithDaysReducer,
    loadInvoiceReducer,
    loadOrderByIdReducer,
    loadOrdersReducer,
    loadPrintablesReducer,
    loadProductsBySubcategoryReducer,
    loadProfileCompletionDataReducer,
    loadTaxesReducer,
    loadThemesReducer,
    loadTipBannersReducer,
    postSocialProfileReducer,
    sendEmailsReducer,
    setAdjustmentReducer,
    setBasicInfoReducer,
    setBrandingColorReducer,
    setCashfreeReducer,
    setLocalizationReducer,
    setPaymentReducer,
    setPhonepeReducer,
    setPushNotificationReducer,
    setQuickOrderReducer,
    setRazorPayReducer,
    setServiceDetailReducer,
    setThemeReducer,
    updateDeliveryOrderReducer,
    uploadImageReducer
} from './reducers/dashboardReducers';
import { deleteMediaImageReducer, getMediaImageReducer, uploadMediaImageReducer } from './reducers/mediaReducers';
import { addProductInBulkReducer, bulkEditProductReducer, createProductReducer, deleteProductReducer, editProductReducer, loadProductByIdReducer, searchProductQueryReducer } from './reducers/productReducer';
import { createPickupLocationReducer, createShiprocketOrderReducer, loadPickupLocationsReducer, loadShiprocketOrdersReducer } from './reducers/shiprocketReducers';
import { copyProductsReducer, getMasterCategoriesReducer, getMasterProductsReducer } from './reducers/masterCatalogueReducer';

const reducer = combineReducers({
    userAPITokens: loadUserTokensReducer,
    currentUser: loadCurrentUserReducer,
    userAuthenticate: userAuthenticateReducer,
    businessTypeList: loadBusinessTypeListReducer,
    userOnboarding: userOnboardingReducer,
    categories: loadCategoriesReducer,
    tipBanners: loadTipBannersReducer,
    homeScreenData: loadHomeScreenReducer,
    homeScreenDataWithDays: loadHomeScreenWithDaysReducer,
    academyContent: loadAcademyContentReducer,
    customers: loadCustomersReducer,
    deleteCouponData: deleteCouponReducer,
    createCouponData: createCouponReducer,
    coupons: loadCouponsReducer,
    banners: loadBannersReducer,
    addBannerData: createBannerReducer,
    deleteBannerData: deleteBannerReducer,
    setBasicInfo: setBasicInfoReducer,
    basicInfo: getBasicInfoReducer,
    serviceDetail: getServiceDetailReducer,
    setServiceDetail: setServiceDetailReducer,
    quickOrder: getQuickOrderReducer,
    setQuickOrder: setQuickOrderReducer,
    payment: getPaymentReducer,
    setPayment: setPaymentReducer,
    RazorPay: getRazorPayReducer,
    setRazorPay: setRazorPayReducer,
    setLocalization: setLocalizationReducer,
    localization: getLocalizationReducer,
    taxes: loadTaxesReducer,
    createTaxData: createTaxReducer,
    deleteTaxData: deleteTaxReducer,
    uploadImageResponse: uploadImageReducer,
    orders: loadOrdersReducer,
    invoice: loadInvoiceReducer,
    changeOrderStatusData: changeOrderStatusReducer,
    changeOrderStaffData: changeOrderStaffReducer,
    adjustments: loadAdjustmentsReducer,
    setAdjustmentData: setAdjustmentReducer,
    editAdjustmentData: editAdjustmentReducer,
    deleteAdjustmentData: deleteAdjustmentReducer,
    printables: loadPrintablesReducer,
    loadAnnouncementsReducer: loadAnnouncementsReducer,
    createAnnouncementReducer: createAnnouncementReducer,
    orderById: loadOrderByIdReducer,
    productListBySubcategory: loadProductsBySubcategoryReducer,
    deleteProductData: deleteProductReducer,
    productById: loadProductByIdReducer,
    createProducData: createProductReducer,
    addProductInBulkData: addProductInBulkReducer,
    editProductData: editProductReducer,
    bulkEditData: bulkEditProductReducer,
    searchQueryProductData: searchProductQueryReducer,
    profileCompletionData: loadProfileCompletionDataReducer,
    themes: loadThemesReducer,
    setThemeData: setThemeReducer,
    brandingColor: loadBrandingColorsReducer,
    setBrandingColorData: setBrandingColorReducer,
    gaConfig: gaConfigurationReducer,
    whatsappConfig: whatsappConfigurationReducer,
    shiprocketConfig: shiprocketConfigurationReducer,
    emailStats: loadEmailStatsReducer,
    sendEmailsData: sendEmailsReducer,
    sales: getSalesReducer,
    visitors: getVisitorsReducer,
    cart: getCartReducer,
    setPushNotification: setPushNotificationReducer,
    deletePushNotificationData: deletePushNotificationReducer,
    getCommunication: getCommunicationReducer,
    getTagLine: getTagLineReducer,
    cashfree: getCashfreeReducer,
    setCashfree: setCashfreeReducer,
    phonepe: getPhonepeReducer,
    setPhonepe: setPhonepeReducer,
    mediaLibrary: getMediaImageReducer,
    uploadmediaLibrary: uploadMediaImageReducer,
    deletemediaLibrary: deleteMediaImageReducer,
    setDynamicMessage: dynamicMessagereducer,
    postSocialProfile: postSocialProfileReducer,
    getSocialProfile: getSocialProfileReducer,
    pickupLocations: loadPickupLocationsReducer,
    createShiprocketOrder: createShiprocketOrderReducer,
    createPickupLocation: createPickupLocationReducer,
    shiprocketOrders: loadShiprocketOrdersReducer,
    masterCategories: getMasterCategoriesReducer,
    masterProducts: getMasterProductsReducer,
    copyMasterProducts: copyProductsReducer,
    createDeliveryOrder:createDeliveryOrder,
    deliveryOrders:loadDeliveryOrder,
    deleteDeliveryOrderdata:deleteDeliveryOrderReducer,
    updateDeliveryOrderdata:updateDeliveryOrderReducer

})

let initialState = {};

const middleware = [thunk];
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
