import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { open as openIcon } from 'ionicons/icons';
import { useRef, useState } from 'react';
import QRCode from "react-qr-code";
import { useUser } from '../../../utils/contexts';
import Header from './components/Header';
import SideMenu from './components/SideMenu';

const screenSizes = {
    desktop: {
        width: '100%',
        height: '100%'
    },
    mobile: {
        width: '38%',
        height: '90%'
    },
    tablet: {
        width: '95%',
        height: '90%'
    }
}

const centeredContentStyled = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '93%'
}

const WebsiteSettings = (props) => {
    const user:any = useUser();
    const [screenType, setScreenType] = useState("desktop");
    const iframeRef:any = useRef();
    return (<>
        <Header screenType={screenType} setScreenType={setScreenType} forApp={props?.location?.state?.forApp}/>
        <IonGrid className="ion-no-padding" style={{height: '100%'}}>
            <IonRow className="ion-align-items-stretch" style={{height: '100%'}}>
                <IonCol size='12' sizeMd='4'>
                    <SideMenu iframeRef={iframeRef} forApp={props?.location?.state?.forApp}></SideMenu>
                </IonCol>
                <IonCol>
                    <IonCard color='medium' className="ion-no-margin" style={{height: '100%'}}>
                        <IonCardContent className='ion-no-padding' style={centeredContentStyled}>
                            {props?.location?.state?.forApp ? 
                                <>
                                    <div className='ion-text-center' style={{ height: "auto", margin: "0 auto", maxWidth: 500, width: "100%" }}>  
                                    {user?.store?.apk_url ? (
                                        <>                              
                                        <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
                                            <QRCode
                                                size={500}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                value={user?.store?.apk_url || ""}
                                            />
                                        </div>                                  
                                        <div >OR</div>                                       
                                        <a href={user?.store?.apk_url} target="_blank" rel="noreferrer"><IonButton fill='outline' shape='round' color='light'>Open url &nbsp;<IonIcon icon={openIcon} /></IonButton></a>
                                        <div>Scan the QRcode or visit the URL to download the mobile app to see the changes after saving them.      
                                            </div>                                                           
                                                     </>
                                    ):( 
                                        <div>Upgrade your subscription to include android mobile app</div>
                                    ) }
                                      
                                    </div>
                                </>
                                :
                                <iframe ref={iframeRef} src={`https://${user?.store?.subdomain}`} title='your website' style={screenSizes[screenType]}></iframe>

                            }
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </IonGrid>
    </>
    )
}

export default WebsiteSettings