import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonLabel,
  IonText,
  IonGrid,
  IonCol,
  IonItem,
  IonInput,
  IonIcon,
  IonButton,
  IonRow,
  IonLoading,
  IonPage,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  useIonToast,
  isPlatform,
} from "@ionic/react";
import Header from "../../components/dashboard/Header";
import {
  search as serachIcon,
  logoWhatsapp as whatsappIcon,
  call as phoneIcon,
  mailOutline,
} from "ionicons/icons";
import { Link, useHistory } from "react-router-dom";
import useFetch from "../../utils/useFetch";
import useAPI from "../../utils/api";
import { getCart } from "../../actions/dashboardActions";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import * as XLSX from "xlsx";
import { Directory, Filesystem } from "@capacitor/filesystem";
import write_blob from "capacitor-blob-writer";

const Customer = () => {
  const [loadingState, setLoadingState] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    list: [],
    page: 1,
    total: undefined,
  });
  const [searchCustomer, setSearchCustomer] = useState({
    inSearch: false,
    query: "",
    result: [],
    total: undefined,
    page: 1,
  });

  const [disableInfiniteScroll, setDisableInfiniteScroll] =
    useState<boolean>(false);
  let { cartLoading, cart, cartError } = useSelector(
    (state: any) => state.cart
  );
  let [cartedCustomers, setCartedCustomers] = useState(cart);
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser } = useSelector((state: any) => state.currentUser);
  const API = useAPI();
  const { loading, data, status } = useFetch("customers", customerDetails.page);
  const [cartChecked, setCartChecked] = useState(false);
  const [presentToast, dismissToast] = useIonToast();
  const [loadingExport, setLoadingExport] = useState(false);

  useEffect(() => {
    if (!loading && status === "fetched") {
      if (data?.data.length > 0) {
        setCustomerDetails({
          ...customerDetails,
          list: [...customerDetails.list, ...data?.data],
          total: data?.meta?.pagination.total,
        });
      }
      if (data?.data.length < 20 || data?.data.length === 0) {
        setDisableInfiniteScroll(true);
      }
    }
  }, [loading, data, status]);

  useEffect(() => {
    if (currentUser && currentUser.data) {
      dispatch(getCart(getAccessToken(), currentUser.data.store.subdomain));
    }
  }, [currentUser]);

  async function searchNext($event: CustomEvent<void>) {
    await fetchNextData();
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }
  const fetchNextData = async () => {
    if (searchCustomer.inSearch) {
      const [ok, response] = await API.customers(
        searchCustomer.page,
        searchCustomer.query
      );
      if (ok) {
        setSearchCustomer((prev) => {
          return {
            ...prev,
            result: [...prev.result, ...response.data],
            page: prev.page + 1,
          };
        });
      }
      if (response?.data.length < 20 || response?.data.length === 0) {
        setDisableInfiniteScroll(true);
      }
    } else {
      setCustomerDetails({
        ...customerDetails,
        page: customerDetails.page + 1,
      });
    }
  };

  async function searchCustomerHandler(e) {
    e.preventDefault();
           setLoadingState(true)

    if (!cartChecked) {
      setSearchCustomer({ ...searchCustomer, inSearch: true });
      const [ok, response] = await API.customers(
        searchCustomer.page,
        searchCustomer.query
      );
      if (ok) {
        setSearchCustomer((prev) => {
          return {
            ...prev,
            result: response.data,
            total: response.meta.pagination.total,
          };
        });

        if (response?.data.length < 20 || response?.data.length === 0) {
          setDisableInfiniteScroll(true);
        }
      }
    } else {
      setSearchCustomer({ ...searchCustomer, inSearch: true });
      let filter = cart.data.filter((items) => {
        if (items.user.first_name !== null) {
          let fullName = items.user.first_name + " " + items.user.last_name;
          fullName = fullName.toLowerCase();
          let query = searchCustomer.query;
          query = query.toLowerCase();
          return fullName.includes(query);
        } else {
          return false;
        }
      });
      let obj = { data: filter };
      setCartedCustomers(obj);
      searchCustomer.query = "";
    }
    setLoadingState(false);
  }

  const handleExport = async () => {
    try {
      setLoadingExport(true);
      let response = await fetch(`https://api2.intelikart.in/customers/export`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${getAccessToken()}`,
          "x-ik-domain": `${currentUser.data.store.subdomain}`,
        },
      });

      if (response && response.status == 200) {
        const blob = await response.blob();
        if (isPlatform("capacitor")) {
          try {
            write_blob({
              path: `customers.xlsx`,
              directory: Directory.Documents,
              blob: blob,
            });
          } catch (err) {
            presentToast("Error downloading file", 5000);
            return;
          }
        } else {
          let a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = "customers.xlsx";
          a.click();
        }
        setLoadingExport(false);
        presentToast("Downloading File", 2000);
      }
    } catch (err) {
      console.log(err);
      setLoadingExport(false);
    }
  };

  // const filterHandler = async (e: any) => {
  //     const selection = e.detail.value[0];
  //     if(selection === "cart")
  //     {
  //         setCartChecked(true);
  //     }
  //     else
  //     {
  //         setCartChecked(false);
  //     }

  // }

  return (
    <IonPage>
      <div className="ion-page" id="main-content">
        <Header name="Customer" docs={true} docslink="https://intelikart.tawk.help/article/customer" />
        <IonContent className="ion-padding">
          <form onSubmit={searchCustomerHandler}>
            <IonItem className="ion-margin-bottom">
              <IonLabel>
                <IonIcon icon={serachIcon}></IonIcon>
              </IonLabel>
              <IonInput
                placeholder="Enter Input"
                value={searchCustomer.query}
                onIonChange={(e) =>
                  setSearchCustomer((prev) => {
                    return { ...prev, query: e.detail.value };
                  })
                }
              ></IonInput>
              <IonButton type="submit" shape="round">
                Search
              </IonButton>
              {searchCustomer.query && (
                <IonButton
                  shape="round"
                  color="danger"
                  onClick={(e) => {
                    setSearchCustomer((prev) => {
                      return {
                        result: [],
                        query: "",
                        total: undefined,
                        inSearch: false,
                        page: 1,
                      };
                    });
                    setCartedCustomers([]);
                    customerDetails.total != customerDetails.list.length &&
                      setDisableInfiniteScroll(false);
                  }}
                >
                  Cancel
                </IonButton>
              )}
            </IonItem>
          </form>
          <IonGrid className="ion-no-padding">
            <IonRow className="ion-align-items-center">
              <IonCol className="ion-no-padding">
                <IonText>
                  Total:{" "}
                  {searchCustomer.inSearch
                    ? cartChecked
                      ? cartedCustomers?.data?.length
                      : searchCustomer.total
                    : cartChecked
                    ? cart.data.length
                    : customerDetails.total}
                </IonText>
              </IonCol>

              <IonCol
                sizeMd="6"
                sizeSm="6"
                sizeXs="12"
                className="ion-text-end ion-no-padding"
              >
                <Link
                  to={{ pathname: "/marketing/announcements", state: true }}
                >
                  <IonButton shape="round">Send Announcement</IonButton>
                </Link>
              </IonCol>

              {/*<IonCol sizeMd="2" sizeSm="6">
                                <IonList>
                                    <IonItem>
                                        <IonSelect placeholder='Filters' multiple={true} onIonChange={(e) => {filterHandler(e)}}>
                                            <IonSelectOption value="cart">Having Cart</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                </IonList>
                        </IonCol>*/}
            </IonRow>
          </IonGrid>
          <IonGrid>
            <IonRow className="ion-no-padding">
              <IonCol className="ion-text-right ion-no-padding">
                <IonButton
                  shape="round"
                  disabled={loadingExport}
                  onClick={handleExport}
                >
                  Export
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonGrid>
            <IonRow>
              <IonCol sizeSm="2.5" sizeXs="8">
                <IonText>
                  <b>Name</b>
                </IonText>
              </IonCol>
              {!isMobile && (
                <>
                  <IonCol sizeSm="3">
                    <IonText>
                      <b>Email</b>
                    </IonText>
                  </IonCol>
                  <IonCol sizeSm="2">
                    <IonText>
                      <b>Phone</b>
                    </IonText>
                  </IonCol>
                  <IonCol sizeSm="2" className="ion-text-center">
                    <IonText>
                      <b>Orders</b>
                    </IonText>
                  </IonCol>
                </>
              )}
              <IonCol sizeSm="2.5" sizeXs="4" className="ion-text-center">
                <IonText>
                  <b>Contact</b>
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonLoading isOpen={loading || loadingState} />
          {!cartChecked &&
            (searchCustomer.inSearch
              ? searchCustomer.result
              : customerDetails.list
            ).map((value: any) => {
              return (
                <IonItem key={value.id}>
                  {!loading && (
                    <IonGrid className="ion-no-padding">
                      <IonRow className="ion-align-items-center">
                        <IonCol sizeSm="2.5" sizeXs="6">
                          <IonText>{value.name || "-"}</IonText>
                        </IonCol>
                        {!isMobile && (
                          <>
                            <IonCol sizeSm="3">
                              <IonText>{value.email || "-"}</IonText>
                            </IonCol>
                            <IonCol sizeSm="2">
                              <IonText>{value.phone || "-"}</IonText>
                            </IonCol>
                            <IonCol sizeSm="2" className="ion-text-center">
                              <IonText>{value.orders || "-"}</IonText>
                            </IonCol>
                          </>
                        )}
                        <IonCol
                          sizeSm="0.7"
                          sizeXs="2"
                          className="ion-text-center"
                        >
                          <IonButton
                            fill="clear"
                            color="secondary"
                            href={value.whatsapp_link}
                            target="_blank"
                          >
                            <IonIcon
                              slot="icon-only"
                              icon={whatsappIcon}
                            ></IonIcon>
                          </IonButton>
                        </IonCol>

                        <IonCol
                          sizeSm="0.6"
                          sizeXs="2"
                          className="ion-text-center"
                        >
                          {value.email == "" ? (
                            ""
                          ) : (
                            <a href={`mailto:${value.email}`}>
                              <IonButton
                                fill="clear"
                                color="secondary"
                                target="_blank"
                              >
                                <IonIcon
                                  slot="icon-only"
                                  icon={mailOutline}
                                ></IonIcon>
                              </IonButton>
                            </a>
                          )}
                        </IonCol>

                        <IonCol
                          sizeSm="0.7"
                          sizeXs="2"
                          className="ion-text-center"
                        >
                          <IonButton
                            fill="clear"
                            color="secondary"
                            href={value.calling_link}
                            target="_blank"
                          >
                            <IonIcon
                              slot="icon-only"
                              icon={phoneIcon}
                            ></IonIcon>
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                </IonItem>
              );
            })}
          <IonInfiniteScroll
            disabled={disableInfiniteScroll}
            onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}
          >
            <IonInfiniteScrollContent loadingText="Loading more customers..."></IonInfiniteScrollContent>
          </IonInfiniteScroll>

          {cartChecked
            ? cartLoading === false &&
              cart &&
              cart.data &&
              cart.data.length !== 0
              ? (Object.keys(cartedCustomers)?.length !== 0
                  ? cartedCustomers
                  : cart
                ).data.map((value: any) => {
                  return (
                    <IonItem key={value.id}>
                      <IonGrid className="ion-no-padding">
                        <IonRow className="ion-align-items-center">
                          <IonCol size="5">
                            <IonText>
                              {value.user.first_name
                                ? value.user.first_name
                                : value.user.email}{" "}
                              {value.user.last_name ? value.user.last_name : ""}
                            </IonText>
                          </IonCol>
                          <IonCol size="3" className="ion-text-center">
                            <IonText>{value.products.length} Products</IonText>
                          </IonCol>
                          <IonCol size="3" className="ion-text-center">
                            <IonText>{value.user.phone}</IonText>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  );
                })
              : null
            : null}
        </IonContent>
      </div>
    </IonPage>
  );
};

export default Customer;
