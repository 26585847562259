import {
    IonButton,
    IonCard,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText
} from "@ionic/react";
import {
    caretDown as caretDownIcon,
    removeCircle as removeCircleIcon
} from "ionicons/icons";
import { useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { atleastOneCharValidation } from "../../../utils/validations";

const placeholderlist = {
    Color: "eg. Red, Blue, Green",
    Size: "eg. Small, Medium, Large",
    Material: "eg. Cotton, Wool, Polyester",
    Weight: "eg. 500gm, 1Kg",
    "Add yours": "Add yours",
};
const VariantForm = ({ priceData, variantValidation, setVariantValidation, setTableData, optionsData, setOptionsData, duplicateValidation, isVariantChecked, setIsVariantChecked, isDuplicateValue, removedOptionsList, setRemovedOptionsList, numberOfOptionValue, setNumberOfOptionValue }) => {
    const [selectRef, setSelectRef] = useState([
        useRef<HTMLIonSelectElement>(),
        useRef<HTMLIonSelectElement>(),
        useRef<HTMLIonSelectElement>(),
        useRef<HTMLIonSelectElement>(),
    ]);
    const [firstTimeInputClick, setFirstTimeInputClick] = useState(true);

    const optionNameChangeHandler = (e, index) => {
        let temp = [...optionsData];
        temp[index].name = e.target.value;
        setOptionsData(temp);
    };

    const showSelectFirstTime = (index) => {
        if (firstTimeInputClick) {
            selectRef[index].current.click();
            setFirstTimeInputClick(false);
        }
    };

    const addValueHandler = async (e, optionIndex, valueIndex) => {
        if (e.detail.value !== null && e.detail.value !== undefined) {
            let temp = numberOfOptionValue;
            temp[optionIndex][valueIndex + 1] = 1;
            await setNumberOfOptionValue(temp);
            let temp1 = [...optionsData];
            temp1[optionIndex].value[valueIndex] = e.detail.value;
            await setOptionsData(temp1);
            await tableContentHandler();
            if (valueIndex === 0) {
                setVariantValidation({...variantValidation, value: ''});
            }
            // duplicateValidation();
        }
    };

    const removeOptionHandler = async (number) => {
        if (optionsData.length <= 1) {
            setIsVariantChecked(false);
            setOptionsData([{
                name: '',
                value: []
            }]);
            setTableData({});
            setRemovedOptionsList([]);
        } else {
            await setOptionsData(
                await optionsData.filter((val, index) => index !== number)
            );
            await setNumberOfOptionValue(
                await numberOfOptionValue.filter((val, index) => index !== number)
            );
            setVariantValidation({...variantValidation, value: '', name: ''});
            tableContentHandler();
        }
    };

    const tableContentForOneVariant = () => {
        let temp = {};
        for (let i = 0; i < optionsData[0].value.length; i++) {
            if (removedOptionsList.indexOf(`${optionsData[0].value[i]}`) === -1) {
                temp[`${optionsData[0].value[i]}`] = {
                    sellingPrice: priceData.sellingPrice,
                    Mrp: priceData.mrp,
                    avlQty: priceData.avlQty,
                    video: priceData.video
                };
            }
        }
        return temp;
    };

    const tableContentForTwoVariant = () => {
        let temp = {};
        for (let i = 0; i < optionsData[0].value.length; i++) {
            for (let j = 0; j < optionsData[1].value.length; j++) {
                if (
                    removedOptionsList.indexOf(
                        `${optionsData[0].value[i]} / ${optionsData[1].value[j]}`
                    ) === -1
                ) {
                    temp[`${optionsData[0].value[i]} / ${optionsData[1].value[j]}`] = {
                        sellingPrice: priceData.sellingPrice,
                        Mrp: priceData.mrp,
                        avlQty: priceData.avlQty,
                        video: priceData.video
                    }
                }
            }
        }
        return temp;
    };

    const tableContentForThreeVariant = () => {
        let temp = {};
        for (let i = 0; i < optionsData[0].value.length; i++) {
            for (let j = 0; j < optionsData[1].value.length; j++) {
                for (let k = 0; k < optionsData[2].value.length; k++) {
                    if (
                        removedOptionsList.indexOf(
                            `${optionsData[0].value[i]} / ${optionsData[1].value[j]} / ${optionsData[2].value[k]}`
                        ) === -1
                    ) {
                        temp[`${optionsData[0].value[i]} / ${optionsData[1].value[j]} / ${optionsData[2].value[k]}`] = {
                            sellingPrice: priceData.sellingPrice,
                            Mrp: priceData.mrp,
                            avlQty: priceData.avlQty,
                            video: priceData.video
                        }
                    }
                }
            }
        }
        return temp;
    };

    const tableContentHandler = () => {
        let data = {};
        if (optionsData.length === 1 || (optionsData.length === 2 && optionsData[1].value.length === 0) || (optionsData.length === 3 && optionsData[2].value.length === 0 && optionsData[1].value.length === 0) ) {
            data = tableContentForOneVariant();
        } else if (optionsData.length === 2 || (optionsData.length === 3 && optionsData[2].value.length === 0 &&  optionsData[1].value.length !== 0)) {
            data = tableContentForTwoVariant();
        }else if (optionsData.length === 3) {
            data = tableContentForThreeVariant();
        } else{}
        setTableData(data);
    };

    const deleteValueHandler = async (optionIndex, valueIndex) => {
        let valueName = optionsData[optionIndex].value[valueIndex];
        let temp = [...optionsData];
        temp[optionIndex].value = temp[optionIndex].value.filter(
            (tag, index) => index !== valueIndex
        );
        setOptionsData(temp);
        let temp1 = numberOfOptionValue;
        temp1[optionIndex].pop();
        await setNumberOfOptionValue(temp1);
        await tableContentHandler();
        let data1 = removedOptionsList;
        for (let i = 0; i < removedOptionsList.length; i++) {
            if (removedOptionsList[i] && removedOptionsList[i].indexOf(valueName) !== -1) {
                data1.splice(i, 1);
            }
        }
        setRemovedOptionsList(data1);
    };

    const addOptionHandler = async () => {
        if (optionsData.length >= 3) {
            window.alert("Variant can not be more than 3.");
        } else {
            let res = true;
            let temp = {...variantValidation};

            for (let i = 0; i < optionsData.length; i++) {
                if (
                    optionsData[i].value.length === 0 ||
                    optionsData[i].name === "" ||
                    optionsData[i].name === undefined ||
                    optionsData[i].name === null
                ) {
                    if (optionsData[i].value.length === 0) {
                        temp.value = `Minimum one value is required of ${optionsData[i].name || "variant"}.`;
                        res = false;
                    } else {
                        temp.value = "";
                    }
                    if (
                        optionsData[i].name === "" ||
                        optionsData[i].name === undefined ||
                        optionsData[i].name === null || 
                        atleastOneCharValidation(optionsData[i].name)
                    ) {
                        if(atleastOneCharValidation(optionsData[i].name)){
                            temp.name = "Variant name should have atleast one letter.";
                        }else{
                            temp.name = "Variant name can not be empty.";
                        }
                        res = false;
                    } else {
                        temp.name = "";
                    }
                    break;
                }
            }
            if (res === true && !variantValidation.valuesArray) {
                temp.name = "";
                temp.value = "";
                await setOptionsData([...optionsData, {
                    name: "",
                    value: []
                }]);
                await setNumberOfOptionValue([...numberOfOptionValue, [1]]);
            }
            setVariantValidation(temp);
        }
    };

    return (
        <>
            <IonItem lines="none">
                <IonLabel style={{fontSize:"15px"}}>(+ Add Variants)</IonLabel>
                <IonCheckbox
                    slot="start"
                    checked={isVariantChecked}
                    onIonChange={(e) => {
                        setIsVariantChecked(e.detail.checked);
                    }}
                />
            </IonItem >

            <IonRow>
            <IonCol offsetMd="0.7" offsetXs="2.2">
            <IonText color="medium">
                                    The product has multiple options like different sizes and colors.
            </IonText>
            </IonCol>
            </IonRow>


            <br/>
                {isVariantChecked === true && 
                    <>
                        {optionsData.map((value, index) => {
                            return (
                                <IonCard>
                                    <IonItem lines="none" style={{ height: 38 + "px" }}>
                                        <IonButton
                                            shape="round"
                                            onClick={(e) => removeOptionHandler(index)}
                                            slot="end"
                                            color="danger"
                                        >
                                            Remove
                                        </IonButton>
                                    </IonItem>
                                    <IonGrid
                                        className=" ion-no-margin"
                                        style={{ width: 100 + "%" }}
                                    >
                                        <IonRow>
                                            <IonCol
                                                sizeXs="12"
                                                sizeMd="5" 
                                            >
                                                <IonLabel position="stacked" className="ion-padding-start" color="dark" style={{fontSize:"15px"}}>Variant Name:</IonLabel>
                                                <IonItem lines="inset">
                                                    <IonSelect
                                                        ref={selectRef[index]}
                                                        style={{ width: 0.1 + "px" }}
                                                        interface="popover"
                                                        value={optionsData[index].name}
                                                        onIonChange={(e) => {
                                                            optionNameChangeHandler(e, index);
                                                        }}
                                                    >
                                                        <IonContent overflow-scrollY={false}>
                                                            {placeholderlist
                                                                ? Object.keys(placeholderlist).map((val) => (
                                                                    <IonSelectOption value={val}>
                                                                        {val}
                                                                    </IonSelectOption>
                                                                ))
                                                                : ""}
                                                        </IonContent>
                                                    </IonSelect>
                                                    <IonInput
                                                        style={{fontSize:"14px"}}
                                                        type="text"
                                                        placeholder="Enter variant name"
                                                        value={optionsData[index].name}
                                                        onIonChange={(e) =>
                                                            optionNameChangeHandler(e, index)
                                                        }
                                                        onFocus={(e) => showSelectFirstTime(index)}
                                                    />
                                                    <IonIcon
                                                        size="small"
                                                        slot="end"
                                                        color="primary"
                                                        icon={caretDownIcon}
                                                        onClick={(e) => selectRef[index].current.click()}
                                                    ></IonIcon>
                                                </IonItem>
                                            </IonCol>
                                            <IonCol sizeMd="6"
                                                    size="12">
                                                <div className="top-margin-on-mobile">
                                                    <IonLabel position="stacked" className="ion-padding-start" color="dark" style={{fontSize:"15px"}}>
                                                        {optionsData[index].name || "Variant"}&nbsp;
                                                        value:
                                                    </IonLabel>
                                                    {numberOfOptionValue[index] &&
                                                        numberOfOptionValue[index].length !== 0
                                                        ? numberOfOptionValue[index].map((val, ind) => {
                                                            return (
                                                                <IonItem lines="inset">
                                                                    <IonInput
                                                                    style={{fontSize:"14px"}}
                                                                        type="text"
                                                                        placeholder={
                                                                            ind !== 0 &&
                                                                                ind ===
                                                                                numberOfOptionValue[index].length - 1
                                                                                ? "Add Another Value"
                                                                                : placeholderlist[optionsData[index].name]
                                                                                    ? placeholderlist[optionsData[index].name]
                                                                                    : `Enter ${optionsData[index].name
                                                                                        ? optionsData[index].name
                                                                                        : "option"
                                                                                    } value`
                                                                        }
                                                                        value={
                                                                            optionsData[index].value[ind] || null
                                                                        }
                                                                        onIonChange={(e) =>
                                                                            addValueHandler(e, index, ind)
                                                                        }
                                                                        onIonBlur={(e) =>
                                                                            duplicateValidation(index, ind)
                                                                        }
                                                                    />
                                                                    {ind !==
                                                                        numberOfOptionValue[index].length - 1 ? (
                                                                        <IonButton
                                                                            slot="end"
                                                                            color="danger"
                                                                            shape="round"
                                                                            size="small"
                                                                            onClick={(e) => {
                                                                                deleteValueHandler(index, ind);
                                                                                duplicateValidation(index);
                                                                            }}
                                                                        >
                                                                            <IonIcon
                                                                                icon={removeCircleIcon}
                                                                            ></IonIcon>
                                                                        </IonButton>
                                                                    ) : null}
                                                                </IonItem>
                                                            );
                                                        })
                                                        : null}
                                                    <IonText color="danger">
                                                        <h5 className={`error-text ion-margin-horizontal ${isDuplicateValue[index] ? "" : "ion-hide"}`} >
                                                            Duplicate Values are not allowed.
                                                        </h5>
                                                    </IonText>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCard>
                            );
                        })}
                        <IonText color="danger" >
                            <h5 className={`error-text ion-margin-horizontal ${variantValidation.name ? "" : 'ion-hide'}`}> {variantValidation.name} </h5>
                            <h5 className={`error-text ion-margin-horizontal ${variantValidation.value ? "" : 'ion-hide'}`}> {variantValidation.value} </h5>
                            <h5 className={`error-text ion-margin-horizontal ${variantValidation.valuesArray ? "" : 'ion-hide'}`}> {variantValidation.valuesArray} </h5>
                        </IonText>
                        <IonRow className="ion-justify-content-end ion-padding-end">
                        {optionsData.length === 3 ? (
                            <IonButton disabled className="ion-margin-top" shape="round" size="small">
                                Add another variant
                            </IonButton>
                        ) : (
                            <IonButton
                            
                                onClick={(e) => addOptionHandler()}
                                className="ion-margin-top"
                                shape="round"
                                size="small"
                            >
                                Add another variant
                            </IonButton>
                        
                        )}
                        </IonRow>
                    </>
                }
        </>
    );
};

export default VariantForm;
