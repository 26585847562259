import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonApp, IonText, IonItem, IonIcon, IonLoading, IonCard, IonButton, IonAlert, IonPage, IonBadge, IonCardContent, IonRow, IonGrid, IonCol } from '@ionic/react';
import DashboardMenu from '../../components/dashboard/DashboardMenu';
import { getCoupons, deleteCoupon, clearErrors } from '../../actions/dashboardActions';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import { BrowserView, MobileView } from "react-device-detect";
import Header from '../../components/dashboard/Header'
import {
    trash as trashIcon,
    shareSocial as shareSocialIcon
} from 'ionicons/icons';
import { Share } from '@capacitor/share';
import { authenticateUserWhenLogout } from '../../actions/authActions';
import { useHistory } from 'react-router';
import { ShareButtonMobile } from '../../components/ShareButtonMobile';
import { ShareButtonWeb } from '../../components/ShareButtonWeb';
import { StoreCurrency } from '../../utils/currency';
const Coupons = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState<any>();
    const currency = StoreCurrency();

    const dispatch = useDispatch();
    const history = useHistory();
    const { couponsLoading, coupons, couponsError } = useSelector((state: any) => state.coupons);
    const { deleteCouponMessageLoading, deleteCouponMessageError } = useSelector((state: any) => state.deleteCouponData);
    const { currentUser } = useSelector((state: any) => state.currentUser);
    const { createCouponMessageLoading, createCouponMessage, createCouponMessageError } = useSelector((state: any) => state.createCouponData);
    const { homeScreenWithDaysLoading, homeScreenWithDays, homeScreenWithDaysError } = useSelector((state: any) => state.homeScreenDataWithDays);

    useEffect(() => {
        if (currentUser && currentUser.data) {
            dispatch(getCoupons(getAccessToken(), currentUser.data.store.subdomain));
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUser && currentUser.data && createCouponMessageLoading === false) {
            dispatch(getCoupons(getAccessToken(), currentUser.data.store.subdomain));
        }
    }, [createCouponMessageLoading]);

    useEffect(() => {
        if (currentUser && currentUser.data && deleteCouponMessageLoading === false) {
            dispatch(getCoupons(getAccessToken(), currentUser.data.store.subdomain));
        }
    }, [deleteCouponMessageLoading]);

    const onCouponDeleteClick = async (value: any) => {
        if (currentUser && currentUser.data && value) {
            if (getAccessToken()) {
                await dispatch(deleteCoupon(getAccessToken(), currentUser.data.store.subdomain, {
                    store_id: currentUser.data.store.subdomain,
                    code: `${value.code}`,
                    value: `${value.value}`,
                    id: value.id
                }));
            } else {
                await dispatch(authenticateUserWhenLogout())
                await localStorage.clear();
                history.push('/')
            }
        }
    }

    const shareCoupon = async (value: any) => {
        await Share.share({
            title: 'Intelikart Coupon',
            text: ``
        });
    }
    return (
        <IonPage>
            <div className="ion-page" id="main-content">
                <Header name="Coupons" docs={true} docslink='https://intelikart.tawk.help/article/coupons' />
                <IonContent className="ion-padding">
                    <IonText className="ion-text-center ion-margin-bottom">
                        <h5 className="ion-no-margin"><strong>Manage coupons for your app and website</strong></h5>
                        <p>Using coupons you can run online sale</p>
                    </IonText>
                    {
                        couponsLoading === false && coupons && coupons.data && coupons.data.length !== 0 ?
                            coupons.data.map((value: any) => {
                                return (
                                    <IonCard key={value.id}>
                                        <IonCardContent>
                                            <IonGrid className="ion-no-padding ion-no-margin">
                                                <IonRow className="ion-no-padding ion-no-margin">
                                                    <IonCol className="ion-no-padding ion-no-margin"
                                                        size="10">
                                                        <IonText style={{ fontSize: "22px" }} color="dark">
                                                            {`${value.code} `}
                                                            {value.visibility === "PRIVATE" ? (<IonBadge>{value.visibility}</IonBadge>) : ""}
                                                            <br />
                                                        </IonText>
                                                        <IonText style={{ fontSize: "15px" }}>
                                                            {`${value.value} ${value.method === "FLAT" ? currency : "%"} off on orders above ${value.min_order_value} ${currency}`}
                                                        </IonText>
                                                    </IonCol>
                                                    <IonCol size="2" className="ion-no-padding ion-no-margin">
                                                        <MobileView>
                                                            <ShareButtonMobile title='Intelikart Coupon'
                                                                text={`Use code ${value.code} to get ${value.value} ${value.method === "FLAT" ? currency : "%"} off on all orders above ${value.min_order_value} ${currency} from our app`} />
                                                            <IonButton
                                                                fill="clear"
                                                                color="secondary"
                                                                onClick={() => { setAlertData(value); setShowAlert(true) }}>
                                                                <IonIcon
                                                                    slot="icon-only"
                                                                    icon={trashIcon}
                                                                ></IonIcon>
                                                            </IonButton>
                                                        </MobileView>
                                                        <BrowserView>
                                                            <ShareButtonWeb url="" text={`Use code ${value.code} to get ${value.value} ${value.method === "FLAT" ? currency : "%"} off on orders above ${value.min_order_value} ${currency} from our ${homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.app && homeScreenWithDays.data.app.URL ? `${homeScreenWithDays.data.app.URL} app` : homeScreenWithDays.data && homeScreenWithDays.data.website && homeScreenWithDays.data.website.URL ? `${homeScreenWithDays.data.website.URL} website` : "app"}`} />
                                                            <IonButton
                                                                fill="clear"
                                                                color="secondary"
                                                                onClick={() => { setAlertData(value); setShowAlert(true) }}>
                                                                <IonIcon
                                                                    slot="icon-only"
                                                                    icon={trashIcon}
                                                                ></IonIcon>
                                                            </IonButton>
                                                        </BrowserView>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                );
                            }) : null
                    }
                    <div className="ion-text-center">
                        <IonButton shape="round" routerLink="/createcoupon">ADD COUPON</IonButton>
                    </div>
                    <IonAlert
                        isOpen={showAlert === true}
                        onDidDismiss={() => setShowAlert(false)}
                        header={'Delete Coupon'}
                        message={`You are about to delete coupon ${alertData && alertData.code ? alertData.code : ""}`}
                        buttons={[
                            {
                                text: 'CANCEL',
                                role: 'cancel'
                            },
                            {
                                text: 'DELETE',
                                handler: () => { onCouponDeleteClick(alertData); }
                            }
                        ]}
                    />

                    <IonLoading isOpen={couponsLoading === true || deleteCouponMessageLoading === true} message="Please Wait"></IonLoading>
                </IonContent>
            </div>
        </IonPage>
    )
}

export default Coupons
