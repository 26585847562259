import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonLabel, IonText, IonLoading, IonBackButton, IonHeader, IonToolbar, IonButtons, IonTitle, IonItem, IonInput, IonButton, IonPage, IonList, IonCard, IonImg, IonToggle, IonGrid, IonRow, IonCol, isPlatform, useIonToast, IonToast, IonCheckbox, IonIcon } from '@ionic/react';
import { clearErrors, setPayment, getPayment, setRazorPay, getRazorPayDetails, getCashfreeDetails, setCashfreeDetails, getPhonepeDetails, setPhonepeDetails } from '../../actions/dashboardActions';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import {
    arrowBack as arrowBackIcon,
    informationCircleOutline
} from 'ionicons/icons';
import paymentGatewayImage from '../../images/paymentsettings/paymentGatewayImage.png';
import RazorPayImage from '../../images/paymentsettings/razorpay.png';
import CashfreeImage from '../../images/paymentsettings/cashfree.png';
import PhonepeImage from '../../images/paymentsettings/phonepe.png';
import CODImage from '../../images/paymentsettings/CODImage.png';
import { Camera, CameraResultType } from '@capacitor/camera';
import FeatHeader from '../../components/dashboard/FeatHeader';

// import paytmImage from '../../images/paymentsettings/paytmImage.png';

// async function savePicture(file) {
//     if (!file) return ""
//     try {
//         const formData = new FormData();
//         formData.append("file", file)
//         const response = await fetch(`https://api.intelikart.com/api/upload/store-settings-image`, {
//             method: 'POST',
//             body: formData
//         });
//         const data = await response.json();
//         return data.platform_path;
//     }
//     catch (error) {
//         console.log(error)
//         return ""
//     }
// }

const Payments = () => {
    const [bankAccount, setBankAccount] = useState<any>();
    const [conBankAccount, setConBankAccount] = useState<any>();
    const [ifscCode, setIfscCode] = useState<any>();
    const [accountHolderName, setAccountHolderName] = useState<any>();
    // const [paytmNumber, setPaytmNumber] = useState<any>();
    const [gatewayChecked, setGatewayChecked] = useState<any>(false);
    const [codChecked, setCodChecked] = useState<any>(true);
    // cashfree
    const [cashfreeAppId, setCashfreeAppId] = useState<any>();
    const [cashfreeSecret, setCashfreeSecret] = useState<any>();
    const [cashfreeChecked, setCashfreeChecked] = useState<any>(false);
    const [showCashfree, setShowCashfree] = useState<any>(false);

    // phonepe
    const [phonepeMerchantId, setPhonepeMerchantId] = useState<any>();
    const [phonepeSaltKey, setPhonepeSaltKey] = useState<any>();
    const [phonepeSaltIndex, setPhonepeSaltIndex] = useState<any>();
    const [phonepeChecked, setPhonepeChecked] = useState<any>(false);
    const [showPhonepe, setShowPhonepe] = useState<any>(false);

    // razorpay
    const [razorpayKeyId, setRazorpayKeyId] = useState<any>();
    const [razorpayKeySecret, setRazorpayKeySecret] = useState<any>();
    const [razorpayChecked, setRazorpayChecked] = useState<any>(false);
    const [showRazorpay, setShowRazorpay] = useState<any>(false);
    const [isKeyIdInvalid, setIsKeyIdInvalid] = useState<any>(false);


    // const [paytmChecked, setPaytmChecked] = useState<any>(false);
    // const [pictureUrl, setPictureUrl] = useState('https://cdn.intelikart.com/uploads/new-images/default-store.png');
    // const [imageFile, setImageFile] = useState(null)
    // const fileInputRef = useRef<HTMLInputElement>();
    const [loading, setLoading] = useState(false)
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    //Validations
    const [isBankAccountValid, setIsBankAccountValid] = useState(true);
    const [isConBankAccountValid, setIsConBankAccountValid] = useState(true);
    const [isIfscCodeValid, setIsIfscCodeValid] = useState(true);
    const [isHolderNameValid, setIsHolderNameValid] = useState(true);
    // const [isPaytmNumberValid, setIsPaytmNumberValid] = useState(true);

    const [isBankAccountEmpty, setIsBankAccountEmpty] = useState(false);
    const [isIfscCodeEmpty, setIsIfscCodeEmpty] = useState(false);
    const [isHolderNameEmpty, setIsHolderNameEmpty] = useState(false);
    // const [isPaytmNumberEmpty, setIsPaytmNumberEmpty] = useState(false);
    // const [isPaytmImageEmpty, setIsPaytmImageEmpty] = useState(false);
    // cashfree
    const [isCashfreeAppIdEmpty, setIsCashfreeAppIdEmpty] = useState(false);
    const [isCashfreeSecretEmpty, setIsCashfreeSecretEmpty] = useState(false);
    // phonepe
    const [isPhonepeMerchantIdEmpty, setIsPhonepeMerchantIdEmpty] = useState(false);
    const [isPhonepeSaltKeyEmpty, setIsPhonepeSaltKeyEmpty] = useState(false);
    const [isPhonepeSaltIndexEmpty, setIsPhonepeSaltIndexEmpty] = useState(false);
    // razorpay
    const [isRazorpayKeyIdEmpty, setIsRazorpayKeyIdEmpty] = useState(false);
    const [isRazorpayKeySecretEmpty, setIsRazorpayKeySecretEmpty] = useState(false);

    const [present] = useIonToast();
    const dispatch = useDispatch();
    const { paymentLoading, payment, paymentError } = useSelector((state: any) => state.payment);
    const { setPaymentResponseLoading, setPaymentResponse, setPaymentResponseError } = useSelector((state: any) => state.setPayment);

    const { razorPayLoading, razorPay, razorPayError } = useSelector((state: any) => state.RazorPay);
    const { setRazorPayResponseLoading, setRazorPayResponse, setRazorPayResponseError } = useSelector((state: any) => state.setRazorPay);

    const { cashfreeLoading, cashfree, cashfreeError } = useSelector((state: any) => state.cashfree);
    const { setCashfreeResponseLoading, setCashfreeResponse, setCashfreeResponseError } = useSelector((state: any) => state.setCashfree);

    const { phonepeLoading, phonepe, phonepeError } = useSelector((state: any) => state.phonepe);
    const { setPhonepeResponseLoading, setPhonepeResponse, setPhonepeResponseError } = useSelector((state: any) => state.setPhonepe);

    const { currentUser } = useSelector((state: any) => state.currentUser);

    useEffect(() => {
        if (currentUser && currentUser.data) {
            dispatch(getPayment(getAccessToken(), currentUser.data.store.subdomain));
            dispatch(getCashfreeDetails(getAccessToken(), currentUser.data.store.subdomain));
            dispatch(getPhonepeDetails(getAccessToken(), currentUser.data.store.subdomain));
            dispatch(getRazorPayDetails(getAccessToken(), currentUser.data.store.subdomain));
        }
    }, [currentUser]);

    useEffect(() => {
        if (paymentLoading === false && payment && payment.data) {
            setCodChecked(payment.data.enable_cod === 1 ? true : false);
        }

        // cashfree
        if (cashfreeLoading === false && cashfree && cashfree.data) {
            setCashfreeChecked(cashfree.data.enable_cashfree_pg === 1 ? true : false);
            setCashfreeAppId(cashfree.data.cashfree_pg_client_id);
            setCashfreeSecret(cashfree.data.cashfree_pg_client_secret);
        }

        // phonepe
        if (phonepeLoading === false && phonepe && phonepe.data) {
            setPhonepeChecked(phonepe.data.enable_phonepe === 1 ? true : false);
            setPhonepeMerchantId(phonepe.data.merchant_id);
            setPhonepeSaltKey(phonepe.data.salt_key);
            setPhonepeSaltIndex(phonepe.data.salt_index);
        }

        // razorpay
        if (razorPayLoading === false && razorPay && razorPay.data) {
            setRazorpayChecked(razorPay.data.enable_razorpay === 1 ? true : false);
            setRazorpayKeyId(razorPay.data.razorpay_api_key);
            setRazorpayKeySecret(razorPay.data.razorpay_api_secret);
        }

    }, [paymentLoading, cashfreeLoading, phonepeLoading, razorPayLoading]);

    useEffect(() => {
        if (setPaymentResponse.statusCode === 400) {
            present(setPaymentResponse.message, 2000);
        }
    }, [setPaymentResponse]);

    const bankAccountValidation = () => {
        if (gatewayChecked) {
            let result = true;
            if (!bankAccount && gatewayChecked) {
                setIsBankAccountEmpty(true);
                result = false;
            } else {
                setIsBankAccountEmpty(false);
                if (/\D/.test(bankAccount) && gatewayChecked) {
                    setIsBankAccountValid(false);
                    result = false;
                }
                else {
                    setIsBankAccountValid(true);
                    if ((bankAccount.length < 9 || bankAccount.length > 18) && gatewayChecked) {
                        setIsBankAccountValid(false);
                        result = false;
                    } else
                        setIsBankAccountValid(true);
                }
            }
            return result;
        }
        return true;
    }


    const validateKeyId = (keyId) => {
        // Regular expression to match letters, digits, and special symbols
        const regex = /^(?=.*[a-zA-Z]).*$/;
        return regex.test(keyId);
    };

    const razorPayValidation = () => {
        if (razorpayChecked) {
            let result = true;
            if (!razorpayKeyId) {
                setIsRazorpayKeyIdEmpty(true);
                result = false;
            } else {
                setIsRazorpayKeyIdEmpty(false);
            }
            if (!razorpayKeySecret) {
                setIsRazorpayKeySecretEmpty(true);
                result = false;
            } else {
                setIsRazorpayKeySecretEmpty(false);
            }
            return result;
        }
        return true;
    }

    const cashfreeValidation = () => {
        // reset
        setIsCashfreeAppIdEmpty(false);
        setIsCashfreeSecretEmpty(false);

        if (cashfreeChecked) {
            let result = true;
            if (!cashfreeAppId) {
                setIsCashfreeAppIdEmpty(true);
                result = false;
            } else {
                setIsCashfreeAppIdEmpty(false);
            }
            if (!cashfreeSecret) {
                setIsCashfreeSecretEmpty(true);
                result = false;
            } else {
                setIsCashfreeSecretEmpty(false);
            }
            return result;
        }

        return true;
    }

    const conBankAccountValidation = () => {
        if (gatewayChecked) {
            let result = true;
            if (conBankAccount !== bankAccount) {
                setIsConBankAccountValid(false);
                result = false;
            } else {
                setIsConBankAccountValid(true);
            }
            return result;
        }
        return true;
    }

    const ifscValidation = () => {
        if (gatewayChecked) {
            let result = true;
            if (!ifscCode) {
                setIsIfscCodeEmpty(true);
                result = false;
            } else {
                setIsIfscCodeEmpty(false);
                if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(ifscCode)) {
                    setIsIfscCodeValid(false);
                    result = false;
                } else {
                    setIsIfscCodeValid(true);
                    if (ifscCode.length > 11) {
                        setIsIfscCodeValid(false);
                        result = false;
                    } else
                        setIsIfscCodeValid(true);
                }
            }
            return result;
        }
        return true;
    }

    const accountHolderNameValidation = () => {
        if (gatewayChecked) {
            let result = true;
            if (!accountHolderName) {
                setIsHolderNameEmpty(true);
                result = false;
            } else {
                setIsHolderNameEmpty(false);
                if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(accountHolderName)) {
                    setIsHolderNameValid(false);
                    result = false;
                }
                else
                    setIsHolderNameValid(true);
            }
            return result;
        }
        return true;
    }

    // const paytmValidation = () => {
    //     if (paytmChecked) {
    //         let result = true;
    //         if (!paytmNumber) {
    //             setIsPaytmNumberEmpty(true);
    //             result = false;
    //         } else {
    //             setIsPaytmNumberEmpty(false);
    //             if (/\D/.test(paytmNumber)) {
    //                 setIsPaytmNumberValid(false);
    //                 result = false;
    //             }
    //             else {
    //                 setIsPaytmNumberValid(true);
    //                 if (paytmNumber.length !== 10) {
    //                     setIsPaytmNumberValid(false);
    //                     result = false;
    //                 } else
    //                     setIsPaytmNumberValid(true);
    //             }
    //         }
    //         if (!imageFile) {
    //             setIsPaytmImageEmpty(true);
    //             result = false;
    //         } else {
    //             setIsPaytmImageEmpty(false);
    //         }
    //         return result;
    //     }
    //     return true;
    // }
    // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (event.target.files.length > 0) {
    //         const file = event.target.files.item(0);
    //         setImageFile(file)
    //         const pictureUrl = URL.createObjectURL(file);
    //         setPictureUrl(pictureUrl);
    //     }
    // };
    // const handlePictureClick = async () => {
    //     if (isPlatform('capacitor')) {
    //         try {
    //             const photo = await Camera.getPhoto({
    //                 resultType: CameraResultType.Uri,
    //             })
    //             setPictureUrl(photo.webPath);
    //         }
    //         catch (error) {
    //             console.log(error);
    //         }
    //     }
    //     else {
    //         fileInputRef.current.click();
    //     }

    // }

    const phonepeValidation = () => {
        // reset
        setIsPhonepeMerchantIdEmpty(false);
        setIsPhonepeSaltKeyEmpty(false);
        setIsPhonepeSaltIndexEmpty(false);

        if (phonepeChecked) {
            let result = true;
            if (!phonepeMerchantId) {
                setIsPhonepeMerchantIdEmpty(true);
                result = false;
            } else {
                setIsPhonepeMerchantIdEmpty(false);
            }
            if (!phonepeSaltKey) {
                setIsPhonepeSaltKeyEmpty(true);
                result = false;
            } else {
                setIsPhonepeSaltKeyEmpty(false);
            }
            if (!phonepeSaltIndex) {
                setIsPhonepeSaltIndexEmpty(true);
                result = false;
            } else {
                setIsPhonepeSaltIndexEmpty(false);
            }
            return result;
        }
        return true;
    }

    const submitCashfree = async (e) => {
        e.preventDefault();
        const res = await cashfreeValidation();
        if (res && currentUser && currentUser.data) {
            setLoading(true);
            Promise.all([
                await dispatch(setCashfreeDetails(getAccessToken(), currentUser.data.store.subdomain, {
                    enable_cashfree_pg: cashfreeChecked ? 1 : 0,
                    cashfree_pg_client_id: `${cashfreeAppId}`,
                    cashfree_pg_client_secret: `${cashfreeSecret}`,
                })),
                await dispatch(getCashfreeDetails(getAccessToken(), currentUser.data.store.subdomain)),
            ]);
            setLoading(false);
            setToastMessage("Cashfree data saved successfully");
            setShowToast(true);
        }
    }

    const submitPhonepe = async (e) => {
        e.preventDefault();
        const res = await phonepeValidation();
        if (res && currentUser && currentUser.data) {
            setLoading(true);
            Promise.all([
                await dispatch(setPhonepeDetails(getAccessToken(), currentUser.data.store.subdomain, {
                    enable_phonepe: phonepeChecked ? 1 : 0,
                    merchant_id: `${phonepeMerchantId}`,
                    salt_key: `${phonepeSaltKey}`,
                    salt_index: `${phonepeSaltIndex}`,
                })),
                await dispatch(getPhonepeDetails(getAccessToken(), currentUser.data.store.subdomain)),
            ]);
            setLoading(false);
            setToastMessage("Phone Pe data saved successfully");
            setShowToast(true);
        }
    }

    const submitRazorpay = async (e) => {
        e.preventDefault();
        const res = await razorPayValidation();
        const isKeyIdValid = validateKeyId(razorpayKeyId);
        setIsKeyIdInvalid(!isKeyIdValid);
        console.log(res, isKeyIdValid)

        if (!res || !isKeyIdValid) {
            // Do not proceed if validation fails
            return;
        }
        if (res && currentUser && currentUser.data) {
            setLoading(true);
            Promise.all([
                await dispatch(setRazorPay(getAccessToken(), currentUser.data.store.subdomain, {
                    enable_razorpay: razorpayChecked ? 1 : 0,
                    razorpay_api_key: `${razorpayKeyId}`,
                    razorpay_api_secret: `${razorpayKeySecret}`,
                })),
                await dispatch(getRazorPayDetails(getAccessToken(), currentUser.data.store.subdomain)),
            ]);
            setLoading(false);
            setToastMessage("Razorpay data saved successfully");
            setShowToast(true);
        }
    }

    const handleCOD = async (e) => {
        setCodChecked(e.detail.checked)

        await dispatch(setPayment(getAccessToken(), currentUser.data.store.subdomain, {
            enable_cod: e.detail.checked ? 1 : 0,
        }))
    }

    const openLinkInNewTab = () => {
        window.open(`https://intelikart.tawk.help/article/payment-gateway`, '_blank');
    };

    return (
        <IonPage>
            
            <FeatHeader
                title='Order Payments'
                defaultHref='/settings'
                showInfoButton={true}
                onInfoClick={openLinkInNewTab} 
            />
            <IonContent className="ion-padding">
                <IonList>
                    {/* hide ik payment gateway */}
                    {/* <IonCard>
                        <IonItem lines="none">
                            <IonImg src={paymentGatewayImage} slot="start"></IonImg>
                            <IonText><h5>IK Payment Gateway</h5></IonText>
                            <IonToggle slot="end" disabled={razorChecked ? true : false} checked={gatewayChecked} onIonChange={e => setGatewayChecked(e.detail.checked)} />
                        </IonItem>
                        {gatewayChecked === true ?
                            <>
                                <IonText className="ion-text-center">
                                    <p>Payment gateway charges & settlement charges applicable</p>
                                    <p>Add your account details for settlements</p>
                                </IonText>
                                <IonGrid>
                                    <IonRow className="ion-align-items-center">
                                        <IonCol size="4">
                                            <IonLabel>Bank Account</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem>
                                                <IonInput placeholder="Bank Account Number" type="tel" value={bankAccount} name="bankAccount" onIonChange={(e) => { setBankAccount(e.detail.value) }} />
                                            </IonItem>
                                            <IonText color="danger" className={`${isBankAccountEmpty === false && isBankAccountValid === true ? "ion-hide" : ""}`}>
                                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isBankAccountEmpty ? "" : "ion-hide"}`}>Bank Account is required.</h5>
                                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isBankAccountValid ? "ion-hide" : ""}`}>Bank Account is not valid.</h5>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-align-items-center">
                                        <IonCol size="4">
                                            <IonLabel>Confirm Bank Account</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem>
                                                <IonInput placeholder="Bank Account Number" type="tel" value={conBankAccount} name="confirmBankAccount" onIonChange={(e) => { setConBankAccount(e.detail.value) }} />
                                            </IonItem>
                                            <IonText color="danger" className={`${isConBankAccountValid === true ? "ion-hide" : ""}`}>
                                                <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }} className="ion-margin-horizontal">Confirm Bank Account is not same.</h5>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-align-items-center">
                                        <IonCol size="4">
                                            <IonLabel>IFSC Code</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem>
                                                <IonInput placeholder="Enter IFSC Code" type="text" value={ifscCode} name="ifscCode" onIonChange={(e) => { setIfscCode(e.detail.value) }} />
                                            </IonItem>
                                            <IonText color="danger" className={`${isIfscCodeEmpty === false && isIfscCodeValid === true ? "ion-hide" : ""}`}>
                                                <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }} className={`ion-margin-horizontal ${isIfscCodeEmpty ? "" : "ion-hide"}`}>IFSC Code is required.</h5>
                                                <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }} className={`ion-margin-horizontal ${isIfscCodeValid ? "ion-hide" : ""}`}>IFSC Code is not correct.</h5>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-align-items-center">
                                        <IonCol size="4">
                                            <IonLabel>Account Holder Name</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem>
                                                <IonInput placeholder="Enter Account Holder Name" type="text" value={accountHolderName} name="accountHolderName" onIonChange={(e) => { setAccountHolderName(e.detail.value) }} />
                                            </IonItem>
                                            <IonText color="danger" className={`${isHolderNameEmpty === false && isHolderNameValid === true ? "ion-hide" : ""}`}>
                                                <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }} className={`ion-margin-horizontal ${isHolderNameEmpty ? "" : "ion-hide"}`}>Account Holder Name is required.</h5>
                                                <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }} className={`ion-margin-horizontal ${isHolderNameValid ? "ion-hide" : ""}`}>Account Holder Name can't contain special characters.</h5>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </>
                            : null
                        }
                    </IonCard> */}

                    {/* Razorpay setting */}

                    <IonCard>
                        <IonItem lines="none">
                            <IonImg src={RazorPayImage} slot="start"></IonImg>
                            <IonText><h5>RazorPay Integration</h5></IonText>
                            <IonButton slot='end' onClick={() => setShowRazorpay(!showRazorpay)} className='ion-no-margin'
                                fill='clear'
                            >Configure</IonButton>
                        </IonItem>
                        {showRazorpay === true ?
                            <>
                                <IonText className="ion-text-center">
                                    <p>Add your Razorpay account details for settlements</p>
                                </IonText>
                                <IonGrid className='ion-padding'>
                                    <IonRow className='ion-align-items-center'>
                                        <IonCol size="4">
                                            <IonLabel>Enable / Disable</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem lines="none" className="ion-no-padding">
                                                <IonLabel>Enable Razorpay</IonLabel>
                                                <IonCheckbox slot="start" checked={razorpayChecked} onIonChange={e => setRazorpayChecked(e.detail.checked)} />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-align-items-center'>
                                        <IonCol size="4">
                                            <IonLabel>Key Id</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem className="ion-no-padding">
                                                <IonInput placeholder="Enter Key Id" type="tel" value={razorpayKeyId} onIonChange={(e) => {
                                                    const value = e.detail.value;
                                                    setRazorpayKeyId(value);
                                                 }} />
                                            </IonItem>
                                            <IonText color="danger" className={`${isRazorpayKeyIdEmpty === false ? "ion-hide" : ""}`}>
                                                <h5 style={{ fontSize: 12 + 'px' }}>Key Id is required.</h5>
                                            </IonText>
                                            { isKeyIdInvalid === true && isRazorpayKeyIdEmpty=== false && <IonText color="danger">
                                                <h5 style={{ fontSize: 12 + 'px' }}>Key Id is invalid.</h5>
                                            </IonText>}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-align-items-center'>
                                        <IonCol size="4">
                                            <IonLabel>Key Secret</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem className="ion-no-padding">
                                            <IonInput placeholder="Enter Key Secret" type="tel" value={razorpayKeySecret} onIonChange={(e) => { setRazorpayKeySecret(e.detail.value) }} />
                                            </IonItem>
                                            <IonText color="danger" className={`${isRazorpayKeySecretEmpty === false ? "ion-hide" : ""}`}>
                                                <h5 style={{ fontSize: 12 + 'px' }}>Key secret is required.</h5>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                                <div className="ion-text-center ion-margin-bottom">
                                    <IonButton type="button" onClick={submitRazorpay}>Save</IonButton>
                                </div>
                            </>
                            : null
                        }
                    </IonCard>

                    {/* Cashfree payment gateway */}
                    <IonCard>
                        <IonItem lines="none">
                            <IonImg src={CashfreeImage} slot="start" style={{ width: "45px" }}></IonImg>
                            <IonText><h5>Cashfree Integration</h5></IonText>
                            <IonButton slot='end' onClick={() => setShowCashfree(!showCashfree)} className='ion-no-margin'
                                fill='clear'
                            >Configure</IonButton>
                        </IonItem>
                        {showCashfree === true ?
                            <>
                                <IonText className="ion-text-center">
                                    <p>Add your Cashfree account details for settlements</p>
                                </IonText>
                                <IonGrid className='ion-padding'>
                                    <IonRow className='ion-align-items-center'>
                                        <IonCol size="4">
                                            <IonLabel>Enable / Disable</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem lines="none" className="ion-no-padding">
                                                <IonLabel>Enable Cashfree</IonLabel>
                                                <IonCheckbox slot="start" checked={cashfreeChecked} onIonChange={e => setCashfreeChecked(e.detail.checked)} />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-align-items-center'>
                                        <IonCol size="4">
                                            <IonLabel>App Id</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem className="ion-no-padding">
                                                <IonInput placeholder="Enter Client App Id" type="tel" value={cashfreeAppId} onIonChange={(e) => { setCashfreeAppId(e.detail.value) }} />
                                            </IonItem>
                                            <IonText color="danger" className={`${isCashfreeAppIdEmpty === false ? "ion-hide" : ""}`}>
                                                <h5 style={{ fontSize: 12 + 'px' }}>Client App Id is required.</h5>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-align-items-center">
                                        <IonCol size="4">
                                            <IonLabel>Secret Key</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem className="ion-no-padding">
                                                <IonInput placeholder="Enter Client Secret Key" type="tel" value={cashfreeSecret} onIonChange={(e) => { setCashfreeSecret(e.detail.value) }} />
                                            </IonItem>
                                            <IonText color="danger" className={`${isCashfreeSecretEmpty === false ? "ion-hide" : ""}`}>
                                                <h5 style={{ fontSize: 12 + 'px' }}>Client Secret Key is required.</h5>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                                <div className="ion-text-center ion-margin-bottom">
                                    <IonButton type="button" onClick={submitCashfree}>Save</IonButton>
                                </div>
                            </>
                            : null
                        }
                    </IonCard>

                    {/* Phonepe payment gateway */}
                    <IonCard>
                        <IonItem lines="none">
                            <IonImg src={PhonepeImage} slot="start"></IonImg>
                            <IonText><h5>PhonePe Integration</h5></IonText>
                            <IonButton slot='end' onClick={() => setShowPhonepe(!showPhonepe)} className='ion-no-margin'
                                fill='clear'
                            >Configure</IonButton>
                        </IonItem>
                        {showPhonepe === true ?
                            <>
                                <IonText className="ion-text-center">
                                    <p>Add your PhonePe account details for settlements</p>
                                </IonText>
                                <IonGrid className='ion-padding'>
                                    <IonRow className='ion-align-items-center'>
                                        <IonCol size="4">
                                            <IonLabel>Enable / Disable</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem lines="none" className="ion-no-padding">
                                                <IonLabel>Enable PhonePe</IonLabel>
                                                <IonCheckbox slot="start" checked={phonepeChecked} onIonChange={e => setPhonepeChecked(e.detail.checked)} />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-align-items-center'>
                                        <IonCol size="4">
                                            <IonLabel>Merchant Id</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem className="ion-no-padding">
                                                <IonInput placeholder="Enter Merchant Id" type="tel" value={phonepeMerchantId} onIonChange={(e) => { setPhonepeMerchantId(e.detail.value) }} />
                                            </IonItem>
                                            <IonText color="danger" className={`${isPhonepeMerchantIdEmpty === false ? "ion-hide" : ""}`}>
                                                <h5 style={{ fontSize: 12 + 'px' }}>Merchant Id is required.</h5>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-align-items-center'>
                                        <IonCol size="4">
                                            <IonLabel>Salt Key</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem className="ion-no-padding">
                                                <IonInput placeholder="Enter Salt Key" type="tel" value={phonepeSaltKey} onIonChange={(e) => { setPhonepeSaltKey(e.detail.value) }} />
                                            </IonItem>
                                            <IonText color="danger" className={`${isPhonepeSaltKeyEmpty === false ? "ion-hide" : ""}`}>
                                                <h5 style={{ fontSize: 12 + 'px' }}>Salt Key is required.</h5>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-align-items-center'>
                                        <IonCol size="4">
                                            <IonLabel>Salt Index</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem className="ion-no-padding">
                                                <IonInput placeholder="Enter Salt Index" type="tel" value={phonepeSaltIndex} onIonChange={(e) => { setPhonepeSaltIndex(e.detail.value) }} />
                                            </IonItem>
                                            <IonText color="danger" className={`${isPhonepeSaltIndexEmpty === false ? "ion-hide" : ""}`}>
                                                <h5 style={{ fontSize: 12 + 'px' }}>Salt Index is required.</h5>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                                <div className="ion-text-center ion-margin-bottom">
                                    <IonButton type="button" onClick={submitPhonepe}>Save</IonButton>
                                </div>
                            </>
                            : null
                        }
                    </IonCard>

                    <IonCard>
                        <IonItem lines="none">
                            <IonImg src={CODImage} slot="start"></IonImg>
                            <IonText><h5>Cash on Delivery</h5></IonText>
                            <IonToggle slot="end" checked={codChecked} onIonChange={handleCOD} />
                        </IonItem>
                    </IonCard>

                    {/* hide paytm QR */}
                    {/* <IonCard>
                        <IonItem lines="none">
                            <IonImg src={paytmImage} slot="start"></IonImg>
                            <IonText><h5>Pay via Paytm</h5></IonText>
                            <IonToggle slot="end" checked={paytmChecked} onIonChange={e => setPaytmChecked(e.detail.checked)} />
                        </IonItem>
                        {paytmChecked === true ?
                            <>
                                <IonGrid>
                                    <IonRow className="ion-align-items-center">
                                        <IonCol size="4">
                                            <IonLabel>Paytm Number</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem>
                                                <IonInput placeholder="Enter paytm number" type="text" value={paytmNumber} name="paytmNumber" onIonChange={(e) => { setPaytmNumber(e.detail.value) }} />
                                            </IonItem>
                                            <IonText color="danger" className={`${isPaytmNumberEmpty === false && isPaytmNumberValid === true ? "ion-hide" : ""}`}>
                                                <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }} className={`ion-margin-horizontal ${isPaytmNumberEmpty ? "" : "ion-hide"}`}>Paytm Number is required.</h5>
                                                <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }} className={`ion-margin-horizontal ${isPaytmNumberValid ? "ion-hide" : ""}`}>Paytm Number is incorrect.</h5>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonLabel>Paytm QR Code</IonLabel>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-align-items-center">
                                        <IonCol size='6'>
                                            <IonItem>
                                                <input hidden type='file' accept="image/*" ref={fileInputRef}
                                                    onChange={handleFileChange} />
                                                {pictureUrl ? <img width="90%" height="225" src={pictureUrl} alt=""></img> : null}
                                            </IonItem>
                                            <IonText color="danger" className={`${isPaytmImageEmpty === false ? "ion-hide" : ""}`}>
                                                <h5 style={{ fontSize: 12 + 'px' }}>QR code is required.</h5>
                                            </IonText>
                                        </IonCol>
                                        <IonCol >
                                            <IonButton shape="round" size='small' onClick={handlePictureClick}>Update QR code</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </>
                            : null
                        }
                    </IonCard> */}
                </IonList>
                {/* <div className="ion-text-center">
                    <IonButton shape="round" type="button" onClick={(e) => submitHandler()}>Save</IonButton>
                </div> */}
                <IonLoading isOpen={loading === true || paymentLoading === true} message="Please Wait"></IonLoading>
            </IonContent>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toastMessage}
                duration={2000}
            />
        </IonPage>
    )
}

export default Payments


