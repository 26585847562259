import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonLoading, IonCard, IonButton, IonAlert, IonImg, IonInput, IonPage, isPlatform, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle } from '@ionic/react';
import {createBanner, clearErrors } from '../../actions/dashboardActions';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import 'react-image-crop/dist/ReactCrop.css';
import {
    arrowBack as arrowBackIcon
} from 'ionicons/icons';
import { Camera, CameraResultType } from '@capacitor/camera';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useHistory } from 'react-router';

async function savePicture(file) {
    if(!file)return "";
    const formData = new FormData();
    formData.append("file",file)
    try{
        const response = await fetch(`https://api2.intelikart.in/upload/banner-image`, {
        method: 'POST',
        body:formData
        });
        const data = await response.json();
        return data.platform_path;
    }
    catch(error){
        console.log(error)
        return "";
    }
    
}
const AddBanner = () => {
    const [loading, setLoading] = useState(false);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    //states for image crop
    const [imageResult, setImageResult] = useState(null);
    const [imageCrop, setImageCrop] = useState({
        width: 1440,
        aspect: 3.2
    });
    const [croppedImageUrl, setCroppedImageUrl] = useState<any>('');
    const [refImage, setRefImage] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();
    const { addBannerLoading, addBanner, addBannerError } = useSelector((state: any)=> state.addBannerData);
    const { currentUser } = useSelector((state : any)=> state.currentUser);
    const fileInputRef = useRef<HTMLInputElement>();

    useEffect(() => {}, [addBannerLoading]);

    const handlePictureClick= async()=>{
        if(isPlatform('capacitor')){
            try{
            const photo = await Camera.getPhoto({
                resultType:CameraResultType.Uri,
            })
            }
            catch(error){
                console.log(error);
            }
        }
        else{
            fileInputRef.current.click();
        }
    }

    const handleFileChange =async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setIsFileUploaded(true);
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                setImageResult( reader.result )
            );
            reader.readAsDataURL(event.target.files[0]);
        }
        
    };

    const addBannerFun = async ()=>{
        if(croppedImageUrl) {
            setLoading(true);
            const file = croppedImageUrl;
            const accessToken = await getAccessToken();
            if(accessToken && currentUser && currentUser.data){
                const platform_path = await savePicture(file);
                await dispatch(createBanner(accessToken, currentUser.data.store.subdomain, {
                    image_link :platform_path
                }));
            }
            await setLoading(false);
            if(loading === false && addBanner){
                setIsFileUploaded(false);
                setImageResult(null);
                setImageCrop({
                    width: 1440,
                    aspect: 3.2
                });
                setRefImage('');
                setCroppedImageUrl('');
                history.goBack();
            }
        }
    }

    var ref_image;
    const onImageLoaded = (image) => {
        ref_image = image;
        setRefImage(image);
    };

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop) => {
        setImageCrop(crop);
    };

    async function makeClientCrop(crop) {
        if ((ref_image || refImage) && crop.width && crop.height) {
            if(ref_image){
                getCroppedImg(ref_image, crop);
            }
            if(refImage){
                getCroppedImg(refImage, crop);
            }
        }
    }
    
    async function getCroppedImg(image, crop) {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
        let blob = await canvas.toBlob(function(blob) {
            setCroppedImageUrl(new File([blob], 'uploadeBanner.jpeg', { type: 'image/jpeg' }));
        }, 'image/jpeg');
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/banners" icon={arrowBackIcon}/>
                    </IonButtons>
                    <IonTitle>Add Banner</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {imageResult && (
                    <ReactCrop
                        src={imageResult}
                        crop={imageCrop}
                        ruleOfThirds
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                        locked={true}
                    />
                )}
                <input hidden type='file' accept="image/*" ref={fileInputRef} onChange={handleFileChange}/>
                {!isFileUploaded &&
                    <div className="ion-text-center">
                        
                        <IonButton shape="round" onClick={handlePictureClick}>UPLOAD BANNER</IonButton>
                    </div>
                }
                {isFileUploaded &&
                    <div className="ion-text-center">
                        <IonButton shape="round" onClick={handlePictureClick}>Change Banner</IonButton>
                        <IonButton shape="round" onClick={e=> addBannerFun()}>Save Banner</IonButton>
                    </div>
                }
                <IonLoading isOpen={loading || addBannerLoading === true} message="Please Wait"></IonLoading>
            </IonContent>
        </IonPage>
    )
}

export default AddBanner
