import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonPage, IonText, IonTitle, IonToggle, IonToolbar, useIonToast } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import React, { useState } from "react";
import { useAddon } from '../../../utils/contexts';
import useAPI from '../../../utils/api';
import { indexedValue } from '../AddonsRoutes';
import { alphaNumericValidation } from "../../../utils/validations";
import FeatHeader from '../../../components/dashboard/FeatHeader';


const MailChimpForm = () => {
    const addons:any = useAddon();
    const [present] = useIonToast();
    const API = useAPI();
    const [mailchimpApiKey, setMailchimpApiKey] = useState(Array.isArray(addons[indexedValue.mailchimp].data) ? '' : addons[indexedValue.mailchimp].data?.mailchimp_api_key);
    const [mailchimpListId, setMailchimpListId] = useState(Array.isArray(addons[indexedValue.mailchimp].data) ? '' : addons[indexedValue.mailchimp].data?.mailchimp_list_id);
    const [mailchimpCheck, setMailchimpCheck] = useState(Array.isArray(addons[indexedValue.mailchimp].data) ? false : addons[indexedValue.mailchimp].data?.status === 'active');
    
    const [apiKeyValidation, setApiKeyValidation] = useState("");
    const [listIdValidation, setListIdValidation] = useState("");

    const validation = () => {
        let result = true;

        if (!mailchimpApiKey) { 
            setApiKeyValidation("Mailchimp API Key is required");
            result = false;
        } else {
            if ((/[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/.test(mailchimpApiKey))) {
                setApiKeyValidation(
                    "Mailchimp API Key should not contain any special characters except '-'"
                );
                result = false;
            } else {
                setApiKeyValidation("");
            }
        }

        if (!mailchimpListId) {
            setListIdValidation("Mailchimp List Id is required");
            result = false;
        } else {
            if (!alphaNumericValidation(mailchimpListId)) {
                setListIdValidation(
                    "Mailchimp List Id should not contain any special characters"
                );
                result = false;
            } else {
                setListIdValidation("");
            }
        }

        return result;
    };

    const onSubmitHandler = async(e)=>{
        e.preventDefault();
        if (validation()) {
            const [ok, response] = Array.isArray(addons[indexedValue.mailchimp].data) ? 
                await API.createMailchimpAddon({"mailchimp_api_key": mailchimpApiKey, "mailchimp_list_id" : mailchimpListId,"status": mailchimpCheck ? "active" : "inactive"})
                : 
                await API.updateMailchimpAddon({"mailchimp_api_key": mailchimpApiKey, "mailchimp_list_id" : mailchimpListId,"status": mailchimpCheck ? "active" : "inactive", id: addons[indexedValue.mailchimp].data.id});

            if(ok) {
                present("Data saved successfully!", 1000);
                addons[indexedValue.mailchimp].updateMethod(!addons[indexedValue.mailchimp].update);
            }
            else {
                present(response.message, 1000);
            }
        }
    }

    return (
        <IonPage>
           
            <FeatHeader
                title='Configure Mailchimp'
                defaultHref='/add-ons'
                showInfoButton={false}
                
            />
            <IonContent className="ion-padding">
                <form onSubmit={onSubmitHandler}>
                    <IonItem>
                        <IonLabel position="stacked"><h1>Mailchimp API Key</h1></IonLabel>
                        <IonInput placeholder="Enter Mailchimp API Key" type="text" value={mailchimpApiKey} onIonChange={(e)=>{ setMailchimpApiKey(e.detail.value)}}/>
                    </IonItem>
                    <IonText
                        color="danger"
                        className={`${apiKeyValidation ? "" : "ion-hide"}`}
                    >
                        <h5
                            style={{
                                fontSize: 12 + "px",
                                marginTop: 0 + "px",
                                marginBottom: 0 + "px",
                            }}
                            className="ion-margin-horizontal"
                        >
                            {apiKeyValidation}
                        </h5>
                    </IonText>
                    <br/>
                    <IonItem>
                        <IonLabel position="stacked"><h1>Mailchimp List ID</h1></IonLabel>
                        <IonInput placeholder="Enter Mailchimp List ID" type="text" value={mailchimpListId} onIonChange={(e)=>{ setMailchimpListId(e.detail.value)}}/>
                    </IonItem>
                    <IonText
                        color="danger"
                        className={`${listIdValidation ? "" : "ion-hide"}`}
                    >
                        <h5
                            style={{
                                fontSize: 12 + "px",
                                marginTop: 0 + "px",
                                marginBottom: 0 + "px",
                            }}
                            className="ion-margin-horizontal"
                        >
                            {listIdValidation}
                        </h5>
                    </IonText>
                    <br />
                    <IonItem lines="none">
                        <IonText><h5>Enable Mailchimp</h5></IonText>
                        <IonToggle slot="end" checked={mailchimpCheck} onIonChange={e => setMailchimpCheck(e.detail.checked)} />
                    </IonItem>
                    <br/>
                    <div className="ion-text-center">
                        <IonButton type="submit">Save</IonButton>
                    </div>
                </form>
            </IonContent>
        </IonPage>
    );

}

export default MailChimpForm;