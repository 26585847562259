export const GET_MEDIA_IMAGE_REQUEST = 'GET_MEDIA_IMAGE_REQUEST';
export const GET_MEDIA_IMAGE_SUCCESS = 'GET_MEDIA_IMAGE_SUCCESS';
export const GET_MEDIA_IMAGE_FAIL = 'GET_MEDIA_IMAGE_FAIL';

export const UPLOAD_MEDIA_IMAGE_REQUEST ='UPLOAD_MEDIA_IMAGE_REQUEST'
export const UPLOAD_MEDIA_IMAGE_SUCCESS = 'UPLOAD_MEDIA_IMAGE_SUCCESS'
export const UPLOAD_MEDIA_IMAGE_FAIL = 'UPLOAD_MEDIA_IMAGE_FAIL'

export const DELETE_MEDIA_IMAGE_REQUEST = 'DELETE_MEDIA_IMAGE_REQUEST'
export const DELETE_MEDIA_IMAGE_SUCCESS ='UPLOAD_MEDIA_IMAGE_SUCCESS'
export const DELETE_MEDIA_IMAGE_FAIL = 'DELETE_MEDIA_IMAGE_FAIL'