import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonItem,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import { BrowserView, MobileView } from "react-device-detect";

const ToolCard = ({ toolData }) => {
  return (
    <IonCard key={toolData.id}>
    <IonCardHeader>
      <IonCardTitle>{toolData.title}</IonCardTitle>
    </IonCardHeader>
    <IonCardContent>
      <IonGrid>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <IonCol size="auto">
            <IonImg
              src={toolData.imageUrl}
              alt={`${toolData.title} Image`}
              style={{  height: "80px" }}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonText>{toolData.description}</IonText>
      <IonRow>
        <IonCol>
          <BrowserView>
            <IonButton onClick={() => window.open(toolData.link, "_blank")}>
              Open {toolData.name}
            </IonButton>
          </BrowserView>

          <MobileView>
            <IonButton
              size="small"
              onClick={() => window.open(toolData.link, "_blank")}
            >
              Open
            </IonButton>
          </MobileView>
        </IonCol>
      </IonRow>
    </IonCardContent>
  </IonCard>
     
  );
};

export default ToolCard;
