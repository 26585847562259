import { IonAccordion, IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonLoading, IonPage, IonReorder, IonReorderGroup, IonRow, IonText, ItemReorderEventDetail, useIonToast } from "@ionic/react";
import useFetch from '../../utils/useFetch';
import { trashOutline as trashIcon, createOutline as updateIcon } from "ionicons/icons";
import useAPI from "../../utils/api";
import { useState } from "react";
import { useHistory } from "react-router";
import Header from '../../components/dashboard/Header'


const AppMenu = () => {
    let [updateMenu, setUpdateMenu] = useState(false);
    let menu = useFetch("getAppMenus", updateMenu);
    const history = useHistory();
    const API = useAPI();
    const [present] = useIonToast();
    const deleteMenu = async (id) => {
        try {
            const [ok, response] = await API.deleteAppMenu(id);
            if (ok) {
                setUpdateMenu(!updateMenu)
            } else {
                present(response.message, 3000)
            }
        }
        catch (err) {
            present(err.message, 3000)
        }
    }

    const doLinksReorder = async (event, menuData) => {
        const { from, to } = event.detail;

        if (menu.data.data.id === menuData.id) {
            const links = [...menuData.links];
            const [movedItem] = links.splice(from, 1);
            links.splice(to, 0, movedItem);

            links.forEach((link, index) => {
                link.priority = links.length - index;
            });

            try {
                const [ok, response] = await API.updateAppMenu({ ...menuData, links });
                if (!ok) {
                    present(response.message, 3000);
                }
            } catch (err) {
                present(err.message, 3000);
            }
        }

        event.detail.complete();
    };

    return (
        <IonPage>
            <Header name="App Menu" />
            <IonContent className="ion-padding">

                <IonText className="ion-text-center ion-margin-bottom">
                    <h5 className="ion-no-margin"><strong>Manage Menu for your app</strong></h5>
                    <p>Using Menu you can manage menu for your customers</p>
                </IonText>
                {
                    menu.loading === false ? menu?.data?.data?.id ?
                        <IonCard key={menu.data.data.id}>
                                    <IonCardHeader >
                                        <IonCardTitle>
                                            <IonRow>
                                                        <IonCol size="9">
                                                            <IonText>
                                                                {menu.data.data.name}
                                                            </IonText>
                                                        </IonCol>
                                                        <IonCol size="3" className="ion-text-right">
                                                                <IonIcon className="pointer-cursor" icon={updateIcon} onClick={() => { history.push(`/content/menus/app-menu/update/${menu.data.data.id}`) }}></IonIcon>
                                                                <IonIcon className="pointer-cursor" 
                                                                onClick={() => { deleteMenu(menu.data.data.id) }}
                                                                    icon={trashIcon}
                                                                ></IonIcon>
                                                        </IonCol>
                                                        </IonRow>
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <hr style={{ borderTop: '2px solid #ccc' }} />
                                    <IonCardContent>
                                        <IonReorderGroup disabled={false} onIonItemReorder={(event) => { doLinksReorder(event, menu.data.data) }} >
                                        {menu.data.data.links.map((link) => (
                                                <div key={link.id}>
                                                    <IonItem>
                                                        <IonGrid style={{ width: '100%' }}>
                                                            <IonRow>
                                                                <IonCol size="4" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{link.text}</IonCol>
                                                                <IonCol size="7" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                                    <IonText>{link.link}</IonText>
                                                                </IonCol>
                                                                <IonCol size="1">
                                                                    <IonReorder />
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                    </IonItem>
                                                </div>
                                            ))}
                                        </IonReorderGroup>
                                    </IonCardContent>
                                </IonCard>
                        : (<div className="ion-text-center">
                        <IonButton shape="round" routerLink="/content/menus/app-menu/create">Add Menu</IonButton>
                    </div>) : <IonLoading isOpen={true}></IonLoading>
                }
            </IonContent>
        </IonPage>
    )
}

export default AppMenu