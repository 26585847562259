import { IonContent, IonPage } from "@ionic/react";
import Header from "../../components/dashboard/Header";

const Features = () => {
    return (
        <IonPage>
            <Header name="Features" />
            <IonContent>
                <iframe
                    src="https://feedback.userreport.com/ba4ea186-91bd-404c-87bc-40277321f02e/#ideas/popular"
                    style={{ width: "95%", height: "100%" }}
                    frameBorder="0"
                ></iframe>
            </IonContent>
        </IonPage>
    );
};

export default Features;
