import { Share } from '@capacitor/share';
import { IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonItem, IonLoading, IonPage, IonRow,isPlatform } from '@ionic/react';
import {
    cloudDownload as cloudDownloadIcon,
    shareSocial as shareSocialIcon
} from 'ionicons/icons';
import { useState } from 'react';
import { MobileView } from 'react-device-detect';
import Header from '../../components/dashboard/Header';
import useFetch from '../../utils/useFetch';
import { Filesystem, Directory} from '@capacitor/filesystem';

const Printables = () => {
    const {loading, data, status} = useFetch("printables");
    const [downloadingLoader, setDownloadingLoader] = useState(false);

    const sharePrintables = async (value: any)=>{
        await Share.share({
            title: 'Intelikart Printables',
            text: ``
        });
    }

    var UrlImageDownloader = async (value) =>  {
        setDownloadingLoader(true);
        if (isPlatform("capacitor")) {
            try {
                const url = value
                const splitedArray = url.split('/');
                const fileName = splitedArray[splitedArray.length-1];
                Filesystem.downloadFile({path:fileName,url:value,directory:Directory.Documents}).then((res)=>{
            alert("Download successfull"); 
                }).catch(err=>alert(err))
            }
            catch(err){
                alert(err)
            }
        }else{
            console.log(value)
        await fetch(value, {
            method: 'GET'
        }).then(function (response) {
            response.arrayBuffer().then(function (buffer) {
                var url = window.URL.createObjectURL(new Blob([buffer]));
                var link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', value.substr(value.lastIndexOf('/') + 1));
                document.body.appendChild(link);
                link.click();
            });
        }).catch(function (error) {
            console.error(error.message);
        });
    }
        setDownloadingLoader(false);
    }

    return (
    <IonPage>
        <div className="ion-page" id="main-content">
            <Header name="Printables"/>
            <IonContent className="ion-padding">
                <IonGrid>
                    <IonRow>
                        {!loading && status === "fetched" && data.data.map((elem)=>{
                            return(<IonCol key={elem.type} size="12" sizeSm="6">
                                <IonCard>
                                    <IonCardContent className="ion-no-padding">
                                    <IonItem style={{paddingTop: 16+'px'}}><IonImg src={elem.image_link}></IonImg></IonItem>
                                    <IonItem lines="none">
                                        <MobileView><IonIcon size="large" slot="start" icon={shareSocialIcon} onClick={(e)=> sharePrintables(elem)}></IonIcon></MobileView>
                                        <div className="ion-text-center"><h6>{elem.type}</h6></div>
                                        <IonIcon slot="end" size="large"
                                        style={{cursor:"pointer"}} icon={cloudDownloadIcon} onClick={e=> UrlImageDownloader(elem.image_link)}></IonIcon>
                                    </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>)
                        })}
                    </IonRow>
                </IonGrid>
                <IonLoading isOpen={loading || downloadingLoader === true} message="Please Wait"></IonLoading>
            </IonContent>
        </div>
    </IonPage>
)}

export default Printables