export const LOAD_TIPS_BANNERS_REQUEST = 'LOAD_TIPS_BANNERS_REQUEST'
export const LOAD_TIPS_BANNERS_SUCCESS = 'LOAD_TIPS_BANNERS_SUCCESS'
export const LOAD_TIPS_BANNERS_FAIL = 'LOAD_TIPS_BANNERS_FAIL'

export const LOAD_HOME_SCREEN_REQUEST = 'LOAD_HOME_SCREEN_REQUEST'
export const LOAD_HOME_SCREEN_SUCCESS = 'LOAD_HOME_SCREEN_SUCCESS'
export const LOAD_HOME_SCREEN_FAIL = 'LOAD_HOME_SCREEN_FAIL'

export const LOAD_HOME_SCREEN_WITH_DAYS_REQUEST = 'LOAD_HOME_SCREEN_WITH_DAYS_REQUEST'
export const LOAD_HOME_SCREEN_WITH_DAYS_SUCCESS = 'LOAD_HOME_SCREEN_WITH_DAYS_SUCCESS'
export const LOAD_HOME_SCREEN_WITH_DAYS_FAIL = 'LOAD_HOME_SCREEN_WITH_DAYS_FAIL'

export const LOAD_PROFILE_COMPLETION_REQUEST='LOAD_PROFILE_COMPLETION_REQUEST'
export const LOAD_PROFILE_COMPLETION_SUCCESS='LOAD_PROFILE_COMPLETION_SUCCESS'
export const LOAD_PROFILE_COMPLETION_FAIL='LOAD_PROFILE_COMPLETION_FAIL'

export const LOAD_ACADEMY_CONTENT_REQUEST = 'LOAD_ACADEMY_CONTENT_REQUEST'
export const LOAD_ACADEMY_CONTENT_SUCCESS = 'LOAD_ACADEMY_CONTENT_SUCCESS'
export const LOAD_ACADEMY_CONTENT_FAIL = 'LOAD_ACADEMY_CONTENT_FAIL'

export const LOAD_CUSTOMERS_REQUEST = 'LOAD_CUSTOMERS_REQUEST'
export const LOAD_CUSTOMERS_SUCCESS = 'LOAD_CUSTOMERS_SUCCESS'
export const LOAD_CUSTOMERS_FAIL = 'LOAD_CUSTOMERS_FAIL'

export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST'
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS'
export const GET_COUPONS_FAIL = 'GET_COUPONS_FAIL'

export const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST'
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS'
export const CREATE_COUPON_FAIL = 'CREATE_COUPON_FAIL'

export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST'
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS'
export const DELETE_COUPON_FAIL = 'DELETE_COUPON_FAIL'

export const GET_BANNERS_REQUEST = 'GET_BANNERS_REQUEST'
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS'
export const GET_BANNERS_FAIL = 'GET_BANNERS_FAIL'

export const CREATE_BANNER_REQUEST = 'CREATE_BANNER_REQUEST'
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS'
export const CREATE_BANNER_FAIL = 'CREATE_BANNER_FAIL'

export const DELETE_BANNER_REQUEST = 'DELETE_BANNER_REQUEST'
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS'
export const DELETE_BANNER_FAIL = 'DELETE_BANNER_FAIL'

export const GET_SETTINGS_BASIC_INFO_REQUEST = 'GET_SETTINGS_BASIC_INFO_REQUEST'
export const GET_SETTINGS_BASIC_INFO_SUCCESS = 'GET_SETTINGS_BASIC_INFO_SUCCESS'
export const GET_SETTINGS_BASIC_INFO_FAIL = 'GET_SETTINGS_BASIC_INFO_FAIL'

export const SET_SETTINGS_BASIC_INFO_REQUEST = 'SET_SETTINGS_BASIC_INFO_REQUEST'
export const SET_SETTINGS_BASIC_INFO_SUCCESS = 'SET_SETTINGS_BASIC_INFO_SUCCESS'
export const SET_SETTINGS_BASIC_INFO_FAIL = 'SET_SETTINGS_BASIC_INFO_FAIL'

export const GET_SETTINGS_LOCALIZATION_REQUEST = 'GET_SETTINGS_LOCALIZATION_REQUEST'
export const GET_SETTINGS_LOCALIZATION_SUCCESS = 'GET_SETTINGS_LOCALIZATION_SUCCESS'
export const GET_SETTINGS_LOCALIZATION_FAIL = 'GET_SETTINGS_LOCALIZATION_FAIL'

export const SET_SETTINGS_LOCALIZATION_REQUEST = 'SET_SETTINGS_LOCALIZATION_REQUEST'
export const SET_SETTINGS_LOCALIZATION_SUCCESS = 'SET_SETTINGS_LOCALIZATION_SUCCESS'
export const SET_SETTINGS_LOCALIZATION_FAIL = 'SET_SETTINGS_LOCALIZATION_FAIL'

export const GET_SETTINGS_SERVICE_DETAILS_REQUEST = 'GET_SETTINGS_SERVICE_DETAILS_REQUEST'
export const GET_SETTINGS_SERVICE_DETAILS_SUCCESS = 'GET_SETTINGS_SERVICE_DETAILS_SUCCESS'
export const GET_SETTINGS_SERVICE_DETAILS_FAIL = 'GET_SETTINGS_SERVICE_DETAILS_FAIL'

export const SET_SETTINGS_SERVICE_DETAILS_REQUEST = 'SET_SETTINGS_SERVICE_DETAILS_REQUEST'
export const SET_SETTINGS_SERVICE_DETAILS_SUCCESS = 'SET_SETTINGS_SERVICE_DETAILS_SUCCESS'
export const SET_SETTINGS_SERVICE_DETAILS_FAIL = 'SET_SETTINGS_SERVICE_DETAILS_FAIL'

export const GET_SETTINGS_QUICK_ORDERS_REQUEST = 'GET_SETTINGS_QUICK_ORDERS_REQUEST'
export const GET_SETTINGS_QUICK_ORDERS_SUCCESS = 'GET_SETTINGS_QUICK_ORDERS_SUCCESS'
export const GET_SETTINGS_QUICK_ORDERS_FAIL = 'GET_SETTINGS_QUICK_ORDERS_FAIL'

export const SET_SETTINGS_QUICK_ORDERS_REQUEST = 'SET_SETTINGS_QUICK_ORDERS_REQUEST'
export const SET_SETTINGS_QUICK_ORDERS_SUCCESS = 'SET_SETTINGS_QUICK_ORDERS_SUCCESS'
export const SET_SETTINGS_QUICK_ORDERS_FAIL = 'SET_SETTINGS_QUICK_ORDERS_FAIL'

export const GET_SETTINGS_PAYMENTS_REQUEST = 'GET_SETTINGS_PAYMENTS_REQUEST'
export const GET_SETTINGS_PAYMENTS_SUCCESS = 'GET_SETTINGS_PAYMENTS_SUCCESS'
export const GET_SETTINGS_PAYMENTS_FAIL = 'GET_SETTINGS_PAYMENTS_FAIL'

export const SET_SETTINGS_PAYMENTS_REQUEST = 'SET_SETTINGS_PAYMENTS_REQUEST'
export const SET_SETTINGS_PAYMENTS_SUCCESS = 'SET_SETTINGS_PAYMENTS_SUCCESS'
export const SET_SETTINGS_PAYMENTS_FAIL = 'SET_SETTINGS_PAYMENTS_FAIL'

export const GET_SETTINGS_RAZORPAY_REQUEST = 'GET_SETTINGS_RAZORPAY_REQUEST'
export const GET_SETTINGS_RAZORPAY_SUCCESS = 'GET_SETTINGS_RAZORPAY_SUCCESS'
export const GET_SETTINGS_RAZORPAY_FAIL = 'GET_SETTINGS_RAZORPAY_FAIL'

export const SET_SETTINGS_RAZORPAY_REQUEST = 'SET_SETTINGS_RAZORPAY_REQUEST'
export const SET_SETTINGS_RAZORPAY_SUCCESS = 'SET_SETTINGS_RAZORPAY_SUCCESS'
export const SET_SETTINGS_RAZORPAY_FAIL = 'SET_SETTINGS_RAZORPAY_FAIL'

export const GET_TAXES_REQUEST = 'GET_TAXES_REQUEST'
export const GET_TAXES_SUCCESS = 'GET_TAXES_SUCCESS'
export const GET_TAXES_FAIL = 'GET_TAXES_FAIL'

export const CREATE_TAX_REQUEST = 'CREATE_TAX_REQUEST'
export const CREATE_TAX_SUCCESS = 'CREATE_TAX_SUCCESS'
export const CREATE_TAX_FAIL = 'CREATE_TAX_FAIL'

export const DELETE_TAX_REQUEST = 'DELETE_TAX_REQUEST'
export const DELETE_TAX_SUCCESS = 'DELETE_TAX_SUCCESS'
export const DELETE_TAX_FAIL = 'DELETE_TAX_FAIL'

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL'

export const CHANGE_ORDER_STAFF_REQUEST = 'CHANGE_ORDER_STAFF_REQUEST'
export const CHANGE_ORDER_STAFF_SUCCESS = 'CHANGE_ORDER_STAFF_SUCCESS'
export const CHANGE_ORDER_STAFF_FAIL = 'CHANGE_ORDER_STAFF_FAIL'

export const GET_PRINTABLES_REQUEST = 'GET_PRINTABLES_REQUEST'
export const GET_PRINTABLES_SUCCESS = 'GET_PRINTABLES_SUCCESS'
export const GET_PRINTABLES_FAIL = 'GET_PRINTABLES_FAIL'

export const GET_ANNOUNCEMENTS_REQUEST = 'GET_ANNOUNCEMENTS_REQUEST'
export const GET_ANNOUNCEMENTS_SUCCESS = 'GET_ANNOUNCEMENTS_SUCCESS'
export const GET_ANNOUNCEMENTS_FAIL = 'GET_ANNOUNCEMENTS_FAIL'

export const CREATE_ANNOUNCEMENT_REQUEST = 'CREATE_ANNOUNCEMENT_REQUEST'
export const CREATE_ANNOUNCEMENT_SUCCESS = 'CREATE_ANNOUNCEMENT_SUCCESS'
export const CREATE_ANNOUNCEMENT_FAIL = 'CREATE_ANNOUNCEMENT_FAIL'

export const GET_EMAILS_STATS_REQUEST = 'GET_EMAILS_STATS_REQUEST'
export const GET_EMAILS_STATS_SUCCESS = 'GET_EMAILS_STATS_SUCCESS'
export const GET_EMAILS_STATS_FAIL = 'GET_EMAILS_STATS_FAIL'

export const CREATE_EMAILS_REQUEST = 'CREATE_EMAILS_REQUEST'
export const CREATE_EMAILS_SUCCESS = 'CREATE_EMAILS_SUCCESS'
export const CREATE_EMAILS_FAIL = 'CREATE_EMAILS_FAIL'

export const GET_ORDERS_BY_STATUS_REQUEST = 'GET_ORDERS_BY_STATUS_REQUEST'
export const GET_ORDERS_BY_STATUS_SUCCESS = 'GET_ORDERS_BY_STATUS_SUCCESS'
export const GET_ORDERS_BY_STATUS_FAIL = 'GET_ORDERS_BY_STATUS_FAIL'

export const GET_ORDER_BY_ID_REQUEST = 'GET_ORDER_BY_ID_REQUEST'
export const GET_ORDER_BY_ID_SUCCESS = 'GET_ORDER_BY_ID_SUCCESS'
export const GET_ORDER_BY_ID_FAIL = 'GET_ORDER_BY_ID_FAIL'

export const GET_INVOICE_REQUEST = 'GET_INVOICE_REQUEST'
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS'
export const GET_INVOICE_FAIL = 'GET_INVOICE_FAIL'

export const ORDER_STATUS_CHANGE_REQUEST = 'ORDER_STATUS_CHANGE_REQUEST'
export const ORDER_STATUS_CHANGE_SUCCESS = 'ORDER_STATUS_CHANGE_SUCCESS'
export const ORDER_STATUS_CHANGE_FAIL = 'ORDER_STATUS_CHANGE_FAIL'

export const GET_ADJUSTMENTS_REQUEST = 'GET_ADJUSTMENTS_REQUEST'
export const GET_ADJUSTMENTS_SUCCESS = 'GET_ADJUSTMENTS_SUCCESS'
export const GET_ADJUSTMENTS_FAIL = 'GET_ADJUSTMENTS_FAIL'

export const SET_ADJUSTMENTS_REQUEST = 'SET_ADJUSTMENTS_REQUEST'
export const SET_ADJUSTMENTS_SUCCESS = 'SET_ADJUSTMENTS_SUCCESS'
export const SET_ADJUSTMENTS_FAIL = 'SET_ADJUSTMENTS_FAIL'

export const EDIT_ADJUSTMENTS_REQUEST = 'EDIT_ADJUSTMENTS_REQUEST'
export const EDIT_ADJUSTMENTS_SUCCESS = 'EDIT_ADJUSTMENTS_SUCCESS'
export const EDIT_ADJUSTMENTS_FAIL = 'EDIT_ADJUSTMENTS_FAIL'

export const DELETE_ADJUSTMENTS_REQUEST = 'DELETE_ADJUSTMENTS_REQUEST'
export const DELETE_ADJUSTMENTS_SUCCESS = 'DELETE_ADJUSTMENTS_SUCCESS'
export const DELETE_ADJUSTMENTS_FAIL = 'DELETE_ADJUSTMENTS_FAIL'

export const LOAD_PRODUCTS_BY_SUBCATEGORY_REQUEST = 'LOAD_PRODUCTS_BY_SUBCATEGORY_REQUEST'
export const LOAD_PRODUCTS_BY_SUBCATEGORY_SUCCESS = 'LOAD_PRODUCTS_BY_SUBCATEGORY_SUCCESS'
export const LOAD_PRODUCTS_BY_SUBCATEGORY_FAIL = 'LOAD_PRODUCTS_BY_SUBCATEGORY_FAIL'

export const GET_THEMES_REQUEST = 'GET_THEMES_REQUEST'
export const GET_THEMES_SUCCESS = 'GET_THEMES_SUCCESS'
export const GET_THEMES_FAIL = 'GET_THEMES_FAIL'

export const SET_THEME_REQUEST = 'SET_THEME_REQUEST'
export const SET_THEME_SUCCESS = 'SET_THEME_SUCCESS'
export const SET_THEME_FAIL = 'SET_THEME_FAIL'

export const GET_BRANDING_COLORS_REQUEST = 'GET_BRANDING_COLORS_REQUEST'
export const GET_BRANDING_COLORS_SUCCESS = 'GET_BRANDING_COLORS_SUCCESS'
export const GET_BRANDING_COLORS_FAIL = 'GET_BRANDING_COLORS_FAIL'

export const SET_BRANDING_COLOR_REQUEST = 'SET_BRANDING_COLOR_REQUEST'
export const SET_BRANDING_COLOR_SUCCESS = 'SET_BRANDING_COLOR_SUCCESS'
export const SET_BRANDING_COLOR_FAIL = 'SET_BRANDING_COLOR_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const GET_SALES_REQUEST = 'GET_SALES_REQUEST'
export const GET_SALES_SUCCESS = 'GET_SALES_SUCCESS'
export const GET_SALES_FAIL = 'GET_SALES_FAIL'

export const GET_VISITORS_REQUEST = 'GET_VISITORS_REQUEST'
export const GET_VISITORS_SUCCESS = 'GET_VISITORS_SUCCESS'
export const GET_VISITORS_FAIL = 'GET_VISITORS_FAIL'

export const GET_CART_REQUEST = 'GET_CART_REQUEST'
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS'
export const GET_CART_FAIL = 'GET_CART_FAIL'

export const SET_PUSH_NOTIFICATION_REQUEST = 'SET_PUSH_NOTIFICATION_REQUEST'
export const SET_PUSH_NOTIFICATION_SUCCESS = 'SET_PUSH_NOTIFICATION_SUCCESS'
export const SET_PUSH_NOTIFICATION_FAIL = 'SET_PUSH_NOTIFICATION_FAIL'

export const DELETE_PUSH_NOTIFICATION_REQUEST = 'DELETE_PUSH_NOTIFICATION_REQUEST'
export const DELETE_PUSH_NOTIFICATION_SUCCESS = 'DELETE_PUSH_NOTIFICATION_SUCCESS'
export const DELETE_PUSH_NOTIFICATION_FAIL = 'DELETE_PUSH_NOTIFICATION_FAIL'

//communication
export const GET_COMMUNICATIONS_REQUEST = 'GET_COMMUNICATIONS_REQUEST'
export const GET_COMMUNICATIONS_SUCCESS = 'GET_COMMUNICATIONS_SUCCESS'
export const GET_COMMUNICATIONS_FAIL ='GET_COMMUNICATIONS_FAIL'
export const POST_COMMUNICATIONS_REQUEST ='POST_COMMUNICATIONS_REQUEST'
export const POST_COMMUNICATIONS_SUCCESS ='POST_COMMUNICATIONS_SUCCESS'
export const POST_COMMUNICATIONS_FAIL ='POST_COMMUNICATIONS_FAIL'
export const POST_TAGLINE_REQUEST = 'POST_TAGLINE_REQUEST'
export const POST_TAGLINE_SUCCESS = 'POST_TAGLINE_SUCCESS'
export const POST_TAGLINE_FAIL = 'POST_TAGLINE_FAIL'
export const GET_TAGLINE_REQUEST = 'GET_TAGLINE_REQUEST'
export const GET_TAGLINE_SUCCESS ='GET_TAGLINE_SUCCESS'
export const GET_TAGLINE_FAIL ='GET_TAGLINE_FAIL'

export const POST_DELIVERY_ORDER_REQUEST = 'POST_DELIVERY_ORDER_REQUEST'
export const POST_DELIVERY_ORDER_SUCCESS = 'POST_DELIVERY_ORDER_SUCCESS'
export const POST_DELIVERY_ORDER_FAIL = 'POST_DELIVERY_ORDER_FAIL'

export const GET_DELIVERY_ORDER_REQUEST = 'GET_DELIVERY_ORDER_REQUEST'
export const GET_DELIVERY_ORDER_SUCCESS ='GET_DELIVERY_ORDER_SUCCESS'
export const GET_DELIVERY_ORDER_FAIL ='GET_DELIVERY_ORDER_FAIL'


export const DELETE_DELIVERY_ORDER_REQUEST = 'DELETE_COUPON_REQUEST'
export const DELETE_DELIVERY_ORDER_SUCCESS = 'DELETE_COUPON_SUCCESS'
export const DELETE_DELIVERY_ORDER_FAIL = 'DELETE_COUPON_FAIL'

export const UPDATE_DELIVERY_ORDER_REQUEST = 'UPDATE_COUPON_REQUEST'
export const UPDATE_DELIVERY_ORDER_SUCCESS = 'UPDATE_COUPON_SUCCESS'
export const UPDATE_DELIVERY_ORDER_FAIL = 'UPDATE_COUPON_FAIL'

// cashfree
export const GET_SETTINGS_CASHFREE_REQUEST = 'GET_SETTINGS_CASHFREE_REQUEST'
export const GET_SETTINGS_CASHFREE_SUCCESS = 'GET_SETTINGS_CASHFREE_SUCCESS'
export const GET_SETTINGS_CASHFREE_FAIL = 'GET_SETTINGS_CASHFREE_FAIL'

export const SET_SETTINGS_CASHFREE_REQUEST = 'SET_SETTINGS_CASHFREE_REQUEST'
export const SET_SETTINGS_CASHFREE_SUCCESS = 'SET_SETTINGS_CASHFREE_SUCCESS'
export const SET_SETTINGS_CASHFREE_FAIL = 'SET_SETTINGS_CASHFREE_FAIL'

// phonepe
export const GET_SETTINGS_PHONEPE_REQUEST = 'GET_SETTINGS_PHONEPE_REQUEST'
export const GET_SETTINGS_PHONEPE_SUCCESS = 'GET_SETTINGS_PHONEPE_SUCCESS'
export const GET_SETTINGS_PHONEPE_FAIL = 'GET_SETTINGS_PHONEPE_FAIL'

export const SET_SETTINGS_PHONEPE_REQUEST = 'SET_SETTINGS_PHONEPE_REQUEST'
export const SET_SETTINGS_PHONEPE_SUCCESS = 'SET_SETTINGS_PHONEPE_SUCCESS'
export const SET_SETTINGS_PHONEPE_FAIL = 'SET_SETTINGS_PHONEPE_FAIL'

// dynamic message display 
export const FETCH_SUGGESTIONS_REQUEST = "FETCH_SUGGESTIONS_REQUEST";
export const FETCH_SUGGESTIONS_SUCCESS = "FETCH_SUGGESTIONS_SUCCESS";
export const FETCH_SUGGESTIONS_FAILURE = "FETCH_SUGGESTIONS_FAILURE";

// constants for social profiles
export const POST_SOCIAL_PROFILE_REQUEST = "POST_SOCIAL_PROFILE_REQUEST";
export const POST_SOCIAL_PROFILE_SUCCESS = "POST_SOCIAL_PROFILE_SUCCESS";
export const POST_SOCIAL_PROFILE_FAILURE = "POST_SOCIAL_PROFILE_FAILURE";

export const GET_SOCIAL_PROFILE_REQUEST = "GET_SOCIAL_PROFILE_REQUEST";
export const GET_SOCIAL_PROFILE_SUCCESS = "GET_SOCIAL_PROFILE_SUCCESS";
export const GET_SOCIAL_PROFILE_FAILURE = "GET_SOCIAL_PROFILE_FAILURE";

// delivery message
export const POST_DELIVERY_MESSAGE_REQUEST = "POST_DELIVERY_MESSAGE_REQUEST";
export const POST_DELIVERY_MESSAGE_SUCCESS = "POST_DELIVERY_MESSAGE_SUCCESS";
export const POST_DELIVERY_MESSAGE_FAILURE = "POST_DELIVERY_MESSAGE_FAILURE";