import React, { useState, useEffect, useContext } from "react";
import {
  IonContent,
  IonText,
  IonItem,
  IonIcon,
  IonLoading,
  IonCard,
  IonButton,
  IonModal,
  IonLabel,
  IonInput,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  useIonToast,
  IonCheckbox,
} from "@ionic/react";
import { arrowBack as arrowBackIcon, closeOutline } from "ionicons/icons";
import useAPI from "../../../utils/api";
import { TimeslotContext, useTimeSlotUpdate } from "../../../utils/contexts";
import useFetch from "../../../utils/useFetch";
import FeatHeader from '../../../components/dashboard/FeatHeader';


const Timeslot = () => {
  const [selectedTimeslots, setSelectedTimeslots] = useState<string[]>([]);
  const [allTimeslots, setAllTimeslots] = useState<string[]>([]);
  const [addTime, setAddTime] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const API = useAPI();
  const [timeslotUpdate, setTimeslotUpdate] = useState(false);
  const { loading, data } = useFetch("timeslot", timeslotUpdate);
  const [present] = useIonToast();
 

  const statusType = [
    "08:00(AM)-10:00(AM)",
    "10:00(AM)-12:00(PM)",
    "12:00(PM)-02:00(PM)",
    "02:00(PM)-04:00(PM)",
    "04:00(PM)-06:00(PM)",
    "06:00(PM)-08:00(PM)",
    "08:00(PM)-10:00(PM)",
    "10:00(PM)-11:59(PM)"
  ];

  useEffect(() => {
    if (data && data.time_slot) {
      const uniqueTimeslots = Array.from(new Set([...statusType, ...data.time_slot]));
      setSelectedTimeslots(data.time_slot);
      setAllTimeslots(uniqueTimeslots);
    }
  }, [data]);

  const saveSelectedStatus = async () => {
    if(selectedTimeslots.length>0){
    try {
      const [ok, response] = await API.updateTimeslot({ time: selectedTimeslots });
      if (ok) {
        present({
          message: "Timeslot saved successfully",
          duration: 3000,
        });
      }
    } catch (error) {
      console.error("Error while saving timeslot:", error);
      present({
        message: "Failed to save status",
        duration: 3000,
        color: "danger",
      });
    }
  }
  else {
    present({
      message: "Please select minimum one time slot.",
      duration: 2000,
      position: "bottom",
    });
  }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handlePushChange = (e, statusItem) => {
    if (e.detail.checked) {
      setSelectedTimeslots((prevTimeslots) => [...prevTimeslots, statusItem]);
    } else {
      setSelectedTimeslots((prevTimeslots) => prevTimeslots.filter((item) => item !== statusItem));
    }
  };

  const saveCustomTime = async () => {
    if (addTime.length>0) {
   
      try {
        const [ok, response] = await API.postTimeslot({ time: addTime });
        if (ok) {
          present({
            message: "Saved successfully!",
            duration: 2000,
            position: "bottom",
          });
          setSelectedTimeslots((prevTimeslots) => [...prevTimeslots, addTime]);
          setAllTimeslots((prevTimeslots) => Array.from(new Set([...prevTimeslots, addTime])));
          setAddTime("");
          setTimeslotUpdate(!timeslotUpdate); // Trigger re-fetch
        }
      } catch (error) {
        console.error("Error saving custom time:", error);
      }
      setShowModal(false);
    } else {
      present({
        message: "Please enter a valid time slot.",
        duration: 2000,
        position: "bottom",
      });
    }
  };

  return (
    <TimeslotContext.Provider value={{ timeslotUpdate, setTimeslotUpdate }}>
      <IonPage>
       
        <FeatHeader
          title='Time Slot'
          defaultHref="/settings"
          showInfoButton={false}
        />
        <IonContent className="ion-padding">
          {/* <IonItem>
            <IonButton slot="end" shape="round" onClick={handleOpenModal} style={{ marginBottom: "1rem" }}>
              Add Time Slot
            </IonButton>
          </IonItem> */}

          {/* <IonModal isOpen={showModal}>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Custom Time Slot</IonTitle>
                <IonIcon slot="end" icon={closeOutline} size="large" onClick={() => setShowModal(false)} />
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding ion-justify-content-center">
              <IonItem>
                <IonLabel position="floating">Add Custom Time Slot</IonLabel>
                <IonInput value={addTime} onIonChange={(e) => setAddTime(e.detail.value)} />
              </IonItem>
              <div className="ion-text-center ion-padding-top">
                <IonButton shape="round" onClick={saveCustomTime}>
                  Save
                </IonButton>
              </div>
            </IonContent>
          </IonModal> */}
          {allTimeslots.map((statusItem, index) => (
            <IonCard key={index}>
              <IonItem>
                <IonCheckbox
                  checked={selectedTimeslots.includes(statusItem)}
                  onIonChange={(e) => handlePushChange(e, statusItem)}
                />
                 <IonText style={{ paddingLeft: "10px", fontSize: "14px" }}>
                  <h4 style={{ fontSize: "16px", margin: 0 }}>{statusItem}</h4>
                </IonText>
              </IonItem>
            </IonCard>
          ))}
          <IonButton
            shape="round"
            className="ion-padding"
            style={{ marginLeft: "40%" }}
            onClick={saveSelectedStatus}
          >
            Save
          </IonButton>
          <IonLoading isOpen={loading} message="Please Wait" />
        </IonContent>
      </IonPage>
    </TimeslotContext.Provider>
  );
};

export default Timeslot;
