
export const setTokensObject = (tokensObject: any) => {
    localStorage.setItem("IN_TOKEN_DATA", JSON.stringify(tokensObject));
}

export const getAccessToken = ()=> {
    const itemStr = localStorage.getItem("IN_TOKEN_DATA");
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr);
    return item.token;
}

export const getAccessPermission = ()=> {
    const itemStr = localStorage.getItem("IN_TOKEN_DATA");
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr);
    return item.permissions ?? [];
}
export const getTokenObject = ()=> {
    const itemStr = localStorage.getItem("IN_TOKEN_DATA");
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr);
    return item;
}

export const deleteTokenObject = ()=>{
    localStorage.removeItem("IN_TOKEN_DATA");
}