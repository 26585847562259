import { IonAlert, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonLoading, IonPage, IonRow, useIonToast } from '@ionic/react';
import {
    create as createIcon,
    eye as eyeIcon,
    trash as trashIcon
} from 'ionicons/icons';
import { useState } from 'react';
import { Link } from "react-router-dom";
import Header from '../../components/dashboard/Header';
import useAPI from '../../utils/api';
import { usePagesForUpdate, useUser } from '../../utils/contexts';
import useFetch from '../../utils/useFetch';

const PageListing = () => {
    const pageUpdateData: any = usePagesForUpdate(); 
    const {loading, status, data} = useFetch("pages", pageUpdateData.update);
    const API = useAPI();
    const currentUser: any = useUser();
    const [present] = useIonToast();
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState<any>();

    const deletePageFunction = async(pageId)=>{
        const [ok, response] = await API.deletePage(pageId);
        if(ok){
            present("Data saved successfully!", 1000);
            pageUpdateData.setUpdate(!pageUpdateData.update);
        }
    }

    return (
        <IonPage>
            <div className="ion-page" id="main-content">
                <Header name="Pages" docs={true} docslink='https://intelikart.tawk.help/article/pages' />
                <IonContent>
                    {!loading && status === "fetched" && data?.data ? 
                        <IonGrid className='ion-padding' style={{backgroundColor: 'rgba(250,250,250,.8)'}}>
                        <IonRow className='ionrow-style' style={{backgroundColor: 'white', padding: 10+'px'}}>
                            <IonCol className='ioncol-style'><b>Title</b></IonCol>
                            <IonCol className='ioncol-style'><b>Status</b></IonCol>
                            <IonCol><b>Preview</b></IonCol>
                        </IonRow>
                        {data?.data.map((element)=>{
                            return(
                                <IonRow key={element.id} className='ionrow-style ion-align-items-center'>
                                    <IonCol className='ioncol-style'>{element.name}</IonCol>
                                    <IonCol className='ioncol-style'>{`${element.status === 'ACTIVE' ? 'Published' : 'Draft'}`}</IonCol>
                                    <IonCol>
                                        <a href={`https://${currentUser?.store && (currentUser.store.enable_domain == 1 && currentUser.store.domain ? currentUser.store.domain : currentUser.store.subdomain)}/${element.slug}`} target='_blank'><IonButton shape='round' size='small'><IonIcon icon={eyeIcon}/></IonButton></a> 
                                        <Link to={{ pathname: `/content/pages/update`, state: element }}>
                                        <IonButton shape='round' size='small'><IonIcon icon={createIcon}/> </IonButton>
                                    </Link> 
                                    <IonButton shape='round' size='small' color='danger' onClick={(e)=> {setAlertData(element.id); setShowAlert(true)}}><IonIcon icon={trashIcon}/></IonButton></IonCol>
                                </IonRow>
                            )
                        })}
                        </IonGrid>
                    : ""}
                    <div className="ion-text-center">
                        <IonButton shape="round" routerLink="/content/pages/create">Add new page</IonButton>
                    </div>
                    <IonAlert
                        isOpen={showAlert === true}
                        onDidDismiss={() => setShowAlert(false)}
                        header={'Delete Page'}
                        message={`Are you sure?`}
                        buttons={[
                            {
                                text: 'CANCEL',
                                role: 'cancel'
                            },
                            {
                                text: 'DELETE',
                                handler: () => { deletePageFunction(alertData); }
                            }
                        ]}
                    />
                    <IonLoading isOpen={loading} message="Please wait"></IonLoading>
                </IonContent>
            </div>
        </IonPage>
    )
}

export default PageListing
