import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonItem,
  IonCard,
  IonText,
  IonRow,
  IonCol,
  IonGrid,
  IonChip,
  IonLoading,
  IonLabel,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { StoreCurrency } from "../../utils/currency";
import {
  CustomerContext,
  CartContext,
} from "../../utils/contexts";
import { arrowBack as arrowBackIcon } from "ionicons/icons";
import useAPI from "../../utils/api";
import ProductCard from "./ProductCard";
import RightBar from "./RightBar";
import useFetch from '../../utils/useFetch';

const AddOrders = () => {
  const [products, setProducts] = useState<any[]>([]);
  const currency = StoreCurrency();
  const [productInCart, setProductInCart] = useState<any[]>([]);
  const [myCartData, setMyCartData] = useState(null);
  
  const [customer, setCustomer] = useState<any>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [loading, setLoading] = useState(false);
  const API = useAPI();
  const {cagtegoryLoading, data, status} = useFetch("categories", "active");
  const [categories, setCategories] = useState([])

  useEffect(() => {
    if(!cagtegoryLoading && status === "fetched"){
        if(data?.data.length > 0) {
            setCategories([...data.data])
        }
    }
}, [cagtegoryLoading, data, status])

  useEffect(() => {
    if (categories && categories.length > 0 && !selectedSubcategory) {
        if (categories[0].subCategory[0]) {
            setSelectedSubcategory(categories[0].subCategory[0].id);
            fetchProductsForSubcategory(categories[0].subCategory[0].id);
        } else {
            const data = categories.filter((cat) => {
                return cat.subCategory.length > 0;
            });
            if (data && data.length !== 0) {
                setSelectedSubcategory(data[0].subCategory[0].id);
                fetchProductsForSubcategory(data[0].subCategory[0].id);
            }
        }
    }
  }, [categories, selectedSubcategory]);  

  const fetchProductsForSubcategory = async (subcategoryId) => {
    setLoading(true);
    try {
    const [ok, response] = await API.productsByCategory(subcategoryId);
        if(ok){
            if(response){
                setProducts(response.data);
                setLoading(false);
            }
        }
    } catch (error) {
      console.error("Error fetching products for subcategory:", error);
    }
  };

  const handleSubcategoryClick = (subcategory: any) => {
    setSelectedSubcategory(subcategory.id);
    fetchProductsForSubcategory(subcategory.id);
  };
  return (
    <CustomerContext.Provider value={{ customer, setCustomer }}>
      <CartContext.Provider value={{ myCartData, setMyCartData }}>
        <IonPage>
          <IonHeader style={{height:"68px"}}>
            <IonToolbar>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/orders/all" icon={arrowBackIcon} />
                Add Order
              </IonButtons>
              <IonTitle className="ion-no-padding ">
                <IonItem lines="none" className="ion-no-padding"></IonItem>
              </IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonGrid>
              <IonRow>
                {/* left part */}
                <IonCol sizeMd="8" size="12">
                  {/* Display Subcategory */}

                  <IonCard
                    className="ion-padding"
                    style={{ whiteSpace: "nowrap", position: "relative" }}
                  >
                    <IonItem lines="none">
                    <IonLabel>Categories</IonLabel>
                    </IonItem>
                    <IonGrid>
                      <IonRow className="sub-cat">
                        {categories?.map((parentCategory) =>
                          parentCategory.subCategory?.map((subcategory) => (
                            <IonChip
                              style={{
                                color:
                                  subcategory.id === selectedSubcategory
                                    ? "white"
                                    : "",
                                background:
                                  subcategory.id === selectedSubcategory
                                    ? "#4286f5"
                                    : "",
                              }}
                              color={
                                subcategory.id === selectedSubcategory
                                  ? ""
                                  : "secondary"
                              }
                              key={subcategory.id}
                              onClick={() =>
                                handleSubcategoryClick(subcategory)
                              }
                            >
                              <IonText>{subcategory.name}</IonText>
                            </IonChip>
                          ))
                        )}
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                  {/* display Product */}
                  <IonCard
                    className="ion-padding"
                    style={{ height: "450px", overflowY: "auto" }}
                  >
                       <IonItem lines="none">
                    <IonLabel>Products</IonLabel>
                    </IonItem>
                    <IonGrid className="ion-no-padding">
                      <IonRow>
                        {products.length !== 0 ? products.map((product) => {                           
                          return(product.product_type === "PHYSICAL" || product.product_type === null) &&(
                                <IonCol key={product.id} sizeMd="3" size="6" sizeSm="4">
                                  <ProductCard
                                    product={product}
                                    currency={currency}
                                    productInCart={productInCart}
                                    setProductInCart={setProductInCart}
                                  />
                                </IonCol>
                              )
                        }) : <IonCol><IonText className="ion-margin-start">No Products found</IonText></IonCol>}
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                </IonCol>

                {/* right part */}
                <IonCol sizeMd="4" size="12">                 
                  <RightBar
                    productInCart={productInCart}
                    setProductInCart={setProductInCart}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
          <IonLoading isOpen={loading}/>
        </IonPage>
      </CartContext.Provider>
    </CustomerContext.Provider>
  );
};

export default AddOrders;
