// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyD1hj3N00hnxteV-9JtufhYOirzWxLszJo",
    authDomain: "intelikart-11b5c.firebaseapp.com",
    databaseURL: "https://intelikart-11b5c.firebaseio.com",
    projectId: "intelikart-11b5c",
    storageBucket: "intelikart-11b5c.appspot.com",
    messagingSenderId: "53114676789",
    appId: "1:53114676789:web:385dc011822597cb68737c",
    measurementId: "G-3CL7R9RG1B"
  };

let messaging;

// Initialize Firebase

export const initializeFirebase = () => {
    const firebaseApp = initializeApp(firebaseConfig);
    messaging = getMessaging(firebaseApp);
    console.log('start --registering the application for firebase push notification')
};

export const getFirebaseToken = async () => {
    try {
        const permission = await Notification.requestPermission(); if (permission === "granted") {
            const currentToken = await getToken(
                messaging, 
                {vapidKey : 'BF5GTNXZeFErrqBeT1r5hc5XVuljxqOsTWzAUGPimsnoE-CapEugFIup7C9Yhppp5xodYT9S9jeisj3BeAXT2zg'}
                );
            if (currentToken) {
                console.log('current token for client: ', currentToken); //
                return currentToken;
            }
        } else if (permission === "denied") { alert("you denied for permission"); console.log("you denied for permission"); }
    } catch (err) { console.log("An error occurred while retrieving token. ", err); }
};