import { CREATE_PRODUCT_FAIL, CREATE_PRODUCT_REQUEST, CREATE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAIL, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, EDIT_PRODUCT_FAIL, EDIT_PRODUCT_REQUEST, EDIT_PRODUCT_SUCCESS, GET_PRODUCT_BY_ID_REQUEST, GET_PRODUCT_BY_ID_SUCCESS, GET_PRODUCT_BY_ID_FAIL,  SEARCH_PRODUCT_QUERY_REQUEST, SEARCH_PRODUCT_QUERY_SUCCESS, SEARCH_PRODUCT_QUERY_FAIL,ADD_PRODUCT_IN_BULK_REQUEST, ADD_PRODUCT_IN_BULK_SUCCESS, ADD_PRODUCT_IN_BULK_FAIL, BULK_EDIT_REQUEST, BULK_EDIT_SUCCESS, BULK_EDIT_FAIL } from "../constants/productConstants";
const API_ROOT = 'https://api2.intelikart.in/';

export const getProductById = (accessToken: string, ikDomain: string | number, productId: string | number) => async (dispatch: any) => {
    try{
        dispatch({type : GET_PRODUCT_BY_ID_REQUEST})
        const response = await fetch(`${ API_ROOT }search/products?productId=${productId}`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
            },
        });
        const data = await response.json();
        dispatch({
            type: GET_PRODUCT_BY_ID_SUCCESS,
            payload: data.data[0]
        })
    }catch(error) {
        dispatch({
            type: GET_PRODUCT_BY_ID_FAIL,
            payload: error
        })
    }
}

export const createProduct = (productData: any, ikDomain: string | number, accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : CREATE_PRODUCT_REQUEST})
        const response = await fetch(`${ API_ROOT }products`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
            },
            body: JSON.stringify(productData)
        });
        const data = await response.json();
        dispatch({
            type: CREATE_PRODUCT_SUCCESS,
            payload: data.data
        })
    }catch(error) {
            dispatch({
                type: CREATE_PRODUCT_FAIL,
                payload: error
            })
    }
}

export const addProductInBulk = (productsData: any, ikDomain: string | number, accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : ADD_PRODUCT_IN_BULK_REQUEST})
        const response = await fetch(`${ API_ROOT }products/bulk`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
            },
            body: JSON.stringify(productsData)
        });
        const data = await response.json();
        dispatch({
            type: ADD_PRODUCT_IN_BULK_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: ADD_PRODUCT_IN_BULK_FAIL,
                payload: error
            })
    }
}

export const editProduct = (productData: any, ikDomain: string | number, accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : EDIT_PRODUCT_REQUEST})
        const response = await fetch(`${ API_ROOT }products`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
            },
            body: JSON.stringify(productData)
        });
        const data = await response.json();
        dispatch({
            type: EDIT_PRODUCT_SUCCESS,
            payload: data.data
        })
    }catch(error) {
            dispatch({
                type: EDIT_PRODUCT_FAIL,
                payload: error
            })
    }
}

export const deleteProduct = (accessToken: string,storeId: string,productId, ikDomain: string | number,) => async (dispatch: any) => {
    try{
        dispatch({type : DELETE_PRODUCT_REQUEST})
        const response = await fetch(`${ API_ROOT }products/${productId}`, {
            method: 'DELETE',
            headers: {
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
        });
        const data = await response.json();
        dispatch({
            type: DELETE_PRODUCT_SUCCESS,
            payload: data
        })
                
    }catch(error) {
            dispatch({
                type: DELETE_PRODUCT_FAIL,
                payload: error
            })
    }
}


export const searchQueryProduct = (query: string, storeId: string | number) => async (dispatch: any) => {

    try{
        dispatch({type : SEARCH_PRODUCT_QUERY_REQUEST})
        const response = await fetch(`${ API_ROOT }api/search/products?includeall=true&query=${query}&storeId=${storeId}`, {
            method: 'GET'
        });
        const data = await response.json();
        dispatch({
            type: SEARCH_PRODUCT_QUERY_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: SEARCH_PRODUCT_QUERY_FAIL,
                payload: error
            })
    }
}

export const bulkEditProduct = (productData: any, ikDomain: string | number, accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : BULK_EDIT_REQUEST})
        const response = await fetch(`${ API_ROOT }products/bulk-edit`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
            },
            body: JSON.stringify(productData)
        });
        const data = await response.json();
        dispatch({
            type: BULK_EDIT_SUCCESS,
            payload: data.data
        })
    }catch(error) {
            dispatch({
                type: BULK_EDIT_FAIL,
                payload: error
            })
    }
}

