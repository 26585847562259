import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonText, IonTitle, IonToggle, IonToolbar, useIonToast } from '@ionic/react';
import {
    arrowBack as arrowBackIcon
} from 'ionicons/icons';
import { useState } from 'react';
import useAPI from '../../../utils/api';
import { useAddon } from '../../../utils/contexts';
import { indexedValue } from '../AddonsRoutes';
import FeatHeader from '../../../components/dashboard/FeatHeader';


const DynamicFieldsForm = () => {
    const addons:any = useAddon();
    const [present] = useIonToast();
    const API = useAPI();
    const [manualCheck, setManualCheck] = useState(Array.isArray(addons[indexedValue.dynamicFields].data) ? false : addons[indexedValue.dynamicFields].data?.status === 'active');

    const onSubmitHandler = async(e)=>{
        e.preventDefault();
            const [ok, response] = Array.isArray(addons[indexedValue.dynamicFields].data) ? 
                await API.createDynamicFieldsAddon({
                    "status": manualCheck ? "active" : "inactive"
                })
                :
                await API.createDynamicFieldsAddon({
                    "status": manualCheck ? "active" : "inactive",
                    id: addons[indexedValue.dynamicFields].data.id
                })
                if(response.statusCode === 400){
                    setManualCheck(!manualCheck);
                    present(response.message, 1500);
                }
                if(ok) {
                    present("Data saved successfully!", 1500);
                    addons[indexedValue.dynamicFields].updateMethod(!addons[indexedValue.dynamicFields].update);
                }
    }

    return ( <IonPage>
        
        <FeatHeader
            title='Configure Product Fields'
            showInfoButton={false}
            defaultHref='/add-ons'
        />
            <IonContent className="ion-padding">
                <form onSubmit={onSubmitHandler}>
                    <IonItem lines="none">
                        <IonText><h5>Enable product Fields</h5></IonText>
                        <IonToggle slot="end" checked={manualCheck} onIonChange={e => setManualCheck(e.detail.checked)} />
                    </IonItem>
                    <br/>
                    <div className="ion-text-center">
                        <IonButton type="submit">Save</IonButton>
                    </div>
                </form>
            </IonContent>
        </IonPage>
    )
}

export default DynamicFieldsForm