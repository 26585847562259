import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonText, IonTitle, IonToggle, IonToolbar, useIonToast } from '@ionic/react'
import { useState } from 'react'
import {
    arrowBack as arrowBackIcon,
    informationCircleOutline,
} from 'ionicons/icons';
import { useAddon } from '../../../utils/contexts';
import useAPI from '../../../utils/api';
import { indexedValue } from '../AddonsRoutes';
import { useSelector } from 'react-redux';
import { advEmailValidation } from '../../../utils/validations';
import FeatHeader from '../../../components/dashboard/FeatHeader';


const SMTPForm = () => {
    const addons: any = useAddon();
    const [present] = useIonToast();
    const API = useAPI();
    
    const [hostValidation, setHostValidation] = useState('');
    const [portValidation, setPortValidation] = useState('');
    const [usernameValidation, setUsernameValidation] = useState('');
    const [passwordValidation, setPasswordValidation] = useState('');
    const [fromAddressValidation, setFromAddressValidation] = useState('');
    const [fromNameValidation, setFromNameValidation] = useState('');
    const [host, setHost] = useState(Array.isArray(addons[indexedValue.smtp].data) ? '' : addons[indexedValue.smtp].data?.host);
    const [port, setPort] = useState(Array.isArray(addons[indexedValue.smtp].data) ? '' : addons[indexedValue.smtp].data?.port);
    const [username, setUsername] = useState(Array.isArray(addons[indexedValue.smtp].data) ? '' : addons[indexedValue.smtp].data?.username);
    const [password, setPassword] = useState(Array.isArray(addons[indexedValue.smtp].data) ? '' : addons[indexedValue.smtp].data?.password);
    const [fromAddress, setFromAddress] = useState(Array.isArray(addons[indexedValue.smtp].data) ? '' : addons[indexedValue.smtp].data?.from_address);
    const [fromName, setFromName] = useState(Array.isArray(addons[indexedValue.smtp].data) ? '' : addons[indexedValue.smtp].data?.from_name);
    const [smtpCheck, setSmtpCheck] = useState(Array.isArray(addons[indexedValue.smtp].data) ? false : addons[indexedValue.smtp].data?.status === 'active');

    const { currentUser } = useSelector((state: any) => state.currentUser);

    const validation = () => {
        let result = true;

        if (!host) {
            setHostValidation('Host is required');
            result = false;
        } else {
            if(!(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/.test(host))){
                setHostValidation("Host is invalid")
                result = false;
            }
            else{
                setHostValidation('');
            }
        }

        if (!port) {
            setPortValidation('Port is required');
            result = false;
        } else {
            if(/\D/.test(port)){
                setPortValidation("Port is invalid")
                result = false;
            }
            else{
                setPortValidation('');
            }
        }

        if (!username) {
            setUsernameValidation('Username is required');
            result = false;
        } else {
            if(advEmailValidation(username)) {
                setUsernameValidation('Username is invalid');
                result = false
            } else {
                setUsernameValidation('');
            }
        }

        if (!password) {
            setPasswordValidation('App Password is required');
            result = false;
        } else {
            setPasswordValidation('');
        }

        if (!fromAddress) {
            setFromAddressValidation('From Email Id is required');
            result = false;
        } else {
            if(advEmailValidation(fromAddress)) {
                setFromAddressValidation('From Email Id is invalid');
                result = false
            } else {
                setFromAddressValidation('');
            }
        }

        if (!fromName) {
            setFromNameValidation('From Name is required');
            result = false;
        } else {
            setFromNameValidation('');
        }

        return result;
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();

        if (validation()) {
            const [ok, response] = Array.isArray(addons[indexedValue.smtp].data) ?
                await API.createSmtpAddon({ "host": host, "port": port, "username": username, "password": password, "from_address": fromAddress, "from_name": fromName, "status": smtpCheck ? "active" : "inactive" })
                :
                await API.updateSmtpAddon({ "host": host, "port": port, "username": username, "password": password, "from_address": fromAddress, "from_name": fromName, "status": smtpCheck ? "active" : "inactive", id: addons[indexedValue.smtp].data.id });
            if (ok) {
                present("Data saved successfully!", 1000);
                addons[indexedValue.smtp].updateMethod(!addons[indexedValue.smtp].update);
            } else {
                present(response.message, 1000);
            }
        }
    }
    const openLinkInNewTab = () => {
        window.open(`https://intelikart.tawk.help/article/personalized-email`, '_blank');
    };

    return (<IonPage>
       
        <FeatHeader title='Configure SMTP Settings' defaultHref="/add-ons" showInfoButton={true} onInfoClick={openLinkInNewTab} />
        <IonContent className="ion-padding">
            <form onSubmit={onSubmitHandler}>
                <IonItem>
                    <IonLabel position="stacked"><h1>Mail Host</h1></IonLabel>
                    <IonInput placeholder="e.g. smtp.gmail.com" type="text" value={host} onIonChange={(e) => { setHost(e.detail.value) }} />
                </IonItem>
                <IonText color="danger" className={`${hostValidation ? "" : "ion-hide"}`}>
                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px', marginBottom: 0 + 'px' }} className='ion-margin-horizontal'>{hostValidation}</h5>
                </IonText>
                <br />
                <IonItem>
                    <IonLabel position="stacked"><h1>Mail Port</h1></IonLabel>
                    <IonInput placeholder="e.g. 587" type="text" value={port} onIonChange={(e) => { setPort(e.detail.value) }} />
                </IonItem>
                <IonText color="danger" className={`${portValidation ? "" : "ion-hide"}`}>
                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px', marginBottom: 0 + 'px' }} className='ion-margin-horizontal'>{portValidation}</h5>
                </IonText>
                <br />
                <IonItem>
                    <IonLabel position="stacked"><h1>Mail Username</h1></IonLabel>
                    <IonInput placeholder={`e.g. ${currentUser?.data?.email}`} type="text" value={username} onIonChange={(e) => { setUsername(e.detail.value) }} />
                </IonItem>
                <IonText color="danger" className={`${usernameValidation ? "" : "ion-hide"}`}>
                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px', marginBottom: 0 + 'px' }} className='ion-margin-horizontal'>{usernameValidation}</h5>
                </IonText>
                <br />
                <IonItem>
                    <IonLabel position="stacked">
                        <h1>Mail Password </h1>
                    </IonLabel>
                    <IonInput placeholder="Enter Mail Password" type="password" value={password} onIonChange={(e) => { setPassword(e.detail.value) }} />
                </IonItem>
                <IonText color="danger" className={`${passwordValidation ? "" : "ion-hide"}`}>
                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px', marginBottom: 0 + 'px' }} className='ion-margin-horizontal'>{passwordValidation}</h5>
                </IonText>
                <br />
                <IonItem>
                    <IonLabel position="stacked"><h1>Mail From</h1></IonLabel>
                    <IonInput placeholder={`e.g. ${currentUser?.data?.email}`} type="text" value={fromAddress} onIonChange={(e) => { setFromAddress(e.detail.value) }} />
                </IonItem>
                <IonText color="danger" className={`${fromAddressValidation ? "" : "ion-hide"}`}>
                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px', marginBottom: 0 + 'px' }} className='ion-margin-horizontal'>{fromAddressValidation}</h5>
                </IonText>
                <br />
                <IonItem>
                    <IonLabel position="stacked"><h1>Mail From Name</h1></IonLabel>
                    <IonInput placeholder={`e.g. ${currentUser?.data?.store?.name}`} type="text" value={fromName} onIonChange={(e) => { setFromName(e.detail.value) }} />
                </IonItem>
                <IonText color="danger" className={`${fromNameValidation ? "" : "ion-hide"}`}>
                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px', marginBottom: 0 + 'px' }} className='ion-margin-horizontal'>{fromNameValidation}</h5>
                </IonText>
                <br />
                <IonItem lines="none">
                    <IonText><h5>Enable SMTP</h5></IonText>
                    <IonToggle slot="end" checked={smtpCheck} onIonChange={e => setSmtpCheck(e.detail.checked)} />
                </IonItem>
                <br />
                <div className="ion-text-center">
                    <IonButton type="submit">Save</IonButton>
                </div>
            </form>
        </IonContent>
    </IonPage>
    )
}

export default SMTPForm