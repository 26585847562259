import { IonAccordion, IonCard, IonItem, IonLabel } from "@ionic/react";
import { useUser, useWebsiteSettings } from "../../../../../utils/contexts";
import { useState } from "react";

const AppBrandingColors = () => {
  //const user: any = useUser();
  const settings: any = useWebsiteSettings();
  const [primaryColor, setPrimaryColor] = useState(
    settings?.websiteSettings?.global_settings?.branding_colors
      ?.primary_color || "#4286f5"
  );
  const [secondaryColor, setSecondaryColor] = useState(
    settings?.websiteSettings?.global_settings?.branding_colors
      ?.secondary_color || "#ee534f"
  );
  const [backgroundColor, setBackgroundColor] = useState(
    settings?.websiteSettings?.global_settings?.branding_colors
      ?.background_color || "#1d1d1d"
  );

  const colorCircleStyle = {
    display: "inline-block",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    margin: "5px",
    cursor: "pointer",
  };

  const array = [
    "#4286f5",
    "#ee534f",
    "#2e9b5a",
    "#e04e7b",
    "#f09125",
    "#5b55af",
    "#009788",
    "#00bcd5",
    "#2196f3",
    "#1d1d1d",
  ];

  const handlePrimaryColor = (color) => {
    setPrimaryColor(color);
    settings.setWebsiteSettings({
      ...settings.websiteSettings,
      global_settings: {
        ...settings.websiteSettings.global_settings,
        branding_colors: {
          ...settings.websiteSettings.global_settings.branding_colors,
          primary_color: color,
        },
      },
    });
  };

  const handleSecondaryColor = (color) => {
    setSecondaryColor(color);
    settings.setWebsiteSettings({
      ...settings.websiteSettings,
      global_settings: {
        ...settings.websiteSettings.global_settings,
        branding_colors: {
          ...settings.websiteSettings.global_settings.branding_colors,
          secondary_color: color,
        },
      },
    });
  };

  const handleBackgroundColor = (color) => {
    setBackgroundColor(color);
    settings.setWebsiteSettings({
      ...settings.websiteSettings,
      global_settings: {
        ...settings.websiteSettings.global_settings,
        branding_colors: {
          ...settings.websiteSettings.global_settings.branding_colors,
          background_color: color,
        },
      },
    });
  };

  return (
    <IonCard>
      <IonAccordion>
        <IonItem slot="header" color="light">
          <IonLabel>Branding colors</IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          {/* Primary Colours */}

          <div>
            <IonItem lines="none">
              <IonLabel>Primary Color</IonLabel>
            </IonItem>
            <div style={{ marginTop: "10px" }}>
              {array.map((color, index) => (
                <div
                  key={index}
                  style={{
                    ...colorCircleStyle,
                    backgroundColor: color,
                    position: "relative",
                  }}
                  onClick={() => handlePrimaryColor(color)}
                >
                  {primaryColor === color && (
                    <div
                      style={{
                        color: "white",
                        fontSize: "large",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      ✓
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <br />

          {/* Secondary Colours */}
          <div>
            <IonItem lines="none">
              <IonLabel>Secondary Color</IonLabel>
            </IonItem>
            <div style={{ marginTop: "10px" }}>
              {array.map((color, index) => (
                <div
                  key={index}
                  style={{
                    ...colorCircleStyle,
                    backgroundColor: color,
                    position: "relative",
                  }}
                  onClick={() => handleSecondaryColor(color)}
                >
                  {secondaryColor === color && (
                    <div
                      style={{
                        color: "white",
                        fontSize: "large",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      ✓
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <br />
          {/* Background Colours */}
          <div>
            <IonItem lines="none">
              <IonLabel>Background Color</IonLabel>
            </IonItem>
            <div style={{ marginTop: "10px" }}>
              {array.map((color, index) => (
                <div
                  key={index}
                  style={{
                    ...colorCircleStyle,
                    backgroundColor: color,
                    position: "relative",
                  }}
                  onClick={() => handleBackgroundColor(color)}
                >
                  {backgroundColor === color && (
                    <div
                      style={{
                        color: "white",
                        fontSize: "large",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      ✓
                    </div>
                  )}
                </div>
              ))}     
              <div   style={{
                color: "white",
                display: "inline-block",
                width: "40px",
                height: "40px",
                border:"0.5px solid black",
                borderRadius: "50%",
                margin: "5px",
                cursor: "pointer",
                position: "relative",
              }}
              onClick={() => handleBackgroundColor('#ffffff')}
              >
                 {backgroundColor === '#ffffff' && (
                <div
                      style={{
                        color: "black",
                        fontSize: "large",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      ✓
                    </div>
                     )}
    </div> 
            </div>  
          </div>
        </div>
      </IonAccordion>
    </IonCard>
  );
};

export default AppBrandingColors;
