import { 
    GET_GA_CONFIGURATION_REQUEST, GET_GA_CONFIGURATION_SUCCESS, GET_GA_CONFIGURATION_FAIL,
    POST_GA_CONFIGURATION_REQUEST, POST_GA_CONFIGURATION_SUCCESS, POST_GA_CONFIGURATION_FAIL,
    PUT_GA_CONFIGURATION_REQUEST, PUT_GA_CONFIGURATION_SUCCESS, PUT_GA_CONFIGURATION_FAIL,
    GET_WHATSAPP_CONFIGURATION_REQUEST, GET_WHATSAPP_CONFIGURATION_SUCCESS, GET_WHATSAPP_CONFIGURATION_FAIL,
    POST_WHATSAPP_CONFIGURATION_REQUEST, POST_WHATSAPP_CONFIGURATION_SUCCESS, POST_WHATSAPP_CONFIGURATION_FAIL,
    PUT_WHATSAPP_CONFIGURATION_REQUEST, PUT_WHATSAPP_CONFIGURATION_SUCCESS, PUT_WHATSAPP_CONFIGURATION_FAIL,
    GET_SHIPROCKET_CONFIGURATION_REQUEST, GET_SHIPROCKET_CONFIGURATION_SUCCESS, GET_SHIPROCKET_CONFIGURATION_FAIL,
    POST_SHIPROCKET_CONFIGURATION_REQUEST, POST_SHIPROCKET_CONFIGURATION_SUCCESS, POST_SHIPROCKET_CONFIGURATION_FAIL,
    PUT_SHIPROCKET_CONFIGURATION_REQUEST, PUT_SHIPROCKET_CONFIGURATION_SUCCESS, PUT_SHIPROCKET_CONFIGURATION_FAIL,
} from "../constants/addonsConstants";

export const gaConfigurationReducer = (state = { gaRecord: {} }, action: any) =>{
    switch(action.type){
        case GET_GA_CONFIGURATION_REQUEST:
        case POST_GA_CONFIGURATION_REQUEST:
        case PUT_GA_CONFIGURATION_REQUEST:
            return{
                ...state,
                loadingGARecords: true,
                error: null
            }
        case GET_GA_CONFIGURATION_SUCCESS:
            return{
                ...state,
                loadingGARecords: false,
                gaRecord: action.payload
            }

            case POST_GA_CONFIGURATION_SUCCESS:
            return{
                ...state,
                loadingGARecords: false,
                gaPostMessage: action.payload
            }

            case PUT_GA_CONFIGURATION_SUCCESS:
            return{
                ...state,
                loadingGARecords: false,
                gaPutMessage: action.payload
            }
        case GET_GA_CONFIGURATION_FAIL:
        case POST_GA_CONFIGURATION_FAIL:
        case PUT_GA_CONFIGURATION_FAIL:
            return{
                ...state,
                loadingGARecords: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const whatsappConfigurationReducer = (state = { whatsappRecord: {} }, action: any) =>{
    switch(action.type){
        case GET_WHATSAPP_CONFIGURATION_REQUEST:
        case POST_WHATSAPP_CONFIGURATION_REQUEST:
        case PUT_WHATSAPP_CONFIGURATION_REQUEST:
            return{
                ...state,
                loadingWhatsappRecords: true,
                error: null
            }
        case GET_WHATSAPP_CONFIGURATION_SUCCESS:
            return{
                ...state,
                loadingWhatsappRecords: false,
                whatsappRecord: action.payload
            }

            case POST_WHATSAPP_CONFIGURATION_SUCCESS:
            return{
                ...state,
                loadingWhatsappRecords: false,
                whatsappPostMessage: action.payload
            }

            case PUT_WHATSAPP_CONFIGURATION_SUCCESS:
            return{
                ...state,
                loadingWhatsappRecords: false,
                whatsappPutMessage: action.payload
            }
        case GET_WHATSAPP_CONFIGURATION_FAIL:
        case POST_WHATSAPP_CONFIGURATION_FAIL:
        case PUT_WHATSAPP_CONFIGURATION_FAIL:
            return{
                ...state,
                loadingWhatsappRecords: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const shiprocketConfigurationReducer = (state = { shiprocketRecord: {} }, action: any) =>{
    switch(action.type){
        case GET_SHIPROCKET_CONFIGURATION_REQUEST:
        case POST_SHIPROCKET_CONFIGURATION_REQUEST:
        case PUT_SHIPROCKET_CONFIGURATION_REQUEST:
            return{
                ...state,
                loadingShiprocketRecords: true,
                error: null
            }
        case GET_SHIPROCKET_CONFIGURATION_SUCCESS:
            return{
                ...state,
                loadingShiprocketRecords: false,
                shiprocketRecord: action.payload
            }

            case POST_SHIPROCKET_CONFIGURATION_SUCCESS:
            return{
                ...state,
                loadingShiprocketRecords: false,
                shiprocketPostMessage: action.payload
            }

            case PUT_SHIPROCKET_CONFIGURATION_SUCCESS:
            return{
                ...state,
                loadingShiprocketRecords: false,
                shiprocketPutMessage: action.payload
            }
        case GET_SHIPROCKET_CONFIGURATION_FAIL:
        case POST_SHIPROCKET_CONFIGURATION_FAIL:
        case PUT_SHIPROCKET_CONFIGURATION_FAIL:
            return{
                ...state,
                loadingShiprocketRecords: false,
                error: action.payload
            }
        default:
            return state
    }
}