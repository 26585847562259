import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonText, IonTitle,IonToolbar, useIonToast } from '@ionic/react';
import {
    arrowBack as arrowBackIcon
} from 'ionicons/icons';
import useAPI from '../../../utils/api';
import FeatHeader from '../../../components/dashboard/FeatHeader';


function GooglePlayStore() {
    const API = useAPI();
    const [present] = useIonToast();
    const buttonhandler =async ()=>{
        const [ok,response] = await API.getAPK();
        if(ok){
            present({message:response.message,duration:5000,position:"bottom",cssClass:"ion-text-center"})
        }else{
            present("Sorry, An error occured", 5000)
        }
    }
    return ( <IonPage>
        <FeatHeader
            defaultHref='/add-ons'
            showInfoButton={false}
            title='Download App'/>
        <IonContent className="ion-padding">
                <div className="ion-text-center">
                    <IonButton type="submit" onClick={buttonhandler}>Download Build for Play Store</IonButton>
                </div>
        </IonContent>
    </IonPage>
)
}

export default GooglePlayStore