import { IonAccordion, IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonLoading, IonPage, IonReorder, IonReorderGroup, IonRow, IonText, ItemReorderEventDetail, useIonToast } from "@ionic/react";
import useFetch from '../../utils/useFetch';
import { trashOutline as trashIcon, createOutline as updateIcon } from "ionicons/icons";
import useAPI from "../../utils/api";
import { useState } from "react";
import { useHistory } from "react-router";
import Header from '../../components/dashboard/Header'


const Menu = () => {
    let [updateMenu, setUpdateMenu] = useState(false);
    let menus = useFetch("getMenus", updateMenu);
    const history = useHistory();
    const API = useAPI();
    const [present] = useIonToast();
    const deleteMenu = async (id) => {
        try {
            const [ok, response] = await API.deleteMenu(id);
            if (ok) {
                setUpdateMenu(!updateMenu)
            } else {
                present(response.message, 3000)
            }
        }
        catch (err) {
            present(err.message, 3000)
        }
    }
    const doLinksReorder = async (event: CustomEvent<ItemReorderEventDetail>, value: any) => {
        menus.data.data.map(async (menu) => {
            if (menu.id === value.id) {
                const len = menu.links.length;
                let itemToMove = menu.links.splice(event.detail.from, 1)[0];
                menu.links.splice(event.detail.to, 0, itemToMove);
                if (len > 1) {
                    menu.links.map((link, index) => {
                        link.priority = len - index;
                    })
                }
                try {
                    const [ok, response] = await API.updateMenu(menu);
                    if (ok) {
                        // console.log(menu,"menu after api")
                    } else {
                        present(response.message, 3000)
                    }
                }
                catch (err) {
                    present(err.message, 3000)
                }
            }
        })
        event.detail.complete();
    }
    return (
        <IonPage>
            <Header name="Website Menu" />
            <IonContent className="ion-padding">

                <IonText className="ion-text-center ion-margin-bottom">
                    <h5 className="ion-no-margin"><strong>Manage Menu for your website</strong></h5>
                    <p>Using Menu you can manage menu for your customers</p>
                </IonText>
                {
                    menus.loading === false && menus && menus?.data.data ?
                        <>
                            {menus?.data?.data.map(menu => {
                                return <IonCard key={menu.id}>
                                    <IonCardHeader >
                                        <IonCardTitle>
                                            <IonRow>
                                                        <IonCol size="9">
                                                            <IonText>
                                                                {menu.name}
                                                            </IonText>
                                                        </IonCol>
                                                        <IonCol size="3" className="ion-text-right">
                                                                <IonIcon className="pointer-cursor" icon={updateIcon} onClick={() => { history.push(`/content/menus/web-menu/update/${menu.id}`) }}></IonIcon>
                                                                <IonIcon className="pointer-cursor" 
                                                                onClick={() => { deleteMenu(menu.id) }}
                                                                    icon={trashIcon}
                                                                ></IonIcon>
                                                        </IonCol>
                                                        </IonRow>
                                                        {menu.position != null ? <IonRow>
                                                            <IonCol size="9">
                                                            <IonText style={{fontSize: "12px", textTransform: "capitalize"}}>{`${menu.position.split('_')[0]} - ${menu.position.split('_').slice(1).join(" ").trim()}`}</IonText>
                                                            </IonCol>
                                                        </IonRow> : null}
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <hr style={{ borderTop: '2px solid #ccc' }} />
                                    <IonCardContent>
                                        <IonReorderGroup disabled={false} onIonItemReorder={(event) => { doLinksReorder(event, menu) }} >
                                            {menu.links.length !== 0 && menu.links.map((link) =>
                                                <div key={menu.id}>
                                                    <IonItem>
                                                        <IonGrid style={{ width: '100%' }}>
                                                            <IonRow>
                                                                <IonCol size="4" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{link.text}</IonCol>
                                                                <IonCol size="7" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                                    <IonText>{link.link}</IonText>
                                                                </IonCol>
                                                                <IonCol size="1">
                                                                    <IonReorder />
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                    </IonItem>
                                                </div>
                                            )}
                                        </IonReorderGroup>
                                    </IonCardContent>
                                </IonCard>
                            })}
                        </> : <IonLoading isOpen={true}></IonLoading>
                }
                <div className="ion-text-center">
                    <IonButton shape="round" routerLink="/content/menus/web-menu/create">Add Menu</IonButton>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Menu