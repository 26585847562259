import {
  IonContent,
  IonPage
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Title from '../components/dashboard/Header';
import HomeScreen from '../components/dashboard/HomeScreen';
import TipBanners from '../components/dashboard/TipBanners';
import './Dashboard.css';

const Dashboard: React.FC = () => {
  const [count, setCount] = useState<any>();
  const { currentUser } = useSelector((state : any)=> state.currentUser);
  
  useEffect(() => {
    if(sessionStorage.getItem('reloadCount')){
      setCount(sessionStorage.getItem('reloadCount'));
    }else{
      sessionStorage.setItem('reloadCount', String(1));
      setCount('1');
    }
  }, [])
  
  useEffect(() => {
    if(count === '1') {
      sessionStorage.setItem('reloadCount', String(10));
      window.location.reload();
    }
  }, [count]);
    return(
      <IonPage className="">
      <div className="ion-page" id="main-content">
        <Title name={currentUser && currentUser.data && currentUser.data && currentUser.data.first_name ? `Hello, ${currentUser.data.first_name}` : ""}/>
        <IonContent className="main-container-dashboard">
          <HomeScreen/>
          <div className="ion-padding">
          <TipBanners/>
          </div>
        </IonContent>
        
      </div>
    </IonPage>
  
    )
}

export default Dashboard