import { IonBackButton, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonRow, IonText, IonTextarea, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import {
    arrowBack as arrowBackIcon,
    checkmarkCircleOutline as checkmarkCircleOutlineIcon
} from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useHistory, useLocation } from 'react-router';
import useAPI from '../../utils/api';
import { usePagesForUpdate, useUser } from '../../utils/contexts';
import FeatHeader from '../../components/dashboard/FeatHeader';


const modules = {
    toolbar:[
        [{header: "1"}, {header: "2"}, {header: [3,4,5,6]}],
        ["bold", "italic", "underline", "sttrike", "blockquote"],
        [{list:"ordered"}, {list:"bullet"}],
        ["link","image"],
        ["clean"],
    ]
}

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image"
]

const CreatePage = () => {
    const location:any = useLocation();
    const [loading, setLoading] = useState(false);
    const pageUpdateData: any = usePagesForUpdate(); 
    const [showAvailableText, setShowAvailableText] = useState(false);
    const [pageData, setPageData] = useState<any>({
        slug: "",
        name: "",
        description: "",
        content: "",
        status: "INACTIVE"
    });

    //validation
    const [pageValidate, setPageValidate] = useState({
        slug: "",
        name: "",
        description: "",
        content: "",
    })
    const currentUser: any = useUser();
    const API = useAPI();
    const history = useHistory();
    const [present] = useIonToast();

    const quillRef = useRef();

    const handleQuillChange = (content, _, __, editor) => {
        quillRef.current = editor;
        setPageData((prevData) => ({ ...prevData, content }));
    };

    
    useEffect(() => {
        if(location?.state){
            setPageData(location?.state);
            }
        }, [location?.state])

    const pageValidations = () =>{
        setPageValidate({
            slug: !pageValidate.slug && (pageData.slug ? "" : "Link is required."),
            name: pageData.name ? "" : "Title is required.",
            description: pageData.description ? "" : "Description is required.",
            content: pageData.content ? "" : "Content is required.",
        })
        return pageData.slug && pageData.name && pageData.description && pageData.content
    }

    const pageDispatcher = async () => {
        const res = pageValidations();
        if(res && !pageValidate.slug){
            setLoading(true);
            const [ok, response] = location?.state ? 
                await API.updatePage({
                    store_id: currentUser?.store.subdomain,
                    slug: pageData.slug,
                    name: pageData.name,
                    description: pageData.description,
                    content: pageData.content,
                    status: pageData.status,
                    id: pageData.id,
                })
                : await API.createPage({
                    store_id: currentUser?.store.subdomain,
                    slug: pageData.slug,
                    name: pageData.name,
                    description: pageData.description,
                    content: pageData.content,
                    status: pageData.status
                });
            if(ok){
                setLoading(false);
                present("Data saved successfully!", 1000);
                pageUpdateData.setUpdate(!pageUpdateData.update);
                setShowAvailableText(false);
                                setPageData({
                    slug: "",
                    name: "",
                    description: "",
                    content: "",
                    status: "INACTIVE"
                });
                setPageValidate({
                    slug: "",
                    name: "",
                    description: "",
                    content: "",
                });
                history.goBack();
            }
            setLoading(false);
        }
    }

    const changeLink = async(e, fromNameInput)=>{
        if(e.detail.value){
            const value = e.detail.value;
            const valueWithoutSpace = await value.replace(/\s+/g, '-');
            const stringWithoutSpecial = await valueWithoutSpace.replace(/[^a-zA-Z -]/g, "");
            var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
            let finalString = await stringWithoutSpecial.replace(regex, "");
            if(finalString.endsWith('-')){
                finalString = finalString.slice(0, -1)
            }
            if(fromNameInput) {
                                setPageData({...pageData, slug: finalString.replace( /-{2,}/g, "-").toLowerCase(), name: value});
            } else{
                                setPageData({...pageData, slug: finalString.replace( /-{2,}/g, "-").toLowerCase()});
            }
        } else {
            setPageData({...pageData, slug: "", name: ""});
        }
    }

    const checkURLAvailability = async()=>{
        if(pageData.slug){
            if(location?.state && location.state?.slug === pageData.slug){
                setShowAvailableText(false);
                setPageValidate({...pageValidate, slug: ""})
                return;
            }
            setLoading(true);
            const [ok, response] = await API.slugAvailable(pageData.slug);
            if(ok){
                if(response.slug_availabe){
                    setShowAvailableText(true);
                    setPageValidate({...pageValidate, slug: "", name: ""})
                }else{
                    setShowAvailableText(false);
                    setPageValidate({...pageValidate, slug: "This URL is already used. Please choose a different URL."});
                }
            }
            setLoading(false);
        }else{
            setShowAvailableText(false);
        }
    }

    return (
        <IonPage>
            <FeatHeader title={location.state ? 'Update Page' : 'Create Page'} showInfoButton={ false} defaultHref='/pages'/>
            <IonContent className="ion-padding">
                <IonItem>
                    <IonLabel position="stacked">Page Title</IonLabel>
                    <IonInput type="text" value={pageData.name} placeholder="Enter page title" onIonChange={e => changeLink(e, true)} onIonBlur={checkURLAvailability}/>
                </IonItem>
                <IonText color="danger" className={`${pageValidate.name ? "" : "ion-hide"}`}>
                    <h5 className='error-text'>{pageValidate.name}</h5>
                </IonText>
                <br/>
                <IonItem>
                    <IonLabel position="stacked">Page Link</IonLabel>
                    <IonInput type="text" placeholder="Enter page link" value={pageData.slug} onIonChange={e=> changeLink(e, false)} onIonBlur={checkURLAvailability}/>
                </IonItem>
                <IonText color="success" className={`${showAvailableText ? "" : "ion-hide"}`}>
                    <h5 className='error-text'><IonIcon icon={checkmarkCircleOutlineIcon}/> This link is available.</h5>
                </IonText>
                <IonText color="danger" className={`${pageValidate.slug ? "" : "ion-hide"}`}>
                    <h5 className='error-text'>{pageValidate.slug}</h5>
                </IonText>
                <IonText><h6>Page URL: <IonText color='primary'>https://{ currentUser?.store && (currentUser.store.enable_domain == 1 && currentUser.store.domain ? currentUser.store.domain : currentUser.store.subdomain)}/{pageData.slug}</IonText></h6></IonText>
                <IonItem lines='none'>
                    <IonLabel position="stacked">Page Content</IonLabel>
                </IonItem>
                <ReactQuill 
                placeholder="Write page content here."
                modules={modules}
                formats={formats}
                value={pageData.content}
                onChange={handleQuillChange}
                />
                <IonText color="danger" className={`${pageValidate.content ? "" : "ion-hide"}`}>
                    <h5 className='error-text'>{pageValidate.content}</h5>
                </IonText>
                <br/>
                <IonItem>
                    <IonLabel position="stacked">Page Description</IonLabel>
                    <IonTextarea placeholder="Enter page description" value={pageData.description} onIonChange={e=> setPageData({...pageData, description: e.detail.value})}/>
                </IonItem>
                <IonText color="danger" className={`${pageValidate.description ? "" : "ion-hide"}`}>
                    <h5 className='error-text'>{pageValidate.description}</h5>
                </IonText>
                <IonGrid>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="3">
                            <IonText>Status:</IonText>
                        </IonCol>
                        <IonCol>
                            <IonItem lines="none">
                                <IonLabel>Published</IonLabel>
                                <IonCheckbox slot="start" checked={pageData.status === 'ACTIVE'} onIonChange={e=> setPageData({...pageData, status: e.detail.checked ? "ACTIVE" : "INACTIVE"})} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="ion-text-center">
                    <IonButton shape="round" onClick={e=> pageDispatcher()}>{location?.state ? "Update Page" : "Create Page"}</IonButton>
                </div>
                <IonLoading isOpen={loading} message="Please wait"></IonLoading>
            </IonContent>
        </IonPage>
    )
}

export default CreatePage
