import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import Header from "../../components/dashboard/Header"

const HubspotContactForm = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/shell.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if ((window as any).hbspt) {
                // @TS-ignore
                (window as any).hbspt.forms.create({
                    region: "na1",
                    portalId: "8742678",
                    formId: "c4a3204b-d41b-4afc-8a36-e777c6dc9a7a",
                    target: '#hubspotForm'
                })
            }
        });
    }, []);

    return (
        <IonPage>
            <Header name="Support" />
            <IonContent>
                <IonGrid className="ion-padding ion-margin">
                    <IonRow>
                        <IonCol>
                            <div id="hubspotForm"></div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default HubspotContactForm;