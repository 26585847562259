import { useState } from 'react';
import {
    Route
} from "react-router-dom";
import Invoice from '../../pages/Invoice';
import { getAccessPermission } from '../../services/auth/userLocalStorage';
import { OrderStatusContext } from '../../utils/contexts';
import CreateShiprocketOrder from '../shiprocket/CreateShiprocketOrder';
import AbandonedOrders from './AbandonedOrders';
import AddOrders from './AddOrders';
import OrderDetails from './OrderDetails';
import Orders from './Orders';

const OrdersRoutes = () => {
    const [filterData, setFilterData] = useState("ALL");
    const [filterOrderData, setFilterOrderData] = useState({});
    const [pageCountData, setPageCountData] = useState({
		ALL: 1,
		PENDING: 1
	});
    const [infiniteScrollData, setInfiniteScrollData] = useState({
		ALL: false,
		PENDING: false,
	});
    const permissions = getAccessPermission();
    const [update, setUpdate] = useState(false);


    return (
            <OrderStatusContext.Provider value={{filterData, setFilterData, filterOrderData, setFilterOrderData, pageCountData, setPageCountData, infiniteScrollData, setInfiniteScrollData, update, setUpdate}}>
                                <Route path="/orders/orderdetails/invoice/:id" exact={true}>
                        <Invoice />
                    </Route>
                    {permissions?.length === 0 && <><Route path="/orders/abandoned" exact={true}>
                        <AbandonedOrders />
                    </Route>
                    </>
                    }
                    
                    <Route path="/orders/add" component={AddOrders} exact={true} />
                    
                    <Route path="/orders/createshiprocketorder" exact={true}>
                        <CreateShiprocketOrder />
                    </Route>
                    <Route path="/orders/orderdetails" exact={true}>
                        <OrderDetails />
                    </Route>
                    <Route path="/orders" exact={true}><Orders /></Route>
                
            </OrderStatusContext.Provider>
    )
}

export default OrdersRoutes
