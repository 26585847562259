function Auth () {
  return {
    setToken: (tokenData) =>  {
      return Promise.resolve().then(() => {
          localStorage.setItem("IN_TOKEN_DATA", tokenData)
      });
    },
    isAuthenticated: () => !!localStorage.getItem("IN_TOKEN_DATA"),
    getToken: () => {
      const items = localStorage.getItem("IN_TOKEN_DATA");
      return JSON.parse(items)?.token
    },
    logout: async () => {
      localStorage.removeItem("IN_TOKEN_DATA");
    }
  }
}

export const auth = Auth();
