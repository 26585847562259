import { IonBackButton, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonRange, IonRow, IonText, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import {
    arrowBack as arrowBackIcon
} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import useAPI from "../../utils/api";
import useFetch from "../../utils/useFetch";
import { numericValidation, phoneValidation } from '../../utils/validations';
import FeatHeader from '../../components/dashboard/FeatHeader';


function formatAMPM(dateString) {
    const date = new Date(dateString);
    let hours = date.getHours();
    let minutes: any = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
        hours = '00';
    }
    if (modifier === 'PM') {
        hours = (parseInt(hours, 10) + 12).toString();
    }

    return [hours.padStart(2, "0"), minutes];
}

const ServiceDetails = () => {
    const [whatsappNumber, setWhatsappNumber] = useState<any>();
    const [phoneNumber, setPhoneNumber] = useState<any>();
    const [minimumOrder, setMinimumOrder] = useState<any>();
    const [deliverWithin, setDeliverWithin] = useState<any>(0);
    const [openTime, setOpenTime] = useState<any>();
    const [closeTime, setCloseTime] = useState<any>();

    const [validation, setValidation] = useState({
        whatsapp: "",
        phone: "",
        minOrder: ""
    })
    const [is24HoursOpen, setIs24HoursOpen] = useState<any>(false);
    const { data, status, loading } = useFetch("serviceDetail");
    const [present] = useIonToast();
    const API = useAPI();

    useEffect(() => {
        if (!loading && status === "fetched" && data?.data) {
            let UTCTime = new Date();
            let ISOTimeString = UTCTime.toISOString()
            
            // open time: default 10:00 AM
            let openTimeArray = convertTime12to24(data.data.open_time === "12:NaN AM" || !data.data.open_time ? "10:00 AM" : data.data.open_time);

            let customizedOpenISO = ISOTimeString.substring(0, ISOTimeString.indexOf("T")) + "T" + openTimeArray[0] + ":" + openTimeArray[1]+ ISOTimeString.substring(ISOTimeString.lastIndexOf(":"), ISOTimeString.lastIndexOf(".")) + ".000+05:30";
            setOpenTime(customizedOpenISO)
            
            // close time: default 10:00 PM
            let closeTimeArray = convertTime12to24(data.data.close_time === "12:NaN AM" || !data.data.close_time ? "10:00 PM" : data.data.close_time);

            let customizedCloseISO = ISOTimeString.substring(0, ISOTimeString.indexOf("T")) + "T" + closeTimeArray[0] + ":" + closeTimeArray[1]+ ISOTimeString.substring(ISOTimeString.lastIndexOf(":"), ISOTimeString.lastIndexOf(".")) + ".000+05:30";
            setCloseTime(customizedCloseISO)
        

            // set states
            setWhatsappNumber(data.data.whatsapp_phone)
            setMinimumOrder(data.data.min_order)
            setDeliverWithin(data.data.delivery_within_kms);
            if (data.data.phone !== "null") {
                setPhoneNumber(data.data.phone || "")
            } else {
                setPhoneNumber("")
            }
            if (data.data.open_time === '12:00 AM' && data.data.close_time === '12:00 AM') {
                setIs24HoursOpen(true)
            }
        }
    }, [data, status, loading]);

    const validate = () => {
        let res = true;
        let temp = { ...validation };
      
        if (!phoneValidation(whatsappNumber) || whatsappNumber.length !== 10) {
          temp.whatsapp = "Whatsapp number is not valid";
          res = false;
        } else {
          temp.whatsapp = "";
        }
      
        if (!phoneValidation(phoneNumber)) {
          temp.phone = "Phone number is not valid";
          res = false;
        } else {
          temp.phone = "";
        }
      
        if (!numericValidation(minimumOrder)) {
          temp.minOrder = "Minimum order must contain a numeric value.";
          res = false;
        } else {
          temp.minOrder = "";
        }
      
        setValidation(temp);
        return res;
      };
      
    async function saveServiceDetails() {
        if (validate()) {
            let customizedData = {
                whatsapp_phone: `${whatsappNumber}`,
                min_order: `${minimumOrder}`,
                delivery_within_kms: deliverWithin,
                phone: `${phoneNumber}`,
                open_time: is24HoursOpen ? "12:00 AM" : formatAMPM(openTime),
                close_time: is24HoursOpen ? "12:00 AM" : formatAMPM(closeTime),
            }
            const [ok, response] = await API.createServiceDetail(customizedData);
            if (ok) {
                present("Data saved successfully!", 1000);
            }
        }
    }

    return (
        <IonPage>
            
            <FeatHeader
                title='Service Details'
                showInfoButton={false}
                defaultHref="/settings"
            />
            <IonContent className="ion-padding">
                <IonGrid>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Whatsapp Number</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput placeholder="Enter whatsapp number" type="tel" value={whatsappNumber} onIonChange={e => setWhatsappNumber(e.detail.value)} />
                            </IonItem>
                            <IonText className={`${validation.whatsapp ? "" : "ion-hide"}`} color="danger">
                                <h5 className="ion-margin-start" style={{ fontSize: 12 + 'px' }}>{validation.whatsapp}</h5>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Phone Number</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput placeholder="Enter contact number" value={phoneNumber} type="tel" onIonChange={e => setPhoneNumber(e.detail.value)} />
                            </IonItem>
                            <IonText className={`${validation.phone ? "" : "ion-hide"}`} color="danger">
                                <h5 className="ion-margin-start" style={{ fontSize: 12 + 'px' }}>{validation.phone}</h5>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Minimum Order</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput placeholder="Enter minimum order quantity" type="tel" value={minimumOrder} onIonChange={e => setMinimumOrder(e.detail.value)} />
                            </IonItem>
                            <IonText className={`${validation.minOrder ? "" : "ion-hide"}`} color="danger">
                                <h5 className="ion-margin-start" style={{ fontSize: 12 + 'px' }}>{validation.minOrder}</h5>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center ion-margin-top">
                        <IonCol size="4">
                            <IonLabel>Store Timings</IonLabel>
                        </IonCol>
                        <IonCol>
                            {!is24HoursOpen &&
                                <IonItem lines="none">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol className="ion-text-left time" style={{ paddingLeft: 0, marginLeft: 0 }}>
                                                <IonText><h3 style={{ fontSize: "12px", paddingBottom: "4px", paddingLeft: "4px" }}
                                                    className="ion-no-margin">Opening Time</h3></IonText>
                                                <IonDatetimeButton datetime="opentime"></IonDatetimeButton>
                                                <IonModal keepContentsMounted={true}>
                                                    <IonDatetime id="opentime" minuteValues="0,30" value={openTime} presentation="time" hourCycle="h12" onIonChange={e => setOpenTime(e.detail.value)}></IonDatetime>
                                                </IonModal>
                                            </IonCol>
                                            <IonCol className="ion-text-left time" style={{ paddingLeft: 0, marginLeft: 0 }}>
                                                <IonText><h3 style={{ fontSize: "12px", paddingBottom: "4px", paddingLeft: "4px" }}
                                                    className="ion-no-margin">Closing Time</h3></IonText>
                                                <IonDatetimeButton datetime="closetime"></IonDatetimeButton>
                                                <IonModal keepContentsMounted={true}>
                                                    <IonDatetime id="closetime" minuteValues="0,30" value={closeTime} presentation="time" hourCycle="h12" onIonChange={e => setCloseTime(e.detail.value)}></IonDatetime>
                                                </IonModal>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>}
                            <IonItem lines="none">
                                <IonLabel>24x7 Open</IonLabel>
                                <IonCheckbox slot="start" checked={is24HoursOpen} onIonChange={e => setIs24HoursOpen(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Deliver Within</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem lines="none" className="ion-no-padding">
                                <IonRange pin={true} value={deliverWithin} onIonChange={e => setDeliverWithin(e.detail.value as number)} />
                                <IonText>{`${deliverWithin == 0 || deliverWithin === null ? "unlimited" : `${deliverWithin} kms`}`}</IonText>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="ion-text-center">
                    <IonButton shape="round" type="submit" onClick={saveServiceDetails}>Save</IonButton>
                </div>
                <IonLoading isOpen={loading} message="Please Wait"></IonLoading>
            </IonContent>
        </IonPage>
    )
}

export default ServiceDetails
