import React from 'react';
import {
    IonContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonIcon,
} from '@ionic/react';
import { arrowBackOutline as Back } from 'ionicons/icons';
import { useHistory } from 'react-router';

const TermsOfService: React.FC = () => {
    const history = useHistory()
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonIcon icon={Back} onClick={(e) => { history.goBack() }} style={{ fontSize: "25px", cursor: "pointer", padding: "5px" }}></IonIcon>
                    </IonButtons>
                    <IonTitle>Terms of service </IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <div style={{ width: "90%", margin: "auto" }}>
                    <h2>Terms of service</h2>
                    <p>By signing up for a Intelikart APP (as defined in Section 1) or by using any Intelikart Services (as defined below), you are agreeing to be bound by the following terms and conditions (the “Terms of Service”).</p>
                    <p>As used in these Terms of Service, “we”, “us” and “Intelikart” means the applicable Intelikart Contracting Party (as defined in Section 4 below).</p>
                    <p>The services offered by Intelikart under the Terms of Service include various products and services to help you sell goods and services to buyers, whether online (“Online Services”), in person (“POS Services”), or both. Any such services offered by Intelikart are referred to in these Terms of Services as the “Services”. Any new features or tools which are added to the current Services shall be also subject to the Terms of Service. You can review the current version of the Terms of Service at any time. Intelikart reserves the right to update and change the Terms of Service by posting updates and changes to the Intelikart website or APP. You are advised to check the Terms of Service from time to time for any updates or changes that may impact you. and if you do not accept such amendments, you must cease using the Services. This legal document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                    <p>This legal document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008 that require publishing the Terms of Services and practices for usage of Interlikart mobile app and its integrated systems.</p>
                    <p>These terms of services apply to Store Owner which use Interlikart mobile app and its integrated system for its managers and back end system.(Herein after referred to as “app”)vUsers in this context shall include Store owners, managers and staff members of Interlikart partnered retailers who use Interlikart app or website for online ordering, billing and payment of their business transactions.</p>
                    <p>By using this app, clicking the “I accept” button or completing the registration process, you agree and acknowledge that you have reviewed these terms of service, given consent and accepted this agreement.</p>
                    <p>References in these Terms to “we” or “us” or “app” or “company” are references to Interlikart and “you” as user/users of Interlikart App and its integrated systems.</p>
                    <b>1. Purpose of app</b>
                    <p>A complete & innovative Mobile Commerce Platform which creates Android apps for your shop. It helps facilitate your customers to submit order from mobile phone and let them get the order at their door step at their convenient time.</p>
                    <p>We understand business people and their time limitation that’s why, Intelikart is launched as a hosted and cloud base solution so no worry about technology, IT and maintenance. Intelikart provides services like Create and Personalize Apps, Manage Catalog and Inventory, Do Promotion – SMS and Push Notifications, Accept Orders and Payment, Manage Delivery.</p>
                    <b>2. User Agreement</b>
                    <p>This User Agreement contains rules and regulations, policies and terms and conditions and agreement applicable to any person who may access or use Intelikart, including any sub domains, Web Pages or extension of Intelikart and you hereby agree and acknowledge to be bound by the terms of this User Agreement</p>
                    <p>The use of Intelikart and the services provided are subject to the rules and regulations, policies, notices, terms and conditions set forth in this User Agreement. For the purposes of this User Agreement, ‘User’ and wherever the context may require ‘You’ (or any variation thereof), shall mean any natural or legal person owning a retail business and who has agreed to become a user of the App by accessing or browsing the App and has registered as a user of the App by submitting identification information/ registration details using the computer system of this App and Accepting the electronic record of the User Agreement.</p>
                    <p>Amendment: The Company reserves the right to change this App and/or alter the terms and conditions of this User Agreement at any time and retains the right to deny access to anyone who the Company believes has violated the provisions of this User Agreement. You are advised that any amendment to the User Agreement or rules and policies incorporated herein by reference will only be notified on the App on publicly accessible links and you agree by accessing, browsing or using this app that this constitutes sufficient notice for all purposes against you. The revised version/ terms shall be effective from the time that the Company posts the same on the App. [In the event, that the User Agreement includes a substantial change, A breach or violation of any term in the Terms of Service, as determined in the sole discretion of Intelikart may result in an immediate termination of your Services. You are responsible for your Account and any Materials you upload to the Intelikart Service. Remember that if you violate these terms we may cancel your service.</p>
                    <p>If we need to reach you, we will send you an email.</p>
                    <p>For the purposes of this User Agreement, ‘substantial change’ means a change to the terms of this User Agreement that reduces your rights or increases your responsibilities.</p>
                    <p>By impliedly or expressly accepting this User Agreement, You also accept and agree to be bound by Intelikart Rules and Policies as provided from time to time.</p>
                    <b>3. Eligibility</b>
                    <p>This App may only be used or accessed by such persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are ‘incompetent to contract’ within the meaning of the Indian Contract Act, 1872 including minors, persons of unsound mind, and insolvents are not eligible to use the App. A minor is not allowed to access or register as a user on the App.</p>
                    <p>Here, only business entity which is in the Store or Shop shall be allowed to register for this app. By accepting the User Agreement, you represent that such entity has sufficient authority under applicable law to enter into the User Agreement and you are duly authorized by the business entity to accept this User Agreement and you have the authority to bind that business entity to this User Agreement.</p>
                    <b>4. Registration and Communication</b>
                    <p>Registration: You are solely responsible for maintaining secrecy and confidentiality of your username and password. You hereby acknowledge and accept that the app will grant access to any person who has obtained your user name and password in the same manner as it would have granted access to you and you are responsible for all activities conducted under your account. The Company, its employees or associates shall never be responsible in any manner for any kind of losses whatsoever occurring from such breach of security.</p>
                    <p>
                        You agree that your sole purpose of registering or using the App is to subscribe for online Store management services which are specifically notified by the Company on the App from time to time and you shall not use this App for any other purpose including for subscription other than as mentioned above or products that are not allowed under applicable law in any manner.</p>
                    <p>You agree to provide true, accurate and complete information while registering or for any other purpose when prompted to do so on the App. You are prohibited from misrepresenting your identity and agree not to represent yourself as another User or login/ register using the identity of any other person. You are responsible to maintain and promptly update the information provided while registering or for any other purpose on the App to ensure that the information provided by you is true, accurate, current and complete at all times. If you provide any information that is untrue, inaccurate, not current or incomplete or the Company has reasonable grounds to deduce that such information is untrue, inaccurate, not current or incomplete, or not in accordance with this User Agreement, the Company reserves the right to indefinitely suspend or terminate or block your use or access to the App in any manner whatsoever.</p>
                    <p>Electronic Communication: You agree to keep yourself updated with all data, information and communication pertaining to you made available on the App by the Company. You further agree that your use of the App or provision of any data or information including any correspondence (by email or otherwise) to or by the Company is through electronic records and you give consent to receive communication from the Company via electronic records which will be deemed adequate service of notice/ electronic record.</p>
                    <b>5. Technical requirements</b>
                    <p>User may only register with Intelikart App if you have the necessary technical requirements at your disposal. Technical requirements are PC/Tablet and broadband internet connection & android or ios smart phone having 3G or higher internet connection. The availability and the proper functioning of these technical requirements are the sole responsibility of the User.</p>
                    <p>The instructions “How to use” Intelikart app will be available on the homepage of the app. All users are to ensure that they read and adhere to these instructions for accessing Intelikart. The user agrees that Intelikart shall not be held responsible in event of any occurrence due to the non adherence of these instructions which prevents the user from using the app as designed or due to any reasons over which Intelikart have no control.</p>
                    <p>You also give permission to the Company to store details and records of your usage of the App indefinitely. However, this does not constitute any obligation on the part of the Company or the App to do so.</p>
                    <b>6. Payment Policy</b>
                    <p>You are responsible for paying all charges/ fees (as per Annexure-A) associated with the use of the App and shall be liable to pay any and all applicable taxes, charges, cesses etc. which may be levied. In case of any non- payment, the Company reserves the right to issue a warning or temporarily/ indefinitely suspend or terminate your membership with the App and disallow access to the App.</p>
                    <p>The Company reserves the right to introduce new services or modify/upgrade the existing services provided on the App. Additionally, the Company at its sole discretion may introduce new fees for the new or modified/upgraded services provided. Changes to the User Agreement or any of the rules and policies of the Company shall be posted on the App and such changes shall automatically become effective immediately after they are posted on the App.</p>
                    <p>Payments must be made using the any of the payment softwares or Gateway of various payment gateway service providers in accordance with the terms and conditions / privacy policy of payment gateway. We facilitate payment processing through PCI compliant debit card/credit card/net banking/mobile banking/ mobile wallet/gift cards payment gateways. The user agrees and acknowledges that company shall not be held responsible in event of any delays, errors and frauds in processing of payment by entities outside the control of company.</p>
                    <p>For the purposes of selected services on the App, you agree and undertake not to receive payments in any manner other than as provided, without the prior consent of the Company. You acknowledge and accept that you have specifically authorized the Company to collect, process, facilitate and remit payments and/ or the Transaction Price from your customers by any of the prescribed methods of payment. You further authorize, agree and acknowledge that company may deduct its handling fees (if any) and other relevant taxes from the total amount received from the customers.</p>
                    <p>Company uses Payment systems as may be authorized by the Reserve Bank of India for collection, refund and remittance, as the case may be.</p>
                    <p>The Company neither makes any representations nor makes any warranties regarding the amount of time needed to complete processing, including delays in the online payment system and nor shall the Company be liable for any actual or consequential damages arising from any claim of delay or any payment process related errors or delays.</p>
                    <b>7. Services Activation and Delivery:</b>
                    <p>Activation e-mail/sms – After the registration user will get an notification for acknowledgement.</p>
                    <p>Services? – Initial Setup is free for the Store Owner for a Limited time ( to be Defined by intelikart Team ), User will start receiving all services according to the chosen Plan. However, you agree and acknowledge that company shall never be held responsible for any delays by any app store platform including but not limited google play store and app store while making your mobile store live on their platform.</p>
                    <p>App access is not possible during maintenance periods. Users may be informed about maintenance periods wherever possible.</p>
                    <p>The user agrees and acknowledges that company shall never be held responsible for any problems that may arise during the course of the subscription due to the internet malfunction/connectivity issues and when the app is closed for maintenance / updating with or without prior notice.</p>
                    <b>8. User account and security</b>
                    <p>To register for the service, you must complete the registration process by providing us with current, complete and accurate information as prompted by the registration form, including your e-mail address and mobile number. You will protect your account and take full responsibility for your own, and third party, use of your accounts. You are solely responsible for any and all activities that occur under your account. You must notify us immediately upon learning of any unauthorized use of your account or any other breach of security.</p>
                    <b>9. User Obligations</b>
                    <p> You agree and undertake not to host, display, upload, modify, publish, transmit, update or share any information or list any information or item that:</p>
                    <ul>
                        <li>belongs to another person and to which you do not have any right to;</li>
                        <li>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing harm minors in any way;</li>
                        <li>infringes any patent, trademark, copyright or other proprietary rights or third party’s trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen items;</li>
                        <li>violates any law for the time being in force;</li>
                        <li>deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
                        <li>impersonate another person or use an anonymous proxy;</li>
                        <li>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;</li>
                        <li> threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation;</li>
                        <li>shall not be false, inaccurate or misleading;</li>
                        <li>shall not, directly or indirectly, offer, attempt to offer, trade or attempt to trade in the dealing which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force;</li>
                        <li>may give rise to liability on part of the App or the Company or cause any hindrance (in whole or in part)</li>
                    </ul>
                    <p>In case of any violation of the above provisions, the Company has the right to immediately terminate the access or usage rights of the user to the App without any notice and any such violate information that is displayed or submitted on the App can be removed immediately and completely.</p>
                    <p>You shall be responsible for keeping backup versions of the information and data provided by you. You hereby agree that you will not expect the App to restore or keep back up of your information and data and not hold the App or the Company accountable for any loss of data in any circumstances.</p>
                    <p>You will also refrain from accessing information or databases in an unauthorized manner from the App or servers where information or databases are kept.</p>
                    <p>You shall not attempt to or circumvent or manipulate any of the obligations conferred on you by this User Agreement. If such attempt is discovered, it will constitute sufficient ground for termination of access to the App and also for taking appropriate legal action.</p>
                    <p>If you choose to provide feedback on the App which is visible to other users, you shall exercise due care while making comments and not make any comments that are not factual in nature and shall not post defamatory or illegal or offensive/ obscene contents.</p>
                    <p>You undertake not to disclose or distribute any other User’s Information to a third party, or use the Information for any unauthorized purpose including for the purposes of marketing unless you have obtained the User’s express consent to do so.</p>
                    <p>You shall not place any advertisements on the App in any manner. Further, you shall not use the App to promote any other person’s business or interests on the App unless permitted by the Company in writing.</p>
                    <b>10. Obligations under relevant sections of Indian Cyber Laws</b>
                    <p>You agree and acknowledge that</p>
                    <ul>
                        <li>You shall not use this App for any purpose that is unlawful and illegal.</li>
                        <li>You shall not try to penetrate the app and will not implant any virus, Trojan, worm, spyware, malware, spam, spim, scareware, bots, logic bomb, time bomb, key-loggers and any other automated tool like DOS or DDOS attack tool (Denial of Service attack) which may lead to computer related offences on our app.</li>
                        <li>You shall not use this app for publishing or promoting or transmitting pornographic data or child pornographic details.</li>
                        <li> You shall not carry out any web jacking attacks on this app.</li>
                        <li>You understand, agree and acknowledge that you may be held responsible for above criminal, fraudulent, dishonest and injurious activities under criminal offences’ sections 65, 66, ,66B,66C,66D,66E,66F,67,67A,67B,84B and 84C of Indian Information Technology Act,2000 including any amendments therein based on your IP address and other relevant details.</li>
                        <li>You further agree and acknowledge that you may be held responsible under sections 43, and 45 of Indian Information Technology Act, 2000 including any amendments therein for compensating damages due to business losses such as financial losses, outage losses, opportunity losses, reputational losses and other overheads like repairing, fixing and maintenance in case of any cyber contravention on your part.</li>
                    </ul>
                    <b>11. Privacy of Data</b>
                    <p>All details of Intelikart will be stored securely on the web server. It is compulsory to refer and agree with our privacy policy and security practices.</p>
                    <b>12. Third Party Links</b>
                    <p>We may choose from time to time to provide links to various third-party apps from the App. This may include links to sites owned by our associated companies. These third-party links are provided for your convenience only and are accessed at your own risk. You agree that we have no responsibility or liability for any independent policies or actions of these third-party sites and are not responsible in any way for the privacy practices, customer service practices, content or availability of any such apps. You also agree and acknowledge that we shall not be responsible in any way for any damage or loss caused in relation to the content, goods or services available through such apps.</p>
                    <b>13. Content</b>
                    <p>Information, reports, videos, blogs, newsletters and every other feature (Collectively known as “online contents”) may be added to and removed from the Service without notice. The Information, reports, videos, blogs, news letters and other details included in the Service are provided for reference purposes only. They are not intended either as a substitute for professional advice or judgment or to provide legal or other advice with respect to particular circumstances. Every effort is made to keep online contents up to date but users should obtain independent verification or advice before relying on any piece of information in circumstances where loss or damage may result. The Service and online contents are provided on an ‘as is’ basis and we exclude to the extent permitted by law all implied warranties relating to fitness for a particular purpose.</p>
                    <p>Reasonable care has been taken to ascertain the accuracy of all contents. Intelikart unequivocally states that the contents provided in the app should not be considered as the authority on that topic / subject. Should the user find that any content is inaccurate or does not agree with that derived by the User then the User may voluntarily undertake to inform Intelikart about the same through all available means provided in the app of Intelikart along with the justification / solution / reasoning for the correct content proposed by the user. The User undertakes and acknowledges that Intelikart shall not be held responsible for any content(s) given that may be / have been proven wrong at a later stage, without recourse through a meaningful dialogue with Intelikart and providing Intelikart an opportunity, if required, to undertake corrective action in a mutually agreeable time frame.</p>
                    <b>14. Indemnity</b>
                    <p>You shall indemnify and hold harmless the Company and the Company’s parent, subsidiaries, affiliates, third-parties and their respective officers, directors, agents, and employees, from any claim or demand, or actions including reasonable attorneys’ fees, made by any third party or penalty imposed due to or arising out of your breach of the User Agreement including the rules and policies incorporated herein by reference, or your violation of any law, rules or regulations or the rights of a third party.</p>
                    <b>15. Intellectual Property Rights (IPR)</b>
                    <p>All intellectual property rights and other rights in the online contents (in both machine readable and printed form) belong to the appropriate user who has uploaded/stored the said contents in its account of app.</p>
                    <p>Users shall not obscure or remove any copyright notices that appear on online contents published on app. However, we may use your online contents for our data analytics purposes as per our privacy policy.</p>
                    <p>The graphics, software, display formats (excluding online contents uploaded by user in its account on app), design, style, Interface, compilation, digital conversion, flow and every other matters related to the app/site are owned by the company and protected under applicable Indian and international copyright, trademark and other proprietary (including but not limited to intellectual property) rights. “intelikart.com” and other marks are either trademarks or registered trademarks of INTELIKART PRIVATE LIMITED having its registered office ADDRESS. We have started using Intelikart trademark on 13.02.2016 and we have uploaded Intelikart mobile app on Digital Ocean server on 28.2.2016 You hereby agree and acknowledge that aforesaid dates shall be considered as documentary evidences for proving our first ownership for all IPR relating Intelikart.</p>
                    <p>Every effort has been made to ensure the originality and authenticity of every matter Intelikart. Similarities with any other matter on similar subjects / topics available in any form elsewhere are purely coincidental. The user unequivocally agrees to ensure that in event of claiming a copyright violation the user will undertake to first inform us through all available means provided in the app of Intelikart about the nature of copyright violation and suspected user who is prima facie responsible for said copyright violations as well undertakes not to proceed with legal / judicial action without recourse through a meaningful dialogue with us and providing us an opportunity to undertake corrective action, if required, in a mutually agreeable time frame.</p>
                    <p>We reserve the right to modify or terminate the Services for any reason, without notice at any time. Not all Services and features are available in every jurisdiction and we are under no obligation to make any Services or features available in any jurisdiction.</p>
                    <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
                    <p>We may, but have no obligation to, remove Materials and suspend or terminate Accounts if we determine in our sole discretion that the goods or services offered via a Store, or the Materials uploaded or posted to a Store, violate our Terms of Service.</p>
                    <p>Verbal or written abuse of any kind (including threats of abuse or retribution) of any Intelikart customer, Intelikart employee, member, or officer will result in immediate Account termination.</p>
                    <p>Intelikart does not pre-screen Materials and it is in our sole discretion to refuse or remove any Materials from the Service, including your Store.</p>
                    <p>We reserve the right to provide our services to your competitors and make no promise of exclusivity in any particular market segment. You further acknowledge and agree that Intelikart employees and contractors may also be Intelikart customers/merchants and that they may compete with you, although they may not use your Confidential in doing so.</p>
                    <p>In the event of a dispute regarding Account ownership, we reserve the right to request documentation to determine or confirm Account ownership. Documentation may include, but is not limited to, a scanned copy of your business license, government issued photo ID, the last four digits of the credit card on file, your status as an employee of an entity, etc.</p>
                    <p>Intelikart retains the right to determine, in our sole judgment, rightful Account ownership and transfer an Account to the rightful Store Owner. If we are unable to reasonably determine the rightful Store Owner, without prejudice to our other rights and remedies, Intelikart reserves the right to temporarily disable an Account until resolution has been determined between the disputing parties.</p>
                    <p>Which Means We can modify, cancel or refuse the service at anytime.</p>
                    <p>In the event of an ownership dispute over a intelikart account, we can freeze the account or transfer it to the rightful owner, as determined by us.</p>
                    <b>16. Restrictions:</b>
                    <p>Users shall not:</p>
                    <ul>
                        <li>re-sell the Service or any part of the contents to others;</li>
                        <li>make the Service or any contents available to Unauthorized Persons on any network;</li>
                        <li>abstract, download, store, reproduce, transmit, display, copy, distribute or use app other than as permitted;</li>
                        <li>decompile, disassemble or reverse engineer software programs, databases or other systems used by us to provide the Service;</li>
                        <li>use the Service for any illegal purpose nor send or post or allow any posting of information which is defamatory or obscene or which violates the legal rights of others, nor use the Service to send any unsolicited promotional or advertising material, or any volume messages which may interfere with the provision of the Service or the enjoyment of the Service by other users</li>
                    </ul>
                    <b>17. Termination of services</b>
                    <p>This Agreement shall commence on the Start Date stated in the activation email/sms/Notification and shall survive till it is terminated by the company by sending specific e-notice for the same.</p>
                    <p>If, at any time, we have reasonable grounds to believe that the user is not using the app in accordance with this Agreement including, without limitation, by permitting or acquiescing to the use of the Data by persons other than the users identified in the subscription and non payment of fees, we may suspend the provision of the Service to the User. You agree and acknowledge that we shall have no obligation to reinstate the Service until disputed issue(s) has been agreed between the parties. You further agree and acknowledge that we shall have no liability for any loss, damage or inconvenience caused to you by such a suspension of the Service. The user agrees that the user shall not be entitled to any refund of money paid within this subscription period. Once the suspension has been lifted, and provided that the issues surrounding the suspension have been resolved, the user will be entitled to receive and missing data (if any) accumulated during the suspension period. In case suspension is not lifted then also the user agrees and acknowledges that the user shall not be entitled for any refund of money paid. Also, you agree and acknowledge that any restrictions on access rights due to default on your part shall never be termed as denial of service (DoS) contravention.</p>
                    <b>18. Limited liability clause</b>
                    <p>You agree and acknowledge that in no event shall the Company or its suppliers, affiliates and service providers be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses arising (in any manner whatsoever, including but not limited to negligence) out of or in connection with the App, the Pay Facility, or any other services under this User Agreement. Further, The Company’sliability in any circumstance shall be limited to the amount of charges/ fees, if any, paid by you to the Company. Here, it is clarified that any disputes between you (retailer/Owners) and your customers relating to payments/services shall be governed by your policy only. You agree and acknowledge that company shall never be held responsible for such disputes between you and your customers. The Company, its associates, affiliates and service providers and technology partners make no representations or warranties about the accuracy, reliability, completeness, and/or timeliness of any content, information, software, text, graphics, links or communications provided on or through the use of the App or that the operation of the App or Pay Facility will be error free and/or uninterrupted. Consequently, the Company assumes no liability whatsoever for any monetary or other damage suffered by you on account of any delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the App or its Pay Facility.</p>
                    <b>19. Breach</b>
                    <p>Without limiting other remedies that the Company may pursue, the Company may at its sole discretion take such action as it deems fit including but not limited to limit your activity, immediately remove your information, warn other Users of your actions, forthwith temporarily/indefinitely suspend or terminate or block your subscription, and/or refuse to provide you with access to the App or initiate any legal action it may deem fit, particularly in the event:</p>
                    <ul>
                        <li>You breach any of the provisions of this User Agreement including any of the rules and policies, documents, terms and conditions made thereunder which are incorporated therein by reference;</li>
                        <li>Failure of verification or authentication of any information provided by you;</li>
                    </ul>
                    <p>No actions, omissions or decisions taken by the Company shall waive any rights or claims that the Company may have against the User.</p>
                    <p>Any User that may have been suspended or blocked may not register or attempt to register with the App or use the App in any manner whatsoever until such time that such User is reinstated by the Company. Notwithstanding the above, if you breach the User Agreement or the rules and policies and other documents incorporated therein by reference, the Company reserves the right to take strict legal action including but not limited to referral to the appropriate police or other authorities for initiating criminal or civil or other proceedings against you.</p>
                    <b>20. Grievance Redressal Process</b>
                    <p>In case of any grievance, objection or complaint on your part with respect to the App, other Users or the Company, including any complaints or enquiry about suspension, termination or blocking of your membership or right to use the App, you should promptly raise such grievance or complaint with the designated Grievance Officer at intelikart@gmail.com and provide him with all necessary information and/or documents to enable the Company/ Grievance Officer to resolve the issue. The name and contact details of the Grievance Officer is published on the App as required under the provisions of the Information Technology Act, 2000 and the rules made thereunder.</p>
                    <b>21. General</b>
                    <p>None of the provisions of this User Agreement shall be deemed to constitute a partnership or agency between you and the Company and you shall have no authority to bind the Company in any manner whatsoever.</p>
                    <p>Except as explicitly stated otherwise, any notices directed to the Company shall be given by email to manish@inteliheads.com and any notices to you by the Company shall be provided to the email address provided by you during the registration process. Notice shall be deemed given 78 hours after email is sent, unless the sending party is notified that the email address is invalid. Alternatively, we may give you notice by registered mail, postage prepaid and return receipt requested, to the address provided to us during the registration process. In such case, notice shall be deemed given 3 days after the date of mailing.</p>
                    <p>If any clause of this User Agreement or the application thereof to any User or circumstance shall be deemed invalid, void or for any reason unenforceable to any extent, the remainder of this User Agreement and the application of such unenforceable provision to Users or circumstances other than those as to which it is held invalid or unenforceable shall not be affected thereby, and each provision of this User Agreement shall be valid and enforceable to the fullest extent permitted by law.</p>
                    <p>This User Agreement together with the rules and policies incorporated herein by reference constitutes the entire understanding and agreement between you and the Company with respect to the subject matter herein.</p>
                    <p>The Company at its sole discretion shall be entitled to assign or transfer its rights and obligations under this User Agreement hereunder to any other person without your prior consent provided that the Company assigns this User Agreement on the same terms or such terms that are no less favourable to you.</p>
                    <p>All remedies of the Company under this User Agreement whether provided herein or conferred by statute, civil law, custom or trade usage, are cumulative and not alternative and may be enforced successively or concurrently.</p>
                    <b>22. Arbitration</b>
                    <p>If any dispute arises between you and the Company during your use of the App or the Pay Facility or any service incidental to the App or thereafter, in connection with the validity, interpretation, implementation or alleged breach of any provision of the User Agreement or the rules, policies and documents incorporated therein by reference, the dispute shall be referred to a sole arbitrator who shall be an independent and neutral third party identified by the Company whose decision shall be final. The place of arbitration shall be Ahmedabad, Gujarat, India. The Indian Arbitration & Conciliation Act, 1996, shall govern the arbitration proceedings. The arbitration proceedings shall be in the English language.</p>
                    <b>23. Governing Laws</b>
                    <p>This User Agreement and all rules, policies and documents incorporated by reference shall be governed and construed in accordance with the laws of India under I.T.Act, 2000 shall be made applicable.</p>
                    <p>This document is an electronic record in terms of Information Technology Act, 2000 and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical signatures.</p>
                    <b>24. Assignment</b>
                    <p>This Agreement may not be assigned or sublet by the user without the prior written consent of Company.</p>
                    <b>25. Taxes</b>
                    <p>You agree and acknowledge that we shall never be held responsible for any tax liabilities for your transactions. Further, for avoidance of doubt and notwithstanding anything to the contrary herein, You will indemnify, reimburse and hold us harmless from, for and against any sales, use, gross receipts, excise, franchise, business or other taxes or fees (including penalties, fines or interest thereon) imposed by any government or other taxing authority to the extent such taxes or fees are assessed on us which is your primary legal obligation.</p>
                    <b>26. Entire Agreement</b>
                    <p>This Agreement including the current or any renewal Schedule is the complete and exclusive statement of the agreement between the parties hereto and supersedes all prior agreements and communications between the parties concerning the subject matter hereof. The user acknowledges that no reliance is placed on any representation made but not embodied in this Agreement. No variation of this Agreement shall be effective unless in writing signed by the parties and expressed to be such a variation.</p>
                    <b>27. Waiver</b>
                    <p>No failure or delay by us in exercising any remedy, right or privilege under or in relation to this Agreement shall operate as a waiver of the same nor shall any single or partial exercise of any remedy, right, power or privilege preclude any further exercise of any other right, power or privilege.</p>
                    <b>28. Heading</b>
                    <p>The Clause headings in this Agreement are inserted for convenience only and shall not affect the interpretation of this Agreement.</p>
                    <b>29. Force majeure</b>
                    <p>We shall be under no liability for any failure, delay or omission by it in the performance of its obligations under this Agreement if such failure delay or omission arises from any cause beyond our control, including, but not limited to acts of god, acts or regulations of any governmental or supra-national authority, war or national emergency, fire, civil disobedience, strikes, lock-outs, technical black-out and industrial disputes.</p>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default TermsOfService;