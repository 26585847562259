import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import { addPickupLocation } from '../../actions/shiprocketActions';
import { useHistory } from 'react-router';
import FeatHeader from '../../components/dashboard/FeatHeader';


const AddPickupLocation = () => {
    const [nickname, setNickname] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [address1, setAddress1] = useState<string>('');
    const [address2, setAddress2] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [pincode, setPincode] = useState('');

    const [loading, setLoading] = useState(false);

    const [validation, setValidation] = useState({
        nicknameValidation: '',
        nameValidation: '',
        emailValidation: '',
        phoneValidation: '',
        address1Validation: '',
        cityValidation: '',
        stateValidation: '',
        countryValidation: '',
        pincodeValidation: ''
    })

    const dispatch = useDispatch();
    const accessToken = getAccessToken();

    const history = useHistory()

    const { currentUser } = useSelector((state: any) => state.currentUser);
    const { createPickupLocationLoading, createPickupLocation, createPickupLocationError } = useSelector((state: any) => state.createPickupLocation);

    const [present] = useIonToast();

    useEffect(() => {
        if (createPickupLocationLoading === false && createPickupLocation && createPickupLocation.message) {
            setNickname('');
            setName('');
            setPhone('');
            setEmail('');
            setAddress1('');
            setAddress2('');
            setCity('');
            setState('');
            setCountry('');
            setPincode('');
            history.goBack();
        } else if (createPickupLocationLoading === false && createPickupLocation && createPickupLocation.error) {
            present(createPickupLocation.error, 1000);
        }
    }, [createPickupLocationLoading, createPickupLocation]);

    const validate = () => {
        let result = true;
        let temp = { ...validation }

        // pickup location nickname
        if (!nickname) {
            temp.nicknameValidation = "Address Nickname is required."
            result = false;
        } else {
            if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(nickname) && nickname.length <= 36) {
                temp.nicknameValidation = "Address Nickname is invalid."
                result = false;
            }
            else
                temp.nicknameValidation = ""
        }

        // name
        if (!name) {
            temp.nameValidation = "Name is required."
            result = false;
        } else {
            if (!/^[a-zA-Z\s]+$/.test(name)) {
                temp.nameValidation = "Name can't contain numbers and special characters.";
                result = false;
            }
            else
                temp.nameValidation = ""
        }

        // address
        if (!address1) {
            temp.address1Validation = "Address is required."
            result = false;
        } else {
            temp.address1Validation = ""
        }

        // city
        if (!city || !city.trim()) {
            temp.cityValidation = "City is required."
            result = false;
        } else {
            const validCityPattern = /^[A-Za-z\s]+$/;

            if (!validCityPattern.test(city)) {
                temp.cityValidation = "Invalid city name. Please use letters and spaces only.";
                result = false;
            } else {
                temp.cityValidation = "";
            }
        }

        // state
        if (!state || !state.trim()) {
            temp.stateValidation = "State is required."
            result = false;
        } else {
            const validStatePattern = /^[A-Za-z\s]+$/;

            if (!validStatePattern.test(state)) {
                temp.stateValidation = "Invalid state name. Please use letters and spaces only.";
                result = false;
            } else {
                temp.stateValidation = "";
            }
        }

        // country
        if (!country || !country.trim()) {
            temp.countryValidation = "Country is required."
            result = false;
        } else {
            const validCountryPattern = /^[A-Za-z\s]+$/;

            if (!validCountryPattern.test(country)) {
                temp.countryValidation = "Invalid country name. Please use letters and spaces only.";
                result = false;
            } else {
                temp.countryValidation = "";
            }
        }

        // pincode
        if (!pincode) {
            temp.pincodeValidation = "Pincode is required."
            result = false;
        } else {
            if (/\D/.test(pincode)) {
                temp.pincodeValidation = "Pincode is invalid."
                result = false;
            } else {
                temp.pincodeValidation = ""
            }
        }

        // email
        if (!email) {
            temp.emailValidation = "Email is required."
            result = false;
        } else {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                temp.emailValidation = "Email is invalid."
                result = false;
            } else {
                temp.emailValidation = ""
            }
        }

        // phone
        if (!phone) {
            temp.phoneValidation = "Phone number is required."
            result = false
        } else {
            if (!/^\d{10}$/.test(phone)) {
                temp.phoneValidation = "Phone number is invalid."
                result = false;
            }
            else {
                temp.phoneValidation = ""
            }
        }

        setValidation(temp);
        return result;
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await validate();
        if (res) {
            dispatch(addPickupLocation(accessToken, currentUser.data.store.subdomain, {
                pickup_location: nickname,
                name: name,
                email: email,
                phone: phone,
                address: address1,
                address_2: address2.length < 3 ? null : address2,
                city: city,
                state: state,
                country: country,
                pin_code: pincode,

            }));
        }
        setLoading(false)
    }

    return (
        <IonPage>
            
            <FeatHeader title='Add Pickup Location' defaultHref='/orders/orderdetails' showInfoButton={ false} />
            <IonContent className="ion-padding">
                <form onSubmit={submitHandler} noValidate>
                    <IonItem className='ion-no-padding ion-margin-horizontal'>
                        <IonLabel position="stacked"><h1>Address Nickname</h1></IonLabel>
                        <br />
                        <IonInput type="text" value={nickname} placeholder="Enter Address Nickname" onIonChange={(e) => { setNickname(e.detail.value) }} />
                    </IonItem>

                    <IonText color="danger" className={`${validation.nicknameValidation ? "" : "ion-hide"}`}>
                        <h5 style={{ fontSize: 12 + 'px' }} className="ion-no-margin ion-margin-horizontal">{validation.nicknameValidation}</h5>
                    </IonText>
                    <br />

                    <IonGrid className='ion-margin-horizontal' style={{ padding: "8px 0" }}>
                        <IonRow>
                            <IonCol className='ion-no-padding'>
                                <IonText style={{ fontSize: "18px", fontWeight: 400 }}>Contact Details</IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-justify-content-between'>
                            <IonCol size='3' sizeXs='12' className='ion-no-padding'>
                                <IonItem className='ion-no-padding' style={{ paddingTop: "12px" }}>
                                    <IonInput type="text" value={name} placeholder="Name" onIonChange={(e) => { setName(e.detail.value) }} />
                                </IonItem>
                                <IonText color="danger" className={`${!validation.nameValidation ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>{validation.nameValidation}</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='3' sizeXs='6' className='ion-no-padding'>
                                <IonItem className='ion-no-padding' style={{ paddingTop: "12px" }}>
                                    <IonInput type="text" value={email} placeholder="Email" onIonChange={(e) => { setEmail(e.detail.value) }} />
                                </IonItem>
                                <IonText color="danger" className={`${!validation.emailValidation ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>{validation.emailValidation}</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='3' sizeXs='5' className='ion-no-padding'>
                                <IonItem className='ion-no-padding' style={{ paddingTop: "12px" }}>
                                    <IonInput type="text" value={phone} placeholder="Phone" onIonChange={(e) => { setPhone(e.detail.value) }} />
                                </IonItem>
                                <IonText color="danger" className={`${!validation.phoneValidation ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>{validation.phoneValidation}</h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <br />
                    <IonGrid className='ion-margin-horizontal' style={{ padding: "2px 0" }}>
                        <IonRow>
                            <IonCol className='ion-no-padding'>
                                <IonText style={{ fontSize: "18px", fontWeight: 400 }}>Address Details</IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className='ion-no-padding'>
                                <IonItem className='ion-no-padding' style={{ paddingTop: "12px" }}>
                                    <IonInput type="text" value={address1} placeholder="Address Line 1" onIonChange={(e) => { setAddress1(e.detail.value) }} />
                                </IonItem>
                                <IonText color="danger" className={`${!validation.address1Validation ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>{validation.address1Validation}</h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-justify-content-between'>
                            <IonCol sizeSm='8' sizeXs='12' className='ion-no-padding'>
                                <IonItem className='ion-no-padding' style={{ paddingTop: "12px" }}>
                                    <IonInput type="text" value={address2} placeholder="Address Line 2" onIonChange={(e) => { setAddress2(e.detail.value) }} />
                                </IonItem>
                            </IonCol>
                            <IonCol sizeSm='3' sizeXs='12' className='ion-no-padding'>
                                <IonItem className='ion-no-padding' style={{ paddingTop: "12px" }}>
                                    <IonInput type="text" value={pincode} placeholder="Pin Code" onIonChange={(e) => { setPincode(e.detail.value) }} />
                                </IonItem>
                                <IonText color="danger" className={`${!validation.pincodeValidation ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>{validation.pincodeValidation}</h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-justify-content-between'>
                            <IonCol sizeSm='4' sizeXs='6' className='ion-no-padding ion-padding-vertical'>
                                <IonItem className='ion-no-padding'>
                                    <IonInput type="text" value={city} placeholder="City" onIonChange={(e) => { setCity(e.detail.value) }} />
                                </IonItem>
                                <IonText color="danger" className={`${!validation.cityValidation ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>{validation.cityValidation}</h5>
                                </IonText>
                            </IonCol>
                            <IonCol sizeSm='3' sizeXs='5' className='ion-no-padding ion-padding-vertical'>
                                <IonItem className='ion-no-padding'>
                                    <IonInput type="text" value={state} placeholder="State" onIonChange={(e) => { setState(e.detail.value) }} />
                                </IonItem>
                                <IonText color="danger" className={`${!validation.stateValidation ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>{validation.stateValidation}</h5>
                                </IonText>
                            </IonCol>
                            <IonCol sizeSm='3' sizeXs='12' className='ion-no-padding ion-padding-vertical'>
                                <IonItem className='ion-no-padding'>
                                    <IonInput type="text" value={country} placeholder="Country" onIonChange={(e) => { setCountry(e.detail.value) }} />
                                </IonItem>
                                <IonText color="danger" className={`${!validation.countryValidation ? "ion-hide" : ""}`}>
                                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}>{validation.countryValidation}</h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <br />
                    <IonButton className='ion-margin-horizontal' type="submit">Add Pickup Location</IonButton>
                </form>

                <IonLoading isOpen={loading || createPickupLocationLoading} message="Please Wait"></IonLoading>
            </IonContent>
        </IonPage >
    )
}

export default AddPickupLocation