import { GET_MASTER_CATEGORIES_FAIL, GET_MASTER_CATEGORIES_REQUEST, GET_MASTER_CATEGORIES_SUCCESS, GET_MASTER_PRODUCTS_FAIL, GET_MASTER_PRODUCTS_REQUEST, GET_MASTER_PRODUCTS_SUCCESS, POST_COPY_PRODUCTS_FAIL, POST_COPY_PRODUCTS_REQUEST, POST_COPY_PRODUCTS_SUCCESS } from "../constants/masterCatelogueConstants";

const API_ROOT = 'https://api2.intelikart.in/';

export const getMasterCategory = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_MASTER_CATEGORIES_REQUEST })
        const response = await fetch(`${API_ROOT}master/categories`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
            },
        });
        const data = await response.json();
        dispatch({
            type: GET_MASTER_CATEGORIES_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: GET_MASTER_CATEGORIES_FAIL,
            payload: error
        })
    }
}

export const getMasterProducts = (id: Number, accessToken: string, ikDomain: string, pageNumber : Number) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_MASTER_PRODUCTS_REQUEST })
        const response = await fetch(`${API_ROOT}master/products?categoryId=${id}&page=${pageNumber}`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
            },
        });
        const data = await response.json();
        dispatch({
            type: GET_MASTER_PRODUCTS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_MASTER_PRODUCTS_FAIL,
            payload: error
        })
    }
}

export const copyProducts = (productId,accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : POST_COPY_PRODUCTS_REQUEST})
        const response = await fetch(`${ API_ROOT }master`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${accessToken}`,
                "x-ik-domain" : `${ikDomain}`
            },
            body: JSON.stringify(productId)
        });
        const data = await response.json();
        dispatch({
            type: POST_COPY_PRODUCTS_SUCCESS,
            payload: data.message
        })
    }catch(error) {
        dispatch({
            type: POST_COPY_PRODUCTS_FAIL,
            payload: error
        })
    }
}