import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  isPlatform
} from "@ionic/react";
import { openOutline, call as phoneIcon, logoWhatsapp as whatsappIcon } from "ionicons/icons";
import { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { getCart } from "../../actions/dashboardActions";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import useAPI from "../../utils/api";
import { StoreCurrency } from '../../utils/currency';
import Title from "../../components/dashboard/Header";

const AbandonedOrders = () => {
  const [searchCustomer, setSearchCustomer] = useState({
    inSearch: false,
    query: "",
    result: [],
    total: undefined,
    page: 1,
  });
  const currency = StoreCurrency();
  const { currentUser } = useSelector((state: any) => state.currentUser);
  const [disableInfiniteScroll, setDisableInfiniteScroll] =
    useState<boolean>(false);
  const { cartLoading, cart, cartError } = useSelector(
    (state: any) => state.cart
  );
  const API = useAPI();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser && currentUser.data) {
      dispatch(getCart(getAccessToken(), currentUser.data.store.subdomain));
    }
  }, [currentUser]);

  async function searchCustomerHandler(e) {
    e.preventDefault();
    setSearchCustomer({ ...searchCustomer, inSearch: true });
    const [ok, response] = await API.customers(
      searchCustomer.page,
      searchCustomer.query
    );
    if (ok) {
      setSearchCustomer((prev) => {
        return {
          ...prev,
          result: response.data,
          page: prev.page + 1,
          total: response.meta.pagination.total,
        };
      });

      if (response?.data.length < 20 || response?.data.length === 0) {
        setDisableInfiniteScroll(true);
      }
    }
  }

    return (
        <IonPage>

            <Title name="Abandoned Orders" />

            <IonContent className="ion-padding">
                <div style={{ backgroundColor: "#F5F7FB", marginBottom: "16px" }}>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12" size-sm="6" size-md="3" sizeXs="4">
                                <IonText>
                                    <b>Name</b>
                                </IonText>
                            </IonCol>
                            {!isPlatform('capacitor') &&
                                <IonCol size="12" size-sm="6" size-md="3">
                                    <IonText >
                                        <b>Email</b>
                                    </IonText>
                                </IonCol>
                            }
                            <IonCol size="12" size-sm="6" size-md="3" sizeXs="4">
                                <IonText >
                                    <b>Products</b>
                                </IonText>
                            </IonCol>
                            <IonCol size="12" size-sm="6" size-md="3" sizeXs="4">
                                <BrowserView>
                                    <IonText >
                                        <b>Mobile Number</b>
                                    </IonText>
                                </BrowserView>
                                <MobileView>
                                    <IonText >
                                        <b>Phone</b>
                                    </IonText>
                                </MobileView>
                            </IonCol>
                            <IonCol size="12" size-md="2">
                                <IonText className="ion-text-center"></IonText>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
                {cartLoading === false &&
                    cart &&
                    cart.data &&
                    cart.data.length !== 0 ? (
                    <IonAccordionGroup>
                        {cart.data.map((value: any) => (
                            <IonAccordion key={value.id} value={value.id}>
                                <IonItem
                                    className="ion-margin-bottom"
                                    slot="header"
                                    color="light"
                                >
                                    <IonGrid>
                                        <IonRow className="ion-align-items-center">
                                            <IonCol size="4" size-md="3" sizeXs="5">
                                                <IonText>{value.user.first_name}</IonText>
                                            </IonCol>
                                            {!isPlatform('capacitor') &&
                                                <IonCol size="4" size-md="3">
                                                    <IonText>{value.user.email}</IonText>
                                                </IonCol>
                                            }
                                            <IonCol size="4" size-md="3" sizeXs="3.5">
                                                <BrowserView>
                                                    <IonText>{value.products.length} Products</IonText>
                                                </BrowserView>
                                                <MobileView>
                                                    <IonText>{value.products.length}</IonText>
                                                </MobileView>
                                            </IonCol>
                                            <IonCol size="4" size-md="3" sizeXs="3.5">
                                                <div className="d-flex align-items-center">
                                                    <BrowserView>
                                                        <IonText>{value.user.phone}</IonText>
                                                    </BrowserView>
                                                    {value.user.phone ? (
                                                        <div className="d-flex ml-2">
                                                            <IonButton
                                                                fill="clear"
                                                                color="secondary"
                                                                onClick={() =>
                                                                    window.open(`tel:${value.user.phone}`)
                                                                }
                                                            >
                                                                <IonIcon
                                                                    slot="icon-only"
                                                                    icon={phoneIcon}
                                                                    color="secondary"
                                                                    size="small"
                                                                />
                                                            </IonButton>
                                                            <IonButton
                                                                fill="clear"
                                                                color="secondary"
                                                                onClick={() =>
                                                                    window.open(
                                                                        `https://wa.me/${value.user.phone}`
                                                                    )
                                                                }
                                                            >
                                                                <IonIcon
                                                                    slot="icon-only"
                                                                    icon={whatsappIcon}
                                                                    color="secondary"
                                                                    size="small"
                                                                />
                                                            </IonButton>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonLabel
                                        style={{ display: "flex", flexDirection: "column" }}
                                    >
                                        {value.products.map((product: any) => (
                                            <div
                                                key={product.id}
                                                style={{ display: "flex", alignItems: "center" }}
                                            >
                                                {" "}
                                                <IonImg
                                                    src={product.variants[0].image_url_thumb}
                                                    className="img-fluid"
                                                    style={{
                                                        height: "70px",
                                                        width: "70px",
                                                        marginRight: "10px",
                                                    }}
                                                />
                                                <IonGrid>
                                                    <IonRow>
                                                        <IonCol>
                                                        <IonText>{product.name}</IonText>
                                                        <IonText>({product.qty})</IonText>
                                                        <a
                                                            href={`https://${currentUser.data.store.subdomain}/product/${product.name}/${product.id}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <IonIcon
                                                                icon={openOutline}
                                                                color="secondary"
                                                                size="small"
                                                            />
                                                        </a>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                    <IonCol>
                                                        {currency} {product.variants[0].mrp}
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                            </div>
                                        ))}
                                        <IonText className="mt-2">
                                            <b> Total Order Value: </b>{currency} {value.pricing_breakup?.totalPrice}
                                        </IonText>
                                    </IonLabel>
                                </IonItem>
                            </IonAccordion>
                        ))}
                    </IonAccordionGroup>
                ) : (
                    <IonText>No abandoned orders found.</IonText>
                )}
                <IonLoading isOpen={cartLoading} />
            </IonContent>
        </IonPage>
    );
};

export default AbandonedOrders;
