import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
  IonText
} from "@ionic/react";
import {
  call as callIcon,
  location as locationIcon,
  shareSocial as shareSocialIcon,
  logoWhatsapp as whatsappIcon
} from "ionicons/icons";

const OrderUserDetails = ({orderData, shareOrderFunc}) => {
    return <>
        {orderData?.custom_message && (
                  <>
                    <IonText>
                      <h5 className="ion-padding-start">
                        <b>Order Notes:</b>
                      </h5>
                    </IonText>
                    <IonCard>
                      <IonCardContent>
                        <p>{orderData?.custom_message}</p>
                      </IonCardContent>
                    </IonCard>
                  </>
                )}

                <IonText>
                  <h5 className="ion-padding-start">
                    <b>Address:</b>
                  </h5>
                </IonText>
                <IonCard>
                  <IonRow>
                    <IonCol sizeXl="9" sizeXs="12">
                      <IonItem lines="none">
                        <IonText slot="start">
                          <p>{orderData?.delivery_address}</p>
                          <p>
                            {orderData?.delivery_city},{" "}
                            {orderData?.delivery_zip}
                          </p>
                        </IonText>
                      </IonItem>
                    </IonCol>
                    <IonCol
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IonButton
                        expand="full"
                        href={`https://maps.google.com/?q=${orderData?.delivery_latitude},${orderData?.delivery_longitude}`}
                        target="_blank"
                      >
                        <IonIcon icon={locationIcon}></IonIcon> &nbsp; View on
                        Map
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonCard>
                <IonGrid>
                  <IonRow>
                    {orderData?.delivery_latitude == 0 ||
                    orderData?.delivery_longitude == 0 ? (
                      <IonCol sizeXs="12" sizeLg="9.2">
                        <IonButton expand="full">No Location</IonButton>
                      </IonCol>
                    ) : (
                      <>
                        {/* <IonCol size="6">
                                            <IonButton expand="full" onClick={shareLocation}>Share Location</IonButton>
                                        </IonCol> */}
                      </>
                    )}
                  </IonRow>
                </IonGrid>

                {orderData?.user_name ? (
                  <>
                    <IonText>
                      <h5 className="ion-padding-start">
                        <b>Customer Details:</b>
                      </h5>
                    </IonText>
                    <IonCard>
                      <IonItem lines="none">
                        <IonText slot="start">Name:</IonText>
                        <IonText slot="end">{`${
                          orderData?.user_name ? orderData?.user_name : ""
                        }`}</IonText>
                      </IonItem>
                      <IonItem lines="none">
                        <IonText slot="start">Mobile:</IonText>
                        <IonText slot="end">{`${
                          orderData?.user_phone ? orderData?.user_phone : ""
                        }`}</IonText>
                      </IonItem>
                    </IonCard>
                    <IonGrid>
                      <IonRow>
                        <IonCol sizeXs="6" sizeLg="4">
                          <IonButton
                            expand="full"
                            onClick={(e) => shareOrderFunc()}
                          >
                            <IonIcon icon={shareSocialIcon}></IonIcon> &nbsp;
                            Share
                          </IonButton>
                        </IonCol>
                        <IonCol sizeXs="6" sizeLg="4">
                          <IonButton
                            expand="full"
                            href={`tel:${orderData?.user_phone}`}
                          >
                            <IonIcon icon={callIcon}></IonIcon> &nbsp; Call
                          </IonButton>
                        </IonCol>
                        <IonCol sizeXs="12" sizeLg="4">
                          <IonButton
                            expand="full"
                            href={`//api.whatsapp.com/send?phone=91${orderData?.user_phone}`}
                            target="_blank"
                          >
                            <IonIcon icon={whatsappIcon}></IonIcon> &nbsp;
                            Whatsapp
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </>
                ) : (
                  ""
                )}
    </>
}

export default OrderUserDetails