import { useContext } from 'react';
import { auth } from "./auth";
import { UserContext } from "./contexts";
import Timeslot from '../dashboard/settings/timeslot/Timeslot';

// const { REACT_APP_API_HOST } = process.env;
const REACT_APP_API_HOST = "https://api2.intelikart.in/";

async function GET(path, token, userDomain) {
  const response = await fetch(`${REACT_APP_API_HOST}${path}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${token}`,
      'x-ik-domain':`${userDomain}`
    }
  });

  try {
    if (response.status === 401) {
      return [false, {status: 401, message: 'Unauthorized'}];
    }

    if (response.status === 204) {
      return [response.ok, null];
    }

    const json = await response.json();
    return [response.ok, json];
  } catch (e) {
    return [response.ok, {status: response.status}];
  }
}

// async function DOWNLOAD(path, token, filename) {
//   const response = await fetch(`${API_ROOT}${path}`, {
//     mode: 'cors',
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `${token}`
//     }
//   });

//   if (response.status === 401) {
//     return [false, {message: 'Unauthorized'}];
//   }

//   const blob = await response.blob();
//   const file = window.URL.createObjectURL(blob);
//   const link = document.createElement('a');
//   link.href = file;
//   link.download = filename;
//   link.click();
// }

async function UPDATE(method, path, token, userDomain, data={}) {
  const isFormData = data instanceof FormData
  const body = isFormData ? data : JSON.stringify(data)

  const formDataHeaders = {
    'authorization': `Bearer ${token}`,
    'x-ik-domain':`${userDomain}`
  }
  const jsonHeaders = {'Content-Type': 'application/json', ...formDataHeaders}

  try {
    const response = await fetch(`${REACT_APP_API_HOST}${path}`, {
      method: method,
      cache: 'no-cache',
      body: body,
      headers: isFormData ? formDataHeaders : jsonHeaders
    });

    if (response.status === 401) {
      return [false, {message: 'Unauthorized'}];
    }

    if (response.status === 404) {
      return [false, {message: 'Not Found'}];
    }

    if (response.status === 204) {
      return [response.ok, null];
    }
    const contentLength = response.headers.get('Content-Length');

    if (method === 'DELETE' && response.status === 200 && (contentLength === '0' || contentLength === null)) {
        return [response.ok, null];
    }
        
    const json = await response.json();
    return [response.ok, json];
  } catch (e) {
    console.log(e)
    return [false, { message: e.message }]
  }
}

async function LOGIN_UPDATE(method, path, data={}) {
  const isFormData = data instanceof FormData
  const body = isFormData ? data : JSON.stringify(data)

  const options : Object = {
    method: method,
    cache: 'no-cache',
    body: body,
  }

  if(!isFormData)
    options['headers'] = {'Content-Type': 'application/json'}

  try {
    const response = await fetch(`${REACT_APP_API_HOST}${path}`, options);

    if (response.status === 401) {
      return [false, {message: 'Unauthorized'}];
    }

    if (response.status === 404) {
      return [false, {message: 'Not Found'}];
    }

    if (response.status === 204) {
      return [response.ok, null];
    }

    const json = await response.json();
    return [response.ok, json];
  }catch(e) {
    console.log(e)
    return [false, { message: e.message }]
  }
}

async function POST(path, token, userDomain, data) {
  return UPDATE('POST', path, token, userDomain, data);
}

async function LOGIN_POST(path, data) {
  return LOGIN_UPDATE('POST', path, data);
}

async function PUT(path, token, userDomain, data) {
  return UPDATE('PUT', path, token, userDomain, data);
}

async function DELETE(path, userDomain, token) {
  return UPDATE('DELETE', path, userDomain, token);
}

async function DELETEWITHBODY(path, token, userDomain, data) {
  return UPDATE('DELETE', path, token, userDomain, data);
}

const useAPI = () => {
  const token = auth.getToken();
  const user: any = useContext(UserContext);
  
  const storeId = user?.store_id;
  const userDomain = user?.store?.subdomain;

  return {
    storeId,
    pages: () => GET(`pages`, token, userDomain),
    createPage: (pageData) => POST(`pages`, token, userDomain, pageData),
    updatePage: (pageData) => PUT(`pages`, token, userDomain, pageData),
    deletePage: (pageId) => DELETE(`pages/${pageId}`, token, userDomain),
    slugAvailable: (url) => GET(`pages/slug-availability/${url}`, token, userDomain),

    orderStatus: () => GET(`dynamic-order-status`, token, userDomain),
    createOrderStatus: (statusData) => POST(`dynamic-order-status`, token, userDomain, statusData),
    updateOrderStatus: (statusData) => PUT(`dynamic-order-status`, token, userDomain, statusData),
    deleteOrderStatus: (statusId) => DELETE(`dynamic-order-status/${statusId}`, token, userDomain),

    customers:  (page, query = null) => GET(`customers${query ? `?query=${query}` : ''}${page ? `${query ? "&" : "?"}page=${page}` : ""}`, token, userDomain),

    // Coupons
    // coupons: () => GET(`api/coupons?storeId=${storeId}`, token, userDomain),
    // createCoupon: (couponData) => POST(`api/coupons`, token, userDomain, couponData),
    // deleteCoupon: (couponData) => DELETEWITHBODY(`api/coupons`, token, userDomain, couponData),

    getMediaImage: (page) => GET(`media-library/ALL?page=${page}&per_page=15`, token, userDomain),
    uploadMediaImage: (formData) => POST(`media-library`, token, userDomain, formData),
    deleteMediaImage: (id) => DELETE(`media-library/${id}`, token, userDomain),
    
    // Banners
    banners: () => GET(`stores/banners`, token, userDomain),
    createBanner: (bannerData) => POST(`stores/banners`, token, userDomain, bannerData),
    updateBanner: (bannerData) => PUT(`stores/banners`, token, userDomain, bannerData),
    deleteBanner: (bannerId) => DELETE(`stores/banners/${bannerId}`, token, userDomain),

    //Menus
    getMenus : ()=> GET(`store-menus`,token,userDomain),
    createMenu : (menuData) => POST(`store-menus`,token,userDomain,menuData),
    deleteMenu : (menuID) => DELETE(`store-menus/${menuID}`,token,userDomain),
    getMenu : (menuID) => GET(`store-menus/${menuID}`,token,userDomain),
    updateMenu : (menuData) => PUT(`store-menus`,token,userDomain,menuData),

    // App Menus
    getAppMenus : ()=> GET(`store-app-menus`,token,userDomain),
    createAppMenu : (menuData) => POST(`store-app-menus`,token,userDomain,menuData),
    deleteAppMenu : (menuID) => DELETE(`store-app-menus/${menuID}`,token,userDomain),
    updateAppMenu : (menuData) => PUT(`store-app-menus`,token,userDomain,menuData),

    // Products
    // products: () => GET(`stores/banners`, token, userDomain),
    productsById: (id) => id && GET(`search/products?productId=${id}`, token, userDomain),
    productsByCategory: (categoryId) => categoryId && GET(`search/products?categoryId=${categoryId}`, token, userDomain),
    createProduct: (productData) => POST(`products`, token, userDomain, productData),
    updateProduct: (productData) => PUT(`products`, token, userDomain, productData),
    // deleteBanner: (bannerId) => DELETE(`stores/banners/${bannerId}`, token, userDomain),
    
    poster: () => GET(`stores/banners`, token, userDomain),
    createPoster: (bannerData) => POST(`stores/poster`, token, userDomain, bannerData),
    deletePoster: (bannerId) => DELETE(`stores/poster/${bannerId}`, token, userDomain),
  
    //POS Orders
   getCart: (userId) => GET(`seller-cart?user_id=${userId}`, token, userDomain),
   addProductInCart: (data) => POST(`seller-cart`,  token, userDomain, data),
   deleteProductInCart: (data) => DELETEWITHBODY(`seller-cart/product`,  token, userDomain, data),
   createCustomer: (data) => POST(`seller-cart/filter`,  token, userDomain, data),
   createSellerOrder: (data) => POST(`seller-order`,token, userDomain, data),
   deleteCart: (data) => DELETEWITHBODY(`seller-cart`,token, userDomain,data),
   getCustomerByEmail: (email) => GET(`seller-cart/search?email=${email}`, token, userDomain),


    stickyProduct: () => GET(`stores/sticky`, token, userDomain),
    createStickyProduct: (bannerData) => POST(`stores/sticky`, token, userDomain, bannerData),
    updateStickyProduct: (product_id) => PUT(`stores/sticky`, token, userDomain, product_id),
    deleteStickyProduct: (product_id) => DELETE(`stores/sticky/${product_id}`, token, userDomain),


    // Sticky Category
    deleteStickyCategory: (category_id) => DELETE(`stores/sticky-category/${category_id}`, token, userDomain),
    updateStickyCategory: (categories) => PUT(`stores/sticky-category`, token, userDomain, categories),

    // Staffs
    staffs: () => GET(`stores-staff`, token, userDomain),
    createStaff: (staffData) => POST(`stores-staff`, token, userDomain, staffData),
    deleteStaff: (staffId) => DELETE(`stores-staff/${staffId}`, token, userDomain),

    printables: () => GET(`store-marketing/printables`, token, userDomain),
    announcements: () => GET(`stores/announcements-suggestions`, token, userDomain),
    createAnnouncement: (announcementData) => POST(`stores/announcements`, token, userDomain, announcementData),
    emailStats: () => GET(`store/email-marketing-stats`, token, userDomain),
    createEmails: (emailData) => POST(`store/email-marketing`, token, userDomain, emailData),

    // Orders -> Adjustments
    adjustments: (orderId) => GET(`api/orders/${orderId}/adjustments`, token, userDomain),
    createAdjustment: (orderId, adjustmentData) => POST(`orders/${orderId}/adjustments`, token, userDomain, adjustmentData),
    updateAdjustment: (orderId, adjustmentData) => PUT(`orders/${orderId}/adjustments`, token, userDomain, adjustmentData),
    deleteAdjustment: (orderId, adjustmentId) => DELETE(`orders/${orderId}/adjustments/${adjustmentId}`, token, userDomain),

    // Notification
    notifications: () => GET(`notifications`, token, userDomain),
    readNotification: (notificationData) => POST(`notifications`, token, userDomain, notificationData),

    // Setting page
    // basicInfo: () => GET(`api/store-settings/${storeId}/basic-about`, token, userDomain),
    // createBasicInfo: (basicInfoData) => POST(`api/store-settings/${storeId}/basic-about`, token, userDomain, basicInfoData),
    serviceDetail: () => GET(`store-settings/service-details`, token, userDomain),
    createServiceDetail: (serviceDetailData) => POST(`store-settings/service-details`, token, userDomain, serviceDetailData),
    // quickOrder: () => GET(`api/store-settings/${storeId}/quick-orders`, token, userDomain),
    // createQuickOrder: (quickOrderData) => POST(`api/store-settings/${storeId}/quick-orders`, token, userDomain, quickOrderData),
    // payment: () => GET(`api/store-settings/${storeId}/payments`, token, userDomain),
    // createPayment: (paymentData) => POST(`api/store-settings/${storeId}/payments`, token, userDomain, paymentData),
    // localization: () => GET(`api/store-settings/${storeId}/basic-localisation`, token, userDomain),
    // createLocalization: (localizationData) => POST(`api/store-settings/${storeId}/basic-localisation`, token, userDomain, localizationData),
    // tax: (lat, long) => GET(`api/taxes-charges?storeId=${storeId}&lat=${lat}&long=${long}`, token, userDomain),
    // createTax: (taxData) => POST(`api/taxes-charges`, token, userDomain, taxData),
    // deleteTax: (taxId) => DELETE(`api/taxes-charges/${taxId}?storeId=${storeId}`, token, userDomain),
    // theme: () => GET(`api/store-settings/${storeId}/themes
    // api/store-settings/${storeId}/themes`, token, userDomain),
    // createTheme: (themeData) => POST(`api/store-settings/${storeId}/themes
    // api/store-settings/${storeId}/themes`, token, userDomain, themeData),
    // branding: () => GET(`api/store-settings/${storeId}/branding`, token, userDomain),
    // createBranding: (brandingData) => POST(`api/store-settings/${storeId}/branding`, token, userDomain, brandingData),


    //Catalouge
    categories: (status = 'all') => GET(`store-categories?parent_id=0&status=${status}`, token, userDomain),
    createCategory: (categoryData) => POST(`store-categories`, token, userDomain, categoryData),
    updateCategory: (categoryData) => PUT(`store-categories?parent_id=0`, token, userDomain, categoryData),
    deleteCategory: (categoryId) => DELETE(`store-categories/${categoryId}`, token, userDomain),
    // categoryTree: (categoryData) => PUT(`store-categories-tree`, token, userDomain, categoryData),
    Brands: () => GET(`brands`, token, userDomain),
    createBrands: (brandsData) => POST(`brands`, token, userDomain, brandsData),
    updateBrands: (brandsData) => PUT(`brands`, token, userDomain, brandsData),
    deleteBrands: (brandsId) => DELETE(`brands/${brandsId}`, token, userDomain),
    getDeliveryOrder:() => GET(`zipcode`,token,userDomain),
    postDeliveryOrder :(zipcode) => POST(`zipcode`,token,userDomain,zipcode),
    updateDeliveryOrder :(zipcode,id)=> PUT(`zipcode/${id}`,token,userDomain,{zipcode}),
    deleteDeliveryOrder :(id)=>DELETE(`zipcoode/${id}`,token,userDomain),
    inventoryStocks: (pageNumber)=> GET(`inventory?per_page=20&page=${pageNumber}`,token,userDomain),
    
    

    // userToken: (loginData) => LOGIN_POST(`oauth/access-token`, loginData),
    // getOtp: (email) => LOGIN_POST(`oauth/otp`, email),
    // currentUser: () => GET(`users/current`, token, ''),
    // currentUserDirect: (updatedToken) => GET(`users/current`, updatedToken, ''),
    // storeOnboarding: (onboardingData) => POST(`store-onboarding`, token, userDomain, onboardingData),


    // tipBanners: () => GET(`stores/tips-banners`, token, ''),
    // homeScreen: () => GET(`seller-home-screen/action-data`, token, userDomain),
    // profileCompletion: () => GET(`store-setup-steps`, token, userDomain),
    // homeScreenDays: (days) => GET(`analytics/seller-home-screen/${days}`, token, userDomain),
    // academy: () => GET(`academy`, token, userDomain),

    //Analytics
    sales: (startDate, endDate) => GET(`reports/sales?startDate=${startDate}&endDate=${endDate}`, token, userDomain),
    traffic: (startDate, endDate) => GET(`reports/visitors?startDate=${startDate}&endDate=${endDate}`, token, userDomain),

    //Orders
    ordersByStatus: (orderStatus, pageNumber) => GET(`orders/stores/${orderStatus}?page=${pageNumber}&per_page=10`, token, userDomain),
    orderById: (orderId) => GET(`orders/${orderId}`, token, userDomain),
    suggestions: () => GET(`orders/recent/messages`, token, userDomain),
    invoiceDownload: (orderId) => GET(`orders/receipt/${orderId}`, token, userDomain),
    changeOrderStaff: (orderId, driverId) => GET(`orders/assign/${orderId}/${driverId}`, token, userDomain),
    changeOrderStatus: (orderId, newStatus, message = "") => GET(`orders/change/${orderId}/${newStatus}?message=${message}`, token, userDomain),
    slotTime: (orderId, DeliveryMessage) => POST(`orders/delivery-message/${orderId}`, token, userDomain, DeliveryMessage),


    //Addons
    gaAddon: () => GET(`addons/google-analytics`, token, userDomain),
    createGAAddon: (gaData) => POST(`addons/google-analytics`, token, userDomain, gaData),
    updateGAAddon: (gaData) => PUT(`addons/google-analytics`, token, userDomain, gaData),
    whatsappAddon: () => GET(`addons/whatsapp-chat-support`, token, userDomain),
    createWhatsappAddon: (whatsappData) => POST(`addons/whatsapp-chat-support`, token, userDomain, whatsappData),
    updateWhatsappAddon: (whatsappData) => PUT(`addons/whatsapp-chat-support`, token, userDomain, whatsappData),
    shiprocketAddon: () => GET(`addons/shiprocket`, token, userDomain),
    createShiprocketAddon: (shiprocketData) => POST(`addons/shiprocket`, token, userDomain, shiprocketData),
    updateShiprocketAddon: (shiprocketData) => PUT(`addons/shiprocket`, token, userDomain, shiprocketData),
    inventoryAddon: () => GET(`addons/inventory`, token, userDomain),
    createInventoryAddon: (inventoryData) => POST(`addons/inventory`, token, userDomain, inventoryData),
    updateInventoryAddon: (inventoryData) => PUT(`addons/inventory`, token, userDomain, inventoryData),
    orderAddon: () => GET(`addons/orders`, token, userDomain),
    createOrderAddon: (orderData) => POST(`addons/orders`, token, userDomain, orderData),
    updateOrderAddon: (orderData) => PUT(`addons/orders`, token, userDomain, orderData),
    mailchimpAddon: () => GET(`addons/mailchimp`, token, userDomain),
    createMailchimpAddon: (mailchimpData) => POST(`addons/mailchimp`, token, userDomain, mailchimpData),
    updateMailchimpAddon: (mailchimpData) => PUT(`addons/mailchimp`, token, userDomain, mailchimpData),
    productTaxAddon: () => GET(`addons/productleveltaxes`, token, userDomain),
    createProductTaxAddon: (productTaxData) => POST(`addons/productleveltaxes`, token, userDomain, productTaxData),
    createSeoAddon: (seoData) => POST(`website-seo`, token,userDomain, seoData),
    seoAddon: () => GET(`website-seo`, token, userDomain),
    optimonkAddon: () => GET(`addons/optimonk`, token, userDomain),
    createOptimonkAddon: (optimonkData) => POST(`addons/optimonk`, token, userDomain, optimonkData),
    updateOptimonkAddon: (optimonkData) => PUT(`addons/optimonk`, token, userDomain, optimonkData),
    dynamicFieldsAddon: () => GET(`addons/dynamicproductfield`, token, userDomain),
    createDynamicFieldsAddon: (gaData) => POST(`addons/dynamicproductfield`, token, userDomain, gaData),
    updateDynamicFieldsAddon: (gaData) => PUT(`addons/dynamicproductfield`, token, userDomain, gaData),
    smtpAddon: () => GET(`addons/smtp`, token, userDomain),
    createSmtpAddon: (smtpData) => POST(`addons/smtp`, token, userDomain, smtpData),
    updateSmtpAddon: (smtpData) => PUT(`addons/smtp`, token, userDomain, smtpData),
    tawkAddon: () => GET(`addons/tawk`, token, userDomain),
    createTawkAddon: (tawkData) => POST(`addons/tawk`, token, userDomain, tawkData),
    updateTawkAddon: (tawkData) => PUT(`addons/tawk`, token, userDomain, tawkData),
    PostScheduledOrderAddon: (scheduleOrderData) => POST(`addons/schedulestatus`, token, userDomain, scheduleOrderData),
    getScheduledOrderAddon: () => GET(`addons/schedulestatus`, token, userDomain),
    selfDeliveryAddon: () => GET(`addons/selfdelivery`, token, userDomain),
    createSelfDeliveryAddon: (selfDeliveryData) => POST(`addons/selfdelivery`, token, userDomain, selfDeliveryData),
    updateSelfDeliveryAddon: (selfDeliveryData) => PUT(`addons/selfdelivery`, token, userDomain, selfDeliveryData),
    PostDigitalProductAddon: (digitalProductData) => POST(`addons/digitalproduct`, token, userDomain, digitalProductData),
    getDigitalProductAddon: () => GET(`addons/digitalproduct`, token, userDomain),
    getFestival:()=>GET('addons/festival',token,userDomain),
    PostFestivalAddon: (festivalData) => POST(`addons/festival`, token, userDomain, festivalData),
    dynamicStatus: (dynamicstatusData) => PUT(`dynamic-order-status`, token, userDomain, dynamicstatusData),
    getDynamicStatus: () => GET(`dynamic-order-status`, token, userDomain),
    getAPK : () => GET('addons/getapk',token,userDomain),
    // complementary addons
    complementaryProductAddon: () => GET(`addons/complementary`, token, userDomain),
    postComplementaryProductAddon: (complementaryData) => POST(`addons/complementary`, token, userDomain, complementaryData),
    putComplementaryProductAddon: (complementaryData) => PUT(`addons/complementary`, token, userDomain, complementaryData),

    // ondc addons
    ondcAddon: () => GET(`addons/ondc`, token, userDomain),
    postOndcAddon: (ondcData) => POST(`addons/ondc`, token, userDomain, ondcData),
    putOndcAddon: (ondcData) => PUT(`addons/ondc`, token, userDomain, ondcData),

  timeslot:()=>GET('config-time-slot',token,userDomain),
  updateTimeslot:(time)=>PUT('config-time-slot',token,userDomain,time),
  postTimeslot:(time)=>POST('config-time-slot',token,userDomain,time),
  deleteTimeslot:(id)=>DELETE(`config-time-slot/${id}`,token,userDomain),

    // Store Maintenance
    getStoreStatus: ()=> GET(`store-settings/status`,token,userDomain),
    setStoreStatus: (storeStatusData)=> POST(`store-settings/status`,token,userDomain,storeStatusData) ,
    
    // subscriptions api
    subscriptionInfo: () => GET(`subscriptions/info`, token, userDomain),
    subscriptionCouponValidate: (couponCode) => GET(`subscriptions/coupons/${couponCode}`, token, userDomain),
    createSubscription: (subscriptionData) => POST(`subscriptions/create`, token, userDomain, subscriptionData),
    paySubscription: (subscriptionPayData) => POST(`subscriptions/pay`, token, userDomain, subscriptionPayData),
    confirmSubscription: (subscription_id, couponCode) => GET(`subscriptions/confirm?subscription_id=${subscription_id}&coupon=${couponCode}`, token, userDomain),

    theme: () => GET(`theme`, token, userDomain),
    updateTheme: (themeData) => PUT(`theme`, token, userDomain, themeData),
    appTheme: () => GET(`theme-app`, token, userDomain),
    updateAppTheme: (appThemeData) => PUT(`theme-app`, token, userDomain, appThemeData),

     //Manual bill Api
     getManualBills :() => GET(`manualbilling`,token,userDomain),

    uploadImage: (file, type, fileName = undefined) => {
      if(!file) return "";
      const form = new FormData();
      if(fileName){
        form.append("file", file, fileName);
      }else{
        form.append("file", file);
      }
      return POST(`upload/${type}`, token, userDomain, form)
    },
    uploadDigitalProduct: (file, type, fileName = undefined) => {
      if(!file) return "";
      const form = new FormData();
      if(fileName){
        form.append("file", file, fileName);
      }else{
        form.append("file", file);
      }
      return POST(`upload-digital-product-file`, token, userDomain, form)
    }
  }
}

export default useAPI;
