import { IonAccordion, IonCard, IonItem, IonLabel } from '@ionic/react';
import { useWebsiteSettings } from '../../../../../utils/contexts';

const Header = () => {
  const settings:any = useWebsiteSettings();
  return (
    <IonCard>
      <IonAccordion>
          <IonItem slot="header" color='light'>
              <IonLabel>Header</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            Header Content
          </div>
        </IonAccordion>
      </IonCard>
  )
}

export default Header