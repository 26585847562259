import { IonAccordion, IonButton, IonCard, IonIcon, IonImg, IonItem, IonLabel, IonReorder, IonToggle, useIonToast } from '@ionic/react';
import { createSharp as createSharpIcon, trashSharp as trashSharpIcon } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import useAPI from '../../../../../utils/api';
import { useWebsiteSettings } from '../../../../../utils/contexts';
import UpdatePosterModal from '../../components/modal/UpdatePosterModal';

const Poster = ({data}) => {
  const settings:any = useWebsiteSettings();
  const API = useAPI();
  const [present] = useIonToast();
  const [poster, setPoster] = useState<any>();
  const [modalData, setModalData] = useState({
    open: false,
    data: null
  })

  useEffect(() => {
    const posterInfo = settings?.websiteSettings?.poster?.poster_info;
    if(posterInfo){
      setPoster(posterInfo);
    } else {
      setPoster(null);
    }
  }, [settings, settings.update]);

  async function deleteBanner(id)
  {
    try{
      const [ok, response] = await API.deletePoster(id);
      if(ok && response){
        const [okay, resData] = await API.theme();
        if(okay){
          const [postOk, postRes] = await API.updateTheme({...resData?.data, poster: {...resData?.data?.poster, poster_info: null}});
          if(postOk){
            present("Poster Deleted successfully!", 1000);
            settings.setUpdate(!settings.update);
          }

        }

      }

    }
    catch(err){
      present("An error occurred while deleting the poster.", 2000);
      console.error('Error in delete Poster:', err);

    }
  }
  return (<>
    <UpdatePosterModal modalData={modalData} setModalData={setModalData}></UpdatePosterModal>
    <IonCard>
      <IonAccordion>
        <IonItem slot="header" color='light'>
          <IonLabel>Poster</IonLabel>
          {data.reorder && <IonReorder slot="end"/>}
          {data.toggle && <IonToggle checked={settings.websiteSettings.poster.display === 1} color="medium" onIonChange={e => settings.setWebsiteSettings({...settings.websiteSettings, poster: {...settings.websiteSettings.poster, display: e.detail.checked ? 1 : 0}  })} slot="end"></IonToggle>}
        </IonItem>
        <div className="ion-padding" slot="content">
          {poster ?  <IonItem lines='none' className='ion-margin-bottom' >
            <IonImg src={poster.image} />
            <IonButton onClick={e=> setModalData({...modalData, open: true, data: poster })} slot='end'><IonIcon icon={createSharpIcon}/> </IonButton>
            <IonButton color="danger" slot='end' onClick={e=> deleteBanner(poster.id)}><IonIcon icon={trashSharpIcon}/> </IonButton>

          </IonItem>
            :
            <IonButton onClick={e=> setModalData({...modalData, open: true, data: null })} shape='round' expand='full'>Add Poster</IonButton>
          }

        </div>
      </IonAccordion>
    </IonCard>
  </>
  )
}

export default Poster