import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonPage,
    IonText,
    IonTitle,
    IonToggle,
    IonToolbar,
    useIonToast,
} from "@ionic/react";
import { arrowBack as arrowBackIcon } from "ionicons/icons";
import { useState } from "react";
import useAPI from "../../../utils/api";
import { useAddon } from "../../../utils/contexts";
import { indexedValue } from "../AddonsRoutes";
import FeatHeader from '../../../components/dashboard/FeatHeader';


const ComplementaryProductForm = () => {
    const addons: any = useAddon();
    const [present] = useIonToast();
    const API = useAPI();
    const [statusCheck, setStatusCheck] = useState(
        Array.isArray(addons[indexedValue.complementaryProduct].data)
            ? false
            : addons[indexedValue.complementaryProduct].data?.status ===
                  "active"
    );

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        const [ok, response] = Array.isArray(
            addons[indexedValue.complementaryProduct].data
        )
            ? await API.postComplementaryProductAddon({
                  status: statusCheck ? "active" : "inactive",
              })
            : await API.putComplementaryProductAddon({
                  status: statusCheck ? "active" : "inactive",
                  id: addons[indexedValue.complementaryProduct].data.id,
              });
        if (response.statusCode === 400) {
            setStatusCheck(!statusCheck);
            present(response.message, 1500);
        }
        if (ok) {
            present("Data saved successfully!", 1500);
            addons[indexedValue.complementaryProduct].updateMethod(
                !addons[indexedValue.complementaryProduct].update
            );
        }
    };

    return (
        <IonPage>
            <FeatHeader
                title='Configure Complementary Product'
                defaultHref='/add-ons'
                showInfoButton={false}
            />
            <IonContent className="ion-padding">
                <form onSubmit={onSubmitHandler}>
                    <IonItem lines="none">
                        <IonText>
                            <h5>Enable Complementary Product</h5>
                        </IonText>
                        <IonToggle
                            slot="end"
                            checked={statusCheck}
                            onIonChange={(e) =>
                                setStatusCheck(e.detail.checked)
                            }
                        />
                    </IonItem>
                    <br />
                    <div className="ion-text-center">
                        <IonButton type="submit">Save</IonButton>
                    </div>
                </form>
            </IonContent>
        </IonPage>
    );
};

export default ComplementaryProductForm;
