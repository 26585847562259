import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonIcon,
  IonInput,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  useIonToast
} from "@ionic/react";
import { useState } from "react";
import { formatDate } from "../../../services/formatters";
import useAPI from "../../../utils/api";
import {
  informationCircleOutline as infoIcon,
} from "ionicons/icons";

const DeliveryMessage = ({orderId}) => {
    const deliveryMessage = "Your order will be deliver on";
    const [timeSlot, setTimeSlot] = useState("");
    const [date, setDate] = useState(new Date().toISOString());
    const API = useAPI();
  const [present] = useIonToast();
  
  const openLinkInNewTab = () => {
    window.open(`https://intelikart.tawk.help/article/delivery-message`, '_blank'); 
  };


    const handleDateChange = (e) => {
        const selectedDate = e.detail.value;
        const today = new Date().toISOString().split('T')[0];

        // Check if the selected date is in the future or today
        if (selectedDate >= today) {
            setDate(selectedDate);
        }
    };

    const slotTiming = async () => {
        const finalmessage = `${deliveryMessage} ${formatDate(
            date,
            "DD MMM YYYY, ddd"
        )} ${timeSlot}`;
        const data = { delivery_message: finalmessage };
        const [ok, response] = await API.slotTime(orderId || "", data);
        if(ok){
            present("Message sent successfully!", 1000);
        }
    };

  return <IonGrid>
        <IonText>
          <h5 className="ion-padding-start" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        <b>Delivery Message:</b>
        <h3>
          <IonButtons>
          <IonButton fill='clear' color='secondary' onClick={openLinkInNewTab}>
            <IonIcon icon={infoIcon} slot="icon-only">

            </IonIcon>
          </IonButton>
        </IonButtons>
        </h3>
          </h5>
        </IonText>             
        <IonCard>
          <IonGrid>
            <IonRow
              style={{
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <IonCol sizeXl="5" sizeXs="12">
                <IonInput
                  value={deliveryMessage}
                  readonly
                />
              </IonCol>
              <IonCol sizeXl="2.5" sizeSm="5" sizeXs="12">
                <IonDatetimeButton datetime="date"></IonDatetimeButton>
                <IonModal keepContentsMounted={true}>
                  <IonDatetime
                    id="date"
                    presentation="date"
                    value={date}
                    onIonChange={handleDateChange}
                    min={new Date().toISOString().split('T')[0]}
                  />
                </IonModal>
              </IonCol>
              <IonCol size="12" size-sm="6" size-md="3" size-xl="3">
                <IonSelect
                  placeholder="Select Slot"
                  value={timeSlot}
                  onIonChange={(e) => {
                    setTimeSlot(e.detail.value);
                  }}
                  className="ion-no-padding"
                  style={{
                    backgroundColor: "#edeef0",
                    padding: "6px 8px",
                    borderRadius: "8px",
                    width: "100%",
                  }}
                >
                  <IonSelectOption value="08:00 AM-12:00 PM">
                    08:00 AM-12:00 PM
                  </IonSelectOption>
                  <IonSelectOption value="12:00 PM-04:00 PM">
                    12:00 PM-04:00 PM
                  </IonSelectOption>
                  <IonSelectOption value="04:00 PM-08:00 PM">
                    04:00 PM-08:00 PM
                  </IonSelectOption>
                </IonSelect>
              </IonCol>
              <IonCol size="12" size-sm="6" size-md="3" size-xl="1.5">
                <IonButton expand="full" onClick={slotTiming}>
                  Send
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCard>
        </IonGrid>
}

export default DeliveryMessage