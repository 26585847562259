import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonText, IonTitle, IonToggle, IonToolbar, useIonToast } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import {
    arrowBack as arrowBackIcon,
    informationCircleOutline
} from 'ionicons/icons';
import { useAddon } from '../../../utils/contexts';
import useAPI from '../../../utils/api';
import { indexedValue } from '../AddonsRoutes';
import FeatHeader from '../../../components/dashboard/FeatHeader';


const ShiprocketForm = () => {
    const addons: any = useAddon();
    const [present] = useIonToast();
    const API = useAPI();
    const [emailValidation, setEmailValidation] = useState('');
    const [passwordValidation, setPasswordValidation] = useState('');
    const [shiprocketEmail, setShiprocketEmail] = useState(Array.isArray(addons[indexedValue.shiprocket].data) ? '' : addons[indexedValue.shiprocket].data?.shiprocket_email);
    const [shiprocketPassword, setShiprocketPassword] = useState(Array.isArray(addons[indexedValue.shiprocket].data) ? '' : addons[indexedValue.shiprocket].data?.shiprocket_password);
    const [shiprocketCheck, setShiprocketCheck] = useState(Array.isArray(addons[indexedValue.shiprocket].data) ? false : addons[indexedValue.shiprocket].data?.status === 'active');

    const validation = () => {
        let result = true;
        if (!shiprocketEmail) {
            setEmailValidation('Shiprocket email is required');
            result = false;
        } else {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(shiprocketEmail)) {
                setEmailValidation('Shiprocket email is invalid');
                result = false;
            } else {
                setEmailValidation('');
            }
        }

        if (!shiprocketPassword) {
            setPasswordValidation('Shiprocket Password is required');
            result = false;
        } else {
            setPasswordValidation('');
        }
        return result;
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();

        if (validation()) {
            const [ok, response] = Array.isArray(addons[indexedValue.shiprocket].data) ?
                await API.createShiprocketAddon({ "shiprocket_email": shiprocketEmail, "shiprocket_password": shiprocketPassword, "status": shiprocketCheck ? "active" : "inactive" })
                :
                await API.updateShiprocketAddon({ "shiprocket_email": shiprocketEmail, "shiprocket_password": shiprocketPassword, "status": shiprocketCheck ? "active" : "inactive", id: addons[indexedValue.shiprocket].data.id });
            if (ok) {
                present("Data saved successfully!", 1000);
                addons[indexedValue.shiprocket].updateMethod(!addons[indexedValue.shiprocket].update);
            }
        }
    }

    const openLinkInNewTab = () => {
        window.open(`https://intelikart.tawk.help/article/shiprocket`, '_blank');
    };

    return (<IonPage>
        
        <FeatHeader
            title='Configure Shiprocket'
            defaultHref="/addons"
            showInfoButton={true}
            onInfoClick={openLinkInNewTab}
        />
        <IonContent className="ion-padding">
            <form onSubmit={onSubmitHandler}>
                <IonItem>
                    <IonLabel position="stacked"><h1>Shiprocket Email</h1></IonLabel>
                    <IonInput placeholder="Enter Shiprocket email" type="email" value={shiprocketEmail} onIonChange={(e) => { setShiprocketEmail(e.detail.value) }} onIonBlur={e => validation()} />
                </IonItem>
                <IonText color="danger" className={`${emailValidation ? "" : "ion-hide"}`}>
                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px', marginBottom: 0 + 'px' }} className='ion-margin-horizontal'>{emailValidation}</h5>
                </IonText>
                <br />
                <IonItem>
                    <IonLabel position="stacked"><h1>Shiprocket Password</h1></IonLabel>
                    <IonInput placeholder="Enter Shiprocket password" type="password" value={shiprocketPassword} onIonChange={(e) => { setShiprocketPassword(e.detail.value) }} onIonBlur={e => shiprocketPassword ? setPasswordValidation("") : setPasswordValidation("Shiprocket password is required.")} />
                </IonItem>
                <IonText color="danger" className={`${passwordValidation ? "" : "ion-hide"}`}>
                    <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px', marginBottom: 0 + 'px' }} className='ion-margin-horizontal'>{passwordValidation}</h5>
                </IonText>
                <br />
                <IonItem lines="none">
                    <IonText><h5>Enable Shiprocket</h5></IonText>
                    <IonToggle slot="end" checked={shiprocketCheck} onIonChange={e => setShiprocketCheck(e.detail.checked)} />
                </IonItem>
                <br />
                <div className="ion-text-center">
                    <IonButton type="submit">Save</IonButton>
                </div>
            </form>
            {/* <IonLoading isOpen={loadingShiprocketRecords} message="Please Wait"></IonLoading> */}
        </IonContent>
    </IonPage>
    )
}

export default ShiprocketForm