import React, { useEffect, useState } from 'react';
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonLoading, IonMenu, IonPage, IonRow, IonText, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import {
    arrowForwardCircle as arrowForwardCircleIcon,
    addOutline as addOutlineIcon,
    arrowBack as arrowBackIcon
} from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getAccessToken } from '../../../services/auth/userLocalStorage';
import { getStoresCategory } from '../../../actions/categoryAction';

const PreDefineProductList = (props : any) => {
    const [selectedCategory, setSelectedCategory] = useState<any>();
    let propsCatId;
    const { currentUser } = useSelector((state : any)=> state.currentUser);
    const { fetchCategoriesLoading, categories, fetchCategoriesError } = useSelector((state : any)=> state.categories);

    if(props && props.location && props.location.state){
        propsCatId = props.location.state;
    }

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if(props && props.location && props.location.state){
            setSelectedCategory(props.location.state);
        }
    }, [propsCatId]);

    useEffect(()=>{
        if(!categories && getAccessToken() && currentUser && currentUser.data){
            dispatch(getStoresCategory(getAccessToken(), currentUser.data.store.subdomain));
        }
    },[currentUser]);

    useEffect(() => {
        if(fetchCategoriesLoading === false && categories.length !== 0 && categories[0].subCategory && categories[0].subCategory.length !== 0 && props && props.location && !props.location.state){
            setSelectedCategory({
                name : `${categories[0].subCategory[0].name}`,
                id: `${categories[0].subCategory[0].id}`
            });
        }
    }, [fetchCategoriesLoading]);

    const changeCategory = (category)=>{
        setSelectedCategory({
            name: category.name,
            id: category.id
        });
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonIcon size="large" onClick={e=> history.goBack()} icon={arrowBackIcon}/>
                    </IonButtons>
                    <IonTitle>{selectedCategory && selectedCategory.name ? selectedCategory.name : 'Product Lists'}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol size="3">
                            <IonList>
                            { fetchCategoriesLoading === false && categories.length !== 0 ? categories.map((parentCategory)=>{
                            return <>
                                <IonItem>
                                    <IonLabel>{parentCategory.name}</IonLabel>
                                </IonItem>
                                {parentCategory.subCategory.map((childCategory)=>{
                                    return <IonItem lines='none' className='ion-margin-start' onClick={e=>changeCategory(childCategory)}>
                                        <IonLabel color={selectedCategory && selectedCategory.name === childCategory.name ? 'primary' : ''}><h5>{childCategory.name}</h5></IonLabel>
                                    </IonItem>
                                    })}
                            </>
                            }): ''} 
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                
                <IonLoading isOpen={fetchCategoriesLoading === true } message="Please Wait"/>
            </IonContent>
        </IonPage>
    )
}

export default PreDefineProductList
