import { IonButton, IonCard, IonCardContent, IonContent, IonInput, IonItem, IonLoading, IonPage, IonText, useIonToast } from '@ionic/react';
import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Header from '../../components/dashboard/Header';
import useAPI from '../../utils/api';
import useFetch from '../../utils/useFetch';


const Announcements = props => {
    const [present] = useIonToast();
    const {loading, data, status} = useFetch("announcements");
    const API = useAPI();
    const [announcement, setAnnouncement] = useState<string | undefined>("");
  
    const sendAnnouncement = async()=>{
        const [ok, response] = await API.createAnnouncement({
            text: `${announcement}`
        });

        if(ok) {
            present("Announcement will be sent to all of your app customers in next 10 minutes.", 1500);
        }
    }
    return (
      <IonPage>
      <div className="ion-page" id="main-content">
        <Header name="Announcements"/>
        <IonContent className="ion-padding">
        <IonText className="ion-text-center">
            <h5><strong>Make exciting announcements</strong></h5>
            <p>Will be sent as push notification to your customers</p>
        </IonText>
        <IonItem>
            <IonInput type="text" placeholder="Type your message here or select from the suggestions below." onIonChange={e=> setAnnouncement(e.detail.value)} value={announcement}/>
        </IonItem>
        <br/>
        <Carousel autoPlay={true} showArrows={false} showStatus={false}>
          {!loading && status === "fetched" && data.data.map((elem, index) => (
            <IonCard onClick={() => setAnnouncement(elem)}>
              <IonCardContent>
                <IonText>{elem}</IonText>
              </IonCardContent>
            </IonCard>
          ))}
        </Carousel>
        <br/>
        <div className="ion-text-center">
            <IonButton type="button" shape="round" onClick={sendAnnouncement}>Send</IonButton>
        </div>
        <IonLoading isOpen={loading} message="Please Wait"></IonLoading>
        </IonContent>
      </div>
    </IonPage>
)}

export default Announcements