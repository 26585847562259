import { IonAccordion, IonButton, IonCard, IonCol, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonReorder, IonToggle, useIonToast } from '@ionic/react';
import {trashSharp as trashSharpIcon } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import useAPI from '../../../../../utils/api';
import { useWebsiteSettings } from '../../../../../utils/contexts';
import StickyCategoryModal from '../../components/modal/StickyCategoryModal';

const StickyCategory = ({ data }) => {
    const settings: any = useWebsiteSettings();
    const API = useAPI();
    const [present] = useIonToast();
    const [stickyCategory, setStickyCategory] = useState<any>([]);

    const [modalData, setModalData] = useState({
        open: false,
        data: null
    })

    useEffect(() => {
        let slides = settings?.websiteSettings?.sticky_category_sliders?.slides;
        if (slides) {
            setStickyCategory(slides);
        }
    }, [settings]);

    async function deletesticky(id) {
        try {
            const [ok, response] = await API.deleteStickyCategory(id);
            if (ok && response) {
                const [okay, resData] = await API.theme();
                if (okay) {
                    let updatedSticky = resData?.data?.sticky_category_sliders.slides;
                    const [postOk, postRes] = await API.updateTheme({ ...resData?.data, sticky_category_sliders: { ...resData?.data?.sticky_category_sliders, slides: updatedSticky } });
                    if (postOk) {
                        present("Sticky Category Deleted successfully!", 1000);
                        settings.setUpdate(!settings.update);
                    }
                }
            } else {
                if (response && response.error) {
                    alert(response.error);
                }
            }
        }
        catch (err) {
        }
    }
    return (<>
        <StickyCategoryModal modalData={modalData} setModalData={setModalData}></StickyCategoryModal>
        <IonCard>
            <IonAccordion>
                <IonItem slot="header" color='light'>
                    <IonLabel>Sticky Category</IonLabel>
                    {data.reorder && <IonReorder slot="end"/>}

                    {data.toggle && <IonToggle checked={settings.websiteSettings.sticky_category_sliders.display === 1} color="medium" onIonChange={e => settings.setWebsiteSettings({ ...settings.websiteSettings, sticky_category_sliders: { ...settings.websiteSettings.sticky_category_sliders, display: e.detail.checked ? 1 : 0 } })} slot="end"></IonToggle>}
                </IonItem>
                <div className="ion-padding" slot="content">
                    <IonItem>
                        <IonLabel position="stacked">Heading</IonLabel>
                        <IonInput
                            placeholder="Write heading here."
                            type="text"
                            value={settings.websiteSettings.sticky_category_sliders.component_heading}
                            onIonChange={e => settings.setWebsiteSettings({ ...settings.websiteSettings, sticky_category_sliders: { ...settings.websiteSettings.sticky_category_sliders, component_heading: e.detail.value } })}
                        />
                    </IonItem>
                </div>

                <div className="ion-padding" slot="content">
                    <IonLabel>Category List</IonLabel>
                    {stickyCategory?.length > 0 && stickyCategory.map((id) => <IonItem lines='none' className='ion-margin-bottom' key={id}>
                        <IonCol size='3'>
                            <IonImg src={id.icon} />
                        </IonCol>

                        <IonCol size="9" style={{ paddingLeft: "10px" }}>
                            {id.name}
                        </IonCol>
                        <IonButton color="danger" slot='end' onClick={e => { deletesticky(id.id) }} ><IonIcon icon={trashSharpIcon} /></IonButton>
                    </IonItem>
                    )}
                    <IonButton onClick={e => setModalData({ ...modalData, open: true, data: null })} shape='round' expand='full'>Add Category</IonButton>
                </div>
            </IonAccordion>
        </IonCard>
    </>
    )
}

export default StickyCategory