import { IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLoading, IonPage, IonRow, IonText, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import {
    addOutline as addOutlineIcon,
    arrowBack as arrowBackIcon,
    arrowForwardCircle as arrowForwardCircleIcon
} from 'ionicons/icons';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getStoresCategory } from '../../../actions/categoryAction';
import { getAccessToken } from '../../../services/auth/userLocalStorage';

const ChooseCategory = () => {
    const { currentUser } = useSelector((state : any)=> state.currentUser);
    const { fetchCategoriesLoading, categories, fetchCategoriesError } = useSelector((state : any)=> state.categories);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(()=>{
        if(!categories && getAccessToken() && currentUser && currentUser.data){
            dispatch(getStoresCategory(getAccessToken(), currentUser.data.store.subdomain));
        }
    },[currentUser]);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonIcon size="large" onClick={e=> history.goBack()} icon={arrowBackIcon}/>
                    </IonButtons>
                    <IonTitle>Choose Category</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                { fetchCategoriesLoading === false && categories.length !== 0 ? categories.map((parentCategory)=>{
                    return <>
                        <IonText><h3 className='ion-margin-start'>{parentCategory.name}</h3></IonText>
                        {parentCategory.subCategory.length !== 0 ? <IonGrid>
                            <IonRow className='ion-align-items-stretch'>
                                {parentCategory.subCategory.map((childCategory)=>{
                                    return <IonCol sizeXs='12' sizeSm='6' sizeMd='4' key={childCategory.id}>
                                        <Link to={{pathname: '/catalogue/products/addreadymadeproduct/productlist', state:{name: childCategory.name, id: childCategory.id}}} style={{textDecoration: 'none'}}>
                                        <IonCard style={{height: 100+'%'}}>
                                            <IonCardContent style={{height: 100+'%'}} className='ion-no-padding'>
                                                <IonItem lines='none'>
                                                    <IonGrid>
                                                        <IonRow>
                                                    <IonText><h4>{childCategory.name}</h4></IonText>
                                                    </IonRow>
                                                    <IonRow className='ion-margin-top'>
                                                    <IonIcon size='large' color='primary' icon={arrowForwardCircleIcon}></IonIcon>
                                                    </IonRow>
                                                    </IonGrid>
                                                    <IonThumbnail slot="end">
                                                        <IonImg src={childCategory.icon_code_internet_url} />
                                                    </IonThumbnail>
                                                </IonItem>
                                            
                                            </IonCardContent>
                                        </IonCard>
                                        </Link>
                                    </IonCol>
                                })}
                            </IonRow>
                        </IonGrid> : 
                        <>
                        <IonCard>
                            <IonText><h5 className='ion-margin-start'>No subcategory found in this category.</h5></IonText>
                            <IonItem lines='none'>
                                <IonButton color='primary' slot='end' size="small" onClick={()=>history.push(`/catalogue/category/add-subcat/${parentCategory.id}/${parentCategory.name}/false`)} >
                                    <IonIcon  icon={addOutlineIcon}/>
                                    Add SubCategory
                                </IonButton>
                            </IonItem>
                        </IonCard>
                        </>
                        }
                        </>
                })
                : ''} 
                <IonLoading isOpen={fetchCategoriesLoading === true } message="Please Wait"/>
            </IonContent>
        </IonPage>
    )
}

export default ChooseCategory
