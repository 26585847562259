import { IonAlert, IonBadge, IonToast,IonButton, IonCard, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonRow, IonText, IonThumbnail, isPlatform, useIonToast } from '@ionic/react';
import { add, addCircle as addCircleIcon, add as addIcon, checkmarkSharp as checkmarkSharpIcon, closeCircleOutline, closeOutline, createSharp as createSharpIcon, search as serachIcon, toggle, trashSharp as trashSharpIcon } from 'ionicons/icons';
import { useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import ReactPaginate from 'react-paginate';
import useAPI from "../../../../../utils/api";
import { useCategory, useWebsiteSettings } from "../../../../../utils/contexts";
import { StoreCurrency } from "../../../../../utils/currency";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAccessToken } from "../../../../../services/auth/userLocalStorage";
import { getStoresCategory } from "../../../../../actions/categoryAction";
import { getProductsList } from "../../../../../actions/dashboardActions";
import { deleteProduct } from "../../../../../actions/productActions";
import { Link } from "react-router-dom";

const StickyProductModal = ({modalData, setModalData,productID}) => {
    // const settings:any = useWebsiteSettings();
    const [posterDetails, setPosterDetails] = useState<any>();
    const [showSelectCateModal, setShowSelectCateModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const currency = StoreCurrency();
    const updateCategoryData: any = useCategory();
    const location: any = useLocation();
    const API = useAPI();
    const settings:any = useWebsiteSettings();
    const [present] = useIonToast();

    const [totalProducts, setTotalProducts] = useState({});
    //states for search product by name as query
    const [query, setQuery] = useState<any>("");
    const [searchResult, setSearchResult] = useState({
        categoryList: [],
        productList: []
    });
    //states for displaying initial products and load more products.
    const[filterArrayObject,setFilterArrayObject]=useState({});
    const [subcategoryData, setSubcategoryData] = useState<any>({});

    //states for product short info
    const [checkedProducts, setCheckedProducts] = useState<any>([]);
    const [showAlertForMulti, setShowAlertForMulti] = useState(false);
    const [showAlertForSingle, setShowAlertForSingle] = useState(false);
    const [showAlertForStatus, setShowAlertForStatus] = useState(false);
    const [deletedProductId, setDeletedProductId] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [isAnyProductChange, setIsAnyProductChange] = useState(true);
    const [selectAll, setSelectAll] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("")

   

    // var idFromProps;
    // var cateIdFromProps;
    // if(props && props.location && props.location.state){
    //     idFromProps = props.location.state;
    //     if(idFromProps.id){
    //         cateIdFromProps = idFromProps.id;
    //     }
    // }

    const { currentUser } = useSelector((state : any)=> state.currentUser);
    const { fetchCategoriesLoading, categories, fetchCategoriesError } = useSelector((state : any)=> state.categories);

    const { productsBySubcategoryLoading, productsBySubcategory, productsBySubcategoryError } = useSelector((state : any)=> state.productListBySubcategory);

    const {deleteProductLoading} = useSelector((state : any)=> state.deleteProductData);

    const { createProductLoading, createProductMessage, createProductError } = useSelector((state : any)=> state.createProducData);

    const { editProductLoading, editProductMessage, editProductError } = useSelector((state : any)=> state.editProductData);

    const { bulkEditLoading, bulkEditMessage, bulkEditError } = useSelector((state : any)=> state.bulkEditData);

    const { addProductInBulkLoading, addProductInBulkMessage, addProductInBulkError } = useSelector((state : any)=> state.addProductInBulkData);

    // const {searchProductQueryLoading, searchProductQueryMessage, searchProductQueryError} = useSelector((state : any)=> state.searchQueryProductData);

    const dispatch = useDispatch();
    const history = useHistory();

    //youtube option
    const opts = {
        height: '390',
        width: '100%'
    };

    //for reloading when new product is added
    useEffect(() => {
        if(updateCategoryData.updatedCategoryId?.id){
            let temp = {...filterArrayObject};
            delete temp[`${updateCategoryData.updatedCategoryId?.id}`]
            if(updateCategoryData.updatedCategoryId?.id !== updateCategoryData.updatedCategoryId?.newId){
                delete temp[`${updateCategoryData.updatedCategoryId?.newId}`]
            }
            setFilterArrayObject(temp);
            setIsAnyProductChange(!isAnyProductChange)
        }
    }, [updateCategoryData.updatedCategoryId?.id, updateCategoryData.updatedCategoryId?.refresh]);

    // //for reloading when any product is edited
    // useEffect(() => {
    //     if(Boolean(editProductLoading === false && editProductMessage?.category_id)){
    //         let temp = {...filterArrayObject}
    //         //temp[`${editProductMessage[0].category_id}`] = []
    //         delete temp[`${editProductMessage?.category_id}`]
    //         setFilterArrayObject(temp);
    //         setIsAnyProductChange(!isAnyProductChange)
    //     }
    // }, [editProductLoading]);

    //for reloading when any product is edited in bulk
    useEffect(() => {
        if(Boolean(bulkEditLoading === false)){
            setIsAnyProductChange(!isAnyProductChange)
        }
    }, [bulkEditLoading]);

    useEffect(() => {
        if(!query) {
            if(fetchCategoriesLoading === false && categories[0] && categories[0].subCategory){
                if(categories[0].subCategory[0]){
                    setSubcategoryData({
                        ...categories[0].subCategory[0],
                        parentCategory: categories[0].id
                    });
                }else{
                    const data = categories.filter((cat)=>{ return cat.subCategory.length > 0});
                    if(data && data.length !== 0){
                        setSubcategoryData({
                            ...data[0].subCategory[0],
                            parentCategory: data[0].id
                        });
                    }
                }
            }
        }
    }, [fetchCategoriesLoading, query]);

    useEffect(()=>{
        if(!categories && getAccessToken() && currentUser && currentUser.data){
            dispatch(getStoresCategory(getAccessToken(), currentUser.data.store.subdomain));
        }
        if(getAccessToken() && currentUser && currentUser.data && addProductInBulkLoading === false){
            dispatch(getStoresCategory(getAccessToken(), currentUser.data.store.subdomain));
        }
    },[currentUser, addProductInBulkLoading]);

    //dynamic content
    //when subcategory change then get their product for first time.
    useEffect(() => {
        if(currentUser){
            setCurrentPage(0);
           
            setSelectAll(false);
            if(!!subcategoryData.id && !!currentUser.data && !query && !(subcategoryData.id in filterArrayObject)){
                dispatch(getProductsList(subcategoryData.id, currentUser.data.store.subdomain));
            }
            if(productID) {
                stickyproduct();        
                    }
                    else{
                        setCheckedProducts([]);
                    }
        }
    }, [subcategoryData, currentUser]);

    useEffect(() => {
        if(currentUser){
            setCurrentPage(0);
           
            setSelectAll(false);
            if(!!subcategoryData.id && !!currentUser.data && !query && !(subcategoryData.id in filterArrayObject) ){
                dispatch(getProductsList(subcategoryData.id, currentUser.data.store.subdomain));
            }
            if(!!subcategoryData.id && !!currentUser.data && query ){
                // searchProductByName({detail: {value: query} })
            }
            if(productID) {
                stickyproduct();        
                    }
                    else{
                        setCheckedProducts([]);
                    }
        }
    }, [isAnyProductChange]);

    useEffect(()=>{
        if(productsBySubcategoryLoading === false && productsBySubcategory && productsBySubcategory.data && productsBySubcategory.meta){
            if(!totalProducts[subcategoryData.id]){
                let totalTemp = {...totalProducts}
                totalTemp[subcategoryData.id]=productsBySubcategory.meta.pagination.total
                setTotalProducts(totalTemp)
            }
            if(productsBySubcategory.data.length > 0){
                let filterArrayTemp = {...filterArrayObject}
                const op = productsBySubcategory.data.reduce(function(acc, curr){
                    if(!acc[subcategoryData.id]){
                        acc[subcategoryData.id]=[]
                    }
                    acc[subcategoryData.id].push(curr)
                    return acc;
                },filterArrayTemp)
                setFilterArrayObject(op);
            }
            if(productsBySubcategory.data.length === 0){
                let temp = {...filterArrayObject};
                delete temp[subcategoryData.id];
                setFilterArrayObject(temp);
            }
        }
    },[productsBySubcategoryLoading])

    // useEffect(() => {
    //     if(idFromProps && props.location.state){
    //         setSubcategoryData({
    //             id: idFromProps.id,
    //             name: idFromProps.name
    //         });
    //     }
    // }, [idFromProps, cateIdFromProps])

    useEffect(()=>{
        if(productID) {
         stickyproduct();
            console.log("pppppppp")
         }
        
     },[productID])
     async function stickyproduct(){
        
         await setCheckedProducts(productID)
      
        }
       
 
     const handelstickyproduct = async() => {
         let data={"product_id":checkedProducts};
         const [ok, response] = await API.updateStickyProduct(data);
 
         if(ok && response){
               present("Sticky Product Added successful!", 1000);
 
               setModalData({...modalData, open: false,});
               settings.setUpdate(!settings.update);
             }
         }  
     

    const handlePageClick = async (pageNumber)=>{
        setCurrentPage(pageNumber)
        setLoading(true);
        if((subcategoryData.id in filterArrayObject) && filterArrayObject[`${subcategoryData.id}`].length <= (pageNumber * 20)){
            let response = await fetch(`https://api2.intelikart.in/search/products?page=${pageNumber + 1}&categoryId=${subcategoryData.id}&includeall=true`, {
                method: 'GET',
                headers: {
                    "content-type": "application/json",
                    'authorization': `Bearer ${getAccessToken()}`,
                    'x-ik-domain': `${currentUser.data.store.subdomain}`
                }
            });

            response.json().then(async (res) => {
                if(res && res.data && res.data.length > 0) {
                    let filterArrayTemp = {...filterArrayObject}
                    const op = res.data.reduce(function(acc,curr){
                        if(!acc[subcategoryData.id]){
                            acc[subcategoryData.id]=[]
                        }
                        acc[subcategoryData.id].push(curr)
                        return acc;
                    }, filterArrayTemp);
                    setFilterArrayObject(op);
                }
            }).catch(err => console.error(err));
        }
        setLoading(false);
    }

    const handelChecked = async(productId) =>{
        if(checkedProducts.includes(productId)){
            let temp = [...checkedProducts];
            temp = temp.filter(elem => productId !== elem)
            await setCheckedProducts(temp);
            setSelectAll(false);
        }
        else{
            let temp = [...checkedProducts];
            temp.push(productId);
            await setCheckedProducts(temp);

            if(temp.length === filterArrayObject[subcategoryData.id].length){
                setSelectAll(true);
            }
        }
    }

    const handelSelectAll = async() => {
        setSelectAll(!selectAll);
        if(!selectAll === true){
            let temp = [];
            filterArrayObject[subcategoryData.id].map(x => {
                temp.push(x.id)
            })
            await setCheckedProducts(temp)
        }
        else{
            let temp = [];
            await setCheckedProducts(temp);
        }
    }

    

     
 

   
 
    
    return (
         <IonModal isOpen={modalData.open} style={{ '--width': '90%', '--height': '95%' }}>
            <IonHeader>
                <IonItem lines="none" className="ion-justify-content-center ion-align-items-center" >
                <IonText className="ion-text-center">
                  <h5 className="ion-no-margin"><strong>Manage your Sticky products</strong></h5>
                  </IonText>
                    <IonButton fill="clear" size="large" slot="end" onClick={(e) => setModalData({ ...modalData, open: false })}>
                        <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                    </IonButton>
                </IonItem>
            </IonHeader>
           
            <IonContent>
                <div style={{'backgroundColor': '#F5F7FB'}}>
                <br/>
                
                    {/* <IonText className="ion-text-center">
                        <h5 className="ion-no-margin"><strong>Manage your Sticky products</strong></h5>
                        <IonButton fill="clear" size="large" slot="right" onClick={(e) => setModalData({ ...modalData, open: false })}>
                        <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                    </IonButton>
                    </IonText>
                    */}

                    {fetchCategoriesLoading === false && categories.length !== 0 && subcategoryData.id && subcategoryData.name && <>
                        <IonItem lines="none" className="ion-margin-horizontal category-dropdown">
                            <IonLabel position="stacked">Select Subcategory</IonLabel>
                            <div style={{width: 100+'%', display: 'flex', justifyContent: 'space-between'}} onClick={e=> setShowSelectCateModal(true)}>
                                <span style={{width:95+'%'}}>
                                    { subcategoryData && subcategoryData.name && <IonInput value={subcategoryData.name} readonly></IonInput> }
                                </span>
                                <span>&#x25BC;</span>
                            </div>
                        </IonItem>
                        <IonModal isOpen={showSelectCateModal} onDidDismiss={e=>setShowSelectCateModal(false)} >
                            <IonHeader>
                                <IonItem lines="none">
                                    <IonText><h5>Select Subcategory</h5></IonText>
                                    <IonButton fill='clear' size='large'  slot='end' onClick={(e) => setShowSelectCateModal(false)} >
                                        <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                                    </IonButton>
                                </IonItem>
                            </IonHeader>
                            <IonContent className="ion-padding">
                                { (query && !loadingSearch ? searchResult.categoryList : categories)?.map((parentCategory) => {
                                    return <>
                                        <IonItem key={parentCategory.id} style={{cursor:'pointer'}}>
                                            <IonLabel color={subcategoryData.parentCategory === parentCategory.id ? 'primary' : ''}>{parentCategory.name} ({parentCategory.subCategory.length})</IonLabel>
                                        </IonItem>
                                        <IonList>
                                            {parentCategory.subCategory.map((childCategory)=>
                                                {return <IonItem key={childCategory.id} lines="none" className='ion-margin-start'  onClick={e=>{setSubcategoryData({...childCategory, parentCategory: parentCategory.id}); setShowSelectCateModal(false)}} style={{cursor: 'pointer'}}>
                                                    <IonThumbnail slot="start">
                                                        <img src={childCategory.icon_code_internet_url} />
                                                    </IonThumbnail>
                                                    <IonText color={ subcategoryData.name === childCategory.name ? 'primary' : ''}>
                                                        <h6 className='ion-no-margin'>{childCategory.name}</h6>

                                                    </IonText>
                                                    {subcategoryData.name === childCategory.name ? <IonIcon slot="end" color="primary" size="large" icon={checkmarkSharpIcon}/> : ""}
                                                </IonItem> }
                                            )}
                                        </IonList>
                                    </>
                                })}
                            </IonContent>
                        </IonModal>
                    <IonCard style={{backgroundColor: 'white'}}>
                            <IonGrid className='ion-no-padding'>
                                <IonRow>
                                <IonCol size="3" style={{borderRight: '1px solid rgba(0, 0, 0, 0.13)'}} className='side-category-display'>
                                    <IonList style={{marginBottom: 60+'px'}}>
                                        { (query && !loadingSearch ? searchResult.categoryList : categories).map((parentCategory) => {
                                                return <>
                                                    <div key={parentCategory.id} className='ion-padding'>
                                                <IonLabel color={ subcategoryData.parentCategory === parentCategory.id ? 'primary' : ''}>{parentCategory.name} ({parentCategory.subCategory.length})</IonLabel>
                                                    </div>
                                            <IonList style={{borderBottom: '1px solid rgba(0,0,0,0.16)'}}>
                                                {parentCategory.subCategory.map((childCategory)=>
                                                    {return <div key={childCategory.id} className='ion-margin-start ion-padding-horizontal'  onClick={e=>setSubcategoryData({...childCategory, parentCategory: parentCategory.id})} style={{cursor: 'pointer', paddingTop: 10+'px', paddingBottom: 10+'px', backgroundColor: `${subcategoryData.name === childCategory.name ? '#F5F7FB' : 'white'}`}}>
                                                        <IonLabel color={ subcategoryData.name === childCategory.name ? 'primary' : ''}>- {childCategory.name}</IonLabel>
                                                    </div> }
                                                        )}
                                                    </IonList>
                                                </>
                                            })}
                                        </IonList>
                                    </IonCol>
                                    <IonCol>
                                    { (query && !loadingSearch) || (filterArrayObject[subcategoryData.id] && filterArrayObject[subcategoryData.id].length > 0) ? <> { (query && !loadingSearch ? searchResult.productList[subcategoryData.id] : filterArrayObject[subcategoryData.id].slice(currentPage * 20, currentPage * 20 + 20))?.map((product)=> <IonItem key={product.id} className='ion-margin-top ion-margin-bottom'>
                                        <Link to={{ pathname: `/catalogue/products/update/${product.id}`, state: { ...subcategoryData, productData: product} }}>
                                                <IonThumbnail slot="start">
                                                    <img src={product.variants[0].image_url_original} />
                                                </IonThumbnail>
                                            </Link>
                                            <IonText className="ion-margin-start">
                                                <h5 className='ion-no-margin'>{product.name}&nbsp;
                                                    <IonBadge color={`${product.status == "inactive" && "danger"}`} style={{fontSize: "11px", padding: "3px"}}>{product.status == "inactive" && "INACTIVE"}</IonBadge></h5>
                                                <IonText color="medium">({product.variants[0].quantity} {product.variants[0].quantity_unit_name})</IonText>
                                            <br/>
                                            {product.variants[0].mrp != 0 &&  product.variants[0].mrp != null  ?  <h5 className='ion-no-margin' style={{display: 'inline'}}>{currency} {product.variants[0].mrp}&nbsp;</h5>:"" } 
                                            {product.variants[0].display_mrp != 0   && product.variants[0].display_mrp != null ? <del>{currency} {product.variants[0].display_mrp}</del>:""} 
                                            </IonText>
                                            {/* <IonButton onClick={e=> history.push({ pathname: `/catalogue/products/update/${product.id}`, state: {...subcategoryData, productData: product} })} style={{marginLeft: 10+'px'}} slot='end'><IonIcon icon={createSharpIcon}/> </IonButton> */}
                                            {/* <IonButton className="ion-float-end" style={{marginRight: 10+'px'}} onClick={e=>{ setShowAlertForSingle(true); setDeletedProductId(`${product.id}`)}} color="danger" slot='end'><IonIcon icon={trashSharpIcon}/> </IonButton> */}
                                            <IonCheckbox checked={checkedProducts.includes(product.id)} onClick={(e) => handelChecked(product.id)} slot='end'/>
                                        </IonItem>
                                        )}
                                            {!query && productsBySubcategory.meta && productsBySubcategory.meta.pagination && productsBySubcategory.meta.pagination.total_pages > 1 && <IonGrid>
                                                <IonRow className="ion-justify-content-center">
                                                    <IonCol>
                                                <IonText color="primary" className="ion-text-center"><h5>Showing ({currentPage*20 } - {(currentPage+1) * 20 > filterArrayObject[subcategoryData.id].length ? filterArrayObject[subcategoryData.id].length : (currentPage+1) * 20})/{totalProducts[subcategoryData.id]}</h5>
                                                            <ReactPaginate
                                                    previousLabel = "Prev"
                                                    nextLabel = "Next"
                                                    pageCount = {productsBySubcategory.meta.pagination.total_pages}
                                                    onPageChange = {data => handlePageClick(data.selected)}
                                                                containerClassName="pagination-container"
                                                                activeClassName="pagination-button"
                                                            />
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>}

                                        </>
                                    :   <div className='ion-text-center ion-margin-vertical'>
                                            <Link to={{ pathname: `/catalogue/products/create/${true}`, state: {...subcategoryData} }} style={{textDecoration: 'none'}}>
                                                    <IonButton>
                                                    <IonIcon icon={addCircleIcon}/>
                                                        Add Product in {subcategoryData.name}
                                                    </IonButton>
                                                </Link>
                                            </div>
                                        }
                                        <IonCard className='position-bottom ion-text-center ion-no-margin ion-margin-top'>
                                        <Link to={{ pathname: `/catalogue/products/create/${true}`, state: {...subcategoryData} }} style={{textDecoration: 'none', display:'none'}} className='ion-margin'>
                                                <h6 className='ion-no-margin'><span className='category-plus-button'>+</span> Add new product in {subcategoryData.name}</h6>
                                            </Link>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonCard>
                    </>
                    }
                  
                    
                     
                </div>
            <IonLoading isOpen={productsBySubcategoryLoading === true || fetchCategoriesLoading === true || loading === true || deleteProductLoading === true } message="Please Wait"/>
            </IonContent>
           
          <IonRow>
           <IonCol style={{ display: 'flex', justifyContent: 'center' }}>
           <IonButton onClick={e=>handelstickyproduct()}  shape='round'  ><IonIcon icon={add}></IonIcon> &nbsp; Add Sticky Product</IonButton>

           
           </IonCol>
           </IonRow>
        
        
        
       
 
            <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={5000}
        />
        </IonModal>
    )
}
export default StickyProductModal
 