// get all pickup locations
export const GET_PICKUP_REQUEST = 'GET_PICKUP_REQUEST'
export const GET_PICKUP_SUCCESS = 'GET_PICKUP_SUCCESS'
export const GET_PICKUP_FAIL = 'GET_PICKUP_FAIL'

// create order on shiprocket
export const POST_SHIPROCKET_ORDER_REQUEST = 'POST_SHIPROCKET_ORDER_REQUEST'
export const POST_SHIPROCKET_ORDER_SUCCESS = 'POST_SHIPROCKET_ORDER_SUCCESS'
export const POST_SHIPROCKET_ORDER_FAIL = 'POST_SHIPROCKET_ORDER_FAIL'

// create pickup location
export const POST_PICKUP_LOCATION_REQUEST = 'POST_PICKUP_LOCATION_REQUEST'
export const POST_PICKUP_LOCATION_SUCCESS = 'POST_PICKUP_LOCATION_SUCCESS'
export const POST_PICKUP_LOCATION_FAIL = 'POST_PICKUP_LOCATION_FAIL'

// get all shiprocket orders
export const GET_SHIPROCKET_ORDER_REQUEST = 'GET_SHIPROCKET_ORDER_REQUEST'
export const GET_SHIPROCKET_ORDER_SUCCESS = 'GET_SHIPROCKET_ORDER_SUCCESS'
export const GET_SHIPROCKET_ORDER_FAIL = 'GET_SHIPROCKET_ORDER_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'