import { useEffect, useState } from "react";
import {
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonIcon,
  IonCard,
  IonCardContent,
} from "@ionic/react";
import { trashOutline as trashIcon } from "ionicons/icons";
import useAPI from "../../utils/api";
import {  useCustomer, useCart } from "../../utils/contexts";

const CartProducts = ({
  pricingBreakup,
  customer,
  currency,
  productInCart,
  setProductInCart,
}) => {
  const API = useAPI();
  const { customer: customerData, } =
    useCustomer();
  const { myCartData, setMyCartData } = useCart();

  const userId = customerData ? customerData.userId : null;

  const deleteCartProduct = async (id, variant_index) => {
    if (userId) {
      const [ok, response] = await API.deleteProductInCart({
        product_id: id,
        variant_index: variant_index,
        user_id: userId,
      });

      if (ok && response) {
        setMyCartData(response.data);
      } else {
        if (response && response.error) {
          alert(response.error);
        }
      }
    } else {
      const existingProductIndex = productInCart.findIndex(
        (product) =>
          product.id === id && product.variant_index === variant_index
      );

      if (existingProductIndex !== -1) {
        const updatedProductList = [...productInCart];
        updatedProductList.splice(existingProductIndex, 1);
        setProductInCart(updatedProductList);
      }
    }
  };
  const postCartData = async () => {
    if (userId) {
      const [ok, response] = await API.addProductInCart({
        products: productInCart,
        user_id: userId,
      });

      if (ok && response) {
        setMyCartData(response.data);
        setProductInCart([]);
      } else {
        if (response && response.error) {
          alert(response.error);
        }
      }
    }
  };

  useEffect(() => {
    if (customer && productInCart?.length > 0) {
      postCartData();
    } 
  }, [customer]);

  return (
    <>
      {(myCartData?.products?.length > 0 ? myCartData.products : productInCart)
        ?.filter((selectedProduct) => selectedProduct.qty > 0)
        .map((selectedProduct) => (
          <IonItem key={selectedProduct.id} >
          
            <IonGrid>
              <IonRow>
                <IonCol size="1.5">
                  <IonText>{selectedProduct.qty}</IonText>
                </IonCol>
                <IonCol size="7">
                  <IonText>{selectedProduct.name.length <=17?selectedProduct.name:selectedProduct.name.substring(0, 17)+"..."}</IonText>
                </IonCol>
                <IonCol size="3.5">
                  <IonText>
                    {currency}{" "}
                    {selectedProduct.mrp ??
                      selectedProduct.variants[selectedProduct.variant_index]
                        .mrp}
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonIcon
              slot="end"
              icon={trashIcon}
              color="danger"
              style={{ cursor: "pointer" }}
              size="medium"
              onClick={() =>
                deleteCartProduct(
                  selectedProduct.id,
                  selectedProduct.variant_index
                )
              }
            />
          </IonItem>
        ))}

      {pricingBreakup && (
        <IonCard>
          <IonCardContent>
            {pricingBreakup?.taxesCharges[0] && (
              <>
                          
                <div className="table-holder">
                  <table>
                    <tbody>
                      <tr className="totals">
                        <td>Sub Total</td>
                        <td>
                          {currency} {pricingBreakup.subTotal.toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )} 

            {pricingBreakup?.taxesCharges[0] && (
              <>
               
                <div className="table-holder">
                  <h2 className="ion-margin-bottom">
                    <b>Taxes</b>
                  </h2>
                  <table>
                    <tbody>
                      {pricingBreakup?.taxesCharges.map((op, ind) => (
                        <tr key={op.tax_name}>
                          <td>{op.tax_name}</td>
                          <td valign="top">{`${currency} ${op.tax_value_display}`}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {pricingBreakup?.totalPrice && (
              <div className="table-holder">
                <table>
                  <tbody>
                    <tr className="totals">
                      <td>Grand Total</td>
                      <td>
                        {currency}{" "}
                        {parseFloat(pricingBreakup?.totalPrice).toFixed(2)}
                      </td>
                    </tr>                
                  </tbody>
                </table>
              </div>
            )}
          </IonCardContent>
        </IonCard>
      )}
    </>
  );
};

export default CartProducts;