import { Camera, CameraResultType } from "@capacitor/camera";
import {
    IonButton,
    IonCard,
    IonCol,
    IonGrid,
    IonIcon,
    IonRow,
    IonText,
    isPlatform
} from "@ionic/react";
import {
    informationCircleOutline,
    trash as trashIcon
} from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import useAPI from "../../../utils/api";

const DigitalFiles = ({digitalProductPaths, setDigitalProductPaths, digitalValidation, setDigitalValidation, digitalProductUrl, setDigitalProductUrl}) => {
    const API = useAPI();
    const [imageClicked, setImageClicked] = useState<any>();
    const [numOfImages, setNumOfImage] = useState<any>();
    const fileInputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        setNumOfImage(digitalProductPaths.length);
    }, [digitalProductPaths])
    
    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.files.length > 0) {
            const file = event.target.files.item(0);
                setDigitalValidation("");
                setDigitalProductUrl((prev)=> {
                    const myPictureUrl = URL.createObjectURL(file);
                    let temp = [...prev];
                    temp[imageClicked] = "assets/digital.webp";
                    return temp;
                });
                const [ok, response]: any = await API.uploadDigitalProduct(file, "digital-product", file.name);
                if (ok && response?.platform_path) {
                    setDigitalProductPaths((prev)=>{
                        let temp = [...prev];
                        temp[imageClicked] = response?.platform_path;
                        return temp;
                    });
                }
                
        }
        event.target.value = "";
    };

    const handlePictureClick = async (imageIndex) => {
        if (isPlatform("capacitor")) {
            try {
                const photo = await Camera.getPhoto({
                    resultType: CameraResultType.Uri,
                });
                setDigitalProductUrl((prev)=> {
                    let temp = [...prev];
                    temp[imageIndex] = photo.webPath;
                    return temp;
                });
                const file = await fetch(photo.webPath).then((r) => r.blob());
                const [ok, response]: any = await API.uploadImage(file, "product-image", "productImg.jpg");
                if (ok && response?.platform_path) {
                    setDigitalProductPaths((prev)=>{
                        let temp = [...prev];
                        temp[imageIndex] = response?.platform_path;
                        return temp;
                    });
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            fileInputRef.current.click();
        }
    };

    const deleteImage = async (imageNumber) => {
        await setDigitalProductUrl(prev => {
            let temp = [...prev];
            temp.splice(imageNumber, 1);
            temp.push("/assets/upload.svg");
            return temp;
        });
        await setDigitalProductPaths(prev => {
            let temp = [...digitalProductPaths];
            temp.splice(imageNumber, 1);
            return temp;
        });
        console.log(digitalProductUrl);
    };

    return (
        <>
            <IonCard>
            
            <IonText style={{ display: "inline-flex", gap: "12px" }} className="ion-margin-start" color="dark">
                <IonIcon
                    className="ion-margin-right"
                    icon={informationCircleOutline}
                    style={{ fontSize: "22px" }}
                />
                Please use product less than 25 MB
            </IonText>
            <input
                hidden
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
            />
            <IonGrid>
                <IonRow>
                    <IonCol sizeMd="3" sizeXs="6">
                        <IonCard className="product-image-card">
                            <img
                                width="100%"
                                height={
                                    digitalProductUrl[0] === "/assets/upload.svg" ? "100%" : "65%"
                                }
                                src={digitalProductUrl[0]}
                                onClick={(e) => {
                                    handlePictureClick(0);
                                    setImageClicked(0);
                                }}
                            ></img>
                            {digitalProductUrl[0] !== "/assets/upload.svg" && (
                                <IonButton
                                    className="ion-no-margin"
                                    expand="full"
                                    color="danger"
                                    onClick={(e) => deleteImage(0)}
                                >
                                    <IonIcon icon={trashIcon} />
                                </IonButton>
                            )}
                        </IonCard>
                    </IonCol>
                    <IonCol sizeMd="3" sizeXs="6" className={`${numOfImages > 0 ? "" : "ion-hide"}`}>
                        <IonCard className="product-image-card">
                            <img
                                width="100%"
                                height={
                                    digitalProductUrl[1] === "/assets/upload.svg" ? "100%" : "65%"
                                }
                                src={digitalProductUrl[1]}
                                onClick={(e) => {
                                    handlePictureClick(1);
                                    setImageClicked(1);
                                }}
                            ></img>
                            {digitalProductUrl[1] !== "/assets/upload.svg" && (
                                <IonButton
                                    className="ion-no-margin"
                                    expand="full"
                                    color="danger"
                                    onClick={(e) => deleteImage(1)}
                                >
                                    <IonIcon icon={trashIcon} />
                                </IonButton>
                            )}
                        </IonCard>
                    </IonCol>
                    <IonCol sizeMd="3" sizeXs="6" className={`${numOfImages > 1 ? "" : "ion-hide"}`}>
                        <IonCard className="product-image-card">
                            <img
                                width="100%"
                                height={
                                    digitalProductUrl[2] === "/assets/upload.svg" ? "100%" : "65%"
                                }
                                src={digitalProductUrl[2]}
                                onClick={(e) => {
                                    handlePictureClick(2);
                                    setImageClicked(2);
                                }}
                            ></img>
                            {digitalProductUrl[2] !== "/assets/upload.svg" && (
                                <IonButton
                                    className="ion-no-margin"
                                    expand="full"
                                    color="danger"
                                    onClick={(e) => deleteImage(2)}
                                >
                                    <IonIcon icon={trashIcon} />
                                </IonButton>
                            )}
                        </IonCard>
                    </IonCol>
                    <IonCol sizeMd="3" sizeXs="6" className={`${numOfImages > 2 ? "" : "ion-hide"}`}>
                        <IonCard className="product-image-card">
                            <img
                                width="100%"
                                height={
                                    digitalProductUrl[3] === "/assets/upload.svg" ? "100%" : "65%"
                                }
                                src={digitalProductUrl[3]}
                                onClick={(e) => {
                                    handlePictureClick(3);
                                    setImageClicked(3);
                                }}
                            ></img>
                            {digitalProductUrl[3] !== "/assets/upload.svg" && (
                                <IonButton
                                    className="ion-no-margin"
                                    expand="full"
                                    color="danger"
                                    onClick={(e) => deleteImage(3)}
                                >
                                    <IonIcon icon={trashIcon} />
                                </IonButton>
                            )}
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonText
                color="danger"
                className={`${digitalValidation ? "" : "ion-hide"}`}>
                <h5 className="ion-margin-horizontal error-text">
                    {digitalValidation}
                </h5>
            </IonText>
            </IonCard>
        </>
    );
};

export default DigitalFiles;
