import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import {
    IonButton,
    IonContent,
    IonIcon,
    IonLoading,
    IonPage,
    IonText,
    isPlatform
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import LoginFooter from '../components/auth/LoginFooter';
import LogoImage from '../components/auth/LogoImage';
//import { FacebookLogin } from '@rdlabo/capacitor-facebook-login';
import { logoGoogle, mail } from 'ionicons/icons';
import { authenticateUserWhenLogin, getCurrentUser, getUserTokens } from '../actions/authActions';
import { getAccessPermission, getAccessToken, setTokensObject } from '../services/auth/userLocalStorage';

const Home: React.FC = () => {
    const [doReload, setDoReload] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const { userAPITokensLoading, userAPITokens, loadUserAPITokensError } = useSelector((state : any)=> state.userAPITokens);
    const { currentUserLoading, currentUser, currentUserError } = useSelector((state : any)=> state.currentUser);
    const { isUserAuthenticate } = useSelector((state : any)=> state.userAuthenticate);
    const permissions = getAccessPermission();

    useEffect(() => {
        sessionStorage.setItem('reloadCount', String(1));
        GoogleAuth.initialize();
        const script = document.createElement('script');
        script.src = "https://cdn.intelikart.com/scripts/partners.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {
        if(userAPITokensLoading === false){
            if(userAPITokens){
                setTokensObject(userAPITokens.data);
                loadCurrentUser(userAPITokens.data.token);
            }
        }
    }, [userAPITokens]);

    // useEffect(() => {
    //     if(currentUserLoading === false && getAccessToken()){
    //         if(currentUser && currentUser.data){
    //             dispatch(authenticateUserWhenLogin());
    //             if(currentUser.data.store.storetype_id !== 0){
    //                 history.push('/userdashboard', {direction: 'none'});
    //             }else{
    //                 history.push('/getbusinessdetails');
    //             }
    //         }
    //     }
    // }, [currentUser]);
    const handleStaffRedirection = (permissions) => {
        // Customize this logic based on the staff's permissions
        if (permissions && permissions.includes('orders')) {
            history.push('/orders');
        }
    };
    
    useEffect(() => {
        if (currentUserLoading === false && getAccessToken()) {
            if (currentUser && currentUser.data) {
                dispatch(authenticateUserWhenLogin());
    
                if (currentUser.data.store.storetype_id !== 0) {
                    if (permissions?.length === 0) {
                        // Owner logic
                        history.push('/userdashboard', { direction: 'none' });
                    } else if (permissions?.length > 0) {
                        // Staff logic
                        handleStaffRedirection(permissions);
                    } 
                }else {
                    // Default redirection for other users
                    history.push('/getbusinessdetails');
                }
            }
        }
    }, [currentUser, currentUserLoading, dispatch, history, permissions]);

    const loadCurrentUser = async (token: string)=>{
        await dispatch(getCurrentUser(token));
    }

    useEffect(() => {
        if(isUserAuthenticate === false){
            setDoReload(true);
        }
    }, [isUserAuthenticate])

    useEffect(() => {
        if(doReload){
            window.location.reload();
        }
    }, [doReload])

    const signInWithGoogle = async () => {
        const userFromGoogle = await GoogleAuth.signIn();
        if (userFromGoogle) {
            const formData = new FormData();
            formData.append('username',userFromGoogle.email);
            formData.append('password',userFromGoogle.authentication.accessToken);
            formData.append('scope','store')
            formData.append('thirdparty','google')
            await dispatch(getUserTokens(formData,"google"));
        }
    }
    
    return (
        <IonPage  className="ion-no-margin">
            <IonContent className="ion-padding ion-text-center outer-container">
                <div className="inner-container">
                    <div className="iklogo">
                        <LogoImage/>
                    </div>
                    <IonText>
                        <h5 style={{marginBottom: 0}}>Login to launch</h5>
                        <h5 style={{marginTop: "2px"}}>your <span style={{color: "rgb(1, 41, 112)"}}>eCommerce app & website</span></h5>
                    </IonText>
                    <div>

                        {!(isPlatform('hybrid') && isPlatform('android')) ? 
                            <IonButton className="loginButtonDt" shape="round" color="primary" onClick={()=>signInWithGoogle()}>
                                <IonIcon slot="start" icon={logoGoogle}/>
                                <span className="ion-margin-horizontal">Continue with Google</span>
                            </IonButton> : null}                    

                    {/* <IonButton expand="block" shape="round" onClick={()=>signInWithFacebook()} style={{marginBottom: 2+'vh', marginLeft: 10+'%', marginRight: 10+'%'}}>
                        <IonImg style={{height:35+'px',width:35+'px'}} src={FacebookLogo}/>&nbsp;
                        <IonText>Continue with Facebook</IonText> 
                    </IonButton> */}

                    <IonButton className="loginButtonDt" shape="round"  color="primary" onClick={()=>{history.push("/signupwithgmail")}}>
                        <IonIcon slot="start" icon={mail}/>
                        <span className="ion-margin-horizontal">Continue with Email OTP</span>
                    </IonButton>
                    </div>
                    <div className="horizontal-line"/>
                    {/* <IonText>
                        <p>Launch your ecommerce app & website <br/> <a onClick={() =>  window.open("https://www.intelikart.com")} href="#">www.intelikart.com</a></p>
                    </IonText> */}
                </div>
                <IonLoading isOpen={ userAPITokensLoading === true || currentUserLoading === true } message={'Please Wait...'}/>
            </IonContent>
            <LoginFooter/>
        </IonPage>
    )
}

export default Home

