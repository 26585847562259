export const GET_MASTER_CATEGORIES_REQUEST = 'GET_MASTER_CATEGORY_REQUEST'
export const GET_MASTER_CATEGORIES_SUCCESS = 'GET_MASTER_CATEGORY_SUCCESS'
export const GET_MASTER_CATEGORIES_FAIL = 'GET_MASTER_CATEGORY_FAIL'

export const GET_MASTER_PRODUCTS_REQUEST = 'GET_MASTER_PRODUCTS_REQUEST'
export const GET_MASTER_PRODUCTS_SUCCESS = 'GET_MASTER_PRODUCTS_SUCCESS'
export const GET_MASTER_PRODUCTS_FAIL = 'GET_MASTER_PRODUCTS_FAIL'

export const POST_COPY_PRODUCTS_REQUEST = 'POST_COPY_PRODUCTS_REQUEST'
export const POST_COPY_PRODUCTS_SUCCESS = 'POST_COPY_PRODUCTS_SUCCESS'
export const POST_COPY_PRODUCTS_FAIL = 'POST_COPY_PRODUCTS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'