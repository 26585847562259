import {
    IonButton,
    IonCheckbox,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonModal,
    IonText,
    IonThumbnail,
} from "@ionic/react";
import { closeOutline, search as searchIcon } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useUser } from "../../../utils/contexts";
import { getAccessToken } from "../../../services/auth/userLocalStorage";
import { StoreCurrency } from "../../../utils/currency";

const ComplementaryModal = ({
    showModal,
    setShowModal,
    setComplementaryData,
    selectedProducts,
    setSelectedProducts,
}) => {
    const [complementaryProducts, setComplementaryProducts] = useState([]);
    const currency = StoreCurrency();

    const [selected, setSelected] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const currentUser: any = useUser();

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        searchProductByName();
    }, [searchQuery, currentUser]);

    useEffect(() => {
        setSelected(selectedProducts);
    }, [selectedProducts]);

    const searchProductByName = async () => {
        setLoading(true);
        if (searchQuery && searchQuery.length >= 2) {
            if (currentUser) {
                const response = await fetch(
                    `https://api2.intelikart.in/search/products?query=${searchQuery}&includeall=active`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${getAccessToken()}`,
                            "x-ik-domain": `${currentUser.store.subdomain}`,
                        },
                    }
                );
                const data = await response.json();
                setComplementaryProducts(data.data);
            }
        } else {
            setComplementaryProducts([]);
        }
        setLoading(false);
    };

    const handleChecked = (product) => {
        const isSelected = selected.some((p) => p.id === product.id);
        if (isSelected) {
            const updatedProducts = selected.filter(
                (p) => p.id !== product.id
            );
            setSelected(updatedProducts);
        } else {
            const updatedProducts = [...selected, product];
            setSelected(updatedProducts);
        }
    };

    const handleSave = () => {
        setComplementaryData(selected);
        setSelectedProducts(selected);
        setSearchQuery("");
        setShowModal(false);
    };

    return (
        <IonModal
            isOpen={showModal}
            onDidDismiss={() => {
                setSelected(selectedProducts);
                setShowModal(false);
                setSearchQuery("");
            }}
        >
            <IonHeader>
                <IonItem lines="none">
                    <IonText className="ion-padding-start">
                        <h5>Add Complementary Products</h5>
                    </IonText>
                    <IonButton
                        fill="clear"
                        size="large"
                        slot="end"
                        onClick={() => {
                            setSelected(selectedProducts);
                            setShowModal(false);
                            setSearchQuery("");
                        }}
                    >
                        <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                    </IonButton>
                </IonItem>
                <IonItem lines="none">
                    <div
                        style={{
                            width: "100%",
                            margin: "5px 15px 15px",
                            border: "none",
                            backgroundColor: "#eee",
                        }}
                        className="product-search-bar"
                    >
                        <IonIcon
                            icon={searchIcon}
                            style={{ marginRight: "7px" }}
                        ></IonIcon>
                        <IonInput
                            placeholder="Search products"
                            onIonChange={handleSearchChange}
                        ></IonInput>
                    </div>
                </IonItem>
            </IonHeader>
            <IonContent className="ion-padding">
                {!loading && complementaryProducts.length > 0 ? (
                    complementaryProducts.map((product) => (
                        <IonItem key={product.id}>
                            <IonThumbnail slot="start">
                                <img
                                    src={product.variants[0].image_url_original}
                                />
                            </IonThumbnail>
                            <IonText>
                                <p className="ion-no-margin">{product.name}</p>
                                {product.variants[0].mrp != 0 &&
                                product.variants[0].mrp != null ? (
                                    <p
                                        className="ion-no-margin"
                                        style={{
                                            display: "inline",
                                        }}
                                    >
                                        {currency} {product.variants[0].mrp}
                                        &nbsp;
                                    </p>
                                ) : (
                                    ""
                                )}
                                {product.variants[0].display_mrp != 0 &&
                                product.variants[0].display_mrp != null ? (
                                    <del>
                                        {currency}{" "}
                                        {product.variants[0].display_mrp}
                                    </del>
                                ) : (
                                    ""
                                )}
                            </IonText>
                            <IonCheckbox
                                checked={selected.some(
                                    (p) => p.id === product.id
                                )}
                                onClick={() => handleChecked(product)}
                                slot="end"
                            />
                        </IonItem>
                    ))
                ) : !loading ? (
                    searchQuery.length < 3 ? (
                        <IonText>
                            Please enter 2 or more characters to search
                        </IonText>
                    ) : (
                        <IonText>No products found</IonText>
                    )
                ) : (
                    <IonText>Loading...</IonText>
                )}
            </IonContent>
            <IonFooter className="ion-text-center ion-padding">
                <IonButton onClick={handleSave} shape="round">
                    Save
                </IonButton>
            </IonFooter>
        </IonModal>
    );
};

export default ComplementaryModal;
