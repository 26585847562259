
import {
    IonButton,
    IonIcon,
    IonInput,
    IonItem,
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonCard
} from "@ionic/react";
import { removeCircle as removeCircleIcon
} from 'ionicons/icons';

const VariantTable = ({tableData, setTableData, tableValidation, removedOptionsList, setRemovedOptionsList, data}) => {

    const removeFromList = (variantName) => {
        let temp = {...tableData};
        delete temp[`${variantName}`];
        setTableData(temp);
    };

    return (
        <>
            <IonRow className="ion-justify-content-center">
            
            <IonCard className="ion-padding-top">
            {Object.keys(tableData).length !== 0 && <>
                <table
                    style={{
                        width: 100 + "%",
                        overflowX: "auto",
                        display: "block",
                    }}
                >
                    <IonCol>
                    <thead>
                        <tr>
                            <th className="ion-padding-horizontal">Variant</th>
                            <th className="ion-padding-horizontal">Selling Price</th>
                            <th className="ion-padding-horizontal">MRP</th>
                            {data?.data?.status === "active" && (
                                <th className="ion-padding-horizontal ion-text-start">Available Stock</th>
                            )}
                            <th className="ion-padding-horizontal ion-text-start">Video</th>
                            <th ></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(tableData).map((variantName, index) => {
                            return (
                                <tr>
                                    <td className="ion-padding-horizontal">
                                        <IonText >
                                            {variantName}
                                        </IonText>
                                    </td>
                                    <td>
                                            <IonInput
                                            className="ion-text-center"
                                                value={tableData[variantName].sellingPrice}
                                                type="text"
                                                min="0"
                                                onIonChange={(e) => {
                                                    setTableData((prev)=>{
                                                        let temp = {...prev};
                                                        temp[variantName] = {...temp[variantName], sellingPrice: e.detail.value};
                                                        return temp;
                                                    });
                                                }}
                                                
                                            />
                                    </td>
                                    <td>
                                            <IonInput
                                            className="ion-text-center"
                                                min="0"
                                                value={tableData[variantName].Mrp}
                                                type="text"
                                                onIonChange={(e) => {
                                                    setTableData((prev)=>{
                                                        let temp = {...prev};
                                                        temp[variantName] = {...temp[variantName], Mrp: e.detail.value};
                                                        return temp;
                                                    });
                                                }}
                                                
                                            />
                                    </td>
                                    {data?.data?.status === "active" && (
                                        <td>
                                            <IonItem lines="inset">
                                                <IonInput
                                                    value={tableData[variantName].avlQty}
                                                    type="number"
                                                    min="0"
                                                    onIonChange={(e) => {
                                                        setTableData((prev)=>{
                                                            let temp = {...prev};
                                                            temp[variantName] = {...temp[variantName], avlQty: e.detail.value};
                                                            return temp;
                                                        });
                                                    }}
                                                />
                                            </IonItem>
                                        </td>
                                    )}
                                    <td>
                                        <IonItem lines="inset">
                                            <IonInput
                                                value={tableData[variantName].video}
                                                type="url"
                                                onIonChange={(e) => {
                                                    setTableData((prev)=>{
                                                        let temp = {...prev};
                                                        temp[variantName] = {...temp[variantName], video: e.detail.value};
                                                        return temp;
                                                    });
                                                }}
                                            />
                                        </IonItem>
                                    </td>
                                    <td
                                    className="ion-padding-horizontal"
                                        style={{ color: "red" }}
                                        onClick={(e) => {
                                            removeFromList(variantName);
                                            setRemovedOptionsList([
                                                ...removedOptionsList,
                                                variantName
                                            ]);
                                        }}
                                    >
                                        <IonButton
                                            shape="round"
                                            size="small"
                                            slot="end"
                                            color="danger"
                                        >
                                            <IonIcon icon={removeCircleIcon}></IonIcon>
                                        </IonButton>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                    </IonCol>
                </table>
                <IonText color="danger">
                    <h5 className={`error-text ion-margin-horizontal ${tableValidation.mrp ? "" : "ion-hide"}`}
                    >
                        {tableValidation.mrp}
                    </h5>
                    <h5
                        className={`error-text ion-margin-horizontal ${tableValidation.selllingPrice ? "" : "ion-hide"}`}
                    >
                        {tableValidation.selllingPrice}
                    </h5>
                    <h5
                        className={`error-text ion-margin-horizontal ${tableValidation.greater ? "" : "ion-hide"}`}
                    >
                        {tableValidation.greater}
                    </h5>
                </IonText>
            </>
            }
            </IonCard>
            
            </IonRow>
    </>         
    );
};

export default VariantTable;
