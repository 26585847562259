import { IonPage, IonRouterOutlet } from "@ionic/react";
import { useState } from "react";
import { Route } from "react-router";
import { PagesContext } from "../../utils/contexts";
import CreatePage from "./CreatePage";
import PageListing from "./PageListing";

const Pages = () => {
    const [update, setUpdate] = useState(false);

    return (
        <PagesContext.Provider value={{update, setUpdate}}>
            <IonPage>
                <IonRouterOutlet>
                    <Route path="/content/pages/create" exact={true}>
                        <CreatePage />
                    </Route>
                    <Route path="/content/pages/update" exact={true}>
                        <CreatePage />
                    </Route>
                    <Route path="/content/pages" exact={true}><PageListing /></Route>
                </IonRouterOutlet >
            </IonPage>
        </PagesContext.Provider>
    )
}

export default Pages