export const emailValidation = (email)=>{
    return email && email.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/) && !email.match(/,/)
}

export const alphaValidation = (name)=>{
    return name && !(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~01234567899]/.test(name));
}

//no special
export const alphaNumericValidation = (data)=>{
    return data && !(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(data));
}

export const numericValidation = (data)=>{
    return data && !(/\D/.test(data));
}

//return false if it has one letter.
export const atleastOneCharValidation = (data)=>{
    return data && !(/[a-zA-Z]/.test(data));
}

export const phoneValidation = (data)=>{
    return data && /^(\+)?\d+$/.test(data);
}

export const advEmailValidation = (data)=>{
    return data && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data));
}