import { IonAccordion, IonCard, IonInput, IonItem, IonLabel, IonReorder, IonToggle } from '@ionic/react';
import { useWebsiteSettings } from '../../../../../utils/contexts';

const CategorySlides = ({data}) => {
  const settings:any = useWebsiteSettings();
  return (
    <IonCard>
      <IonAccordion>
          <IonItem slot="header" color='light'>
              <IonLabel>Category Slider</IonLabel>
              {data.reorder && <IonReorder slot="end"/>}
              {data.toggle && <IonToggle checked={settings.websiteSettings.category_sliders.display === 1} color="medium" onIonChange={e => settings.setWebsiteSettings({...settings.websiteSettings, category_sliders: {...settings.websiteSettings.category_sliders, display: e.detail.checked ? 1 : 0}  })} slot="end"></IonToggle>}
          </IonItem>
          <div className="ion-padding" slot="content">
            <IonItem>
              <IonLabel position="stacked">Heading</IonLabel>
              <IonInput
                placeholder="Write heading here."
                type="text"
                value={settings.websiteSettings.category_sliders.component_heading}
                onIonChange={e=> settings.setWebsiteSettings({...settings.websiteSettings, category_sliders: {...settings.websiteSettings.category_sliders, component_heading: e.detail.value} })}
              />
            </IonItem>
          </div>
        </IonAccordion>
      </IonCard>
  )
}

export default CategorySlides