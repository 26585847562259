import { useContext } from 'react';
import { IonContent, IonLoading, IonPage } from '@ionic/react';
import Header from '../../components/dashboard/Header'
import Packages from './Packages';
import { UserContext } from '../../utils/contexts';

const Subscriptions = () => {

const user: any = useContext(UserContext);
  

    return (
        <IonPage>
            <div className="ion-page" id="main-content">
                <Header name="Subscription"/>
                <IonContent>
                    {user?.store?.subdomain ? <Packages /> : <IonLoading isOpen={true} message="Please Wait"></IonLoading>}
                </IonContent>
            </div>
        </IonPage>
    )
}
export default Subscriptions