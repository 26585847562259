import React from 'react';
import { useHistory } from 'react-router';
import { arrowBackOutline as Back } from 'ionicons/icons';
import {
    IonContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonIcon,
} from '@ionic/react';
const PrivacyPolicy: React.FC = () => {
    const history = useHistory();
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                    <IonIcon icon={Back} onClick={(e)=>{history.goBack()}} style={{fontSize:"25px",cursor:"pointer",padding:"5px"}}></IonIcon>
                    </IonButtons>
                    <IonTitle>Privacy Policy</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <div style={{width:"90%",margin:"auto"}}>
                    <h2>Privacy Policy</h2>
                    <p>This legal agreement is an electronic record in terms of Information Technology Act, 2000, and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                    <p>
                        This legal agreement is published in accordance with the provisions of Rule 4 of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008 that require publishing the privacy policy for access or usage of Intelikart app and its integrated systems.</p>

                    <p>This privacy policy applies to all users of Intelikart mobile app and its integrated systems including but not limited to website and back end systems.</p>

                    <p>By using this mobile app, clicking the “I accept/I agree” button or completing the registration process, you agree and acknowledge that you have carefully read this privacy policy and accepted this agreement.</p>

                    <p>References in these Terms to “we” or “us” or “app” or “firm” are references to Intelikart mobile app and “you” as subscriber/user of Intelikart mobile app and its integrated systems. Subscriber/user may include but not limited to retailers, customers of retailers and every other user who uses Intelikart.</p>

                    <h2>PURPOSE OF INTELIKART APP</h2>
                    <p>
                        A complete & innovative Mobile Commerce Platform which creates Android apps for your shop. It helps facilitate your customers to submit order from mobile phone and let them get the order at their door step at their convenient time.
                    </p>
                    <p>
                        We understand business people and their time limitation that’s why, Intelikart is launched as a hosted and cloud base solution so no worry about technology, IT and maintenance. Intelikart provides services like Create and Personalize Apps, Manage Intelikart and Inventory, Do Promotion – SMS and Push Notifications, Accept Orders and Payment, Manage Delivery.</p>

                    <h2>PRIVACY PREAMBLE</h2>
                    <p>
                        While providing aforesaid online services, the issue of online privacy is extremely crucial for Intelikart which is committed for safeguarding the information provided by its users and other stakeholders. Intelikart is dedicated to create a reasonably secured environment for its stakeholders.
                    </p>

                    <p>Respecting privacy of its online users while providing top-of-the-line services have remained the core strategies for Intelikart. Our Privacy Policy gives users a view of the Privacy Practices followed by Intelikart and assures them of safe passage through the app.</p>
                    <p>At the same time, Intelikart would like to make it clear that its app provides links to other web sites/apps that are governed by their own Privacy Policies and Intelikart does not take responsibility for the security and privacy provided by these sites/apps. This includes but not limited to retailer’s app on our platform, payment gateways and online advertisement sites.</p>
                    <p>Users to the Intelikart app are urged to familiarize themselves with its Privacy Policy as well the policies of the third party sites/apps that collect personally identifiable information.</p>
                    <p>When you provide us with your personal data, you are agreeing to the rules and regulations stipulated under our Privacy Policy and are bound by it. If you do not agree to our Policy then please do not use the app.</p>
                    <h2>THE PRIVACY POLICY AGREEMENT SHALL BE GOVERNED IN 4 FOLLOWING AREAS</h2>
                    <ol>
                        <li> Online processes & type of information collected through Intelikart app</li>

                        <li>Use and Disclosure of personal information </li>

                        <li> Choice or Opt out </li>

                        <li> Reasonable Security Practices </li>
                    </ol>

                    <p><b>1. Online/Manual Processes & type of information collected through the app</b> </p>
                    <p>Online Registration Process</p>
                    <p>User registering at the Intelikart shall go through following processes:</p>
                    <ul>
                        <li>User registration for creating and managing its app on our platform</li>
                        <li>User access to online materials (upload/download/use)</li>
                        <li>User payment through payment gateway</li>
                        <li>User access to its own account details</li>
                    </ul>
                    <p>Manual Registration Process</p>
                    <p>User not registering online at the Intelikart app/web shall go through following manual processes:</p>
                    <ol>
                        <li>Visit of our sales executive at user’s business place</li>
                        <li>User provides data by filling up physical forms</li>
                        <li>Our executive performs online user registration using data given in physical forms</li>
                    </ol>
                    <p>Personal Information</p>
                    <p> 

Users registering at the Intelikart app, you are requested to provide some personally identifiable information (listed in below clauses) which becomes the property of Intelikart and can be shared, disclosed or distributed to third parties only in accordance with this Privacy Policy. It must be clarified here that registration is mandatory for users.</p>
                    <p><b>2. Use and Disclosure of personal information</b></p>
                    <p>Intelikart will not sell or rent or publish such personally identifiable information collected. You hereby agree and acknowledge that all personally identifiable information is supplied by you voluntarily for some of the following purposes:</p>
                    <p>Registration Data & Other Optional Information:</p>
                    <p>You will be asked to register on registration link. During registration you will be requested to complete a registration form setting out basic online contact information about yourself. Such sensitive personal information shall include but not limited to following:</p>
                    <ul>
                        <li>Mobile Number</li>
                        <li>Full Name</li>
                        <li>Email ID</li>
                        <li>City</li>
                        <li>Shop Name and related identification records including but not limited to PAN card</li>
                        <li>Category</li>
                        <li>Images</li>
                        <li>Expected Number of Product</li>
                    </ul>
                    <p>Images:</p>
                    <p>Images are collected for store logos, to process e-commerce order information, and or for other product requests & related functionality.</p>
                    <p>E-mail information:</p>
                    <p>You agree and acknowledge that when we receive e-mails from you, we may retain the content of any e-mail messages sent to us, as well as details of your e-mail address. You further agree and acknowledge that Intelikart shall use your email id and mobile number information for sending promotional messages from time to time.</p>
                    <p>Data for analytics:</p>
                    <p>We collect information/data contained in your device for data analytics. Data analytics help us track your navigation so that we can tailor our offerings to your specific needs. These requires access to SMS, Contact details, Phone, Camera and Storage of your device. Therefore, you agree and acknowledge that you have given us permission to access SMS, Contact details, Phone, Camera and Storage of your device. The kind of information that may be stored on our data analytics includes registration data so that a user does not need to re-enter the information every time he/she visits a particular area, user IP address, user ISP details, user browser, devices OS details, Current Latitude, Current Longitude, Device Token, Device Type, App Version, Date registered on, Last Logged in, Logged in Latitude, Logged in Longitude, Active, Suspend Reason, Other Reason and Last updated on. You allow us, agree and acknowledge for aforesaid data gathering and analytics so as to get personalized offerings to your specific needs.</p>
                    <p>Third party Apps/sites which are accessible from our app via links, click-through or banner advertising may use Cookies. However, it is important for us to inform you that we shall have no access or control over such Cookies and therefore you agree that we shall not be held responsible for privacy issues relating to third parties.</p>
                    <p><b>3. Choice/Opt-out</b></p>
                    <p>Once you are registered at the Intelikart app, you will have the option at any stage to inform us that you no longer wish to receive future services, e-mails and you may “unsubscribe” by contacting on unsubscribe link on our app.</p>
                    <p>Further, as per Rule 5(7) of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008, you have an option to withdraw your consent for use of your sensitive personal data given earlier to us. Such withdrawal of consent shall be sent in writing to our registered address as described in the app/site.</p>
                    <p><b>4. Reasonable Security Practices As per I.T.Act, 2000 and its Rules</b></p>
                    <p>We have implemented reasonable security practices as per Rule 8 of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008.</p>
                    <p>Access to your personal account online is password protected. We will not release your account password to any person. In the event that you forget your password, you may generate an on-line request for your password to be sent to you by e-mail/sms at the e-mail address/phone number used during registration.</p>
                    <p>Intelikart has implemented stringent, internationally acceptable standards of technology, managerial security, technical security, operational security and physical security in order to protect personally identifiable information from loss, misuse, disclosure, alteration or destruction. The data resides behind a firewall, with access restricted to authorized personnel of Intelikart.</p>
                    <p>Intelikart has implemented “Reasonable Security Practices” as required by the Information Technology Act, 2000 rules including any amendment in the said Act and its rules. By complying with such provisions, Intelikart assures you proper care and control over its I.T. and Security operations as required under relevant sections mainly section 43, 43A, 45, 66,72A & 85 of I.T.Act, 2000 and I.T.A.A, 2008 including related rules and therefore you agree that Intelikart shall not be held responsible for any activity in your account which results from your failure to keep your own password/mobile secured.</p>
                    <p>By using this app you agree that Intelikart shall not be held responsible for any uncontrollable security attacks and in such cases you agree that Intelikart shall not be held responsible for any type of financial losses, loss of opportunity, legal cost, business losses, reputation loss, direct and indirect losses that may occur to you as per the Provisions of Section 43(a), 43(b), 43(c), 43(d), 43(e), 43(f), 43(g), 43(h), 43(i), 43A and 45 of Information Technology Act, 2000 including any amendments in the said Act and any other laws of India for the time being in force.</p>
                    <p>You further agree that Intelikart management shall not be held responsible directly or indirectly for any cyber crime related criminal liabilities under I.T.Act,2000 relating to your information as you have agreed and acknowledged that management of Intelikart complies with due diligence (care & controls) requirements of I.T.Act,2000 including its rules and amendments.</p>
                    <p>Our reasonable security controls include but not limited to following:</p>
                    <ul>
                        <li>HTTPS/SSL for All sensitive actions as us checkout process, payment process, login and sign up.</li>
                        <li>Back-end panel required authentication, all interfaces of back-end are required user authentication.</li>
                        <li>We don’t store Credit card information anywhere</li>
                        <li>OTP based login</li>
                        <li>Physical Security</li>
                        <li>OS Security</li>
                        <li>Anti-virus</li>
                        <li>Intrusion Detection System</li>
                        <li>Managed Firewalls</li>
                        <li>Vulnerability Scanning</li>
                        <li>SSL Certificates</li>
                        <li>Distributed Denial of Service Protection</li>
                        <li>DigitalOcean provides complete list of information security controls of server on which Intelikart app is hosted.</li>
                    </ul>
                    <p>Further, you also agree and acknowledge that Intelikart management shall never be held responsible regarding privacy of your sensitive personal data in case of sharing your sensitive personal data to any authorized cyber investigation agency of appropriate government authorities as required under sections 67C, 69, 69A, 69B, 70B, 79 and 80 of I.T.Act, 2000 including its amendments and rules.</p>
                    <p><b>Grievance Redressal</b></p>
                    <p>If you have any questions or grievances regarding the privacy statement, practices of the site, or any other transaction issue, please contact our grievance officer on pradeep@inteliheads.com</p>
                    <p>We have appointed our grievance officer as per Rule 5 (9) of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008 and you can contact him on pradeep@inteliheads.com</p>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default PrivacyPolicy;
