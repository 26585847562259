import React, { useState, useEffect } from 'react';
import { IonContent, IonText, IonItem, IonIcon, IonLoading, IonCard, IonButton, IonAlert, IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, useIonToast, IonBadge } from '@ionic/react';
import {
    trash as trashIcon,
    arrowBack as arrowBackIcon,
    createOutline as editIcon
} from 'ionicons/icons';
import { Link, useHistory } from "react-router-dom";
import useAPI from '../../utils/api';
import { useStaffForUpdate, useUser } from '../../utils/contexts';
import useFetch from '../../utils/useFetch';
import Header from '../../components/dashboard/Header'


const StaffListing = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState<any>();
    const API = useAPI();
    const staffUpdateData: any = useStaffForUpdate();
    const { loading, status, data } = useFetch("staffs", staffUpdateData.update);
    const [present] = useIonToast();

    const history = useHistory()
    const deleteStaffFunction = async (staffId) => {
        const [ok, response] = await API.deleteStaff(staffId);
        if (ok) {
            present("Data Deleted successfully!", 1000);
            staffUpdateData.setUpdate(!staffUpdateData.update);
        }
    }

    return (
        <IonPage>
            <div className="ion-page" id="main-content">
                <Header name="Users" />
                <IonContent className="ion-padding">
                    <IonText className="ion-text-center ion-margin-bottom">
                        <h5 className="ion-no-margin"><strong>Add user for your store</strong></h5>
                        <p>Manage users and permission</p>
                    </IonText>
                    {
                        !loading && status === "fetched" && data?.data.map((value: any) => {
                            return (
                                <IonCard key={value.id}>
                                    <IonItem>
                                        <IonText>

                                            <h5>{value.name}</h5>
                                            <p>{value.email}</p>
                                            <p>
                                                {value.driver_app ? (
                                                    <IonBadge color="danger">Driver App</IonBadge>
                                                ) : null}

                                                <span style={{ marginLeft: '5px' }}>
                                                    {value.orders ? (
                                                        <IonBadge color="primary">Orders</IonBadge>
                                                    ) : null}   </span>
                                            </p>
                                        </IonText>
                                        <IonIcon slot="end" size="large"
                                        style={{cursor:"pointer"}}
                                        icon={editIcon} onClick={() => history.push({ pathname: '/staffs/update', state: value })}></IonIcon>

                                        <IonIcon slot="end" size="large" 
                                        style={{cursor:"pointer"}}
                                        icon={trashIcon} onClick={(e) => { setAlertData(value.id); setShowAlert(true) }}></IonIcon>
                                    </IonItem>
                                </IonCard>
                            );
                        })
                    }
                    <div className="ion-text-center">
                        <IonButton shape="round" routerLink="/staffs/create">Add User</IonButton>
                    </div>
                    <IonAlert
                        isOpen={showAlert === true}
                        onDidDismiss={() => setShowAlert(false)}
                        header={'Delete Status'}
                        message={`You really want to delete status ${alertData && alertData.code ? alertData.code : ""}`}
                        buttons={[
                            {
                                text: 'CANCEL',
                                role: 'cancel'
                            },
                            {
                                text: 'DELETE',
                                handler: () => { deleteStaffFunction(alertData); }

                            }
                        ]}
                    />
                    <IonLoading isOpen={loading} message="Please Wait"></IonLoading>
                </IonContent>
            </div>
        </IonPage>
    )
}

export default StaffListing
