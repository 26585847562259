import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Directory } from "@capacitor/filesystem";
import {
    IonPage, IonContent, IonGrid, IonRow, IonCol,
    IonInput, IonButton, IonLabel, IonItem, IonIcon, useIonToast, IonSelect, IonSelectOption,
    IonHeader, IonToolbar, IonButtons, IonBackButton, IonText,
    IonTitle,
    IonLoading,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    isPlatform,
} from '@ionic/react';
import { add, trash } from 'ionicons/icons';
import { auth } from '../../utils/auth';
import { UserContext } from '../../utils/contexts';
import useFetch from "../../utils/useFetch";
import write_blob from 'capacitor-blob-writer';
import { advEmailValidation, phoneValidation } from '../../utils/validations';

interface ValidationErrors {
    customerName: string;
    customerAddress: string;
    customerEmail: string;
    customerPhone: string;
    productName: string[];
    priceErrors: string[];
    quantityError: string[];
}

const Invoice = () => {
    const [customerName, setCustomerName] = useState<string>('');
    const [customerAddress, setCustomerAddress] = useState<string>('');
    const [customerNumber, setCustomerNumber] = useState<string>('');
    const [customerEmail, setCustomerEmail] = useState<string>('');
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [items, setItems] = useState([]);
    const [errors, setErrors] = useState<{ [key: number]: boolean }>({});
    const [present] = useIonToast();
    const { id: invoiceId } = useParams<{ id: string }>();
    const token: string | null = auth.getToken();
    const user: any = useContext(UserContext);
    const userDomain: string | undefined = user?.store?.subdomain;
    const isEditMode: boolean = Boolean(invoiceId);
    const { data, loading, status } = useFetch('getManualBills');

    const [validation, setValidation] = useState({
        customerName: '',
        customerAddress: '',
        customerEmail: '',
        customerPhone: '',
        productName: new Array(items.length).fill(''),
        priceErrors: new Array(items.length).fill(''),
        quantityError: new Array(items.length).fill(''),
    });

    const calculateSubtotal = (item: any) => {
        const totalTax = item.tax_type === 'PERCENT' ? (item.price * item.tax) / 100 || 0 : item.tax || 0;
        const subTotal = (item.price + totalTax) * item.quantity || 0;
        return parseFloat(subTotal.toFixed(2));
    };

    const validateFields = () => {
        let isValid = true;
        const errors: ValidationErrors = {
            customerName: '',
            customerAddress: '',
            customerEmail: '',
            customerPhone: '',
            productName: new Array(items.length).fill(''),
            priceErrors: new Array(items.length).fill(''),
            quantityError: new Array(items.length).fill(''),
        };

        if (/^[\d`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+$/.test(customerName ? customerName.trim() : '')) {
            errors.customerName = 'Name can\'t contain only numbers and special characters.';
            isValid = false;
        }

        if (/^[\d`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+$/.test(customerAddress ? customerAddress.trim() : '')) {
            errors.customerAddress = 'Address can\'t contain only numbers and special characters.';
            isValid = false;
        }

        if (customerNumber && (!phoneValidation(customerNumber ? customerNumber.trim() : '') || customerNumber.length !== 10)) {
            errors.customerPhone = 'Phone number is not valid.';
            isValid = false;
        }

        if (advEmailValidation(customerEmail ? customerEmail.trim() : '')) {
            errors.customerEmail = 'Email is not valid.';
            isValid = false;
        }

        items.forEach((item) => {
            if (!item.name) {
                errors.productName[item.id] = 'Product name is required.';
                isValid = false;
            }
            if (item.price === null || item.price <= 0 || isNaN(item.price)) {
                errors.priceErrors[item.id] = 'Price can\'t be zero or less!';
                isValid = false; 
            }
            if (item.quantity === null || item.quantity <= 0 || isNaN(item.quantity)) {
                errors.quantityError[item.id] = 'Quantity can\'t be zero or less!';
                isValid = false;
            }
        });

        setValidation(errors);
        return isValid;
    };



    useEffect(() => {
        const total = items.reduce((acc, item) => acc + item.subtotal, 0);
        setTotalAmount(total);
    }, [items]);

    const handleItemChange = useCallback((id: number, field: string, value: any) => {
        const updatedItems = items.map(item =>
            item.id === id ? { ...item, [field]: value, subtotal: calculateSubtotal({ ...item, [field]: value }) } : item
        );
        setItems(updatedItems);
    }, [items]);

    const addItem = () => {
        const newItem = { id: items.length + 1, name: '', quantity: '', price: '', subtotal: 0, tax: 0, tax_type: 'PERCENT' };
        setItems(prevItems => [...prevItems, newItem]);
    };

    const removeItem = (id: number) => {
        setItems(prevItems => prevItems.filter(item => item.id !== id));

    };

    useEffect(() => {
        if (isEditMode && !loading && status === 'fetched' && data?.data) {
            const invoiceData = data.data.find((item: any) => parseInt(invoiceId) === item.id);
            if (invoiceData) {
                setCustomerName(invoiceData.name);
                setCustomerAddress(invoiceData.address);
                setCustomerNumber(invoiceData.number);
                setCustomerEmail(invoiceData.email);
                const updatedItems = invoiceData.productdetails.data.map((product: any, index: number) => ({
                    ...product,
                    id: index + 1,
                    subtotal: calculateSubtotal(product)
                }));
                setItems(updatedItems);
            }
        }
    }, [data, loading, isEditMode, invoiceId]);

    const handleSaveOrUpdate = async () => {
        if (items.length === 0) {
            present({ message: 'At least one product is required!', duration: 2000, position: "bottom" });
            return;
        }
    
        if (!validateFields()) {
            return;
        }
    
        const invoiceData = {
            customer_name: customerName,
            customer_address: customerAddress,
            customer_number: customerNumber,
            customer_email: customerEmail,
            product_details: items.map(item => ({
                name: item.name,
                quantity: item.quantity,
                price: item.price,
                tax: item.tax,
                tax_type: item.tax_type,
                subtotal: item.subtotal
            })),
        };
    
        const url = isEditMode ? `https://api2.intelikart.in/manualbilling/${invoiceId}` : 'https://api2.intelikart.in/manualbilling';
        const method = isEditMode ? 'PUT' : 'POST';
    
        try {
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'x-ik-domain': userDomain,
                },
                body: JSON.stringify(invoiceData)
            });
    
            if (!response.ok) {
                throw new Error(`Failed to ${isEditMode ? 'update 1' : 'save 1'} invoice: ${response.statusText}`);
            }
    
            const myBlob = await response.blob();
            console.log("blob created", myBlob)
            if (isPlatform("capacitor")) {
                try {
                 const result = await write_blob({
                        path: `invoice${invoiceId || Date.now()}.pdf`,
                        directory: Directory.Documents,
                        blob: myBlob
                    });

                    console.log('Write result:', result);
                    present({ message: `Invoice ${isEditMode ? 'updated' : 'saved'} successfully!`, duration: 2000, position: "bottom" });
                    if (!isEditMode) resetForm();
                } catch (error) {
                    console.error('Error writing file:', error);
                    alert(`Failed to save invoice 2: ${error.message}`);
                }
            } else {
                const url = window.URL.createObjectURL(myBlob);
                const link = document.createElement("a");
                link.href = url;
                link.download = "invoice.pdf";
                document.body.appendChild(link); // Required for Firefox
                link.click();
                document.body.removeChild(link); // Clean up
                window.URL.revokeObjectURL(url); // Free up memory
    
                present({ message: `Invoice ${isEditMode ? 'updated' : 'saved'} successfully!`, duration: 2000, position: "bottom" });
                if (!isEditMode) resetForm();
            }
        } catch (error) {
            console.error(`Error ${isEditMode ? 'updating' : 'saving'} invoice:`, error);
            present({ message: `An error occurred while ${isEditMode ? 'updating' : 'saving'} invoice.`, duration: 2000, position: "bottom" });
        }
    }; 

    const resetForm = () => {
        setCustomerName('');
        setCustomerAddress('');
        setCustomerNumber('');
        setCustomerEmail('');
        setItems([]);
        setErrors({});
    };

    return (
        <IonPage>
            <IonHeader style={{ height: '68px' }}>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/manualbilling" />
                    </IonButtons>
                    <IonTitle>{isEditMode ? 'Update Invoice' : 'Create Invoice'}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className='ion-padding'>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle style={{ fontSize: "32px", textAlign: 'center' }}>Invoice</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12" size-md="6">
                                    <IonItem>
                                        <IonLabel position="floating">Customer Name</IonLabel>
                                        <IonInput value={customerName} onIonChange={e => setCustomerName(e.detail.value!)} />
                                    </IonItem>
                                    {validation.customerName && <IonText color="danger">{validation.customerName}</IonText>}

                                    <IonItem>
                                        <IonLabel position="floating">Customer Address</IonLabel>
                                        <IonInput value={customerAddress} onIonChange={e => setCustomerAddress(e.detail.value!)} />
                                    </IonItem>
                                    {validation.customerAddress && <IonText color="danger">{validation.customerAddress}</IonText>}

                                    <IonItem>
                                        <IonLabel position="floating">Customer Number</IonLabel>
                                        <IonInput type='tel' value={customerNumber} onIonChange={e => setCustomerNumber(e.detail.value!)} />
                                    </IonItem>
                                    {validation.customerPhone && <IonText color="danger">{validation.customerPhone}</IonText>}

                                    <IonItem>
                                        <IonLabel position="floating">Customer Email</IonLabel>
                                        <IonInput type='email' value={customerEmail} onIonChange={e => setCustomerEmail(e.detail.value!)} />
                                    </IonItem>
                                    {validation.customerEmail && <IonText color="danger">{validation.customerEmail}</IonText>}

                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                {items.length <= 1 ? <h1>Product</h1> : <h1>Products</h1>}
                            </IonCol>
                        </IonRow>
                        {items.map((item) => (
                            !isPlatform("capacitor") ? <IonCard key={item.id}>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="6" size-md="6">
                                                <IonItem>
                                                    <IonLabel position="floating">Product Name</IonLabel>
                                                    <IonInput value={item.name} onIonChange={e => handleItemChange(item.id, 'name', e.detail.value!)} />
                                                </IonItem>
                                                {validation.productName[item.id] && (
                                                    <IonText color="danger" style={{ fontSize: '12px' }}>
                                                        {validation.productName[item.id]}
                                                    </IonText>
                                                )}
                                            </IonCol>
                                            <IonCol size="6" size-md="3">
                                                <IonItem>
                                                    <IonLabel position="floating">Quantity</IonLabel>
                                                    <IonInput type="number" min={0} value={item.quantity} onIonChange={e => handleItemChange(item.id, 'quantity', parseFloat(e.detail.value!))} />
                                                </IonItem>
                                                {validation.quantityError[item.id] && (
                                                    <IonText color="danger" style={{ fontSize: '12px' }}>
                                                        {validation.quantityError[item.id]}
                                                    </IonText>
                                                )}
                                            </IonCol>
                                            <IonCol size="6" size-md="3">
                                                <IonItem>
                                                    <IonLabel position="floating">Price</IonLabel>
                                                    <IonInput type="number" min={0} value={item.price} onIonChange={e => handleItemChange(item.id, 'price', parseFloat(e.detail.value!))} />
                                                </IonItem>
                                                {validation.priceErrors[item.id] && (
                                                    <IonText color="danger" style={{ fontSize: '12px' }}>
                                                        {validation.priceErrors[item.id]}
                                                    </IonText>
                                                )}
                                            </IonCol>
                                            <IonCol size="6" size-md="3">
                                                <IonItem>
                                                    <IonLabel position="floating">Tax Type</IonLabel>
                                                    <IonSelect value={item.tax_type} onIonChange={e => handleItemChange(item.id, 'tax_type', e.detail.value!)}>
                                                        <IonSelectOption value="PERCENT">PERCENTAGE</IonSelectOption>
                                                        <IonSelectOption value="FLAT">FLAT</IonSelectOption>
                                                    </IonSelect>
                                                </IonItem>
                                            </IonCol>
                                            <IonCol size="4" size-md="3">
                                                <IonItem>
                                                    <IonLabel position="floating">Tax</IonLabel>
                                                    <IonInput type="number" value={item.tax} onIonChange={e => handleItemChange(item.id, 'tax', parseFloat(e.detail.value!))} />
                                                </IonItem>
                                            </IonCol>

                                            <IonCol size="4" size-md="3">
                                                <IonItem>
                                                    <IonLabel position="floating">Subtotal</IonLabel>
                                                    <IonInput value={item.subtotal.toFixed(2)} readonly />
                                                </IonItem>
                                            </IonCol>
                                            <IonCol size="2" size-md="3" className="ion-text-center">
                                                <IonButton shape="round" onClick={() => removeItem(item.id)} color="danger">
                                                    <IonIcon icon={trash} />
                                                </IonButton>
                                            </IonCol>

                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard> : <IonCard key={item.id}>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="12">
                                                <IonItem>
                                                    <IonLabel position="floating">Product Name</IonLabel>
                                                    <IonInput value={item.name} onIonChange={e => handleItemChange(item.id, 'name', e.detail.value!)} />
                                                </IonItem>
                                                {validation.productName[item.id] && (
                                                    <IonText color="danger" style={{ fontSize: '12px' }}>
                                                        {validation.productName[item.id]}
                                                    </IonText>
                                                )}
                                            </IonCol>
                                            <IonCol size="6">
                                                <IonItem>
                                                    <IonLabel position="floating">Quantity</IonLabel>
                                                    <IonInput type="number" value={item.quantity} onIonChange={e => handleItemChange(item.id, 'quantity', parseFloat(e.detail.value!))} />
                                                </IonItem>
                                                {validation.quantityError[item.id] && (
                                                    <IonText color="danger" style={{ fontSize: '12px' }}>
                                                        {validation.quantityError[item.id]}
                                                    </IonText>
                                                )}
                                            </IonCol>
                                            <IonCol size="6">
                                                <IonItem>
                                                    <IonLabel position="floating">Price</IonLabel>
                                                    <IonInput type="number" value={item.price} onIonChange={e => handleItemChange(item.id, 'price', parseFloat(e.detail.value!))} />
                                                </IonItem>
                                                {validation.priceErrors[item.id] && (
                                                    <IonText color="danger" style={{ fontSize: '12px' }}>
                                                        {validation.priceErrors[item.id]}
                                                    </IonText>
                                                )}
                                            </IonCol>
                                            <IonCol size="12">
                                                <IonItem>
                                                    <IonLabel position="floating">Tax Type</IonLabel>
                                                    <IonSelect value={item.tax_type} onIonChange={e => handleItemChange(item.id, 'tax_type', e.detail.value!)}>
                                                        <IonSelectOption value="PERCENT">PERCENTAGE</IonSelectOption>
                                                        <IonSelectOption value="FLAT">FLAT</IonSelectOption>
                                                    </IonSelect>
                                                </IonItem>
                                            </IonCol>
                                            <IonCol size="6">
                                                <IonItem>
                                                    <IonLabel position="floating">Tax</IonLabel>
                                                    <IonInput type="number" value={item.tax} onIonChange={e => handleItemChange(item.id, 'tax', parseFloat(e.detail.value!))} />
                                                </IonItem>
                                            </IonCol>

                                            <IonCol size="6">
                                                <IonItem>
                                                    <IonLabel position="floating">Subtotal</IonLabel>
                                                    <IonInput value={item.subtotal.toFixed(2)} readonly />
                                                </IonItem>
                                            </IonCol>
                                            <IonCol size="12" className="ion-text-center">
                                                <IonButton expand='block' shape="round" onClick={() => removeItem(item.id)} color="danger">
                                                    <IonIcon icon={trash} /> &nbsp;Remove
                                                </IonButton>
                                            </IonCol>

                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        ))}
                        <IonRow>
                            <IonCol size="6">
                                <IonButton onClick={addItem} shape="round">
                                    <IonIcon icon={add} />
                                    Product
                                </IonButton>
                            </IonCol>
                            {items.length !== 0 && <IonCol size="6" className="ion-text-end ion-align-self-center">
                                <h2 className="ion-no-margin">TOTAL : {Math.round(totalAmount)}</h2>
                            </IonCol>
                            }
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="ion-text-center">
                                <IonButton shape='round' onClick={handleSaveOrUpdate}>
                                    Generate Invoice
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                </IonCard>
            </IonContent>
            <IonLoading isOpen={loading} message={'Please wait...'} />
        </IonPage>
    );
};

export default Invoice;
