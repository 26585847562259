import { Directory } from "@capacitor/filesystem";
import {
    IonBackButton,
    IonButton,
    IonButtons, IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonPage, IonRow, IonTitle,
    IonToolbar,
    isPlatform
} from "@ionic/react";
import write_blob from "capacitor-blob-writer";
import React, { useContext, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useParams } from "react-router-dom";
import { getAccessToken } from "../services/auth/userLocalStorage";
import { UserContext } from "../utils/contexts";

const Invoice: React.FC = (props: any) => {
    const ppp = useParams();
    const { id } = useParams<any>();

    const [invoiceData, setInvoiceData] = useState<string>("")
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    
    const user: any = useContext(UserContext);
    const userDomain = user?.store?.subdomain;

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    useEffect(() => {
        const loadPdf = async () => {
            try {
                const accessToken = getAccessToken();
                const response = await fetch(
                    `https://api2.intelikart.in/orders/invoices/${id}`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            "x-ik-domain": userDomain
                        },
                    }

                );

                const myBlob = await response.blob();
                const url = window.URL.createObjectURL(new Blob([myBlob]));

                setInvoiceData(url);
            } catch (error) {
                alert(error);
            }
        };

        if(userDomain) {
            loadPdf();
        }
    }, [id]);

    const  handleDownload = async () => {
        if(isPlatform("capacitor")){
            try {
                const accessToken = getAccessToken();
                const response = await fetch(
                    `https://api2.intelikart.in/orders/invoices/${id}`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        },
                    }

                );

                const myBlob = await response.blob();
                try{
                    write_blob({
                    path:`invoice${id}.pdf`,
                    directory:Directory.Documents,
                    blob:myBlob
                })}
                catch(err){
                    alert(`blobbing ${err}`)
                }
            } catch (error) {
                alert(error);
            }
        }
        else{
            try {
                const accessToken = getAccessToken();
                const response = await fetch(
                    `https://api2.intelikart.in/orders/invoices/${id}`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        },
                    }
                );
        
                const blob = await response.blob();

                // Convert Blob to Uint8Array
                const arrayBuffer = await new Response(blob).arrayBuffer();
                const uint8Array = new Uint8Array(arrayBuffer);
        
                // Convert Uint8Array to Blob
                const blobFromUint8Array = new Blob([uint8Array]);

            } catch (error) {
                alert(error);
            }
        var link = document.createElement("a");
        link.href = invoiceData;
        link.download = "invoice.pdf";
        link.dispatchEvent(new MouseEvent("click"));}
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/orders" />
                    </IonButtons>
                    <IonTitle>Invoice #{id}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollX={true}>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonButton onClick={() => handleDownload()}>Download</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <Document
                    file={invoiceData}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} />
                </Document>
            </IonContent>
        </IonPage>
    );
};

export default Invoice;
