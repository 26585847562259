import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory } from 'react-router';
import { Route, useLocation } from 'react-router-dom';
import { authenticateUserWhenLogin, getCurrentUser } from './actions/authActions';
import { initializeFirebase } from './push/firebase';
import { deleteTokenObject, getAccessPermission, getAccessToken } from './services/auth/userLocalStorage';
import PrivacyPolicy from './content/PrivacyPolicy';
import ReturnPolicy from './content/ReturnPolicy';
import TermsOfService from './content/TermsOfservice';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import './App.css';

/* Theme variables */
import { getProfileCompletionData } from './actions/dashboardActions';
import GetBusinessDetails from './auth/GetBusinessDetails';
import Home from './auth/Home';
import SignUpWithEmailOTP from './auth/SignUpWithEmailOTP';
import DashboardMenu from './components/dashboard/DashboardMenu';
import WebsiteSettings from './dashboard/settings/website/WebsiteSettings';
import MainTabs from './MainTabs';
import SSO from './pages/SSO';
import RedirectToLogin from './RedirectToLogin';
import './theme/variables.css';
import { IsStaffContext, MediaLibraryContext, SharedContext, UserContext } from './utils/contexts';

const App: React.FC = () => {
    const [inventoryUpdate, setInventoryUpdate] = useState(false);
    const [orderManagementUpdate, setOrderManagementUpdate] = useState(false);
    const [productLevelTaxUpdate, setProductLevelTaxUpdate] = useState(false);
    const [shiprocketUpdate, setShiprocketUpdate] = useState(false);
    const [dynamicFieldsUpdate, setDynamicFieldsUpdate] = useState(false);
    const [scheduledOrderUpdate, setScheduledOrderUpdate] = useState(false);
    const [selfDeliveryUpdate, setSelfDeliveryUpdate] = useState(false);
    const [digitalProductUpdate, setdigitalProductUpdate] = useState(false);
    const [festivalUpdate, setFestivalUpdate] = useState(false);
    const [isStaff, setIsStaff] = useState(false);
    const [complementaryProductUpdate, setComplementaryProductUpdate] = useState(false);
    const [ondcUpdate, setOndcUpdate] = useState(false);

    setupIonicReact();
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { currentUser, currentUserError } = useSelector((state: any) => state.currentUser);
    //const { isUserAuthenticate } = useSelector((state : any)=> state.userAuthenticate);
    var style = { "--side-max-width": '30px' } as React.CSSProperties;
    let showMenu = false;
    if (getAccessToken()) {
        showMenu = true;
    }
    // register app for firebase push notifications
    initializeFirebase();


    useEffect(() => { loadCurrentUser() }, []);
    const loadCurrentUser = async () => {
        const token = await getAccessToken();
        if (token) {
            let permissions = getAccessPermission();
            let staff = (permissions && permissions.length === 0) ? "false" : "true"
            if(permissions && permissions.length === 0){
                setIsStaff(false);
            }else{
                setIsStaff(true);
            }
            await dispatch(getCurrentUser(token, staff));
        }
    }
    useEffect(() => {
        if (currentUser && currentUser.data) {
            dispatch(authenticateUserWhenLogin());
        }
        if (currentUser && currentUser.data && !isStaff) {
            dispatch(getProfileCompletionData(getAccessToken(), currentUser.data.store.subdomain));
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUserError && currentUserError.statusCode == 401 && currentUserError.message == "401 Unauthorized") {
            deleteTokenObject();
            history.push("/");
        }
    }, [currentUserError]);

    const [mediaLibrary, setMediaLibrary] = useState([]);
    const [uploadMediaLibrary, setUploadMediaLibrary] = useState(null);
    const [deleteMediaLibrary, setDeleteMediaLibrary] = useState(null);
    const [mediaLoading, setMediaLoading] = useState(false);
    const [allMedia, setAllMedia] = useState({});

    return (
        <UserContext.Provider value={currentUser?.data}>
            <SharedContext.Provider value={{ inventoryUpdate, setInventoryUpdate, orderManagementUpdate, setOrderManagementUpdate, productLevelTaxUpdate, setProductLevelTaxUpdate, shiprocketUpdate, setShiprocketUpdate, complementaryProductUpdate, setComplementaryProductUpdate, dynamicFieldsUpdate, setDynamicFieldsUpdate,scheduledOrderUpdate,setScheduledOrderUpdate,selfDeliveryUpdate, setSelfDeliveryUpdate,digitalProductUpdate,setdigitalProductUpdate,festivalUpdate,setFestivalUpdate, ondcUpdate, setOndcUpdate}}>
                <IsStaffContext.Provider value={{isStaff, setIsStaff}}>
                <MediaLibraryContext.Provider value={{mediaLibrary,uploadMediaLibrary,deleteMediaLibrary,mediaLoading,setMediaLibrary,setUploadMediaLibrary,setDeleteMediaLibrary,setMediaLoading,allMedia,setAllMedia}}>
                <IonApp>
                    <IonReactRouter>
                        <IonSplitPane contentId="main">
                            {showMenu === true && location.pathname !== '/' && location.pathname !== '/getbusinessdetails' && location.pathname !== '/appearance/ik-web-settings' && location.pathname !== '/appearance/ik-app-settings' && <DashboardMenu />}
                            <IonRouterOutlet id="main">
                                <Switch>
                                    <Route path="/" component={Home} exact />
                                    <Route path="/sso" component={SSO} exact />
                                    <Route path="/signupwithgmail" component={SignUpWithEmailOTP} />
                                    <Route path="/getbusinessdetails" component={GetBusinessDetails} />
                                    <Route path="/appearance/ik-web-settings" component={WebsiteSettings} />
                                    <Route path="/appearance/ik-app-settings" component={WebsiteSettings} />
                                    <Route path="/content/privacy-policy" component={PrivacyPolicy} />
                                    <Route path="/content/return-policy" component={ReturnPolicy} />
                                    <Route path="/content/terms-of-service" component={TermsOfService} />
                                    <Route path="/logout" render={() => {
                                        return <RedirectToLogin />;
                                    }} />
                                    <Route path="/" render={() => <MainTabs />} />
                                </Switch>
                            </IonRouterOutlet>
                        </IonSplitPane>
                    </IonReactRouter>
                </IonApp>
                </MediaLibraryContext.Provider>
                </IsStaffContext.Provider>
            </SharedContext.Provider>
        </UserContext.Provider>
    );
};

export default App;
