import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonPage, IonText, IonTitle, IonToggle, IonToolbar, useIonToast } from '@ionic/react';
import {
    arrowBack as arrowBackIcon,
    informationCircleOutline
} from 'ionicons/icons';
import { useState } from 'react';
import useAPI from '../../../utils/api';
import { useAddon } from '../../../utils/contexts';
import { indexedValue } from '../AddonsRoutes';
import FeatHeader from '../../../components/dashboard/FeatHeader';


const SelfDeliveryForm = () => {
    const addons:any = useAddon();
    const [present] = useIonToast();
    const API = useAPI();
    const [statusCheck, setStatusCheck] = useState(Array.isArray(addons[indexedValue.selfDelivery].data) ? false : addons[indexedValue.selfDelivery].data?.status === 'active');

    const onSubmitHandler = async(e)=>{
        e.preventDefault();
            const [ok, response] = Array.isArray(addons[indexedValue.selfDelivery].data) ? 
                await API.createSelfDeliveryAddon({
                    "status": statusCheck ? "active" : "inactive"
                })
                :
                await API.updateSelfDeliveryAddon({
                    "status": statusCheck ? "active" : "inactive",
                    id: addons[indexedValue.selfDelivery].data.id
                })
                if(response.statusCode === 400){
                    setStatusCheck(!statusCheck);
                    present(response.message, 1500);
                }
                if(ok) {
                    present("Data saved successfully!", 1500);
                    addons[indexedValue.selfDelivery].updateMethod(!addons[indexedValue.selfDelivery].update);

                }
    }

    const openLinkInNewTab = () => {
        window.open(`https://intelikart.tawk.help/article/self-delivery`, '_blank');
    };

    return (
        <IonPage>
          
            <FeatHeader title='Configure Self Delivery' defaultHref='/add-ons' showInfoButton={ true} onInfoClick={openLinkInNewTab} />
            <IonContent className="ion-padding">
                <form onSubmit={onSubmitHandler}>
                    <IonItem lines="none">
                        <IonText><h5>Enable Self Delivery</h5></IonText>
                        <IonToggle slot="end" checked={statusCheck} onIonChange={e => setStatusCheck(e.detail.checked)} />
                    </IonItem>
                    <br/>
                    <div className="ion-text-center">
                        <IonButton type="submit">Save</IonButton>
                    </div>
                </form>
            </IonContent>
        </IonPage>
    )
}

export default SelfDeliveryForm