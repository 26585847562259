import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonModal,
  IonText,
  IonThumbnail,
  IonTitle,
  IonToast,
} from "@ionic/react";
import React, { useState } from "react";
import "./addmedia.css";
import { closeOutline, copyOutline } from "ionicons/icons";
import { Clipboard } from "@capacitor/clipboard";

const MediaLibraryPopUp = ({ isOpen, onClose, media }) => {
  const [showToast, setShowToast] = useState(false);


  console.log("media m kya aa rha ", media);
  const handleCopy = () => {
    Clipboard.write({
        string: `${media.url}`
    });
    setShowToast(true);
  };

  return (
    <IonContent>
      <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader className="ion-text-start">
      <IonItem lines="none">
          <h4>Image Information</h4>
            <IonIcon
            style={{ cursor :"pointer"}}
            onClick={onClose}
          slot="end"
          color="primary"
          size="large"
          icon={closeOutline}>
          </IonIcon>
          </IonItem>
        </IonHeader>
        <IonLabel style={{ marginLeft: "20px", marginTop: "20px", color:"black" }}>
          Image
        </IonLabel>
        <IonThumbnail
          style={{ margin: "1px auto", height: "200px", width: "250px" }}
        >
          <IonImg src={media.url} alt="Card Image" style={{objectFit:"contain"}} />
        </IonThumbnail>
        <IonLabel style={{ marginLeft: "20px", marginTop: "20px",color:"black" }}>
          URL
        </IonLabel>
        <IonCard
          key={media.id}
          style={{ display: "flex", alignItems: "center" , paddingTop:"7px" , paddingLeft:"20px" , paddingRight:"20px" , paddingBottom:"7px" }}
        >
          <IonText
            style={{
              color:"black" ,
              flex: "1",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {media.url}
          </IonText>
          <IonIcon
            color="primary"
            size="large"
            icon={copyOutline}
            onClick={handleCopy}
            style={{ cursor: "pointer"  }}
          />
        </IonCard>
        
      </IonModal>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Media URL copied"
        duration={500}
      />
    </IonContent>
  );
};

export default MediaLibraryPopUp;
