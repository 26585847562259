import { IonButton, IonCheckbox, IonContent, IonHeader, IonIcon, IonItem, IonLoading, IonModal, IonText, useIonToast, IonTitle, IonFooter } from '@ionic/react';
import { add, closeOutline } from 'ionicons/icons';
import { useEffect, useState } from "react";
import useAPI from "../../../../../utils/api";
import { useWebsiteSettings } from "../../../../../utils/contexts";
import useFetch from '../../../../../utils/useFetch';

const StickyCategoryModal = ({ modalData, setModalData }) => {
    const API = useAPI();
    const settings: any = useWebsiteSettings();
    const [present] = useIonToast();

    const [checkedCategories, setCheckedCategories] = useState<any>([]);

    const { categoryLoading, data, status } = useFetch("categories", "active");
    const [categories, setCategories] = useState([])

    const handleStickyCategory = async () => {
        let data = { "categories": checkedCategories };
        const [ok, response] = await API.updateStickyCategory(data);

        if (ok && response) {
            present("Sticky Category Added successful!", 1000);

            setModalData({ ...modalData, open: false, });
            settings.setUpdate(!settings.update);
        }
    }

    const handleChecked = async (categoryId) => {
        if (checkedCategories.includes(categoryId)) {
            let temp = [...checkedCategories];
            temp = temp.filter(elem => categoryId !== elem)
            await setCheckedCategories(temp);
        }
        else {
            let temp = [...checkedCategories];
            temp.push(categoryId);
            await setCheckedCategories(temp);
        }
    }

    useEffect(() => {
        if (!categoryLoading && status === "fetched") {
            if (data?.data.length > 0) {
                setCategories([...data.data])
            }
        }
    }, [categoryLoading, data, status])

    useEffect(() => {
        let slides = settings?.websiteSettings?.sticky_category_sliders?.slides
        let temp = [];
        if (slides?.length > 0) {
            slides.forEach(category => {
                temp.push(category.id);
            });
        }
        setCheckedCategories(temp);
    }, [settings, modalData])

    return (
        <IonModal isOpen={modalData.open} onDidDismiss={e => setModalData({ ...modalData, open: false })}>
            <IonHeader>
                <IonItem lines="none">
                    <IonTitle>Select category</IonTitle>
                    <IonButton fill="clear" size="large" slot="end" onClick={(e) => setModalData({ ...modalData, open: false })}>
                        <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                    </IonButton>
                </IonItem>
            </IonHeader>
            <IonContent className="ion-padding">

                {!categoryLoading && status === "fetched" && categories
                    ? categories.map((parent) => {
                        return (
                            <div key={parent.id}>
                                <IonItem>
                                    <IonText color="medium">
                                        <h5>{parent.name}</h5>
                                    </IonText>
                                </IonItem>
                                {parent.subCategory.map((child) => {
                                    return (
                                        <IonItem
                                            key={child.id}
                                            lines="none"
                                            className="ion-padding-start"
                                            onClick={() => handleChecked(child.id)}
                                        >
                                            {child.name}
                                            <IonCheckbox checked={checkedCategories.includes(child.id)} onClick={() => handleChecked(child.id)} slot='end' />
                                        </IonItem>
                                    );
                                })}
                                <br />
                            </div>
                        );
                    })
                    : ""}


                <IonLoading isOpen={categoryLoading} message="Please Wait" />
            </IonContent>
            {checkedCategories?.length > 0 &&
                <IonFooter className='ion-text-center ion-padding'>
                    <IonButton onClick={() => handleStickyCategory()} shape='round'  ><IonIcon icon={add}></IonIcon> &nbsp; Add Category</IonButton>
                </IonFooter>
            }

        </IonModal>
    )
}
export default StickyCategoryModal
