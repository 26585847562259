import {
    IonButton,
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonText,
    IonTitle,
    isPlatform,
} from "@ionic/react";
import {
    caretDown as caretDownIcon,
    closeOutline,
    informationCircleOutline
} from "ionicons/icons";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSharedData } from "../../../utils/contexts";
import useFetch from "../../../utils/useFetch";

const modules = {
    toolbar: [
        [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }],
        ["bold", "italic", "underline", "sttrike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        ["clean"],
    ],
};

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
];

const unitArray = [
    "piece",
    "kg",
    "gm",
    "ml",
    "litre",
    "mm",
    "ft",
    "meter",
    "sq. ft.",
    "sq. meter",
    "km",
    "set",
    "hour",
    "day",
    "bunch",
    "bundle",
    "month",
    "year",
    "service",
    "work",
    "packet",
    "box",
    "pound",
    "dozen",
    "gunta",
    "pair",
    "minute",
    "quintal",
    "ton",
    "capsule",
    "tablet",
    "plate",
    "inch",
];

const ProductDetailForm = ({dataObject, validation, setTableData,productType}) => {
    const sharedData: any = useSharedData();
    const { data } = useFetch("inventoryAddon", sharedData.inventoryUpdate);
    const [showUnitModal, setShowUnitModal] = useState(false);
    function changeTableDataWithForm(name, val) {
        setTableData((prev) => {
            let temp = {...prev};
            for (let item of Object.keys(temp)) {
                temp[item][name] = val;
            }
            return temp;
        })
    }
    return (
        <>
            <IonCard>
            <IonItem lines="inset" className="ion-padding-horizontal zero-padding-horizontal">
                <IonLabel position="stacked" className="ion-padding-bottom"><h1 className="label">Product Name</h1></IonLabel>
                <IonInput
                    placeholder="Enter product name"
                    type="text"
                    value={dataObject.name}
                    onIonChange={(e) => dataObject.setName(e.detail.value)}
                />
            </IonItem>
            {validation.name && (
                <IonText color="danger">
                    <h5 className="ion-margin-horizontal error-text">
                        {validation.name}
                    </h5>
                </IonText>
            )}

            <IonItem lines="none"  className="ion-padding-horizontal zero-padding-horizontal">
                <IonLabel position="stacked"><h1 className="label">Description</h1></IonLabel>
            </IonItem>

            <IonRow className="ion-justify-content-center">
            <IonCol className="ion-padding-horizontal ion-margin-horizontal zero-padding-horizontal">
            <ReactQuill
                placeholder="Write product description here."
                modules={modules}
                formats={formats}
                value={dataObject.description}
                onChange={(e) => dataObject.setDescription(e)}
            />

            </IonCol>
            </IonRow>
            
            <IonText style={{display:"inline-flex", gap:"8px",paddingTop:"10px"}} color="dark" className="ion-margin-start ion-padding-start">
                <IonIcon icon={informationCircleOutline} 
                style={{ fontSize:"22px"}}/>
                Emojis are not allowed.
            </IonText>

            
                <IonItem lines="inset" className="ion-padding-horizontal zero-padding-horizontal">
                    <IonLabel position="stacked" className="ion-padding-bottom"><h1 className="label">Selling Price</h1></IonLabel>
                    <IonInput
                        placeholder="Enter selling price"
                        type="text"
                        value={dataObject.sellingPrice}
                        onIonChange={(e) => {
                            dataObject.setSellingPrice(e.detail.value)
                        }}
                        onIonBlur={e=> changeTableDataWithForm("sellingPrice", dataObject.sellingPrice)}
                    />
                </IonItem>
                {validation.sellingPrice && (
                    <IonText color="danger">
                        <h5 className="ion-margin-horizontal error-text">
                            {validation.sellingPrice}
                        </h5>
                    </IonText>
                )}
                    
                <IonItem lines="inset" className="ion-padding-horizontal zero-padding-horizontal">
                    <IonLabel position="stacked" className="ion-padding-bottom"><h1 className="label">MRP</h1></IonLabel>
                    <IonInput
                        placeholder="Enter MRP"
                        type="tel"
                        value={dataObject.mrp}
                        onIonChange={(e) => {
                            dataObject.setMrp(e.detail.value)
                        }}
                        onIonBlur={e=> changeTableDataWithForm("Mrp", dataObject.mrp)}
                    />
                </IonItem>
                {validation.Mrp && (
                    <IonText color="danger">
                        <h5 className="ion-margin-horizontal error-text">
                            {validation.Mrp}
                        </h5>
                    </IonText>
                )}
            {productType==="PHYSICAL" &&<IonItem lines="inset" className="ion-padding-horizontal zero-padding-horizontal">
                <IonLabel position="stacked" className="ion-padding-bottom"><h1 className="label">Quantity</h1></IonLabel>
                <IonInput
                    placeholder="E.g.- 1, 100, 200, 500, etc."
                    type="text"
                    value={dataObject.quantity}
                    onIonChange={(e) => dataObject.setQuantity(e.detail.value)}
                />
            </IonItem>}
            {validation.quantity && (
                <IonText color="danger">
                    <h5 className="ion-margin-horizontal error-text">
                        {validation.quantity}
                    </h5>
                </IonText>
            )}
            {productType==="PHYSICAL" &&<IonText className="ion-padding-start">
                <a
                    style={{ textDecoration: "none", fontSize: 14 + "px" , display:"inline-flex", gap:"8px", paddingTop:"5px"  }}
                    target="_blank"
                    rel="noreferrer"
                    href="http://cdn.intelikart.com/uploads/demoimage%20for%20product%20add.png"
                >
                    <IonIcon icon={informationCircleOutline} className="ion-padding-start"  style={{fontSize:"22px"}} />

                    View Sample
                </a>
            </IonText>}
            {productType==="PHYSICAL" &&<br />}

            {productType==="PHYSICAL" &&<IonItem lines="inset" className="ion-padding-horizontal zero-padding-horizontal">
                    <IonLabel position="stacked"><h1 className="label">Quantity unit Name</h1></IonLabel>
                    <IonItem
                        lines="none"
                        className="ion-no-padding"
                        style={{ width: 100 + "%", marginTop: 4 + "px" }}
                        onClick={(e) => setShowUnitModal(true)}
                    >
                        <IonLabel color={dataObject.qtyUnit ? "dark" : "medium"}>
                            {dataObject.qtyUnit
                                ? `${dataObject.qtyUnit}`
                                : "E.g.- Kg, Gm, XL, XXL, Item, Piece etc."}
                        </IonLabel>
                        <IonIcon color="primary" slot="end" size="small" icon={caretDownIcon} />
                    </IonItem>
                </IonItem>}
                 <IonModal
                    isOpen={showUnitModal}
                    onDidDismiss={(e) => setShowUnitModal(false)}
                >
                    <IonHeader>
                        <IonItem lines="none">
                            <IonTitle>Choose product unit</IonTitle>
                            <IonButton
                                fill="clear"
                                size="large"
                                slot="end"
                                onClick={(e) => setShowUnitModal(false)}
                            >
                                <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                            </IonButton>
                        </IonItem>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <IonGrid>
                            <IonRow>
                                {unitArray?.map((value) => {
                                    return (
                                        <IonCard
                                            onClick={(e) => {
                                                dataObject.setQtyUnit(value);
                                                setShowUnitModal(false);
                                            }}
                                            style={{
                                                backgroundColor: `${value === dataObject.qtyUnit
                                                    ? "rgba(56, 128, 255, 0.12)"
                                                    : "white"
                                                    }`,
                                                border: `${value === dataObject.qtyUnit
                                                    ? "1px solid rgb(56, 128, 255)"
                                                    : "none"
                                                    }`,
                                            }}
                                        >
                                            <IonText
                                                color={value === dataObject.qtyUnit ? "primary" : ""}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <h6
                                                    className="ion-no-margin"
                                                    style={{ margin: 10 + "px" }}
                                                >
                                                    {value}
                                                </h6>
                                            </IonText>
                                        </IonCard>
                                    );
                                })}
                            </IonRow>
                        </IonGrid>
                    </IonContent>
                </IonModal>
                {validation.qtyUnit && (
                    <IonText color="danger">
                        <h5
                            style={{ fontSize: 12 + "px", marginTop: 0 + "px" }}
                            className="ion-margin-horizontal"
                        >
                            {validation.qtyUnit}
                        </h5>
                    </IonText>
                )}
                {productType==="PHYSICAL" &&
                <IonText className="ion-padding-start">
                    <a
                        style={{ textDecoration: "none", fontSize: 14 + "px", display:"inline-flex" , gap:"8px", paddingTop:"5px" }}
                        target="_blank"
                        href="http://cdn.intelikart.com/uploads/demoimage%20for%20product%20add.png"
                        rel="noreferrer"
                    >

                    <IonIcon icon={informationCircleOutline} className="ion-padding-start" style={{fontSize:"22px"}} >
                    </IonIcon>

                        View Sample
                    </a>
                </IonText>}
                {productType==="PHYSICAL" &&<IonItem lines="inset" className="ion-padding-horizontal zero-padding-horizontal">
                    <IonLabel position="stacked" className="ion-padding-bottom"><h1 className="label">Product SKU</h1></IonLabel>
                    <IonInput
                        placeholder="Enter product sku id"
                        type="text"
                        value={dataObject.skuId}
                        onIonChange={(e) => dataObject.setSkuId(e.detail.value)}
                    />
                </IonItem>}
                {productType==="PHYSICAL" &&<IonItem lines="inset" className="ion-padding-horizontal zero-padding-horizontal">
                    <IonLabel position="stacked" className="ion-padding-bottom"><h1 className="label">{isPlatform('capacitor') ? "Min. Order Quantity" : "Minimum Order Quantity"}</h1></IonLabel>
                    <IonInput
                        
                        placeholder="Enter minimum order quantity"
                        type="text"
                        value={dataObject.minQty}
                        onIonChange={(e) => dataObject.setMinQty(e.detail.value)}
                    />
                </IonItem>}
                {validation.minQty && (
                    <IonText color="danger">
                        <h5
                            style={{ fontSize: 12 + "px", marginTop: 0 + "px" }}
                            className="ion-margin-horizontal"
                        >
                            {validation.minQty}
                        </h5>
                    </IonText>
                )}
                {productType==="PHYSICAL" &&<IonItem lines="inset" className="ion-padding-horizontal zero-padding-horizontal">
                    <IonLabel position="stacked" className="ion-padding-bottom"><h1 className="label">{isPlatform('capacitor') ? "Max. Order Quantity" : "Maximum Order Quantity"}</h1></IonLabel>
                    <IonInput
                        placeholder="Enter maximum order quantity"
                        type="text"
                        value={dataObject.maxQty}
                        onIonChange={(e) => dataObject.setMaxQty(e.detail.value)}
                    />
                </IonItem>}
                {validation.maxQty && (
                    <IonText color="danger">
                        <h5
                            style={{ fontSize: 12 + "px", marginTop: 0 + "px" }}
                            className="ion-margin-horizontal"
                        >
                            {validation.maxQty}
                        </h5>
                    </IonText>
                )}
                {productType==="PHYSICAL" &&<IonItem lines="inset" className="ion-padding-horizontal zero-padding-horizontal">
                    <IonLabel position="stacked" className="ion-padding-bottom"><h1 className="label">Product Video Link</h1></IonLabel>
                    <IonInput
                        placeholder="Enter embedded links only"
                        type="url"
                        value={dataObject.video}
                        onIonChange={(e) => dataObject.setVideo(e.detail.value)}
                        onIonBlur={e=> changeTableDataWithForm("video", dataObject.video)}
                    />
                </IonItem>}
                {productType==="PHYSICAL" && data?.data?.status === "active" && (
                    <>
                        <IonItem lines="inset" className="ion-padding-horizontal ion-margin-bottom zero-padding-horizontal">
                            <IonLabel position="stacked" className="ion-padding-bottom"><h1 className="label">Available Quantity</h1></IonLabel>
                            <IonInput
                                placeholder="Enter available quantity"
                                type="text"
                                value={dataObject.avlQty}
                                onIonChange={(e) => {
                                    dataObject.setAvlQty(e.detail.value);
                                }}
                                onIonBlur={e=> changeTableDataWithForm("avlQty", dataObject.avlQty)}
                            />
                        </IonItem>
                        {validation.avlQty && (
                            <IonText color="danger">
                                <h5
                                    style={{ fontSize: 12 + "px", marginTop: 0 + "px" }}
                                    className="ion-margin-horizontal"
                                >
                                    {validation.avlQty}
                                </h5>
                            </IonText>
                        )}
                        
                    </>
                )}
                </IonCard>
        </>
    );
};

export default ProductDetailForm;
