import { IonPage, IonRouterOutlet } from "@ionic/react";
import { useState } from "react";
import { Route } from "react-router";
import { StaffContext } from "../../utils/contexts";
import CreateStaff from "./CreateStaff";
import StaffListing from "./StaffListing";

const Staff = () => {
    const [update, setUpdate] = useState(false);

    return (
        <StaffContext.Provider value={{update, setUpdate}}>
            <IonPage>
                <IonRouterOutlet>
                    <Route path="/staffs/create" exact={true}>
                        <CreateStaff />
                    </Route>
                    <Route path="/staffs/update" exact={true}>
                        <CreateStaff />
                    </Route>
                    <Route path="/staffs" exact={true}><StaffListing /></Route>
                </IonRouterOutlet >
            </IonPage>
        </StaffContext.Provider>
    )
}

export default Staff