import { IonCard, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, IonText } from '@ionic/react';
import {
    apps as appsIcon,
    chatboxEllipsesOutline as chatboxIcon, colorPaletteOutline as colorPaletteOutlineIcon,
    globeOutline as globeOutlineIcon, informationCircle as informationCircleIcon, logoUsd as logoUsdIcon,
    newspaperOutline as newspaperOutlineIcon,
    walletOutline as walletOutlineIcon,
    keypadOutline as dynamicOutlineIcon,
    locationSharp, constructOutline,
    timeOutline,
    peopleOutline
} from 'ionicons/icons';
import { useHistory } from 'react-router';
import Title from '../../components/dashboard/Header';
import { useUser } from '../../utils/contexts';

const Settings = () => {
    const history = useHistory();
    const user:any = useUser();
    
    return (
        <IonPage>
                <Title name="Settings"/>
                <IonContent>
                    <IonGrid>
                        <IonRow className="ion-justify-content-start">
                            <IonCol>
                                <IonCard routerLink="/settings/basicinfo" style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4' >
                                            <IonIcon icon={informationCircleIcon} size="large" style={{width: '100%', height: '60px'}}/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>Basic Info</h3>
                                            </IonText>
                                            <ul>
                                                <li>Logo</li>
                                                <li>Business Name</li>
                                                <li>GSTIN</li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol>

                            <IonCol>
                                <IonCard routerLink="/settings/localization" style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4' >
                                        <IonIcon style={{width: '100%', height: '60px'}} icon={globeOutlineIcon} size="large"/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>Localization</h3>
                                            </IonText>
                                            <ul>
                                                <li>Address</li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol>

                            <IonCol>
                                <IonCard routerLink="/settings/servicedetails" style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4' >
                                            <IonIcon style={{width: '100%', height: '60px'}} icon={appsIcon} size="large"/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>Service Details</h3>
                                            </IonText>
                                            <ul>
                                                <li>Minimum Order</li>
                                                <li>Store Timings</li>
                                                <li>Delivery Range</li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol>

                            <IonCol>
                                <IonCard routerLink="/settings/taxes" style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4' >
                                        <IonIcon style={{width: '100%', height: '60px'}} icon={logoUsdIcon} size="large"/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>{'Taxes & Charges'}</h3>
                                            </IonText>
                                            <ul>
                                                <li>{'Taxes & charges'}</li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol>

                            <IonCol>
                                <IonCard routerLink="/settings/payments" style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4' >
                                        <IonIcon style={{width: '100%', height: '60px'}} icon={walletOutlineIcon} size="large"/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>Order Payments</h3>
                                            </IonText>
                                            <ul>
                                                <li>Mode of Payments</li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol>

                            <IonCol>
                                <IonCard routerLink="/settings/communications" style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4' >
                                        <IonIcon style={{width: '100%', height: '60px'}} icon={chatboxIcon} size="large"/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>Communications</h3>
                                            </IonText>
                                            <ul>
                                                <li>Push Notifications</li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol>

                            {/* <IonCol>
                                <IonCard routerLink="/settings/themes" style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4' >
                                        <IonIcon style={{width: '100%', height: '60px'}} icon={cardOutlineIcon} size="large"/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>Themes</h3>
                                            </IonText>
                                            <ul>
                                                <li>Themes</li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol> */} 

                            {/* <IonCol>
                                <IonCard routerLink="/settings/branding-colors" style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4'>
                                        <IonIcon style={{width: '100%', height: '60px'}} icon={colorPaletteOutlineIcon} size="large"/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>Brand Colors</h3>
                                            </IonText>
                                            <ul>
                                                <li>Brand Colors</li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol> */}

                            {/* <IonCol>
                                <IonCard onClick={e=> history.push({ pathname: `/settings/ik-web-app-settings`, state: {forApp: false}})} style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4' >
                                            <IonIcon icon={constructOutline} size="large" style={{width: '100%', height: '60px'}}/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>Website Settings</h3>
                                            </IonText>
                                            <ul>
                                                <li>Component adjustments in website</li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol>

                            <IonCol>
                                <IonCard onClick={e=> history.push({ pathname: `/settings/ik-web-app-settings`, state: {forApp: true}})} style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4' >
                                            <IonIcon icon={constructOutline} size="large" style={{width: '100%', height: '60px'}}/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>App Settings</h3>
                                            </IonText>
                                            <ul>
                                                <li>Component adjustments in mobile app</li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol> */}
                            <IonCol>
                                <IonCard routerLink="/settings/branding" style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4' >
                                        <IonIcon style={{width: '100%', height: '60px'}} icon={newspaperOutlineIcon} size="large"/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>Branding</h3>
                                            </IonText>
                                            <ul>
                                                <li>Write Your Own Tag line</li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol>
                            <IonCol>
                                <IonCard routerLink="/settings/social-profiles" style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4' >
                                        <IonIcon style={{width: '100%', height: '60px'}} icon={peopleOutline} size="large"/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>Social Profiles</h3>
                                            </IonText>
                                            <ul>
                                                <li>Your Social Profiles</li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol>

                            <IonCol>
                                <IonCard routerLink="/settings/dynamic-order-status" style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4' >
                                        <IonIcon style={{width: '100%', height: '60px'}} icon={dynamicOutlineIcon} size="large"/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>Dynamic Order Status</h3>
                                            </IonText>
                                            <ul>
                                                <li>Manage your order status</li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol>   

                            <IonCol>
                                <IonCard routerLink="/settings/show-delivery-order" style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4' >
                                        <IonIcon style={{width: '100%', height: '60px'}} icon={locationSharp} size="large"/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>Delivery order</h3>
                                            </IonText>
                                            <ul>
                                                <li>Enter your Delivery area zip code</li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol>  
                            <IonCol>
                                <IonCard routerLink="/settings/store-maintenance" style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4' >
                                        <IonIcon style={{width: '100%', height: '60px'}} icon={constructOutline} size="large"/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>Store maintenance</h3>
                                            </IonText>
                                            <ul>
                                                <li>Handle Store offline & Under maintenance</li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol>  
                            <IonCol>
                                <IonCard routerLink="/settings/config-time-slot" style={{minWidth: 360+'px'}} className='ion-padding-horizontal'>
                                    <IonRow style={{minHeight: '143px'}} className="ion-align-items-center">
                                        <IonCol size='4'>
                           
                                        <IonIcon style={{width: '100%', height: '60px'}} icon={timeOutline} size="large"/>
                                        </IonCol>
                                        <IonCol>
                                            <IonText>
                                                <h3>Configuration Time slot </h3>
                                            </IonText>
                                            <ul>
                                                <li>Manage your Schedule time slot </li>
                                            </ul>
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            </IonCol> 
                       

                        </IonRow>
                    </IonGrid>
                </IonContent>
        </IonPage>
    )
}

export default Settings

