import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { arrowBack as arrowBackIcon } from "ionicons/icons";
import { useState } from "react";
import useAPI from "../../../utils/api";
import { useAddon } from "../../../utils/contexts";
import { indexedValue } from '../AddonsRoutes';
import FeatHeader from '../../../components/dashboard/FeatHeader';


const SeoForm = () => {
  const addons: any = useAddon();
  const [present] = useIonToast();
  const API = useAPI();

  const [title, setTitle] = useState(Array.isArray(addons[indexedValue.seo].data) ? '' : addons[indexedValue.seo].data?.title);
  const [description, setDescription] = useState(Array.isArray(addons[indexedValue.seo].data) ? '' : addons[indexedValue.seo].data?.description);
  const [keyword, setKeyword] = useState(Array.isArray(addons[indexedValue.seo].data) ? '' : addons[indexedValue.seo].data?.keyword);
  const [seoCheck, setSEOCheck] = useState(Array.isArray(addons[indexedValue.seo].data) ? false : addons[indexedValue.seo].data?.status === 'active');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      title,
      description,
      keyword,
      status: seoCheck ? "active" : "inactive"
    };
    const [ok, response] = Array.isArray(addons[indexedValue.seo].data) ? 
      await API.createSeoAddon(data)
      : 
      await API.createSeoAddon({...data, id: addons[indexedValue.seo].data.id});

      if(ok) {
          present("Data saved successfully!", 1000);
          addons[indexedValue.seo].updateMethod(!addons[indexedValue.seo].update);
      }
  };

  return (
    <IonPage>
      
      <FeatHeader title='Configure SEO ' defaultHref="/add-ons" showInfoButton={false} />
      <IonContent className="ion-padding">
        <form onSubmit={handleSubmit}>
          <IonItem>
            <IonLabel position="stacked">
              <h1>SEO Title</h1>
            </IonLabel>
            <IonInput
              placeholder="Enter SEO Title"
              type="text"
              value={title}
              onIonChange={(e) => setTitle(e.detail.value)}
              required
            />
          </IonItem>
          <br />
          <IonItem>
            <IonLabel position="stacked">
              <h1>SEO Description</h1>
            </IonLabel>
            <IonInput
              placeholder="Enter SEO Description"
              type="text"
              value={description}
              onIonChange={(e) => setDescription(e.detail.value)}
            />
          </IonItem>
          <br />
          <IonItem>
            <IonLabel position="stacked">
              <h1>SEO Keyword</h1>
            </IonLabel>
            <IonInput
              placeholder="Enter SEO Keyword"
              type="text"
              value={keyword}
              onIonChange={(e) => setKeyword(e.detail.value)}
            />
          </IonItem>
          <br />
          <IonItem lines="none">
            <IonText><h5>Enable SEO</h5></IonText>
            <IonToggle slot="end" checked={seoCheck} onIonChange={e => setSEOCheck(e.detail.checked)} />
          </IonItem>
          <br/>
          <div className="ion-text-center">
            <IonButton type="submit">Save</IonButton>
          </div>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default SeoForm;
