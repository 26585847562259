import React from 'react';
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonIcon,
} from '@ionic/react';
import { arrowBackOutline as Back } from 'ionicons/icons';
import { useHistory } from 'react-router';

const ReturnPolicy: React.FC = () => {
    const history = useHistory()
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
                    <IonIcon icon={Back} onClick={(e)=>{history.goBack()}} style={{fontSize:"25px",cursor:"pointer",padding:"5px"}}></IonIcon>
          </IonButtons>
          <IonTitle>Return Policy</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div style={{width:"90%",margin:"auto"}}>
          <h2>Return Policy</h2>
          <p>At Intelikart, we strive to provide you with a seamless and satisfying shopping experience. We understand that there may be occasions when you need to return a product, and we are here to make that process as straightforward as possible. Please take a moment to review our return policy to ensure a smooth and hassle-free return process.</p>
          <b>1. Returns Eligibility:</b>
          <ul>
            <li>We accept returns within 7 days of the purchase date.</li>
            <li>To be eligible for a return, the item must be unused, in its original packaging, and in the same condition as when you received it.</li>
          </ul>

          <b>2. How to Initiate a Return:</b>
          <ul>
            <li>To initiate a return, please contact our customer service team at +91-8448355548.</li>
            <li>Provide your order number and a detailed reason for the return.</li>
            <li>Our customer service team will guide you through the return process, which may include issuing a Return Merchandise Authorization (RMA) number.</li>
          </ul>

          <b>3. Return Shipping:</b>
          <ul>
            <li>Customers are responsible for the cost of return shipping, unless the return is due to an error on our part or a defective product.</li>
            <li>We recommend using a trackable shipping method to ensure your return is received.</li>
          </ul>

          <b>4. Refunds:</b>
          <ul>
            <li>Once your return is received and inspected, we will notify you of the approval or rejection of your refund.</li>
            <li>If approved, your refund will be processed within 2 days and credited back to the original payment method.</li>
            <li>Shipping fees, if applicable, are non-refundable.</li>
          </ul>

          <b>5. Exchanges:</b>
          <ul>
            <li>If you wish to exchange an item for a different size, color, or product, please contact our customer service team.</li>
            <li>Exchanges are subject to product availability.</li>
          </ul>
          
          <b>6. Damaged or Defective Items:</b>
          <ul>
            <li>If you receive a damaged or defective item, please contact us immediately, and we will arrange for a replacement or refund.</li>
          </ul>

          <b>7. Non-Returnable Items:</b>
          <ul><li> Some items are non-returnable,</li></ul>

          <b>8. Cancellations:</b>
          <ul>
            <li>Orders may be canceled within 1 hours of purchase. After this period, the order may have already been processed and shipped.</li>
          </ul>
          <b>9. Final Sale Items:</b>
          <ul>
            <li>Items marked as "Final Sale" cannot be returned or exchanged.</li>
          </ul>
          <b>10. Warranty:</b>
          <ul>
            <li>For products with a manufacturer's warranty, please refer to the warranty information provided with the product.</li>
          </ul>
          <b>11. Changes to this Policy:</b>
          <p>
            Intelikart reserves the right to update or modify this return policy at any time.
            Any changes will be posted on our website.
            By making a purchase on our website, you agree to abide by this return policy.
          </p>

          <p>
            We appreciate your business and are committed to providing you with high-quality products and excellent customer service.
            If you have any questions or need assistance with a return, please don't hesitate to contact our customer service team.
          </p>

          <p>- Thank you for choosing Intelikart for your Business needs.</p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ReturnPolicy;
