import { IonButton, IonIcon } from '@ionic/react';
import { Share } from '@capacitor/share';
import {
    shareSocialOutline as shareIcon
} from 'ionicons/icons';
import React from 'react';

export const ShareButtonMobile = (props: any) => {
    const shareContent = async () => {
        if (props && props.title && props.text) {
            const shareObject = {
                title: `${props.title}`,
                text: `${props.text}`,
                ...(props.url !== null && props.url !== undefined ? { url: `${props.url}` } : {})
            };
    
            await Share.share(shareObject);
        }
    }
    return (
        <IonButton fill='clear'color='secondary' slot='end' onClick={(e) => shareContent()} >
                <IonIcon slot="icon-only"    icon={shareIcon}  ></IonIcon> 
        </IonButton>
        // <IonIcon size="large" icon={shareIcon} onClick={(e) => shareContent()}></IonIcon>
    )
}
