import React, { useState } from 'react';
import { IonContent, IonInput, IonButton, IonLabel, IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton, IonCol, IonRow, IonIcon, IonGrid, IonItem, IonFooter, useIonToast } from '@ionic/react';
import {
  arrowBack as arrowBackIcon,
  trash as trashIcon,
} from "ionicons/icons";
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken } from "../../services/auth/userLocalStorage";
import { postDeliveryOrder } from '../../actions/dashboardActions';
import FeatHeader from '../../components/dashboard/FeatHeader';



import { useHistory } from 'react-router';
const DeliveryOrder: React.FC = () => {
  // State to store input values
  const [zipcode, setZipcode] = useState<string[]>(['']);
  const { currentUser } = useSelector((state: any) => state.currentUser);
  const { createDeliveryOrder } = useSelector((state: any) => state.createDeliveryOrder);
  const dispatch=useDispatch();
  const [isSaved, setIsSaved] = useState(false);
  const [present] = useIonToast();
  const history = useHistory();
const [loading,setLoading]=useState(false);
  // Function to handle button click and add a new input box


  const handleButtonClick = () => {
    setZipcode([...zipcode, '']);
  };


  // Function to handle input value change
  const handleInputChange = (index: number, value: string) => {
   
    
   
      const updatedInputValues = [...zipcode];
      updatedInputValues[index] = value;
      setZipcode(updatedInputValues);
    
  };
  

  // Function to handle delete button click
  const handleDeleteButtonClick = (index: number) => {
    const updatedInputValues = [...zipcode];
    updatedInputValues.splice(index, 1);
    setZipcode(updatedInputValues);
  };

  const handleClick = async (e: any) => {
    e.preventDefault();
    // setLoading(true);
    const areAllValuesValid = zipcode.every((value) => /^\d{6}$/.test(value));

    // const res = await validate();
    if (areAllValuesValid){ 

      dispatch(
        postDeliveryOrder(getAccessToken(), currentUser.data.store.subdomain, {
          zipcode
        })
      );
      setIsSaved(true);
      setLoading(false);
      present({
        message: "Saved successfully!",
        duration: 2000,
        position: "bottom",
      });
      console.log("lo",loading,"cr",createDeliveryOrder);
      setZipcode([''])

  if (loading === false && createDeliveryOrder ) {
      history.goBack();
  }
     
    }else {
    setIsSaved(false);
    present({
      message: "Please enter valid 6-digit numbers in all fields.",
      duration: 2000,
      position: "bottom",
    });
    
  }

    
    // if(!inputValues || inputValues.replace(/<(.|\n)*?>/g, "").trim().length === 0){
    //   setIsSaved(false)
    // }
    // setTimeout(() => {
    //   setIsSaved(false);
    // }, 1000);
  };

  return (
    <IonPage>
      <FeatHeader
        title='Add Delivery Pin code'
        defaultHref='/settings'

      />

      <IonContent className="ion-padding">
        {console.log("input", zipcode)}
        <IonGrid>
          <h1>Enter your Delivery Pin code</h1>
          {zipcode.map((value, index) => (
            <IonItem key={index}>
              <IonInput
                value={value}
                type='number'
                placeholder={`Enter your Delivery Pin code  ${index + 1}`}
                onIonChange={(e) => handleInputChange(index, e.detail.value!)}
                className="bottom-border-input"
                style={{ width: "70%" }}
              ></IonInput>
              <IonButton
                fill="clear"
                color="secondary"
                onClick={() => handleDeleteButtonClick(index)}
              >
                <IonIcon
                  slot="icon-only"
                  icon={trashIcon}
                ></IonIcon>
              </IonButton>
            </IonItem>
          ))}

          {/* Add More button to the right of the input box */}
          <IonRow className="ion-align-items-center">
            <IonCol className='ion-text-center' >
              <IonButton  shape="round" onClick={handleButtonClick}>Add More</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonFooter>
        <IonRow className="ion-align-items-center">
          <IonCol className='ion-text-center'>
            {/* Save button centered at the bottom */}
            <IonButton  shape="round"
              onClick={(e) => {
                handleClick(e);
              }}
            >
              Save
            </IonButton>
          </IonCol>
        </IonRow>
      </IonFooter>
    </IonPage>
  );
};

export default DeliveryOrder;
