import { IonButton, IonLoading,  useIonToast } from "@ionic/react";
import CartProducts from "./CartProduct";
import CustomerDetails from "./CustomerDetails";
import { StoreCurrency } from "../../utils/currency";
import { useHistory } from "react-router-dom";

import {  useState } from "react";
import useAPI from "../../utils/api";
import { useCustomer, useCart, useOrderStatusChangeData } from "../../utils/contexts";
const RightBar = ({ productInCart, setProductInCart }) => {
  const API = useAPI();
  const [loading, setLoading] = useState(false);
  const [present] = useIonToast();
  const currency = StoreCurrency();
  const { customer } = useCustomer();
  const history = useHistory();
  const { myCartData, setMyCartData } = useCart();

	const {setFilterData, setFilterOrderData, setPageCountData}: any = useOrderStatusChangeData();

  const deleteCart = async () => {
    try {
      const [ok, response] = await API.deleteCart({
        user_id: customer?.userId,
      });

      if (ok && response) {
        setMyCartData(response.data);
      } else {
        if (response && response.error) {
          alert(response.error);
        }
      }
    } catch {
    }
  };
  const CreateOrder = async () => {
    try {
      setLoading(true);
      const [ok, response] = await API.createSellerOrder({
        user_id: customer.userId,
        user_name: customer.name,
        email: customer.email,
        phone: customer.user_phone,
      });
      if (ok && response) {
        deleteCart();
        present("Order has been added successfully!", 2000)

        setFilterOrderData({});
        setPageCountData({ALL: 1});
        setFilterData("ALL");

        history.push({ pathname: "/orders", state: true })
      } else {
        if (response && response.error) {
          alert(response.error);
        }
      }
    } catch (error) {
      console.error("Error creating order:", error);
      setLoading(false);
    }
  };

  const handleSave = () => {
    CreateOrder();
  };
  return (
    <>
      {/* Add & Display Customer */}
      <CustomerDetails />
      {/* Display product in cart */}
      <CartProducts
        customer={customer}
        currency={currency}
        productInCart={productInCart}
        setProductInCart={setProductInCart}
        pricingBreakup={myCartData?.pricing_breakup}
      />
 {customer && (
        <IonButton  
          onClick={handleSave}
          shape="round"
          expand="full"
          disabled={myCartData?.products?.length === 0 || myCartData?.length ===0}
          style={{margin:"10px"}}
        >
          Add Order
        </IonButton>
      )}   
      <IonLoading isOpen={loading}/>
    </>
  );
};
export default RightBar;
