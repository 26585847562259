import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonText, IonTitle, IonToggle, IonToolbar, useIonToast } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import {
    arrowBack,
    arrowBack as arrowBackIcon,
    informationCircleOutline
} from 'ionicons/icons';
import { useAddon } from '../../../utils/contexts';
import useAPI from '../../../utils/api';
import { indexedValue } from '../AddonsRoutes';
import FeatHeader from '../../../components/dashboard/FeatHeader';


const InventoryForm = () => {
    const addons:any = useAddon();
    const [present] = useIonToast();
    const API = useAPI();
    const [showStockOutProduct, setShowStockOutProduct] = useState(Array.isArray(addons[indexedValue.inventory].data) ? false : addons[indexedValue.inventory].data?.show_stock_out_products === "yes" ? true : false);
    const [inventoryCheck, setInventoryCheck] = useState(Array.isArray(addons[indexedValue.inventory].data) ? false : addons[indexedValue.inventory].data?.status === 'active');

    const onSubmitHandler = async(e)=>{
        e.preventDefault();
            const [ok, response] = Array.isArray(addons[indexedValue.inventory].data) ? 
                await API.createInventoryAddon({
                    "show_stock_out_products": showStockOutProduct ? "yes" : "no",
                    "status": inventoryCheck ? "active" : "inactive"
                })
                :
                await API.updateInventoryAddon({
                    "show_stock_out_products": showStockOutProduct ? "yes" : "no",
                    "status": inventoryCheck ? "active" : "inactive",
                    id: addons[indexedValue.inventory].data.id
                })

                if(ok) {
                    present("Data saved successfully!", 1000);
                    addons[indexedValue.inventory].updateMethod(!addons[indexedValue.inventory].update);
                }
    }

    const openLinkInNewTab = () => {
        window.open(`https://intelikart.tawk.help/article/inventory-management`, '_blank');
    };

    return ( <IonPage>
       
        <FeatHeader
            title='Configure Product Inventory'
            showInfoButton={true}
            onInfoClick={openLinkInNewTab}
            defaultHref='/add-ons'
        />
            <IonContent className="ion-padding">
                <form onSubmit={onSubmitHandler}>
                    <IonItem lines="none">
                        <IonText><h5>Show out of stock products in listing</h5></IonText>
                        <IonToggle slot="end" checked={showStockOutProduct} onIonChange={e => setShowStockOutProduct(e.detail.checked)} />
                    </IonItem>
                    <br/>
                    <IonItem lines="none">
                        <IonText><h5>Enable product based inventory management</h5></IonText>
                        <IonToggle slot="end" checked={inventoryCheck} onIonChange={e => setInventoryCheck(e.detail.checked)} />
                    </IonItem>
                    <br/>
                    <div className="ion-text-center">
                        <IonButton type="submit">Save</IonButton>
                    </div>
                </form>
                {/* <IonLoading isOpen={loadingGARecords} message="Please Wait"></IonLoading> */}
            </IonContent>
        </IonPage>
    )
}

export default InventoryForm