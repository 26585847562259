import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonText, IonTitle, IonToggle, IonToolbar, useIonToast } from '@ionic/react';
import {
    arrowBack as arrowBackIcon
} from 'ionicons/icons';
import { useState } from 'react';
import useAPI from '../../../utils/api';
import { useAddon } from '../../../utils/contexts';
import { indexedValue } from '../AddonsRoutes';
import FeatHeader from '../../../components/dashboard/FeatHeader';


const DigitalProductForm = () => {
    const addons:any = useAddon();
    const [present] = useIonToast();
    const API = useAPI();
    const [statusCheck, setStatusCheck] = useState(Array.isArray(addons[indexedValue.digitalProduct].data) ? false : addons[indexedValue.digitalProduct].data?.status === 'active');

    const onSubmitHandler = async(e)=>{
        e.preventDefault();
            const [ok, response] = Array.isArray(addons[indexedValue.digitalProduct].data) ? 
                await API.PostDigitalProductAddon({
                    "status": statusCheck ? "active" : "inactive"
                })
                :
                await API.PostDigitalProductAddon({
                    "status": statusCheck ? "active" : "inactive",
                    id: addons[indexedValue.digitalProduct].data.id
                })
                if(response.statusCode === 400){
                    setStatusCheck(!statusCheck);
                    present(response.message, 1500);
                }
                if(ok) {
                    present("Data saved successfully!", 1500);
                    addons[indexedValue.digitalProduct].updateMethod(!addons[indexedValue.digitalProduct].update);

                }
    }

    return ( <IonPage>
      
        <FeatHeader
            title='Configure Digital Product'
            defaultHref='/add-ons'
            showInfoButton={false}
        />
            <IonContent className="ion-padding">
                <form onSubmit={onSubmitHandler}>
                    <IonItem lines="none">
                        <IonText><h5>Enable Digital Product</h5></IonText>
                        <IonToggle slot="end" checked={statusCheck} onIonChange={e => setStatusCheck(e.detail.checked)} />
                    </IonItem>
                    <br/>
                    <div className="ion-text-center">
                        <IonButton type="submit">Save</IonButton>
                    </div>
                </form>
            </IonContent>
        </IonPage>
    )
}

export default DigitalProductForm