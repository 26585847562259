import { useContext } from 'react';
import { UserContext } from "./contexts";


function Currency() {
    const user: any = useContext(UserContext);
    const currency = user?.store.currency;
    
        if (currency === null || currency === undefined ) {
          return "INR";
        } 
        else {
          return currency;   
    };
  }
  
  export const StoreCurrency = Currency;
  