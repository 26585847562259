import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonLabel, IonText, IonLoading, IonBackButton, IonHeader, IonToolbar, IonButtons, IonTitle, IonItem, IonInput, IonButton, IonPage, IonItemDivider, IonRange, IonList, IonCard, IonIcon, IonToggle } from '@ionic/react';
import { getServiceDetails, setServiceDetails, clearErrors, getQuickOrder, setQuickOrder } from '../../actions/dashboardActions';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import FeatHeader from '../../components/dashboard/FeatHeader';

import {
    arrowBack as arrowBackIcon,
    newspaperOutline as newspaperOutlineIcon
} from 'ionicons/icons';

const QuickOrders = () => {
    const [quickOrderText, setQuickOrderText] = useState<any>();
    const [quickOrderChecked, setQuickOrderChecked] = useState<any>();

    const dispatch = useDispatch();
    const { quickOrderLoading, quickOrder, quickOrderError } = useSelector((state : any)=> state.quickOrder);
    const { setQuickOrderResponseLoading, setQuickOrderResponse, setQuickOrderResponseError } = useSelector((state : any)=> state.setQuickOrder);
    const { currentUser } = useSelector((state : any)=> state.currentUser);

    useEffect(() => {
        if(currentUser && currentUser.data){
            dispatch(getQuickOrder(getAccessToken(), currentUser.data.store.subdomain));
        }
    }, [currentUser, setQuickOrderResponseLoading]);

    useEffect(() => {
        if(quickOrderLoading === false && quickOrder && quickOrder.data){
            setQuickOrderText(quickOrder.data.quick_order_text);
            setQuickOrderChecked(quickOrder.data.enable_quick_order === 0 ? false : true);
        }
    }, [quickOrder]);

    const submitHandler = async () => {
        if(currentUser && currentUser.data){
            await dispatch(setQuickOrder(getAccessToken(), currentUser.data.store.subdomain, {
                enable_quick_order: quickOrderChecked ? 1 : 0 ,
                quick_order_text: quickOrderText ? `${quickOrderText}` : null
            }));
        }
    }
    return (
        <IonPage>
            <FeatHeader
            
                title='Quick Orders'
                defaultHref='/settings'
                showInfoButton={false}
            />
            <IonContent className="ion-padding">
                <IonText className="ion-text-center">
                    <h5>Receive orders as images</h5>
                    <p>can be prescription, grocery list</p>
                </IonText>
                <IonCard>
                    <IonItem lines="none">
                        <IonIcon size="large" icon={newspaperOutlineIcon}></IonIcon>
                        <IonText><h5>Quick Orders</h5></IonText>
                        <IonToggle slot="end" checked={quickOrderChecked} onIonChange={e => setQuickOrderChecked(e.detail.checked)} />
                    </IonItem>
                </IonCard>
                <IonCard>
                    <IonText>
                        <p className="ion-no-margin ion-margin-top ion-margin-start">Add text for quick orders</p>
                    </IonText>
                    <IonItem>
                        <IonInput type="text" placeholder="Eg: Upload Perscription or Grocery list" value={quickOrderText} onIonChange={(e)=> setQuickOrderText(e.detail.value)}></IonInput>
                    </IonItem>
                    <br/>
                    <div className="ion-text-center">
                        <IonButton shape="round" type="button" onClick={e => submitHandler()}>Save</IonButton>
                    </div>
                </IonCard>
                <IonLoading isOpen={quickOrderLoading === true || setQuickOrderResponseLoading === true} message="Please Wait"></IonLoading>
            </IonContent>
        </IonPage>
    )
}

export default QuickOrders
