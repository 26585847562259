import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonApp, IonText, IonItem, IonIcon, IonLoading, IonCard, IonButton, IonAlert, IonPage, IonCol, IonGrid, IonRow, IonRadioGroup, IonImg, IonRadio, IonLabel } from '@ionic/react';
import { getThemes, setTheme } from '../../actions/dashboardActions';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import Header from '../../components/dashboard/Header'

const Themes = () => {
    const [selectedTheme, setSelectedTheme] = useState();
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state : any)=> state.currentUser);
    const { setThemeLoading, setThemeResponse, setThemeError } = useSelector((state : any)=> state.setThemeData);
    const {  themesLoading, themes, themesError } = useSelector((state: any) => state.themes);

    useEffect(() => {
        if(currentUser && currentUser.data){
            dispatch(getThemes(currentUser.data.store.subdomain, getAccessToken()));
        }
    }, [currentUser]);

    useEffect(() => {
        if(themesLoading === false){
            if(themes && themes.currentTheme && themes.currentTheme.name ){
                setSelectedTheme(themes.currentTheme.name);
            }
        }
    }, [themesLoading])

    useEffect(() => {
        if(currentUser && currentUser.data && setThemeLoading === false){
            dispatch(getThemes(currentUser.data.store.subdomain, getAccessToken()));
        }
    }, [setThemeLoading]);

    const saveTheme = ()=>{
        dispatch(setTheme(getAccessToken(), currentUser.data.store.subdomain, {name: selectedTheme} ));
    }

    return (
        <IonPage>
            <div className="ion-page" id="main-content">
                <Header name="Themes"/>
                <IonContent className="ion-padding">
                <IonRadioGroup value={selectedTheme}>
                    <IonGrid>
                        <IonRow>
                                {themes && themes.themes ? themes.themes.map((elem)=>{
                                    return <IonCol>
                                        <IonCard key={elem.name} style={{ cursor:'pointer', height: 350+'px'}} onClick={e=> setSelectedTheme(elem.name)}>
                                            <IonImg src={elem.image} style={{ height: 87+'%'}}/>
                                            <IonItem lines='none' className='ion-text-center'>
                                                <IonRadio value={elem.name} />
                                                <IonLabel>{elem.name}</IonLabel>
                                            </IonItem>
                                        </IonCard>
                                    </IonCol>
                                }) : ''}
                        </IonRow>
                    </IonGrid>
                    </IonRadioGroup>
                    {themes && themes.themes && themes.currentTheme && <div className={`ion-text-center ${selectedTheme === themes.currentTheme.name ? 'ion-hide' : ''}`}>
                        <IonButton shape='round' onClick={e=> saveTheme()}>Save</IonButton>
                    </div>}
                    <IonLoading isOpen={setThemeLoading === true || themesLoading === true} message="Please Wait"></IonLoading>
                </IonContent>
            </div>
        </IonPage>
    )
}

export default Themes
