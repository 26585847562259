import { IonLoading, IonRouterOutlet } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { AddonContext, useSharedData } from '../../utils/contexts';
import useFetch from '../../utils/useFetch';
import Addons from './Addons';
import DigitalProductForm from './configuers/DigitalProductForm';
import DynamicFieldsForm from './configuers/DynamicFieldsForm';
import FestivalForm from './configuers/FestivalForm';
import GAnalyticalForm from './configuers/GAnalyticalForm';
import GooglePlayStore from './configuers/GooglePlayStore';
import InventoryForm from './configuers/InventoryForm';
import MailChimpForm from './configuers/MailchimpForm';
import OptimonkForm from './configuers/OptimonkForm';
import OrderForm from './configuers/OrderForm';
import ProductLevelTaxForm from './configuers/ProductLevelTaxForm';
import SMTPForm from './configuers/SMTPForm';
import ScheduledOrder from './configuers/ScheduledOrder';
import SelfDeliveryForm from './configuers/SelfDeliveryForm';
import SeoForm from './configuers/SeoForm';
import ShiprocketForm from './configuers/ShiprocketForm';
import TawkForm from './configuers/TawkForm';
import WhatsappForm from './configuers/WhatsappForm';
import ComplementaryProductForm from './configuers/ComplementaryProductForm';
import OndcForm from './configuers/OndcForm';

export const indexedValue = {
    ga: 0,
    inventory: 5,
    shiprocket: 2,
    whatsapp: 1,
    order: 3,
    mailchimp: 4,
    productLevelTax: 6,
    seo: 7,
    optimonk: 8,
    dynamicFields: 9,
    smtp: 10,
    tawk: 11,
    scheduledOrder:12,
    selfDelivery: 13,
    digitalProduct:14,
    Festival:15,  
    GooglePlay:16,
    complementaryProduct:17,
    ondc:18,
}

const AddonsRoutes = () => {
    const sharedData: any = useSharedData();
    const [gaUpdate, setGAUpdate] = useState(false);
    const [whatsappUpdate, setWhatsappUpdate] = useState(false);
    const [mailchimpUpdate, setMailchimpUpdate] = useState(false);
    const [seoUpdate, setSeoUpdate] = useState(false);
    const [optimonkUpdate, setOptimonkUpdate] = useState(false);
    const [smtpUpdate, setSmtpUpdate] = useState(false);
    const [tawkUpdate, setTawkUpdate] = useState(false);

    const [addons, setAddons] = useState([
        {
            image: "/assets/google_analytics.svg",
            name: "Google Analytics",
            description: "Help in growing and manage your customers",
            url: "/add-ons/ga-configure",
            data: [],
            updateMethod: setGAUpdate,
            update: gaUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/whatsapp-logo.png",
            name: "Whatsapp",
            description: "Chat with your customers on Whatsapp to grow your business",
            url: "/add-ons/whatsapp-configure",
            data: [],
            updateMethod: setWhatsappUpdate,
            update: whatsappUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/shiprocket-logo.avif",
            name: "Shiprocket",
            description: "Reduce shipping cost & increase customer reach using Ship Rocket",
            url: "/add-ons/shiprocket-configure",
            data: [],
            updateMethod: sharedData.setShiprocketUpdate,
            update: sharedData.shiprocketUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/order.png",
            name: "Manual Order Management",
            description: "Accept or reject orders manually.",
            url: "/add-ons/order-configure",
            data: [],
            updateMethod: sharedData.setOrderManagementUpdate,
            update: sharedData.orderManagementUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/mailchimp.png",
            name: "Mailchimp",
            description: "Grow your business on your terms with Mailchimp's All-In-One marketing, automation & email marketing platform.",
            url: "/add-ons/mailchimp-configure",
            data: [],
            updateMethod: setMailchimpUpdate,
            update: mailchimpUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/inventory.jpg",
            name: "Inventory Management(Product Level)",
            description: "List your product according to your inventory.",
            url: "/add-ons/inventory-configure",
            data: [],
            updateMethod: sharedData.setInventoryUpdate,
            update: sharedData.inventoryUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/productleveltax.png",
            name: "Product Level Taxes",
            description: "Apply taxes on the product level.",
            url: "/add-ons/producttax-configure",
            data: [],
            updateMethod: sharedData.setProductLevelTaxUpdate,
            update: sharedData.productLevelTaxUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/seoLogo.jpg",
            name: "SEO",
            description: "To Enable Meta tags functionality to your website.",
            url: "/add-ons/seo-configure",
            data: [],
            updateMethod: setSeoUpdate,
            update: seoUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/optimonk.png",
            name: "OptiMonk",
            description: "Create high-converting popups that don’t annoy your visitors.",
            url: "/add-ons/optimonk-configure",
            data: [],
            updateMethod: setOptimonkUpdate,
            update: optimonkUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/dynamicFields.jpg",
            name: "Product Fields",
            description: "Add product fields.",
            url: "/add-ons/dynamicFields-configure",
            data: [],
            updateMethod: sharedData.setDynamicFieldsUpdate,
            update: sharedData.dynamicFieldsUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/smtp.png",
            name: "Custom SMTP",
            description: "Send personalized emails to your customers.",
            url: "/add-ons/smtp-configure",
            data: [],
            updateMethod: setSmtpUpdate,
            update: smtpUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/tawk.png",
            name: "Tawk.To",
            description: "Monitor and chat with the visitors on your website.",
            url: "/add-ons/tawk-configure",
            data: [],
            updateMethod: setTawkUpdate,
            update: tawkUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/purchaseOrder.png",
            name: "Scheduled Order",
            description: "Apply Scheduled Order on your Orders",
            url: "/add-ons/schedule-order",
            data: [],
            updateMethod: sharedData.setScheduledOrderUpdate,
            update: sharedData.scheduledOrderUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/selfdelivery.png",
            name: "Self Delivery",
            description: "Apply Self Delivery mode",
            url: "/add-ons/selfDelivery-configure",
            data: [],
            updateMethod: sharedData.setSelfDeliveryUpdate,
            update: sharedData.selfDeliveryUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/digital.webp",
            name: "Digital Product",
            description: "You can also add your Digital Products",
            url: "/add-ons/digitalProduct-configure",
            data: [],
            updateMethod: sharedData.setdigitalProductUpdate,
            update: sharedData.digitalProductUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/festival.png",
            name: "Festival",
            description: " Apply festival theme",
            url: "/add-ons/festival-configure",
            data: [],
            updateMethod: sharedData.setFestivalUpdate,
            update: sharedData.festivalUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/google-play.png",
            name: "Google Play Store",
            description: "Get your app at playstore",
            url: "/add-ons/google-play",
            data: [],
            updateMethod: "",
            update: "",
            loading: false,
            status: "fetched"
        },
        {
            image: "/assets/complementary.jpg",
            name: "Complementary Products",
            description: "Boost sales with complementary products.",
            url: "/add-ons/complementary-configure",
            data: [],
            updateMethod: sharedData.setComplementaryProductUpdate,
            update: sharedData.complementaryProductUpdate,
            loading: true,
            status: "fetching"
        },
        {
            image: "/assets/ondc.png",
            name: "ONDC",
            description: "Sell on ONDC.",
            url: "/add-ons/ondc-configure",
            data: [],
            updateMethod: sharedData.setOndcUpdate,
            update: sharedData.ondcUpdate,
            loading: true,
            status: "fetching"
        },
    ]);
    const gaData = useFetch("gaAddon", gaUpdate);
    const inventoryData = useFetch("inventoryAddon", sharedData.inventoryUpdate);
    const orderData = useFetch("orderAddon", sharedData.orderManagementUpdate);
    const whatsappData = useFetch("whatsappAddon", whatsappUpdate);
    const mailchimpData = useFetch("mailchimpAddon", mailchimpUpdate);
    const shiprocketData = useFetch("shiprocketAddon", sharedData.shiprocketUpdate);
    const productLevelTaxData = useFetch("productTaxAddon", sharedData.productLevelTaxUpdate);
    const scheduleOrderData = useFetch("getScheduledOrderAddon",sharedData.scheduledOrderUpdate)
    const seoData = useFetch("seoAddon", seoUpdate);
    const dynamicFieldsData = useFetch("dynamicFieldsAddon", sharedData.dynamicFieldsUpdate);
    const optimonkData = useFetch("optimonkAddon", optimonkUpdate);
    const smtpData = useFetch("smtpAddon", smtpUpdate);
    const tawkData = useFetch("tawkAddon", tawkUpdate);
    const selfDeliveryData = useFetch("selfDeliveryAddon", sharedData.selfDeliveryUpdate);
    const digitalProductData = useFetch("getDigitalProductAddon", sharedData.digitalProductUpdate);
    const festivalData = useFetch("getFestival",sharedData.festivalUpdate);
    const complementaryProductData = useFetch("complementaryProductAddon", sharedData.complementaryProductUpdate);
    const ondcData = useFetch("ondcAddon", sharedData.ondcUpdate);

    useEffect(() => {
        if (gaData && !gaData.loading && gaData.status === "fetched" && gaData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.ga].data = gaData.data?.data;
                temp[indexedValue.ga].loading = false;
                temp[indexedValue.ga].status = "fetched";
                return temp;
            })
        }
    }, [gaData])

    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.ga].update = gaUpdate
            return temp;
        })
    }, [gaUpdate])

    useEffect(() => {
        if (whatsappData && !whatsappData.loading && whatsappData.status === "fetched" && whatsappData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.whatsapp].data = whatsappData.data?.data;
                temp[indexedValue.whatsapp].loading = false;
                temp[indexedValue.whatsapp].status = "fetched";
                return temp;
            })
        }
    }, [whatsappData])

    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.whatsapp].update = whatsappUpdate
            return temp;
        })
    }, [whatsappUpdate])

    useEffect(() => {
        if (mailchimpData && !mailchimpData.loading && mailchimpData.status === "fetched" && mailchimpData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.mailchimp].data = mailchimpData.data?.data;
                temp[indexedValue.mailchimp].loading = false;
                temp[indexedValue.mailchimp].status = "fetched";
                return temp;
            })
        }
    }, [mailchimpData])

    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.mailchimp].update = mailchimpUpdate
            return temp;
        })
    }, [mailchimpUpdate])

    useEffect(() => {
        if (shiprocketData && !shiprocketData.loading && shiprocketData.status === "fetched" && shiprocketData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.shiprocket].data = shiprocketData.data?.data;
                temp[indexedValue.shiprocket].loading = false;
                temp[indexedValue.shiprocket].status = "fetched";
                return temp;
            })
        }
    }, [shiprocketData])

    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.shiprocket].update = sharedData.shiprocketUpdate
            return temp;
        })
    }, [sharedData.shiprocketUpdate])

    //Changes in values in inventory.
    useEffect(() => {
        if (inventoryData && !inventoryData.loading && inventoryData.status === "fetched" && inventoryData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.inventory].data = inventoryData.data?.data;
                temp[indexedValue.inventory].loading = false;
                temp[indexedValue.inventory].status = "fetched";
                return temp;
            })
        }
    }, [inventoryData])

    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.inventory].update = sharedData.inventoryUpdate
            return temp;
        })
    }, [sharedData.inventoryUpdate])

    //Changes in values in order.
    useEffect(() => {
        if (orderData && !orderData.loading && orderData.status === "fetched" && orderData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.order].data = orderData.data?.data;
                temp[indexedValue.order].loading = false;
                temp[indexedValue.order].status = "fetched";
                return temp;
            })
        }
    }, [orderData])

    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.order].update = sharedData.orderManagementUpdate
            return temp;
        })
    }, [sharedData.orderManagementUpdate])

    useEffect(() => {
        if (productLevelTaxData && !productLevelTaxData.loading && productLevelTaxData.status === "fetched" && productLevelTaxData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.productLevelTax].data = productLevelTaxData.data?.data;
                temp[indexedValue.productLevelTax].loading = false;
                temp[indexedValue.productLevelTax].status = "fetched";
                return temp;
            })
        }
    }, [productLevelTaxData])

    useEffect(() => {
        if (scheduleOrderData && !scheduleOrderData.loading && scheduleOrderData.status === "fetched" && scheduleOrderData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.scheduledOrder].data = scheduleOrderData.data?.data;
                temp[indexedValue.scheduledOrder].loading = false;
                temp[indexedValue.scheduledOrder].status = "fetched";
                return temp;
            })
        }
    }, [scheduleOrderData])

    
useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.scheduledOrder].update = sharedData.scheduledOrderUpdate
            return temp;
        })
    }, [sharedData.scheduledOrderUpdate])
    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.productLevelTax].update = sharedData.productLevelTaxUpdate
            return temp;
        })
    }, [sharedData.productLevelTaxUpdate])

    useEffect(() => {
        if (seoData && !seoData.loading && seoData.status === "fetched" && seoData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.seo].data = seoData.data?.data;
                temp[indexedValue.seo].loading = false;
                temp[indexedValue.seo].status = "fetched";
                return temp;
            })
        }
    }, [seoData])

    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.seo].update = seoUpdate
            return temp;
        })
    }, [seoUpdate])

    useEffect(() => {
        if (optimonkData && !optimonkData.loading && optimonkData.status === "fetched" && optimonkData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.optimonk].data = optimonkData.data?.data;
                temp[indexedValue.optimonk].loading = false;
                temp[indexedValue.optimonk].status = "fetched";
                return temp;
            })
        }
    }, [optimonkData])

    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.optimonk].update = optimonkUpdate
            return temp;
        })
    }, [optimonkUpdate])

    useEffect(() => {
        if (dynamicFieldsData && !dynamicFieldsData.loading && dynamicFieldsData.status === "fetched" && dynamicFieldsData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.dynamicFields].data = dynamicFieldsData.data?.data;
                temp[indexedValue.dynamicFields].loading = false;
                temp[indexedValue.dynamicFields].status = "fetched";
                return temp;
            })
        }
    }, [dynamicFieldsData])

    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.dynamicFields].update = sharedData.dynamicFieldsUpdate
            return temp;
        })
    }, [sharedData.dynamicFieldsUpdate])

    useEffect(() => {
        if (smtpData && !smtpData.loading && smtpData.status === "fetched" && smtpData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.smtp].data = smtpData.data?.data;
                temp[indexedValue.smtp].loading = false;
                temp[indexedValue.smtp].status = "fetched";
                return temp;
            })
        }
    }, [smtpData])

    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.smtp].update = smtpUpdate
            return temp;
        })
    }, [smtpUpdate])

    useEffect(() => {
        if (tawkData && !tawkData.loading && tawkData.status === "fetched" && tawkData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.tawk].data = tawkData.data?.data;
                temp[indexedValue.tawk].loading = false;
                temp[indexedValue.tawk].status = "fetched";
                return temp;
            })
        }
    }, [tawkData])

    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.tawk].update = tawkUpdate
            return temp;
        })
    }, [tawkUpdate])

    useEffect(() => {
        if (selfDeliveryData && !selfDeliveryData.loading && selfDeliveryData.status === "fetched" && selfDeliveryData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.selfDelivery].data = selfDeliveryData.data?.data;
                temp[indexedValue.selfDelivery].loading = false;
                temp[indexedValue.selfDelivery].status = "fetched";
                return temp;
            })
        }
    }, [selfDeliveryData])

    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.selfDelivery].update = sharedData.selfDeliveryUpdate
            return temp;
        })
    }, [sharedData.selfDeliveryUpdate])

    useEffect(() => {
        if (digitalProductData && !digitalProductData.loading && digitalProductData.status === "fetched" && digitalProductData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.digitalProduct].data = digitalProductData.data?.data;
                temp[indexedValue.digitalProduct].loading = false;
                temp[indexedValue.digitalProduct].status = "fetched";
                return temp;
            })
        }
    }, [digitalProductData])

    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.digitalProduct].update = sharedData.digitalProductUpdate
            return temp;
        })
    }, [sharedData.digitalProductUpdate])
     
    useEffect(() => {
      
        if (festivalData && !festivalData.loading && festivalData.status === "fetched" && festivalData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.Festival].data = festivalData.data?.data;
                temp[indexedValue.Festival].loading = false;
                temp[indexedValue.Festival].status = "fetched";
                return temp;
            })
        }
    }, [festivalData])

    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.Festival].update = sharedData.festivalUpdate
            return temp;
        })
    }, [sharedData.festivalUpdate])
    
    useEffect(() => {
        if (indexedValue.GooglePlay) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.GooglePlay].data = ["google-Play"];
                temp[indexedValue.GooglePlay].loading = false;
                temp[indexedValue.GooglePlay].status = "fetched";
                return temp;
            })
        }
    }, [])

    useEffect(() => {
        if (complementaryProductData && !complementaryProductData.loading && complementaryProductData.status === "fetched" && complementaryProductData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.complementaryProduct].data = complementaryProductData.data?.data;
                temp[indexedValue.complementaryProduct].loading = false;
                temp[indexedValue.complementaryProduct].status = "fetched";
                return temp;
            })
        }
    }, [complementaryProductData])
    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.complementaryProduct].update = sharedData.complementaryProductUpdate
            return temp;
        })
    }, [sharedData.complementaryProductUpdate])
     
    useEffect(() => {
        if (ondcData && !ondcData.loading && ondcData.status === "fetched" && ondcData.data) {
            setAddons((prev) => {
                let temp = [...prev];
                temp[indexedValue.ondc].data = ondcData.data?.data;
                temp[indexedValue.ondc].loading = false;
                temp[indexedValue.ondc].status = "fetched";
                return temp;
            })
        }
    }, [ondcData])
    useEffect(() => {
        setAddons((prev) => {
            let temp = [...prev];
            temp[indexedValue.ondc].update = sharedData.ondcUpdate
            return temp;
        })
    }, [sharedData.ondcUpdate])
    return (
        <AddonContext.Provider value={addons}>
                <IonRouterOutlet>

                    <Route path="/add-ons/ga-configure" exact={true}>
                        <GAnalyticalForm />
                    </Route>

                    <Route path="/add-ons/whatsapp-configure" exact={true}>
                        <WhatsappForm />
                    </Route>

                    <Route path="/add-ons/shiprocket-configure" exact={true}>
                        <ShiprocketForm />
                    </Route>

                    <Route path="/add-ons/inventory-configure" exact={true}>
                        <InventoryForm />
                    </Route>

                    <Route path="/add-ons/order-configure" exact={true}>
                        <OrderForm />
                    </Route>

                    <Route path="/add-ons/mailchimp-configure" exact={true}>
                        <MailChimpForm />
                    </Route>

                    <Route path="/add-ons/producttax-configure" exact={true}>
                        <ProductLevelTaxForm />
                    </Route>

                    <Route path="/add-ons/schedule-order" exact={true}>
                        <ScheduledOrder />
                    </Route>

                    <Route path="/add-ons/seo-configure" exact={true}>
                        <SeoForm />
                    </Route >

                    <Route path="/add-ons/optimonk-configure" exact={true}>
                        <OptimonkForm />
                    </Route>

                    <Route path="/add-ons/dynamicFields-configure" exact={true}>
                        <DynamicFieldsForm />
                    </Route>

                    <Route path="/add-ons/smtp-configure" exact={true}>
                        <SMTPForm />
                    </Route>

                    <Route path="/add-ons/tawk-configure" exact={true}>
                        <TawkForm />
                    </Route>

                    <Route path="/add-ons/selfDelivery-configure" exact={true}>
                        <SelfDeliveryForm />
                    </Route>
                    <Route path="/add-ons/digitalProduct-configure" exact={true}>
                        <DigitalProductForm />
                    </Route>
                    <Route path="/add-ons/festival-configure" exact={true}>
                        <FestivalForm />
                    </Route>
                    <Route path="/add-ons/google-play" exact={true}>
                        <GooglePlayStore />
                    </Route>

                    <Route path="/add-ons/complementary-configure" exact={true}>
                        <ComplementaryProductForm />
                    </Route>

                    <Route path="/add-ons/ondc-configure" exact={true}>
                        <OndcForm />
                    </Route>

                    <Route path="/add-ons" exact={true}><Addons /></Route>
                </IonRouterOutlet >

            <IonLoading isOpen={gaData.loading || whatsappData.loading || inventoryData.loading || orderData.loading || mailchimpData.loading || productLevelTaxData.loading || seoData.loading || shiprocketData.loading || optimonkData.loading || dynamicFieldsData.loading || smtpData.loading || tawkData.loading || selfDeliveryData.loading || digitalProductData.loading || festivalData.loading || complementaryProductData.loading || ondcData.loading} />

            {/* <IonLoading isOpen={gaData.loading || whatsappData.loading || shiprocketData.loading || inventoryData.loading} /> */}
        </AddonContext.Provider >
    )
}

export default AddonsRoutes
