import { Share } from "@capacitor/share";
import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSlide,
  IonSlides,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
  isPlatform
} from "@ionic/react";
import html2pdf from 'html2pdf.js/dist/html2pdf.min.js';
import {
  arrowBack as arrowBackIcon,
  caretDown as caretDownIcon,
  checkmarkOutline as checkmarkOutlineIcon,
  closeOutline as closeOutlineIcon,
  documentText as documentTextIcon,
  downloadOutline as downloadIcon,
  informationCircleOutline,
  receiptOutline as receiptIcon,
  shareSocial as shareSocialIcon,
  time
} from "ionicons/icons";
import qz from 'qz-tray';
import { useContext, useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import { formatDate } from "../../services/formatters";
import useAPI from "../../utils/api";
import { UserContext, useOrderStatusChangeData } from "../../utils/contexts";
import { StoreCurrency } from '../../utils/currency';
import useFetch from "../../utils/useFetch";
import Adjustments from "./components/Adjustments";
import DeliveryMessage from "./components/DeliveryMessage";
import OrderUserDetails from "./components/OrderUserDetails";
import write_blob from "capacitor-blob-writer";
import { Directory } from "@capacitor/filesystem";
interface RouteParams {
  id: string;
}
const OrderDetails = (props) => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [updateOrder, setUpdateorder] = useState<any>("");
  const [statusId, setStatusId] = useState("");
  const [customMessage, setCustomMessage] = useState("");
  const [driver, setDriver] = useState("");
  const [showModalForStatus, setShowModalForStatus] = useState(false);
  const [showDynamicOptions, setShowDynamicOptions] = useState(false);
  const [receiptLoading, setReceiptLoading] = useState(false)
  const [receiptDownloadLoading, setReceiptDownloadLoading] = useState(false)
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

	const {filterOrderData, setFilterOrderData, pageCountData, setPageCountData, infiniteScrollData, setInfiniteScrollData}: any = useOrderStatusChangeData();
  const API = useAPI();

  const history = useHistory();
  const currency = StoreCurrency();

  const order = useFetch("orderById", location.state, updateOrder);
  const suggestionData = useFetch("suggestions");

  useEffect(() => {
    changeDriverHelper();
  }, [driver]);

  const updateStatusData = (currentStatus, updatedStatus) => {
    let pageTemp = {...pageCountData};
    pageTemp["ALL"] = 1;
    pageTemp["PENDING"] = 1;
    pageTemp[`${currentStatus}`] = 1;
    if(updatedStatus in pageTemp){
      pageTemp[`${updatedStatus}`] = 1;
    }
    setPageCountData(pageTemp);

    let dataTemp = {...filterOrderData};
    dataTemp["ALL"] = [];
    dataTemp["PENDING"] = [];
    dataTemp[`${currentStatus}`] = [];
    if(updatedStatus in dataTemp){
      dataTemp[`${updatedStatus}`] = [];
    }
    setFilterOrderData(dataTemp);

    let infiniteScrollTemp = {...infiniteScrollData};
    infiniteScrollTemp["ALL"] = false;
    infiniteScrollTemp["PENDING"] = false;
    infiniteScrollTemp[`${currentStatus}`] = false;
    if(updatedStatus in infiniteScrollTemp){
      infiniteScrollTemp[`${updatedStatus}`] = false;
    }
    setInfiniteScrollData(infiniteScrollTemp);
  }

  const slideOpts = {
    initialSlide: 0,
    slidesPerView: 1,
    loop: true,
    speed: 20,
  };

  const mySlides = useRef<HTMLIonSlidesElement>(null);

  function slidesDidLoad() {
    if (mySlides.current) {
      mySlides.current.startAutoplay();
    }
  }

  const handleOptionClick = (option) => {
    setCustomMessage(option);
    setShowDynamicOptions(false);
  };
  const changeDriverHelper = async () => {
    if (
      order &&
      driver &&
      driver != order.data?.data.assigned_driver_id
    ) {
      const [ok, response] = await API.changeOrderStaff(order?.data?.data?.id, driver);

      if (ok) {
        updateStatusData(order?.data?.data?.status, statusId);
        setUpdateorder(!updateOrder);
      }
    }
  };
  const changeStatus = async (e: any) => {
    e.preventDefault();
    setShowModalForStatus(false);
    setStatusId("");
    if (
      order &&
      statusId &&
      statusId != order.data?.data.status
    ) {
      const [ok, response] = await API.changeOrderStatus(order?.data?.data?.id, statusId, customMessage);

      if (ok) {
        updateStatusData(order?.data?.data?.status, statusId);
        setUpdateorder(!updateOrder);
      }
    }
  };

  const changeStaff = async (e: any) => {
    e.preventDefault();
    setDriver(e.detail.value);
  };

  const shareOrder = async () => {
    await Share.share({
      title: "Order Details",
      text: `${order.data?.data.share_order_detail}`,
    });
  };

  const paymentStatusColor = {
    PAID: "warning",
    COD: "danger",
  };

  async function orderStatusChange(
    orderStatus: boolean,
    scheduleDate,
    scheduleTime
  ) {
    if (orderStatus) {
      if (scheduleDate || scheduleTime) {

        const [ok, response] = await API.changeOrderStatus(order?.data?.data?.id, "SCHEDULED", "");

        if (ok) {
          updateStatusData(order?.data?.data?.status, "SCHEDULED");
          setUpdateorder(!updateOrder);
        }
      } else {
        const [ok, response] = await API.changeOrderStatus(order?.data?.data?.id, "NEW", "");

        if (ok) {
          updateStatusData(order?.data?.data?.status, "NEW");
          setUpdateorder(!updateOrder);
        }
      }
    } else {
      const [ok, response] = await API.changeOrderStatus(order?.data?.data?.id, "DENY", "");

      if (ok) {
        updateStatusData(order?.data?.data?.status, "DENY");
        setUpdateorder(!updateOrder);
      }
    }
  }

  const user: any = useContext(UserContext);
  const userDomain = user?.store?.subdomain;

  const handlePrintReceipt = async (orderId) => {
    const accessToken = getAccessToken();

    try {
      setReceiptLoading(true);
      const response = await fetch(`https://api2.intelikart.in/orders/receipt/${orderId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "x-ik-domain": userDomain
        },
      });

      const clonedResponse = response.clone();

      if (clonedResponse.headers.get('Content-Type')?.includes('text/html')) {
        const htmlContent = await clonedResponse.text();

        await qzTray(htmlContent, "html")
        setReceiptLoading(false);

      } else {
        setReceiptLoading(false);
      }
    } catch (error) {
      console.error('Error fetching or printing receipt:', error);
      setReceiptLoading(false);
    }
  };

    const handleDownload = async (orderId) => {
        const accessToken = getAccessToken();
        
        try {
            setReceiptDownloadLoading(true);
            const response = await fetch(`https://api2.intelikart.in/orders/receipt/${orderId}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "x-ik-domain": userDomain
                },
            });
            const clonedResponse = response.clone();
    
            if (isPlatform("capacitor")) {
                const htmlContent = await clonedResponse.text();
                const pdfOptions = {
                    margin: 5,
                    filename: `invoice_${orderId}.pdf`,
                    image: { type: 'jpeg', quality: 1.0 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: 'mm', format: [80, 297], orientation: 'portrait' },
                };
                const pdfBlob = await html2pdf().set(pdfOptions).from(htmlContent).outputPdf('blob');
                try {
                    write_blob({
                        path: `invoice${orderId}.pdf`,
                        directory: Directory.Documents,
                        blob: pdfBlob,
                    });
                    setReceiptDownloadLoading(false);
                } catch (error) {
                    console.error("Error saving PDF:", error);
                    setReceiptDownloadLoading(false);
                }
            } else {
                try {
                    if (
                        clonedResponse.headers
                            .get("Content-Type")
                            ?.includes("text/html")
                    ) {
                        const htmlContent = await clonedResponse.text();

                        setTimeout(() => {
                            // Convert HTML to PDF
                            const pdfOptions = {
                                margin: 5,
                                filename: `receipt_${orderId}.pdf`,
                                image: { type: "jpeg", quality: 1.0 },
                                html2canvas: { scale: 2 },
                                jsPDF: {
                                    unit: "mm",
                                    format: [80, 297],
                                    orientation: "portrait",
                                },
                            };

                            html2pdf().from(htmlContent).set(pdfOptions).save();
                            setReceiptDownloadLoading(false);
                        }, 500);
                    } else {
                        console.error("Received content is not HTML.");
                        setReceiptDownloadLoading(false);
                    }
                } catch (error) {
                    console.error(
                        "Error fetching or converting HTML to PDF:",
                        error
                    );
                    setReceiptDownloadLoading(false);
                }
            }
        } catch (error) {
            console.error('Error fetching PDF or handling response:', error);
            setReceiptDownloadLoading(false);
        }
    }

    const qzTray = async (data, format) => {
        try {
            await qz.websocket.connect();
            const printers = await qz.printers.find();
            const config = qz.configs.create(printers[0], { size: { width: 4, height: 6 } });
            let printData = [
                {
                    type: 'raw', format: 'html', flavor: 'plain',
                    data: data,
                    options: { language: "ESCPOS" }
                 }
             ];

            await qz.print(config, printData);
            
            await qz.websocket.disconnect();
        } catch (err) {
            setToastMessage(err);
            setShowToast(true);
            setReceiptLoading(false);
        }
  };
  const openLinkInNewTab = () => {
    window.open(`https://intelikart.tawk.help/article/change-order-status`, '_blank');
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar style={{height: '68px'}}>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/orders" icon={arrowBackIcon} />
          </IonButtons>
          <IonTitle className="ion-no-padding ">
            <IonItem lines="none" className="ion-no-padding">
                <MobileView>
                    <IonText className='ion-no-margin'>Order #{order && order.data?.data ? order.data?.data.id : ""}</IonText>
                </MobileView>
                <BrowserView>
                    <IonText className='ion-no-margin'><h5>Order #{order && order.data?.data ? order.data?.data.id : ""}</h5></IonText>
                </BrowserView>
              {(isPlatform("android") && !isPlatform("mobileweb")) ? (
                <IonButton
                  fill="clear"
                  color="secondary"
                  routerLink={`/orders/orderdetails/invoice/${order.data?.data?.id}`}
                  slot="end"
                >
                  <IonIcon slot="start" size="large" icon={documentTextIcon} />
                  <h4>Invoice</h4>
                </IonButton>
              ) : null}
            </IonItem>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonLoading
          isOpen={order.loading ||
            receiptLoading ||
            receiptDownloadLoading 
          }
        />
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={5000}
        />
        {!order.loading && order.status === "fetched" && order.data?.data ? (
          <IonGrid>
            <IonRow>
              <IonCol sizeXl="8.5" sizeSm="12">
                <IonCard>
                  <IonItem>
                    <IonText slot="start">
                      <h5>#{order.data?.data.id}</h5>
                    </IonText>
                    <IonText slot="end">
                      <h5>
                        {formatDate(order.data?.data.created_at, "DD MMM YYYY, ddd")}{" "}
                        {formatDate(order.data?.data.created_at, "hh:mm A")}
                      </h5>
                    </IonText>
                  </IonItem>
                </IonCard>

                {order.data?.data.order_images[0] && (
                  <IonCard>
                    <IonCardContent>
                      <h2 style={{ marginBottom: "10px" }}>
                        <b>Order Image</b>
                      </h2>
                      <img
                        src={order.data?.data.order_images[0].image_path_public_url}
                        alt=""
                      ></img>
                    </IonCardContent>
                  </IonCard>
                )}
                <IonCard>
                  <IonItem lines="none">
                    <IonText slot="start">
                      <IonBadge
                        color={`${
                          paymentStatusColor[order.data?.data.payment_status]
                        }`}
                      >
                        {order.data?.data.payment_status}
                      </IonBadge>
                    </IonText>
                    <IonButton
                      slot="end"
                      fill="clear"
                      color="secondary"
                      onClick={(e) => shareOrder()}
                    >
                      <IonIcon
                        slot="icon-only"
                        icon={shareSocialIcon}
                      ></IonIcon>
                    </IonButton>
                  </IonItem>
                  {order.data?.data.products.map((val) => {
                    return (
                      <IonRow
                      style={{width:"100%",color:"black"}}
                        key={val.variant_name}
                        className="ion-padding-bottom"
                      >
                        <IonCol size="3">
                        <IonImg
                          src={val.variant_image_url_small}
                          style={{height: "15vh",width:"100%" }}
                        ></IonImg>
                        </IonCol>
                        <IonCol size="9">
                          <IonRow>
                            <h5>{val.variant_name}</h5>
                          </IonRow>
                          <IonRow>
                            <IonCol size="9">
                              <IonText>
                            {val.variant_ordered_units} &#9747; {currency} {val.variant_price}
                           
                            </IonText>
                            </IonCol>
                            <IonCol size="3" style={{textAlign:"right"}}>
                              <IonText >
                              {currency} {val.variant_ordered_units * val.variant_price}
                            </IonText>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    );
                  })}
                </IonCard>
                <IonCard>
                  <IonCardContent>
                    {order.data?.data.pricing_breakup.products[0] && (
                      <div className="table-holder">
                        <h2 className="ion-margin-bottom">
                          <b>Products</b>
                        </h2>
                        <table>
                          <tbody>
                            {order.data?.data.pricing_breakup.products.map(
                              (op, ind) => (
                                <>
                                  <tr
                                    key={op.product_name}
                                    className="ion-margin-bottom"
                                  >
                                    <td>
                                      {op.product_ordered_units} x{" "}
                                      {op.product_name}
                                    </td>
                                    <td valign="top">{`${currency} ${op.product_total_price.toFixed(
                                      2
                                    )}`}</td>
                                  </tr>

                                  {order.data?.data.products.find(
                                    (element) =>
                                      element.variant_name === op.product_name
                                  )?.product_level_taxes_and_charges.length >
                                    0 && (
                                    <>
                                      <h2 className="ion-margin-bottom">
                                        <b>
                                          {op.product_name} - Taxes and Charges
                                        </b>
                                      </h2>
                                      {order.data?.data.products
                                        .find(
                                          (element) =>
                                            element.variant_name ===
                                            op.product_name
                                        )
                                        .product_level_taxes_and_charges.map(
                                          (tax, ind, arr) => (
                                            <tr
                                              key={tax.name}
                                              className={`${
                                                ind === arr.length - 1
                                                  ? "ion-margin-bottom"
                                                  : ""
                                              }`}
                                            >
                                              <td>
                                                {tax.name}{" "}
                                                {`${
                                                  tax.method === "FLAT"
                                                    ? ""
                                                    : `(${tax.value}%)`
                                                }`}
                                              </td>
                                              <td valign="top">{`${currency} ${
                                                tax.method === "FLAT"
                                                  ? `${tax.value}`
                                                  : `${(
                                                      (op.product_total_price *
                                                        tax.value) /
                                                      100
                                                    ).toFixed(2)}`
                                              }`}</td>
                                            </tr>
                                          )
                                        )}
                                      <tr
                                        className="totals ion-padding-bottom ion-margin-bottom"
                                        style={{
                                          borderBottom: "1px solid grey",
                                        }}
                                      >
                                        <td>Total product charges</td>
                                        <td>{currency} {op.product_taxes}</td>
                                      </tr>
                                    </>
                                  )}
                                </>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {order.data?.data.pricing_breakup.adjustments[0] && (
                      <>
                        <br />
                        <div className="table-holder">
                          <h2 className="ion-margin-bottom">
                            <b>Adjustments</b>
                          </h2>
                          <table>
                            <tbody>
                              {order.data?.data.pricing_breakup.adjustments.map(
                                (op, ind) => (
                                  <tr key={op.adjustment_name}>
                                    <td>{op.adjustment_name}</td>
                                    <td valign="top">{`${op.adjustment_value_display.slice(
                                      0,
                                      1
                                    )} ${currency} ${op.adjustment_value_display.slice(
                                      1
                                    )}`}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                    {order.data?.data.pricing_breakup.discounts && (
                      <>
                        <br />
                        <div className="table-holder">
                          <h2 className="ion-margin-bottom">
                            <b>Discounts</b>
                          </h2>
                          <table>
                            <tbody>
                              <tr
                                key={
                                  order.data?.data.pricing_breakup.discounts
                                    .coupon_name
                                }
                              >
                                <td>
                                  {
                                    order.data?.data.pricing_breakup.discounts
                                      .coupon_name
                                  }
                                </td>
                                <td valign="top">{`${order.data?.data.pricing_breakup.discounts.coupon_value_display.slice(
                                  0,
                                  1
                                )} ${currency} ${order.data?.data.pricing_breakup.discounts.coupon_value_display.slice(
                                  1
                                )}`}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}

                    {order.data?.data.pricing_breakup.taxesCharges[0] && (
                      <>
                        {" "}
                        <br />
                        <div className="table-holder">
                          <table>
                            <tbody>
                              <tr className="totals">
                                <td>Sub Total</td>
                                <td>
                                {currency} {order.data?.data.pricing_breakup.subTotal.toFixed(
                                    2
                                  )}                               
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>{" "}
                      </>
                    )}

                    {order.data?.data.pricing_breakup.taxesCharges[0] && (
                      <>
                        {" "}
                        <br />{" "}
                        <div className="table-holder">
                          <h2 className="ion-margin-bottom">
                            <b>Taxes and Charges</b>
                          </h2>
                          <table>
                            <tbody>
                              {order.data?.data.pricing_breakup.taxesCharges.map(
                                (op, ind) => (
                                  <tr key={op.tax_name}>
                                    <td>{op.tax_name}</td>
                                    <td valign="top">{`${currency} ${op.tax_value_display}`}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>{" "}
                      </>
                    )}
                    <div className="table-holder">
                      <table>
                        <tbody>
                          <tr className="totals">
                            <td>Total</td>
                            <td>                          
                            {currency} {parseFloat(
                                order.data?.data.pricing_breakup.total
                              ).toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </IonCardContent>
                </IonCard>
                
                <Adjustments order={order} showModal={showModal} setShowModal={setShowModal} currency={currency} updateOrder={updateOrder} setUpdateorder={setUpdateorder}/>
                
                {order?.data?.data?.status !== "PENDING" && (
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonButton
                          expand="full"
                          onClick={(e) => {
                            setShowModal(true);
                          }}
                        >
                          Add Adjustment
                        </IonButton>
                      </IonCol>
                      {order?.data?.data?.available_carrier_service?.includes(
                        "shiprocket"
                      ) &&
                        order?.data?.data?.fulfillment == null &&
                        (order?.data?.data?.status == "NEW" ||
                          order.data?.data.status == "PROCESSING") && (
                          <IonCol>
                            <IonButton
                              expand="full"
                              onClick={() =>
                                history.push({ pathname: "/orders/createshiprocketorder", state: order?.data?.data?.id})
                              }
                            >
                              Create Shiprocket Order
                            </IonButton>
                          </IonCol>
                        )}

                      {order?.data?.data?.available_carrier_service?.includes(
                        "shiprocket"
                      ) &&
                        order?.data?.data?.fulfillment != null && (
                          <IonCol>
                            <IonButton
                              expand="full"
                              onClick={() =>
                                history.push({
                                  pathname: `/delivery/shiprocket`,
                                  state: order?.data?.data?.id,
                                })
                              }
                            >
                              View Shiprocket Order
                            </IonButton>
                          </IonCol>
                        )}

                      {order?.data?.data?.available_carrier_service?.includes(
                        "self"
                      ) && order.data?.data.available_driver &&
                        order.data?.data.available_driver.length > 0 && (
                          <IonCol>
                            <IonItem lines="none">
                              <IonLabel>Assign to driver</IonLabel>
                              <IonSelect
                                value={
                                  driver
                                    ? driver
                                    : order.data?.data.assigned_driver_id
                                }
                                onIonChange={(e) => changeStaff(e)}
                              >
                                {order.data?.data.available_driver.map((val) => {
                                  return (
                                    <IonSelectOption
                                      key={val.id}
                                      value={val.id}
                                    >
                                      {val.name}
                                    </IonSelectOption>
                                  );
                                })}
                              </IonSelect>
                            </IonItem>
                          </IonCol>
                        )}
                    </IonRow>
                  </IonGrid>
                )}
                
                <OrderUserDetails orderData={order.data?.data} shareOrderFunc={shareOrder}/>
            {order.data?.data.status !== "PENDING" && <DeliveryMessage orderId ={order.data?.data.id}/>}

              {isPlatform("android") && !isPlatform("mobileweb") && (order && order.data?.data && order.data?.data.id) && (
                      <>
                      <IonCard>
                      <IonItem lines="none">
                          <IonButton
                            fill="clear"
                            color="secondary"
                            slot="start"
                            onClick={() => handlePrintReceipt(order.data?.data.id)}
                          >
                            <IonIcon
                              slot="start"
                              style={{fontSize: "24px"}}
                              icon={receiptIcon}
                            ></IonIcon>&nbsp;
                            <h4>Print Receipt</h4>
                          </IonButton>
                      </IonItem>
                    </IonCard>
                    <IonCard>
                    <IonItem lines="none">
                        <IonButton
                          fill="clear"
                          color="secondary"
                          slot="start"
                          onClick={() => handleDownload(order.data?.data.id)}
                        >
                          <IonIcon
                            slot="start"
                            style={{fontSize: "24px"}}
                            icon={downloadIcon}
                          ></IonIcon>&nbsp;
                          <h4>Receipt</h4>
                        </IonButton>
                    </IonItem>
                  </IonCard>
                  </>
                    )}
                 {isPlatform("android") && !isPlatform("mobileweb") && (order.data?.data.status == "SCHEDULED") && (
                      <IonCard
                        style={{
                          display: "grid",
                          justifyContent: "space-between",
                        }}
                      >
                        <IonItem>
                          <IonText>Scheduled For</IonText>
                          <IonIcon icon={time} style={{ marginLeft: "10px" }} />
                          <br />{" "}
                        </IonItem>
                        <IonItem>
                          <IonText>
                            Date : {order.data?.data.schedule_order_date}
                          </IonText>
                        </IonItem>
                        <IonItem>
                          <IonText>
                            Time : {order.data?.data.schedule_order_time}
                          </IonText>
                        </IonItem>
                      </IonCard>
                    )}
              </IonCol>
              {!(isPlatform("hybrid") && isPlatform("android")) ? (
                <IonCol sizeXl="3.5">
                  <IonRow>
                    <IonCol className="ion-no-padding">
                      <IonCard className="margin-vertical-for-toolbar">
                        <IonItem lines="none">
                          {order && order.data?.data ? (
                            <IonButton
                              fill="clear"
                              color="secondary"
                              routerLink={`/orders/orderdetails/invoice/${order.data?.data.id}`}
                              slot="start"
                            >
                              <IonIcon
                                slot="start"
                                size="large"
                                icon={documentTextIcon}
                              ></IonIcon>
                              <h4>Invoice</h4>
                            </IonButton>
                          ) : (
                            ""
                          )}
                        </IonItem>
                      </IonCard>
                    </IonCol>
                    {(order && order.data?.data && order.data?.data.id) && (
                    <>
                    <IonCol className="ion-no-padding">
                      <IonCard className="margin-vertical-for-toolbar">
                        <IonItem lines="none">
                            <IonButton
                              fill="clear"
                              color="secondary"
                              slot="start"
                              onClick={() => handlePrintReceipt(order.data?.data.id)}
                            >
                              <IonIcon
                                slot="start"
                                style={{fontSize: "24px"}}
                                icon={receiptIcon}
                              ></IonIcon>&nbsp;
                              <h4>Print Receipt</h4>
                            </IonButton>
                        </IonItem>
                      </IonCard>
                    </IonCol>
                    <IonCol className="ion-no-padding">
                        <IonCard className="margin-vertical-for-toolbar">
                    <IonItem lines="none">
                        <IonButton
                          fill="clear"
                          color="secondary"
                          slot="start"
                          onClick={() => handleDownload(order.data?.data.id)}
                        >
                          <IonIcon
                            slot="start"
                            style={{fontSize: "24px"}}
                            icon={downloadIcon}
                          ></IonIcon>&nbsp;
                          <h4>Receipt</h4>
                        </IonButton>
                    </IonItem>
                  </IonCard>
                    </IonCol>
                    </>
                    )}
                    {order.data?.data.status === "SCHEDULED" && 
                      <IonCol className="ion-no-padding">
                      <IonCard className="margin-vertical-for-toolbar">
                        <IonItem>
                          <IonText>Scheduled For</IonText>
                          <IonIcon icon={time} style={{ marginLeft: "10px" }} />
                          <br />{" "}
                        </IonItem>
                        <IonItem>
                          <IonText>
                            Date : {order.data?.data.schedule_order_date}
                          </IonText>
                        </IonItem>
                        <IonItem lines="none">
                          <IonText>
                            Time : {order.data?.data.schedule_order_time}
                          </IonText>
                        </IonItem>
                      </IonCard>
                      </IonCol>
                    }
                    <IonCol className="ion-no-padding">
                      <>
                      {order.data?.data.status !== "PENDING" ? (
                        <IonCard
                          onClick={(e) => setShowModalForStatus(true)}
                          color="medium"
                          className="margin-vertical-for-toolbar"
                        >    
                        <IonItem lines="none" color="medium">       
                          <IonGrid className="no-padding-left">
                            <IonRow>
                              <IonCol size="12" className="no-padding-left">
                                    <IonLabel style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                      <span>Change Order Status</span>
                                      
                                      <span>
                                    <IonButtons>
                                    <IonButton fill='clear' style={{color:'white'}} onClick={(e) => {
                                        e.stopPropagation();
                                        openLinkInNewTab();
                                    }}>
                                      <IonIcon icon={informationCircleOutline} slot="icon-only">

                                      </IonIcon>
                                    </IonButton>
                                      </IonButtons>

                                      </span>
                                    
                                </IonLabel>
                                  </IonCol>
                              <br />
                              <IonCol size="12" className="no-padding-left">
                                <IonText>{order.data?.data.status}</IonText>
                                <IonIcon icon={caretDownIcon} />
                              </IonCol>
                            </IonRow>
                          </IonGrid>  
                          </IonItem>        
                        </IonCard>
                         ): null}
                        <IonModal className="auto-height"
                          id="statusmodal"
                          isOpen={showModalForStatus}
                          onDidDismiss={(e) => {
                            setShowModalForStatus(false);
                            setCustomMessage("");
                            setStatusId("");
                          }}
                        >
                          <IonHeader>
                            <IonItem lines="none">
                                <IonText className="ion-padding-start">
                                    <h5>
                                    Select Order Status
                                    </h5>
                                </IonText>
                              <IonButton
                                style={{marginLeft: 0}}
                                fill="clear"
                                size="large"
                                slot="end"
                                onClick={(e) => setShowModalForStatus(false)}
                              >
                                <IonIcon
                                  slot="icon-only"
                                  icon={closeOutlineIcon}
                                ></IonIcon>
                              </IonButton>
                            </IonItem>
                          </IonHeader>
                          <IonContent className="ion-padding">
                            <IonRadioGroup
                              value={statusId ? statusId : order.data?.data.status}
                              onIonChange={(e) => setStatusId(e.detail.value)}
                            >
                              {order.data?.data.available_order_statuses.map(
                                (element) => (
                                  <IonItem
                                    key={element.status}
                                    lines="none"
                                    className="ion-padding-start"
                                  >
                                    {element.display_name}
                                    <IonRadio
                                      slot="start"
                                      value={element.status}
                                    />
                                  </IonItem>
                                )
                              )}
                            </IonRadioGroup>
                            <IonItem className="ion-padding-horizontal">
                              <IonInput
                                placeholder="Add Message"
                                type="text"
                                value={customMessage}
                                onIonChange={(e) =>
                                  setCustomMessage(e.detail.value)
                                }
                                onFocus={() => setShowDynamicOptions(true)}
                              />
                            </IonItem>
                            <br />
                            {showDynamicOptions && (
                              <IonSlides
                                ref={mySlides}
                                pager={true}
                                options={slideOpts}
                                onIonSlidesDidLoad={slidesDidLoad}
                              >
                                {suggestionData.data?.data &&
                                  suggestionData.data.data.map((elem) => (
                                    <IonSlide key={elem}>
                                      <IonCard
                                        onClick={() => handleOptionClick(elem)}
                                      >
                                        <IonCardContent>
                                          <IonText className="ion-margin ion-padding">
                                            {elem}
                                          </IonText>
                                        </IonCardContent>
                                      </IonCard>
                                    </IonSlide>
                                  ))}
                              </IonSlides>
                            )}
                            <IonItem lines="none">
                              <IonButton
                                fill="clear"
                                size="large"
                                slot="end"
                                onClick={(e) => setShowModalForStatus(false)}
                              >
                                CANCEL
                              </IonButton>
                              <IonButton
                                fill="clear"
                                size="large"
                                slot="end"
                                onClick={(e) => changeStatus(e)}
                              >
                                OK
                              </IonButton>
                            </IonItem>
                          </IonContent>
                        </IonModal>
                      </>
                    </IonCol>
                  </IonRow>
                </IonCol>
              ) : null}
            </IonRow>
          </IonGrid>
        ) : (
          <IonText
            className={`ion-align-items-center ion-text-center ${
              order?.loading === true ? "ion-hide" : ""
            }`}
          >
            <h5>No order to display.</h5>
          </IonText>
        )}
      </IonContent>
      {order && order.data?.data && (
        <>
          {order.data?.data.status === "PENDING" ? (
            <IonGrid className="ion-no-margin" style={{ flexGrow: 0 }}>
              <IonRow>
                <IonCol>
                  <IonButton
                    color="success"
                    expand="block"
                    onClick={(e) =>
                      orderStatusChange(
                        true,
                        order.data?.data.schedule_order_date,
                        order.data?.data.schedule_order_time
                      )
                    }
                  >
                    <IonIcon icon={checkmarkOutlineIcon}></IonIcon>
                    Accept
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    color="danger"
                    expand="block"
                    onClick={(e) =>
                      orderStatusChange(
                        false,
                        order.data?.data.schedule_order_date,
                        order.data?.data.schedule_order_time
                      )
                    }
                  >
                    <IonIcon icon={closeOutlineIcon}></IonIcon>
                    Reject
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            <>
              {(isPlatform("android") && !isPlatform("mobileweb")) ? (
                <IonItem
                  lines="none"
                  onClick={(e) => setShowModalForStatus(true)}
                  color="medium"
                >
                  <IonLabel>Change Order Status</IonLabel>
                  <IonText slot="end">{order.data?.data.status}</IonText>
                  <IonIcon slot="end" icon={caretDownIcon} />
                </IonItem>
              ) : null}
              <IonModal
                id="statusmodal"
                isOpen={showModalForStatus}
                onDidDismiss={(e) => {
                  setShowModalForStatus(false);
                  setCustomMessage("");
                  setStatusId("");
                }}
              >
                <IonHeader>
                  <IonItem lines="none">
                  <IonText className="ion-padding-start">
                        <h5>
                        Select Order Status
                        </h5>
                    </IonText>
                    <IonButton
                      style={{marginLeft: 0}}
                      fill="clear"
                      size="large"
                      slot="end"
                      onClick={(e) => setShowModalForStatus(false)}
                    >
                      <IonIcon
                        slot="icon-only"
                        icon={closeOutlineIcon}
                      ></IonIcon>
                    </IonButton>
                  </IonItem>
                </IonHeader>
                <IonContent className="ion-padding">
                  <IonRadioGroup
                    value={statusId ? statusId : order.data?.data.status}
                    onIonChange={(e) => {setStatusId(e.detail.value);}}
                  >
                    {order.data?.data.available_order_statuses.map((element) => (
                      <IonItem
                        key={element.status}
                        lines="none"
                        className="ion-padding-start"
                      >
                        {element.display_name}
                        <IonRadio slot="start" value={element.status} />
                      </IonItem>
                    ))}
                  </IonRadioGroup>
                  <IonItem className="ion-padding-horizontal">
                    <IonInput
                      placeholder="Add Message"
                      type="text"
                      value={customMessage}
                      onIonChange={(e) => setCustomMessage(e.detail.value)}
                      onFocus={() => setShowDynamicOptions(true)}
                    />
                  </IonItem>
                  <br />
                  {showDynamicOptions && (
                    <IonSlides
                      ref={mySlides}
                      pager={true}
                      options={slideOpts}
                      onIonSlidesDidLoad={slidesDidLoad}
                    >
                      {suggestionData.data?.data && suggestionData.data.data.map((elem) => (
                          <IonSlide key={elem}>
                            <IonCard onClick={() => handleOptionClick(elem)}>
                              <IonCardContent>
                                <IonText className="ion-margin ion-padding">
                                  {elem}
                                </IonText>
                              </IonCardContent>
                            </IonCard>
                          </IonSlide>
                        ))}
                    </IonSlides>
                  )}
                  <IonItem lines="none">
                    <IonButton
                      fill="clear"
                      size="large"
                      slot="end"
                      onClick={(e) => setShowModalForStatus(false)}
                    >
                      CANCEL
                    </IonButton>
                    <IonButton
                      fill="clear"
                      size="large"
                      slot="end"
                      onClick={(e) => changeStatus(e)}
                    >
                      OK
                    </IonButton>
                  </IonItem>
                </IonContent>
              </IonModal>
            </>
          )}
        </>
      )}
    </IonPage>
  );
};

export default OrderDetails;
