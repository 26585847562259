import { IonAccordion, IonCard, IonInput, IonItem, IonLabel, IonReorder, IonToggle } from '@ionic/react';
import { useWebsiteSettings } from '../../../../../utils/contexts';

const RecentProducts = ({data}) => {
  const settings:any = useWebsiteSettings();
  return (
    <IonCard>
      <IonAccordion>
          <IonItem slot="header" color='light'>
              <IonLabel>Recent product</IonLabel>
              {data.reorder && <IonReorder slot="end"/>}
              {data.toggle && <IonToggle checked={settings.websiteSettings.product_sliders.display === 1} color="medium" onIonChange={e => settings.setWebsiteSettings({...settings.websiteSettings, product_sliders: {...settings.websiteSettings.product_sliders, display: e.detail.checked ? 1 : 0}  })} slot="end"></IonToggle>}
          </IonItem>
          <div className="ion-padding" slot="content">
          <IonItem>
              <IonLabel position="stacked">Heading</IonLabel>
              <IonInput
                placeholder="Write heading here."
                type="text"
                value={settings.websiteSettings.product_sliders.component_heading}
                onIonChange={e=> settings.setWebsiteSettings({...settings.websiteSettings, product_sliders: {...settings.websiteSettings.product_sliders, component_heading: e.detail.value} })}
              />
            </IonItem>
          </div>
        </IonAccordion>
      </IonCard>
)}

export default RecentProducts