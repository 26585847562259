export const API_NOT_REQUIRE = ['currentUser']
export const API_RESTRICT_FOR_STAFF = ['shiprocketAddon']

export const TOUCH_TARGET_MAP = {
    'SCREEN.NONE':'/userdashboard',
    'SCREEN.HELP':'/help&support',
    'SCREEN.CUSTOMERS':'/customer',
    'SCREEN.LEARNGROW':'/ikacademy',
    'SCREEN.BANNERS':'/banners',
    'SCREEN.COUPONS':'/coupons',
    'SCREEN.CATALOG':'/catalogue/categories',
    'SCREEN.SETTINGS.LOCALISATION':'/settings/localization',
    'SCREEN.SETTINGS.BASICINFO':'/settings/basicinfo'
}

export const ORDER_STATUSES_AUTO = [
    {status: 'NEW', display_name: 'New'},
    {status: 'PROCESSING', display_name: 'Processing'},
    {status: 'DELIVER', display_name: 'Delivered'},
    {status: 'CANCEL', display_name: 'Cancelled'},
    {status: 'RETURN', display_name: 'Returned'},
    {status: 'DENY', display_name: 'Denied'}
]

export const ORDER_STATUSES_MANUAL = [
    {status: 'PENDING', display_name: 'Pending'},
    {status: 'NEW', display_name: 'New'},
    {status: 'PROCESSING', display_name: 'Processing'},
    {status: 'DELIVER', display_name: 'Delivered'},
    {status: 'CANCEL', display_name: 'Cancelled'},
    {status: 'RETURN', display_name: 'Returned'},
    {status: 'DENY', display_name: 'Denied'}
]

export const ORDERS_PER_PAGE = 10