import { IonAccordion, IonButton, IonCard, IonCol, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonReorder, IonRow, IonText, IonToggle, useIonToast } from '@ionic/react';
import { createSharp as createSharpIcon, trashSharp as trashSharpIcon } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import useAPI from '../../../../../utils/api';
import { useWebsiteSettings } from '../../../../../utils/contexts';
import UpdatePosterModal from '../../components/modal/UpdatePosterModal';
import { useHistory } from 'react-router';
import StickyProductModal from '../../components/modal/StickyProductModal';

const StickyProduct = ({data}) => {
  const settings:any = useWebsiteSettings();
  const API = useAPI();
  const [present] = useIonToast();
  const [stickyProduct, setStickyProduct] = useState<any>([]);
  
  const [productID, setproductID] = useState<any>([]);
  const history = useHistory();
  const [modalData, setModalData] = useState({
    open: false,
    data: null
  })

  useEffect(() => {
    let sticky_product_info = settings?.websiteSettings?.sticky_product?.sticky_product_info;
    if(sticky_product_info){
      setStickyProduct(sticky_product_info);
    }
  }, [settings]);

  useEffect(()=>{
    getProduct()
  },[])
  const  getProduct=async()=>{
    const [ok, response] = await API.stickyProduct();
    if(ok && response )
    {
     
      let arr=[];
      response.data.map((item)=>{
        arr.push(item.product_id);
      })
      setproductID(arr)
    }


  }

  console.log("RRRR",productID);
  async function deletesticky(id)
  {
try{
  const [ok, response] = await API.deleteStickyProduct(id);
  if(ok && response){
    const [okay, resData] = await API.theme();
    if(okay){
      let updatedSticky = resData?.data?.sticky_product.sticky_product_info;
      const [postOk, postRes] = await API.updateTheme({...resData?.data, sticky_product: {...resData?.data?.sticky_product, sticky_product_info: updatedSticky}});
             if(postOk){
              present("Sticky Product Deleted successfully!", 1000);
              settings.setUpdate(!settings.update);
             }
    
    }

  }

}
catch(err){

}
  }
  return (<>
 {console.log("productID",productID)}
 <StickyProductModal modalData={modalData} setModalData={setModalData} productID={productID}></StickyProductModal>
    <IonCard>
      <IonAccordion>
          <IonItem slot="header" color='light'>
              <IonLabel>Sticky Product</IonLabel>
              {data.reorder && <IonReorder slot="end"/>}
              {data.toggle && <IonToggle checked={settings.websiteSettings.sticky_product.display === 1} color="medium" onIonChange={e => settings.setWebsiteSettings({...settings.websiteSettings, sticky_product: {...settings.websiteSettings.sticky_product, display: e.detail.checked ? 1 : 0}  })} slot="end"></IonToggle>}
</IonItem>
<div className="ion-padding" slot="content">
            <IonItem>
              <IonLabel position="stacked">Heading</IonLabel>
              <IonInput
                placeholder="Write heading here."
                type="text"
                value={settings.websiteSettings.sticky_product.component_heading}
                onIonChange={e=> settings.setWebsiteSettings({...settings.websiteSettings, sticky_product: {...settings.websiteSettings.sticky_product, component_heading: e.detail.value} })}
              />
            </IonItem>
          </div>
          
          <div className="ion-padding" slot="content">
          <IonLabel> Product List</IonLabel>
          {stickyProduct && stickyProduct.map((id, index) => <IonItem lines='none' className='ion-margin-bottom' key={id}>
         
          <IonCol  size='3'>
          
                        <img src={id.variants[0].images[0].image_url_thumb}    />
                    </IonCol>
              
                    <IonCol size="10" style={{ paddingLeft:"10px" }}>
            {id.name} 
            </IonCol>
              {/* <IonButton onClick={e=> setModalData({...modalData, open: true, data: bannerDataByID[id] })} slot='end'><IonIcon icon={createSharpIcon}/> </IonButton> */}
              <IonButton color="danger" slot='end'     onClick={e=> {deletesticky(id.id) }} ><IonIcon icon={trashSharpIcon}/> </IonButton>
           
            </IonItem>
            )}
          <IonButton onClick={e=> setModalData({...modalData, open: true, data: null })} shape='round' expand='full'>Add Product</IonButton>
          {/* <IonButton  onClick={() => history.push({ pathname:`/catalogue/products`, state: {"data":productID} })} shape='round' expand='full'>Add Product</IonButton> */}
          </div>
        
        </IonAccordion>
      </IonCard>
      </>
  )
}

export default StickyProduct