import { Camera, CameraResultType } from '@capacitor/camera';
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonText,
    IonToolbar,
    isPlatform,
    useIonAlert,
    useIonToast,IonGrid,IonCard,IonRow
} from '@ionic/react';
import {
    arrowBack as arrowBackIcon, informationCircleOutline, logoYoutube
} from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import useAPI from '../utils/api';
import { useBrands, useCategory } from '../utils/contexts';
import { useDispatch, useSelector } from "react-redux";
import { alphaNumericValidation, atleastOneCharValidation } from '../utils/validations';
import { trash as trashIcon } from "ionicons/icons";
interface RouteParams {
    id: string;
    name: string;
    fp: string;
}

const AddUpdateBrand = (props: any) => {
    const { id } = useParams<RouteParams>();
    const { name } = useParams<RouteParams>();
    const { fp } = useParams<RouteParams>();
    const [showModal, setShowModal] = useState(false);
    const [imageClicked, setImageClicked] = useState<any>();
    const [showButton, setShowButton] = useState(true);
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state: any) => state.currentUser);
    const { mediaLibraryLoading, mediaLibrary } = useSelector(
        (state: any) => state.mediaLibrary);
 
    const [brandName, setBrandName] = useState('');
    const [isNameValidate, setIsNameValidate] = useState(true);

    const [pageName, setPageName] = useState({
        first: "Add",
        second: "Brands"
    });
    const [iconCode, setIconCode] = useState('');
    const [pictureUrl, setPictureUrl] = useState('');
    const [platformPath, setPlatformPath] =useState<string | null>(null);
    const [imageFile, setImageFile] = useState(null);
    const fileInputRef = useRef<HTMLInputElement>();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const location: any = useLocation();
    const API = useAPI();
    const [present] = useIonToast();
    const [alertPresent] = useIonAlert();
    const brandscontext:any=useBrands()
    console.log("brandscontext",brandscontext)
    const[brandsList,setBrandsList]=useState([]);

    useEffect(() => {
        if (location.pathname.includes("add")) {
            if (location.pathname.includes("add-brands")) {
                setPageName({
                    first: "Add",
                    second: "Brand"
                });
            }  
        } else if (location.pathname.includes("update")) {
            if (location.pathname.includes("update-brands")) {
             
                setPageName({
                    first: "Update",
                    second: "Brand"
                });
            } 
            setBrandsList(brandscontext?.brands);
        }
         

    }, [location.pathname,brandscontext]);

    useEffect(()=>{
      const brandToUpdate = brandscontext?.brands?.find((item) => item.id === id);
      // alert(id)
      
     if(id){
      console.log(brandsList,"brandToUpdate",brandToUpdate,brandscontext,"p")
      brandscontext?.brands?.map((item)=>{
        if(item.id==id){
        setBrandName(item.name);     
        setPictureUrl(item.icon)
        setIconCode(item.icon)
        }
      })
    }
    
     
       
    },[brandscontext?.brands,id])


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files.length > 0) {
            const file = event.target.files.item(0);
            setImageFile(file);
            const pictureUrl = URL.createObjectURL(file);
            setPictureUrl(pictureUrl);
            setShowButton(false);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }
    };
   
    const handlePictureClick = async () => {
        if (isPlatform('capacitor')) {
            try {
                const photo = await Camera.getPhoto({
                    resultType: CameraResultType.Uri,
                })
                setPictureUrl(photo.webPath);
                const file = await fetch(photo.webPath).then((r) =>
                    r.blob()
                );
                setImageFile(file);
            }
            catch (error) {
                console.log(error);
            }
        }
        else {
            fileInputRef.current.click();
        }
    }
    const handleImageSelection = (imageUrl,path) => {
        setPictureUrl(imageUrl);
        setPlatformPath(path);
        setShowModal(false);
        setShowButton(false);
      };
        
         useEffect(() => {
            setShowButton(!pictureUrl);
        }, [pictureUrl]);
        
    const handleSave = async () => {
        // if (pageName.second !== "Brands" && pageName.first !== "Update"  && (!imageFile && !iconCode) || !mediaLibrary && iconCode) {
        //     present("Image is mandatory for Brands", 2000)            
        // }
          {       
            setLoading(true);
            let imageRes: any;
            if (imageFile) {
                imageRes = await API.uploadImage(imageFile, "brand-image", imageFile.name || 'brand-image.jpg'); 
            }
            let res = true
            if(!alphaNumericValidation(brandName) || atleastOneCharValidation(brandName)){
                res = false;
                setIsNameValidate(false);
            }
         
            if ((pageName.first === "Add" || (platformPath) ||(imageRes && imageRes[0]  && imageRes[1]?.platform_path) || (pageName.first === "Update" && iconCode)) && res ) {
              
              const [createOk, createResponse] = pageName.first === "Add" ? await API.createBrands({
                    "name": brandName,
                    "icon": (imageRes && imageRes[1]?.internet_url) || platformPath  || null,
                    "status": 'active'
                }) : await API.updateBrands({
                    "id": id,
                    "name": brandName,
                    "icon": (imageRes && imageRes[1]?.internet_url) || platformPath || iconCode || null,
                    "status": 'active'
                });
                // console.log("hello",createResponse)
                if (createOk && createResponse) {
                    setBrandName('')
                    setPictureUrl('');
                    setImageFile(null);
                    setLoading(false); 
                    setPlatformPath(null); 
                    fileInputRef.current.value = null;
                    brandscontext.setUpdatedBrandsData(!brandscontext.updatedBrandsData);
                    history.push({ pathname: `/catalogue/brands` });
                    
                   
                } else {
                    if (!createOk) {
                        present(createResponse.message || "Some error occured!", 2000)
                    }
                }
            }
            setLoading(false);
        }
    }

    
    const deleteImage = () => {
      setPictureUrl('');
      setIconCode('');
      setPlatformPath('');
      setShowButton(true);
      setImageFile(null);
    };  

    return (
      <IonPage>
        <IonHeader style={{ height: '68px' }}>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton
                defaultHref="/catalogue/brands"
                icon={arrowBackIcon}
              />
            </IonButtons>
            <MobileView>
              <IonText className="ion-no-margin">{`${pageName.first} ${pageName.second}`}</IonText>
            </MobileView>
            <BrowserView>
              <IonText className="ion-no-margin">
                <h5>{`${pageName.first} ${pageName.second}`}</h5>
              </IonText>
            </BrowserView>
            {/* <IonButtons slot="end">
              <IonButton
                size="small"
                target="_blank"
                href="https://www.youtube.com/watch?v=dkOvPjF41YA"
                color="medium"
              >
                <IonIcon slot="start" icon={logoYoutube}></IonIcon>
                How to Use
              </IonButton>
            </IonButtons> */}
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">   
          <IonRow className="ion-justify-content-center">
          <IonCol size="13" size-md="6" className="ion-margin-horizontal">
          <IonCard className="ion-padding" >
          <IonItem lines="inset">
            <IonLabel position="stacked" className="ion-padding-bottom ">
              <h1>{`${pageName.second} Name`}</h1></IonLabel>  
            <IonInput
              required
              value={brandName}
              autofocus={pageName.first === "Add"}
              placeholder={`Add ${pageName.second} Example  Red Tape`}
              onIonChange={(event) => setBrandName(event.detail.value)}
            />
          </IonItem>
          <IonText color="danger">
            <h5
              className={`error-text ion-margin-horizontal ${
                isNameValidate ? "ion-hide" : ""
              }`}
            >
              {" "}
              {`${pageName.second} should have atlest one letter and should not contain any special character.`}{" "}
            </h5>
          </IonText>
          
          <IonText
            style={{ display: "inline-flex", gap: "10px" }} 
            className="ion-padding"
          >
            <IonIcon 
            icon={informationCircleOutline}
              style={{ fontSize: "22px" , color:"black"}}
            />
            
           <div style={{fontSize:"15px" , color:"black"}}>
             For Best View, Use 500X500px image
             </div>
          </IonText>
          <input
            hidden
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleFileChange}
          />

          {pageName.first === "Add" ? (
            <IonItem lines="none" >
               <IonLabel
                className="ion-padding-bottom"
                position="stacked"
                 
              >
                <h1>{`${pageName.second} Image`}</h1></IonLabel>
              <div>
                {pictureUrl && (
                  <>
                  <IonCard className="category-image-card"> 
                    <img
                    width= "100%"
                    height={ 
                     pictureUrl ==="/assets/placeholder.svg"
                     ? "100%"
                     : "78%"
                    }
                    src={pictureUrl}
                    />
                    <IonButton
                      className="ion-no-margin"
                      expand="full"
                      color="danger"
                      onClick={deleteImage}
                    >
                      <IonIcon icon={trashIcon} slot='icon-only' />
                    </IonButton>
                    </IonCard>
                  </>
                )}  
              </div>
              
              
              <div style={{ margin: "10px 0 0 0" }}>
                {showButton && (
                  <>
                    <IonButton
                      className="ion-no-margin"
                      onClick={(e) => {
                        handlePictureClick();
                        setImageClicked(0);
                      }}
                    >
                      Upload Image
                    </IonButton>
                    <br></br>

                  </>
                )}
              </div>
            </IonItem>
          ) : (
            <div className="category-image-container ion-justify-content-start">
            <IonItem lines="none">
                {/* for update media library */}   
                <div>
                  
                  {pictureUrl && (
                    <>
                    <IonCard className="category-image-card">
                      <img
                    width= "100%"
                    height={ 
                     pictureUrl ==="/assets/placeholder.svg"
                    ? "100%"
                    : "78%"
                    }
                    src={pictureUrl}
                    />
                      <IonButton
                        className="ion-no-margin"
                         expand="full"
                        color="danger"
                        onClick={deleteImage} >
                        <IonIcon icon={trashIcon}  />
                      </IonButton>
                      </IonCard>
                    </>
                  )}
             </div>              
                <div>
                  {showButton && (
                    <>
                      <IonButton
                        className="ion-no-margin"
                        onClick={(e) => {
                          handlePictureClick();
                          setImageClicked(0);
                        }}
                      >
                        Update Image
                      </IonButton>
                      <br></br>
                      {/* <IonButton
                        className="ion-margin-top"
                        onClick={() => mediaClick()}
                      >
                        Media Library
                      </IonButton> */}
                    </>
                  )}
                </div>  
            </IonItem>
            </div>
          )}
          
          
          
          <div className="ion-text-end ion-padding-top">
            <IonButton
              shape="round"
              disabled={brandName === "" ? true : false}
              onClick={handleSave}
            >
              {" "}
              save
            </IonButton>
          </div>
          </IonCard>
          </IonCol>
          
          </IonRow>
          
          {/* {pageName.first === "Update" && pageName.second === "brand" && (
            <div className="ion-text-center ion-margin">
              <Link
                to={{
                  pathname: "/catalogue/products",
                  state: { id: id, name: brandName },
                }}
                style={{ textDecoration: "none" }}
              >
                <IonButton expand="block" shape="round">
                  View All Products
                </IonButton>
              </Link>
            </div>
          )} */}
          <IonLoading isOpen={loading} message={"Loading..."} />
         
        </IonContent>
      </IonPage>
    );
};

export default AddUpdateBrand;