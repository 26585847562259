import { Camera, CameraResultType } from "@capacitor/camera";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonModal, IonText, IonTitle, IonToolbar, isPlatform, useIonToast } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import useAPI from "../../../../../utils/api";
import { useWebsiteSettings } from "../../../../../utils/contexts";
import { informationCircleOutline } from "ionicons/icons";

const UpdateBannerModal = ({ modalData, setModalData }) => {
    const settings: any = useWebsiteSettings();
    const [bannerDetails, setBannerDetails] = useState<any>();
    useEffect(() => {
        setBannerDetails(() => modalData.data ? { ...modalData.data } : {
            image_link: null,
            redirect_link: null
        })
    }, [modalData, setModalData])

    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const API = useAPI();
    const [present] = useIonToast();
    const [errorMessage, setErrorMessage] = useState<string | null>(null); 
    const [linkErrorMessage, setLinkErrorMessage] = useState<string | null>(null); 

    //states for image crop
    const [imageResult, setImageResult] = useState(null);
    const [imageCrop, setImageCrop] = useState({
        width: 1440,
        aspect: 3.2
    });
    const [croppedImageUrl, setCroppedImageUrl] = useState<any>('');
    const [refImage, setRefImage] = useState("");
    const fileInputRef = useRef<HTMLInputElement>();

    const handlePictureClick = async () => {
        if (isPlatform('capacitor')) {
            try {
                const photo = await Camera.getPhoto({
                    resultType: CameraResultType.Uri,
                });
                if (photo.webPath) {
                    setIsFileUploaded(true);
                    setImageResult(photo.webPath);
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        else {
            fileInputRef.current.click();
        }
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setIsFileUploaded(true);
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                setImageResult(reader.result)
            );
            reader.readAsDataURL(event.target.files[0]);

            // Reset error message when a new image is selected
            setErrorMessage(null);
        }
    };

    var ref_image;
    const onImageLoaded = (image) => {
        ref_image = image;
        setRefImage(image);
    };

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop) => {
        setImageCrop(crop);
    };

    async function makeClientCrop(crop) {
        if ((ref_image || refImage) && crop.width && crop.height) {
            if (ref_image) {
                getCroppedImg(ref_image, crop);
            }
            if (refImage) {
                getCroppedImg(refImage, crop);
            }
        }
    }

    async function getCroppedImg(image, crop) {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
        await canvas.toBlob(function (blob) {
            setCroppedImageUrl(new File([blob], 'uploadeBanner.jpeg', { type: 'image/jpeg' }));
        }, 'image/jpeg');
    }

    const isValidURL = (string) => {
        const res = string.match(/(https?:\/\/[^\s]+)/g);
        return (res !== null);
    }

    const addBannerFun = async () => {

        if (!isFileUploaded && !bannerDetails?.image_link) {
            setErrorMessage("Please upload a banner image.");
            return;
        }


        if (bannerDetails?.redirect_link && !isValidURL(bannerDetails.redirect_link)) {
            setLinkErrorMessage("Please provide a valid link.");
            return;
        }


        if (croppedImageUrl) {
            const file = croppedImageUrl;
            const [ok, response]: any = await API.uploadImage(file, "banner-image");
            if (ok && response && response.platform_path) {
                const payload = { ...bannerDetails, image_link: response.platform_path };
                const [createOk, createResponse] = modalData.data ? await API.updateBanner(payload) : await API.createBanner(payload);
                if (createOk && createResponse) {
                    present("Data saved successfully!", 1000);
                    setIsFileUploaded(false);
                    setImageResult(null);
                    setImageCrop({
                        width: 1440,
                        aspect: 3.2
                    });
                    setRefImage('');
                    setCroppedImageUrl('');
                    setBannerDetails({
                        image_link: null,
                        redirect_link: null,
                        display_order: null
                    });
                    setModalData({ ...modalData, open: false, data: null });
                    settings.setUpdate(!settings.update);
                } else {
                    if (createResponse && createResponse.error) {
                        alert(createResponse.error);
                    }
                }
            } else {
                if (response && response.error) {
                    alert(response.error);
                }
            }
        } else {
            const payload = { ...bannerDetails };
            const [ok, response] = await API.updateBanner(payload);
            if (ok && response) {
                present("Data saved successfully!", 1000);
                setIsFileUploaded(false);
                setImageResult(null);
                setImageCrop({
                    width: 1440,
                    aspect: 3.2
                });
                setRefImage('');
                setCroppedImageUrl('');
                setModalData({ ...modalData, open: false, data: null });
                settings.setUpdate(!settings.update);
            } else {
                if (response && response.error) {
                    alert(response.error);
                }
            }
        }
    }
    return (
        <IonModal isOpen={modalData.open}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton color="primary" onClick={e => { setModalData({ ...modalData, open: false }); setIsFileUploaded(false); setImageResult(null); setImageCrop({ width: 1440, aspect: 3.2 }); setRefImage(''); setCroppedImageUrl('') }}>Cancel</IonButton>
                    </IonButtons>
                    <IonTitle>{modalData.data ? "Update Banner" : "Add Banner"}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
            <IonText style={{ display: "flex", gap: "15px", fontSize: "14px", color: "#737373", alignItems: "center" }} className="ion-margin-bottom ion-justify-content-center">
            <IonIcon
                    className="ion-margin-right"
                    icon={informationCircleOutline}
                    style={{ fontSize: "25px" }}
                />
                Images of 1440x450 px are recommended
            </IonText>
                {imageResult && (
                    <ReactCrop
                        src={imageResult}
                        crop={imageCrop}
                        ruleOfThirds
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                        locked={true}
                    />
                )}
                <input hidden type='file' accept="image/*" ref={fileInputRef} onChange={handleFileChange} />
                <div className="ion-text-center">
                    {bannerDetails?.image_link && !imageResult && <IonImg src={bannerDetails?.image_link} alt="banner" />}
                    <IonButton shape="round" onClick={handlePictureClick}>{isFileUploaded || bannerDetails?.image_link ? "Change Banner" : "Upload Banner"}</IonButton>
                </div>
                {errorMessage && ( // Display the error message
                    <div className="ion-text-center ion-margin-top">
                        <IonLabel color="danger">{errorMessage}</IonLabel>
                    </div>
                )}
                <IonItem>
                    <IonLabel position="stacked">Banner Link</IonLabel>
                    <IonInput value={bannerDetails?.redirect_link} type="url" placeholder="Banner link" onIonChange={e => setBannerDetails({ ...bannerDetails, redirect_link: e.target.value })} />
                </IonItem>
                {linkErrorMessage && (
                    <div className="ion-text-center ion-margin-top">
                        <IonLabel color="danger">{linkErrorMessage}</IonLabel>
                    </div>
                )}
                <div className="ion-text-center">
                    <IonButton shape="round" onClick={e => addBannerFun()}>Save Banner</IonButton>
                </div>
            </IonContent>
            

            

        </IonModal>
    )
}

export default UpdateBannerModal