import {
    IonAccordion,
    IonAccordionGroup,
    IonCard,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel
} from "@ionic/react";

import {
    chevronDownOutline,
} from "ionicons/icons";

import "react-quill/dist/quill.snow.css";

const ProductMetaTags = ({metaTagData, setMetaTagData}) => {
    return (
            <>
            <IonCard>
            <IonAccordionGroup>
                
                <IonAccordion>
                    <IonItem slot="header" >
                        <IonLabel>Meta Tags</IonLabel>
                        <IonIcon icon={ chevronDownOutline} className="ion-accordion-toggle-icon md" slot="end" color="primary" ></IonIcon>
                        
                    </IonItem>

                    <div className="ion-padding zero-padding-horizontal" slot='content'>
                        <IonItem lines="inset">
                            <IonLabel position="stacked" className="ion-padding-bottom"><h1 className="label">Meta Title</h1></IonLabel>
                            <IonInput
                                value={metaTagData.metaTitle}
                                placeholder="Enter Meta Title"
                                type="text"
                                onIonChange={e => { setMetaTagData({...metaTagData, metaTitle: e.detail.value}) }} />
                        </IonItem>

                        <IonItem lines="inset">
                            <IonLabel position="stacked" className="ion-padding-bottom"><h1 className="label">Meta Description</h1></IonLabel>
                            <IonInput
                                value={metaTagData.metaDescription}
                                placeholder="Enter Meta Description"
                                type="text"
                                onIonChange={e => { setMetaTagData({...metaTagData, metaDescription: e.detail.value}) }} />
                        </IonItem>

                        <IonItem lines="inset">
                            <IonLabel position="stacked" className="ion-padding-bottom"><h1 className="label">Meta Keyword</h1></IonLabel>
                            <IonInput
                                value={metaTagData.metaKeyword}
                                placeholder="Enter Meta Keyword"
                                type="text"
                                onIonChange={e => { setMetaTagData({...metaTagData, metaKeyword: e.detail.value}) }} />
                        </IonItem>
                    </div>
                </IonAccordion>
            </IonAccordionGroup>

            </IonCard>
            </>
    );
};

export default ProductMetaTags;
