import { IonAccordion, IonCard, IonItem, IonLabel, IonReorder, IonToggle } from '@ionic/react';
import { useWebsiteSettings } from '../../../../../utils/contexts';

const CategorySubcategoryList = ({data}) => {
  const settings:any = useWebsiteSettings();
  return (
    <IonCard>
      <IonAccordion>
          <IonItem slot="header" color='light'>
              <IonLabel>category subcategory list</IonLabel>
              {data.reorder && <IonReorder slot="end"/>}
              {data.toggle && <IonToggle slot="end" checked={settings.websiteSettings.category_subcategory_lists.display === 1} color="medium" onIonChange={e => settings.setWebsiteSettings({...settings.websiteSettings, category_subcategory_lists: {...settings.websiteSettings.category_subcategory_lists, display: e.detail.checked ? 1 : 0}})}></IonToggle>}
          </IonItem>
          <div className="ion-padding" slot="content">
            Options are coming soon...
          </div>
        </IonAccordion>
      </IonCard>
  )
}

export default CategorySubcategoryList