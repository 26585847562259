import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonPage, IonText, IonTitle, IonToggle, IonToolbar, useIonToast } from '@ionic/react';
import {
    arrowBack as arrowBackIcon,
    informationCircleOutline
} from 'ionicons/icons';
import { useState } from 'react';
import useAPI from '../../../utils/api';
import { useAddon } from '../../../utils/contexts';
import { indexedValue } from '../AddonsRoutes';
import FeatHeader from '../../../components/dashboard/FeatHeader';


const FestivalForm = () => {
    const addons:any = useAddon();
    const [present] = useIonToast();
    const API = useAPI();
    const [statusCheck, setStatusCheck] = useState(Array.isArray(addons[indexedValue.Festival].data) ? false : addons[indexedValue.Festival].data?.status === 'active');
    const [christmasTheme, setChristmasTheme] = useState(Array.isArray(addons[indexedValue.Festival].data) ? false : addons[indexedValue.Festival].data?.christmas_theme === 'active');

    const onSubmitHandler = async(e)=>{
        e.preventDefault();
            const [ok, response] = Array.isArray(addons[indexedValue.Festival].data) ? 
                await API.PostFestivalAddon({
                    "status": statusCheck ? "active" : "inactive",
                    "christmas_theme":christmasTheme ? "active" : "inactive",
                })
                :
                await API.PostFestivalAddon({
                    "status": statusCheck ? "active" : "inactive",
                    "christmas_theme":christmasTheme ? "active" : "inactive",
                    id: addons[indexedValue.Festival].data.id
                })
                if(response.statusCode === 400){
                    setStatusCheck(!statusCheck);
                    present(response.message, 1500);
                }
                if(ok) {
                    present("Data saved successfully!", 1500);
                    addons[indexedValue.Festival].updateMethod(!addons[indexedValue.Festival].update);

                }
    }

    const openLinkInNewTab = () => {
        window.open(`https://intelikart.tawk.help/article/festival-theme`, '_blank');
    };


    return ( <IonPage>
        <FeatHeader
        
            title='Configure Festival'
            defaultHref='/add-ons'
            showInfoButton={true}
            onInfoClick={openLinkInNewTab}
        />
            <IonContent className="ion-padding">
                <form onSubmit={onSubmitHandler}>
                    <IonItem lines="none">
                        <IonText><h5>Enable New year theme</h5></IonText>
                        <IonToggle slot="end" checked={statusCheck} onIonChange={e => setStatusCheck(e.detail.checked)} />
                    </IonItem>
                    <IonItem lines="none">
                        <IonText><h5>Enable Christmas theme</h5></IonText>
                        <IonToggle slot="end" checked={christmasTheme} onIonChange={e => setChristmasTheme(e.detail.checked)} />
                    </IonItem>
                    <br/>
                    <div className="ion-text-center">
                        <IonButton type="submit">Save</IonButton>
                    </div>
                </form>
            </IonContent>
        </IonPage>
    )
}

export default FestivalForm;