import {
    IonLoading,
    IonPage,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateUserWhenLogin, getCurrentUser } from '../actions/authActions';
import { setTokensObject } from '../services/auth/userLocalStorage';

const SSO: React.FC = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [isNewTokenUserLoaded, setIsNewTokenUserLoaded] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const { currentUserLoading, currentUser, currentUserError } = useSelector((state: any) => state.currentUser);
    const token = new URLSearchParams(props.location.search).get("token");
    const redirectUrl = new URLSearchParams(props.location.search).get("redirect_url");

    const loadCurrentUser = async (token: string) => {
        setLoading(true);
        if (token) {
            const response = await fetch(`https://api2.intelikart.in/oauth/sso?access_token=${token}`);
            const data = await response.json();
            if (response.status === 200 && data.data.store.storetype_id !== 0) {
                await dispatch(getCurrentUser(data.data.token));
                setLoading(false);
                setTokensObject(data.data);
                await setIsNewTokenUserLoaded(true);
            }
        }
    }

    useEffect(() => {
        if (token) {
            loadCurrentUser(token);
        }
    }, [token]);

    useEffect(() => {
        if (isNewTokenUserLoaded) {
            if (currentUserLoading === false && currentUser && currentUser.data) {
                dispatch(authenticateUserWhenLogin());
                if (currentUser.data.store.storetype_id !== 0) {
                    if (redirectUrl) {
                        history.push(`/${redirectUrl}`, { direction: 'none' });
                    } else {
                        history.push('/userdashboard', { direction: 'none' });
                    }
                } else {
                    history.push('/getbusinessdetails');
                }
            }
            if (currentUserLoading === false && currentUserError) {
                history.push('/')
            }
        }
    }, [currentUser, currentUserLoading, isNewTokenUserLoaded]);

    return (
        <IonPage>
            <IonLoading isOpen={currentUserLoading || loading}></IonLoading>
        </IonPage>
    );
};

export default SSO;