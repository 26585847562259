import {
    IonButton,
    IonCard,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonRadio,
    IonRadioGroup,
    IonText,
    IonThumbnail,
    IonTitle
} from "@ionic/react";
import {
    caretDown as caretDownIcon,
    closeOutline
} from "ionicons/icons";
import { useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useBrands } from "../../../utils/contexts";

const BrandModal = ({ brand,setBrand, setBrandId }) => {
    console.log("brand", brand);
    const brandsData: any = useBrands();
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <IonCard>
            <IonItem lines="none" onClick={(e) => setShowModal(true)}>
                <IonLabel>Select Brand</IonLabel>
                {brand ? <IonText slot="end" color="primary">{brand.name}</IonText> : ""}
                <IonIcon color="primary" slot="end" size="small" icon={caretDownIcon} />
            </IonItem>

            </IonCard>
            <IonModal isOpen={showModal} onDidDismiss={(e) => setShowModal(false)}>
                <IonHeader>
                    <IonItem lines="none">
                        <IonTitle>Select Brand</IonTitle>
                        <IonButton fill="clear" size="large" slot="end" onClick={(e) => setShowModal(false)}>
                            <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                        </IonButton>
                    </IonItem>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonRadioGroup
                        value={brand?.id}
                        onIonChange={(e) => {
                            setBrandId(e.detail.value);
                            setShowModal(false);
                        }}
                    >
                        {!brandsData.loading && brandsData.status === "fetched" && brandsData.brands
                            ? brandsData.brands.map((parent) => (
                                  <IonItem key={parent.id}                               
                                 onClick={(e) => setBrand(parent)}
                                  lines="none"
                                  className="ion-padding-start"
                                  >
                                 
                                      <IonText color="medium">
                                    
                                          <h5>{parent.name}</h5>
                                      </IonText>
                                     
                                      <IonRadio slot="start" value={parent.id} />
                                      <IonThumbnail slot="start">
                                                {/* {item.icon} */}
                                                <img src={parent.icon} />
                                            </IonThumbnail>
                                  </IonItem>
                              ))
                            : ""}
                    </IonRadioGroup>
                   
                </IonContent>
            </IonModal>
        </>
    );
};

export default BrandModal;
