import { 
    LOAD_TIPS_BANNERS_REQUEST, LOAD_TIPS_BANNERS_SUCCESS, LOAD_TIPS_BANNERS_FAIL,
    LOAD_HOME_SCREEN_REQUEST, LOAD_HOME_SCREEN_SUCCESS, LOAD_HOME_SCREEN_FAIL,
    LOAD_HOME_SCREEN_WITH_DAYS_REQUEST, LOAD_HOME_SCREEN_WITH_DAYS_SUCCESS, LOAD_HOME_SCREEN_WITH_DAYS_FAIL,
    LOAD_ACADEMY_CONTENT_REQUEST, LOAD_ACADEMY_CONTENT_SUCCESS, LOAD_ACADEMY_CONTENT_FAIL,
    LOAD_CUSTOMERS_REQUEST, LOAD_CUSTOMERS_SUCCESS, LOAD_CUSTOMERS_FAIL,
    GET_COUPONS_REQUEST, GET_COUPONS_SUCCESS, GET_COUPONS_FAIL,
    CREATE_COUPON_REQUEST, CREATE_COUPON_SUCCESS, CREATE_COUPON_FAIL,
    DELETE_COUPON_REQUEST, DELETE_COUPON_SUCCESS, DELETE_COUPON_FAIL,
    GET_BANNERS_REQUEST, GET_BANNERS_SUCCESS, GET_BANNERS_FAIL,
    CREATE_BANNER_REQUEST, CREATE_BANNER_SUCCESS, CREATE_BANNER_FAIL,
    DELETE_BANNER_REQUEST, DELETE_BANNER_SUCCESS, DELETE_BANNER_FAIL,
    GET_SETTINGS_BASIC_INFO_REQUEST, GET_SETTINGS_BASIC_INFO_SUCCESS, GET_SETTINGS_BASIC_INFO_FAIL,
    SET_SETTINGS_BASIC_INFO_REQUEST, SET_SETTINGS_BASIC_INFO_SUCCESS, SET_SETTINGS_BASIC_INFO_FAIL,
    GET_SETTINGS_LOCALIZATION_REQUEST, GET_SETTINGS_LOCALIZATION_SUCCESS, GET_SETTINGS_LOCALIZATION_FAIL,
    SET_SETTINGS_LOCALIZATION_REQUEST, SET_SETTINGS_LOCALIZATION_SUCCESS, SET_SETTINGS_LOCALIZATION_FAIL,
    GET_SETTINGS_SERVICE_DETAILS_REQUEST, GET_SETTINGS_SERVICE_DETAILS_SUCCESS, GET_SETTINGS_SERVICE_DETAILS_FAIL,
    SET_SETTINGS_SERVICE_DETAILS_REQUEST, SET_SETTINGS_SERVICE_DETAILS_SUCCESS, SET_SETTINGS_SERVICE_DETAILS_FAIL,
    GET_SETTINGS_QUICK_ORDERS_REQUEST, GET_SETTINGS_QUICK_ORDERS_SUCCESS, GET_SETTINGS_QUICK_ORDERS_FAIL,
    SET_SETTINGS_QUICK_ORDERS_REQUEST, SET_SETTINGS_QUICK_ORDERS_SUCCESS, SET_SETTINGS_QUICK_ORDERS_FAIL,
    GET_SETTINGS_PAYMENTS_REQUEST, GET_SETTINGS_PAYMENTS_SUCCESS, GET_SETTINGS_PAYMENTS_FAIL,
    SET_SETTINGS_PAYMENTS_REQUEST, SET_SETTINGS_PAYMENTS_SUCCESS, SET_SETTINGS_PAYMENTS_FAIL,
    GET_SETTINGS_RAZORPAY_REQUEST, GET_SETTINGS_RAZORPAY_SUCCESS, GET_SETTINGS_RAZORPAY_FAIL,
    SET_SETTINGS_RAZORPAY_REQUEST, SET_SETTINGS_RAZORPAY_SUCCESS, SET_SETTINGS_RAZORPAY_FAIL,
    GET_TAXES_REQUEST, GET_TAXES_SUCCESS, GET_TAXES_FAIL,
    CREATE_TAX_REQUEST, CREATE_TAX_SUCCESS, CREATE_TAX_FAIL,
    DELETE_TAX_REQUEST, DELETE_TAX_SUCCESS, DELETE_TAX_FAIL,
    UPLOAD_IMAGE_REQUEST, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAIL,
    GET_ORDERS_BY_STATUS_REQUEST, GET_ORDERS_BY_STATUS_SUCCESS, GET_ORDERS_BY_STATUS_FAIL,
    GET_ORDER_BY_ID_REQUEST, GET_ORDER_BY_ID_SUCCESS, GET_ORDER_BY_ID_FAIL,
    GET_INVOICE_REQUEST, GET_INVOICE_SUCCESS, GET_INVOICE_FAIL,
    ORDER_STATUS_CHANGE_REQUEST, ORDER_STATUS_CHANGE_SUCCESS, ORDER_STATUS_CHANGE_FAIL,
    CHANGE_ORDER_STAFF_REQUEST, CHANGE_ORDER_STAFF_SUCCESS, CHANGE_ORDER_STAFF_FAIL,
    GET_ADJUSTMENTS_REQUEST, GET_ADJUSTMENTS_SUCCESS, GET_ADJUSTMENTS_FAIL,
    SET_ADJUSTMENTS_REQUEST, SET_ADJUSTMENTS_SUCCESS, SET_ADJUSTMENTS_FAIL,
    EDIT_ADJUSTMENTS_REQUEST, EDIT_ADJUSTMENTS_SUCCESS, EDIT_ADJUSTMENTS_FAIL,
    DELETE_ADJUSTMENTS_REQUEST, DELETE_ADJUSTMENTS_SUCCESS, DELETE_ADJUSTMENTS_FAIL,
    GET_PRINTABLES_REQUEST, GET_PRINTABLES_SUCCESS, GET_PRINTABLES_FAIL,
    GET_ANNOUNCEMENTS_REQUEST, GET_ANNOUNCEMENTS_SUCCESS, GET_ANNOUNCEMENTS_FAIL,
    CREATE_ANNOUNCEMENT_REQUEST, CREATE_ANNOUNCEMENT_SUCCESS, CREATE_ANNOUNCEMENT_FAIL,
    LOAD_PRODUCTS_BY_SUBCATEGORY_REQUEST, LOAD_PRODUCTS_BY_SUBCATEGORY_SUCCESS, LOAD_PRODUCTS_BY_SUBCATEGORY_FAIL,
    LOAD_PROFILE_COMPLETION_FAIL,
    LOAD_PROFILE_COMPLETION_REQUEST,
    LOAD_PROFILE_COMPLETION_SUCCESS,
    GET_THEMES_FAIL, GET_THEMES_SUCCESS, GET_THEMES_REQUEST,
    SET_THEME_REQUEST, SET_THEME_SUCCESS, SET_THEME_FAIL,
    GET_BRANDING_COLORS_FAIL, GET_BRANDING_COLORS_SUCCESS, GET_BRANDING_COLORS_REQUEST,
    SET_BRANDING_COLOR_REQUEST, SET_BRANDING_COLOR_SUCCESS, SET_BRANDING_COLOR_FAIL,
    CLEAR_ERRORS,
    GET_EMAILS_STATS_REQUEST,
    GET_EMAILS_STATS_SUCCESS,
    GET_EMAILS_STATS_FAIL,
    CREATE_EMAILS_REQUEST,
    CREATE_EMAILS_SUCCESS,
    CREATE_EMAILS_FAIL, 
    GET_SALES_REQUEST, GET_SALES_SUCCESS, GET_SALES_FAIL, 
    GET_VISITORS_REQUEST, GET_VISITORS_SUCCESS, GET_VISITORS_FAIL,
    GET_CART_REQUEST, GET_CART_SUCCESS, GET_CART_FAIL,
    SET_PUSH_NOTIFICATION_REQUEST, SET_PUSH_NOTIFICATION_SUCCESS, SET_PUSH_NOTIFICATION_FAIL,
    DELETE_PUSH_NOTIFICATION_REQUEST, DELETE_PUSH_NOTIFICATION_SUCCESS, DELETE_PUSH_NOTIFICATION_FAIL,GET_COMMUNICATIONS_SUCCESS,GET_COMMUNICATIONS_FAIL,GET_COMMUNICATIONS_REQUEST, POST_COMMUNICATIONS_REQUEST, POST_COMMUNICATIONS_SUCCESS, POST_COMMUNICATIONS_FAIL,POST_TAGLINE_REQUEST,POST_TAGLINE_SUCCESS,POST_TAGLINE_FAIL, GET_TAGLINE_FAIL,GET_TAGLINE_SUCCESS,GET_TAGLINE_REQUEST,
    GET_SETTINGS_CASHFREE_REQUEST, GET_SETTINGS_CASHFREE_SUCCESS, GET_SETTINGS_CASHFREE_FAIL,
    SET_SETTINGS_CASHFREE_REQUEST, SET_SETTINGS_CASHFREE_SUCCESS, SET_SETTINGS_CASHFREE_FAIL,
    GET_SETTINGS_PHONEPE_REQUEST, GET_SETTINGS_PHONEPE_SUCCESS, GET_SETTINGS_PHONEPE_FAIL,
    SET_SETTINGS_PHONEPE_REQUEST, SET_SETTINGS_PHONEPE_SUCCESS, SET_SETTINGS_PHONEPE_FAIL, FETCH_SUGGESTIONS_REQUEST, FETCH_SUGGESTIONS_SUCCESS, FETCH_SUGGESTIONS_FAILURE, POST_SOCIAL_PROFILE_REQUEST, POST_SOCIAL_PROFILE_SUCCESS, POST_SOCIAL_PROFILE_FAILURE, GET_SOCIAL_PROFILE_REQUEST, GET_SOCIAL_PROFILE_SUCCESS, GET_SOCIAL_PROFILE_FAILURE, POST_DELIVERY_MESSAGE_SUCCESS, POST_DELIVERY_MESSAGE_REQUEST, POST_DELIVERY_MESSAGE_FAILURE, POST_DELIVERY_ORDER_REQUEST, POST_DELIVERY_ORDER_SUCCESS, POST_DELIVERY_ORDER_FAIL, GET_DELIVERY_ORDER_REQUEST, GET_DELIVERY_ORDER_SUCCESS, GET_DELIVERY_ORDER_FAIL, DELETE_DELIVERY_ORDER_SUCCESS, DELETE_DELIVERY_ORDER_FAIL, DELETE_DELIVERY_ORDER_REQUEST, UPDATE_DELIVERY_ORDER_REQUEST, UPDATE_DELIVERY_ORDER_SUCCESS, UPDATE_DELIVERY_ORDER_FAIL,
} from '../constants/dashboardConstants';

const API_ROOT = 'https://api2.intelikart.in/';

//Load tip banners
export const getTipBanners = (accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : LOAD_TIPS_BANNERS_REQUEST})
        const response = await fetch(`${ API_ROOT }stores/tips-banners`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${accessToken}`
            }
        });
        const data = await response.json();
        dispatch({
            type: LOAD_TIPS_BANNERS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: LOAD_TIPS_BANNERS_FAIL,
                payload: error
            })
    }
}

//Load Home Screen Data Anytime
export const getHomeScreenData = (accessToken: string, ikDomain: number | string) => async (dispatch: any) => {
    try{
        dispatch({type : LOAD_HOME_SCREEN_REQUEST})
        const response = await fetch(`${ API_ROOT }seller-home-screen/action-data`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: LOAD_HOME_SCREEN_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: LOAD_HOME_SCREEN_FAIL,
                payload: error
            })
    }
}

export const getProfileCompletionData = (accessToken: string, ikDomain: number | string) => async (dispatch: any) => {
    try{
        dispatch({type : LOAD_PROFILE_COMPLETION_REQUEST})
        const response = await fetch(`${ API_ROOT }store-setup-steps`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain':`${ikDomain}`
            }
        });
        const data = await response.json(); 
        dispatch({
            type: LOAD_PROFILE_COMPLETION_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: LOAD_PROFILE_COMPLETION_FAIL,
                payload: error
            })
        }
    }
  
//Load Home Screen Data with Days
export const getHomeScreenDataWithDays = (accessToken: string, ikDomain: number | string, daysFromNow: number) => async (dispatch: any) => {
    try{
        dispatch({type : LOAD_HOME_SCREEN_WITH_DAYS_REQUEST})
        const response = await fetch(`${ API_ROOT }analytics/seller-home-screen/${daysFromNow}`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: LOAD_HOME_SCREEN_WITH_DAYS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: LOAD_HOME_SCREEN_WITH_DAYS_FAIL,
                payload: error
            })
    }
}

//Load Academy Content
export const getAcademyContent = (accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : LOAD_ACADEMY_CONTENT_REQUEST})
        const response = await fetch(`${ API_ROOT }academy`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${accessToken}`
            }
        });
        const data = await response.json();
        dispatch({
            type: LOAD_ACADEMY_CONTENT_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: LOAD_ACADEMY_CONTENT_FAIL,
                payload: error
            })
    }
}

//Load Customer Lists
export const getCustomers = (accessToken: string, ikDomain: string, query: string | null | undefined) => async (dispatch: any) => {
    try{
        dispatch({type : LOAD_CUSTOMERS_REQUEST})
        let response;
        if(query === null || query === undefined){
            response = await fetch(`${ API_ROOT }customers`, {
                method: 'GET',
                headers: {
                    'authorization': `Bearer ${accessToken}`,
                    'x-ik-domain': `${ikDomain}`,
                }
            });
        }else{
            response = await fetch(`${ API_ROOT }customers?query=${query}`, {
                method: 'GET',
                headers: {
                    'authorization': `Bearer ${accessToken}`,
                    'x-ik-domain': `${ikDomain}`,
                }
            });
        }
        const data = await response.json();
        dispatch({
            type: LOAD_CUSTOMERS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: LOAD_CUSTOMERS_FAIL,
                payload: error
            })
    }
}

//Load Coupons
export const getCoupons = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_COUPONS_REQUEST})
        const response = await fetch(`${ API_ROOT }stores/coupons?includeall=true`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
                
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_COUPONS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_COUPONS_FAIL,
                payload: error
            })
    }
}

//Create Coupon
export const createCoupon = (accessToken: string, ikDomain: string, couponData: any) => async (dispatch: any) => {
    try{
        dispatch({type : CREATE_COUPON_REQUEST})
        const response = await fetch(`${ API_ROOT }stores/coupons`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
                
            },
            body: JSON.stringify(couponData)
        });
        const data = await response.json();
        dispatch({
            type: CREATE_COUPON_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: CREATE_COUPON_FAIL,
                payload: error
            })
    }
}

//Delete Coupon
export const deleteCoupon = (accessToken: string, ikDomain: string, couponData: any) => async (dispatch: any) => {
    try{
        dispatch({type : DELETE_COUPON_REQUEST})
        const response = await fetch(`${ API_ROOT }stores/coupons`, {
            method: 'DELETE',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
            body: JSON.stringify(couponData)
        });
        const data = await response.json();
        dispatch({
            type: DELETE_COUPON_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: DELETE_COUPON_FAIL,
                payload: error
            })
    }
}

//Load Banner
export const getBanners = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_BANNERS_REQUEST})
        const response = await fetch(`${ API_ROOT }stores/banners`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain':`${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_BANNERS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_BANNERS_FAIL,
                payload: error
            })
    }
}

//Create Banner
export const createBanner = (accessToken: string, ikDomain: string, bannerData: any) => async (dispatch: any) => {
    try{
        dispatch({type : CREATE_BANNER_REQUEST})
        const response = await fetch(`${ API_ROOT }stores/banners`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain':`${ikDomain}`
            },
            body: JSON.stringify(bannerData)
        });
        const data = await response.json();
        dispatch({
            type: CREATE_BANNER_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: CREATE_BANNER_FAIL,
                payload: error
            })
    }
}

//Delete Banner
export const deleteBanner = (accessToken: string, ikDomain: string, bannerId: string) => async (dispatch: any) => {
    try{
        dispatch({type : DELETE_BANNER_REQUEST})
        const response = await fetch(`${ API_ROOT }stores/banners/${bannerId}`, {
            method: 'DELETE',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain':`${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: DELETE_BANNER_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: DELETE_BANNER_FAIL,
                payload: error
            })
    }
}

//Get Basic info
export const getBasicInfo = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_SETTINGS_BASIC_INFO_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/basic-about`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_SETTINGS_BASIC_INFO_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_SETTINGS_BASIC_INFO_FAIL,
                payload: error
            })
    }
}

//Post Basic Information
export const setBasicInfo = (accessToken: string, ikDomain: string, basicInfoData: any) => async (dispatch: any) => {
    try{
        dispatch({type : SET_SETTINGS_BASIC_INFO_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/basic-about`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
            body: JSON.stringify(basicInfoData)
        });
        const data = await response.json();
        dispatch({
            type: SET_SETTINGS_BASIC_INFO_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: SET_SETTINGS_BASIC_INFO_FAIL,
                payload: error
            })
    }
}

//Get Service Details
export const getServiceDetails = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_SETTINGS_SERVICE_DETAILS_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/service-details`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_SETTINGS_SERVICE_DETAILS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_SETTINGS_SERVICE_DETAILS_FAIL,
                payload: error
            })
    }
}

//Post Service Details
export const setServiceDetails = (accessToken: string, ikDomain: string, serviceDetailsData: any) => async (dispatch: any) => {
    try{
        dispatch({type : SET_SETTINGS_SERVICE_DETAILS_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/service-details`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
            body: JSON.stringify(serviceDetailsData)
        });
        const data = await response.json();
        dispatch({
            type: SET_SETTINGS_SERVICE_DETAILS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: SET_SETTINGS_SERVICE_DETAILS_FAIL,
                payload: error
            })
    }
}

//Get Quick Orders
export const getQuickOrder = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_SETTINGS_QUICK_ORDERS_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/quick-orders`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_SETTINGS_QUICK_ORDERS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_SETTINGS_QUICK_ORDERS_FAIL,
                payload: error
            })
    }
}

//Post Quick Order
export const setQuickOrder = (accessToken: string, ikDomain: string, serviceDetailsData: any) => async (dispatch: any) => {
    try{
        dispatch({type : SET_SETTINGS_QUICK_ORDERS_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/quick-orders`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
            body: JSON.stringify(serviceDetailsData)
        });
        const data = await response.json();
        dispatch({
            type: SET_SETTINGS_QUICK_ORDERS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: SET_SETTINGS_QUICK_ORDERS_FAIL,
                payload: error
            })
    }
}

//Get Payments
export const getPayment = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_SETTINGS_PAYMENTS_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/payments`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_SETTINGS_PAYMENTS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_SETTINGS_PAYMENTS_FAIL,
                payload: error
            })
    }
}

//Post Payments
export const setPayment = (accessToken: string, ikDomain: string, serviceDetailsData: any) => async (dispatch: any) => {
    try{
        dispatch({type : SET_SETTINGS_PAYMENTS_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/payments`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
            body: JSON.stringify(serviceDetailsData)
        });
        const data = await response.json();
        dispatch({
            type: SET_SETTINGS_PAYMENTS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: SET_SETTINGS_PAYMENTS_FAIL,
                payload: error
            })
    }
}

//Get Razor pay details
export const getRazorPayDetails = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_SETTINGS_RAZORPAY_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/payment-gateway`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${accessToken}`,
                "x-ik-domain" : `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_SETTINGS_RAZORPAY_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_SETTINGS_RAZORPAY_FAIL,
                payload: error
            })
    }
}

//Set Razor pay details
export const setRazorPay = (accessToken: string, ikDomain: string, razorPayData: any) => async (dispatch: any) => {
    try{
        dispatch({type : SET_SETTINGS_RAZORPAY_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/payment-gateway`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${accessToken}`,
                "x-ik-domain" : `${ikDomain}`
            },
            body: JSON.stringify(razorPayData)
        });
        const data = await response.json();
        dispatch({
            type: SET_SETTINGS_RAZORPAY_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: SET_SETTINGS_RAZORPAY_FAIL,
                payload: error
            })
    }
}

//Get Localization
export const getLocalization = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_SETTINGS_LOCALIZATION_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/basic-localisation`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_SETTINGS_LOCALIZATION_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_SETTINGS_LOCALIZATION_FAIL,
                payload: error
            })
    }
}

export const setLocalization = (accessToken: string, ikDomain: string, localizationData: any) => async (dispatch: any) => {
    try{
        dispatch({type : SET_SETTINGS_LOCALIZATION_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/basic-localisation`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
            body: JSON.stringify(localizationData)
        });
        const data = await response.json();
        dispatch({
            type: SET_SETTINGS_LOCALIZATION_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: SET_SETTINGS_LOCALIZATION_FAIL,
                payload: error
            })
    }
}


//Load Taxes
export const getTaxes = (accessToken: string, ikDomain: string, lat: string | number, long: string | number) => async (dispatch: any) => {
    try{
        dispatch({type : GET_TAXES_REQUEST})
        const response = await fetch(`${ API_ROOT }taxes-charges?lat=${lat}&long=${long}`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_TAXES_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_TAXES_FAIL,
                payload: error
            })
    }
}

//Create Tax
export const createTax = (accessToken: string, ikDomain: string, taxData: any) => async (dispatch: any) => {
    try{
        dispatch({type : CREATE_TAX_REQUEST})
        const response = await fetch(`${ API_ROOT }taxes-charges`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
            body: JSON.stringify(taxData)
        });
        const data = await response.json();
        dispatch({
            type: CREATE_TAX_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: CREATE_TAX_FAIL,
                payload: error
            })
    }
}

//Delete Tax
export const deleteTax = (accessToken: string, ikDomain: string, taxId: string | number) => async (dispatch: any) => {
    try{
        dispatch({type : DELETE_TAX_REQUEST})
        const response = await fetch(`${ API_ROOT }taxes-charges/${taxId}`, {
            method: 'DELETE',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: DELETE_TAX_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: DELETE_TAX_FAIL,
                payload: error
            })
    }
}

//Upload Image
export const uploadImage = (imageData: FormData, type: string) => async (dispatch: any) => {
    try{
        dispatch({type : UPLOAD_IMAGE_REQUEST})
        const response = await fetch(`${ API_ROOT }upload/${type}`, {
            method: 'POST',
            headers: {
                "content-type": "multipart/form-data"
            },
            body: imageData
        });
        const data = await response.json();
        dispatch({
            type: UPLOAD_IMAGE_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: UPLOAD_IMAGE_FAIL,
                payload: error
            })
    }
}

//clear Errors
export const clearErrors = () => async (dispatch:any) =>{
    dispatch({type: CLEAR_ERRORS})
}

//Get marketing printables
export const getPrintables = (accessToken: string, ikDomain: string | number) => async (dispatch: any) => {
    try{
        dispatch({type : GET_PRINTABLES_REQUEST})
        const response = await fetch(`${ API_ROOT }store-marketing/printables`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_PRINTABLES_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_PRINTABLES_FAIL,
                payload: error
            })
    }
}

export const getEmailStats = (accessToken: string, ikDomain: string | number) => async (dispatch: any) => {
    try{
        dispatch({type : GET_EMAILS_STATS_REQUEST})
        const response = await fetch(`${ API_ROOT }store/email-marketing-stats`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_EMAILS_STATS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_EMAILS_STATS_FAIL,
                payload: error
            })
    }
}

export const createEmails = (accessToken: string,ikDomain: string, emailData: any) => async (dispatch: any) => {
    try{
        dispatch({type : CREATE_EMAILS_REQUEST})
        const response = await fetch(`${ API_ROOT }store/email-marketing`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
            },
            body: JSON.stringify(emailData)
        });
        const data = await response.json();
        dispatch({
            type: CREATE_EMAILS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: CREATE_EMAILS_FAIL,
                payload: error
            })
    }
}



//Get Announcements for marketing
export const getAnnouncements = (accessToken: string, ikDomain: string | number) => async (dispatch: any) => {
    try{
        dispatch({type : GET_ANNOUNCEMENTS_REQUEST})
        const response = await fetch(`${ API_ROOT }stores/announcements-suggestions`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_ANNOUNCEMENTS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_ANNOUNCEMENTS_FAIL,
                payload: error
            })
    }
}

//Create Announcements for marketing
export const createAnnouncements = (accessToken: string,ikDomain: string, announcementData: any) => async (dispatch: any) => {
    try{
        dispatch({type : CREATE_ANNOUNCEMENT_REQUEST})
        const response = await fetch(`${ API_ROOT }stores/announcements`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
            },
            body: JSON.stringify(announcementData)
        });
        const data = await response.json();
        dispatch({
            type: CREATE_ANNOUNCEMENT_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: CREATE_ANNOUNCEMENT_FAIL,
                payload: error
            })
    }
}

//Get Orders by status
export const getOrdersByStatus = (accessToken: string,ikDomain: string | number, orderStatus: any) => async (dispatch: any) => {
    try{
        dispatch({type : GET_ORDERS_BY_STATUS_REQUEST})
        const response = await fetch(`${ API_ROOT }orders/stores/${orderStatus}?page=1&per_page=10`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain':`${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_ORDERS_BY_STATUS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_ORDERS_BY_STATUS_FAIL,
                payload: error
            })
    }
}

//Get Orders by id
export const getOrdersById = (accessToken: string, orderId: any) => async (dispatch: any) => {
    try{
        dispatch({type : GET_ORDER_BY_ID_REQUEST})
        const response = await fetch(`${ API_ROOT }orders/${orderId}`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_ORDER_BY_ID_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_ORDER_BY_ID_FAIL,
                payload: error
            })
    }
}

//Get order invoice
export const getInvoice = (accessToken: string, orderId: any) => async (dispatch: any) => {
    try{
        dispatch({type : GET_INVOICE_REQUEST})
        const response = await fetch(`${ API_ROOT }orders-invoices/${orderId}`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_INVOICE_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_INVOICE_FAIL,
                payload: error
            })
    }
}

//Change order Status
export const changeOrderStatusFunction = (accessToken: string, orderId: string | number, newStatus: string,message:string) => async (dispatch: any) => {
    try{
        dispatch({type : ORDER_STATUS_CHANGE_REQUEST})
        const response = await fetch(`${ API_ROOT }orders/change/${orderId}/${newStatus}?message=${message}`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`
            }
        });
        console.log("action called");
        const data = await response.json();
        dispatch({
            type: ORDER_STATUS_CHANGE_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: ORDER_STATUS_CHANGE_FAIL,
                payload: error
            })
    }
}



//Change order Staff
export const changeOrderStaffFunction = (accessToken: string, orderId: string | number, driverId: string | number, ikDomain: string | number) => async (dispatch: any) => {
    try{
        dispatch({type : CHANGE_ORDER_STAFF_REQUEST})
        const response = await fetch(`${ API_ROOT }orders/assign/${orderId}/${driverId}`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain':`${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: CHANGE_ORDER_STAFF_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: CHANGE_ORDER_STAFF_FAIL,
                payload: error
            })
    }
}

//Get Adjustments
export const getAdjustments = (accessToken: string, orderId: string | number) => async (dispatch: any) => {
    try{
        dispatch({type : GET_ADJUSTMENTS_REQUEST})
        const response = await fetch(`${ API_ROOT }orders/${orderId}/adjustments`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_ADJUSTMENTS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_ADJUSTMENTS_FAIL,
                payload: error
            })
    }
}

//Create Adjustments
export const createAdjustments = (accessToken: string, orderId: string | number, adjustmentData: any, ikDomain: string | number) => async (dispatch: any) => {
    try{
        dispatch({type : SET_ADJUSTMENTS_REQUEST})
        const response = await fetch(`${ API_ROOT }orders/${orderId}/adjustments`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain':`${ikDomain}`
            },
            body: JSON.stringify(adjustmentData)
        });
        const data = await response.json();
        dispatch({
            type: SET_ADJUSTMENTS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: SET_ADJUSTMENTS_FAIL,
                payload: error
            })
    }
}

//Edit Adjustments
export const editAdjustments = (accessToken: string, orderId: string | number, adjustmentData: any, ikDomain: string | number) => async (dispatch: any) => {
    try{
        dispatch({type : EDIT_ADJUSTMENTS_REQUEST})
        const response = await fetch(`${ API_ROOT }orders/${orderId}/adjustments`, {
            method: 'PUT',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain':`${ikDomain}`
            },
            body: JSON.stringify(adjustmentData)
        });
        const data = await response.json();
        dispatch({
            type: EDIT_ADJUSTMENTS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: EDIT_ADJUSTMENTS_FAIL,
                payload: error
            })
    }
}

//Delete Adjustments
export const deleteAdjustments = (accessToken: string, orderId: string | number, adjustmentId: string | number, ikDomain: string | number) => async (dispatch: any) => {
    try{
        dispatch({type : DELETE_ADJUSTMENTS_REQUEST})
        const response = await fetch(`${ API_ROOT }orders/${orderId}/adjustments/${adjustmentId}`, {
            method: 'DELETE',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain':`${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: DELETE_ADJUSTMENTS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: DELETE_ADJUSTMENTS_FAIL,
                payload: error
            })
    }
}

//Get products by sub categories
export const getProductsList = (categoryId: string | number, ikDomain: string | number) => async (dispatch: any) => {
    try{
        dispatch({type :LOAD_PRODUCTS_BY_SUBCATEGORY_REQUEST})
        const response = await fetch(`${ API_ROOT }search/products?categoryId=${categoryId}&includeall=true`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'x-ik-domain':`${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: LOAD_PRODUCTS_BY_SUBCATEGORY_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: LOAD_PRODUCTS_BY_SUBCATEGORY_FAIL,
                payload: error
            })
    }
}

//Load themes
export const getThemes = (ikDomain: string | number, accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_THEMES_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/themes`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
        });
        const data = await response.json();
        dispatch({
            type: GET_THEMES_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_THEMES_FAIL,
                payload: error
            })
    }
}

//Post Theme
export const setTheme = (accessToken: string, ikDomain: string, themeData: any) => async (dispatch: any) => {
    try{
        dispatch({type : SET_THEME_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/themes`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
            body: JSON.stringify(themeData)
        });
        const data = await response.json();
        dispatch({
            type: SET_THEME_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: SET_THEME_FAIL,
                payload: error
            })
    }
}

//Load Branding colors values
export const getBrandingColors = (ikDomain: string | number, accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_BRANDING_COLORS_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/branding`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
        });
        const data = await response.json();
        dispatch({
            type: GET_BRANDING_COLORS_SUCCESS,
            payload: data.data
        })
    }catch(error) {
            dispatch({
                type: GET_BRANDING_COLORS_FAIL,
                payload: error
            })
    }
}

//Set Branding Colors
export const setBrandingColor = (accessToken: string, ikDomain: string, brandingData: any) => async (dispatch: any) => {
    try{
        dispatch({type : SET_BRANDING_COLOR_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/branding`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
            body: JSON.stringify(brandingData)
        });
        const data = await response.json();
        dispatch({
            type: SET_BRANDING_COLOR_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: SET_BRANDING_COLOR_FAIL,
                payload: error
            })
    }
}

export const getSales = (accessToken: string, ikDomain: string, startDate: string, endDate: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_SALES_REQUEST})
        const response = await fetch(`${ API_ROOT }reports/sales?startDate=${startDate}&endDate=${endDate}`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_SALES_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_SALES_FAIL,
                payload: error
            })
    }
}


export const getVisitors = (accessToken: string, ikDomain: string, startDate: string, endDate: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_VISITORS_REQUEST})
        const response = await fetch(`${ API_ROOT }reports/visitors?startDate=${startDate}&endDate=${endDate}`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_VISITORS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_VISITORS_FAIL,
                payload: error
            })
    }
}

//Load Cart Data
export const getCart = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_CART_REQUEST})
        const response = await fetch(`${ API_ROOT }cart/customers`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_CART_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_CART_FAIL,
                payload: error
            })
    }
}

//Set Push Notifications
export const setPushNotification = (accessToken: string, ikClient: string, ikDevice: string, registrationId: any) => async (dispatch: any) => {
    try {
        dispatch({ type: SET_PUSH_NOTIFICATION_REQUEST })
        const response = await fetch(`${ API_ROOT }pushs`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-client': `${ikClient}`,
                'x-ik-device': `${ikDevice}`
            },
            body: JSON.stringify(registrationId)
        });
        const data = await response.json();
        dispatch({
            type: SET_PUSH_NOTIFICATION_SUCCESS,
            payload: data
        })
    }catch(error) {
        dispatch({
            type: SET_PUSH_NOTIFICATION_FAIL,
            payload: error
        })
    }
}

//Delete Push Notifications
export const deletePushNotification = (accessToken: string, registrationId: any) => async (dispatch: any) => {
    try{
        dispatch({type : DELETE_PUSH_NOTIFICATION_REQUEST})
const response = await fetch(`${ API_ROOT }pushs`, {
                    method: 'DELETE',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`
                            },
            body: JSON.stringify(registrationId)
        });
        const data = await response.json();
        dispatch({
            type: DELETE_PUSH_NOTIFICATION_SUCCESS,
            payload: data
        })
    }catch(error) {
        dispatch({
            type: DELETE_PUSH_NOTIFICATION_FAIL,
            payload: error
        })
    }
}
// for communications
export const getCommunications = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_COMMUNICATIONS_REQUEST})
        const response = await fetch(`${ API_ROOT }communications`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_COMMUNICATIONS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_COMMUNICATIONS_FAIL,
                payload: error
            })
    }
}
export const postCommunications = (accessToken: string, ikDomain: string, CommunicationInfoData: any) => async (dispatch: any) => {
    try{
        dispatch({type : POST_COMMUNICATIONS_REQUEST})
        const response = await fetch(`${ API_ROOT }communications`,{
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
            body: JSON.stringify(CommunicationInfoData)
        });
        const data = await response.json();
        dispatch({
            type: POST_COMMUNICATIONS_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: POST_COMMUNICATIONS_FAIL,
                payload: error
            })
    }
}

export const postTagLine = (accessToken: string, ikDomain: string, TagLineInfoData: any) => async (dispatch: any) => {
    try{
        dispatch({type : POST_TAGLINE_REQUEST})
        const response = await fetch(`${ API_ROOT }footer`,{
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
            body: JSON.stringify(TagLineInfoData)
        });
        const data = await response.json();
        dispatch({
            type: POST_TAGLINE_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: POST_TAGLINE_FAIL,
                payload: error
            })
    }
}
export const getTagLine = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_TAGLINE_REQUEST})
        const response = await fetch(`${ API_ROOT }footer`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`,
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_TAGLINE_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_TAGLINE_FAIL,
                payload: error
            })
    }
}

// Get Cashfree details
export const getCashfreeDetails = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_SETTINGS_CASHFREE_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/payment-gateway/cashfree`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${accessToken}`,
                "x-ik-domain" : `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_SETTINGS_CASHFREE_SUCCESS,
            payload: data
        })
    }catch(error) {
        dispatch({
            type: GET_SETTINGS_CASHFREE_FAIL,
            payload: error
        })
    }
}

// Set Cashfree details
export const setCashfreeDetails = (accessToken: string, ikDomain: string, cashfreeData: any) => async (dispatch: any) => {
    try{
        dispatch({type : SET_SETTINGS_CASHFREE_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/payment-gateway/cashfree`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${accessToken}`,
                "x-ik-domain" : `${ikDomain}`
            },
            body: JSON.stringify(cashfreeData)
        });
        const data = await response.json();
        dispatch({
            type: SET_SETTINGS_CASHFREE_SUCCESS,
            payload: data
        })
    }catch(error) {
        dispatch({
            type: SET_SETTINGS_CASHFREE_FAIL,
            payload: error
        })
    }
}

// Get Phonepe details
export const getPhonepeDetails = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_SETTINGS_PHONEPE_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/payment-gateway/phonepe`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${accessToken}`,
                "x-ik-domain" : `${ikDomain}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_SETTINGS_PHONEPE_SUCCESS,
            payload: data
        })
    }catch(error) {
        dispatch({
            type: GET_SETTINGS_PHONEPE_FAIL,
            payload: error
        })
    }
}

// Set Phonepe details
export const setPhonepeDetails = (accessToken: string, ikDomain: string, phonepeData: any) => async (dispatch: any) => {
    try{
        dispatch({type : SET_SETTINGS_PHONEPE_REQUEST})
        const response = await fetch(`${ API_ROOT }store-settings/payment-gateway/phonepe`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${accessToken}`,
                "x-ik-domain" : `${ikDomain}`
            },
            body: JSON.stringify(phonepeData)
        });
        const data = await response.json();
        dispatch({
            type: SET_SETTINGS_PHONEPE_SUCCESS,
            payload: data
        })
    }catch(error) {
        dispatch({
            type: SET_SETTINGS_PHONEPE_FAIL,
            payload: error
        })
    }
}

// action for dynamic message 

export const fetchSuggestionsRequest = (accessToken: string, ikDomain: string,) => async (dispatch: any) => {
    try{
        dispatch({type : FETCH_SUGGESTIONS_REQUEST})
        const response = await fetch(`${ API_ROOT }orders/recent/messages`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${accessToken}`,
                "x-ik-domain" : `${ikDomain}`
            },
            // body: JSON.stringify(phonepeData)
        });
        const data = await response.json();
        console.log("dataa",data)
        dispatch({
            type: FETCH_SUGGESTIONS_SUCCESS,
            payload: data
        })
    }catch(error) {
        dispatch({
            type: FETCH_SUGGESTIONS_FAILURE,
            payload: error
        })
    }
}
  
export const postSocialProfile = (accessToken: string, ikDomain: string, SocialProfileData: any) => async (dispatch: any) => {
    try{
        dispatch({type : POST_SOCIAL_PROFILE_REQUEST})
        const response = await fetch(`${ API_ROOT }social-links`,{
            method: 'POST',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
            body: JSON.stringify(SocialProfileData)
        });
        const data = await response.json();
        dispatch({
            type: POST_SOCIAL_PROFILE_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: POST_SOCIAL_PROFILE_FAILURE,
                payload: error
            })
    }
}
export const getSocialProfileAction = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_SOCIAL_PROFILE_REQUEST})
        const response = await fetch(`${ API_ROOT }social-links`,{
            method: 'GET',
            headers: {
                "content-type": "application/json",
                'authorization': `Bearer ${accessToken}`,
                'x-ik-domain': `${ikDomain}`
            },
        });
        const data = await response.json();
        console.log("data get",data)
        dispatch({
            type: GET_SOCIAL_PROFILE_SUCCESS,
            payload: data
        })
    }catch(error) {
            dispatch({
                type: GET_SOCIAL_PROFILE_FAILURE,
                payload: error
            })
    }
}
    export const slotTime = (accessToken: string, ikDomain: string, DeliveryMessage : any, order_id) => async (dispatch: any) => {
        try{
            dispatch({type : POST_DELIVERY_MESSAGE_REQUEST})
             const response = await fetch(`${ API_ROOT }orders/delivery-message/${order_id}`,{
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                    'authorization': `Bearer ${accessToken}`,
                    'x-ik-domain': `${ikDomain}`     

                },                              

                body: JSON.stringify(DeliveryMessage)
            });
            const data = await response.json();
            dispatch({
                type: POST_DELIVERY_MESSAGE_SUCCESS,
                payload: data
            })
        }catch(error) {
                dispatch({
                    type: POST_DELIVERY_MESSAGE_FAILURE,
                    payload: error
                })
        }
    }
    export const postDeliveryOrder = (accessToken: string, ikDomain: string, zipcode: any) => async (dispatch: any) => {
        try{
            dispatch({type : POST_DELIVERY_ORDER_REQUEST})
            const response = await fetch(`${ API_ROOT }zipcode`,{
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                    'authorization': `Bearer ${accessToken}`,
                    'x-ik-domain': `${ikDomain}`
                },
                body: JSON.stringify(zipcode)
            });
            const data = await response.json();
            dispatch({
                type: POST_DELIVERY_ORDER_SUCCESS,
                payload: data
            })
        }catch(error) {
                dispatch({
                    type: POST_DELIVERY_ORDER_FAIL,
                    payload: error
                })
        }
    }
    export const getDeliveryOrder = (accessToken: string, ikDomain: string) => async (dispatch: any) => {
        try{
            dispatch({type : GET_DELIVERY_ORDER_REQUEST})
            const response = await fetch(`${ API_ROOT }zipcode`, {
                method: 'GET',
                headers: {
                    'authorization': `Bearer ${accessToken}`,
                    'x-ik-domain': `${ikDomain}`,
                    
                }
            });
            const data = await response.json();
            console.log("response",data);
            dispatch({
                type: GET_DELIVERY_ORDER_SUCCESS,
                payload: data.zipcodes
            })
        }catch(error) {
                dispatch({
                    type: GET_DELIVERY_ORDER_FAIL,
                    payload: error
                })
        }
    }
    export const deleteDeliverOrder = (accessToken: string, ikDomain: string, deliveryData: any) => async (dispatch: any) => {
        try{
            dispatch({type : DELETE_DELIVERY_ORDER_REQUEST})
            const response = await fetch(`${ API_ROOT }zipcode/${deliveryData.id}`, {
                method: 'DELETE',
                headers: {
                    "content-type": "application/json",
                    'authorization': `Bearer ${accessToken}`,
                    'x-ik-domain': `${ikDomain}`
                },
                body: JSON.stringify(deliveryData)
            });
            console.log("del",deliveryData);
            const data = await response.json();
            dispatch({
                type: DELETE_DELIVERY_ORDER_SUCCESS,
                payload: data
            })
        }catch(error) {
                dispatch({
                    type: DELETE_DELIVERY_ORDER_FAIL,
                    payload: error
                })
        }
    }
    export const updateDeliveryOrder = (accessToken: string, ikDomain: string, deliveryData: any) => async (dispatch: any) => {
        try{
            dispatch({type : UPDATE_DELIVERY_ORDER_REQUEST})
            const response = await fetch(`${ API_ROOT }zipcode/${deliveryData.alertData.id}`, {
                method: 'PUT',
                headers: {
                    "content-type": "application/json",
                    'authorization': `Bearer ${accessToken}`,
                    'x-ik-domain': `${ikDomain}`
                },
                body: JSON.stringify({zipcode:deliveryData.updateValue})
            });
            console.log("delu",deliveryData);
            const data = await response.json();
            dispatch({
                type: UPDATE_DELIVERY_ORDER_SUCCESS,
                payload: data
            })
        }catch(error) {
                dispatch({
                    type: UPDATE_DELIVERY_ORDER_FAIL,
                    payload: error
                })
        }
    }