import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { appsSharp, cashSharp, homeSharp, settingsSharp } from "ionicons/icons";
import React, { useState } from "react";
import { Redirect, useHistory } from 'react-router-dom';
import Features from "./components/dashboard/Features";
import Customer from "./dashboard/Customer/Customer";
import Dashboard from "./dashboard/Dashboard";
import HelpAndSupport from "./dashboard/HelpAndSupport/HelpAndSupport";
import Support from "./dashboard/HelpAndSupport/Support";
import IKAcademy from "./dashboard/IKAcademy/IKAcademy";
import ToolPage from "./dashboard/Tools/ToolPage";
import AddonsRoutes from "./dashboard/add-ons/AddonsRoutes";
import AddBanner from "./dashboard/banners/AddBanner";
import Banners from "./dashboard/banners/Banners";
import CatalogueRoutes from "./dashboard/catalogue/CatalogueRoutes";
import Coupons from "./dashboard/coupons/Coupons";
import CreateCouponPage from "./dashboard/coupons/CreateCouponPage";
import Announcements from "./dashboard/marketing/Announcements";
import Emails from "./dashboard/marketing/Emails";
import Printables from "./dashboard/marketing/Printables";
import AddMediaLibrary from "./dashboard/media-library/AddMediaLibrary";
import CreateMenu from "./dashboard/menus/CreateMenu";
import Menu from "./dashboard/menus/Menu";
import NotificationList from "./dashboard/notification/NotificationList";
import OrdersRoutes from "./dashboard/orders/OrdersRoutes";
import Pages from "./dashboard/page/Pages";
import Sales from "./dashboard/reports/Sale";
import Traffic from "./dashboard/reports/Traffic";
import BasicInfo from "./dashboard/settings/BasicInfo";
import Branding from "./dashboard/settings/Branding";
import Communications from "./dashboard/settings/Communications";
import DeliveryOrder from "./dashboard/settings/DeliveryOrder";
import DeliveryOrderAll from "./dashboard/settings/DeliveryOrderAll";
import Localization from "./dashboard/settings/Localization";
import Maintenance from "./dashboard/settings/Maintenance";
import OrderStatusListing from "./dashboard/settings/OrderStatus/OrderStatusListing";
import Payments from "./dashboard/settings/Payments";
import QuickOrders from "./dashboard/settings/QuickOrders";
import ServiceDetails from "./dashboard/settings/ServiceDetails";
import Settings from "./dashboard/settings/Settings";
import SocialProfile from "./dashboard/settings/SocialProfile";
import Themes from "./dashboard/settings/Themes";
import CreateTaxPage from "./dashboard/settings/taxes/CreateTaxes";
import Taxes from "./dashboard/settings/taxes/Taxes";
import AddPickupLocation from "./dashboard/shiprocket/AddPickupLocation";
import ShiprocketOrder from "./dashboard/shiprocket/ShiprocketOrder";
import Staff from "./dashboard/staff/Staff";
import Subscriptions from "./dashboard/subscriptions/Subscriptions";
import ProtectedRoute from "./route/ProtectedRoute";
import { getAccessPermission, getAccessToken } from "./services/auth/userLocalStorage";
import Timeslot from "./dashboard/settings/timeslot/Timeslot";
import CreateManualBill from './dashboard/manualbilling/CreateManualBill'
import ShowManualBills from "./dashboard/manualbilling/ShowManualBills";
import AppMenu from "./dashboard/app_menus/AppMenu";
import CreateAppMenu from "./dashboard/app_menus/CreateAppMenu";
interface MainTabsProps { }

const MainTabs: React.FC<MainTabsProps> = () => {
  const permissions = getAccessPermission();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("");

  return (
    <IonTabs>
      <IonRouterOutlet>
        {getAccessToken() ? (
          permissions?.length === 0 ? (
            <>
              <ProtectedRoute
                path="/userdashboard"
                component={Dashboard}
                exact={true}
              />
              <ProtectedRoute
                path="/notification"
                component={NotificationList}
                exact={true}
              />
              <ProtectedRoute
                path="/features"
                component={Features}
                exact={true}
              />
              <ProtectedRoute
                path="/ikacademy"
                component={IKAcademy}
                exact={true}
              />
              <ProtectedRoute
                path="/subscriptions"
                component={Subscriptions}
                exact={true}
              />
              <ProtectedRoute
                path="/customer"
                component={Customer}
                exact={true}
              />
              <ProtectedRoute path="/coupons" component={Coupons} exact={true} />
              <ProtectedRoute
                path="/createcoupon"
                component={CreateCouponPage}
                exact={true}
              />
              <ProtectedRoute path="/banners" component={Banners} exact={true} />
              <ProtectedRoute
                path="/settings/themes"
                component={Themes}
                exact={true}
              />
              <ProtectedRoute
                path="/settings"
                component={Settings}
                exact={true}
              />
              <ProtectedRoute
                path="/content/menus/web-menu/create"
                component={CreateMenu}
                exact={true}
              />
              <ProtectedRoute
                path="/content/menus/web-menu/update/:id"
                component={CreateMenu}
                exact={true}
              />
              <ProtectedRoute path="/settings/basicinfo" component={BasicInfo} />
              <ProtectedRoute
                path="/settings/localization"
                component={Localization}
              />
              <ProtectedRoute
                path="/settings/servicedetails"
                component={ServiceDetails}
              />
              <ProtectedRoute path="/settings/payments" component={Payments} />
              <ProtectedRoute
                path="/settings/quickorders"
                component={QuickOrders}
              />
              <ProtectedRoute path="/settings/branding" component={Branding} />
              <ProtectedRoute
                path="/settings/dynamic-order-status"
                component={OrderStatusListing}
              />
              <ProtectedRoute path="/settings/taxes" component={Taxes} />
              <ProtectedRoute
                path="/settings/createtax"
                component={CreateTaxPage}
              />
              <ProtectedRoute
                path="/settings/communications"
                component={Communications}
              />
              <ProtectedRoute
                path="/settings/social-profiles"
                component={SocialProfile}
              />
              <ProtectedRoute
                path="/settings/delivery-order"
                component={DeliveryOrder}
              />
              <ProtectedRoute
                path="/settings/show-delivery-order"
                component={DeliveryOrderAll}
              />
              <ProtectedRoute
                path="/settings/store-maintenance"
                component={Maintenance}
              />
              <ProtectedRoute path="/staffs" component={Staff} />
              <ProtectedRoute
                path="/marketing/printables"
                component={Printables}
                exact={true}
              />
              <ProtectedRoute
                path="/marketing/announcements"
                component={Announcements}
                exact={true}
              />
              <ProtectedRoute
                path="/marketing/emails"
                component={Emails}
                exact={true}
              />
              <ProtectedRoute
                path="/help&support"
                component={HelpAndSupport}
                exact={true}
              />
              <ProtectedRoute
                path="/media-library"
                component={AddMediaLibrary}
                exact={true}
              />
              <ProtectedRoute
                path="/manualbilling"
                component={ShowManualBills}
                exact={true}
              />
              <ProtectedRoute
                path="/manualbilling/createmanualbill"
                component={CreateManualBill}
                exact={true}
              />
              <ProtectedRoute
                path="/manualbilling/updatemanualbill/:id"
                component={CreateManualBill}
                exact={true}
              />
              <ProtectedRoute path="/support" component={Support} exact={true} />
              <ProtectedRoute
                path="/addbanner"
                component={AddBanner}
                exact={true}
              />

              <ProtectedRoute
                path="/settings/config-time-slot"
                component={Timeslot}
              />




              <ProtectedRoute path="/content/pages" component={Pages} />
              <ProtectedRoute
                path="/content/menus/web-menu"
                component={Menu}
                exact={true}
              />
              <ProtectedRoute
                path="/content/menus/app-menu"
                component={AppMenu}
                exact={true}
              />
              <ProtectedRoute
                path="/content/menus/app-menu/create"
                component={CreateAppMenu}
                exact={true}
              />
              <ProtectedRoute
                path="/content/menus/app-menu/update/:id"
                component={CreateAppMenu}
                exact={true}
              />

              <ProtectedRoute path="/add-ons" component={AddonsRoutes} />
              <ProtectedRoute path="/orders" component={OrdersRoutes} />
              <ProtectedRoute path="/catalogue" component={CatalogueRoutes} />
              <ProtectedRoute
                path="/reports/traffic"
                component={Traffic}
                exact={true}
              />
              <ProtectedRoute
                path="/reports/sales"
                component={Sales}
                exact={true}
              />
              <ProtectedRoute
                path="/delivery/shiprocket"
                component={ShiprocketOrder}
                exact={true}
              />
              <ProtectedRoute
                path="/shiprocket/addpickuplocation"
                component={AddPickupLocation}
                exact={true}
              />
              <ProtectedRoute path="/tool" component={ToolPage} exact={true} />
            </>
          ) : (
            <>
              <ProtectedRoute path="/orders" component={OrdersRoutes} />
              <ProtectedRoute
                path="/help&support"
                component={HelpAndSupport}
                exact={true}
              />
              <ProtectedRoute path="/support" component={Support} exact={true} />
              <ProtectedRoute
                path="/ikacademy"
                component={IKAcademy}
                exact={true}
              />

              <ProtectedRoute
                path="/notification"
                component={NotificationList}
                exact={true}
              />
            </>
          )
        ) : <Redirect to='/' />}
      </IonRouterOutlet>

      <IonTabBar className="global-tabbar" slot="bottom">
        <IonTabButton
          tab="schedule"
          href="/userdashboard"
          onClick={() => setActiveTab("schedule")}
          selected={activeTab === "schedule"}
        >
          <IonIcon icon={homeSharp} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="speakers"
          href="/orders"
          onClick={() => setActiveTab("speakers")}
          selected={activeTab === "speakers"}
        >
          <IonIcon icon={cashSharp} />
          <IonLabel>Orders</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="map"
          href="/catalogue/products"
          onClick={() => setActiveTab("map")}
          selected={activeTab === "map"}
        >
          <IonIcon icon={appsSharp} />
          <IonLabel>Products</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="about"
          selected={activeTab === "about"}
          onClick={() => {
            history.push("/settings");
            setActiveTab("about");
          }}
        >
          <IonIcon icon={settingsSharp} />
          <IonLabel>Settings</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;
