import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    IonContent,
    IonText,
    IonItem,
    IonIcon,
    IonLoading,
    IonCard,
    IonButton,
    IonAlert,
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonGrid,
    IonRow,
    IonCol,
    isPlatform,
} from '@ionic/react';
import { getTaxes, deleteTax, clearErrors } from '../../../actions/dashboardActions';
import { getAccessToken } from '../../../services/auth/userLocalStorage';
import FeatHeader from '../../../components/dashboard/FeatHeader';

import {
    trash as trashIcon,
    arrowBack as arrowBackIcon,
    informationCircleOutline,
} from 'ionicons/icons';
import { Geolocation } from '@capacitor/geolocation';
import { BrowserView, MobileView } from 'react-device-detect';
import { StoreCurrency } from '../../../utils/currency';
const Taxes = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState<any>();
    const dispatch = useDispatch();
    const currency = StoreCurrency();
    const { taxesLoading, taxes, taxesError } = useSelector((state: any) => state.taxes);
    const { deleteTaxMessageLoading, deleteTaxMessageError } = useSelector((state: any) => state.deleteTaxData);
    const { currentUser } = useSelector((state: any) => state.currentUser);
    const { createTaxMessageLoading, createTaxMessage, createTaxMessageError } = useSelector((state: any) => state.createTaxData);


    useEffect(() => {
        Geolocation.getCurrentPosition()
            .then((val) => {
                if (currentUser && currentUser.data) {
                    dispatch(getTaxes(getAccessToken(), currentUser.data.store.subdomain, val.coords.latitude, val.coords.longitude));
                }
            })
            .catch((e) => {
                alert("Please turn on your location");
            });
            Geolocation.getCurrentPosition()
            .then((val) => {
                if (currentUser && currentUser.data) {
                    dispatch(getTaxes(getAccessToken(), currentUser.data.store.subdomain, val.coords.latitude, val.coords.longitude));
                }
            })
    }, [currentUser]);

    useEffect(() => {
        Geolocation.getCurrentPosition()
            .then((val) => {
                if (currentUser && currentUser.data && deleteTaxMessageLoading === false) {
                    dispatch(getTaxes(getAccessToken(), currentUser.data.store.subdomain, val.coords.latitude, val.coords.longitude));
                }
            });
    }, [deleteTaxMessageLoading]);

    useEffect(() => {
        Geolocation.getCurrentPosition()
            .then((val) => {
                if (currentUser && currentUser.data && createTaxMessageLoading === false) {
                    dispatch(getTaxes(getAccessToken(), currentUser.data.store.subdomain, val.coords.latitude, val.coords.longitude));
                }
            });
    }, [createTaxMessageLoading]);

    const onTaxDeleteClick = async (value: any) => {
        if (currentUser && currentUser.data && value) {
            await dispatch(deleteTax(getAccessToken(), currentUser.data.store.subdomain, value.id));
        }
    };

    const openLinkInNewTab = () => {
        window.open(`https://intelikart.tawk.help/article/taxes-and-charges`, '_blank');
    };

    return (
        <IonPage>
            
            <FeatHeader
                title="Taxes and Charges"
                showInfoButton={true}
                defaultHref='/settings'
                onInfoClick={openLinkInNewTab}
            />
            <IonContent className="ion-padding">
                {taxesLoading === false && taxes && taxes.data && taxes.data.length !== 0 ? (
                    <>
                        <IonItem lines="none">
                            <IonGrid className="ion-no-padding">
                                <IonRow>
                                    <IonCol size="5">
                                        <IonText>
                                            <b>Tax Name</b>
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="3">
                                        <BrowserView>
                                            <IonText>
                                                <b>COD Amount</b>
                                            </IonText>
                                        </BrowserView>
                                        <MobileView>
                                            <IonText>
                                                <b>COD</b>
                                            </IonText>
                                        </MobileView>
                                    </IonCol>
                                    <IonCol size="3">
                                        <BrowserView>
                                            <IonText>
                                                <b>Prepaid Amount</b>
                                            </IonText>
                                        </BrowserView>
                                        <MobileView>
                                            <IonText>
                                                <b>Prepaid</b>
                                            </IonText>
                                        </MobileView>
                                    </IonCol>
                                    <IonCol size="1">
                                        <BrowserView>
                                            <IonText>
                                                <b>Action</b>
                                            </IonText>
                                        </BrowserView>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonItem>
                        {taxes.data.map((value: any) => {
                            return (
                                <IonCard key={value.id}>
                                    {value.range.length === 0 ? (
                                        <IonItem>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="5">
                                                        <h5 className='ion-no-margin' style={{marginTop: "10px"}}>{value.name}</h5>
                                                    </IonCol>
                                                    <IonCol size="3">
                                                        <p className='ion-no-margin' style={{marginTop: "10px"}}>{`${value.method === "PERCENT" ? `${value.value} %` : `${currency} ${value.value}`}`}</p>
                                                    </IonCol>
                                                    <IonCol size="3">
                                                    <p className='ion-no-margin' style={{marginTop: "10px"}}>{
                                                            `${value.method === "PERCENT" ? `${value.prepaid_value ?? value.value} %` : `${currency} ${value.prepaid_value ?? value.value}`}`}</p>
                                                    </IonCol>
                                                    <IonCol size="1">
                                                        <BrowserView>
                                                            <IonIcon
                                                                size="large"
                                                                icon={trashIcon}
                                                                onClick={(e) => {
                                                                    setAlertData(value);
                                                                    setShowAlert(true);
                                                                }}
                                                            ></IonIcon>
                                                        </BrowserView>
                                                        <MobileView>
                                                            <IonIcon
                                                                size="small"
                                                                icon={trashIcon}
                                                                onClick={(e) => {
                                                                    setAlertData(value);
                                                                    setShowAlert(true);
                                                                }}
                                                                className='ion-no-margin' style={{marginTop: "10px"}}
                                                            ></IonIcon>
                                                        </MobileView>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>
                                    ) : (

                                        value.distance_dependent === 1 ? (
                                            <IonItem>
                                                <IonGrid>
                                                    <IonRow>
                                                        <IonCol size="5">
                                                            <h5 style={{margin:0}}>{value.name}</h5>
                                                        </IonCol>
                                                        <IonCol size="1" offset="6">
                                                            <IonIcon
                                                                size={isPlatform('capacitor') ? "small" : "large"}
                                                                icon={trashIcon}
                                                                onClick={(e) => {
                                                                    setAlertData(value);
                                                                    setShowAlert(true);
                                                                }}
                                                            ></IonIcon>
                                                        </IonCol>
                                                    </IonRow>
                                                    {value.range.map((obj, ind) => {
                                                        return ind === value.range.length - 1 ? (
                                                            <IonRow key={obj.start}>
                                                                <IonCol size="5">
                                                                    <IonText>ABOVE {obj.start} Kms</IonText>
                                                                </IonCol>
                                                                <IonCol size="3">
                                                                    <IonText>{currency} {obj.value}</IonText>
                                                                </IonCol>
                                                                <IonCol size="3">
                                                                    <IonText>{currency} {obj.prepaid_value ?? obj.value}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                        ) : (
                                                            <IonRow key={obj.start}>
                                                                <IonCol size="5">
                                                                    <IonText>{obj.start} - {obj.end} kms</IonText>
                                                                </IonCol>
                                                                <IonCol size="3">
                                                                    <IonText>{currency} {obj.value}</IonText>
                                                                </IonCol>
                                                                <IonCol size="3">
                                                                    <IonText>{currency} {obj.prepaid_value ?? obj.value}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                        );
                                                    })}
                                                </IonGrid>
                                            </IonItem>
                                        ) : (
                                            <IonItem>
                                                <IonGrid>
                                                    <IonRow>
                                                        <IonCol size="5">
                                                            <h5 style={{margin:0}}>{value.name}</h5>
                                                        </IonCol>
                                                        <IonCol size="1" offset="6">
                                                            <IonIcon
                                                                size={isPlatform('capacitor') ? "small" : "large"}
                                                                icon={trashIcon}
                                                                onClick={(e) => {
                                                                    setAlertData(value);
                                                                    setShowAlert(true);
                                                                }}
                                                            ></IonIcon>
                                                        </IonCol>
                                                    </IonRow>
                                                    {value.range.map((obj, ind) => {
                                                        return ind === value.range.length - 1 ? (
                                                            <IonRow key={obj.start}>
                                                                <IonCol size="5">
                                                                    <IonText>ABOVE {obj.start} Order Value</IonText>
                                                                </IonCol>
                                                                <IonCol size="3">
                                                                    <IonText>{currency} {obj.value}</IonText>
                                                                </IonCol>
                                                                <IonCol size="3">
                                                                    <IonText>{currency} {obj.prepaid_value ?? obj.value}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                        ) : (
                                                            <IonRow key={obj.start}>
                                                                <IonCol size="5">
                                                                    <IonText>{obj.start} - {obj.end} Order Value</IonText>
                                                                </IonCol>
                                                                <IonCol size="3">
                                                                    <IonText>{currency} {obj.value}</IonText>
                                                                </IonCol>
                                                                <IonCol size="3">
                                                                    <IonText>{currency} {obj.prepaid_value ?? obj.value}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                        );
                                                    })}
                                                </IonGrid>
                                            </IonItem>
                                        )

                                    )}

                                </IonCard>
                            )
                        })}
                    </>
                ) : null}
                <div className="ion-text-center">
                    <IonButton shape="round" routerLink="/settings/createtax">
                        ADD Tax/Charge
                    </IonButton>
                </div>
                <IonAlert
                    isOpen={showAlert === true}
                    onDidDismiss={() => setShowAlert(false)}
                    header={'Delete tax/charge'}
                    message={`You are about to delete tax/charge ${alertData && alertData.code ? alertData.code : ''}`}
                    buttons={[
                        {
                            text: 'CANCEL',
                            role: 'cancel',
                        },
                        {
                            text: 'DELETE',
                            handler: () => {
                                onTaxDeleteClick(alertData);
                            },
                        },
                    ]}
                />
                <IonLoading isOpen={taxesLoading === true || deleteTaxMessageLoading === true} message="Please Wait"></IonLoading>
            </IonContent>
        </IonPage>
    );
};

export default Taxes;
