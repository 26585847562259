import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { IonContent, IonLabel, IonText, IonLoading, IonBackButton, IonHeader, IonToolbar, IonButtons, IonTitle, IonItem, IonCheckbox, IonInput, IonButton, IonGrid, IonPage, useIonToast } from '@ionic/react';
import { useStaffForUpdate } from "../../utils/contexts";
import FeatHeader from '../../components/dashboard/FeatHeader';

import {
    arrowBack as arrowBackIcon
} from 'ionicons/icons';
import useAPI from '../../utils/api';

const CreateStaff = () => {
    const location: any = useLocation();
    const [name, setName] = useState<string | null | undefined>("");
    const [email, setEmail] = useState<string | null | undefined>("");
    const [assignToDriver, setAssignToDriver] = useState(false);
    const [viewOrder, setViewOrder] = useState(false);
    const staffUpdateData: any = useStaffForUpdate();
    const [staffId, setStaffId] = useState();

    const API = useAPI();

    //validation
    const [isNameEmpty, setIsNameEmpty] = useState(false);
    const [isNameValidate, setIsNameValidate] = useState(true);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isEmailValidate, setIsEmailValidate] = useState(true);
    const [isPermissionEmpty, setIsPermissionEmpty] = useState(false);


    const [loading, setLoading] = useState(false);

    const [present] = useIonToast();
    const history = useHistory();

    useEffect(() => {
        if (location?.state) {
            const { name, email, driver_app, orders, id } = location?.state;
            setName(name || "");
            setEmail(email || "");
            setAssignToDriver(driver_app || false);
            setViewOrder(orders || false)
            setStaffId(id);
        }
    }, [location?.state])

    const emailValidation = () => {
        let result = true;
        if (!email) {
            setIsEmailEmpty(true);
            result = false;
        } else {
            setIsEmailEmpty(false);
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                setIsEmailValidate(false)
                result = false;
            } else {
                setIsEmailValidate(true)
            }
        }
        return result;
    }

    const nameValidation = () => {
        let result = true;
        if (!name) {
            setIsNameEmpty(true);
            result = false;
        } else {
            setIsNameEmpty(false);
            if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~01234567899]/.test(name)) {
                setIsNameValidate(false);
                result = false;
            }
            else
                setIsNameValidate(true);
        }
        return result;
    }
    const permissionValidation = () => {
        let result = true;
        if (viewOrder == false && assignToDriver == false) {
            setIsPermissionEmpty(true);
            result = false;
            
        
        }
        return result;
    }
    const submitHandler = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const res1 = await nameValidation();
        const res2 = await emailValidation();
         const res3 = await permissionValidation();
        if (res1 && res2 && res3) {
            const staffData = {
                name: `${name}`,
                email: `${email}`,
                driver_app: assignToDriver,
                orders: viewOrder,
            };
            try {
                const [ok, response] = location?.state ? await API.createStaff({ ...staffData, id: staffId }) : await API.createStaff(staffData);

                if (ok) {
                    present("Data saved successfully!", 1000);
                    console.log("API call success");
                    staffUpdateData.setUpdate(!staffUpdateData.update);
                    setName("")
                    setEmail("")
                    setViewOrder(false)
                    setAssignToDriver(false)
                    history.goBack();
                }
            } catch (error) {
                console.log("API call failed:", error);
            }
        }
        setLoading(false);
    }

    return (
        <IonPage>
            <FeatHeader
                showInfoButton={false}
                defaultHref='/staffs'
                title={location?.state?"Update User":'Create User'}
            />
            <IonContent className="ion-padding">
                <form onSubmit={submitHandler}>
                    <IonItem>
                        <IonLabel position="stacked"><h1>Name</h1></IonLabel>
                        <br />
                        <IonInput placeholder="Enter User Name" type="text" value={name} name="name" onIonChange={(e) => { setName(e.detail.value) }} />
                    </IonItem>
                    <IonText color="danger" className={`${isNameEmpty === false && isNameValidate === true ? "ion-hide" : ""}`}>
                        <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }} className={`ion-margin-horizontal ${isNameEmpty ? "" : "ion-hide"}`}>Name is required.</h5>
                        <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }} className={`ion-margin-horizontal ${isNameValidate ? "ion-hide" : ""}`}>Name can't contain special characters or numbers.</h5>
                    </IonText>
                    <br />
                    <IonItem>
                        <IonLabel position="stacked"><h1>Email</h1></IonLabel>
                        <br />
                        <IonInput placeholder="Enter User Email" type="text" value={email} name="email" onIonChange={(e) => { setEmail(e.detail.value) }} disabled={location?.state ? true:false} />
                    </IonItem>
                    <IonText color="danger" className={`${isEmailEmpty === false && isEmailValidate === true ? "ion-hide" : ""}`}>
                        <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }} className={`ion-margin-horizontal ${isEmailEmpty ? "" : "ion-hide"}`}>Email is required.</h5>
                        <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }} className={`ion-margin-horizontal ${isEmailValidate ? "ion-hide" : ""}`}>Email is invalid.</h5>
                    </IonText>
                    <br />
                    <IonGrid>
                        <IonItem>
                            <IonLabel>
                                <h1>Permissions</h1>
                            </IonLabel>
                        </IonItem>
                        <IonText color="danger" className={`${assignToDriver == true || viewOrder == true ? "ion-hide" : ""}`}>
                        <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }} className={`ion-margin-horizontal ${ (viewOrder == false && assignToDriver == false && isPermissionEmpty)? "" : "ion-hide"}`}>Please select at least one permission</h5>
                       
                    </IonText>
                        <IonItem>
                            <IonLabel>Driver App</IonLabel>
                            <IonCheckbox checked={assignToDriver} onIonChange={(e) => setAssignToDriver(e.detail.checked)} />
                        </IonItem>

                        <IonItem>
                            <IonLabel>Orders</IonLabel>
                            <IonCheckbox checked={viewOrder} onIonChange={(e) => setViewOrder(e.detail.checked)} />

                        </IonItem>
                    </IonGrid>
                    <br />
                    <IonButton type="submit">{location?.state ? "Update User" : "Create User"}</IonButton>
                </form>
                <IonLoading isOpen={loading === true} message="Please Wait"></IonLoading>
            </IonContent>
        </IonPage>
    )
}

export default CreateStaff
