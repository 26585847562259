import {
  IonCard,
  IonText,
  IonButton,
  IonIcon,
  IonInput,
  IonRow,
  IonCol,
  IonGrid,
} from "@ionic/react";
import {
  addOutline as addIcon,
  removeOutline as removeIcon,
  
} from "ionicons/icons";
import { useState, useEffect } from "react";
import { useCart } from "../../utils/contexts";
import {  useCustomer } from "../../utils/contexts";
import useAPI from "../../utils/api";

const ProductCard = ({
  product,
  currency,
  productInCart,
  setProductInCart,
}) => {
  const [qty, setQty] = useState(0);
  const { customer } = useCustomer();
  const API = useAPI();
  const userId = customer ? customer.userId : null;
  const { setMyCartData } = useCart();
  const postCartData = async (item, variant_index, quantity) => {
    if (userId) {
      const [ok, response] = await API.addProductInCart({
        products: [
          { id: item.id, variant_index: variant_index, qty: quantity },
        ],
        user_id: userId,
      });

      if (ok && response) {
        setMyCartData(response.data);
      } else {
        if (response && response.error) {
          alert(response.error);
        }
      }
    } else {
      const existingProductIndex = productInCart.findIndex(
        (product) =>
          product.id === item.id && product.variant_index === variant_index
      );

      if (existingProductIndex !== -1) {
        const updatedProduct = { ...productInCart[existingProductIndex] };
        updatedProduct.qty = quantity;
        const updatedProductList = [...productInCart];
        updatedProductList[existingProductIndex] = updatedProduct;
        setProductInCart(updatedProductList);
      } else {
        const newProduct = {
          id: item.id,
          variant_index: variant_index,
          qty: quantity,
          name: item.name,
          mrp: item.variants[variant_index].mrp,
        };
        setProductInCart([...productInCart, newProduct]);
      }
    }
  };

  const deleteCartProduct = async (id, variant_index) => {
    if (userId) {
      const [ok, response] = await API.deleteProductInCart({
        product_id: id,
        variant_index: variant_index,
        user_id: userId,
      });

      if (ok && response) {
        setMyCartData(response.data);
      } else {
        if (response && response.error) {
          alert(response.error);
        }
      }
    } else {
      const existingProductIndex = productInCart.findIndex(
        (product) =>
          product.id === id && product.variant_index === variant_index
      );

      if (existingProductIndex !== -1) {
        // Product exists in cart, remove it
        const updatedProductList = [...productInCart];
        updatedProductList.splice(existingProductIndex, 1);
        setProductInCart(updatedProductList);
      }
    }
  };
  const cart: any = useCart();
  useEffect(() => {
    if (cart && cart.myCartData && cart.myCartData.products) {
      const filterProduct = cart.myCartData.products.find(
        (result) => result.id === product.id && result.variant_index === 0
      );
      if (filterProduct) {
        setQty(filterProduct.qty);
      } else {
        setQty(0);
      }
    }
  }, [cart, product.id]);

  useEffect(() => {
    const foundProduct = productInCart.find(
      (p) => p.id === product.id && p.variant_index === 0
    );
    if (foundProduct) {
      setQty(foundProduct.qty);
    } else {
      setQty(0);
    }
  }, [productInCart, product.id]);
  return (
      <IonCard
          style={{
              cursor: "pointer",
              height: "234px",
              width: "138px",
              marginLeft: "10px",
          }}
          className="sub-cat-item ion-padding ion-no-margin ion-margin-bottom"
      >
          <img
              src={product.variants[0].image_url_original}
              style={{ height: "100px", width: "100%", objectFit: "cover" }}
          />
          <IonText style={{ fontSize: "16px" }}>
              {product.name.length <= 17
                  ? product.name
                  : product.name.substring(0, 17) + "..."}
          </IonText>
          <br />
          {product.variants[0].mrp != 0 && product.variants[0].mrp != null && (
              <IonText style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {currency} {product.variants[0].mrp}
              </IonText>
          )}

          <IonGrid className="ion-no-padding">
              {product.variants[0].available_quantity !== null &&
              product.variants[0].available_quantity === 0 ? (
                  <IonRow className="ion-no-padding ion-align-items-end">
                      <IonCol size="12">
                          <IonText color="danger" style={{ fontWeight: 600 }}>
                              Out of Stock
                          </IonText>
                      </IonCol>
                  </IonRow>
              ) : (
                  <IonRow className="ion-no-padding">
                      <IonCol size="4">
                          <IonButton
                              size="small"
                              style={{ maxWidth: "37px" }}
                              onClick={() => {
                                  const newQty = qty + 1;
                                  setQty(newQty);
                                  productInCart || newQty > 0
                                      ? postCartData(product, 0, newQty)
                                      : postCartData(product, 0, 1);
                              }}
                              disabled={
                                  product.variants[0].available_quantity !==
                                      null &&
                                  (productInCart || qty) &&
                                  qty &&
                                  qty >= product.variants[0].available_quantity
                              }
                          >
                              <IonIcon
                                  slot="icon-only"
                                  icon={addIcon}
                                  style={{ cursor: "pointer" }}
                              />
                          </IonButton>
                      </IonCol>
                      {/* Input box for quantity */}
                      <IonCol size="3.4">
                          {qty > 0 && (
                              <IonInput
                                  value={qty}
                                  min="0"
                                  max={product.variants[0].max_order_quantity}
                                  type="text"
                                  className="form-control ion-text-center"
                                  onIonChange={(e) => {
                                      const value =
                                          parseInt(e.detail.value, 10) || 0;
                                      setQty(value);
                                      if (value.toString() !== "")
                                          postCartData(
                                              product,
                                              0,
                                              Math.max(0, value)
                                          );
                                  }}
                              />
                          )}
                      </IonCol>
                      {/* Button to decrease quantity by 1 */}
                      {qty > 0 && (
                          <IonCol size="4" offset="0.4">
                              <IonButton
                                  size="small"
                                  style={{ maxWidth: "37px" }}
                                  onClick={() => {
                                      if (qty === 1) {
                                          deleteCartProduct(product.id, 0);
                                          setQty(0);
                                      } else {
                                          postCartData(product, 0, qty - 1);
                                          setQty(qty - 1);
                                      }
                                  }}
                              >
                                  <IonIcon
                                      icon={removeIcon}
                                      size="medium"
                                      style={{ cursor: "pointer" }}
                                      slot="icon-only"
                                  />
                              </IonButton>
                          </IonCol>
                      )}
                  </IonRow>
              )}
          </IonGrid>
      </IonCard>
  );
};

export default ProductCard;
