import { ItemReorderEventDetail } from '@ionic/core/components';
import { IonButton, IonCard, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLoading, IonPage, IonReorder, IonReorderGroup, IonRow, IonText, IonThumbnail, useIonToast } from '@ionic/react';
import { add as addIcon, closeCircleOutline, createOutline, search as serachIcon, trashSharp as trashSharpIcon } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import YouTube from 'react-youtube';
import { getStoresCategory, updateCategoryTree } from '../actions/categoryAction';
import Header from '../components/dashboard/Header';
import { getAccessToken } from '../services/auth/userLocalStorage';
import useAPI from '../utils/api';
import { useCategory } from '../utils/contexts';

const highlightClass = {
    backgroundColor: 'blue', // Light grey background for highlighting
    border: '1px solid #d0d0d0' // Optional: Add a border for better visibility

};

const Catalogue = () => {
    const [CategoryList, setCategoryList] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [markForDeletion, setMarkForDeletion] = useState({
        categories: [],
        subcategories: []
    });
    const [present] = useIonToast();
    const API = useAPI();
    const dispatch = useDispatch();
    const history = useHistory();
    const categoryData:any = useCategory();
    const { currentUser } = useSelector((state: any) => state.currentUser);
    const [categoryReorderd, setCategoryReorderd] = useState(true);
    const opts = {
        height: '390',
        width: '100%'
    };

    useEffect(() => {
        if (categoryData.status === "fetched" && categoryData.loading === false) {
            setCategoryList(categoryData.categories);
        }
    }, [categoryData]);

    useEffect(() => {
        setSearchString('');
   
    }, [history.location.pathname]);

    const filteredCategories = (e) => {
        let query = e.detail.value.toLowerCase();
        let result = [];
        for (let parentCategory of categoryData.categories) {
            if (parentCategory.name.toLowerCase().includes(query)) {
                result.push(parentCategory)
            } else {
                let subCateArray = parentCategory.subCategory.filter((subCate) => {
                    if (subCate.name.toLowerCase().includes(query)) {
                        return true
                    }
                })
                if (subCateArray.length !== 0) {
                    let temp = { ...parentCategory }
                    temp['subCategory'] = subCateArray
                    result.push(temp)
                }
            }
        }
        setCategoryList(result);
    }

    const doCategoryReorder = async (event: CustomEvent<ItemReorderEventDetail>) => {
        let cateList = categoryData?.categories
        const accessToken = getAccessToken();
        if(categoryReorderd === true){
            let itemToMove = cateList.splice(event.detail.from, 1)[0];
            cateList.splice(event.detail.to, 0, itemToMove);
        }
        const putData = {
            "categoriesTree":
            cateList.map(parentCategory => {
                    return parentCategory
                })
        }
        putData.categoriesTree.map((parent, index) => {
            parent.priority = cateList.length - index;
        })
        await dispatch(updateCategoryTree(accessToken, currentUser.data.store.subdomain, putData))
        setCategoryReorderd(true);
        event.detail.complete();
    }
    const doSubCategoryReorder = async (event: CustomEvent<ItemReorderEventDetail>, value: any) => {
        setCategoryReorderd(false);
        const putData = {
            "categoriesTree":
            categoryData.categories.map(parentCategory => {
                    return parentCategory
                })
        }

        putData.categoriesTree.map((parent) => {
            if (parent.name === value.name) {
                const len = parent.subCategory.length;
                console.log(parent.subCategory,"parent.subCategory");
                let itemToMove = parent.subCategory.splice(event.detail.from, 1)[0];
                console.log(itemToMove,"itemToMove")
                parent.subCategory.splice(event.detail.to, 0, itemToMove);
                console.log(parent.subCategory,"after splice")
                if (len > 1) {
                    parent.subCategory.map((subParent, index) => {
                        subParent.priority = len - index;
                    })
                }
                console.log(parent.subCategory,"after priority")
            }
        })
        event.detail.complete();
    }

    function handleMarkForDeletion(categoryData){
        if(markForDeletion.categories.find(cate => cate.id === categoryData.id)){
            let subTemp = [...markForDeletion.subcategories];
            for(const val of categoryData.subCategory){
                subTemp = subTemp.filter(elem => val.id !== elem.id);
            }
            setMarkForDeletion(prev => {
                let temp = [...markForDeletion.categories];
                temp = temp.filter(elem => categoryData.id !== elem.id);
                return {...markForDeletion, categories: temp, subcategories: subTemp}
            })
        } else{
            let temp = [...markForDeletion.subcategories, ...(categoryData.subCategory.map((subCate)=> {return {id: subCate.id, name: subCate.name}}))];
            const jsonObject = temp.map(val => JSON.stringify(val));
            const uniqueSet = new Set(jsonObject);
            const uniqueArray = Array.from(uniqueSet).map(val => JSON.parse(val));
            setMarkForDeletion({
                categories: [...markForDeletion.categories, {id: categoryData.id, name: categoryData.name}],
                subcategories: uniqueArray
            })
        }
    }

    async function deleteAllSelected(){
        for (const val of markForDeletion.subcategories){
            const [ok, response] = await API.deleteCategory(val.id);
            if(!ok){
                present(`There are products associated with ${val.name} category, please delete them first`, 2000);
            }
        }
        for (const val of markForDeletion.categories){
            const [ok, response] = await API.deleteCategory(val.id);
            if(!ok){
                present(`There are sub category associated with ${val.name} category, please delete them first`, 3000);
            }
        }
        setMarkForDeletion({
            categories: [],
            subcategories: []
        });
        categoryData.setUpdatedCategoryData(!categoryData.updatedCategoryData);
        dispatch(getStoresCategory(getAccessToken(), currentUser.data.store.subdomain));
    }

    return(
        <IonPage>
            <Header name="Catalogue" />
            <IonContent>
                <div style={{ 'backgroundColor': '#F5F7FB', height: 81.6 + 'vh' }}>
                    <br />
                    <IonText className="ion-text-center">
                        <h5 className="ion-no-margin ion-margin-bottom"><strong>Manage categories and subcategories</strong></h5>
                    </IonText>
                    <IonGrid>
                        <IonRow>
                            <IonCol sizeSm='6' sizeXs='12'>
                                {categoryData.status === "fetched" && categoryData.loading === false && <div className="product-search-bar">
                                    <IonIcon icon={serachIcon}></IonIcon>
                                    <IonInput placeholder="Search Category" value={searchString} onIonChange={e => { setSearchString(e.detail.value); filteredCategories(e) }}></IonInput>
                                    {searchString ? <IonIcon icon={closeCircleOutline} style={{cursor:"pointer",marginRight:"20px" }} onClick={e => { setCategoryList(categoryData?.categories); setSearchString('') }}></IonIcon> : ""}
                                </div>}
                            </IonCol>
                            <IonCol sizeSm='6' sizeXs='12'>
                                <Link to={`/catalogue/category/add-cat/${false}`} style={{ textDecoration: 'none' }}>
                                    <IonButton shape="round" expand='block' color='success'>Create new category
                                        <IonIcon icon={addIcon} style={{ marginLeft: 'auto' }}></IonIcon>
                                    </IonButton>
                                </Link>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    {categoryData.status === "fetched" && categoryData.loading === false && <>
                        <IonReorderGroup disabled={false} onIonItemReorder={doCategoryReorder}>
                            {CategoryList.map(parentCategory => {
                                 const hasSubcategories = parentCategory.subCategory.length > 0;

                                 return (
                                     <IonCard
                                         style={{ backgroundColor: 'white', ...(hasSubcategories ? {} : highlightClass) }} // Apply highlightClass if no subcategories
                                         key={parentCategory.id}>
                                    {/* {console.log(parentCategory,"category parent")} */}
                                    <BrowserView>
                                    <IonItem  style={{ backgroundColor: 'white', ...(hasSubcategories ? {} : highlightClass) }} >
                                            <IonCheckbox checked={markForDeletion.categories.find(cate => parentCategory.id === cate.id)} slot='start' onClick={e => handleMarkForDeletion(parentCategory)} />
                                            <IonThumbnail slot="start">
                                                <img src={parentCategory.icon} />
                                            </IonThumbnail>
                                            <IonText>
                                                <h4 className='ion-no-margin' style={{ color: hasSubcategories ? '' : 'red' }}>{parentCategory.name}</h4>
                                                <IonText color='primary' style={{ cursor: 'pointer' }}>
                                                    <p className='ion-no-margin' onClick={e => history.push(`/catalogue/category/add-subcat/${parentCategory.id}/${parentCategory.name}/false`)}>Add Sub-Category +</p>
                                                </IonText>
                                            </IonText>
                                            <IonButton fill='clear' color='primary' onClick={() => history.push({ pathname: `/catalogue/category/update-cat/${parentCategory.id}`, state: parentCategory })} slot='end' >
                                                <IonIcon slot="icon-only" icon={createOutline}  ></IonIcon>
                                            </IonButton>
                                            <IonReorder slot="end"  />
                                        </IonItem>
                                    </BrowserView>
                                    <MobileView>
                                        <IonItem>
                                            <IonCheckbox checked={markForDeletion.categories.find(cate => parentCategory.id === cate.id)} slot='start' onClick={e => handleMarkForDeletion(parentCategory)} />
                                            <IonThumbnail slot="start">
                                                <img src={parentCategory.icon} />
                                            </IonThumbnail>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonText>
                                                            <h4 className='ion-no-margin'>{parentCategory.name}</h4>
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol sizeXs='7'>
                                                        <IonText color='primary' style={{ cursor: 'pointer' }}>
                                                            <p className='ion-no-margin' onClick={e => history.push(`/catalogue/category/add-subcat/${parentCategory.id}/${parentCategory.name}/false`)}>Add Sub-Category +</p>
                                                        </IonText>
                                                    </IonCol>
                                                    <IonCol sizeXs='2'>
                                                        <IonButton fill='clear' color='primary' onClick={() => history.push({ pathname: `/catalogue/category/update-cat/${parentCategory.id}`, state: parentCategory })}>
                                                            <IonIcon slot="icon-only" icon={createOutline}  ></IonIcon>
                                                        </IonButton>
                                                    </IonCol>
                                                    <IonCol sizeXs='2' offsetXs='1'>
                                                        <IonReorder />
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>
                                    </MobileView>
                                    <IonReorderGroup disabled={false} onIonItemReorder={(event) => { doSubCategoryReorder(event, parentCategory) }}>
                                        {parentCategory.subCategory.length !== 0 && parentCategory.subCategory.map((subCate) =>
                                            <div key={subCate.id}>
                                                <BrowserView>
                                                    <IonItem className='ion-margin' style={{ marginLeft: 70 + 'px' }}>
                                                        <IonCheckbox  checked={markForDeletion.subcategories.find(cate => subCate.id === cate.id)} onClick={e => {
                                                            markForDeletion.subcategories.find(cate => subCate.id === cate.id) ? setMarkForDeletion(prev => {
                                                                let temp = [...markForDeletion.subcategories];
                                                                temp = temp.filter(elem => subCate.id !== elem.id);
                                                                return { ...markForDeletion, subcategories: temp }
                                                            })
                                                            : setMarkForDeletion({
                                                                ...markForDeletion, subcategories: [...markForDeletion.subcategories, { id: subCate.id, name: subCate.name }]
                                                            })
                                                        }} slot='start' />
                                                        <IonThumbnail slot="start">
                                                            <img src={subCate.icon} />
                                                        </IonThumbnail>
                                                        <IonText>
                                                            <h6 className='ion-no-margin'>{subCate.name}</h6>
                                                            <IonText color='primary' style={{ cursor: 'pointer', display: 'none' }}>
                                                                <p className='ion-no-margin' onClick={e => history.push({ pathname: `/catalogue/products/create/${false}`, state: subCate })}>Add Product +</p>
                                                            </IonText>
                                                        </IonText>
                                                        <IonCol size='3'></IonCol>
                                                        
                                                        <IonButton fill='clear'   color='primary' onClick={e => history.push({ pathname: `/catalogue/category/update-subcat/${subCate.id}`, state: subCate })} slot='end' >
                                                            <IonIcon    slot="icon-only" icon={createOutline}></IonIcon>
                                                        </IonButton>
                                                        
                                                         
                                                        <IonReorder slot='end' />
                                                      
                                                       
                                                    </IonItem>
                                                </BrowserView>
                                                <MobileView>
                                                    <IonItem className='ion-margin' style={{ marginLeft: 20 + 'px' }}>
                                                        <IonCheckbox checked={markForDeletion.subcategories.find(cate => subCate.id === cate.id)} onClick={e => {
                                                            markForDeletion.subcategories.find(cate => subCate.id === cate.id) ? setMarkForDeletion(prev => {
                                                                let temp = [...markForDeletion.subcategories];
                                                                temp = temp.filter(elem => subCate.id !== elem.id);
                                                                return { ...markForDeletion, subcategories: temp }
                                                            })
                                                            : setMarkForDeletion({
                                                                ...markForDeletion, subcategories: [...markForDeletion.subcategories, { id: subCate.id, name: subCate.name }]
                                                            })
                                                        }} slot='start' />
                                                        <IonThumbnail slot="start">
                                                            <img src={subCate.icon} />
                                                        </IonThumbnail>
                                                        <IonGrid>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <IonText>
                                                                        <h6 className='ion-no-margin'>{subCate.name}</h6>
                                                                    </IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol sizeXs='7'>
                                                                    <IonText color='primary' style={{ cursor: 'pointer', display: 'none' }}>
                                                                        <p className='ion-no-margin' onClick={e => history.push({ pathname: `/catalogue/products/create/${false}`, state: subCate })}>Add Product +</p>
                                                                    </IonText>
                                                                </IonCol>
                                                                <IonCol sizeXs='2'>
                                                                <IonButton fill='clear' color='primary' onClick={e => history.push({ pathname: `/catalogue/category/update-subcat/${subCate.id}`, state: subCate })}>
                                                                    <IonIcon slot="icon-only" icon={createOutline}></IonIcon>
                                                                </IonButton>
                                                                </IonCol>
                                                                <IonCol sizeXs='2' offsetXs='1'>
                                                                    <IonReorder />
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                    </IonItem>
                                                </MobileView>
                                            </div>
                                        )}
                                    </IonReorderGroup>
                                </IonCard>
                            )})}
                        </IonReorderGroup>
                    </>
                    }
                    {categoryData.status === "fetched" && categoryData.loading === false && CategoryList?.length === 0 && <IonText className="ion-text-center" >
                        <h4>No category found</h4>
                    </IonText>
                    }
                    {categoryData.status === "fetched" && categoryData.loading === false &&categoryData?.categories.length === 0 && <IonCard className="ion-text-center ion-no-padding" style={{ backgroundColor: 'white' }}>
                        <IonCardHeader>
                            <IonCardTitle>Watch video to understand categories and products.</IonCardTitle>
                        </IonCardHeader>
                        <YouTube videoId='dkOvPjF41YA' opts={opts} />
                    </IonCard>
                    }
                </div>
            </IonContent>
            <IonLoading isOpen={categoryData.loading === true} message="Please Wait"></IonLoading>
            <IonButton expand="full"  color="danger" className={`${markForDeletion.categories.length > 0 || markForDeletion.subcategories.length > 0 ? '' : 'ion-hide'}`} onClick={e=> deleteAllSelected()}><IonIcon icon={trashSharpIcon}></IonIcon> &nbsp; Delete all selected</IonButton>
        </IonPage>
    )
}

export default Catalogue




