import React from "react";
import { IonToolbar, IonFooter } from "@ionic/react";
import { Link } from "react-router-dom";

const LoginFooter = () => {
  return (
    <IonFooter className="ion-text-center" style={{color:"black"}}>
      <p style={{fontSize: "11px"}}>
        By using this application, you agree to our{" "}
        <Link
          to="/content/privacy-policy"
        >
          Privacy Policy
        </Link>{" "}
        ,{" "}
        <Link
          to="/content/return-policy"
        >
          Return policy
        </Link>{" "}
        and{" "}
        <Link
          to="/content/terms-of-service"
        >
          Terms of Service
        </Link>
      </p>
    </IonFooter>
  );
};

export default LoginFooter;
