import { IonAccordion, IonButton, IonCard, IonIcon, IonImg, IonItem, IonLabel, IonReorder, IonReorderGroup, IonToggle, useIonToast } from '@ionic/react';
import { createSharp as createSharpIcon, trashSharp as trashSharpIcon } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import useAPI from '../../../../../utils/api';
import { useWebsiteSettings } from '../../../../../utils/contexts';
import UpdateBannerModal from '../../components/modal/UpdateBannerModal';

const BannerSlides = ({data}) => {
  const settings:any = useWebsiteSettings();
  const API = useAPI();
  const [present] = useIonToast();
  const [bannerDataByID, setBannerDataByID] = useState<any>();
  const [modalData, setModalData] = useState({
    open: false,
    data: null
  })

  useEffect(() => {
    let slides = settings?.websiteSettings?.banner_sliders?.slides;
    if(slides && slides.length !== 0){
      setBannerDataByID(()=>{
        let temp = {};
        for(const slide of slides){
          temp[slide.id] = slide;
        }
        return temp;
      });
    }
  }, [settings]);

  const objectToArray = (object)=> {
    let arr = [];
    for (const property in object) {
      if (object[property]) {
        if(object[property].display_order){
          arr[object[property].display_order - 1] = property;
        }else{
          arr.push(property);
        }
      }
    }
    return arr;
  }

  const arrayToObject = (array)=> {
    let slides = [];
    for(let i=0; i<array.length; i++){
      slides.push({...bannerDataByID[array[i]], display_order: i+1});
    }
    settings.setWebsiteSettings({...settings.websiteSettings, banner_sliders: {...settings.websiteSettings.banner_sliders, slides: slides}});
  }

  function doReorder(event: CustomEvent) {
    let arr = objectToArray(bannerDataByID);
    const reorderArray = event.detail.complete(arr);
    arrayToObject(reorderArray);
  }

  async function deleteBanner(id) {
    if(bannerDataByID[id].display_order) {
      let array = objectToArray(bannerDataByID);
      let indexOfDeleted = array.indexOf(id);
      let expectedData = {};
      for(let i = indexOfDeleted + 1; i < array.length; i++){
        expectedData[array[i]] = i;
      }
      const [ok, response] = await API.deleteBanner(id);
        if(ok && response){
            const [okay, resData] = await API.theme();
            if(okay){
              let updatedSlides = resData?.data?.banner_sliders.slides;
              for(let i = 0; i < updatedSlides.length; i++){
                if(updatedSlides[i].id in expectedData){
                  updatedSlides[i].display_order = expectedData[updatedSlides[i].id];
                }
              }
              const [postOk, postRes] = await API.updateTheme({...resData?.data, banner_sliders: {...resData?.data?.banner_sliders, slides: updatedSlides}});
              if(postOk) {
                  present("Banner Deleted successfully!", 1000);
                  settings.setUpdate(!settings.update);
              }
            }
        }else{
            if(response && response.error){
                alert(response.error);
            }
        }
      }else{
        const [ok, response] = await API.deleteBanner(id);
        if(ok && response){
          present("Banner deleted successfully!", 1000);
          settings.setUpdate(!settings.update);
        }else{
          if(response && response.error){
            alert(response.error);
          }
        }
      }
  }

  return (<>
    <UpdateBannerModal modalData={modalData} setModalData={setModalData}></UpdateBannerModal>
    <IonCard>
      <IonAccordion>
          <IonItem slot="header" color='light'>
              <IonLabel>Banner Slider</IonLabel>
              {data.reorder && <IonReorder slot="end"/>}
              {data.toggle && <IonToggle checked={settings.websiteSettings.banner_sliders.display === 1} color="medium" onIonChange={e => settings.setWebsiteSettings({...settings.websiteSettings, banner_sliders: {...settings.websiteSettings.banner_sliders, display: e.detail.checked ? 1 : 0}  })} slot="end"></IonToggle>}
          </IonItem>
          <div className="ion-padding" slot="content">
            <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
            {bannerDataByID && objectToArray(bannerDataByID).map((id, index) => <IonItem lines='none' className='ion-margin-bottom' key={id}>
              <IonImg src={bannerDataByID[id].image_link} />
              <IonButton onClick={e=> setModalData({...modalData, open: true, data: bannerDataByID[id] })} slot='end'><IonIcon icon={createSharpIcon}/> </IonButton>
              <IonButton color="danger" slot='end' onClick={e=> deleteBanner(id)}><IonIcon icon={trashSharpIcon}/> </IonButton>
              <IonReorder slot="end"/>
            </IonItem>
            )}
            </IonReorderGroup>
            <IonButton onClick={e=> setModalData({...modalData, open: true, data: null })} shape='round' expand='full'>Add Banner</IonButton>
          </div>
        </IonAccordion>
      </IonCard>
      </>
  )
}

export default BannerSlides