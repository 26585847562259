import { IonButton, IonCard, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonRow, IonSelect, IonSelectOption, IonText } from "@ionic/react";
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import {
    calendar as calenderIcon,
} from 'ionicons/icons';
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import Header from '../../components/dashboard/Header';
import useFetch from "../../utils/useFetch";
import { BrowserView, MobileView } from "react-device-detect";

const dateFormatting = (e: any) => {
    const today = new Date(e);
    let day = String(today.getDate()).padStart(2, '0');
    let month = String(today.getMonth() + 1).padStart(2, '0');
    let year = today.getFullYear();
    let date = month + '-' + day + '-' + year;
    return date;
}

const Traffic = () => {
    const [dates, setDates] = useState<any>({
        startDate: new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString(),
        endDate: new Date().toISOString()
    });
    const [callAgain, setCallAgain] = useState(false);
    Chart.register(CategoryScale);

    const { loading, data, status } = useFetch("traffic", dateFormatting(dates.startDate), dateFormatting(dates.endDate), callAgain);

    const [isFiltered, setisFiltered] = useState(false);
    const [dateValidate, setdateValidate] = useState("");

    const dateValidation = () => {
        const startDate = new Date(dates.startDate);
        const endDate= new Date(dates.endDate);

        if (startDate > endDate) {
            setdateValidate("Selected dates are not valid.");
        }
        else {
            setdateValidate("");
            const diffTime = Math.abs(endDate.valueOf() - startDate.valueOf());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays > 30) {
                setdateValidate("Range should not be greater than 30 days.");
            }
            else {
                setdateValidate("");
            }
        }
        return !!dateValidate;
    }

    function getMinDate() {
        const today = new Date();
        const minDate = new Date(today);
        minDate.setDate(today.getDate() - 30);

        // Format the date as YYYY-MM-DD
        const minDateFormatted = minDate.toISOString().slice(0, 10);
        return minDateFormatted;
    }

    const filterHandler = async (e: any) => {
        setisFiltered(true);
        const selection = e.detail.value;
        let today = new Date();
        let yesterday = new Date(today.getTime() - (1 * 24 * 60 * 60 * 1000));

        switch(selection) {
            case "Today":
                setDates({
                    startDate: today.toISOString(),
                    endDate: today.toISOString()
                });
                break;

            case "Yesterday":
                setDates({
                    startDate: yesterday.toISOString(),
                    endDate: yesterday.toISOString()
                });
                break;

            case "Week":
                setDates({
                    startDate: new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString(),
                    endDate: today.toISOString()
                });
                break;

            case "Month":
                setDates({
                    startDate: new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000)).toISOString(),
                    endDate: today.toISOString()
                });
                break;
        }
        setTimeout(()=> setisFiltered(false), 500)
    }

    const visitorHandler = async () => {
        const checkPasses = dateValidation();
        if(checkPasses){
            setCallAgain(!callAgain);
        }
    }

    return (
        <IonPage>
            <Header name="Traffic" />
            <IonContent>
                <IonGrid>
                    <IonCard>
                        <IonRow>
                            <IonCol
                                sizeMd="1.6"
                                sizeSm="4.9"
                                sizeXs="4.9"
                                className="ion-no-padding"
                            >
                                <IonItem lines="none">
                                    <IonLabel>Start Date</IonLabel>
                                </IonItem>
                            </IonCol>
                            <IonCol
                                sizeMd="2.4"
                                sizeSm="7.1"
                                sizeXs="7.1"
                                className="date ion-no-padding"
                            >
                                <IonItem className="ion-no-padding">
                                    <IonIcon
                                        className="ion-no-margin"
                                        icon={calenderIcon}
                                        slot="end"
                                    />
                                    <IonDatetimeButton datetime="trafficStartDate"></IonDatetimeButton>
                                    <IonModal keepContentsMounted={true}>
                                        <IonDatetime
                                            id="trafficStartDate"
                                            presentation="date"
                                            value={dates.startDate}
                                            min={getMinDate()}
                                            max={new Date()
                                                .toISOString()
                                                .slice(0, 10)}
                                            onIonChange={(e) => {
                                                if (!isFiltered) {
                                                    const newStartDate = e.detail.value;
                                                    setDates({
                                                        ...dates,
                                                        startDate: newStartDate,
                                                        endDate: dates.endDate < newStartDate ? newStartDate : dates.endDate
                                                    })
                                                }
                                            }
                                        }
                                        />
                                    </IonModal>
                                </IonItem>
                            </IonCol>
                            <IonCol
                                sizeMd="1.5"
                                sizeSm="4.9"
                                sizeXs="4.9"
                                className="ion-no-padding"
                                offsetLg="0.5"
                                offsetSm="none"
                                offsetXs="none"
                            >
                                <IonItem lines="none">
                                    <IonLabel>End Date</IonLabel>
                                </IonItem>
                            </IonCol>
                            <IonCol
                                sizeMd="2.4"
                                sizeSm="7.1"
                                sizeXs="7.1"
                                className="date ion-no-padding"
                            >
                                <IonItem className="ion-no-padding">
                                    <IonIcon
                                        className="ion-no-margin"
                                        icon={calenderIcon}
                                        slot="end"
                                    />
                                    <IonDatetimeButton datetime="trafficEndDate"></IonDatetimeButton>
                                    <IonModal keepContentsMounted={true}>
                                        <IonDatetime
                                            id="trafficEndDate"
                                            presentation="date"
                                            value={dates.endDate}
                                            min={dates.startDate}
                                            onIonChange={(e) =>
                                                !isFiltered &&
                                                setDates({
                                                    ...dates,
                                                    endDate: e.detail.value,
                                                })
                                            }
                                            max={new Date()
                                                .toISOString()
                                                .slice(0, 10)}
                                        />
                                    </IonModal>
                                </IonItem>
                            </IonCol>
                            <IonCol
                                sizeMd="2"
                                sizeSm="6"
                                sizeXs="6"
                                className="ion-no-padding"
                                offsetLg="0.2"
                                offsetSm="none"
                                offsetXs="none"
                            >
                                <IonList className="ion-no-padding">
                                    <IonItem lines="inset">
                                        <IonSelect
                                            interface="popover"
                                            placeholder="Filters"
                                            onIonChange={(e) => {
                                                filterHandler(e);
                                            }}
                                            className="color"
                                        >
                                            <IonSelectOption>
                                                Today
                                            </IonSelectOption>
                                            <IonSelectOption>
                                                Yesterday
                                            </IonSelectOption>
                                            <IonSelectOption>
                                                Week
                                            </IonSelectOption>
                                            <IonSelectOption>
                                                Month
                                            </IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                </IonList>
                            </IonCol>
                            <IonCol
                                sizeMd="1.4"
                                sizeSm="6"
                                sizeXs="6"
                                className="ion-text-end"
                            >
                                <BrowserView>
                                    <IonButton
                                        shape="round"
                                        color="primary"
                                        onClick={visitorHandler}
                                    >
                                        OK
                                    </IonButton>
                                </BrowserView>
                                <MobileView>
                                    <IonButton
                                        shape="round"
                                        color="primary"
                                        size="small"
                                        onClick={visitorHandler}
                                        className="ion-margin-top ion-padding-start ion-padding-end"
                                        style={{ padding: "0px" }}
                                    >
                                        OK
                                    </IonButton>
                                </MobileView>
                            </IonCol>
                        </IonRow>
                    </IonCard>
                    {!loading && data?.data && status === "fetched" && (
                        <>
                            <IonRow className="ion-text-center">
                                <IonCol>
                                    <IonCard>
                                        <IonItem>
                                            <IonText>Total Visitors</IonText>
                                        </IonItem>
                                        <IonItem>
                                            <IonText>
                                                {data?.data?.totalVisitors}
                                            </IonText>
                                        </IonItem>
                                    </IonCard>
                                </IonCol>
                                <IonCol>
                                    <IonCard>
                                        <IonItem>
                                            <IonText>
                                                Daily Average Visitors
                                            </IonText>
                                        </IonItem>
                                        <IonItem>
                                            <IonText>
                                                {data?.data?.dailyAvgVisitors}
                                            </IonText>
                                        </IonItem>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <div className="canvas-container">
                                <Bar
                                    data={{
                                        labels: data?.data.visitorsData.map(
                                            (o) => o.date
                                        ),
                                        datasets: [
                                            {
                                                label: "Visitors",
                                                backgroundColor:
                                                    "rgba(135, 206, 235, 0.5)",
                                                borderColor: "rgb(0, 0, 0)",
                                                borderWidth: 1,
                                                data: data?.data.visitorsData.map(
                                                    (o) => o.count
                                                ),
                                            },
                                        ],
                                    }}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: true,
                                            },
                                        },
                                        responsive: true,
                                        maintainAspectRatio: true,
                                    }}
                                />
                            </div>
                        </>
                    )}
                </IonGrid>
                <IonLoading isOpen={loading} message="Please Wait"></IonLoading>
            </IonContent>
        </IonPage>
    );
}

export default Traffic;