import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonText, IonTitle, IonToggle, IonToolbar, useIonToast } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import {
    arrowBack as arrowBackIcon
} from 'ionicons/icons';
import { useAddon } from '../../../utils/contexts';
import useAPI from '../../../utils/api';
import { indexedValue } from '../AddonsRoutes';
import FeatHeader from '../../../components/dashboard/FeatHeader';


const WhatsappForm = () => {
    const addons:any = useAddon();
    const [present] = useIonToast();
    const API = useAPI();
    const [phoneValidation, setPhoneValidation] = useState('');
    const [whatsappPhone, setWhatsappPhone] = useState(Array.isArray(addons[indexedValue.whatsapp].data) ? '' : addons[indexedValue.whatsapp].data?.whatsapp_phone);
    const [whatsappCheck, setWhatsappCheck] = useState(Array.isArray(addons[indexedValue.whatsapp].data) ? false : addons[indexedValue.whatsapp].data?.status === 'active');

    const validation = () => {
        let result = true;
        if(!whatsappPhone){
            setPhoneValidation("Whatsapp number is required")
            result = false
        }else{
            if(/\D/.test(whatsappPhone) || whatsappPhone.length !== 10){
                setPhoneValidation("Whatsapp number is invalid")
                result = false;
            }
            else{
                setPhoneValidation("")
            }
        }
        return result
    }

    const onSubmitHandler = async(e)=>{
        e.preventDefault();
        if(validation()){
            const [ok, response] = Array.isArray(addons[indexedValue.whatsapp].data) ? 
                await API.createWhatsappAddon({"whatsapp_phone": whatsappPhone,"status": whatsappCheck ? "active" : "inactive"})
                : 
                await API.updateWhatsappAddon({"whatsapp_phone": whatsappPhone,"status": whatsappCheck ? "active" : "inactive", id: addons[indexedValue.whatsapp].data.id});

            if(ok) {
                present("Data saved successfully!", 1000);
                addons[indexedValue.whatsapp].updateMethod(!addons[indexedValue.whatsapp].update);
            }
        }
    }

    return ( <IonPage>
      
        <FeatHeader defaultHref="/add-ons" title='Configure Whatsapp' showInfoButton={false} />
            <IonContent className="ion-padding">
                <form onSubmit={onSubmitHandler}>
                    <IonItem>
                        <IonLabel position="stacked"><h1>Whatsapp number</h1></IonLabel>
                        <IonInput placeholder="Enter Whatsapp phone number" type="tel" value={whatsappPhone} onIonChange={(e)=>{ setWhatsappPhone(e.detail.value)}} onIonBlur={e=> validation()}/>
                    </IonItem>
                    <IonText color="danger" className={`${phoneValidation ? "": "ion-hide" }`}>
                        <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className='ion-margin-horizontal'>{phoneValidation}</h5>
                    </IonText>
                    <br/>
                    <IonItem lines="none">
                        <IonText><h5>Enable Whatsapp Support</h5></IonText>
                        <IonToggle slot="end" checked={whatsappCheck} onIonChange={e => setWhatsappCheck(e.detail.checked)} />
                    </IonItem>
                    <br/>
                    <div className="ion-text-center">
                        <IonButton type="submit">Save</IonButton>
                    </div>
                </form>
                {/* <IonLoading isOpen={loadingWhatsappRecords} message="Please Wait"></IonLoading> */}
            </IonContent>
        </IonPage>
    )
}

export default WhatsappForm