import {
    IonAccordion,
    IonAccordionGroup,
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
    IonThumbnail,
} from "@ionic/react";
import { chevronDownOutline, trashSharp } from "ionicons/icons";
import { useEffect, useState } from "react";
import ComplementaryModal from "./ComplementaryModal";
import { StoreCurrency } from "../../../utils/currency";
const ComplementaryProduct = ({
    complementaryData,
    setComplementaryData,
    productName,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState<any>([]);
    const currency = StoreCurrency();

    const handleDeleteProduct = (productId) => {
        const updatedComplementaryData = complementaryData.filter(
            (product) => product.id !== productId
        );
        setComplementaryData(updatedComplementaryData);
    };

    useEffect(() => {
        if (complementaryData.length > 0) {
            setSelectedProducts(complementaryData);
        }
    }, [complementaryData]);

    return (
        <>
            <ComplementaryModal
                showModal={showModal}
                setShowModal={setShowModal}
                setComplementaryData={setComplementaryData}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
            ></ComplementaryModal>

            <IonCard>
                <IonAccordionGroup class="accordion-border">
                    <IonAccordion>
                        <IonItem slot="header">
                            <IonLabel>Complementary Products</IonLabel>
                            <IonIcon
                                icon={chevronDownOutline}
                                className="ion-accordion-toggle-icon md"
                                slot="end"
                                color="primary"
                            ></IonIcon>
                        </IonItem>

                        <IonCardContent slot="content">
                            {complementaryData?.length > 0 &&
                                complementaryData.map((product) => (
                                    <IonItem
                                        className="ion-margin-bottom"
                                        key={product.id}
                                    >
                                        <IonThumbnail slot="start">
                                            <img
                                                src={
                                                    product.variants[0]
                                                        ?.image_url_original
                                                }
                                            />
                                        </IonThumbnail>
                                        <IonText>
                                            <h5>{product.name}</h5>
                                            {product.variants[0].mrp != 0 &&
                                            product.variants[0].mrp != null ? (
                                                <h5
                                                    className="ion-no-margin"
                                                    style={{
                                                        display: "inline",
                                                    }}
                                                >
                                                    {currency}{" "}
                                                    {product.variants[0].mrp}
                                                    &nbsp;
                                                </h5>
                                            ) : (
                                                ""
                                            )}
                                            {product.variants[0].display_mrp !=
                                                0 &&
                                            product.variants[0].display_mrp !=
                                                null ? (
                                                <del>
                                                    {currency}{" "}
                                                    {
                                                        product.variants[0]
                                                            .display_mrp
                                                    }
                                                </del>
                                            ) : (
                                                ""
                                            )}
                                        </IonText>
                                        <IonButton
                                            color="danger"
                                            slot="end"
                                            onClick={() =>
                                                handleDeleteProduct(product.id)
                                            }
                                        >
                                            <IonIcon icon={trashSharp} />
                                        </IonButton>
                                    </IonItem>
                                ))}

                            <IonGrid>
                                <IonRow className="ion-align-items-center">
                                    <IonCol>
                                        <IonText>
                                            Select products that could be bought
                                            in addition to{" "}
                                            <b>
                                                <u>{productName}</u>
                                            </b>
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="auto">
                                        <IonButton
                                            onClick={(e) => setShowModal(true)}
                                        >
                                            Add
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonCardContent>
                    </IonAccordion>
                </IonAccordionGroup>
            </IonCard>
        </>
    );
};

export default ComplementaryProduct;
