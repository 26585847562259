import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLoading,
  IonPage,
  IonText,
  IonTitle,
  IonToast,
  IonToggle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {
  arrowBack as arrowBackIcon,
  informationCircleOutline,
  notificationsCircle,
} from "ionicons/icons";
import { getFirebaseToken } from "../../push/firebase";
import {
  setPushNotification,
  deletePushNotification,
  getCommunications,
  postCommunications,
} from "../../actions/dashboardActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import { deviceDetect } from "react-device-detect";
import FeatHeader from '../../components/dashboard/FeatHeader';


const API_ROOT = 'https://api2.intelikart.in';


const Communications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser } = useSelector((state: any) => state.currentUser);
  const [pushChecked, setPushChecked] = useState<boolean>();
  const [present] = useIonToast();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const { getCommunicationLoading, getCommunication, getCommunicationError } = useSelector((state: any) => state.getCommunication);


  const [ebuyer, setEbuyer] = useState<boolean>();
  const [eseller, setEseller] = useState<boolean>(false);
  const [webPushSeller, setWebPushSeller] = useState<boolean>(false);
  const [appPushSeller, setAppPushSeller] = useState<boolean>(false);
  const [appPushBuyer, setAppPushBuyer] = useState<boolean>(false);
  const [smsSeller, setSmsSeller] = useState<boolean>(false);
  const [smsBuyer, setSmsBuyer] = useState<boolean>(false);

  const {
    setPushNotificationLoading,
    setPushNotificationResponse,
    setPushNotificationError,
  } = useSelector((state: any) => state.setPushNotification);
  const { deletePushNotificationLoading, deletePushNotificationError } =
    useSelector((state: any) => state.deletePushNotificationData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("pushRegistered")) {
      localStorage.getItem("pushRegistered") === "true"
        ? setPushChecked(true)
        : setPushChecked(false);
    } else {
      localStorage.setItem("pushRegistered", String(false));
      setPushChecked(false);
    }
  }, []);

  useEffect(() => {
    console.log(getCommunication.data)
    if (currentUser && currentUser.data) {
      dispatch(getCommunications(getAccessToken(), currentUser.data.store.subdomain));
    }
  }, [currentUser]);
  useEffect(() => {
    console.log(getCommunication.data)
    if (getCommunicationLoading === false && getCommunication && getCommunication.data) {
      setEbuyer(getCommunication.data.enable_email_buyer);
      setEseller(getCommunication.data.enable_email_seller);
      setWebPushSeller(getCommunication.data.enable_web_push_seller);
      setAppPushSeller(getCommunication.data.enable_app_push_seller);
      setAppPushBuyer(getCommunication.data.enable_app_push_buyer);
      setSmsSeller(getCommunication.data.enable_sms_seller);
      setSmsBuyer(getCommunication.data.enable_sms_buyer);
    }
  }, [getCommunication])

  const handleClick = async (e: any) => {
    e.preventDefault();
    Promise.all([await dispatch(postCommunications(getAccessToken(), currentUser.data.store.subdomain, {
      enable_email_buyer: ebuyer,
      enable_email_seller: eseller,
      enable_sms_buyer: smsBuyer,
      enable_web_push_seller: webPushSeller,
      enable_app_push_seller: appPushSeller,
      enable_app_push_buyer: appPushBuyer,
      enable_sms_seller: smsSeller
    })), dispatch(getCommunications(getAccessToken(), currentUser.data.store.subdomain))
    ]).then(() => {
      setToastMessage("saved successfully.");
      setShowToast(true);
    });
  }

  const handelPushChanged = async (value: any) => {
    setLoading(true);
    const isChecked = value.detail.checked;
    setPushChecked(isChecked);
    const firebaseToken = await getFirebaseToken();

    console.log(firebaseToken)
    const deviceDetails =
      deviceDetect().osName + " " + deviceDetect().browserName;
    present({
      message: "Saved successfully!",
      duration: 2000,
      position: "bottom",
    });

    if (isChecked === true) {
      dispatch(
        setPushNotification(
          getAccessToken(),
          "seller-web",
          deviceDetails,
          {
            registration_id: `${firebaseToken}`,
          }
        )
      );
      localStorage.setItem("pushRegistered", String(true));
    } else {
      dispatch(
        deletePushNotification(getAccessToken(), {
          registration_id: `${firebaseToken}`,
        })
      );
      localStorage.setItem("pushRegistered", String(false));
    }
    setLoading(false);
  };

  const openLinkInNewTab = () => {
    window.open(`https://intelikart.tawk.help/article/customer-received-order-details-on-email`, '_blank');
  };

  return (
    <IonPage>
      <FeatHeader
        title='Communications'
        defaultHref="/settings"
        showInfoButton={true}
        onInfoClick={openLinkInNewTab}
      />
      <IonContent>
        <IonCard>
          <IonItem lines="none">
            <IonIcon icon={notificationsCircle} slot="start" size="large" />
            <IonText>
              <h5>Register for Push</h5>
            </IonText>
            <IonToggle
              slot="end"
              checked={pushChecked}
              onIonChange={(e) => handelPushChanged(e)}
            />
          </IonItem>
        </IonCard>
        <IonCard>
          <IonItem lines="none">
            <IonIcon icon={notificationsCircle} slot="start" size="large" />
            <IonText>
              <h5>Enable Email for Buyer</h5>
            </IonText>
            <IonToggle
              slot="end"
              checked={ebuyer}
              onIonChange={(e) => setEbuyer(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={notificationsCircle} slot="start" size="large" />
            <IonText>
              <h5>Enable Email for Seller</h5>
            </IonText>
            <IonToggle
              slot="end"
              checked={eseller}
              onIonChange={(e) => setEseller(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={notificationsCircle} slot="start" size="large" />
            <IonText>
              <h5>Enable Web Push for Seller </h5>
            </IonText>
            <IonToggle
              slot="end"
              checked={webPushSeller}
              onIonChange={(e) => setWebPushSeller(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={notificationsCircle} slot="start" size="large" />
            <IonText>
              <h5>Enable App Push for Seller</h5>
            </IonText>
            <IonToggle
              slot="end"
              checked={appPushSeller}
              onIonChange={(e) => setAppPushSeller(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={notificationsCircle} slot="start" size="large" />
            <IonText>
              <h5>Enable App Push for Buyer</h5>
            </IonText>
            <IonToggle
              slot="end"
              checked={appPushBuyer}
              onIonChange={(e) => setAppPushBuyer(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={notificationsCircle} slot="start" size="large" />
            <IonText>
              <h5>Enable SMS Seller</h5>
            </IonText>
            <IonToggle
              slot="end"
              checked={smsSeller}
              onIonChange={(e) => setSmsSeller(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={notificationsCircle} slot="start" size="large" />
            <IonText>
              <h5>Enable SMS Buyer</h5>
            </IonText>
            <IonToggle
              slot="end"
              checked={smsBuyer}
              onIonChange={(e) => setSmsBuyer(e.detail.checked)}
            />
          </IonItem>
          <div className="ion-text-center ion-margin-bottom">
            <IonButton shape="round" onClick={(e) => { handleClick(e) }} >Save</IonButton>
          </div>
        </IonCard>
      </IonContent>
      <IonLoading
        isOpen={
          setPushNotificationLoading === true ||
          deletePushNotificationLoading === true ||
          loading === true
        }
        message="Please Wait"
      ></IonLoading>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  );
};

export default Communications;
