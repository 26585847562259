import { IonAccordion, IonCard, IonItem, IonLabel } from '@ionic/react';
import { useUser, useWebsiteSettings } from '../../../../../utils/contexts';

const colorInputStyle = {
    width: '100px',
    hieght: '50px'
}

const BrandingColors = () => {
    const user:any = useUser();
    const settings:any = useWebsiteSettings();
    return (
        <IonCard>
            <IonAccordion>
                <IonItem slot="header" color='light'>
                    <IonLabel>Branding colors</IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                    <IonItem lines='none'>
                        <IonLabel>Primary color</IonLabel>
                        <input value={settings?.websiteSettings?.global_settings?.branding_colors?.primary_color || user?.store?.primary_color ||'#4286f5'} onChange={(e:any)=> settings.setWebsiteSettings({...settings.websiteSettings, global_settings: {...settings.websiteSettings.global_settings, branding_colors: {...settings.websiteSettings.global_settings.branding_colors, primary_color: e.target.value}}  })} type="color" style={colorInputStyle}></input>
                    </IonItem>
                    <br/>
                    <IonItem lines='none'>
                        <IonLabel>Secondry color</IonLabel>
                        <input value={settings?.websiteSettings?.global_settings?.branding_colors?.secondary_color || user?.store?.secondary_color || '#666'} onChange={(e:any)=> settings.setWebsiteSettings({...settings.websiteSettings, global_settings: {...settings.websiteSettings.global_settings, branding_colors: {...settings.websiteSettings.global_settings.branding_colors, secondary_color: e.target.value}}  })} type="color" style={colorInputStyle}></input>
                    </IonItem>
                    <br/>
                    <IonItem lines='none'>
                        <IonLabel>Background color</IonLabel>
                        <input value={settings?.websiteSettings?.global_settings?.branding_colors?.background_color  || '#ffffff'} onChange={(e:any)=> settings.setWebsiteSettings({...settings.websiteSettings, global_settings: {...settings.websiteSettings.global_settings, branding_colors: {...settings.websiteSettings.global_settings.branding_colors, background_color: e.target.value}}  })} type="color" style={colorInputStyle}></input>
                    </IonItem>
                </div>
            </IonAccordion>
        </IonCard>
    )
}

export default BrandingColors